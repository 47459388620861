import {Divider} from '@mui/material'
import {ChangeEvent, FC, memo, useState} from 'react'
import styled from 'styled-components'
import validate from 'validate.js'
import validator from 'validator'
import {InputBox} from '../../common/InputBox'
import {ToggleButton} from '../../common/ToggleButton'
import {UploadDropZone} from '../../common/UploadDropZone'
import {campaignNameValidation} from '../../../helpers/validation'
import GoogleAnalytics from '../Analitics/GoogleAnalytics'
import GoogleTagManager from '../Analitics/GoogleTagManager'
import {Toast} from '../../../helpers/Toast'
import {File} from '../../common/File'
import {clientUpload} from '../../../lib/upload/clientUpload'
import { useCampaign } from '../../../../builder/context/campaign'
import CampaignsService from '../../../../services/CampaignsService'
import { Input } from '../../../../builder/components/input'

interface Props {
}

const SettingsTab: FC<Props> = () => {
    const { campaign, updateCampaign } = useCampaign();
    const [error, setError] = useState({})
    const [loading, setLoading] = useState(false)

    //
    // Redirects are enabled or disabled.
    //
    async function onEnableRedirect(e: ChangeEvent<HTMLInputElement>): Promise<void> {
        updateCampaign({
            [e.target.name]: e.target.checked,
        });

        if (e.target.checked) {
            if (campaign.redirectUrl) {
                // Enable the redirect in the backend, if the new URL. is already set.
                await CampaignsService.enableRedirect(campaign._id, campaign.redirectUrl);
            }
        }
        else {
            // Disable the redirect in the backend.
            await CampaignsService.disableRedirect(campaign._id);
        }
    }

    //
    // Redirects are enabled and the redirect URL has changed.
    //
    async function onChangeRedirectUrl(name: string, value: string): Promise<void> {

        updateCampaign({
            redirectUrl: value,
        });

        // Enable the redirect in the backend.
        await CampaignsService.enableRedirect(campaign._id, value);
    }

    function handleChangeProjectSettings(e: ChangeEvent<HTMLInputElement>): void {
        updateCampaign({
            [e.target.name]: e.target.value,
        });
    }

    function handleToggleProjectSettings(e: ChangeEvent<HTMLInputElement>): void {
        updateCampaign({
            [e.target.name]: e.target.checked,
        });
    }

    async function handleImageChange(image: File): Promise<void> {
        setLoading(true);
        try {
            const asset = await clientUpload(image);
            updateCampaign({
                metaImageId: asset._id,
            });
        }
        catch (error) {
            const msg = 'Error uploading file';
            console.error(msg);
            console.error(error);
            Toast(msg, 'error');
        }
        finally {
            setLoading(false);
        }
    }

    function deleteImage(): void {
        updateCampaign({
            metaImageId: undefined,
        });
    };

    function handleTextError(name: string, value: string, message: string): void {
        const valid = validate.isString(value)

        if (name === 'campaignName' && validator.isLength(value, {min: 1})) {
            let nameIsValid = campaignNameValidation(value)
            if (!nameIsValid) {
                setError((prevState) => {
                    return {
                        ...prevState,
                        [name]: 'no symbols allowed in campaign name',
                    }
                })
                return
            }

            if (
                value.indexOf('/') > -1 ||
                value.indexOf('.') > -1 ||
                value.indexOf('\\') > -1 ||
                value.indexOf('=') > -1
            ) {
                setError((prevState) => {
                    return {
                        ...prevState,
                        [name]: 'no symbols allowed in campaign name',
                    }
                })

                return
            }
        } else if (name === 'redirectUrl') {
            if (
                validator.isURL(value, {
                    protocols: ['https'],
                    require_protocol: true,
                    require_valid_protocol: true,
                }) === false
            ) {
                setError((prevState) => {
                    return {
                        ...prevState,
                        [name]: message,
                    }
                })
            } else {
                setError((prevState) => {
                    return {
                        ...prevState,
                        [name]: null,
                    }
                })
            }
            return
        }

        if (!valid || validator.isEmpty(value)) {
            setError((prevState) => {
                return {
                    ...prevState,
                    [name]: message,
                }
            })
        } else {
            setError((prevState) => {
                return {
                    ...prevState,
                    [name]: null,
                }
            })
        }
    }

    function checkValidValue(e: ChangeEvent<HTMLInputElement>): void {
        const name = e.target.name
        let message = ''
        if (name === 'campaignName') {
            message = 'Please enter valid campaign name.'
        }
        if (name === 'redirectUrl') {
            message = 'Please enter valid url. Must start with https://'
        }
        handleTextError(name, campaign[name], message)
    }

    function handleChangeAnalitics(name: string, value: string, plugin: string): void {
        updateCampaign({
            analytics: {
                ...campaign.analytics,
                [plugin]: {
                    ...campaign.analytics[plugin],
                    [name]: value,
                },
            },
        });
    }

    return (
        <SettingsContainer>
            <ContentBlock>
                <InputBox
                    label="Project name"
                    name="campaignName"
                    type="text"
                    onChange={handleChangeProjectSettings}
                    value={campaign.campaignName}
                    width="100%"
                    readOnly={campaign.status === 'published'}
                    marginBottom="10px"
                    onBlur={checkValidValue}
                    error={error['campaignName']}
                />
                <Description>
                    Once deployed, your project name becomes your link and can not be changed after
                    deployment at this point.
                </Description>
                <Marginier height="18px"/>
                <InputBox
                    label="Project description"
                    name="campaignDescription"
                    placeholder="Campaign Description (max 160 char)"
                    type="textarea"
                    onChange={handleChangeProjectSettings}
                    value={campaign.campaignDescription}
                    height="140px"
                />
                {campaign.seeds
                    && (
                        <div>
                            <Title size="15px">AI seeds</Title>

                            {campaign.seeds.map((seed, index) => {
                                return (
                                    <div key={index}>
                                        <Title size="12px">Seed {index+1}</Title>

                                        <InputBox
                                            label="Question"
                                            name="question"
                                            type="textarea"
                                            onChange={event => {
                                                updateCampaign({
                                                    seeds: [
                                                        ...campaign.seeds.slice(0, index),
                                                        {
                                                            ...seed,
                                                            question: event.target.value,
                                                        },
                                                        ...campaign.seeds.slice(index + 1),
                                                    ],
                                                });
                                            }}
                                            value={seed.question}
                                            />
                                        <InputBox
                                            label="Prompt"
                                            name="prompt"
                                            type="textarea"
                                            onChange={event => {
                                                updateCampaign({
                                                    seeds: [
                                                        ...campaign.seeds.slice(0, index),
                                                        {
                                                            ...seed,
                                                            prompt: event.target.value,
                                                        },
                                                        ...campaign.seeds.slice(index + 1),
                                                    ],
                                                });
                                            }}
                                            value={seed.prompt}
                                            />

                                        <InputBox
                                            label="Placeholder"
                                            name="placeholder"
                                            type="input"
                                            onChange={event => {
                                                updateCampaign({
                                                    seeds: [
                                                        ...campaign.seeds.slice(0, index),
                                                        {
                                                            ...seed,
                                                            placeholder: event.target.value,
                                                        },
                                                        ...campaign.seeds.slice(index + 1),
                                                    ],
                                                });
                                            }}
                                            value={seed.placeholder}
                                            />

                                        <InputBox
                                            label="Example"
                                            name="example"
                                            type="textarea"
                                            onChange={event => {
                                                updateCampaign({
                                                    seeds: [
                                                        ...campaign.seeds.slice(0, index),
                                                        {
                                                            ...seed,
                                                            example: event.target.value,
                                                        },
                                                        ...campaign.seeds.slice(index + 1),
                                                    ],
                                                });
                                            }}
                                            value={seed.example}
                                            />
                                    </div>
                                );
                            })}
                        </div>
                    )
                }
                <Title size="13px">Sharing thumbnail preview image</Title>
                <Description>
                    The image that is displayed whenever someone shares a post or page of your
                    website on a social media platform like Facebook or Twitter.
                </Description>
                <Marginier height="10px"/>
                {!campaign.metaImageUrl ? (
                    <UploadDropZone
                        width="100%"
                        height="170px"
                        imagesOnly
                        setFile={handleImageChange}
                        isLoading={loading}
                    />
                ) : (
                    <abbr title="Click to Delete">
                        <File
                            width="100%"
                            height="170px"
                            name={"Meta image"}
                            imageSrc={campaign.metaImageUrl}
                            onClick={deleteImage}
                        />
                    </abbr>
                )}
                <Marginier height="18px"/>
                <ToggleButton
                    name="redirect"
                    label="Redirect page?"
                    isToggled={campaign.redirect}
                    onChange={onEnableRedirect}
                />
                <Marginier height="5px"/>
                <Description>
                    When a web browser attempts to open a URL that has been redirected, a page with
                    a different URL is opened.
                </Description>

                {campaign.redirect && (
                    <>
                        <Marginier height="10px"/>
                        <Input
                            placeholder="Redirect url"
                            name="redirectUrl"
                            onChange={onChangeRedirectUrl}
                            value={campaign.redirectUrl}
                            width="100%"
                            onBlur={checkValidValue}
                            error={error['redirectUrl']}
                        />
                    </>
                )}
                <Marginier height="10px"/>
                <Divider/>
                <AnaliticsContainer>
                    <h2>Analytics</h2>
                    <Description>
                        Input Google Tag Manager to be able to link back analytics to your Google
                        Analytics Account.
                    </Description>
                    <GoogleAnalytics
                        analytics={campaign.analytics}
                        handleSettingsChange={handleChangeAnalitics}
                    />
                    <GoogleTagManager
                        analytics={campaign.analytics}
                        handleSettingsChange={handleChangeAnalitics}
                    />
                </AnaliticsContainer>
            </ContentBlock>
        </SettingsContainer>
    )
}

export default memo(SettingsTab)

const SettingsContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    height: 100%;
    padding: 16px;

    & > span {
        margin-bottom: 18px;
    }
`
const SaveContainer = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    bottom: 0;
    background-color: #fff;
`
const ContentBlock = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    `
const AnaliticsContainer = styled.div`
    margin-top: 10px;
    h2 {
        font-size: 18px;
        font-weight: 500;
        margin: 10px 0;
    }
`

export const Description = styled.span`
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: #717f95;
`

export const Title = styled.p<{
    size: string
}>`
    font-weight: bold;
    font-size: ${(props) => props.size};
    line-height: 13px;
    color: #3c4b61;
    margin-bottom: 5px;
`

export const Marginier = styled.div<{
    height: string
}>`
    width: 100%;
    height: ${(props) => props.height};
`