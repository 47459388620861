import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { SEOLive } from "../components/SEOLive";
import { StaticWidgets } from '../components/new/static-widgets';
import { ICampaign, RenderContextProvider, getRenderContext } from 'widgets-base';
import { CampaignContextProvider } from '../../builder/context/campaign';

const campaigns = [];

export function Testbed() {

    const { campaignIndex, pageIndex } = useParams();
    const _campaignIndex = parseInt(campaignIndex);
    const _pageIndex = parseInt(pageIndex);

    const [campaign, setCampaign] = useState<ICampaign>(campaigns[_campaignIndex] as any as ICampaign);
    const [json, setJson] = useState<string>(JSON.stringify(campaign, null, 4));
    const curPage = campaign.views[_pageIndex];

    console.log(`Rendering campaign ${campaignIndex}, page ${pageIndex}`);

    useEffect(() => {
        console.log(`Campaign  -> JSON`);
        setJson(JSON.stringify(campaign, null, 4));
    }, [campaign]);

    useEffect(() => {
        console.log(`JSON -> Campaign`);
        setCampaign(JSON.parse(json));
    }, [json]);

    return (
        <CampaignContextProvider
            campaign={campaign}
            enableSaving={false}
        >
            <div className="relative">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: "100vh",
                    }}
                >
                    <SEOLive title={campaign?.campaignName} />

                    <div
                        className="main flex flex-col w-full"
                    >
                        <RenderContextProvider defaultRenderContext={getRenderContext()}>
                            <StaticWidgets
                                widgets={curPage.widgets}
                                />
                        </RenderContextProvider>
                    </div>

                </div>

                {/* <div className="absolute"
                style={{
                    right: "10px",
                    top: "10px",
                    bottom: "20px",
                    width: "600px",
                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                }}
            >
                <textarea
                    className="bg-transparent text-xs absolute"
                    value={json}
                    onChange={evt => setJson(evt.target.value)}
                    style={{
                        top: "5px",
                        bottom: "2px",
                        left: "5px",
                        right: "2px",
                        "whiteSpace": "nowrap",
                        "overflowX": "scroll",
                    }}
                >
                </textarea>
            </div> */}
            </div>
        </CampaignContextProvider>
    );
}
