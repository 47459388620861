import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'

interface IProps extends HTMLAttributes<HTMLButtonElement> {
    size?: number | string
    name?: string
    color?: string
}

enum defaultSizes {
    extra_small = 'xs',
    small = 'sm',
    medium = 'md',
    large = 'lg',
    extra_large = 'xl',
    double = '2xl',
}

export const iconSizes = {
    extra_small: 12,
    small: 16,
    medium: 32,
    large: 64,
    extra_large: 128,
    double: 256,
}

const IconComponent = styled.span<IProps>`
    font-size: ${(p) => p.size}px;
    color: ${(p) => p.color};
    font-weight: normal;

    @media only screen and (max-width: 768px) {
        font-size: small;
    }
`

const selectIconSize = (key: string | number): number => {
    switch (key) {
        case defaultSizes.extra_small:
            return iconSizes.extra_small
        case defaultSizes.small:
            return iconSizes.small
        case defaultSizes.medium:
            return iconSizes.medium
        case defaultSizes.large:
            return iconSizes.large
        case defaultSizes.extra_large:
            return iconSizes.extra_large
        case defaultSizes.double:
            return iconSizes.double
        default:
            return +key
    }
}

const Icon = ({ name = 'datepicker', size = 32, ...props }: IProps) => {
    const currentSize = selectIconSize(size)
    return <IconComponent className={`icon-deployable-${name}`} size={currentSize} {...props} />
}
export default Icon
