import { nanoid } from 'nanoid'
import { ISliderSlides, ISwiper } from '../../../modules/widgets/general/ISwiper'

export class SliderSlides implements ISliderSlides {
    id = nanoid()
    title = ''
    subtitle = ''
    text = ''
    path = ''

    constructor(title: string, subtitle: string, text: string, path: string) {
        this.title = title
        this.subtitle = subtitle
        this.text = text
        this.path = path
    }
}

export class Swiper implements ISwiper {
    name = 'swiper'
    type = 'swiper'
    category = 'general'
    id = nanoid()
    appearanceSettings = {
        width: 90,
        height: null,
        align: 'left',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        font: {
            family: 'Poppins',
            size: {
                title: 40,
                subtitle: 20,
                text: 14,
            },
            verticalAlign: 'flex-start',
        },
    }
    colourSettings = {
        'text colour': ['#fff', '#FFA7E1', '#0426FF'],
        'arrows colour': ['#fff', '#FFA7E1', '#0426FF'],
        'dots colour': ['#fff', '#FFA7E1', '#0426FF'],
    }
    settings = {
        slidesToShow: 3,
        pagination: true,
        navigation: true,
        slideDescription: true,
        autoplay: false,
        fadeEffect: false,
        speed: 600,
        parallax: {
            active: false,
        },
        slides: [
            new SliderSlides(
                'Slide 1',
                'Subtitle',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam dictum mattis velit, sit amet faucibus felis iaculis nec. Nulla laoreet justo vitae porttitor porttitor.',
                'https://swiperjs.com/demos/images/nature-2.jpg'
            ),
            new SliderSlides(
                'Slide 2',
                'Subtitle',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam dictum mattis velit, sit amet faucibus felis iaculis nec. Nulla laoreet justo vitae porttitor porttitor.',
                'https://swiperjs.com/demos/images/nature-3.jpg'
            ),
            new SliderSlides(
                'Slide 3',
                'Subtitle',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam dictum mattis velit, sit amet faucibus felis iaculis nec. Nulla laoreet justo vitae porttitor porttitor.',
                'https://swiperjs.com/demos/images/nature-4.jpg'
            ),
            new SliderSlides(
                'Slide 4',
                'Subtitle',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam dictum mattis velit, sit amet faucibus felis iaculis nec. Nulla laoreet justo vitae porttitor porttitor.',
                'https://swiperjs.com/demos/images/nature-5.jpg'
            ),
            new SliderSlides(
                'Slide 5',
                'Subtitle',
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam dictum mattis velit, sit amet faucibus felis iaculis nec. Nulla laoreet justo vitae porttitor porttitor.',
                'https://swiperjs.com/demos/images/nature-6.jpg'
            ),
        ],
    }
}
