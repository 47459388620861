import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { capitalizeFirstLetter } from '../../helpers/capitalizeFirstLetter'

interface Props {
    label?: string
    width?: string
    height?: string
    size?: 'small' | 'medium' | 'large'
    value?: string | number
    placeholder?: string
    name?: string
    type?: string
    onChange?: (e: any) => void
    error?: string
    onBlur?: (e: any) => void
    onKeyUp?: (e: React.KeyboardEvent) => void
    onFocus?: (e: any) => void
    maxLength?: number
    min?: number
    max?: number
    step?: number
    disabled?: boolean
    readOnly?: boolean
    required?: boolean
    helperText?: string
    marginBottom?: string
    defaultValue?: any
}

export const InputBox = ({
    label,
    size = 'medium',
    width,
    height,
    disabled,
    value,
    readOnly,
    required,
    helperText,
    step,
    min,
    onFocus,
    marginBottom = '18px',
    ...props
}: Props) => {
    return (
        <InputWrapper className="input_box" marginBottom={marginBottom} width={width}>
            {label && <InputLabel>{capitalizeFirstLetter(label)}</InputLabel>}
            {props.type !== 'textarea' ? (
                <InputBoxStyle
                    {...props}
                    componentSize={size}
                    width={width}
                    value={value}
                    disabled={disabled}
                    readOnly={readOnly}
                    step={step}
                    required={required}
                    onFocus={onFocus}
                    error={props.error}
                />
            ) : (
                <TextAreaStyle
                    {...props}
                    componentSize={size}
                    width={width}
                    maxLength={props.maxLength}
                    height={height}
                    value={value}
                />
            )}
            {helperText && <HelperText>{helperText}</HelperText>}
            {props.error && <p className="error-text">{props.error}</p>}
        </InputWrapper>
    )
}

const shake = keyframes`
  25% {transform: translateX(4px)}
  50% {transform: translateX(-4px)}
  75% {transform: translateX(4px)}
`

const InputWrapper = styled.div<{
    marginBottom: string
    width: string
}>`
    display: flex;
    flex-direction: column;
    margin-bottom: ${(props) => props.marginBottom};
    width: ${(props) => (props.width ? props.width : 'inherit')};
    position: relative;

    .error-text {
        margin: 0.2rem 0;
        color: red;
        position: absolute;
        bottom: -15px;
        left: 0;
    }
`

const InputLabel = styled.label`
    font-size: 14px;
    font-weight: bold;
    color: ${(props) => props.theme.text.secondary};
    margin-bottom: 4px;
`

const InputBoxStyle = styled.input.attrs((props) => ({}))<{
    componentSize: string
    width: string
    error: string
}>`
    background-color: #fafaff;
    border-radius: 10px;
    border: 1px solid ${(props) => (props.error ? 'red' : props.theme.lineColour)};
    color: ${(props) => props.theme.text.light};
    padding: ${(props) =>
        props.componentSize === 'small'
            ? '7px 12px'
            : props.componentSize === 'medium'
            ? '10px 12px'
            : '12px 12px'};
    display: flex;
    align-items: center;
    width: ${(props) => (props.width ? props.width : 'auto')};
    ::placeholder {
        color: ${(props) => props.theme.text.light};
    }
    font-size: 14px;
    animation: ${(props) =>
        props.error
            ? css`
                  ${shake} 300ms
              `
            : 'none'};
`

const TextAreaStyle = styled.textarea.attrs((props) => ({}))<{
    componentSize: string
    width: string
    height?: string
}>`
    background-color: #fafaff;
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.lineColour};
    color: ${(props) => props.theme.text.light};
    padding: ${(props) =>
        props.componentSize === 'small'
            ? '7px 12px'
            : props.componentSize === 'medium'
            ? '10px 12px'
            : '12px 12px'};
    display: flex;
    align-items: center;
    width: ${(props) => (props.width ? props.width : 'auto')};
    ::placeholder {
        color: ${(props) => props.theme.text.light};
    }
    font-size: 14px;
    resize: none;
    height: ${(props) => (props.height ? props.height : 'auto')};
`

const HelperText = styled.span`
    color: #f5393d;
    font-size: 14px;
    margin-top: 5px;
`
