//
// Functions for static deployment.
//

import React from "react";
import { ServerStyleSheet } from "styled-components";
import { renderToString } from 'react-dom/server';
import { ThemeProvider } from 'styled-components';
import { HelmetProvider } from 'react-helmet-async';
import { theme, GlobalStyle } from './theme.config';
import { SEOLive } from "../v1/components/SEOLive";
import { StaticWidgets } from "../v1/components/new/static-widgets";
import { AuthContextProvider } from "../hooks/auth";
import { PageThemeProvider, defaultPageTheme } from "../builder/context/page-theme";
import { getWidgetType } from "../builder/widget/widget-types";
import { IRenderContext, RenderContextProvider, ICampaign, IWidget, makeFullAssetUrl } from "widgets-base";
import { useCampaign, CampaignContextProvider } from "../builder/context/campaign";
import { RenderWidgetContextProvider } from "../builder/context/render-widget";

export function SerializePage() {
    const { campaign, currentPageIndex } = useCampaign();

    const staticRenderContext: IRenderContext = {
        isStaticExport: true,
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
            }}
            >
            <SEOLive title={campaign?.campaignName} />

            <PageThemeProvider
                pageTheme={campaign.pageTheme || defaultPageTheme}
                >
                <div
                    className="main flex flex-col w-full"
                    >
                    <RenderContextProvider defaultRenderContext={staticRenderContext}>
                        <RenderWidgetContextProvider>
                            <StaticWidgets
                                widgets={campaign.views[currentPageIndex].widgets}
                                />
                        </RenderWidgetContextProvider>
                    </RenderContextProvider>
                </div>
            </PageThemeProvider>
        </div>
    );
}

function SerializePageWrapper({ campaign, pageIndex }: { campaign: ICampaign, pageIndex: number }) {
    return (
        <AuthContextProvider>
            <CampaignContextProvider
                campaignId={campaign._id}
                pageIndex={pageIndex}
                campaign={campaign}
                enableSaving={false}
                >
                <ThemeProvider theme={theme}>
                    <HelmetProvider>
                        <GlobalStyle />
                        <SerializePage />
                    </HelmetProvider>
                </ThemeProvider>
            </CampaignContextProvider>
        </AuthContextProvider>
    );
}

//
// Determines all the asssets needed for a campaign.
//
export function _collectAssets(widget: IWidget, assetIds: Set<string>, assetUrls: Set<string>, widgetsUsed: Set<string>): void {

    if (widget.xtype) {
        const widgetType = getWidgetType(widget.xtype);
        if (!widgetType) {
            throw new Error(`Failed to find type for widget ${widget.xtype}`);
        }

        widgetsUsed.add(widget.xtype);

        if (widgetType.collectAssets) {
            widgetType.collectAssets(widget, assetIds, assetUrls);
        }
    }

    if (widget.children) {
        collectAssets(widget.children, assetIds, assetUrls, widgetsUsed);
    }
}

//
// Determines all the asssets needed for a campaign.
//
export function collectAssets(widgets: IWidget[], assetIds: Set<string>, assetUrls: Set<string>, widgetsUsed: Set<string>): void {
    for (const widget of widgets) {
        _collectAssets(widget, assetIds, assetUrls, widgetsUsed);
    }    
}

//
// A single serialized page.
//
export interface ISerializedPage {
    //
    // The ID for the background image asset.
    //
    backgroundImageId?: string;

    //
    // The URL for the background image.
    //
    backgroundImageUrl?: string;

    //
    // The background colour for the page.
    //
    backgroundColour?: string;

    //
    // HTML for the page.
    //
    html: string;

    // 
    // Styles for the page.
    //
    styles: string;
}

//
// Entire serialized project.
//
export interface ISerializedProject {

    //
    // The ID of the campaign.
    //
    campaignId: string;

    //
    // The title of the page.
    //
    title: string;

    //
    // Details for the pretty URL.
    //
    prettyURL?: {
        accountName: string;
        campaignName: string;
    }

    //
    // The unique set of widgets used in the project.
    //
    widgetsUsed: string[];

    //
    // Pages in the project.
    //
    pages: ISerializedPage[];

    //
    // Asset references by ID.
    //
    assetIds: string[];

    //
    // Asset references by URL.
    //
    assetUrls: string[];

    //
    // Google Analytics measurement ID.
    //
    googleAnalyticsId?: string;

    //
    // Google Tag Manager container ID.
    //
    googleTagManagerTag?: string; 
}

//
// Serializes a project to HTML and styles.
//
export function serializeProject(campaign: ICampaign): ISerializedProject {
    
    const assetUrls = new Set<string>();
    const assetIds = new Set<string>();
    const widgetsUsed = new Set<string>();

    for (const view of campaign.views) {
        if (view.backgroundImageId) {
            assetIds.add(view.backgroundImageId);
        }
        else if (view.backgroundImageUrl) {
            assetUrls.add(view.backgroundImageUrl);
        }

        collectAssets(view.widgets, assetIds, assetUrls, widgetsUsed);
    }

    const pages: ISerializedPage[] = [];

    for (let pageIndex = 0; pageIndex < campaign.views.length; pageIndex++) {
        const sheet = new ServerStyleSheet();
        // clearProbe();
        const html = renderToString(sheet.collectStyles(
            <SerializePageWrapper campaign={campaign} pageIndex={pageIndex} />
        ));

        // showProbe();
        const styles = sheet.getStyleTags(); // or sheet.getStyleElement();
        sheet.seal();

        const page = campaign.views[pageIndex];
        const serializedPage: ISerializedPage = {
            backgroundColour: page.backgroundColour,
            html, 
            styles,
        };

        if (page.backgroundImageId) {
            serializedPage.backgroundImageId = page.backgroundImageId;
        }
        else if (page.backgroundImageUrl) {
            serializedPage.backgroundImageUrl = makeFullAssetUrl(page.backgroundImageUrl);
        }   

        pages.push(serializedPage);
    }

    return {
        title: campaign.campaignName,
        campaignId: campaign._id,
        pages,
        assetIds: Array.from(assetIds.values()),
        assetUrls: Array.from(assetUrls.values()),
        widgetsUsed: Array.from(widgetsUsed.values()),
        googleAnalyticsId: campaign.analytics?.googleAnalytics?.enabled && campaign.analytics?.googleAnalytics?.trackingId,
        googleTagManagerTag: campaign.analytics?.tagManagerSettings?.enabled && campaign.analytics?.tagManagerSettings?.containerId,
    };
}

