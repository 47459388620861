import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Button } from './Button'
import { CustomModal } from './CustomModal'
import { useConfirm } from '../../../hooks/confirm'

export const CustomConfirm = () => {
    const { prompt = '', isOpen = false, proceed, cancel } = useConfirm();

    useEffect(() => {
        const handleKeydown = (e: { key: string }) => {
            if (proceed && isOpen && e.key === 'Enter') {
                proceed()
            }
        }

        window.addEventListener('keydown', handleKeydown)
        return () => window.removeEventListener('keydown', handleKeydown)
    }, [proceed, isOpen])

    return (
        <CustomModal visible={isOpen} title="Confirm modal">
            <ConfirmContent>
                <ConfirmHeader>Confirm</ConfirmHeader>
                <img width={80} height={80} src="/images/warning.png" alt="warning" />
                <span>{prompt}</span>
                <Buttons>
                    <Button type="button" onClick={cancel}>
                        No
                    </Button>
                    <Button type="button" onClick={proceed}>
                        Yes
                    </Button>
                </Buttons>
            </ConfirmContent>
        </CustomModal>
    )
}

const ConfirmContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
    max-width: 400px;

    & > span {
        font-size: 18px;
        font-weight: 600;
        padding: 20px;
        text-align: center;
    }

    & > img {
        margin-top: 10px;
    }
`

const ConfirmHeader = styled.p`
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 5px;
    border-bottom: 1px solid #dfe2f2;
`

const Buttons = styled.div`
    margin: 10px;
    display: flex;
    align-items: center;
    & > button:first-child {
        background-color: #ff00007f;
        margin-right: 10px;
    }
    & > button:last-child {
        background-color: #52b1529d;
    }
    & > button {
        width: 80px;
        height: 32px;
    }
`
