import { Slider } from '@mui/material'
import { ChangeEvent, FC } from 'react'
import styled from 'styled-components'
import { useWidget } from 'widgets-base'

interface Props {
    height: number
    label?: string
    isImage?: boolean
}

const Height: FC<Props> = ({
    height = 0,
    label = 'Height',
    isImage = false }) => {

    const { widget, setWidget } = useWidget();

    const handleChangeHeight = (event: ChangeEvent<HTMLInputElement>) => {
        const value = +event.target.value

        let newWidget;
        if (!isImage) {
            newWidget = {
                ...widget,
                appearanceSettings: {
                    ...widget.appearanceSettings,
                    height: value,
                },
            }
        } else {
            newWidget = {
                ...widget,
                appearanceSettings: {
                    ...widget.appearanceSettings,
                    image: {
                        ...widget.appearanceSettings.image,
                        height: value,
                    }
                },
            }
        }

        setWidget(newWidget);
    }

    const handleSliderChange = (_event: Event, newValue: number) => {
        if (typeof newValue === 'number') {
            let newWidget;
            if (!isImage) {
                newWidget = {
                    ...widget,
                    appearanceSettings: {
                        ...widget.appearanceSettings,
                        height: newValue,
                    },
                }
            } else {
                newWidget = {
                    ...widget,
                    appearanceSettings: {
                        ...widget.appearanceSettings,
                        image: {
                            ...widget.appearanceSettings.image,
                            height: newValue,
                        }
                    },
                }
            }

            setWidget(newWidget);
        }
    }

    return (
        <Container>
            <label>{label}</label>
            <ActiveContainer>
                <div className="field">
                    <input
                        placeholder="0"
                        type="number"
                        name="height"
                        value={height}
                        onChange={handleChangeHeight}
                    />
                    <span>%</span>
                </div>
                <Slider
                    size="small"
                    max={100}
                    min={0}
                    value={height}
                    aria-label="Small"
                    valueLabelDisplay="auto"
                    onChange={handleSliderChange}
                />
            </ActiveContainer>
        </Container>
    )
}

export default Height

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #717f95;

    & > label {
        font-size: 12px;
        color: #3c4b61;
        margin-bottom: 7px;
        font-weight: bold;
    }
`

const ActiveContainer = styled.div`
    display: flex;
    height: 32px;
    width: 80%;

    .field {
        display: flex;
        align-items: center;
        padding: 5px;
        width: 78px;
        background: #ffffff;
        border: 1px solid #dfe2f2;
        border-radius: 8px;
        margin-right: 6px;
        input {
            border: none;
            width: 40px;
            font-size: 12px;
            color: #3c4b61;
            font-weight: bold;
        }
        span {
            font-size: 12px;
            color: #3c4b61;
            border-left: 1px solid #dfe2f2;
            padding: 0 5px;
            font-weight: bold;
        }
    }

    .MuiSlider-root {
        height: 3px;
        color: #6478f9;
    }
`
