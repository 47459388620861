import { nanoid } from 'nanoid'
import { IAudio } from '../../../modules/widgets/general/IAudio'

export class AudioFile implements IAudio {
    id = nanoid()
    name = 'audio file'
    type = 'audio'
    icon = '/images/widgets/audio-file.png'
    category = 'general'
    settings = {
        label: 'Audio file',
        src: 'https://www.bensound.com/bensound-music/bensound-sweet.mp3',
        file: null,
        fileName: '',
        name: '',
        autoPlay: false,
        loop: true,
        muted: false,
    }
    appearanceSettings = {
        width: 90,
        height: 5,
        align: 'center',
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 0,
        marginRight: 0,
        font: {
            family: 'Poppins',
            size: 14,
            weight: 400,
        },
    }
    colourSettings = {
        labelColor: ['#000', '#FFA7E1', '#0426FF'],
    }
}
