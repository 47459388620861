import { FC } from 'react'
import { WidgetSettingContainer } from '../../../Design/WidgetSettingContainer'
import SwiperGeneralSetting from '../../../EditMode/Settings/SwiperGeneralSetting'
import SidebarTabs from '../../../EditMode/SidebarTabs'
import SwiperDesignSetting from './SwiperDesignSetting'

interface Props {
}

const SwiperSetting: FC<Props> = () => {
    return (
        <WidgetSettingContainer padding={0}>
            <SidebarTabs
                setting={<SwiperGeneralSetting />}
                design={<SwiperDesignSetting />}
            />
        </WidgetSettingContainer>
    )
}

export default SwiperSetting
