import { Position } from "../../v1/components/new/insertion-marker";

export interface Point {
    x: number;
    y: number;
};

export interface Rect {
    top: number;
    left: number;
    width: number;
    height: number;
    lastUpdated: number;
}

//
// Gets the rect for an element.
//
export function getElementRect(elementRef: React.MutableRefObject<HTMLElement>): Rect | undefined {
    if (!elementRef || !elementRef.current) {
        return undefined;
    }

    const clientRect = elementRef.current.getBoundingClientRect();
    return {
        top: clientRect.top,
        left: clientRect.left,
        width: clientRect.width,
        height: clientRect.height,
        lastUpdated: Date.now(), //fio: needed?
    };
}

//
// Determine the vertical half of the rect that the point is in.
//
export function determineVerticalHalf(rect: Rect, point: Point): Position {
    return point.y < rect.top + rect.height / 2 ? 'start' : 'end';
}

//
// Determine the horizontal half of the rect that the point is in.
//
export function determineHorizontalHalf(rect: Rect, point): Position {
    return point.x < rect.left + rect.width / 2 ? 'start' : 'end';
}