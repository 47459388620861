import { Breadcrumbs, Tooltip } from '@mui/material'
import { FC } from 'react'
import SaveContainer from '../SaveContainer'
import DeployButton from '../SaveContainer/DeployButton'
import PreviewButton from '../SaveContainer/PreviewButton'
import SaveButtonCustom from '../SaveContainer/SaveCampaignButton'
import styled, { keyframes } from 'styled-components'
import { getWidgetName } from '../../../../builder/widget/widget-types'
import { truncate } from '../../../../lib/text'
import { useConfirm } from '../../../../hooks/confirm'
import { useRenderContext } from 'widgets-base'
import { useCampaign } from '../../../../builder/context/campaign'

interface Props {
}

const WidgetPreviewHeader: FC<Props> = ({}) => {

    const { campaign, currentPageIndex, clearWidgets, selectedWidgetStack, selectedWidgetPath, setSelectedWidgetPath } = useCampaign();
    const { isConfirmed } = useConfirm();
    const { renderContext } = useRenderContext();

    async function handleClearPageWidgets() {
        const confirmed = await isConfirmed(`Are you sure you want to delete all elements from this page?`);
        if (confirmed) {
            await clearWidgets();
        }
    };

    let showBreadcrumbs = true;
    if (renderContext.screen === "small" && renderContext.orientation === "portrait") {
        showBreadcrumbs = false;
    }

    return (
        <PreviewHeader>
            <PageOptions>
                <Tooltip title="Clear widgets" arrow>
                    <span className="clear" onClick={handleClearPageWidgets}>
                        CLEAR
                    </span>
                </Tooltip>
                {showBreadcrumbs
                    && <Breadcrumbs 
                        separator="›"
                        aria-label="breadcrumb" 
                        className="select-none"
                        maxItems={4}
                        itemsBeforeCollapse={1}
                        itemsAfterCollapse={2}
                        >
                        {selectedWidgetStack.map((widget, index) => {
                            if (index === selectedWidgetStack.length - 1) {
                                return (
                                    <div
                                        className="text-xs"
                                        key={selectedWidgetStack[index].id}
                                        >
                                        {truncate(getWidgetName(widget), 100)}
                                    </div>
                                );
                            }
                            else {
                                return (
                                    <div
                                        key={selectedWidgetStack[index].id}
                                        className="cursor-pointer text-xs"
                                        onClick={() => {
                                            setSelectedWidgetPath(selectedWidgetPath.slice(0, index + 1), campaign.views[currentPageIndex].widgets);
                                        }}
                                        >
                                        {truncate(getWidgetName(widget), 20)}
                                    </div>
                                );
                            }
                        })}
                    </Breadcrumbs>
                }
            </PageOptions>
            <SaveContainer>
                <PreviewButton campaignId={campaign._id} />
                <SaveButtonCustom />
                <DeployButton />
            </SaveContainer>
        </PreviewHeader>
    );
}

export default WidgetPreviewHeader

export const PreviewHeader = styled.nav`
    width: 100%;
    height: 50px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #9191914b;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 1px solid #7979794a;
`

const arrowPuls = keyframes`
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(0);
    }
`

export const PageOptions = styled.div`
    display: flex;
    align-items: center;

    & > img {
        position: absolute;
        left: 30%;
        z-index: 9999;
        bottom: 30%;
    }

    span {
        font-size: 14px;
        font-weight: bold;
        color: #6478f9;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
    }

    .clear {
        margin-right: 20px;
        &:hover {
            color: #f88686;
            transform: scale(1.1);
        }
    }

    .export {
        margin-right: 20px;
        &:hover {
            color: #6478f9d8;
            transform: scale(1.1);
        }
    }

    .edit {
        &:hover {
            color: #6478f9d8;
            transform: scale(1.1);
        }
    }

    .back {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #6478f9;
        svg {
            margin-right: 10px;
            animation: ${arrowPuls} 1.5s infinite;
        }
        & > span:hover {
            color: #6478f9d8;
            transform: scale(1.01);
        }
    }
`
