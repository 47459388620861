import { IFeedback } from '../../modules/IFeedback'

const webHookURL = `https://hooks.slack.com/services/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`

export const sendFeedbackMessage = async (data: IFeedback) => {
    const message = {
        blocks: [
            {
                type: 'header',
                text: {
                    type: 'plain_text',
                    text: `👋 NEW FEEDBACK MESSAGE FOR DEPLOYABLE`,
                },
            },
            {
                type: 'divider',
            },
            {
                type: 'section',
                text: {
                    type: 'mrkdwn',
                    text: `Customer: *${data.customer}*\n\n Email: *${data.email}*\n\n Company: *${data.company}*\n\n Rating: *${data.rating}*\n\n Feedback: *${data.feedback}*`,
                },
            },
        ],
    }

    fetch(webHookURL, {
        method: 'post',
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
        body: JSON.stringify(message),
    })
        .then((res) => console.log(res))
        .catch((error) => console.log(error))
}
