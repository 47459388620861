import { ChangeEvent, FC, useEffect, useState } from 'react'
import { DotLoader } from 'react-spinners'
import { Toast } from '../../helpers/Toast'
import { clientUpload } from '../../lib/upload/clientUpload'
import BrandingService from '../../../services/BrandingService'
import { useAuth } from 'widgets-base'
import { IAsset } from '../../modules/branding/IAssets'

interface Props {
    onChangeAsset?: (asset: IAsset) => void;
}

export const AssetsImages: FC<Props> = ({ onChangeAsset }) => {
    const { currentUser, makeAssetUrl } = useAuth();
    const [loading, setLoading] = useState<boolean>(false);
    const [assets, setAssets] = useState<IAsset[]>([]); 

    useEffect(() => {
        if (currentUser) {
            setLoading(true);
            BrandingService.getAssets()
                .then(assets => {
                    setAssets(assets);
                    setLoading(false);
                })
                .catch((error) => {
                    Toast(error.response.data.message, 'error')
                    setLoading(false);
                });
        }
    }, [setAssets, currentUser])

    async function onAddImage(event: ChangeEvent<HTMLInputElement>): Promise<void> {
        const file = event.target.files[0]
        setLoading(true)
        try {
            const asset = await clientUpload(file);
            setAssets([asset, ...assets]);
        } 
        catch (error) {
            console.log(error);
            Toast('Error uploading image', 'error');
        }
        setLoading(false)
    }

    const downloadLinkStyles = {
        color: '#0933ee',
        cursor: 'pointer',
        fontWeight: 'bold'
    };

    if (loading) {
        return (
            <div className="assets_list">
                <DotLoader color="#639cf1" />
            </div>
        );
    }

    if (!assets.length) {
        return (
            <div className="assets_list">
                {loading ? (
                    <div style={{ marginBottom: '45px' }}>
                        <DotLoader color="#639cf1" />
                    </div>
                ) : (
                    <img src="/images/meditation.png" alt="no data" />
                )
                }
                <input
                    type="file"
                    id="uploadImage"
                    accept="images/*;capture=camera"
                    onChange={onAddImage}
                />
            </div>
        );
    } 
    else {
        return (
            <>
                <input
                    type="file"
                    style={{ display: 'none' }}
                    id="uploadImage"
                    accept="images/*;capture=camera"
                    onChange={onAddImage}
                />
                <p style={{ marginBottom: '10px' }}><label htmlFor="uploadImage" style={downloadLinkStyles}>or upload</label></p>
                {loading ? <div className='assets_list'><DotLoader color="#639cf1" /></div> : <div className={'assets_grid_list'}>
                    {assets.map((asset: IAsset) => {
                        return (
                            <img
                                key={asset._id}
                                src={asset.fileUrl || (asset._id && makeAssetUrl(asset._id))}
                                alt={asset.fileName}
                                onClick={() => {
                                    onChangeAsset(asset);
                                }}
                            />
                        )
                    })}
                </div>}
            </>
        );
    }
}

