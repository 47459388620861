import { makeStyledElement, useWidget, useRenderContext, usePageTheme, useDragContext, IWidgetType, makeColorProperty, stdFontFamily, stdFontSize, stdLineHeight, stdTextAlignment, stdElementAlignment, stdPadding, stdMargin } from "widgets-base";
import { extractTagAndContent } from "../lib/text";

const elements = {
    p: makeStyledElement("p"),
    b: makeStyledElement("b"),
    h1: makeStyledElement("h1"),
    h2: makeStyledElement("h2"),
    h3: makeStyledElement("h3"),
};

function Static() {

    const { widget, properties, expandedProperties, parentWidget } = useWidget();
    const { renderContext } = useRenderContext();
    const { pageTheme } = usePageTheme();
    
    const text = expandedProperties?.content as string;
    const { tag, content } = extractTagAndContent(text);
    const Element = elements[tag];

    return (
        <label htmlFor={parentWidget?.id}>
            <Element
                widgetId={widget.id}
                elementName={tag}
                variantName={properties.variant || "default"}
                pageTheme={pageTheme}
                properties={expandedProperties}
                renderContext={renderContext}
                >
                <span dangerouslySetInnerHTML={{ __html: content }} />
            </Element>
        </label>
    );
}

function Editor() {

    const { widget, expandedProperties, properties } = useWidget();
    const { renderContext } = useRenderContext();
    const { pageTheme } = usePageTheme();
    const { dragProps, setRef, onSelect } = useDragContext();

    const text = expandedProperties?.content as string;
    const { tag, content } = extractTagAndContent(text);
    const Element = elements[tag];

    return (
        <label>
            <Element
                {...dragProps}
                ref={setRef}
                onClick={event => {
                    event.stopPropagation();
                    event.preventDefault();
                    onSelect();
                }}
                widgetId={widget.id}
                elementName={tag}
                variantName={properties.variant || "default"}
                pageTheme={pageTheme}
                properties={expandedProperties}
                renderContext={renderContext}
                >
                <span dangerouslySetInnerHTML={{ __html: content }} />
            </Element>
        </label>
    );
}

const widgetType: IWidgetType = {
    name: 'Label',
    Static: Static,
    Preview: Static,
    Editor: Editor,
    Template: Static,
    affinity: [ "field" ], // A label can only be placed in a field.
    properties: [
        {
            id: "content",
            name: "Content",
            type: "rich-text",
            required: true,
            defaultValue: "",
            orientation: "vertical",
        },
    ],
    propertyCategories: [
        {
            name: "Colors",
            properties: [
                makeColorProperty("normal", "color", "Color"),
            ],
        },
        {
            name: "Design",
            properties: [
                stdFontFamily,
                stdFontSize,
                stdLineHeight,
                stdTextAlignment,
            ],
        },
        {
            name: "Placement",
            properties: [
                stdElementAlignment,
                stdPadding,
                stdMargin,
            ],
        },
    ],
};

export default widgetType;
