import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
        margin-right: 10px;
        transition: transform 0.2s ease-in-out;
        &:hover {
            transform: scale(1.2);
        }
    }
`

const SaveContainer: FC<{ children: ReactNode }> = ({ children }) => {
    return <Container>{children}</Container>
}

export default SaveContainer
