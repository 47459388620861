import { Button, FormControlLabel, Radio, RadioGroup, Switch } from '@mui/material';
import _ from "lodash";
import { PreviewMultiple } from '../preview';
import { useState } from 'react';
import { SketchPicker } from 'react-color';
import { ProgressButton } from './components/progress-button';

function ModalColorPicker({ propertyValue, setPropertyValue, onCloseModal }) {

    return (
        <div
            className="absolute select-none"
            style={{
                top: "34px",
                zIndex: "100",
            }}
        >
            <div
                className="fixed"
                style={{
                    left: "0",
                    right: "0",
                    top: "0",
                    bottom: "0",
                }}
                onClick={event => {
                    onCloseModal();
                    event.preventDefault();
                }}
            />
            <SketchPicker
                color={propertyValue}
                onChangeComplete={color => {
                    setPropertyValue(color.hex);
                }}
            />
        </div>
    );
}

//
// An editor for color properties.
//
function ColorEditor({ propertyValue, setPropertyValue }) { //todo: Be nice to share this code.

    const [openColorPicker, setOpenColorPicker] = useState<boolean>(false);

    return (
        <>
            <div
                className="cursor-pointer"
                style={{
                    width: "30px",
                    height: "30px",
                    backgroundColor: propertyValue,
                    border: "1px solid #dfe2f2",
                    borderRadius: "8px",
                }}
                onClick={() => setOpenColorPicker(!openColorPicker)}
            />

            {openColorPicker
                && <ModalColorPicker
                    propertyValue={propertyValue}
                    setPropertyValue={value => {
                        setPropertyValue(value);
                    }}
                    onCloseModal={() => {
                        setOpenColorPicker(false);
                    }}
                />
            }
        </>
    );
}

//
// Choose a color scheme.
//
export function ChooseColors({ onGenerateColors, onGenerateFromColor, themes, selectedThemeIndex, setSelectedThemeIndex, campaign, generating }) {

    const [useBrandColor, setUseBrandColor] = useState(false);
    const [brandColor, setBrandColor] = useState("blue");

    function onChange(event: React.ChangeEvent<HTMLInputElement>) {
        setUseBrandColor(event.target.checked);

        if (event.target.checked) {
            onGenerateFromColor(brandColor);
        }
        else {
            onGenerateColors();
        }
    };

    function onChangeColor(color: string) {
        setBrandColor(color);

        onGenerateFromColor(color);
    }    

    return (
        <div
            className="flex flex-col w-full items-center overflow-hidden"
            >
            <div 
                className="border border-gray-100 bg-slate-50 flex items-stretch justify-between gap-5 px-16 py-3.5 rounded-full border-solid"
                >
                <div className="justify-center text-stone-950 text-center text-sm font-medium leading-[56.28px] tracking-wide uppercase self-center grow whitespace-nowrap my-auto">
                    AI AUTO GENERATION
                </div>
                <Switch 
                    checked={useBrandColor}
                    onChange={onChange}

                    />
                <div className="justify-center text-stone-950 text-center text-sm font-medium leading-[56.28px] tracking-wide uppercase self-center grow whitespace-nowrap my-auto">
                    Set brand colours
                </div>

                {useBrandColor
                    && <ColorEditor
                        propertyValue={brandColor}
                        setPropertyValue={onChangeColor}
                    />
                }
            </div>
    
            <PreviewMultiple
                themes={themes}
                selectedThemeIndex={selectedThemeIndex}
                generating={generating}
                campaign={campaign}
                setSelectedThemeIndex={setSelectedThemeIndex}
                fontSize="4px"
                height="600px"
                width="500px"
                />

            {/* <div className="flex flex-col mt-2 ml-2">
                <ColorSchemeSettings
                    onGenerateColors={onGenerateColors}
                    onGenerateFromColor={onGenerateFromColor}
                    />

                <div className="flex flex-row mt-2">
                    
                    <Button
                        variant="contained"
                        onClick={onNext}
                        >
                        Continue
                    </Button>

                    <Button
                        sx={{ ml: 1 }}
                        variant="outlined"
                        onClick={onBack}
                        >
                        Back
                    </Button>

                    <ProgressButton
                        onClick={onGenerateColors}
                        generating={generating}
                        >
                        Generate again
                    </ProgressButton>
                </div>
            </div> */}
        </div>
    );
}

