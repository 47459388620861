import React from 'react'
import { useLocation } from 'react-router-dom'
import ResetPassword from './reset-password';

export const AuthAction = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);  
    const mode = queryParams.get('mode');
    const oobCode = queryParams.get('oobCode');

    return (
        <div>
            {/*todo: {mode === 'recoverEmail' && <p>Recover Email</p>} */}

            {/*todo: {mode === 'verifyEmail' && (
                <VerifyEmail actionCode={oobCode} continueUrl={continueUrl} />
            )} */}

            {mode === 'resetPassword' && <ResetPassword actionCode={oobCode} />}
        </div>
    )
}
