import { FC } from 'react'
import { Helmet } from 'react-helmet-async'

interface Props {
    title: string
    description?: string
    image?: string
    hostUrl?: string
}

export const SEOLive: FC<Props> = ({ title, description, image, hostUrl }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <link rel="canonical" href="https://www.deployable.co/" />
            <meta property="og:title" content="quicklinks" />
            <meta name="description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:url" content={hostUrl} />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:description" content={description} />
            <meta property="twitter:image" content={image} />
            <meta property="twitter:url" content={hostUrl} />
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
        </Helmet>
    )
}
