import { Checkbox, FormControlLabel } from '@mui/material'
import React, { useState } from 'react'
import styled from 'styled-components'
import { useForm } from '../../../../../builder/context/form-state-old'
import { IOption } from '../../../../modules/widgets/forms/ICheckbox'
import { useWidget } from 'widgets-base'

interface Props {
}

interface Options {
    label: string
    value: string
    state: boolean
}

export const CheckboxField = ({}: Props) => {
    
    const { widget } = useWidget();
    const { getFieldValue, setFieldValue } = useForm();
    
    const required = widget.settings.required
    const name = widget.settings.name
    const label = widget.settings.label
    const [checkedState, setCheckedState] = useState<Options[]>(widget.settings.options)

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, index: number): void => {
        const checkboxIndex = checkedState.findIndex((check, ind) => ind === index)
        if (checkboxIndex === -1) return
        const updatedCheckbox = {
            ...checkedState[checkboxIndex],
            state: !checkedState[checkboxIndex].state,
        }

        const newCheckboxes = [
            ...checkedState.slice(0, checkboxIndex),
            updatedCheckbox,
            ...checkedState.slice(checkboxIndex + 1),
        ]

        setCheckedState(newCheckboxes)

        // extract options and create flatten string suitable for csv
        let data: string[] = []
        newCheckboxes.forEach((checkbox) => {
            if (checkbox.state) {
                data.push(checkbox.value)
            }
        })

        setFieldValue(name, data);
    }

    const width = widget.appearanceSettings.width + '%'
    const font = widget.appearanceSettings.font || { family: 'Open Sans' }
    const modFontFamily = font.family?.replace(/\s/g, '+')
    const paddingTop = widget.appearanceSettings.paddingTop / 10 + 'em'
    const paddingBottom = widget.appearanceSettings.paddingBottom / 10 + 'em'
    const paddingLeft = widget.appearanceSettings.paddingLeft / 10 + 'em'
    const paddingRight = widget.appearanceSettings.paddingRight / 10 + 'em'
    const marginTop = widget.appearanceSettings.marginTop / 10 + 'em'
    const marginBottom = widget.appearanceSettings.marginBottom / 10 + 'em'
    const marginLeft = widget.appearanceSettings.marginLeft / 10 + 'em'
    const marginRight = widget.appearanceSettings.marginRight / 10 + 'em'

    const borderTopLeftRadius = widget.appearanceSettings.borderTopLeftRadius / 10 + 'em'
    const borderTopRightRadius = widget.appearanceSettings.borderTopRightRadius / 10 + 'em'
    const borderBottomLeftRadius = widget.appearanceSettings.borderBottomLeftRadius / 10 + 'em'
    const borderBottomRightRadius = widget.appearanceSettings.borderBottomRightRadius / 10 + 'em'
    const borderTopWidth = widget.appearanceSettings.borderTopWidth / 10 + 'em'
    const borderBottomWidth = widget.appearanceSettings.borderBottomWidth / 10 + 'em'
    const borderLeftWidth = widget.appearanceSettings.borderLeftWidth / 10 + 'em'
    const borderRightWidth = widget.appearanceSettings.borderRightWidth / 10 + 'em'
    const borderWidth = borderTopWidth && borderRightWidth && borderBottomWidth && borderLeftWidth
    const alignment = widget.appearanceSettings.alignment
    const fontSize = widget.appearanceSettings.font.size
        ? widget.appearanceSettings.font.size + 'px'
        : '14px'
    const fontUrl = widget.appearanceSettings?.font?.url
    const spacing = widget.appearanceSettings.spacing / 10 + 'em'
    const columns = widget.appearanceSettings.columns
    const weight = Number(widget?.appearanceSettings?.font?.weight) || 400

    const color = Array.isArray(widget.colourSettings['text colour'])
        ? widget.colourSettings['text colour'][0]
        : widget.colourSettings['text colour']
    const borderColor = Array.isArray(widget.colourSettings['border colour'])
        ? widget.colourSettings['border colour'][0]
        : widget.colourSettings['border colour']
    const backgroundColor = Array.isArray(widget.colourSettings['background colour'])
        ? widget.colourSettings['background colour'][0]
        : widget.colourSettings['background colour']
    const labelColour = Array.isArray(widget.colourSettings['label colour'])
        ? widget.colourSettings['label colour'][0]
        : widget.colourSettings['label colour']
    const iconColour = Array.isArray(widget.colourSettings['icon colour'])
        ? widget.colourSettings['icon colour'][0]
        : widget.colourSettings['icon colour']

    const iconSize = widget.appearanceSettings.iconSize / 10 + 'em'

    return (
        <Wrapper
            align={alignment}
            marginTop={marginTop}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            marginRight={marginRight}
        >
            <Container
                width={width}
                paddingTop={paddingTop}
                paddingBottom={paddingBottom}
                paddingLeft={paddingLeft}
                paddingRight={paddingRight}
            >
                <div
                    style={{
                        gridGap: spacing,
                        width: '100%',
                        border: `${borderWidth} solid ${borderColor}`,
                        backgroundColor,
                        paddingTop,
                        paddingBottom,
                        paddingLeft,
                        paddingRight,
                        borderTopLeftRadius,
                        borderTopRightRadius,
                        borderBottomLeftRadius,
                        borderBottomRightRadius,
                    }}
                >
                    <label
                        style={{
                            width: '100%',
                            fontWeight: weight,
                            marginBottom: '1em',
                        }}
                    >
                        {label}
                    </label>

                    <div className="checkbox_list">
                        {checkedState.map((option: IOption, index: number) => {
                            return (
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox
                                            checked={option.state}
                                            onChange={(e) => handleCheckboxChange(e, index)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            required={required}
                                        />
                                    }
                                    label={option.label}
                                />
                            )
                        })}
                    </div>
                </div>
            </Container>
        </Wrapper>
    )
}

const Wrapper = styled.div<{
    align: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
}>`
    display: flex;
    align-items: ${(props) =>
        props.align === 'left' ? 'flex-start' : props.align === 'center' ? 'center' : 'flex-end'};
    width: 100%;
    cursor: pointer;
    flex-direction: column;
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
`
const Container = styled.div<{
    width: string
    paddingTop: string
    paddingBottom: string
    paddingLeft: string
    paddingRight: string
}>`
    position: relative;
    width: ${(props) => props.width};

    .MuiFormControlLabel-root {
        align-items: flex-end;
        & > span {
            padding: 0;
        }
        svg {
            width: 20px;
        }
    }

    .checkbox_list {
        display: flex;
        flex-direction: column;
        margin-top: 5px;
        margin-left: 30px;

        .MuiTypography-root {
            font-size: 12px;
        }
    }
`
