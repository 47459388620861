import { IWidget, IView, ICampaign } from 'widgets-base';
import { getWidgetType } from '../builder/widget/widget-types';
import _ from "./lodash";
import { v4 as uuid } from "uuid";

//
// Fix properties since the direction property was changed to support responsive design in statically exported pages.
//
function fixDirection(properties: any): void {
    if (properties.direction !== undefined) {
        if (properties.direction === "row") {
            properties.flexDirection = "row";
        }
        else if (properties.direction === "col") {
            properties.flexDirection = "column";
        }

        delete properties.direction;
    }

    if (properties.small !== undefined) {
        fixDirection(properties.small);
    }
}

//
// Converts a v2 style widget to a new style one.
//
export function convertWidgetFrom4(widget: IWidget): IWidget | undefined {

    if (widget.xtype === "video") {
        widget.properties.videoUrl = (widget.properties as any).src;
    }

    if (widget.xtype === "image") {
        if ((widget.properties as any).src) {
            widget.properties.image = {
                url: (widget.properties as any).src,
                width: widget.properties.width && parseFloat(widget.properties.width.toString()),
                height: widget.properties.height && parseFloat(widget.properties.height.toString()),
                aspectRatio: widget.properties.aspectRatio,
            };
        }

        if ((widget.properties as any).srcId) {
            widget.properties.image = {
                assetId: (widget.properties as any).srcId,
                width: widget.properties.width && parseFloat(widget.properties.width.toString()),
                height: widget.properties.height && parseFloat(widget.properties.height.toString()),
                aspectRatio: widget.properties.aspectRatio,
            };
        }
    }

    if (widget.xtype === "group") {
        if ((widget.properties as any).src) {
            widget.properties.backgroundImage = {
                url: (widget.properties as any).src,
                width: widget.properties.width && parseFloat(widget.properties.width.toString()),
                height: widget.properties.height && parseFloat(widget.properties.height.toString()),
                aspectRatio: widget.properties.aspectRatio,
            };
        }

        if ((widget.properties as any).srcId) {
            widget.properties.backgroundImage = {
                assetId: (widget.properties as any).srcId,
                width: widget.properties.width && parseFloat(widget.properties.width.toString()),
                height: widget.properties.height && parseFloat(widget.properties.height.toString()),
                aspectRatio: widget.properties.aspectRatio,
            };
        }
    }

    if (widget.xtype === "fill") {
        return {
            id: widget.id,
            xtype: "group",
            properties: {
                flexGrow: "1",
            },
        };
    }

    let children: IWidget[] = [];
    let widgets: IWidget[] = [];
    if (widget.children) {
        children = widget.children.map(widget => convertWidgetFrom4(widget))
    }
    
    if ((widget as any).widgets) {
        widgets = (widget as any).widgets.map(widget => convertWidgetFrom4(widget))
    }

    let properties = undefined;
    if (widget.properties) {
        properties = _.cloneDeep(widget.properties);
        fixDirection(properties);
    }

    return {
        ...widget,
        properties,
        children,
        widgets
    };
};

//
// Converts v2 style view to a new style one.
//
function convertViewFrom4(view: IView): IView {
    return {
        ...view,
        widgets: 
            view.widgets.map(widget => convertWidgetFrom4(widget))
            .filter(widget => widget),
    };
}

//
// Converts a v3 style widget to a new style one.
//
function convertWidgetFrom3(widget: IWidget): IWidget | undefined {

    let children: IWidget[] = [];
    if (widget.children) {
        children = widget.children.map(widget => convertWidgetFrom3(widget))
    }
    
    if ((widget as any).widgets) {
        children = (widget as any).widgets.map(widget => convertWidgetFrom3(widget))
    }

    children = children.filter(widget => widget);

    let type = widget.type;
    let xtype = widget.xtype;
    if (xtype === undefined) {
        if (type.startsWith("x")) {
            type = type.substring(1);
        }

        const newWidgetType = getWidgetType(type);
        if (newWidgetType !== undefined) {
            xtype = type;
            type = undefined;
        }
    }
    else {
        type = undefined;
    }

    let properties = undefined;
    if (widget.properties) {
        properties = _.cloneDeep(widget.properties);
        fixDirection(properties);
    }

    return {
        ...widget,
        properties,
        type,
        xtype,
        children,
    };
};

//
// Converts an old style view to a new style one.
//
function convertViewFrom3(view: IView): IView {
    return {
        ...view,
        widgets: 
            view.widgets.map(widget => convertWidgetFrom3(widget))
            .filter(widget => widget),
    };
}

//
// Gets the version number of a campaign.
//
function getCampaignVersion(campaign: ICampaign): number {
    if (typeof campaign.version === "number") {
        return campaign.version;
    }

    if (campaign.version === "2" || campaign.version === "2.0") {
        return 2;
    }

    return 1;
}

//
// Converts an old style campaign to a new style one.
//
export function convertCampaign(campaign: ICampaign): ICampaign {

    const version = getCampaignVersion(campaign);
    if (version > 4) {
        //
        // No conversion.
        //
        console.log(`No conversion necessary for campaign ${campaign._id}.`);
        return campaign;
    }
    else if (version === 4) {
        //
        // Convert a version 4.
        //
        console.log(`Converting campaign ${campaign._id} from version 4.`);

        // Converts widget type to "xtype".
        const convertedCampaign: ICampaign = {
            ...campaign,
            views: campaign.views.map(view => convertViewFrom4(view)),
            version: 4,
            oldVersion: version,
        };
        return convertedCampaign;
    }
    else if (version === 2) {
        //
        // Convert a version 2.
        //
        console.log(`Converting campaign ${campaign._id} from version 2.`);

        // Converts widget type to "xtype".
        const convertedCampaign: ICampaign = {
            ...campaign,
            views: campaign.views.map(view => convertViewFrom3(view)),
            version: 4,
            oldVersion: version,
        };
        return convertedCampaign;
    }
    else if (version === 3) {
        //
        // Convert a version 3 to version 4.
        //
        console.log(`Converting campaign ${campaign._id} from version 3 to 4.`);

        // Converts widget type to "xtype".
        const convertedCampaign: ICampaign = {
            ...campaign,
            views: campaign.views.map(view => convertViewFrom3(view)),
            version: 4,
            oldVersion: version,
        };
        return convertedCampaign;
    }
    else {
        //
        // Convert an older version to version 4.
        //
        console.log(`Converting campaign ${campaign._id} from version ${version} to 4.`);

        //
        // An old campaign.
        // Need to wrap it to fix the styling.
        //
        const convertedCampaign = {
            ...campaign,
            views: campaign.views.map(view => {
                const newView: IView = {
                    ...view,
                    widgets: [{
                        id: `root-widget-group-${uuid()}`,
                        xtype: "group",
                        name: "Page container",
                        properties: {
                            maxWidth: "512px",
                            width: "100%",
                            marginLeft: "auto",
                            marginRight: "auto",
                            flexDirection: "column",
                        },
                        children: view.widgets,
                    }],
                };
                return newView;
            }),
            version: 4,
            oldVersion: version,
        };
        return convertedCampaign;
    }
}
