import styled from 'styled-components'

export const BillingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 5%;
    margin-bottom: 50px;

    & > h1 {
        font-weight: 600;
        font-size: 32px;
        width: 100%;
        box-sizing: content-box;
        line-height: 38px;
        color: #3ae29b;
        margin-bottom: 7px;
        text-align: left;
    }

    & > span {
        font-size: 14px;
        color: #cacaca;
        width: 100%;
        text-align: left;
        margin-bottom: 2%;
    }
`

export const OfferList = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(357px, 1fr));
    justify-items: stretch;
    padding: 2px 5px 5px 2px;
    grid-gap: 28px;
    justify-content: space-between;
    margin-bottom: 50px;
    margin-top: 28px;

    .offer {
        background-color: #fafaff;
        border: 1px solid #dfe2f2;
        border-radius: 27px;
        padding: 35px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: space-evenly;
        box-shadow: 0px 15px 41px #dfe2f2;

        & > button {
            height: 47px;
            text-transform: uppercase;
        }

        & > h1 {
            font-weight: 600;
            font-size: 40px;
            line-height: 100%;
            text-align: center;
            color: #3c4b61;
        }
        & > span {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            color: #717f95;
        }

        .offer__price {
            font-weight: 500;
            font-size: 49px;
            line-height: 110%;
            letter-spacing: -0.025em;
            color: #6478f9;
            text-align: center;
            margin: 20px 0;
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 10px;

            & > span {
                font-weight: 500;
                font-size: 14px;
                line-height: 99.33%;
                color: #999fae;
            }
        }

        .offer__details {
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding-bottom: 50px;
            margin: 0 auto;
            max-width: 300px;

            & > li {
                display: flex;
                align-items: center;
                gap: 15px;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #3c4b61;
            }
        }
    }

    .offer-premium {
        padding: 35px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        background: #6478f9;
        border: 1px solid #6478f9;
        box-shadow: 0px 15px 41px rgba(100, 120, 249, 0.53);
        border-radius: 27px;
        color: #fff;

        & > button {
            height: 47px;
            text-transform: uppercase;
        }

        & > h1 {
            font-weight: 600;
            font-size: 40px;
            line-height: 100%;
            text-align: center;
        }
        & > span {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
        }

        .offer__price {
            font-weight: 500;
            font-size: 49px;
            line-height: 110%;
            letter-spacing: -0.025em;
            text-align: center;
            margin: 20px 0;
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 10px;

            & > span {
                font-weight: 500;
                font-size: 14px;
                line-height: 99.33%;
            }
        }

        .offer__details {
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding-bottom: 10px;
            margin: 0 auto;
            max-width: 300px;

            & > li {
                display: flex;
                align-items: center;
                gap: 15px;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
            }
        }
    }

    @media (max-width: 550px) {
        justify-items: center;
    }
`

export const SuccessContainer = styled.div`
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
        color: #3ae29b;
        font-size: 36px;
        font-weight: bolder;
        margin: 25px;
    }

    p {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
        color: #8e8e8e;
        text-align: center;
    }
    span {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;
        color: #8e8e8e;
    }
    a {
        color: #fff;
        display: flex;
        align-items: center;
    }
`

export const BillingDetailsContainer = styled.div`
    background: #fafaff;
    border: 1px solid #dfe2f2;
    border-radius: 12px;
    width: 320px;
    margin-right: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .title {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #717f95;
    }

    & > h3 {
        font-weight: 600;
        font-size: 28px;
        line-height: 100%;
        color: #326400;
    }

    .stripe {
        display: flex;
        flex-direction: column;
        gap: 7px;
        font-size: 16px;

        span {
            color: #8e8e8e;
            font-size: 13px;
        }
    }

    @media (max-width: 550px) {
        margin-bottom: 20px;
    }
`

export const SliderContainer = styled.div`
    max-width: 100vw;
    width: 100%;
    margin-bottom: 50px;
    padding: 20px;

    & > h1 {
        font-size: 36px;
        font-weight: bold;
        margin-bottom: 20px;
        text-align: center;
    }

    .swiper {
        width: 100%;
        height: 400px;
    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        width: 400px !important;
    }

    .slide {
        background: #fff;
        border: 1px solid #dfe2f2;
        border-radius: 12px;
        margin-right: auto;
        width: 400px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;
        box-shadow: 0px 15px 41px #dfe2f2;
        transition: all 0.2s ease-in-out;

        & > span {
            font-size: 14px;
            color: #757575;
            line-height: 1.2;
            margin-bottom: 15px;
            text-align: justify;
        }

        .slide__footer {
            display: flex;
            gap: 10px;

            .user-info {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: flex-start;
                gap: 5px;

                & > h3 {
                    font-size: 18px;
                    font-weight: bold;
                    color: #6478f9;
                }
                & > span {
                    font-size: 13px;
                    color: #bab8ff;
                }
            }
        }
    }
`
