import { FC, useState, MouseEvent } from 'react'
import styled from 'styled-components'
import { Divider, Menu, MenuItem, Tooltip } from '@mui/material'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined'
import { capitalizeFirstLetter } from '../../../helpers/capitalizeFirstLetter'
import AddElementButton from '../../EditMode/Settings/AddElementButton'
import FieldSettingModal from './FieldSettingModal'
import { InputModel } from '../../../models/widgets/forms/InputModel'
import { TextareaModel } from '../../../models/widgets/forms/TextareaModel'
import { CheckboxModel } from '../../../models/widgets/forms/CheckboxModel'
import { UploadModel } from '../../../models/widgets/forms/UploadModel'
import { RatingModel } from '../../../models/widgets/forms/RatingModel'
import { SelectModel } from '../../../models/widgets/forms/SelectModel'
import { RadioModel } from '../../../models/widgets/forms/RadioModel'
import { DatePickerModel } from '../../../models/widgets/forms/DatePickerModel'
import { useWidget, IWidget } from 'widgets-base'
import { WidgetContextProvider } from '../../../../builder/context/widget'

interface Props {
}

const AddFormFieldSection: FC<Props> = () => {

    const { widget, updateWidget } = useWidget();
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [activeField, setActiveField] = useState<IWidget | null>(null)

    const open = Boolean(anchorEl)
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleAddFormField = (type: string) => () => {
        let newField: IWidget
        switch (type) {
            case 'input box':
                newField = new InputModel('Enter email', '', 'Enter email', 40, 40, 40, 40)
                break
            case 'text area':
                newField = new TextareaModel()
                break
            case 'select':
                newField = new SelectModel()
                break
            case 'radio':
                newField = new RadioModel()
                break
            case 'checkbox':
                newField = new CheckboxModel('I Agree:', [
                    {
                        label: 'I am over 18 years old',
                        value: 'I am over 18 years old',
                        state: false,
                    },
                ])
                break
            case 'upload':
                newField = new UploadModel()
                break
            case 'rating':
                newField = new RatingModel()
                break
            case 'datepicker':
                newField = new DatePickerModel()
                break
            default:
                break
        }
        
        updateWidget({
            children: [ ...widget.children, newField ],
        });
        setAnchorEl(null)
    }

    const toggleOpenModal = () => {
        setOpenModal((prev) => !prev)
    }

    const onEditField = (field: IWidget) => () => {
        setActiveField(field)
        setOpenModal(true)
    }

    const onRemoveField = (id: string) => () => {
        const filteredFields = widget.children?.filter((field: IWidget) => field.id !== id)
        updateWidget({
            children: filteredFields,
        });
    }

    return (
        <Container>
            <label>Customize form fields</label>
            <ul className="fields_list">
                {widget.children?.map((field: IWidget) => {
                    return (
                        <WidgetContextProvider key={field.id} widget={field}>
                            <li className="field">
                                <Name>
                                    <LabelOutlinedIcon fontSize="small" />
                                    <p>{capitalizeFirstLetter(field.name)}</p>
                                </Name>
                                <Setting>
                                    <Tooltip arrow placement="bottom" title="Customize field">
                                        <span className="field_setting" onClick={onEditField(field)}>
                                            <SettingsOutlinedIcon />
                                        </span>
                                    </Tooltip>
                                    <Tooltip arrow placement="bottom" title="Remove field">
                                        <span
                                            className="field_remove"
                                            onClick={onRemoveField(field.id)}
                                        >
                                            <DeleteOutlinedIcon />
                                        </span>
                                    </Tooltip>
                                </Setting>
                            </li>
                        </WidgetContextProvider>
                    )
                })}
            </ul>
            <Divider />
            <AddElementButton name="Add new field" onClick={handleClick} />
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                sx={{
                    zIndex: 9999,
                }}
            >
                <MenuItem onClick={handleAddFormField('input box')}>Input box</MenuItem>
                <MenuItem onClick={handleAddFormField('text area')}>Text area</MenuItem>
                <MenuItem onClick={handleAddFormField('select')}>Select</MenuItem>
                <MenuItem onClick={handleAddFormField('radio')}>Radio button</MenuItem>
                <MenuItem onClick={handleAddFormField('checkbox')}>Checkbox button</MenuItem>
                <MenuItem onClick={handleAddFormField('upload')}>Upload button</MenuItem>
                <MenuItem onClick={handleAddFormField('rating')}>Rating</MenuItem>
                <MenuItem onClick={handleAddFormField('datepicker')}>Date picker</MenuItem>
            </Menu>
            {openModal && (
                <WidgetContextProvider key={activeField.id} widget={activeField}>
                    <FieldSettingModal
                        open={openModal}
                        toggleOpen={toggleOpenModal}
                    />
                </WidgetContextProvider>
            )}
        </Container>
    )
}

export default AddFormFieldSection

const Container = styled.div`
    display: flex;
    flex-direction: column;

    & > label {
        font-size: 14px;
        font-weight: bold;
        color: #3c4b61;
        margin-bottom: 10px;
    }

    .fields_list {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 10px;
        margin-bottom: 10px;
    }

    .field {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px;
        background-color: aliceblue;
        border-radius: 8px;

        p {
            font-size: 13px;
            font-weight: bold;
            color: #3c4b61;
        }
    }
`
const Name = styled.div`
    display: flex;
    align-items: center;
    color: #8a8a8a;
    svg {
        margin-right: 5px;
    }
`
const Setting = styled.div`
    display: flex;
    align-items: center;

    .field_setting,
    .field_remove {
        width: 50px;
        cursor: pointer;
        color: #8a8a8a;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.2s ease-in-out;
    }

    .field_remove:hover {
        color: #ff5555;
    }

    .field_setting:hover {
        color: #000;
        transform: rotate(180deg);
    }
`
