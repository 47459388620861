import {nanoid} from 'nanoid'
import {CheckboxModel} from '../../v1/models/widgets/forms/CheckboxModel'
import {FormModel} from '../../v1/models/widgets/forms/FormModel'
import {InputModel} from '../../v1/models/widgets/forms/InputModel'
import {RatingModel} from '../../v1/models/widgets/forms/RatingModel'
import {TextareaModel} from '../../v1/models/widgets/forms/TextareaModel'
import {UploadModel} from '../../v1/models/widgets/forms/UploadModel'
import {AudioFile} from '../../v1/models/widgets/general/AudioFile'
import {BeautyCountdown, Countdown} from '../../v1/models/widgets/general/Countdown'
import {DigitalCard} from '../../v1/models/widgets/general/DigitalCard'
import {Events} from '../../v1/models/widgets/general/Events'
import {Iframe} from '../../v1/models/widgets/general/Iframe'
import {Navbar} from '../../v1/models/widgets/general/Navbar'
import Social from '../../v1/models/widgets/general/Social'
import {Spotify} from '../../v1/models/widgets/general/Spotify'
import {Swiper} from '../../v1/models/widgets/general/Swiper'
import {PollsModel} from '../../v1/models/widgets/polls/PollsModel'
import {ProductModel1} from '../../v1/models/widgets/products/ProductModel1'
import {EventsMode} from '../../v1/modules/widgets/general/IEvents'
import {PollsType} from '../../v1/modules/widgets/polls/IPolls'
import text from "../../widgets/text";
import label from "../../widgets/label";
import form from "../../widgets/form";
import input from "../../widgets/fields/input";
import checkbox from "../../widgets/fields/checkbox";
import fileUpload from "../../widgets/fields/file-upload";
import submitButton from "../../widgets/fields/submit-button";
import button from '../../widgets/button';
import image from '../../widgets/image';
import link from '../../widgets/link';
import video from '../../widgets/video';
import { heroWideTemplate } from '../../sections/hero-wide';
import { heroNarrowTemplate } from '../../sections/hero-narrow';
import { hero1Template } from '../../sections/hero-1';
import { nav1Template } from '../../sections/nav-1';
import { caseStudy } from '../../sections/case-study';
import { stats } from '../../sections/stats';
import { contactUsForm } from '../../sections/contact-us-form';
import { competitionForm } from '../../sections/competition-form';
import { feedbackForm } from '../../sections/feedback-form';
import { uploadForm } from '../../sections/upload-form';
import { subscribeForm } from '../../sections/subscribe-form';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import TableRowsIcon from '@mui/icons-material/TableRows';
import { categories } from '../../sections/sections';
import spinWheel from 'spin-to-win-1';
import scratchie from 'scratchie-1';
import chatbot from 'chatbot-1';
import { IWidget, makePadding, makeBorderWidth, makeBorderRadius } from 'widgets-base'
import { convertWidgetFrom4 } from '../../lib/convert-campaign'

export interface IWidgetTemplate {

    //
    // Template instance for the widget.
    //
    widget: IWidget;

    //
    // Scale to display the widget in the palette..
    //
    scale?: string;

    //
    // The image to use for the widget in the widget palette.
    //
    iconImage?: string;

    //
    // The element to use for the widget in the widget palette.
    //
    iconElement?: JSX.Element;

    //
    // The preview image to use for the widget in the widget palette.
    //
    previewImage?: string;
}

export interface IWidgetCategory {
    //
    // The name of the category.
    //
    name: string

    //
    // The widgets in the category.
    //
    widgets: IWidgetTemplate[];

    //
    // The style for this category.
    // Defaults to col.
    //
    style?: "col" | "row";
}

//
// Converts old style properties to new style.
//
function convertWidgetCategory(widgetCategory: IWidgetCategory): IWidgetCategory {
    return {
        ...widgetCategory,
        widgets: widgetCategory.widgets.map(widget => {
            return {
                ...widget,
                widget: convertWidgetFrom4(widget.widget),
            };
        }),
    };
}

//
// Converts old style properties to new style.
//
function convertWidgetCategories(widgetCategories: IWidgetCategory[]): IWidgetCategory[] {
    return widgetCategories.map(convertWidgetCategory);
}

export const sectionCategories: IWidgetCategory[] = [

    ...convertWidgetCategories(categories),

    // {
    //     name: "Older sections",
    //     widgets: [
    //         { 
    //             widget: nav1Template,
    //             scale: `5px`,
    //         },
    //         { 
    //             widget: hero1Template,
    //             scale: `5px`,
    //         },            

    //         // {
    //         //     template: navbarTemplate,
    //         //     scale: `10px`,
    //         // },
    //         { 
    //             widget: heroWideTemplate,
    //             scale: `5px`,
    //         },
    //         { 
    //             widget: heroNarrowTemplate,
    //             scale: `5px`,
    //         },
    //         { 
    //             widget: caseStudy,
    //             scale: `5px`,
    //         },
    //         { 
    //             widget: stats,
    //             scale: `5px`,
    //         },
    //     ],
    // },

    {
        name: 'Forms', 
        widgets: [
            { 
                widget: contactUsForm,
                scale: "5px",
            },
            { 
                widget: competitionForm,
                scale: "5px",
            },
            { 
                widget: feedbackForm,
                scale: "5px",
            },
            { 
                widget: uploadForm,
                scale: "5px",
            },
            { 
                widget: subscribeForm,
                scale: "5px",
            },
        ],
    },
    {
        name: 'image carousel', 
        widgets: [
            { 
                widget: new Swiper() ,
                previewImage: '/images/widgets/swiper/swiper1.png',
            },
        ],
    },
    {
        name: 'social', 
        widgets: [
            { 
                widget: new Social(),
            }, 
            { 
                widget: new Social(true),
            },
        ],
    },
    {
        name: 'countdown',
        widgets: [
            { 
                widget: new Countdown('countdown'),
            }, 
            { 
                widget: new BeautyCountdown('countdown timer'),
            },
        ],
    },
    {
        name: 'events',
        widgets: [
            { 
                widget: new Events(EventsMode.LARGE),
            }, 
            { 
                widget: new Events(EventsMode.SMALL),
            },
        ],
    },
    // {
    //     name: 'digital card', 
    //     widgets: [
    //         { 
    //             widget: new DigitalCard('digital card') ,
    //             previewImage: '/images/widgets/digital/digital.png',
    //         },
    //     ],
    // },
    {
        name: 'media',
        widgets: [
            { 
                widget: video.template,
                previewImage: '/images/widgets/video.png',
            },
            { widget: new AudioFile() },
            { 
                widget: new Spotify('small'),
                previewImage: '/images/widgets/spotify/spotify-small.png',
            },
            { 
                widget: new Spotify('large') ,
                previewImage: '/images/widgets/spotify/spotify-large.png',
            },
        ],
    },
    // {
    //     name: 'iframe', 
    //     widgets: [
    //         { 
    //             widget: new Iframe(),
    //             previewImage: '/images/widgets/iframe/code.png',
    //         },
    //     ],
    // },
    // {
    //     name: 'forms',
    //     widgets: [
    //         { 
    //             widget: new FormModel(
    //                 'subscribe form',
    //                 [new InputModel('Enter email', '', 'Enter email', 40, 40, 40, 40)],
    //                 '#3690f7',
    //                 '#fcba40',
    //                 '#fff',
    //                 'transparent',
    //                 '#fff',
    //                 30,
    //                 30,
    //                 'BECOME A THERMACELL INSIDER'
    //             ),
    //             previewImage: '/images/widgets/forms/subscribe-form.png',
    //         },
    //         { 
    //             widget: new FormModel(
    //                 'product form',
    //                 [
    //                 new InputModel('First name', '', 'Enter first name', 2, 2, 2, 2, '#fcba40'),
    //                 new InputModel('Last name', '', 'Enter last name', 2, 2, 2, 2, '#fcba40'),
    //                 new InputModel('Email', '', 'Enter email', 2, 2, 2, 2, '#fcba40'),
    //                     new UploadModel(),
    //                     new CheckboxModel('I Agree:', [
    //                         {
    //                             label: 'I am over 18 years old',
    //                             value: 'I am over 18 years old',
    //                             state: false,
    //                         },
    //                         {
    //                             label: 'Have read and understand the T&Cs',
    //                             value: 'Have read and understand the T&Cs',
    //                             state: false,
    //                         },
    //                     ]),
    //                 ],
    //                 '#fff',
    //                 '#fcba40',
    //                 '#000',
    //                 '#fcba40',
    //                 '#fff',
    //                 0,
    //                 0
    //             ),
    //             previewImage: '/images/widgets/forms/product-form.png',
    //         },
    //         { 
    //             widget: new FormModel(
    //                 'feedback form',                
    //                 [new RatingModel(), new TextareaModel()],
    //                 '#74369e',
    //                 '#fff',
    //                 '#fff',
    //                 'transparent',
    //                 'rgba(255, 255, 255, 0.17)',
    //                 10,
    //                 10,
    //                 'We would love to hear about your experience'
    //             ),
    //             previewImage: '/images/widgets/forms/feedback-form.png',
    //         },
    //         { 
    //             widget: new FormModel(
    //                 'draw form',                
    //                 [
    //                     new InputModel('Full name', 'Full name', 'Enter your full name', 40, 40, 40, 40),
    //                     new InputModel('Phone number', 'Phone number', 'Enter your number', 40, 40, 40, 40),
    //                     new InputModel('Email', 'Email', 'Enter your email', 40, 40, 40, 40),
    //                     new TextareaModel('Tell us in 25 words or less', 'Tell us here'),
    //                     new CheckboxModel('I Agree:', [
    //                         {
    //                             label: 'I am over 18 years old',
    //                             value: 'I am over 18 years old',
    //                             state: false,
    //                         },
    //                         {
    //                             label: 'Have read and understand the T&Cs',
    //                             value: 'Have read and understand the T&Cs',
    //                             state: false,
    //                         },
    //                     ]),
    //                 ],
    //                 '#fff',
    //                 '#292929',
    //                 '#000',
    //                 'transparent',
    //                 'rgb(243, 243, 243)',
    //                 30,
    //                 30,
    //                 `5 Night Accommodation
    //                 Merch Pack
    //                 Product for a year
    //                 $1000 Visa Card
    //                 + MORE`,
    //                 10
    //             ),
    //             previewImage: '/images/widgets/forms/draw-form-preview.png',                
    //         },
    //     ],
    // },
    // {
    //     name: 'polls',
    //     widgets: [
    //         { 
    //             widget: new PollsModel(
    //                 `If you didn't make a purchase today, what stopped you?`,
    //                 PollsType.MULTIPLE,
    //                 [
    //                     {id: nanoid(), option: `I don't believe the product will work`, votes: 0},
    //                     {id: nanoid(), option: `I don't understand the product`, votes: 0},
    //                     {id: nanoid(), option: `The product is too expensive`, votes: 0},
    //                     {id: nanoid(), option: `I'm just browsing`, votes: 0},
    //                 ],                
    //                 'multiple polls',
    //                 '#f73b6a',
    //                 ['#2c6efa', '#000', '#0426FF']
    //             ),
    //             previewImage: '/images/widgets/polls/multiple-poll.png',
    //         },
    //         { 
    //             widget: new PollsModel(
    //                 `Are you looking forward to Christmas miracles?`,
    //                 PollsType.DOUBLE,
    //                 [
    //                     {id: nanoid(), option: `YES`, votes: 0},
    //                     {id: nanoid(), option: `NO`, votes: 0},
    //                 ],                
    //                 'double polls',
    //                 '#3b9ef7',
    //                 ['#fff', '#000', '#0426FF']
    //             ),
    //             previewImage: '/images/widgets/polls/double-poll.png',
    //         },
    //     ],
    // },
    // {
    //     name: 'navigation bar', 
    //     widgets: [{ widget: new Navbar() }]
    // },
    // {
    //     name: 'product card', 
    //     widgets: [{ 
    //         widget: new ProductModel1(),
    //         previewImage: '/images/widgets/products/product_card.jpg',
    //     }]
    // },
];

export const elementCategories: IWidgetCategory[] = [
    {
        name: "Elements",
        style: "row",
        widgets: [
            { 
                widget: text.template,
                iconImage: '/icons/widgets/font.svg',
            },
            { 
                widget: {
                    id: `widget-${nanoid()}`,
                    name: "Row",
                    xtype: 'group',
                    properties: {
                        flexDirection: "row",
                        gap: 3,
                        alignSelf: "stretch",
                        justifyContent: "center",
                    },
                    children: [],
                },
                iconElement: <ViewColumnIcon />
            },
            { 
                widget: {
                    id: `widget-${nanoid()}`,
                    name: "Column",
                    xtype: 'group',
                    properties: {
                        flexDirection: "column",
                        gap: 3,
                        alignSelf: "stretch",
                        justifyContent: "flex-start",
                    },
                    children: [],
                },
                iconElement: <TableRowsIcon />
            },
            { 
                widget: button.template,
                iconImage: '/icons/widgets/button.svg',
            },
            { 
                widget: image.template,
                iconImage: '/icons/widgets/picture.svg',
            },
            { 
                widget: link.template,
                iconImage: '/icons/widgets/link.svg',
            },
	        { 
                widget: new Iframe(),
                iconImage: '/icons/widgets/iframe.svg',
            },
            { 
                widget: new Navbar(),
                iconImage: '/icons/widgets/navbar.svg',
            },
            { 
                widget: chatbot.template,
                iconImage: '/icons/widgets/chatbot.svg',
            },
        ],
    },
    {
        name: "Games",
        style: "row",
        widgets: [
            {
                widget: spinWheel.template,
                iconImage: '/icons/widgets/spinwheel.svg',
            },
            {
                widget: scratchie.template,
                iconImage: '/icons/widgets/scratchcard.svg',
            },
        ],
    },
    {
        name: "Forms",
        style: "row",
        widgets: [
            {
                widget: {
                    id: `widget-${nanoid()}`,
                    xtype: 'form',
                    name: "Form",
                    properties: {
                        flexDirection: "column",
                        gap: 3,
                        alignSelf: "center",
                        width: 250,
                        ...makePadding(12),
                    },
                    children: [],
                },
                iconImage: '/icons/widgets/form.svg',
            },
            {
                widget: {
                    id: `widget-${nanoid()}`,
                    name: "Input",
                    xtype: 'field',
                    grouped: true,
                    properties: {
                        fieldName: "<Your field name goes here>",
                        flexDirection: "column",
                        gap: 6,
                        alignSelf: "stretch",
                        justifyContent: "center",
                    },
                    children: [
                        {
                            id: `widget-${nanoid()}`,
                            xtype: 'label',
                            properties: {
                                content: "<p>Your label</p>",
                                alignSelf: "flex-start",
                                fontSize: 8,
                                color: "rgb(17,24,39)",
                                fontWeight: 500,            
                            },
                        },
                        {
                            id: `widget-${nanoid()}`,
                            xtype: 'input',
                            properties: {
                                alignSelf: "stretch",
                                color: "rgb(17, 24, 39)",
                                fontSize: 10,
                                ...makePadding(6),
                                backgroundColor: "rgb(249, 250, 241)",
                                borderColor: "rgb(209, 213, 219)",
                                ...makeBorderWidth(1),
                                ...makeBorderRadius(6),
                            },
                        },
                    ],
                },
                iconImage: '/icons/widgets/input.svg',
            },
            {
                widget: {
                    id: `widget-${nanoid()}`,
                    name: "Text",
                    xtype: 'field',
                    grouped: true,
                    properties: {
                        fieldName: "<Your field name goes here>",
                        inputType: "text",
                        flexDirection: "column",
                        gap: 6,
                        alignSelf: "stretch",
                        justifyContent: "center",
                    },
                    children: [
                        {
                            id: `widget-${nanoid()}`,
                            xtype: 'label',
                            properties: {
                                content: "<p>Your label</p>",
                                alignSelf: "flex-start",
                                fontSize: 8,
                                color: "rgb(17,24,39)",
                                fontWeight: 500,            
                            },
                        },
                        {
                            id: `widget-${nanoid()}`,
                            xtype: 'text-area',
                            properties: {
                                alignSelf: "stretch",
                                color: "rgb(17, 24, 39)",
                                fontSize: 10,
                                ...makePadding(6),
                                backgroundColor: "rgb(249, 250, 241)",
                                borderColor: "rgb(209, 213, 219)",
                                ...makeBorderWidth(1),
                                ...makeBorderRadius(6),
                            },
                        },
                    ],
                },
                iconImage: '/icons/widgets/text area.svg',
            },
            {
                widget: {
                    id: `widget-${nanoid()}`,
                    name: "Checkbox",
                    xtype: 'field',
                    grouped: true,
                    properties: {
                        fieldName: "<Your field name goes here>",
                        flexDirection: "row",
                        gap: 6,
                        alignSelf: "flex-start",
                        justifyContent: "flex-start",
                        ...makePadding(12),
                    },
                    children: [
                        {
                            id: `widget-${nanoid()}`,
                            xtype: 'checkbox',
                            properties: {
                                alignSelf: "center",
                            },
                        },
                        {
                            id: `widget-${nanoid()}`,
                            xtype: 'label',
                            properties: {
                                content: "<p>Your label</p>",
                                alignSelf: "center",
                                fontSize: 8,
                                color: "rgb(17,24,39)",
                                fontWeight: 500,            
                            },
                        },
                    ],
                },
                iconImage: '/icons/widgets/checkbox.svg',
            },
            {
                widget: {
                    id: `widget-${nanoid()}`,
                    name: "Upload button",
                    xtype: 'field',
                    grouped: true,
                    properties: {
                        fieldName: "<Your field name goes here>",
                        flexDirection: "column",
                        gap: 6,
                        alignSelf: "stretch",
                        justifyContent: "center",
                    },
                    children: [
                        {
                            id: `widget-${nanoid()}`,
                            xtype: 'label',
                            properties: {
                                content: "<p>Your label</p>",
                                alignSelf: "flex-start",
                                fontSize: 8,
                                color: "rgb(17,24,39)",
                                fontWeight: 500,
                            },
                        },
                        {
                            id: `widget-${nanoid()}`,
                            xtype: 'file-upload',
                            properties: {
                                required: true,
                                acceptedFiles: "image",
                                alignSelf: "stretch",
                                justifyContent: "center",
                                ...makeBorderRadius(40),
                                ...makePadding(10),
                                marginLeft: 6,
                                marginRight: 6,
                                marginTop: 16,
                                marginBottom: 6,
                                color: "white",
                                backgroundColor: "#292929",
                                hover: {
                                    backgroundColor: "#3E3E3E",
                                },
                            },
                            children: [
                                {
                                    id: `widget-${nanoid()}`,
                                    xtype: 'text',
                                    properties: {
                                        content: "<p>Upload file</p>",
                                    },
                                },
                            ],
                        },
                    ],
                },
                iconImage: '/icons/widgets/upload.svg',
            },
            {
                widget: submitButton.template,
                iconImage: '/icons/widgets/submit button.svg',
            },
        ],
    },
];

