import { Divider } from '@mui/material'
import { ChangeEvent, FC, useState } from 'react'
import validator from 'validator'
import { checkInputValid } from '../../../../helpers/validation'
import { InputBox } from '../../../common/InputBox'
import { WidgetSettingContainer } from '../../../Design/WidgetSettingContainer'
import { BorderRadius, Colors, Height, Margins, Paddings, Width } from '../../../EditMode/Design'
import { useWidget } from 'widgets-base'

interface Props {
}

const IframeSetting: FC<Props> = () => {

    const { widget, updateWidget } = useWidget();
    const [errors, setErrors] = useState({})

    const handleNameError = (name: string) => () => {
        if (!checkInputValid(name, 'alphanumericWithDashSpace')) {
            const nameError = { name: 'Please enter valid name.' }
            setErrors(nameError)
        } else {
            setErrors({})
        }
    }

    const handleWebsiteError = (website: string) => () => {
        if (!validator.isURL(website, { protocols: ['https'], require_protocol: true })) {
            const websiteError = { website: 'Please enter valid website with https://' }
            setErrors(websiteError)
        } else {
            setErrors({})
        }
    }

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        updateWidget({
            name: e.target.value,
        });
    }

    const handleWebsiteChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = 
        updateWidget({
            settings: {
                ...widget.settings,
                src: e.target.value,
            },
        });
    }

    return (
        <WidgetSettingContainer padding={18}>
            <InputBox
                label="Frame title"
                name="name"
                type="text"
                onChange={handleNameChange}
                onBlur={handleNameError(widget?.name)}
                value={widget?.name}
                error={errors['name']}
            />
            <InputBox
                label="URL"
                name="src"
                type="text"
                onChange={handleWebsiteChange}
                onBlur={handleWebsiteError(widget?.settings?.src)}
                error={errors['website']}
                value={widget?.settings?.src}
            />
            <Divider />
            <Width width={widget?.appearanceSettings?.width} />
            <Height height={widget?.appearanceSettings?.height} />
            <Divider />
            <Margins
                topValue={widget?.appearanceSettings?.marginTop}
                bottomValue={widget?.appearanceSettings?.marginBottom}
                leftValue={widget?.appearanceSettings?.marginLeft}
                rightValue={widget?.appearanceSettings?.marginRight}
               
            />
            <Paddings
                topValue={widget?.appearanceSettings?.paddingTop}
                bottomValue={widget?.appearanceSettings?.paddingBottom}
                leftValue={widget?.appearanceSettings?.paddingLeft}
                rightValue={widget?.appearanceSettings?.paddingRight}
               
            />
            <Divider />
            <BorderRadius
                topRightValue={widget?.appearanceSettings?.borderTopRightRadius}
                bottomRightValue={widget?.appearanceSettings?.borderBottomRightRadius}
                topLeftValue={widget?.appearanceSettings?.borderTopLeftRadius}
                bottomLeftValue={widget?.appearanceSettings?.borderBottomLeftRadius}
               
            />
            <Divider />
            <Colors colors={widget?.colourSettings} />
        </WidgetSettingContainer>
    )
}

export default IframeSetting
