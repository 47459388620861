import { selector } from 'recoil'

const API_KEY = 'AIzaSyCVKoMaxlHC67C4sUWekndaZS81P13UrQI'

const BASE_URL = `https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=${API_KEY}`

export const fontsState = selector({
    key: 'fontsState',
    get: async () => {
        const response = await fetch(BASE_URL)
        const data = await response.json()
        return data.items
    },
})
