import { Button } from '@mui/material';
import _ from "lodash";
import { PreviewMultiple } from '../preview';
import { ProgressButton } from './components/progress-button';

//
// Choose a font set.
//
export function ChooseFonts({ onNext, onBack, onGenerateFonts, themes, selectedThemeIndex, setSelectedThemeIndex, campaign, generating }) {

    return (
        <div
            className="flex flex-col overflow-hidden"
            >           
           <PreviewMultiple
                themes={themes}
                selectedThemeIndex={selectedThemeIndex}
                generating={generating}
                campaign={campaign}
                setSelectedThemeIndex={setSelectedThemeIndex}
                />
            
            <div className="flex flex-row mt-8 ml-2">
                <Button
                    variant="contained"
                    onClick={onNext}
                    >
                    Continue
                </Button>

                <Button
                    sx={{ ml: 1 }}
                    variant="outlined"
                    onClick={onBack}
                    >
                    Back
                </Button>

                <ProgressButton
                    onClick={onGenerateFonts}
                    generating={generating}
                    >
                    Generate again
                </ProgressButton>
            </div>
        </div>
    );
}

