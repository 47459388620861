import { Divider } from '@mui/material'
import { ChangeEvent, FC } from 'react'
import { InputBox } from '../../../common/InputBox'
import { WidgetSettingContainer } from '../../../Design/WidgetSettingContainer'
import {Colors, Fonts, LetterSpace, LineHeight, Margins, Paddings, Sizer} from '../../../EditMode/Design'
import { useWidget } from 'widgets-base'

interface Props {
}

const ColumnTextSetting: FC<Props> = () => {

    const { widget, setWidget } = useWidget();

    const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const name = event.target.name

        const newWidget = {
            ...widget,
            settings: {
                ...widget.settings,
                [name]: value,
            },
        }

        setWidget(newWidget);
    }

    return (
        <WidgetSettingContainer padding={18}>
            {Object.keys(widget?.settings).map((key) => (
                <InputBox
                    key={key}
                    label={`Set ${key}`}
                    value={widget?.settings?.[key]}
                    name={key}
                    type="text"
                    onChange={onChangeValue}
                />
            ))}
            <Divider />
            <div style={{ display: 'flex', gap: '10px' }}>
                <Fonts
                    label="Select font"
                    defaultFont={widget?.appearanceSettings?.font?.family}
                   
                />
                <Sizer
                   
                    label="Font size"
                    value={widget?.appearanceSettings?.font?.size}
                    name="size"
                />
            </div>
            <LetterSpace               
                label={'Letter Space'}
                value={widget?.appearanceSettings?.font?.spacing}
            />
            <LineHeight               
                label={'Line Height'}
                value={`${widget?.appearanceSettings?.font?.lineHeight}`}
                name={'lineHeight'} />
            <Margins
                topValue={widget?.appearanceSettings?.marginTop}
                bottomValue={widget?.appearanceSettings?.marginBottom}
                leftValue={widget?.appearanceSettings?.marginLeft}
                rightValue={widget?.appearanceSettings?.marginRight}
               
            />
            <Paddings
                topValue={widget?.appearanceSettings?.paddingTop}
                bottomValue={widget?.appearanceSettings?.paddingBottom}
                leftValue={widget?.appearanceSettings?.paddingLeft}
                rightValue={widget?.appearanceSettings?.paddingRight}
               
            />
            <Divider />
            <Colors colors={widget?.colourSettings} />
        </WidgetSettingContainer>
    )
}

export default ColumnTextSetting
