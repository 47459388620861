import { Divider } from '@mui/material'
import { ChangeEvent, FC, useState } from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import validator from 'validator'
import { checkInputValid } from '../../../../../helpers/validation'
import { IRadioOption } from '../../../../../modules/widgets/forms/IRadio'
import { InputBox } from '../../../../common/InputBox'
import { ToggleButton } from '../../../../common/ToggleButton'
import { WidgetSettingContainer } from '../../../../Design/WidgetSettingContainer'
import AddElementButton from '../../../../EditMode/Settings/AddElementButton'
import { useWidget } from 'widgets-base'

interface Props {
}

const RadioSetting: FC<Props> = () => {

    const { widget, setWidget } = useWidget();
    const [error, setError] = useState<string>('')

    const options = widget?.settings?.options?.map((item) => ({
        label: item.label,
        value: item.value,
    }))

    const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newField = {
            ...widget,
            settings: { ...widget.settings, [e.target.name]: e.target.value },
        }

        setWidget(newField);
    }

    const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
        const newField = {
            ...widget,
            settings: { ...widget.settings, required: e.target.checked },
        }

        setWidget(newField);
    }

    const handleTextBlur = (value: string, attr: string) => () => {
        setError('')
        if (attr === 'name') {
            if (validator.isEmpty(value) || value === '') {
                setError('Input name can not be empty')
                return
            }
            if (!checkInputValid(value, 'alphanumericWithDashSpace')) {
                setError('Please set valid input name without special characters')
            }
        }
    }

    const handleChangeCategory = (value: { label: string; value: string }) => {
        const newField = {
            ...widget,
            settings: { ...widget.settings, defaultValue: value },
        }

        setWidget(newField);
    }

    const onAddOption = (): void => {
        const optionIndex = widget.settings.options.length + 1
        const optionValue =
            widget.type === 'checkbox' ? `checkbox ${optionIndex}` : `option ${optionIndex}`

        const newField = {
            ...widget,
            settings: {
                ...widget.settings,
                options: [...widget.settings.options, { label: optionValue, value: optionValue }],
            },
        }

        setWidget(newField);
    }

    const onRemoveOption = (index: number) => () => {
        const options = widget.settings.options
        if (options.length === 1) return
        const filteredOptions = options.filter((o, i) => i !== index)
        const newField = {
            ...widget,
            settings: {
                ...widget.settings,
                options: filteredOptions,
            },
        }

        setWidget(newField);
    }

    const handleOptionLabelChange = (
        e: ChangeEvent<HTMLInputElement>,
        index: number
    ) => {
        const options = widget.settings.options
        const optionIndex = options.findIndex((o: IRadioOption, i: number) => i === index)
        if (optionIndex === -1) return
        const value = e.target.value
        const newOption = {
            ...options[optionIndex],
            label: value,
            value: value,
        }
        const newField = {
            ...widget,
            settings: {
                ...widget.settings,
                options: [
                    ...options.slice(0, optionIndex),
                    newOption,
                    ...options.slice(optionIndex + 1),
                ],
            },
        }

        setWidget(newField);
    }

    return (
        <WidgetSettingContainer padding={0}>
            <InputBox
                label={`${widget?.type} label`}
                name="label"
                placeholder="Input label name"
                onChange={handleTextChange}
                type="text"
                value={widget?.settings?.label}
                onBlur={handleTextBlur(widget?.settings?.label, 'label')}
            />
            <InputBox
                label="Field name"
                name="name"
                placeholder="Input Name"
                onChange={handleTextChange}
                type="text"
                value={widget?.settings?.name}
                onBlur={handleTextBlur(widget?.settings?.name, 'name')}
                error={error}
            />
            <SelectContainer>
                <label>Input default field</label>
                <Select
                    options={options}
                    defaultValue={widget?.settings?.defaultValue}
                    onChange={handleChangeCategory}
                />
            </SelectContainer>
            <ToggleContainer>
                <ToggleButton isToggled={widget?.settings?.required} onChange={handleCheckbox} />
                <span>Field is required</span>
            </ToggleContainer>
            <Divider />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Label>Options</Label>
                <span style={{ fontSize: '11px', color: '#717F95' }}>
                    Add options to the dropdown list.
                </span>
                <OptionsContainer>
                    {widget?.settings?.options?.map((option: IRadioOption, index: number) => (
                        <FlexBox key={`option-${index}`}>
                            <InputBox
                                value={option.label}
                                onChange={(e) => handleOptionLabelChange(e, index)}
                                name="label"
                                type="text"
                            />
                            <svg
                                onClick={onRemoveOption(index)}
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12 21.25C6.89 21.25 2.75 17.11 2.75 12C2.75 6.89 6.89 2.75 12 2.75C17.11 2.75 21.25 6.89 21.25 12C21.25 17.11 17.11 21.25 12 21.25Z"
                                    stroke="#717F95"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M13.8894 10.0801L10.1094 13.9201"
                                    stroke="#717F95"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M10.1094 10.0801L13.8894 13.9201"
                                    stroke="#717F95"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </FlexBox>
                    ))}
                </OptionsContainer>
            </div>
            <AddElementButton name="Add option" onClick={onAddOption} />
        </WidgetSettingContainer>
    )
}

export default RadioSetting

const SelectContainer = styled.div`
    & > label {
        font-size: 14px;
        font-weight: bold;
        color: #3c4b61;
    }
    & > div {
        margin-top: 5px;
        & > div {
            background-color: #fafaff;
            border-radius: 10px;
            border-color: #dfe2f2;
        }
    }
`

const FlexBox = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    & > div {
        margin-bottom: 0;
        flex: 1;
        & > span {
            display: none;
        }
    }
    & > svg {
        cursor: pointer;
        position: absolute;
        right: 0;
        margin-right: 9px;
        opacity: 0.5;
        transition: opacity 0.15s ease-in-out;
        &:hover {
            opacity: 1;
        }
    }
`

const OptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 18px;
    & > div:not(:last-child) {
        margin-bottom: 10px;
    }
`

export const ToggleContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 18px;

    & > span {
        font-size: 14px;
        font-weight: bold;
        color: #3c4b61;
        margin-bottom: 4px;
        margin-left: 10px;
    }
`


export const Label = styled.label`
    font-size: 14px;
    font-weight: bold;
    color: #3c4b61;
    margin-bottom: 4px;
`
