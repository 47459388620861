import { useForm } from "../../builder/context/form-state-new";
import { MoveableWidgets } from "../../v1/components/new/moveable-widgets";
import { StaticWidgets } from "../../v1/components/new/static-widgets";
import { useRef, ChangeEvent, useState } from "react";
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import TableRowsIcon from '@mui/icons-material/TableRows';
import { Toast } from "../../v1/helpers/Toast";
import { AcceptedFileTypes, makeStyledElement, useWidget, useRenderContext, usePageTheme, useDragContext, determineDirection, IWidgetType, makeColorProperty, stdBorderWidth, stdBorderStyle, stdBorderRadius, stdFontFamily, stdFontSize, stdLineHeight, stdTextAlignment, stdElementAlignment, stdPadding, stdMargin, stdWidth, stdHeight } from "widgets-base";

//
// Format an accept string.
//
function formatAcceptAttribute(acceptedFileTypes: AcceptedFileTypes): string {
    switch (acceptedFileTypes) {
        case "audio":
            return "audio/*";
        case "video":
            return "video/*";
        case "document":
            return '.doc,.docx,.ppt, .pptx,.txt,.pdf,.xlsx, .xlsm, .csv';
    }
    
    return "image/*";
}

const Button = makeStyledElement("button");
const Div = makeStyledElement("div");

function Static() {

    const { widget, properties, expandedProperties, parentWidget } = useWidget();
    const form = useForm();
    const { renderContext } = useRenderContext();
    const inputRef = useRef<HTMLInputElement>();
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const { pageTheme } = usePageTheme();

    let widgetClassName = `upload flex flex-grow `;
   

    //
    // Some of these functions won't be neccessary when we switch to static exporting.
    //
    
    function checkFileSize(files: File[], limit: number): boolean {
        let totalFileSize = 0;
        for (let i = 0; i < files.length; i++) {
            totalFileSize += files[i].size;
        }
    
        if (totalFileSize / (1024 * 1024) > limit) {
            return false;
        }
    
        return true;
    }

    function validateSingleInput(oInput, _validFileExtensions): boolean {
        var sFileName = oInput.name;
        if (sFileName.length > 0) {
            var blnValid = false;
            for (var j = 0; j < _validFileExtensions.length; j++) {
                var sCurExtension = _validFileExtensions[j];
                if (
                    sFileName
                        .substr(sFileName.length - sCurExtension.length, sCurExtension.length)
                        .toLowerCase() == sCurExtension.toLowerCase()
                ) {
                    blnValid = true;
                    break;
                }
            }
    
            if (!blnValid) {
                return false;
            }
        }
    
        return true;
    }

    function checkFileTypes(files: File[], fileType: string) {
        let isValid = [];
        for (let i = 0; i < files.length; i++) {
            let validFileExtensions = [];
            let valid = true;
    
            switch (fileType) {
                case 'image':
                    validFileExtensions = ['jpeg', 'png', 'gif', 'webp', 'svg+xml', 'svg', 'jpg'];
                    break;
                case 'audio':
                    validFileExtensions = ['mpeg', 'ogg', 'mp3', 'wav'];
                    break;
                case 'video':
                    validFileExtensions = ['mp4', 'webm', 'ogg', 'wmv', 'mov', 'avi', 'mpeg', 'mpg'];
                    break;
                case 'document':
                    validFileExtensions = [
                        'doc',
                        'csv',
                        'pdf',
                        'docx',
                        'txt',
                        'ppt',
                        'xlsx',
                        'xlsm',
                        'pptx',
                    ];
                    break;
                default:
                    break;
            }
    
            valid = validateSingleInput(files[i], validFileExtensions);
    
            if (!valid) {
                isValid.push(valid);
            }
        }
    
        return isValid;
    }
        
    function onFileChange(e: ChangeEvent<HTMLInputElement>, fileType: AcceptedFileTypes): void {
        const files = Array.from(e.target.files);
        const limitExceed = checkFileSize(files, 50);
        if (!limitExceed) {
            Toast('Total file size exceeds 50mb. Please upload smaller file.', 'error');
            setSelectedFiles([]);
            return;
        }

        const isValidArray = checkFileTypes(files, fileType);
        if (isValidArray.length > 0) {
            Toast('One or more files is not an accepted file type.', 'error');
            setSelectedFiles([]);
            return
        }

        setSelectedFiles(files);

        if (form) {
            form.setFieldValue(parentWidget?.properties?.fieldName, { files, fileType });
        }
    }

    return (
        <>
            <input
                style={{
                    display: "none",
                }}
                ref={inputRef}
                id={parentWidget?.id}
                type="file"
                name={parentWidget?.properties?.fieldName}
                accept={formatAcceptAttribute(expandedProperties.acceptedFiles)}
                required={expandedProperties.required}
                onChange={(e) => onFileChange(e, expandedProperties.acceptedFiles)}
                multiple={expandedProperties.allowMultipleFiles}
                />
            <Button
                widgetId={widget.id}
                className={widgetClassName}
                elementName="button"
                variantName={properties.variant || "upload"}
                pageTheme={pageTheme}
                properties={expandedProperties}
                renderContext={renderContext}
                type="button"
                data-button-type="upload"
                data-file-input-id={parentWidget?.id}
                onClick={() => {
                    if (inputRef.current) {
                        inputRef.current.click();
                    }
                }}
                >
                <StaticWidgets
                    widgets={widget.children}
                    />

            </Button>

        </>
    );
}

function Editor() {

    const { widget, widgetPath, properties, expandedProperties } = useWidget();
    const { renderContext } = useRenderContext();
    const { pageTheme } = usePageTheme();
    const { dragProps, setRef, onSelect } = useDragContext();

	let direction = determineDirection(properties, renderContext);
    let widgetClassName = `upload flex flex-grow `;
    
    const groupStyle: any = {};

    if (widget.children?.length === 0) {
        if (direction === "column") {
            if (!groupStyle.minWidth) {
                groupStyle.minWidth = "175px";
            }
            if (!groupStyle.minHeight) {
                groupStyle.minHeight = "250px";
            }
        }
        else {
            if (!groupStyle.minWidth) {
                groupStyle.minWidth = "250px";
            }
            if (!groupStyle.minHeight) {
                groupStyle.minHeight = "170px";
            }
        }
    }

    return (
        <Div
            {...dragProps}
            ref={setRef}
            onClick={event => {
                event.stopPropagation();
                event.preventDefault();
                onSelect();
            }}
            widgetId={widget.id}
            className={widgetClassName}
            elementName="button"
            variantName={properties.variant || "upload"}
            pageTheme={pageTheme}
            properties={expandedProperties}
            renderContext={renderContext}
            style={groupStyle}
            >
            <MoveableWidgets
                key={`${widget.id}-${widget.lastUpdate}`}
                groupId={widget.id}
                widgets={widget.children}
                direction={direction}
                parentWidgetPath={widgetPath}
                />
        </Div>
    );
}

function Template() {

    const { widget, properties, expandedProperties } = useWidget();
    const { renderContext } = useRenderContext();
    const { pageTheme } = usePageTheme();
    const { dragProps, setRef, onSelect } = useDragContext();
    
    let widgetClassName = `upload flex `;

    return (
        <Div
            widgetId={widget.id}
            className={widgetClassName}
            elementName="button"
            variantName={properties.variant || "upload"}
            pageTheme={pageTheme}
            properties={expandedProperties}
            renderContext={renderContext}
            >
            <input
                type="checkbox"
                name={widget.name}
                readOnly
                />
        </Div>
    );
}

const widgetType: IWidgetType = {
    name: 'File upload',
    Static: Static,
    Preview: Static,
    Editor: Editor,
    Template: Template,
    isFormWidget: true,
    affinity: [ "field" ], // A file upload can only be placed in a field.
    properties: [
        {
            id: "acceptedFiles",
            name: "Allowed file type",
            type: "option",
            values: [{
                    name: "Image", 
                    value: "image", 
                },
                {
                    name: "Audio", 
                    value: "audio",
                },
                {
                    name: "Video", 
                    value: "video",
                },
                {
                    name: "Document", 
                    value: "document",
                },
            ],
            defaultValue: "row",
            required: true,
            orientation: "vertical",
        },
        {
            id: "required",
            name: "Required",
            type: "boolean",
            required: true, 
            defaultValue: false,
        },
        {
            id: "multipleFiles",
            name: "Multiple files",
            type: "boolean",
            required: true, 
            defaultValue: false,
        },
        {
            id: "flexDirection",
            name: "Direction",
            type: "option",
            values: [{
                    name: "Row", 
                    value: "row", 
                    icon: <ViewColumnIcon />,
                },
                {
                    name: "Column", 
                    value: "column",
                    icon: <TableRowsIcon />,
                },
            ],
            defaultValue: "row",
            required: true,
            orientation: "vertical",
        },
    ],
    propertyCategories: [
        {
            name: "Colors",
            properties: [
                makeColorProperty("normal", "color", "Color"),
                makeColorProperty("hover", "color", "Color / Hover"),
                makeColorProperty("normal", "borderColor", "Border"),
                makeColorProperty("hover", "borderColor", "Border / Hover"),
                makeColorProperty("normal", "backgroundColor", "Background"),
                makeColorProperty("hover", "backgroundColor", "Background / Hover"),
            ],
        },
        {
            name: "Border",
            properties: [
                stdBorderWidth,
                stdBorderStyle,
                stdBorderRadius,
            ],
        },
        {
            name: "Design",
            properties: [
                stdFontFamily,
                stdFontSize,
                stdLineHeight,
                stdTextAlignment,
            ],
        },
        {
            name: "Placement",
            properties: [
                stdElementAlignment,
                stdPadding,
                stdMargin,
                stdWidth,
                stdHeight,
            ],
        },
    ],
};

export default widgetType;
