import { FC } from 'react'
import ContainerWidget from '.'
import Checkbox from '../../Forms/oldForm/Checkbox'
import DatePicker from '../../Forms/oldForm/DatePicker'
import FormContainer from '../../Forms/oldForm/FormContainer'
import Input from '../../Forms/oldForm/Input'
import Radio from '../../Forms/oldForm/Radio'
import RatingComponent from '../../Forms/oldForm/Rating'
import Select from '../../Forms/oldForm/Select'
import Textarea from '../../Forms/oldForm/Textarea'
import Upload from '../../Forms/oldForm/Upload'
import AudioWidget from '../AudioWidget'
import ButtonWidget from '../ButtonWidget'
import CountdownWidget from '../CountdownWidget'
import DigitalCardWidget from '../DigitalCardWidget'
import EventsWidget from '../EventsWidget'
import IframeWidget from '../IframeWidget'
import ImageWidget from '../ImageWidget'
import LinkWidget from '../LinkWidget'
import SocialWidget from '../SocialWidget'
import SpotifyWidget from '../SpotifyWidget'
import SwiperWidget from '../SwiperWidget'
import TextWidget from '../TextWidget/TextWidget'
import VideoWidget from '../VideoWidget'
import { IWidget } from 'widgets-base'
import { WidgetContextProvider } from '../../../../../builder/context/widget'

interface Props {
    id: string
    widgets: IWidget[]
    parentId?: string
}

const RenderWidgets: FC<Props> = ({ id, widgets, parentId }) => {
    return (
        <div style={{ width: '100%' }}>
            {widgets?.map((widget, index) => {
                if (!widget) return null
                return (
                    <WidgetContextProvider
                        widget={widget}
                        key={`${widget.id}-${index}`}
                        >
                        {renderContainerWidget(widget, parentId, index, id)}
                    </WidgetContextProvider>
                );
            })}
        </div>
    )
}

export default RenderWidgets

function renderContainerWidget(widget: IWidget, parentId: string, index: number, id: string) {
    const widget_type = widget.type
    let widget_id = ''
    if (parentId) {
        widget_id = `${parentId}-${index}`
    } else {
        widget_id = `${id}-${index}`
    }

    switch (widget_type) {
        case 'image':
            return <ImageWidget key={`${widget_id}-${index}`} />
        case 'video':
            return <VideoWidget key={`${widget_id}-${index}`} />
        case 'rich text':
            return <TextWidget key={`${widget_id}-${index}`} />
        case 'link text':
            return <LinkWidget key={`${widget_id}-${index}`} />
        case 'profile card':
            return <DigitalCardWidget key={`${widget_id}-${index}`} />
        case 'events':
            return <EventsWidget key={`${widget_id}-${index}`} />
        case 'button':
        case 'gamebutton':
        case 'submit':
            return <ButtonWidget key={`${widget_id}-${index}`} />
        case 'social':
            return <SocialWidget key={`${widget_id}-${index}`} />
        case 'container':
            return (
                <ContainerWidget
                    key={`${widget_id}-${index}`} />
            )
        case 'swiper':
            return <SwiperWidget key={`${widget_id}-${index}`} />
        case 'audio':
            return <AudioWidget key={`${widget_id}-${index}`} />
        case 'spotify':
            return <SpotifyWidget key={`${widget_id}-${index}`} />
        case 'iframe':
            return <IframeWidget key={`${widget_id}-${index}`} />
        case 'countdown':
            return <CountdownWidget key={`${widget_id}-${index}`} />
        case 'form':
        case 'old-form':
            return (
                <FormContainer
                    key={`${widget_id}-${index}`} />
            )
        case 'input box':
            return <Input key={`${parentId}-${index}`} />
        case 'text area':
            return <Textarea key={`${parentId}-${index}`} />
        case 'select':
            return <Select key={`${parentId}-${index}`} />
        case 'radio':
            return <Radio key={`${parentId}-${index}`} />
        case 'checkbox':
            return <Checkbox key={`${parentId}-${index}`} />
        case 'upload':
            return <Upload key={`${parentId}-${index}`} />
        case 'rating':
            return <RatingComponent key={`${parentId}-${index}`} />
        case 'datepicker':
            return <DatePicker key={`${parentId}-${index}`} />
        // case 'stepper':
        //     return <Stepper key={`${widget_id}-${index}`} />
        // case 'polls':
        //     return <Polls key={`${widget_id}-${index}`} />
        // case 'spinwheel':
        //     return <Spinwheel key={`${parentIndex}-${index}`} />
        // case 'flipcard':
        //     return <Flipcard key={`${parentIndex}-${index}`} />
        // case 'puzzle':
        //     return <Puzzle key={`${parentIndex}-${index}`} />
        // case 'quiz':
        //     return <Quiz key={`${parentIndex}-${index}`} />
        // case 'scratchcard':
        //     return (
        //         <Scratchcard
        //             key={`${parentIndex}-${index}`}
        //            
        //             isPreview={false}
        //         />
        //     )
        default:
            return null
    }
}

