import { ChangeEvent, FC } from 'react'
import styled from 'styled-components'
import { InputBox } from '../../common/InputBox'
import EventListSetting from './EventListSetting'
import { useWidget } from 'widgets-base'

interface Props {
}

const EventGeneralSetting: FC<Props> = () => {

    const { widget, updateWidget } = useWidget();

    const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
        updateWidget({
            settings: {
                ...widget.settings,
                [e.target.name]: e.target.value,
            },
        });
    }

    return (
        <Container>
            <InputBox
                label="Events title"
                width="100%"
                name="label"
                onChange={onChangeValue}
                type="text"
                value={widget?.settings?.label}
            />
            <EventListSetting  />
        </Container>
    )
}

export default EventGeneralSetting

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
`
