import React, { FC } from 'react'
import styled from 'styled-components'
import { useRedirectAction } from '../../../../hooks/useRedirectAction'
import { IButtonSettings } from '../../../../modules/widgets/IButtonSettings'
import { useWidget } from 'widgets-base'

interface Props {
    action: IButtonSettings
    editMode: boolean
}

const DefaultButton: FC<Props> = ({ action, editMode }) => {

    const { widget } = useWidget();
    const handleAction = useRedirectAction(action)

    const appearanceSettings = widget.appearanceSettings
    const colourSettings = widget.colourSettings

    const width = appearanceSettings?.width + '%'
    const paddingTop = appearanceSettings?.paddingTop / 10 + 'em'
    const paddingBottom = appearanceSettings?.paddingBottom / 10 + 'em'
    const paddingLeft = appearanceSettings?.paddingLeft / 10 + 'em'
    const paddingRight = appearanceSettings?.paddingRight / 10 + 'em'
    const fontFamily = appearanceSettings?.font?.family || 'Poppins'
    const modFontFamily = fontFamily?.replace(/\s/g, '+')
    const fontUrl = appearanceSettings?.font?.url
    const fontSize = widget?.appearanceSettings?.font?.size + 'px'
    const weight = appearanceSettings?.font?.weight
    const borderTopLeftRadius = appearanceSettings?.borderTopLeftRadius / 10 + 'em'
    const borderTopRightRadius = appearanceSettings?.borderTopRightRadius / 10 + 'em'
    const borderBottomLeftRadius = appearanceSettings?.borderBottomLeftRadius / 10 + 'em'
    const borderBottomRightRadius = appearanceSettings?.borderBottomRightRadius / 10 + 'em'
    const borderTopWidth = appearanceSettings?.borderTopWidth / 10 + 'em'
    const borderBottomWidth = appearanceSettings?.borderBottomWidth / 10 + 'em'
    const borderLeftWidth = appearanceSettings?.borderLeftWidth / 10 + 'em'
    const borderRightWidth = appearanceSettings?.borderRightWidth / 10 + 'em'
    const borderWidth = `${borderTopWidth} ${borderRightWidth} ${borderBottomWidth} ${borderLeftWidth}`

    const color = Array.isArray(colourSettings?.['text colour'])
        ? colourSettings?.['text colour'][0]
        : colourSettings?.['text colour']
    const backgroundColor = Array.isArray(colourSettings?.['button colour'])
        ? colourSettings?.['button colour'][0]
        : colourSettings?.['button colour']
    const borderColor = Array.isArray(colourSettings?.['border colour'])
        ? colourSettings?.['border colour'][0]
        : colourSettings?.['border colour']

    const handleClickOn = () => {
        handleAction.action()
    }

    const letterSpacing = appearanceSettings?.font?.spacing + 'px' || '8px'
    const lineHeight = appearanceSettings?.font?.lineHeight || 1

    function convertType(type: string): string {
        switch (type) {
            case "redirect": 
                return "url";
            case "go to page": 
            case "redirect to view":
                return "page";
        }
    }

    return (
        <Container
            isPreview={editMode}
            width={width}
            borderTopLeftRadius={borderTopLeftRadius}
            borderBottomLeftRadius={borderBottomLeftRadius}
            borderTopRightRadius={borderTopRightRadius}
            borderBottomRightRadius={borderBottomRightRadius}
            onClick={handleClickOn}
        >
            <style>
                {!fontUrl
                    ? `@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`
                    : `@font-face {
                        font-family: '${fontFamily}';
                        src: url('${fontUrl}');
                        font-style: normal;
                      }
                      
                      .header-text {
                        font-family: '${fontFamily}';
                      }`}
            </style>
            <button
                type="button"
                onClick={handleClickOn}
                data-button-type={convertType(action.type)}
                data-button-url={action.linkUrl}
                data-button-page={+action.viewIndex+1}
                style={{
                    paddingTop,
                    paddingBottom,
                    paddingLeft,
                    paddingRight,
                    fontFamily,
                    backgroundColor,
                    fontWeight: weight,
                    color,
                    margin: `0 0`,
                    borderWidth,
                    borderColor,
                    fontSize,
                    letterSpacing,
                    lineHeight,
                }}
            >
                {action.linkTitle}
            </button>
        </Container>
    )
}

export default DefaultButton

const Container = styled.div.attrs((props) => ({}))<{
    width: string
    borderTopLeftRadius: string
    borderBottomLeftRadius: string
    borderTopRightRadius: string
    borderBottomRightRadius: string
    isPreview: boolean
}>`
    position: relative;
    cursor: pointer;
    width: ${(props) => props.width};
    min-width: min-content;
    border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
    border-top-left-radius: ${(props) => props.borderTopLeftRadius};
    border-top-right-radius: ${(props) => props.borderTopRightRadius};
    border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
    button {
        width: 100%;
        height: 100%;
        border-radius: inherit;
        transition: all 0.2s ease-in-out;
    }
    button:hover {
        transform: translateX(3px);
    }
`
