//
// A button that includes a progress indicator.
//

import { Button } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

export function ProgressButton({ onClick, generating, children }) {
    return (
        <Button
            variant='outlined'
            sx={{
                ml: 5,
                position: "relative",
                overflow: "hidden",
            }}
            onClick={onClick}
            disabled={generating}
            >
            {children}

            {generating
                && <CircularProgress
                    size={24}
                    color="inherit"
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: '-12px',  // half of the size
                        marginLeft: '-12px'  // half of the size
                    }}
                />
            }
        </Button>
    );
}