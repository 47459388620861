import { nanoid } from 'nanoid'
import { ButtonAction, IButtonSettings } from '../../modules/widgets/IButtonSettings'

class ButtonSettings implements IButtonSettings {
    id = nanoid()
    linkTitle = ''
    linkUrl = ''
    type = ButtonAction.REDIRECT
    layerIndex = 0
    viewIndex = 0
    widgetSettings = {}

    constructor(
        linkTitle: string,
        linkUrl: string,
        type: ButtonAction,
        layerIndex: number,
        viewIndex: number
    ) {
        this.linkTitle = linkTitle
        this.linkUrl = linkUrl
        this.type = type
        this.layerIndex = layerIndex // if type === moveToComponent / showComponent / closeComponent, then find widgetIndex in settings.widget and move/show
        this.viewIndex = viewIndex // if type === goToView , then find view from viewIndex in settings to display
    }
}

export default ButtonSettings
