import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import TableRowsIcon from '@mui/icons-material/TableRows';
import { StaticWidgets } from "../v1/components/new/static-widgets";
import { MoveableWidgets } from "../v1/components/new/moveable-widgets";
import { FormStateContextProvider } from "../builder/context/form-state-new";
import { makeStyledElement, useWidget, useRenderContext, usePageTheme, useDragContext, determineDirection, IWidgetType, stdGroupProperties, makeColorProperty, stdBorderWidth, stdBorderStyle, stdBorderRadius, stdElementAlignment, stdPadding, stdMargin, stdWidth, stdHeight } from 'widgets-base';

const Form = makeStyledElement("form");

function Static() {
    
    const { widget, properties, expandedProperties } = useWidget();
    const { renderContext } = useRenderContext();
    const { pageTheme } = usePageTheme();

    let widgetClassName = `form flex flex-grow `;

    return (
        <Form
            id={widget.id}
            widgetId={widget.id}
            data-form-name={widget.name || "Form"}
            className={widgetClassName}
            elementName="form"
            variantName={properties.variant || "default"}
            pageTheme={pageTheme}
            properties={expandedProperties}
            renderContext={renderContext}
            >
            <FormStateContextProvider formName={widget.name}>
                <StaticWidgets
                    widgets={widget.children}
                    />
            </FormStateContextProvider>
        </Form>
    );
}

function Editor() {

    const { widget, widgetPath, properties, expandedProperties } = useWidget();
    const { renderContext } = useRenderContext();
    const { pageTheme } = usePageTheme();
    const { dragProps, setRef, onSelect } = useDragContext();

	let direction = determineDirection(properties, renderContext);
    let widgetClassName = `form flex flex-grow `;

    const groupStyle: any = {};

    if (widget.children?.length === 0) {
        if (direction === "column") {
            if (!groupStyle.minWidth) {
                groupStyle.minWidth = "175px";
            }
            if (!groupStyle.minHeight) {
                groupStyle.minHeight = "250px";
            }
        }
        else {
            if (!groupStyle.minWidth) {
                groupStyle.minWidth = "250px";
            }
            if (!groupStyle.minHeight) {
                groupStyle.minHeight = "170px";
            }
        }
    }

    return (
        <Form
            {...dragProps}
            ref={setRef}
            onClick={event => {
                event.stopPropagation();
                event.preventDefault();
                onSelect();
            }}
            widgetId={widget.id}
            className={widgetClassName}
            elementName="form"
            variantName={properties.variant || "default"}
            pageTheme={pageTheme}
            properties={expandedProperties}
            renderContext={renderContext}
            style={groupStyle}
            >
            <FormStateContextProvider formName={widget.name}>
                <MoveableWidgets
                    key={`${widget.id}-${widget.lastUpdate}`}
                    groupId={widget.id}
                    widgets={widget.children}
                    direction={direction}
                    parentWidgetPath={widgetPath}
                    />
            </FormStateContextProvider>
        </Form>
    );
}

function Template() {

    const { widget } = useWidget();

    if (widget.children?.length === 0) {
        return (
            <div className="empty-form flex flex-row items-center justify-center">
                <span className="pr-2">Form</span>
                <ViewColumnIcon />
                <TableRowsIcon />
            </div>
        );
    }
    else {
        return <Static />;
    }
}

const widgetType: IWidgetType = {
    name: 'Form',
    Static: Static,
    Preview: Static,
    Editor: Editor,
    Template: Template,
    antiAffinity: [ "form" ], // A form cannot be placed inside another form.
    properties: [
        {
            id: "flexDirection",
            name: "Direction",
            type: "option",
            values: [{
                    name: "Row", 
                    value: "row", 
                    icon: <ViewColumnIcon />,
                },
                {
                    name: "Column", 
                    value: "column",
                    icon: <TableRowsIcon />,
                },
            ],
            required: true,
            defaultValue: "row",
        },
    ],
    propertyCategories: [
        {
            name: "Items",
            properties: stdGroupProperties,
        },
        {
            name: "Colors",
            properties: [
                makeColorProperty("normal", "color", "Color"),
                makeColorProperty("normal", "borderColor", "Border"),
                makeColorProperty("normal", "backgroundColor", "Background"),
            ],
        },
        {
            name: "Border",
            properties: [
                stdBorderWidth,
                stdBorderStyle,
                stdBorderRadius,
            ],
        },
        {
            name: "Placement",
            properties: [
                stdElementAlignment,
                stdPadding,
                stdMargin,
                stdWidth,
                stdHeight,
            ],
        },
    ],
};

export default widgetType;
