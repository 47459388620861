import { nanoid } from 'nanoid'
import { IIFrame } from '../../../modules/widgets/general/IIframe'

export class Iframe implements IIFrame {
    id = nanoid()
    name = 'Iframe'
    type = 'iframe'
    category = 'general'
    settings = {
        src: 'https://deployable.co',
    }
    appearanceSettings = {
        align: 'center',
        height: 30,
        width: 90,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        borderRadius: 0,
        borderWidth: 0,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
    }
    colourSettings = {
        'background colour': ['#f4f4f4', '#FFA7E1', '#0426FF'],
    }
}
