import { AxiosResponse } from 'axios'
import instance from '../lib/http'
import { IForm, IForms } from '../v1/modules/IForm'
import { ISubmission } from '../v1/modules/ISubmission'
import { IFields } from '../v1/modules/widgets/forms/IForm'

interface IFormData {
    fields: IFields[]
    uid: string
    campaignId: string
    formId: string
    formName: string
}

interface ISubmissionsResponseData {
    submissions: ISubmission[]
    form: IForms
}

export interface ICampaignForm {
    campaignId: string;
    scans: number;
    status: string;
    campaignName: string;
    createdAt: string;
    metaImageUrl: string;
    uid: string;
    creatorUID: string;
}

export interface IFormsData {
    campaigns: ICampaignForm[];
    forms: IForm[];
    submissions: ISubmission[];
}

export default class FormService {
    static async uploadFormFiles(
        data: FormData,
        uid: string,
        campaignId: string,
        formId: string,
        fileType: string
    ): Promise<AxiosResponse> {
        return instance.post(
            `/uploadFiles?uid=${uid}&campaignId=${campaignId}&formId=${formId}&fileType=${fileType}`,
            data
        )
    }

    static async submitForm(data: IFormData): Promise<AxiosResponse> {
        return instance.post('/submitForm', { data })
    }

    static async getPollsData(campaignId: string): Promise<AxiosResponse> {
        return instance.post('/getPolls', { campaignId })
    }

    static async submitPolls(
        fields: { [key: string]: IFields[] },
        campaignId: string,
        formId: string,
        formName: string,
        uid: string
    ): Promise<AxiosResponse> {
        return instance.post(
            `/submitPolls?campaignId=${campaignId}&formId=${formId}&formName=${formName}&uid=${uid}`,
            fields
        )
    }

    static async getForms(): Promise<AxiosResponse<IFormsData>> {
        return instance.get('/getForms')
    }

    static async getSubmissions(
        campaignId: string,
        formId: string
    ): Promise<AxiosResponse<ISubmissionsResponseData>> {
        return instance.post('/getSubmissions', { campaignId, formId })
    }

    static async deleteField(
        campaignId: string,
        fieldId: string
    ): Promise<AxiosResponse<ISubmission[]>> {
        return instance.post('/deleteField', { campaignId, fieldId })
    }

    static async dowloadCSV(
        campaignId: string,
        formId: string,
        formFields: string[]
    ): Promise<AxiosResponse> {
        return instance.post('/dowloadCSV', { campaignId, formId, formFields })
    }

    static async deleteForm(campaignId: string, fieldIds: string[]): Promise<AxiosResponse> {
        return instance.post('/deleteForm', { campaignId, fieldIds })
    }

    static async markFormAsRead(_id: string): Promise<AxiosResponse> {
        return instance.post('/updateReading', { _id })
    }
}
