import { ChangeEvent, FC } from 'react'
import ReactQuill from 'react-quill'
import { InputBox } from '../../components/common/InputBox'
import { WidgetSettingContainer } from '../../components/Design/WidgetSettingContainer'
import AssetsSetting from '../../components/EditMode/Settings/AssetsSetting'
import { useWidget } from 'widgets-base'

interface Props {
}

const Hero2GeneralSettings: FC<Props> = () => {

    const { widget, setWidget } = useWidget();

    const onChangeSettingValue = (e: ChangeEvent<HTMLInputElement>) => {
        const newSetting = {
            ...widget,
            settings: {
                ...widget.settings,
                [e.target.name]: e.target.value,
            },
        }

        setWidget(newSetting);
    }

    const onChangeTextValue = (name: string, value: string) => {

        if (widget.settings.text[name] === value) {
            // No change.
            return;
        }

        const newSetting = {
            ...widget,
            settings: {
                ...widget.settings,
                text: {
                    ...widget.settings.text,
                    [name]: value,
                },
            },
        }

        setWidget(newSetting);
    }

    return (
        <WidgetSettingContainer padding={0}>
            <AssetsSetting  label="Upload your image" />
            {Object.keys(widget.settings.text).map((key) => (
                <div key={key}>
                    <label>Set your {key}</label>
                    <ReactQuill 
                        className="mt-2"
                        theme="snow" 
                        value={widget?.settings?.text?.[key]}
                        onChange={state => onChangeTextValue(key, state)} 
                        />
                </div>
            ))}
            <InputBox
                label="Set redirect link"
                value={widget.settings.link}
                name="link"
                type="text"
                onChange={onChangeSettingValue}
            />
        </WidgetSettingContainer>
    )
}

export default Hero2GeneralSettings
