import { Divider } from '@mui/material'
import { FC } from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import { WidgetSettingContainer } from '../../Design/WidgetSettingContainer'
import {
    BorderRadius,
    BorderWidth,
    Colors,
    Fonts,
    Margins,
    Paddings,
    Sizer,
} from '../../EditMode/Design'
import { useWidget } from 'widgets-base'

interface Props {
}

const defaultMode = [
    { label: 'purple', value: 'purple' },
    { label: 'red', value: 'red' },
    { label: 'blue', value: 'blue' },
    { label: 'black', value: 'black' },
    { label: 'white', value: 'white' },
    { label: 'cyan', value: 'cyan' },
]

const PollsDesignSetting: FC<Props> = () => {

    const { widget, setWidget } = useWidget();
    
    const mode = widget?.appearanceSettings?.mode

    const handleChangeMode = (value: { label: string; value: string }) => {
        const newField = {
            ...widget,
            appearanceSettings: { ...widget.appearanceSettings, mode: value.value },
        }

        setWidget(newField);
    }

    return (
        <WidgetSettingContainer padding={0}>
            <div style={{ display: 'flex', gap: '10px' }}>
                <Fonts
                    label="Select font"
                    defaultFont={widget?.appearanceSettings?.font?.family}
                />
                <Sizer
                    
                    label="Font size"
                    value={widget?.appearanceSettings?.font?.size}
                    name="size"
                />
            </div>
            <Divider />
            {widget?.type === 'multiple polls' && (
                <>
                    <SelectContainer>
                        <label>Select answers theme</label>
                        <Select
                            options={defaultMode}
                            defaultValue={{ label: mode, value: mode }}
                            onChange={handleChangeMode}
                        />
                    </SelectContainer>
                    <Divider />
                </>
            )}
            <BorderWidth
                topValue={widget?.appearanceSettings?.borderTopWidth}
                rightValue={widget?.appearanceSettings?.borderRightWidth}
                leftValue={widget?.appearanceSettings?.borderLeftWidth}
                bottomValue={widget?.appearanceSettings?.borderBottomWidth}
            />
            <BorderRadius
                topRightValue={widget?.appearanceSettings?.borderTopRightRadius}
                bottomRightValue={widget?.appearanceSettings?.borderBottomRightRadius}
                topLeftValue={widget?.appearanceSettings?.borderTopLeftRadius}
                bottomLeftValue={widget?.appearanceSettings?.borderBottomLeftRadius}
            />
            <Divider />
            <Margins
                topValue={widget?.appearanceSettings?.marginTop}
                bottomValue={widget?.appearanceSettings?.marginBottom}
                leftValue={widget?.appearanceSettings?.marginLeft}
                rightValue={widget?.appearanceSettings?.marginRight}
            />
            <Paddings
                topValue={widget?.appearanceSettings?.paddingTop}
                bottomValue={widget?.appearanceSettings?.paddingBottom}
                leftValue={widget?.appearanceSettings?.paddingLeft}
                rightValue={widget?.appearanceSettings?.paddingRight}
            />
            <Divider />
            <Colors colors={widget?.colourSettings}  />
        </WidgetSettingContainer>
    )
}

export default PollsDesignSetting

const SelectContainer = styled.div`
    & > label {
        font-size: 14px;
        font-weight: bold;
        color: #3c4b61;
    }
    & > div {
        margin-top: 5px;
        & > div {
            background-color: #fafaff;
            border-radius: 10px;
            border-color: #dfe2f2;
        }
    }
`
