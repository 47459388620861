import { nanoid } from 'nanoid'
import { IRating } from '../../../modules/widgets/forms/IRating'

export class RatingModel implements IRating {
    id = nanoid()
    name = 'rating'
    type = 'rating'
    icon = '/icons/widgets/rating.svg'
    category = 'form'
    colourSettings = {
        'icon colour': ['orange', '#FFA7E1', '#0426FF'],
    }
    appearanceSettings = {
        margin: true,
        padding: true,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        labelSize: 14,
        size: ['small', 'medium', 'large'],
        defaultSize: 'large' as 'small' | 'medium' | 'large',
    }
    settings = {
        options: ['stars', 'sentiment'],
        defaultOption: 'stars',
        numberOfRatings: 5,
        defaultValue: 4,
        placeholder: 'placeholder text',
        required: false,
        name: 'rating',
        label: 'Tap on your star rating',
    }
}
