import React, { FC, memo } from 'react'
import styled from 'styled-components'
import { useCountdown } from '../../../../hooks/useCountdown'
import { useWidget } from 'widgets-base';

interface Props {
}

const CountdownWidget: FC<Props> = () => {

    const { widget } = useWidget();
    const [total, days, hours, minutes, seconds] = useCountdown(widget?.settings?.endDate)

    const color = widget?.colourSettings?.textColour[0]
    const fontFamily = widget?.appearanceSettings?.font?.family || 'Poppins'
    const modFontFamily = fontFamily?.replace(/\s/g, '+')
    const fontUrl = widget?.appearanceSettings?.font?.url

    return (
        <CountdownSection id={widget.id}>
            <style>
                {!fontUrl
                    ? `@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`
                    : `@font-face {
                          font-family: '${fontFamily}';
                          src: url('${fontUrl}');
                          font-style: normal;
                        }
          
                .header-text {
                  font-family: '${fontFamily}';
                }`}
            </style>
            <Container
                paddingTop={widget?.appearanceSettings?.paddingTop}
                paddingBottom={widget?.appearanceSettings?.paddingBottom}
                paddingLeft={widget?.appearanceSettings?.paddingLeft}
                paddingRight={widget?.appearanceSettings?.paddingRight}
                marginTop={widget?.appearanceSettings?.marginTop}
                marginBottom={widget?.appearanceSettings?.marginBottom}
                marginLeft={widget?.appearanceSettings?.marginLeft}
                marginRight={widget?.appearanceSettings?.marginRight}
            >
                {total > 0 ? (
                    <WrapperDiv
                        color={color}
                        align={widget?.appearanceSettings?.align}
                        fontFamily={widget?.appearanceSettings?.font?.family}
                        fontSize={widget?.appearanceSettings?.font?.size}
                        weight={widget?.appearanceSettings?.font?.weight}
                        lineHeight={widget?.appearanceSettings?.font?.lineHeight}
                        letterSpacing={widget?.appearanceSettings?.font?.spacing}
                    >
                        <TimeDiv>
                            {days}
                            <Timespan>Days</Timespan>
                        </TimeDiv>
                        <TimeDiv>
                            {hours} <Timespan>Hours</Timespan>
                        </TimeDiv>
                        <TimeDiv>
                            {minutes} <Timespan>Minutes</Timespan>
                        </TimeDiv>
                        <TimeDiv>
                            {seconds} <Timespan>Seconds</Timespan>
                        </TimeDiv>
                    </WrapperDiv>
                ) : (
                    <WrapperDiv
                        align={widget?.appearanceSettings?.align}
                        fontFamily={widget?.appearanceSettings?.font?.family}
                        fontSize={widget?.appearanceSettings?.font?.size}
                        weight={widget?.appearanceSettings?.font?.weight}
                        lineHeight={widget?.appearanceSettings?.font?.lineHeight}
                        letterSpacing={widget?.appearanceSettings?.font?.spacing}
                        color={color}
                    >
                        {widget?.settings?.endDateText}
                    </WrapperDiv>
                )}
            </Container>
        </CountdownSection>
    )
}

export default memo(CountdownWidget)

const CountdownSection = styled.section`
    width: inherit;
`

const WrapperDiv = styled.div<{
    fontFamily: string
    fontSize: number
    letterSpacing: number
    align: string
    weight: number
    lineHeight: number
    color: string
}>`
    width: 100%;
    display: flex;
    justify-content: ${(props) => props.align};
    font-family: ${(props) => props.fontFamily};
    font-size: ${(props) => props.fontSize}px;
    letter-spacing: ${(props) => props.letterSpacing}px;
    font-weight: ${(props) => props.weight};
    line-height: ${(props) => props.lineHeight};
    align-items: center;
    color: ${(props) => props.color};
`

const TimeDiv = styled.div`
    letter-spacing: inherit;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 0.5rem;
`

const Timespan = styled.span`
    letter-spacing: inherit;
    display: flex;
    flex-direction: column;
`

const Container = styled.div<{
    paddingTop: number
    paddingBottom: number
    paddingLeft: number
    paddingRight: number
    marginTop: number
    marginBottom: number
    marginLeft: number
    marginRight: number
}>`
    width: 100%;
    padding-top: ${(props) => props.paddingTop}px;
    padding-bottom: ${(props) => props.paddingBottom}px;
    padding-left: ${(props) => props.paddingLeft}px;
    padding-right: ${(props) => props.paddingRight}px;
    margin-top: ${(props) => props.marginTop}px;
    margin-bottom: ${(props) => props.marginBottom}px;
    margin-left: ${(props) => props.marginLeft}px;
    margin-right: ${(props) => props.marginRight}px;
`
