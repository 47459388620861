import { memo, useState } from 'react'
import styled from 'styled-components'
import { formFieldCheckRequired } from '../../../../../helpers/form/formFieldCheckRequired'
import { Toast } from '../../../../../helpers/Toast'
import { useRedirectAction } from '../../../../../hooks/useRedirectAction'
import { formFileUpload } from '../../../../../lib/forms/formFileUpload'
import { FormFields } from '../../../../../modules/widgets/forms/IForm'
import { IButtonSettings } from '../../../../../modules/widgets/IButtonSettings'
import FormService from '../../../../../../services/FormService'
import ButtonSpinner from '../../../../common/ButtonSpiner'
import { useCampaign } from '../../../../../../builder/context/campaign'
import { useForm } from '../../../../../../builder/context/form-state-old'
import { useWidget } from 'widgets-base'

interface Props {
    action: IButtonSettings
}

const SubmitButton = ({ action }: Props) => {

    const { widget } = useWidget();
    const { getFormState, clearForm } = useForm();
    const handleAction = useRedirectAction(action)
    const { campaign } = useCampaign();
    const [loading, setLoading] = useState<boolean>(false);
    const appearanceSettings = widget.appearanceSettings
    const colourSettings = widget.colourSettings

    const width = appearanceSettings.width + '%'
    const paddingTop = appearanceSettings.paddingTop / 10 + 'em'
    const paddingBottom = appearanceSettings.paddingBottom / 10 + 'em'
    const paddingLeft = appearanceSettings.paddingLeft / 10 + 'em'
    const paddingRight = appearanceSettings.paddingRight / 10 + 'em'
    const fontFamily = appearanceSettings?.font?.family || 'Poppins'
    const modFontFamily = fontFamily?.replace(/\s/g, '+')
    const fontSize = appearanceSettings ? appearanceSettings.font.size + 'px' : '14px'
    const fontUrl = appearanceSettings?.font?.url

    const borderTopLeftRadius = appearanceSettings.borderTopLeftRadius / 10 + 'em'
    const borderTopRightRadius = appearanceSettings.borderTopRightRadius / 10 + 'em'
    const borderBottomLeftRadius = appearanceSettings.borderBottomLeftRadius / 10 + 'em'
    const borderBottomRightRadius = appearanceSettings.borderBottomRightRadius / 10 + 'em'

    const borderTopWidth = appearanceSettings.borderTopWidth / 10 + 'em'
    const borderBottomWidth = appearanceSettings.borderBottomWidth / 10 + 'em'
    const borderLeftWidth = appearanceSettings.borderLeftWidth / 10 + 'em'
    const borderRightWidth = appearanceSettings.borderRightWidth / 10 + 'em'
    const borderWidth = `${borderTopWidth} ${borderRightWidth} ${borderBottomWidth} ${borderLeftWidth}`

    const color = Array.isArray(colourSettings['text colour'])
        ? colourSettings['text colour'][0]
        : colourSettings['text colour']
    const backgroundColor = Array.isArray(colourSettings['button colour'])
        ? colourSettings['button colour'][0]
        : colourSettings['button colour']
    const borderColor = Array.isArray(colourSettings['border colour'])
        ? colourSettings['border colour'][0]
        : colourSettings['border colour']
    const weight = appearanceSettings?.font?.weight

    const handleSubmit = async (formState: FormFields): Promise<void> => {
        setLoading(true)

        try {
            const errors = formFieldCheckRequired(formState)

            if (errors.length > 0) {
                errors.forEach((error) => {
                    Toast(error, 'error')
                })
                setLoading(false)
                return
            }

            let filesToUpload = []

            formState.fields.map((field) => {
                // check if is file upload type - upload separately
                if (field.fieldType === 'upload') {
                    filesToUpload.push({
                        fieldName: field.fieldName,
                        files: field.value.files,
                        fileType: field.value.fileType,
                    })
                }
                return filesToUpload
            })

            let userId = campaign.uid
            if (!userId) {
                userId = campaign.creatorUID
            }

            const uploadData = await formFileUpload(
                filesToUpload,
                userId,
                campaign._id,
                formState.formId
            )

            if (filesToUpload.length > 0 && uploadData.length === 0) {
                Toast('Please select file.', 'error', widget.id)
                setLoading(false)
                return
            }

            let fields = formState.fields

            uploadData.forEach((data) => {
                const index = formState.fields.findIndex(
                    (field) => field.fieldName === data.fieldName
                )

                if (index === -1) {
                    return
                }

                const newField = { ...formState.fields[index], value: data.data }

                fields = [
                    ...formState.fields.slice(0, index),
                    newField,
                    ...formState.fields.slice(index + 1),
                ]
            })

            await FormService.submitForm({
                fields,
                uid: userId,
                campaignId: campaign._id,
                formId: formState.formId,
                formName: formState.formName,
            })

            resetFormState()
            setLoading(false)
            Toast(widget.settings.toastMessage || 'Form submitted!', 'success', widget.id)

            handleAction.action()
        } catch (error) {
            resetFormState()
            setLoading(false)
            Toast(error.message, 'error', widget.id)
        }
    }

    const resetFormState = (): void => {
        clearForm();
    }

    const handleClickOn = () => {
        handleSubmit(getFormState())
    }

    const letterSpacing = appearanceSettings?.font?.spacing + 'px' || '8px'
    const lineHeight = appearanceSettings?.font?.lineHeight || 1

    return (
        <Container
            width={width}
            paddingTop={paddingTop}
            paddingBottom={paddingBottom}
            paddingLeft={paddingLeft}
            paddingRight={paddingRight}
            borderTopLeftRadius={borderTopLeftRadius}
            borderBottomLeftRadius={borderBottomLeftRadius}
            borderTopRightRadius={borderTopRightRadius}
            borderBottomRightRadius={borderBottomRightRadius}
        >
            <style>
                {!fontUrl
                    ? `@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`
                    : `@font-face {
                        font-family: '${fontFamily}';
                        src: url('${fontUrl}');
                        font-style: normal;
                      }
                      
                      * {
                        font-family: '${fontFamily}';
                      }`}
            </style>

            <button
                type="button"
                onClick={handleClickOn}
                style={{
                    paddingTop,
                    paddingBottom,
                    paddingLeft,
                    paddingRight,
                    fontFamily,
                    fontWeight: weight,
                    backgroundColor,
                    color,
                    margin: `0 0`,
                    borderWidth,
                    borderColor,
                    fontSize,
                    letterSpacing,
                    lineHeight,
                }}
            >
                {loading ? (
                    <span>
                        <ButtonSpinner color={color} height="1.5em" width="1.5em" />
                        <p className="ml-4">Submitting Details...</p>
                    </span>
                ) : (
                    action.linkTitle
                )}
            </button>
        </Container>
    )
}

export default memo(SubmitButton)

const Container = styled.div<{
    width: string
    paddingTop: string
    paddingBottom: string
    paddingLeft: string
    paddingRight: string
    borderTopLeftRadius: string
    borderBottomLeftRadius: string
    borderTopRightRadius: string
    borderBottomRightRadius: string
}>`
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: ${(props) => props.width};
    border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
    border-top-left-radius: ${(props) => props.borderTopLeftRadius};
    border-top-right-radius: ${(props) => props.borderTopRightRadius};
    border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
    button {
        width: 100%;
        height: 100%;
        border-radius: inherit;
    }
`
