import React from 'react'
import { QRCode as RQRCode } from 'react-qrcode-logo'
import { useCampaign } from '../../../../builder/context/campaign';

const CustomQRCode = () => {
    const { campaign } = useCampaign();

    return (
        <RQRCode
            value={campaign?.canonicalUrl}
            ecLevel={campaign?.qrcode?.ecLevel}
            enableCORS={campaign?.qrcode?.enableCORS}
            size={campaign?.qrcode?.size}
            quietZone={campaign?.qrcode?.quietZone}
            bgColor={campaign?.qrcode?.bgColor}
            fgColor={campaign?.qrcode?.fgColor}
            logoImage={campaign?.qrcode?.logoImage}
            logoWidth={campaign?.qrcode?.logoWidth}
            logoHeight={campaign?.qrcode?.logoHeight}
            logoOpacity={campaign?.qrcode?.logoOpacity}
            qrStyle={campaign?.qrcode?.qrStyle}
        />
    )
}

export default CustomQRCode
