import { Dispatch, FC, SetStateAction } from 'react'
import { useRecoilState } from 'recoil'
import styled from 'styled-components'

import { Toast } from '../../helpers/Toast'
import { ISubmission } from '../../modules/ISubmission'
import { IFields } from '../../modules/widgets/forms/IForm'
import FormService from '../../../services/FormService'
import TableBodyFields from './TableBodyFields'
import TableHeadFields from './TableHeadFields'
import { useConfirm } from '../../../hooks/confirm'

interface Props {
    fields: IFields[]
    campaignId: string
    setLoading: Dispatch<SetStateAction<boolean>>
    submissions: ISubmission[];
    setSubmissions: Dispatch<SetStateAction<ISubmission[]>>
}

const FormTable: FC<Props> = ({ fields = [], campaignId, setLoading, submissions, setSubmissions }) => {
    const { isConfirmed } = useConfirm();

    const onDeleteField = (id: string) => async () => {
        setLoading(true)
        try {
            const confirmed = await isConfirmed(`Are you sure you want to delete this submission?`)

            if (confirmed) {
                await FormService.deleteField(campaignId, id)
                setSubmissions((prev) => prev.filter((sub) => sub._id !== id))
            }
        } catch (error) {
            console.log(error)
            Toast(error.response.data.message, 'error')
        }
        setLoading(false)
    }

    return (
        <StyledTable>
            <thead className="table__head">
                <TableHeadFields fields={fields} />
            </thead>
            <tbody className="table__body">
                {submissions.length ? (
                    <TableBodyFields
                        submissions={submissions}
                        fields={fields}
                        removeField={onDeleteField}
                    />
                ) : (
                    <tr style={{ backgroundColor: 'rgb(248 250 252)', textAlign: 'center' }}>
                        <td colSpan={2 + fields.length} className="table__body-item">
                            No form data
                        </td>
                    </tr>
                )}
            </tbody>
        </StyledTable>
    )
}

export default FormTable

const StyledTable = styled.table`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 8px;
    table-layout: fixed;
    overflow-x: scroll;
    height: 100%;
    padding: 0 10px;

    .table__head {
        position: sticky;
        top: 0;
        border-bottom-width: 1px;
        border-color: #a0aec0;
        background-color: #fff;

        .table__head-item {
            width: 150px;
            text-align: left;
            padding: 1rem;
            font-size: 1rem;
            font-weight: 600;
        }
    }

    .table__body {
        overflow-y: auto;

        tr {
            background-color: rgb(248, 250, 252);
        }

        .table__body-item {
            border-top: 1px solid rgb(203, 213, 225);
            border-bottom: 1px solid rgb(203, 213, 225);
            overflow-wrap: break-word;
            padding: 1rem;
            margin-top: 0.25rem;
            margin-bottom: 0.25rem;
            font-size: 1rem;
        }

        .table__body-item:first-child {
            border-left: 1px solid rgb(203, 213, 225);
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
        }

        .table__body-item:last-child {
            border-right: 1px solid rgb(203, 213, 225);
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
            cursor: pointer;
            & > svg {
                opacity: 0.5;
                transition: all 0.2s ease-in-out;
            }

            &:hover {
                & > svg {
                    opacity: 1;
                }
            }
        }
    }
`
