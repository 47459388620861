export const uploadToWinTemplate: any = {
    "templateName": "Upload to Win",
    "templateThumbnail" : "/images/templates/upload-to-win.png",
    "_id" : "474fSEY9-oBQupHDVqCpU",
    "userId" : "Hf7F58y0nlVatZxg4L7N6ESu5AL2",
    "uid" : "Hf7F58y0nlVatZxg4L7N6ESu5AL2",
    "views" : [
        {
            "name" : "Page 1",
            "id" : "ZinCWWf-VXGb81Kb3yHPt",
            "backgroundColour" : "rgb(255,255,255,1)",
            "backgroundImageUrl" : "",
            "widgets" : [
                {
                    "id" : "eecbc115592387a6ed8f64bdbd83c886af3d8a70ad24c0702deefa13efe5921f",
                    "name" : "contact-form",
                    "xtype" : "group",
                    "properties" : {
                        "columnGap" : 48,
                        "rowGap" : 48,
                        "flexDirection" : "column",
                        "justifyContent" : "flex-start",
                        "alignItems" : "center",
                        "paddingTop" : 48,
                        "paddingRight" : 18,
                        "paddingBottom" : 48,
                        "paddingLeft" : 18,
                        "display" : "flex",
                        "small" : {
                            "backgroundAttachment" : "scroll"
                        }
                    },
                    "children" : [
                        {
                            "id" : "fb2f6afed26c9b8d2e77996c5d3a372a6745a29fddf2bf72db84ba4836904132",
                            "name" : "container-2",
                            "xtype" : "group",
                            "properties" : {
                                "maxWidth" : 900,
                                "columnGap" : 30,
                                "rowGap" : 30,
                                "flexDirection" : "column",
                                "justifyContent" : "flex-start",
                                "alignItems" : "center",
                                "display" : "flex",
                                "small" : {
                                    "backgroundAttachment" : "scroll"
                                },
                                "width" : 350
                            },
                            "children" : [
                                {
                                    "id" : "widget-FMKPYCT7dPonruI_bO05Z",
                                    "xtype" : "image",
                                    "properties" : {
                                        "src" : "https://storage.googleapis.com/quicklinks---staging.appspot.com/assets/content/Hf7F58y0nlVatZxg4L7N6ESu5AL2/company-logo.svg",
                                        "alt" : "<Describe your image>",
                                        "width" : 94,
                                        "height" : 40.285714285714285,
                                        "aspectRatio" : 0.42857142857142855,
                                        "maxWidth" : "100%",
                                        "alignSelf" : "center"
                                    },
                                    "lastUpdate" : 1694607513800.0,
                                    "children" : [

                                    ],
                                    "widgets" : [

                                    ]
                                },
                                {
                                    "name" : "countdown",
                                    "id" : "mOlsvnH_XE1s5Vf-U3hgI",
                                    "type" : "countdown",
                                    "category" : "general",
                                    "settings" : {
                                        "endDate" : 1694865560397.0,
                                        "endDateText" : "Time is up"
                                    },
                                    "appearanceSettings" : {
                                        "width" : 90,
                                        "height" : 100,
                                        "align" : "center",
                                        "borderTopLeftRadius" : 36,
                                        "borderTopRightRadius" : 36,
                                        "borderBottomLeftRadius" : 36,
                                        "borderBottomRightRadius" : 36,
                                        "borderTopWidth" : 1,
                                        "borderBottomWidth" : 1,
                                        "borderLeftWidth" : 1,
                                        "borderRightWidth" : 1,
                                        "backgroundImageUrl" : "",
                                        "backgroundFile" : null,
                                        "backgroundFileName" : "",
                                        "paddingTop" : 30,
                                        "paddingBottom" : 30,
                                        "paddingLeft" : 0,
                                        "paddingRight" : 0,
                                        "marginTop" : 0,
                                        "marginBottom" : 0,
                                        "marginLeft" : 0,
                                        "marginRight" : 0,
                                        "shadow" : false,
                                        "font" : {
                                            "family" : "Inter",
                                            "size" : 20,
                                            "weight" : 700,
                                            "spacing" : 2
                                        }
                                    },
                                    "colourSettings" : {
                                        "textColour" : [
                                            "rgb(0,0,0,1)",
                                            "#6478F9",
                                            "#FFA7E1"
                                        ]
                                    },
                                    "lastUpdate" : 1694607480537.0,
                                    "children" : [

                                    ],
                                    "widgets" : [

                                    ]
                                },
                                {
                                    "id" : "widget-eNpkr-RSy7t2tqiQga_KH",
                                    "xtype" : "image",
                                    "properties" : {
                                        "src" : "https://storage.googleapis.com/quicklinks---staging.appspot.com/assets/content/Hf7F58y0nlVatZxg4L7N6ESu5AL2/Image-Placeholder__.png",
                                        "alt" : "<Describe your image>",
                                        "width" : 348,
                                        "height" : 208.87246225923997,
                                        "aspectRatio" : 0.6002082248828735,
                                        "maxWidth" : "100%",
                                        "alignSelf" : "center"
                                    },
                                    "lastUpdate" : 1694607761862.0,
                                    "children" : [

                                    ],
                                    "widgets" : [

                                    ]
                                },
                                {
                                    "id" : "b34c2b2edeba97a83a402a2e5ed0c8d77e0b96e679746f58ba5d2ea3607d3773",
                                    "name" : "section-title",
                                    "xtype" : "group",
                                    "properties" : {
                                        "maxWidth" : 397.5,
                                        "columnGap" : 12,
                                        "rowGap" : 12,
                                        "flexDirection" : "column",
                                        "justifyContent" : "flex-start",
                                        "alignItems" : "center",
                                        "display" : "flex",
                                        "small" : {
                                            "backgroundAttachment" : "scroll"
                                        }
                                    },
                                    "children" : [
                                        {
                                            "id" : "bc38029dc1113f6e35b517d719e1cd8831db151c47109bd2576dc399a546764b",
                                            "name" : "text-14",
                                            "xtype" : "group",
                                            "properties" : {
                                                "fontWeight" : "700",
                                                "marginBottom" : 7.5,
                                                "marginTop" : 15,
                                                "textAlign" : "center",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll",
                                                    "fontWeight" : "bold"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "c2c5e04eb968c72e46155c90494af89e78ba34dd1aba4de91ea719f9090aee31",
                                                    "xtype" : "text",
                                                    "properties" : {
                                                        "content" : "<h2><strong>Upload to Win Competition</strong></h2>",
                                                        "maxWidth" : "100%",
                                                    },
                                                    "lastUpdate" : 1694606162532.0,
                                                    "children" : [

                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "widgets" : [

                                            ]
                                        },
                                        {
                                            "id" : "8f76f911842de9c0dac94e46278d271cdc986756f39ca2222f699cafbd16fbaa",
                                            "name" : "text-15",
                                            "xtype" : "group",
                                            "properties" : {
                                                "fontSize" : 13.5,
                                                "textAlign" : "center",
                                                "fontWeight" : "400",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "8bef4bed11e712cc005a40a837b26828a5331f7b78b2534935746b2cb934e188",
                                                    "xtype" : "text",
                                                    "properties" : {
                                                        "content" : "<p><strong>Step 1:</strong></p><p>Explain what your audience has to do to enter.</p><p><strong>Step 2: </strong></p><p>Enter details and upload proof of purchase.</p><p><strong>Step 3: </strong></p><p>Submit to enter!</p>",
                                                        "maxWidth" : "100%"
                                                    },
                                                    "children" : [

                                                    ],
                                                    "widgets" : [

                                                    ],
                                                    "lastUpdate" : 1694606718469.0
                                                }
                                            ],
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "widgets" : [

                                    ]
                                },
                                {
                                    "id" : "42dcbfb430f90f1a0430cf8fcdb82f81e6c13a54239fcac22b46f723c312654b",
                                    "name" : "form-wrapper w-form",
                                    "xtype" : "group",
                                    "properties" : {
                                        "marginBottom" : 11.25,
                                        "maxWidth" : 375,
                                        "columnGap" : 7.5,
                                        "rowGap" : 7.5,
                                        "flexDirection" : "column",
                                        "justifyContent" : "flex-start",
                                        "alignItems" : "flex-start",
                                        "display" : "flex",
                                        "small" : {
                                            "backgroundAttachment" : "scroll"
                                        }
                                    },
                                    "children" : [
                                        {
                                            "id" : "817125118788b181916bee96ba0c9da427158be60fbda77525a7471da55f23dc",
                                            "name" : "form",
                                            "xtype" : "form",
                                            "properties" : {
                                                "columnGap" : 12,
                                                "rowGap" : 12,
                                                "flexDirection" : "column",
                                                "justifyContent" : "flex-start",
                                                "alignItems" : "flex-start",
                                                "display" : "flex",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                },
                                                "width" : 350
                                            },
                                            "children" : [
                                                {
                                                    "id" : "b9f3eb644dc51b5c8581d822273f4e2b535fd43d2e3e2b4ada23f722a778253f",
                                                    "name" : "input-wrapper",
                                                    "xtype" : "field",
                                                    "properties" : {
                                                    	"fieldName" : "First name",
                                                        "columnGap" : 6,
                                                        "rowGap" : 6,
                                                        "flexDirection" : "column",
                                                        "justifyContent" : "flex-start",
                                                        "alignItems" : "flex-start",
                                                        "display" : "flex",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        },
                                                        "alignSelf" : "stretch"
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "8a0e3a7c584f92e9fbb80dd7e6ea225fe96d379c594c9d56d639eb2c527559ba",
                                                            "name" : "form-block-label",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "display" : "block",
                                                                "marginBottom" : 3.75,
                                                                "fontWeight" : "400",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll",
                                                                    "fontWeight" : "bold"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "9b1200a5f96546c1781a9c5d5db126d32c241ba3d227cb7276a8995fae260cc0",
                                                                    "xtype" : "label",
                                                                    "properties" : {
                                                                        "content" : "<p>First Name</p>",
                                                                        "maxWidth" : "100%"
                                                                    },
                                                                    "lastUpdate" : 1694601955171.0,
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        },
                                                        {
                                                            "id" : "389087f394ffcfb0b8f8f2e6b00a0e973d23f1e301b59c42ecba39f09fb34d35",
                                                            "name" : "form-text-input w-input",
                                                            "xtype" : "input",
                                                            "properties" : {
                                                                "required": true,
                                                                "fontStyle" : "inherit",
                                                                "fontVariantLigatures" : "inherit",
                                                                "fontVariantCaps" : "inherit",
                                                                "fontVariantNumeric" : "inherit",
                                                                "fontVariantEastAsian" : "inherit",
                                                                "fontVariantAlternates" : "inherit",
                                                                "fontWeight" : "500",
                                                                "fontStretch" : "inherit",
                                                                "fontOpticalSizing" : "inherit",
                                                                "fontKerning" : "inherit",
                                                                "fontFeatureSettings" : "inherit",
                                                                "fontVariationSettings" : "inherit",
                                                                "display" : "flex",
                                                                "paddingTop" : 9,
                                                                "paddingRight" : 9,
                                                                "paddingBottom" : 9,
                                                                "paddingLeft" : 9,
                                                                "marginBottom" : 7.5,
                                                                "verticalAlign" : "middle",
                                                                "borderTopWidth" : 0.75,
                                                                "borderRightWidth" : 0.75,
                                                                "borderBottomWidth" : 0.75,
                                                                "borderLeftWidth" : 0.75,
                                                                "borderTopStyle" : "solid",
                                                                "borderRightStyle" : "solid",
                                                                "borderBottomStyle" : "solid",
                                                                "borderLeftStyle" : "solid",
                                                                "borderTopColor" : "rgb(0, 0, 0)",
                                                                "borderRightColor" : "rgb(0, 0, 0)",
                                                                "borderBottomColor" : "rgb(0, 0, 0)",
                                                                "borderLeftColor" : "rgb(0, 0, 0)",
                                                                "borderImageSource" : "initial",
                                                                "borderImageSlice" : "initial",
                                                                "borderImageWidth" : "initial",
                                                                "borderImageOutset" : "initial",
                                                                "borderImageRepeat" : "initial",
                                                                "columnGap" : 6,
                                                                "rowGap" : 6,
                                                                "justifyContent" : "flex-start",
                                                                "alignItems" : "center",
                                                                "small" : {
                                                                    "fontWeight" : "inherit",
                                                                    "marginBottom" : 0,
                                                                    "backgroundAttachment" : "scroll",
                                                                    "display" : "block",
                                                                    "paddingTop" : 6,
                                                                    "paddingBottom" : 6,
                                                                    "borderTopColor" : "rgb(204, 204, 204)",
                                                                    "borderRightColor" : "rgb(204, 204, 204)",
                                                                    "borderBottomColor" : "rgb(204, 204, 204)",
                                                                    "borderLeftColor" : "rgb(204, 204, 204)"
                                                                },
                                                                "textAlign" : "center",
                                                                "alignSelf" : "stretch"
                                                            },
                                                            "children" : [

                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "widget-PFTxEvTcS5psyA1H-EwYk",
                                                    "name" : "input-wrapper",
                                                    "xtype" : "field",
                                                    "properties" : {
                                                    	"fieldName" : "Last name",
                                                        "columnGap" : 6,
                                                        "rowGap" : 6,
                                                        "flexDirection" : "column",
                                                        "justifyContent" : "flex-start",
                                                        "alignItems" : "flex-start",
                                                        "display" : "flex",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        },
                                                        "alignSelf" : "stretch"
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "widget-81wOo7CE-ggfLcyk5-nnd",
                                                            "name" : "form-block-label",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "display" : "block",
                                                                "marginBottom" : 3.75,
                                                                "fontWeight" : "400",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll",
                                                                    "fontWeight" : "bold"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "widget-fFqAvlPJhhRnfAcpG0yTc",
                                                                    "xtype" : "label",
                                                                    "properties" : {
                                                                        "content" : "<p>Last Name</p>",
                                                                        "maxWidth" : "100%"
                                                                    },
                                                                    "lastUpdate" : 1694601967085.0,
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        },
                                                        {
                                                            "id" : "widget-Av4E7ix2CgQnLVTnu0xOY",
                                                            "name" : "form-text-input w-input",
                                                            "xtype" : "input",
                                                            "properties" : {
                                                                "required": true,
                                                                "fontStyle" : "inherit",
                                                                "fontVariantLigatures" : "inherit",
                                                                "fontVariantCaps" : "inherit",
                                                                "fontVariantNumeric" : "inherit",
                                                                "fontVariantEastAsian" : "inherit",
                                                                "fontVariantAlternates" : "inherit",
                                                                "fontWeight" : "500",
                                                                "fontStretch" : "inherit",
                                                                "fontOpticalSizing" : "inherit",
                                                                "fontKerning" : "inherit",
                                                                "fontFeatureSettings" : "inherit",
                                                                "fontVariationSettings" : "inherit",
                                                                "display" : "flex",
                                                                "paddingTop" : 9,
                                                                "paddingRight" : 9,
                                                                "paddingBottom" : 9,
                                                                "paddingLeft" : 9,
                                                                "marginBottom" : 7.5,
                                                                "verticalAlign" : "middle",
                                                                "borderTopWidth" : 0.75,
                                                                "borderRightWidth" : 0.75,
                                                                "borderBottomWidth" : 0.75,
                                                                "borderLeftWidth" : 0.75,
                                                                "borderTopStyle" : "solid",
                                                                "borderRightStyle" : "solid",
                                                                "borderBottomStyle" : "solid",
                                                                "borderLeftStyle" : "solid",
                                                                "borderTopColor" : "rgb(0, 0, 0)",
                                                                "borderRightColor" : "rgb(0, 0, 0)",
                                                                "borderBottomColor" : "rgb(0, 0, 0)",
                                                                "borderLeftColor" : "rgb(0, 0, 0)",
                                                                "borderImageSource" : "initial",
                                                                "borderImageSlice" : "initial",
                                                                "borderImageWidth" : "initial",
                                                                "borderImageOutset" : "initial",
                                                                "borderImageRepeat" : "initial",
                                                                "columnGap" : 6,
                                                                "rowGap" : 6,
                                                                "justifyContent" : "flex-start",
                                                                "alignItems" : "center",
                                                                "small" : {
                                                                    "fontWeight" : "inherit",
                                                                    "marginBottom" : 0,
                                                                    "backgroundAttachment" : "scroll",
                                                                    "display" : "block",
                                                                    "paddingTop" : 6,
                                                                    "paddingBottom" : 6,
                                                                    "borderTopColor" : "rgb(204, 204, 204)",
                                                                    "borderRightColor" : "rgb(204, 204, 204)",
                                                                    "borderBottomColor" : "rgb(204, 204, 204)",
                                                                    "borderLeftColor" : "rgb(204, 204, 204)"
                                                                },
                                                                "textAlign" : "center",
                                                                "alignSelf" : "stretch"
                                                            },
                                                            "children" : [

                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "04ee032714447b3969996545e60ce806fc850bd056134d799fe7b3fda34dc017",
                                                    "name" : "input-wrapper",
                                                    "xtype" : "field",
                                                    "properties" : {
                                                    	"fieldName" : "Email",
                                                        "columnGap" : 6,
                                                        "rowGap" : 6,
                                                        "flexDirection" : "column",
                                                        "justifyContent" : "flex-start",
                                                        "alignItems" : "flex-start",
                                                        "display" : "flex",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        },
                                                        "alignSelf" : "stretch"
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "dbfa865b4e31d4fcf99546f2da4519884b62d517a9ed2b35d642b69748c19e6f",
                                                            "name" : "form-block-label",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "display" : "block",
                                                                "marginBottom" : 3.75,
                                                                "fontWeight" : "400",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll",
                                                                    "fontWeight" : "bold"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "b1f03afec651961d8869634c53816d0c0c366215155c8461dd85d57f4a8f63f5",
                                                                    "xtype" : "label",
                                                                    "properties" : {
                                                                        "content" : "<p>Email</p>",
                                                                        "maxWidth" : "100%"
                                                                    },
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        },
                                                        {
                                                            "id" : "6c3e1543a35d82fe3836e6c9ee2576e20e722a53ea92813750b6fc8f5fbf9565",
                                                            "name" : "form-text-input-2 w-input",
                                                            "xtype" : "input",
                                                            "properties" : {
                                                                "required": true,
                                                                "fontStyle" : "inherit",
                                                                "fontVariantLigatures" : "inherit",
                                                                "fontVariantCaps" : "inherit",
                                                                "fontVariantNumeric" : "inherit",
                                                                "fontVariantEastAsian" : "inherit",
                                                                "fontVariantAlternates" : "inherit",
                                                                "fontWeight" : "500",
                                                                "fontStretch" : "inherit",
                                                                "fontOpticalSizing" : "inherit",
                                                                "fontKerning" : "inherit",
                                                                "fontFeatureSettings" : "inherit",
                                                                "fontVariationSettings" : "inherit",
                                                                "display" : "flex",
                                                                "paddingTop" : 9,
                                                                "paddingRight" : 9,
                                                                "paddingBottom" : 9,
                                                                "paddingLeft" : 9,
                                                                "marginBottom" : 7.5,
                                                                "verticalAlign" : "middle",
                                                                "borderTopWidth" : 0.75,
                                                                "borderRightWidth" : 0.75,
                                                                "borderBottomWidth" : 0.75,
                                                                "borderLeftWidth" : 0.75,
                                                                "borderTopStyle" : "solid",
                                                                "borderRightStyle" : "solid",
                                                                "borderBottomStyle" : "solid",
                                                                "borderLeftStyle" : "solid",
                                                                "borderTopColor" : "rgb(0, 0, 0)",
                                                                "borderRightColor" : "rgb(0, 0, 0)",
                                                                "borderBottomColor" : "rgb(0, 0, 0)",
                                                                "borderLeftColor" : "rgb(0, 0, 0)",
                                                                "borderImageSource" : "initial",
                                                                "borderImageSlice" : "initial",
                                                                "borderImageWidth" : "initial",
                                                                "borderImageOutset" : "initial",
                                                                "borderImageRepeat" : "initial",
                                                                "columnGap" : 6,
                                                                "rowGap" : 6,
                                                                "justifyContent" : "flex-start",
                                                                "alignItems" : "flex-start",
                                                                "small" : {
                                                                    "fontWeight" : "inherit",
                                                                    "marginBottom" : 0,
                                                                    "backgroundAttachment" : "scroll",
                                                                    "display" : "block",
                                                                    "paddingTop" : 6,
                                                                    "paddingBottom" : 6,
                                                                    "borderTopColor" : "rgb(204, 204, 204)",
                                                                    "borderRightColor" : "rgb(204, 204, 204)",
                                                                    "borderBottomColor" : "rgb(204, 204, 204)",
                                                                    "borderLeftColor" : "rgb(204, 204, 204)"
                                                                },
                                                                "textAlign" : "center",
                                                                "alignSelf" : "stretch",
                                                                "placeholder" : ""
                                                            },
                                                            "children" : [

                                                            ],
                                                            "lastUpdate" : 1694603259917.0,
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "widget-IBenYYoszklD5gm8dbMLP",
                                                    "name" : "Upload button",
                                                    "xtype" : "field",
                                                    "grouped" : true,
                                                    "properties" : {
                                                        "fieldName" : "Upload file",
                                                        "flexDirection" : "column",
                                                        "gap" : 6,
                                                        "alignSelf" : "stretch",
                                                        "justifyContent" : "center"
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "widget-ModsbOZJixQefFTQyhTV7",
                                                            "xtype" : "file-upload",
                                                            "properties" : {
                                                                "required" : true,
                                                                "acceptedFiles" : "image",
                                                                "alignSelf" : "stretch",
                                                                "justifyContent" : "center",
                                                                "borderTopLeftRadius" : 40,
                                                                "borderTopRightRadius" : 40,
                                                                "borderBottomLeftRadius" : 40,
                                                                "borderBottomRightRadius" : 40,
                                                                "paddingLeft" : 10,
                                                                "paddingRight" : 10,
                                                                "paddingTop" : 10,
                                                                "paddingBottom" : 10,
                                                                "marginLeft" : 6,
                                                                "marginRight" : 6,
                                                                "marginTop" : 16,
                                                                "marginBottom" : 6,
                                                                "color" : "white",
                                                                "backgroundColor" : "#292929",
                                                                "hover" : {
                                                                    "backgroundColor" : "#3E3E3E"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "widget-3FUsSrFvzfiDEMFEggfiW",
                                                                    "xtype" : "text",
                                                                    "properties" : {
                                                                        "content" : "<p>Upload file</p>"
                                                                    },
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "widget-yEnaSgMtvThYkClBkHwyp",
                                                    "name" : "Checkbox",
                                                    "xtype" : "field",
                                                    "grouped" : true,
                                                    "properties" : {
                                                        "fieldName" : "<Your field name goes here>",
                                                        "flexDirection" : "row",
                                                        "gap" : 6,
                                                        "alignSelf" : "flex-start",
                                                        "justifyContent" : "flex-start",
                                                        "paddingLeft" : 12,
                                                        "paddingRight" : 12,
                                                        "paddingTop" : 12,
                                                        "paddingBottom" : 12
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "widget-X3YG-_AXWRBNV17ppmg07",
                                                            "xtype" : "checkbox",
                                                            "properties" : {
                                                                "alignSelf" : "center",
                                                                "required" : true
                                                            },
                                                            "lastUpdate" : 1694603170125.0,
                                                            "children" : [

                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        },
                                                        {
                                                            "id" : "widget-h-9gyy62VnUqr8dpOcLyW",
                                                            "xtype" : "label",
                                                            "properties" : {
                                                                "content" : "<p>I am over 18 years old</p>",
                                                                "alignSelf" : "center",
                                                                "fontSize" : 11,
                                                                "color" : "rgb(17,24,39)",
                                                                "fontWeight" : 500
                                                            },
                                                            "lastUpdate" : 1694602336963.0,
                                                            "children" : [

                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "widget-5cfRxu0c4WXdcRsTEIyMn",
                                                    "name" : "Checkbox",
                                                    "xtype" : "field",
                                                    "grouped" : true,
                                                    "properties" : {
                                                        "fieldName" : "<Your field name goes here>",
                                                        "flexDirection" : "row",
                                                        "gap" : 6,
                                                        "alignSelf" : "flex-start",
                                                        "justifyContent" : "flex-start",
                                                        "paddingLeft" : 12,
                                                        "paddingRight" : 12,
                                                        "paddingTop" : 12,
                                                        "paddingBottom" : 12
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "widget-gXGrzxflUBlPYfEFXNXco",
                                                            "xtype" : "checkbox",
                                                            "properties" : {
                                                                "alignSelf" : "center",
                                                                "required" : true
                                                            },
                                                            "lastUpdate" : 1694603176397.0,
                                                            "children" : [

                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        },
                                                        {
                                                            "id" : "widget-MSgp9P5bp70KN-9K_PAKw",
                                                            "xtype" : "label",
                                                            "properties" : {
                                                                "content" : "<p>By entering I agree to receive email marketing</p>",
                                                                "alignSelf" : "center",
                                                                "fontSize" : 11,
                                                                "color" : "rgb(17,24,39)",
                                                                "fontWeight" : 500
                                                            },
                                                            "lastUpdate" : 1694606809227.0,
                                                            "children" : [

                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "c21dbb8090dcafd3600192c1a56da78cb767026ccda56523677aa34566274fdd",
                                                    "name" : "form-button w-button",
                                                    "xtype" : "submit-button",
                                                    "properties" : {
                                                        "fontStyle" : "inherit",
                                                        "fontVariantLigatures" : "inherit",
                                                        "fontVariantCaps" : "inherit",
                                                        "fontVariantNumeric" : "inherit",
                                                        "fontVariantEastAsian" : "inherit",
                                                        "fontVariantAlternates" : "inherit",
                                                        "fontWeight" : "500",
                                                        "fontStretch" : "inherit",
                                                        "fontOpticalSizing" : "inherit",
                                                        "fontKerning" : "inherit",
                                                        "fontFeatureSettings" : "inherit",
                                                        "fontVariationSettings" : "inherit",
                                                        "display" : "flex",
                                                        "paddingTop" : 9,
                                                        "paddingRight" : 18,
                                                        "paddingBottom" : 9,
                                                        "paddingLeft" : 18,
                                                        "borderTopWidth" : 0,
                                                        "borderRightWidth" : 0,
                                                        "borderBottomWidth" : 0,
                                                        "borderLeftWidth" : 0,
                                                        "borderTopStyle" : "initial",
                                                        "borderRightStyle" : "initial",
                                                        "borderBottomStyle" : "initial",
                                                        "borderLeftStyle" : "initial",
                                                        "borderTopColor" : "initial",
                                                        "borderRightColor" : "initial",
                                                        "borderBottomColor" : "initial",
                                                        "borderLeftColor" : "initial",
                                                        "borderImageSource" : "initial",
                                                        "borderImageSlice" : "initial",
                                                        "borderImageWidth" : "initial",
                                                        "borderImageOutset" : "initial",
                                                        "borderImageRepeat" : "initial",
                                                        "textDecorationLine" : "none",
                                                        "textDecorationThickness" : "initial",
                                                        "textDecorationStyle" : "initial",
                                                        "textDecorationColor" : "initial",
                                                        "cursor" : "pointer",
                                                        "borderTopLeftRadius" : 0,
                                                        "borderTopRightRadius" : 0,
                                                        "borderBottomRightRadius" : 0,
                                                        "borderBottomLeftRadius" : 0,
                                                        "appearance" : "button",
                                                        "columnGap" : 6,
                                                        "rowGap" : 6,
                                                        "justifyContent" : "center",
                                                        "alignItems" : "center",
                                                        "small" : {
                                                            "fontWeight" : "inherit",
                                                            "backgroundAttachment" : "scroll",
                                                            "display" : "inline-block",
                                                            "paddingTop" : 6.75,
                                                            "paddingRight" : 11.25,
                                                            "paddingBottom" : 6.75,
                                                            "paddingLeft" : 11.25
                                                        },
                                                        "alignSelf" : "center"
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "ba6da065c17bdaf49ce11de8de2ade1b9e3f8f1481649f2b7c8055f58d3236cf",
                                                            "xtype" : "text",
                                                            "properties" : {
                                                                "content" : "<p>Submit</p>"
                                                            },
                                                            "lastUpdate" : 1694602163393.0,
                                                            "children" : [

                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "fields" : [

                                            ],
                                            "formId" : "6d973220-26b3-44bc-86ee-edaaf76e9001",
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "widgets" : [

                                    ]
                                }
                            ],
                            "widgets" : [

                            ],
                            "lastUpdate" : 1694608053341.0
                        }
                    ],
                    "widgets" : [

                    ]
                },
                {
                    "id" : "cd36287365332a581715902a1cbb3d38f033ae62ea81b99bc39655e3a3bfc48c",
                    "name" : "team-rectangles",
                    "xtype" : "group",
                    "properties" : {
                        "columnGap" : 48,
                        "rowGap" : 48,
                        "backgroundColor" : "rgb(245, 247, 250)",
                        "flexDirection" : "column",
                        "justifyContent" : "flex-start",
                        "alignItems" : "center",
                        "paddingTop" : 48,
                        "paddingRight" : 18,
                        "paddingBottom" : 48,
                        "paddingLeft" : 18,
                        "display" : "flex",
                        "small" : {
                            "backgroundAttachment" : "scroll"
                        }
                    },
                    "children" : [
                        {
                            "id" : "be114b3c03f237d9735905601bf6c7aeabc1bfcdf0a43c7814088c71ceabe639",
                            "name" : "container-3",
                            "xtype" : "group",
                            "properties" : {
                                "maxWidth" : 900,
                                "columnGap" : 48,
                                "rowGap" : 48,
                                "flexDirection" : "column",
                                "justifyContent" : "flex-start",
                                "alignItems" : "center",
                                "display" : "flex",
                                "small" : {
                                    "backgroundAttachment" : "scroll"
                                }
                            },
                            "children" : [
                                {
                                    "id" : "df7743b6ab9f90ccf3f854bbd68d928676884e789fc7c482c42287034d7b84e1",
                                    "name" : "section-title",
                                    "xtype" : "group",
                                    "properties" : {
                                        "maxWidth" : 397.5,
                                        "columnGap" : 12,
                                        "rowGap" : 12,
                                        "flexDirection" : "column",
                                        "justifyContent" : "flex-start",
                                        "alignItems" : "center",
                                        "display" : "flex",
                                        "small" : {
                                            "backgroundAttachment" : "scroll"
                                        }
                                    },
                                    "children" : [
                                        {
                                            "id" : "3f5b6ebe6f692fa1a02095c69a511acb7e6bde907b2494e0ad2804228eb2bb20",
                                            "name" : "text-25",
                                            "xtype" : "group",
                                            "properties" : {
                                                "fontSize" : 24,
                                                "textAlign" : "center",
                                                "fontWeight" : "700",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "0415363430a5154d4919568cb8bdca80ccfaa42e53b7fccaaaa4a92cf53cedcd",
                                                    "xtype" : "label",
                                                    "properties" : {
                                                        "content" : "<p>Prizes to be won</p>",
                                                        "maxWidth" : "100%"
                                                    },
                                                    "lastUpdate" : 1694607117326.0,
                                                    "children" : [

                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "widgets" : [

                                            ]
                                        },
                                        {
                                            "id" : "d0d5fe4aaad4bedfaadb53a2f49c34ac299d52d86c34917341e596bb9e7d7644",
                                            "name" : "text-26",
                                            "xtype" : "group",
                                            "properties" : {
                                                "fontSize" : 13.5,
                                                "textAlign" : "center",
                                                "fontWeight" : "400",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "addbe41dbbfbaa6460126c72f5eb064699166ddcb50332f6d9d75f6fc4bfef9b",
                                                    "xtype" : "label",
                                                    "properties" : {
                                                        "content" : "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse tincidunt sagittis eros. Quisque quis euismod lorem.</p>",
                                                        "maxWidth" : "100%"
                                                    },
                                                    "children" : [

                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "widgets" : [

                                    ]
                                },
                                {
                                    "id" : "1d01948eeaad587e5d0ba365301b94e12301833738de8dc97a3b0304d2f6eee9",
                                    "name" : "columns-2",
                                    "xtype" : "group",
                                    "properties" : {
                                        "columnGap" : 36,
                                        "rowGap" : 36,
                                        "justifyContent" : "flex-start",
                                        "alignItems" : "flex-start",
                                        "display" : "flex",
                                        "small" : {
                                            "backgroundAttachment" : "scroll",
                                            "flexDirection" : "column",
                                            "alignItems" : "center"
                                        }
                                    },
                                    "children" : [
                                        {
                                            "id" : "e7a282d42a280fadb6d5ef4545e67698a7fb39799fabf811cdb7029beb9ff84d",
                                            "name" : "card-2",
                                            "xtype" : "group",
                                            "properties" : {
                                                "flexDirection" : "column",
                                                "justifyContent" : "flex-start",
                                                "alignItems" : "flex-start",
                                                "display" : "flex",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "86c69d9241f5365f969dcde45b99fa1111ea505bcefd25f2f2bf03b1a7acfb1e",
                                                    "name" : "image-wrapper-4",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "columnGap" : 7.5,
                                                        "rowGap" : 7.5,
                                                        "flexDirection" : "column",
                                                        "justifyContent" : "flex-start",
                                                        "alignItems" : "flex-start",
                                                        "display" : "flex",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "22ae16cbd7582bacfcf3d4a668557bc86841893e349fbaa3272dd81d66f27548",
                                                            "name" : "image-4",
                                                            "xtype" : "image",
                                                            "properties" : {
                                                                "borderTopWidth" : 0,
                                                                "borderRightWidth" : 0,
                                                                "borderBottomWidth" : 0,
                                                                "borderLeftWidth" : 0,
                                                                "borderTopStyle" : "initial",
                                                                "borderRightStyle" : "initial",
                                                                "borderBottomStyle" : "initial",
                                                                "borderLeftStyle" : "initial",
                                                                "borderTopColor" : "initial",
                                                                "borderRightColor" : "initial",
                                                                "borderBottomColor" : "initial",
                                                                "borderLeftColor" : "initial",
                                                                "borderImageSource" : "initial",
                                                                "borderImageSlice" : "initial",
                                                                "borderImageWidth" : "initial",
                                                                "borderImageOutset" : "initial",
                                                                "borderImageRepeat" : "initial",
                                                                "maxWidth" : "100%",
                                                                "verticalAlign" : "middle",
                                                                "display" : "flex",
                                                                "objectFit" : "cover",
                                                                "justifyContent" : "center",
                                                                "alignItems" : "center",
                                                                "small" : {
                                                                    "display" : "inline-block",
                                                                    "backgroundAttachment" : "scroll"
                                                                },
                                                                "src" : "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910ccd439872d9cfc5d96_Image.png",
                                                                "alt" : "",
                                                                "maxHeight" : "100%"
                                                            },
                                                            "children" : [

                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "ba2e4cc2256a9f72f299ed8c318e031da9cf03aa3aa458221079d77a148d519a",
                                                    "name" : "info-2",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "flexDirection" : "column",
                                                        "justifyContent" : "flex-start",
                                                        "alignItems" : "flex-start",
                                                        "paddingTop" : 18,
                                                        "paddingRight" : 18,
                                                        "paddingBottom" : 18,
                                                        "paddingLeft" : 18,
                                                        "display" : "flex",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "f7059d1f3aa823bfd4e32d8d38f602a97f66dad2c9a281b72b827c1d208c4455",
                                                            "name" : "name",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "fontWeight" : "600",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "6095e3b5fa71027db111ea1b34a6b5aa9771fb46e9533a5f49c9eb0398e88249",
                                                                    "xtype" : "label",
                                                                    "properties" : {
                                                                        "content" : "<p>Prize Name</p>",
                                                                        "maxWidth" : "100%"
                                                                    },
                                                                    "lastUpdate" : 1694607154607.0,
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        },
                                                        {
                                                            "id" : "18b4109bc814ed78d0d26b2b1b280a66ef18cf0ea8ea4aea47731d0b60049e13",
                                                            "name" : "description",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "fontWeight" : "400",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "9bb294a6bc71b7d844ea01f8f5c31ebfe157ec5495131a8f233d66f55cdd5d7c",
                                                                    "xtype" : "label",
                                                                    "properties" : {
                                                                        "content" : "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>",
                                                                        "maxWidth" : "100%"
                                                                    },
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "widgets" : [

                                            ]
                                        },
                                        {
                                            "id" : "b19b25e6c699fadc01ad5fdbfa037c43e432a01726cbd06ddd14975aff0f40f0",
                                            "name" : "card-2",
                                            "xtype" : "group",
                                            "properties" : {
                                                "flexDirection" : "column",
                                                "justifyContent" : "flex-start",
                                                "alignItems" : "flex-start",
                                                "display" : "flex",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "65c8009b50f772feb7e9051b31c15000e49ba344fbc159065cb3e3f9d97d536d",
                                                    "name" : "image-wrapper-4",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "columnGap" : 7.5,
                                                        "rowGap" : 7.5,
                                                        "flexDirection" : "column",
                                                        "justifyContent" : "flex-start",
                                                        "alignItems" : "flex-start",
                                                        "display" : "flex",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "773d3f2898e675c9b372c2f953bc7dab2141a797d7fa72be23956f9f36f10673",
                                                            "name" : "image-4",
                                                            "xtype" : "image",
                                                            "properties" : {
                                                                "borderTopWidth" : 0,
                                                                "borderRightWidth" : 0,
                                                                "borderBottomWidth" : 0,
                                                                "borderLeftWidth" : 0,
                                                                "borderTopStyle" : "initial",
                                                                "borderRightStyle" : "initial",
                                                                "borderBottomStyle" : "initial",
                                                                "borderLeftStyle" : "initial",
                                                                "borderTopColor" : "initial",
                                                                "borderRightColor" : "initial",
                                                                "borderBottomColor" : "initial",
                                                                "borderLeftColor" : "initial",
                                                                "borderImageSource" : "initial",
                                                                "borderImageSlice" : "initial",
                                                                "borderImageWidth" : "initial",
                                                                "borderImageOutset" : "initial",
                                                                "borderImageRepeat" : "initial",
                                                                "maxWidth" : "100%",
                                                                "verticalAlign" : "middle",
                                                                "display" : "flex",
                                                                "objectFit" : "cover",
                                                                "justifyContent" : "center",
                                                                "alignItems" : "center",
                                                                "small" : {
                                                                    "display" : "inline-block",
                                                                    "backgroundAttachment" : "scroll"
                                                                },
                                                                "src" : "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910ccd439872d9cfc5d96_Image.png",
                                                                "alt" : "",
                                                                "maxHeight" : "100%"
                                                            },
                                                            "children" : [

                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "3a9d5d61cab0361ae4844514660188a921139c980089029be1997599c13ace84",
                                                    "name" : "info-2",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "flexDirection" : "column",
                                                        "justifyContent" : "flex-start",
                                                        "alignItems" : "flex-start",
                                                        "paddingTop" : 18,
                                                        "paddingRight" : 18,
                                                        "paddingBottom" : 18,
                                                        "paddingLeft" : 18,
                                                        "display" : "flex",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "0bf740d5a4d2e896b731d2c2d781e6da8d7ac6964ff46cd80134d5d274f20834",
                                                            "name" : "name",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "fontWeight" : "600",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "7c700ebf845cf5877cee0e0a3d891f79c4593dbbd078015b5d6c3cf214a7f0dc",
                                                                    "xtype" : "label",
                                                                    "properties" : {
                                                                        "content" : "<p>Prize Name</p>",
                                                                        "maxWidth" : "100%"
                                                                    },
                                                                    "lastUpdate" : 1694607159217.0,
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        },
                                                        {
                                                            "id" : "7e59eea7943779c460d5c6fc35709096d5b396a7974f2a855ea530a580ae68ce",
                                                            "name" : "description",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "fontWeight" : "400",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "cd95fe99bbb3201946172a26b1da1441640827fe5bfc729379243bee4dfd25a7",
                                                                    "xtype" : "label",
                                                                    "properties" : {
                                                                        "content" : "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>",
                                                                        "maxWidth" : "100%"
                                                                    },
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "widgets" : [

                                            ]
                                        },
                                        {
                                            "id" : "83caaf608e8771bcd702778848155e859b9c5df180ceafbe0ac7aaafc14e37b8",
                                            "name" : "card-2",
                                            "xtype" : "group",
                                            "properties" : {
                                                "flexDirection" : "column",
                                                "justifyContent" : "flex-start",
                                                "alignItems" : "flex-start",
                                                "display" : "flex",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "83107c157b85e88415af530a5efb69ed58f14670bf682195fba5b4444944beeb",
                                                    "name" : "image-wrapper-4",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "columnGap" : 7.5,
                                                        "rowGap" : 7.5,
                                                        "flexDirection" : "column",
                                                        "justifyContent" : "flex-start",
                                                        "alignItems" : "flex-start",
                                                        "display" : "flex",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "df2af532a2fba4b9a495d813f43545e7913471139f2af6728db4e5baee8296df",
                                                            "name" : "image-4",
                                                            "xtype" : "image",
                                                            "properties" : {
                                                                "borderTopWidth" : 0,
                                                                "borderRightWidth" : 0,
                                                                "borderBottomWidth" : 0,
                                                                "borderLeftWidth" : 0,
                                                                "borderTopStyle" : "initial",
                                                                "borderRightStyle" : "initial",
                                                                "borderBottomStyle" : "initial",
                                                                "borderLeftStyle" : "initial",
                                                                "borderTopColor" : "initial",
                                                                "borderRightColor" : "initial",
                                                                "borderBottomColor" : "initial",
                                                                "borderLeftColor" : "initial",
                                                                "borderImageSource" : "initial",
                                                                "borderImageSlice" : "initial",
                                                                "borderImageWidth" : "initial",
                                                                "borderImageOutset" : "initial",
                                                                "borderImageRepeat" : "initial",
                                                                "maxWidth" : "100%",
                                                                "verticalAlign" : "middle",
                                                                "display" : "flex",
                                                                "objectFit" : "cover",
                                                                "justifyContent" : "center",
                                                                "alignItems" : "center",
                                                                "small" : {
                                                                    "display" : "inline-block",
                                                                    "backgroundAttachment" : "scroll"
                                                                },
                                                                "src" : "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910ccd439872d9cfc5d96_Image.png",
                                                                "alt" : "",
                                                                "maxHeight" : "100%"
                                                            },
                                                            "children" : [

                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "7b772ab0487adfe547aff32936d7b2046b0726408084d4c993ee850ab5a64216",
                                                    "name" : "info-2",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "flexDirection" : "column",
                                                        "justifyContent" : "flex-start",
                                                        "alignItems" : "flex-start",
                                                        "paddingTop" : 18,
                                                        "paddingRight" : 18,
                                                        "paddingBottom" : 18,
                                                        "paddingLeft" : 18,
                                                        "display" : "flex",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "b0744b94c3ac5c0679a2a19339a9fadf73af59a66cf98bc623ea737d8897fff2",
                                                            "name" : "name",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "fontWeight" : "600",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "6bc54856cf3d4bb9c2de30ea8ed271ea93bf87356d02f9743a0454170cfa4d05",
                                                                    "xtype" : "label",
                                                                    "properties" : {
                                                                        "content" : "<p>Prize Name</p>",
                                                                        "maxWidth" : "100%"
                                                                    },
                                                                    "lastUpdate" : 1694607162068.0,
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        },
                                                        {
                                                            "id" : "ff615d97de4a5fd39ebb306e5d2f5946a480d5768411d52c518b8cf12d6b842f",
                                                            "name" : "description",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "fontWeight" : "400",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "90362ae632370363fddf0c3073bc86538952b8fb4b3d3815efae06064c211f6d",
                                                                    "xtype" : "label",
                                                                    "properties" : {
                                                                        "content" : "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>",
                                                                        "maxWidth" : "100%"
                                                                    },
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "widgets" : [

                                    ]
                                },
                                {
                                    "id" : "widget-ltjiXoBGI7gJmO_0BQG7v",
                                    "name" : "columns-2",
                                    "xtype" : "group",
                                    "properties" : {
                                        "columnGap" : 36,
                                        "rowGap" : 36,
                                        "justifyContent" : "flex-start",
                                        "alignItems" : "flex-start",
                                        "display" : "flex",
                                        "small" : {
                                            "backgroundAttachment" : "scroll",
                                            "flexDirection" : "column",
                                            "alignItems" : "center"
                                        }
                                    },
                                    "children" : [
                                        {
                                            "id" : "widget-AEwBEguGfqlN11Hqk-Ryi",
                                            "name" : "card-2",
                                            "xtype" : "group",
                                            "properties" : {
                                                "flexDirection" : "column",
                                                "justifyContent" : "flex-start",
                                                "alignItems" : "flex-start",
                                                "display" : "flex",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "widget-lJEU2AobLNoWsNx3yWcKh",
                                                    "name" : "image-wrapper-4",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "columnGap" : 7.5,
                                                        "rowGap" : 7.5,
                                                        "flexDirection" : "column",
                                                        "justifyContent" : "flex-start",
                                                        "alignItems" : "flex-start",
                                                        "display" : "flex",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "widget-dP7JVaMhNtCexBeDND0MA",
                                                            "name" : "image-4",
                                                            "xtype" : "image",
                                                            "properties" : {
                                                                "borderTopWidth" : 0,
                                                                "borderRightWidth" : 0,
                                                                "borderBottomWidth" : 0,
                                                                "borderLeftWidth" : 0,
                                                                "borderTopStyle" : "initial",
                                                                "borderRightStyle" : "initial",
                                                                "borderBottomStyle" : "initial",
                                                                "borderLeftStyle" : "initial",
                                                                "borderTopColor" : "initial",
                                                                "borderRightColor" : "initial",
                                                                "borderBottomColor" : "initial",
                                                                "borderLeftColor" : "initial",
                                                                "borderImageSource" : "initial",
                                                                "borderImageSlice" : "initial",
                                                                "borderImageWidth" : "initial",
                                                                "borderImageOutset" : "initial",
                                                                "borderImageRepeat" : "initial",
                                                                "maxWidth" : "100%",
                                                                "verticalAlign" : "middle",
                                                                "display" : "flex",
                                                                "objectFit" : "cover",
                                                                "justifyContent" : "center",
                                                                "alignItems" : "center",
                                                                "small" : {
                                                                    "display" : "inline-block",
                                                                    "backgroundAttachment" : "scroll"
                                                                },
                                                                "src" : "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910ccd439872d9cfc5d96_Image.png",
                                                                "alt" : "",
                                                                "maxHeight" : "100%"
                                                            },
                                                            "children" : [

                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "widget-3LjQVePSu3dMttyQ0_gm0",
                                                    "name" : "info-2",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "flexDirection" : "column",
                                                        "justifyContent" : "flex-start",
                                                        "alignItems" : "flex-start",
                                                        "paddingTop" : 18,
                                                        "paddingRight" : 18,
                                                        "paddingBottom" : 18,
                                                        "paddingLeft" : 18,
                                                        "display" : "flex",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "widget-3jvbaYgqpeg5C9dwUogS6",
                                                            "name" : "name",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "fontWeight" : "600",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "widget-IHc5nia-rquK6MY5DDi20",
                                                                    "xtype" : "label",
                                                                    "properties" : {
                                                                        "content" : "<p>Prize Name</p>",
                                                                        "maxWidth" : "100%"
                                                                    },
                                                                    "lastUpdate" : 1694607154607.0,
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        },
                                                        {
                                                            "id" : "widget-CzqVbqKxtEelIQpRJ84VF",
                                                            "name" : "description",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "fontWeight" : "400",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "widget-7SSGTTiEK8e8U9mgrh5w3",
                                                                    "xtype" : "label",
                                                                    "properties" : {
                                                                        "content" : "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>",
                                                                        "maxWidth" : "100%"
                                                                    },
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "widgets" : [

                                            ]
                                        },
                                        {
                                            "id" : "widget-gj-78ze-DRZwXkd4VtfTt",
                                            "name" : "card-2",
                                            "xtype" : "group",
                                            "properties" : {
                                                "flexDirection" : "column",
                                                "justifyContent" : "flex-start",
                                                "alignItems" : "flex-start",
                                                "display" : "flex",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "widget-C2C_jm4UND_he7AglDGU0",
                                                    "name" : "image-wrapper-4",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "columnGap" : 7.5,
                                                        "rowGap" : 7.5,
                                                        "flexDirection" : "column",
                                                        "justifyContent" : "flex-start",
                                                        "alignItems" : "flex-start",
                                                        "display" : "flex",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "widget-72bOMXqM_Q4Bt8i-FAq_0",
                                                            "name" : "image-4",
                                                            "xtype" : "image",
                                                            "properties" : {
                                                                "borderTopWidth" : 0,
                                                                "borderRightWidth" : 0,
                                                                "borderBottomWidth" : 0,
                                                                "borderLeftWidth" : 0,
                                                                "borderTopStyle" : "initial",
                                                                "borderRightStyle" : "initial",
                                                                "borderBottomStyle" : "initial",
                                                                "borderLeftStyle" : "initial",
                                                                "borderTopColor" : "initial",
                                                                "borderRightColor" : "initial",
                                                                "borderBottomColor" : "initial",
                                                                "borderLeftColor" : "initial",
                                                                "borderImageSource" : "initial",
                                                                "borderImageSlice" : "initial",
                                                                "borderImageWidth" : "initial",
                                                                "borderImageOutset" : "initial",
                                                                "borderImageRepeat" : "initial",
                                                                "maxWidth" : "100%",
                                                                "verticalAlign" : "middle",
                                                                "display" : "flex",
                                                                "objectFit" : "cover",
                                                                "justifyContent" : "center",
                                                                "alignItems" : "center",
                                                                "small" : {
                                                                    "display" : "inline-block",
                                                                    "backgroundAttachment" : "scroll"
                                                                },
                                                                "src" : "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910ccd439872d9cfc5d96_Image.png",
                                                                "alt" : "",
                                                                "maxHeight" : "100%"
                                                            },
                                                            "children" : [

                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "widget-Ed5DS-0sNIPH86qT8NlNv",
                                                    "name" : "info-2",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "flexDirection" : "column",
                                                        "justifyContent" : "flex-start",
                                                        "alignItems" : "flex-start",
                                                        "paddingTop" : 18,
                                                        "paddingRight" : 18,
                                                        "paddingBottom" : 18,
                                                        "paddingLeft" : 18,
                                                        "display" : "flex",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "widget-6_0U9m0qPeGMwMWPrq-kR",
                                                            "name" : "name",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "fontWeight" : "600",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "widget-TvyH1kPTQXEWpPPteV2S4",
                                                                    "xtype" : "label",
                                                                    "properties" : {
                                                                        "content" : "<p>Prize Name</p>",
                                                                        "maxWidth" : "100%"
                                                                    },
                                                                    "lastUpdate" : 1694607159217.0,
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        },
                                                        {
                                                            "id" : "widget-rPRawfZ52BCbHRA2tg3yW",
                                                            "name" : "description",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "fontWeight" : "400",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "widget-md4A8_rBhh35oLAmZDxcG",
                                                                    "xtype" : "label",
                                                                    "properties" : {
                                                                        "content" : "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>",
                                                                        "maxWidth" : "100%"
                                                                    },
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "widgets" : [

                                            ]
                                        },
                                        {
                                            "id" : "widget-rT5mgOjSgGwViahy8ENKE",
                                            "name" : "card-2",
                                            "xtype" : "group",
                                            "properties" : {
                                                "flexDirection" : "column",
                                                "justifyContent" : "flex-start",
                                                "alignItems" : "flex-start",
                                                "display" : "flex",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "widget-E-Hg2nnZarxO2oqSWhzXd",
                                                    "name" : "image-wrapper-4",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "columnGap" : 7.5,
                                                        "rowGap" : 7.5,
                                                        "flexDirection" : "column",
                                                        "justifyContent" : "flex-start",
                                                        "alignItems" : "flex-start",
                                                        "display" : "flex",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "widget-ldFaQRpa6RCmMQ-ufYoBZ",
                                                            "name" : "image-4",
                                                            "xtype" : "image",
                                                            "properties" : {
                                                                "borderTopWidth" : 0,
                                                                "borderRightWidth" : 0,
                                                                "borderBottomWidth" : 0,
                                                                "borderLeftWidth" : 0,
                                                                "borderTopStyle" : "initial",
                                                                "borderRightStyle" : "initial",
                                                                "borderBottomStyle" : "initial",
                                                                "borderLeftStyle" : "initial",
                                                                "borderTopColor" : "initial",
                                                                "borderRightColor" : "initial",
                                                                "borderBottomColor" : "initial",
                                                                "borderLeftColor" : "initial",
                                                                "borderImageSource" : "initial",
                                                                "borderImageSlice" : "initial",
                                                                "borderImageWidth" : "initial",
                                                                "borderImageOutset" : "initial",
                                                                "borderImageRepeat" : "initial",
                                                                "maxWidth" : "100%",
                                                                "verticalAlign" : "middle",
                                                                "display" : "flex",
                                                                "objectFit" : "cover",
                                                                "justifyContent" : "center",
                                                                "alignItems" : "center",
                                                                "small" : {
                                                                    "display" : "inline-block",
                                                                    "backgroundAttachment" : "scroll"
                                                                },
                                                                "src" : "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910ccd439872d9cfc5d96_Image.png",
                                                                "alt" : "",
                                                                "maxHeight" : "100%"
                                                            },
                                                            "children" : [

                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "widget--aXgntbO0FbqoLkuf8pLv",
                                                    "name" : "info-2",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "flexDirection" : "column",
                                                        "justifyContent" : "flex-start",
                                                        "alignItems" : "flex-start",
                                                        "paddingTop" : 18,
                                                        "paddingRight" : 18,
                                                        "paddingBottom" : 18,
                                                        "paddingLeft" : 18,
                                                        "display" : "flex",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "widget-OPvgxfFucaDNzvFBOepg5",
                                                            "name" : "name",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "fontWeight" : "600",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "widget-sTNPQ1GeOapHM6tTWesKG",
                                                                    "xtype" : "label",
                                                                    "properties" : {
                                                                        "content" : "<p>Prize Name</p>",
                                                                        "maxWidth" : "100%"
                                                                    },
                                                                    "lastUpdate" : 1694607162068.0,
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        },
                                                        {
                                                            "id" : "widget-4t9wAusNprSKFfF88y5Td",
                                                            "name" : "description",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "fontWeight" : "400",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "widget-K9J1PcIHFQM899f40NOCW",
                                                                    "xtype" : "label",
                                                                    "properties" : {
                                                                        "content" : "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>",
                                                                        "maxWidth" : "100%"
                                                                    },
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "widgets" : [

                                    ]
                                }
                            ],
                            "widgets" : [

                            ]
                        }
                    ],
                    "widgets" : [

                    ]
                },
                {
                    "id" : "0490d801f45c86e5533e44270180ab5a3c6368e3a6a67a8b5f2ad25fdcfdb8f5",
                    "name" : "hero-no-image",
                    "xtype" : "group",
                    "properties" : {
                        "columnGap" : 60,
                        "rowGap" : 60,
                        "flexDirection" : "column",
                        "justifyContent" : "flex-start",
                        "alignItems" : "center",
                        "paddingTop" : 48,
                        "paddingRight" : 18,
                        "paddingBottom" : 48,
                        "paddingLeft" : 18,
                        "display" : "flex",
                        "small" : {
                            "backgroundAttachment" : "scroll"
                        }
                    },
                    "children" : [
                        {
                            "id" : "524c238eb623298ba6fb0403b002176ea7058d717664c5e8c83f1231ec1526fa",
                            "name" : "small-container-2",
                            "xtype" : "group",
                            "properties" : {
                                "maxWidth" : 720,
                                "columnGap" : 30,
                                "rowGap" : 30,
                                "backgroundColor" : "rgb(245, 247, 250)",
                                "borderTopLeftRadius" : 12,
                                "borderTopRightRadius" : 12,
                                "borderBottomRightRadius" : 12,
                                "borderBottomLeftRadius" : 12,
                                "flexDirection" : "column",
                                "justifyContent" : "flex-start",
                                "alignItems" : "center",
                                "paddingTop" : 24,
                                "paddingRight" : 24,
                                "paddingBottom" : 24,
                                "paddingLeft" : 24,
                                "display" : "flex",
                                "small" : {
                                    "backgroundAttachment" : "scroll"
                                }
                            },
                            "children" : [
                                {
                                    "id" : "708f70cd597fc567a1c79211163a332f2ddebf1d6761ae306169883744d44d7e",
                                    "name" : "content",
                                    "xtype" : "group",
                                    "properties" : {
                                        "columnGap" : 18,
                                        "rowGap" : 18,
                                        "flexDirection" : "column",
                                        "justifyContent" : "flex-start",
                                        "alignItems" : "center",
                                        "display" : "flex",
                                        "small" : {
                                            "backgroundAttachment" : "scroll"
                                        }
                                    },
                                    "children" : [
                                        {
                                            "id" : "c4aa65e44e746a949cf38bffaa2e99e04b0132aa3d8ed13084b9ef6a7bec67a2",
                                            "name" : "text-12",
                                            "xtype" : "group",
                                            "properties" : {
                                                "fontSize" : 36,
                                                "textAlign" : "center",
                                                "fontWeight" : "700",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "68eb0da4d347f847fa6ea18da13e1893ea75179f65be7ad4924ced6589a2dfea",
                                                    "xtype" : "text",
                                                    "properties" : {
                                                        "content" : "<p>Terms and Conditions</p>",
                                                        "maxWidth" : "100%"
                                                    },
                                                    "lastUpdate" : 1694612555303.0
                                                }
                                            ]
                                        },
                                        {
                                            "id" : "d909da017dbc368ea88a19b2ce2f5d6beea9971c897c231481c751204ba225a5",
                                            "name" : "lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit-suspendisse-varius-enim-in-eros-elementum-tristique",
                                            "xtype" : "group",
                                            "properties" : {
                                                "textAlign" : "center",
                                                "fontWeight" : "400",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "4eb19339723a24fba2edeaad3abacb07227543489c82c02b43581366bec62015",
                                                    "xtype" : "text",
                                                    "properties" : {
                                                        "content" : "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</p>",
                                                        "maxWidth" : "100%"
                                                    }
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ],
    "thumbnail" : null,
    "from_template" : true,
    "campaignName" : "Upload to Win Template",
    "campaignDescription" : "",
    "metaImageUrl" : "",
    "metaImageFile" : null,
    "screenshot" : "",
    "redirect" : false,
    "redirectUrl" : "",
    "templateCategory" : "",
    "isPublicTemplate" : false,
    "status" : "published",
    "editMode" : true,
    "createdAt" : "2023-09-13T13:44:38.824Z",
    "lastModified" : "2023-09-13T13:44:30.380Z",
    "scans" : 2,
    "deployQRUrl" : "https://storage.googleapis.com/quicklinks---staging.appspot.com/campaign/Hf7F58y0nlVatZxg4L7N6ESu5AL2/474fSEY9-oBQupHDVqCpU/qrcode/Upload%20to%20Win%20Template_1694612669584.png?GoogleAccessId=firebase-adminsdk-w2673%40quicklinks---staging.iam.gserviceaccount.com&Expires=16447017600&Signature=ItwDULfBjKXfwMVWPoAqL1%2BrJGNS1%2FHhekSyBT33%2Br2cybAbbzWBj%2B4pYEiYJtu8oAofuRIeocCkdB2zX5DwJ3a%2F5Cbp5jtlQQRUoqg0zdxUtLjgT7brAr6QOrx3JbAGBPgKYy224RManxZjZj%2B7M5R9VaxVZObjxGRmi7EglIi5fRxggSZs8XVHKD2HUTEXU4%2FyyRnCq5i2dXMDPaoeh6YEXJZZX4tEk%2Fo56j7ywWI5UtjovVkQkQTpPnNOY4W5vOdTPe8S3KgIUaWOCzFy9bkRbW%2BlSh4aLDfYrJBoL%2Frou7pt2R5COSdCzZpF3gOHEa6jI51dc9suluuKtAE3%2BA%3D%3D",
    "analytics" : {
        "googleAnalytics" : {
            "enabled" : false,
            "trackingId" : ""
        },
        "tagManagerSettings" : {
            "enabled" : false,
            "appName" : "deploy-link",
            "containerId" : "GTM-",
            "dataLayerName" : "customDataLayer"
        }
    },
    "qrcode" : {
        "value" : "",
        "ecLevel" : "M",
        "enableCORS" : true,
        "size" : 200,
        "quietZone" : 10,
        "bgColor" : "rgba(0,0,0,0)",
        "fgColor" : "#000000",
        "logoImage" : "",
        "logoWidth" : 50,
        "logoHeight" : 50,
        "logoOpacity" : 0.5,
        "qrStyle" : "squares"
    },
    "version" : 4,
    "campaignId" : "474fSEY9-oBQupHDVqCpU",
    "deployUrl" : "https://staging.deploy.link/v2/samhussey/Upload-to-Win-Template",
    "oldVersion" : 4,
    "lastUpdate" : 1694612671191.0,
    "fonts" : [

    ]
}