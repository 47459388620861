import React, { FC, memo } from 'react'
import styled from 'styled-components';
import { useWidget } from 'widgets-base';

interface Props {
}

const SpotifyWidget: FC<Props> = () => {

    const { widget } = useWidget();
    const appearanceSettings = widget?.appearanceSettings
    const width = appearanceSettings?.width
    const height = widget?.settings.mode === 'large' ? 380 : 80
    const title = widget?.settings?.title
    const uri = widget?.settings?.uri
    const theme = widget?.settings?.theme
    const src = `https://open.spotify.com/embed/${uri}${theme ? '&theme=0' : ''}`

    const globalStyles = {
        marginTop: appearanceSettings.marginTop,
        marginBottom: appearanceSettings.marginBottom,
        marginLeft: appearanceSettings.marginLeft,
        marginRight: appearanceSettings.marginRight,
    }

    return (
        <SpotifySection id={widget.id}>
            <Container style={globalStyles}>
                <iframe
                    loading="lazy"
                    frameBorder="0"
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                    style={{ borderRadius: '12px' }}
                    title={title}
                    src={src}
                    width={width}
                    height={height}
                />
            </Container>
        </SpotifySection>
    )
}

export default memo(SpotifyWidget)

const SpotifySection = styled.section`
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Container = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 95%;
`
