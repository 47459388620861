import { Divider } from '@mui/material'
import { FC } from 'react'

import { WidgetSettingContainer } from '../../../Design/WidgetSettingContainer'
import {
    Alignment,
    Colors,
    Fonts,
    Height,
    Margins,
    Paddings,
    Sizer,
    Width,
} from '../../../EditMode/Design'
import { useWidget } from 'widgets-base'

interface Props {
}

const AudioDesignSetting: FC<Props> = () => {
    
    const { widget } = useWidget();

    return (
        <WidgetSettingContainer padding={0}>
            <div style={{ display: 'flex', gap: '10px' }}>
                <Fonts
                    label="Select font"
                    defaultFont={widget?.appearanceSettings?.font?.family}
                />
                <Sizer
                   
                    label="Font size"
                    value={widget?.appearanceSettings?.font?.size}
                    name="size"
                />
            </div>
            <Divider />
            <Width width={widget?.appearanceSettings?.width} />
            <Height height={widget?.appearanceSettings?.height} />
            <Alignment align={widget?.appearanceSettings?.align} />
            <Divider />
            <Margins
                topValue={widget?.appearanceSettings?.marginTop}
                bottomValue={widget?.appearanceSettings?.marginBottom}
                leftValue={widget?.appearanceSettings?.marginLeft}
                rightValue={widget?.appearanceSettings?.marginRight}
               
            />
            <Paddings
                topValue={widget?.appearanceSettings?.paddingTop}
                bottomValue={widget?.appearanceSettings?.paddingBottom}
                leftValue={widget?.appearanceSettings?.paddingLeft}
                rightValue={widget?.appearanceSettings?.paddingRight}
               
            />
            <Divider />
            <Colors colors={widget?.colourSettings} />
        </WidgetSettingContainer>
    )
}

export default AudioDesignSetting
