//
// Represents a settable property on a widget.
//

import _ from "lodash";

export type ElementAlignment = "flex-start" | "center" | "flex-end" | "stretch";
export type ContentJustification = "flex-start" | "center" | "flex-end";
export type TextAlignment = "left" | "center" | "right" | "justify";
export type Direction = "row" | "column";
export type BorderStyle = "hidden" | "solid" | "dotted" | "dashed" | "double" | "groove" | "ridge" | "inset" | "outset";
export type TextDecoration = "none" | "underline" | "overline" | "line-through" | "blink";
export type AcceptedFileTypes = "image" | "audio" | "video" | "document";

export interface IPropertyValue {
    //
    // Name of the property value for display in the editor.
    //
    name: string;

    //
    // A valid value of the property.
    //
    value: any;

    //
    // An icon to render for the option.
    //
    icon?: JSX.Element;
}

export interface IProperty<PropertyValueT> {
    //
    // The id of the property.
    // This is also the path of the property within the widget's property bag, e.g. `style.fontSize`.
    //
    id: string;

    //
    // Id(s) of the style attributes for the propertry.
    // If omitted defaults, to the property id.
    //
    styleId?: string[];

    //
    // The name of the property.
    //
    name: string;

    //
    // The type of the property.
    //
    type: string;

    //
    // The type of units expressed by this value.
    //
    unit?: string;

    //
    // Valid values of the property, if set.
    //
    values?: IPropertyValue[];

    //
    // Range of values for the property (enables a slider in the UI).
    //
    range?: number[];

    //
    // Set to true if the property is required.
    //
    required?: boolean;

    //
    // Orientation to display the property in the property editor.
    //
    orientation?: "horizontal" | "vertical";

    //
    // The default value for the property, for when optional properties are enabled/disabled.
    //
    defaultValue?: PropertyValueT;

    //
    // Configuration options for the property.
    //
    config?: any;

    //
    // Nested properties.
    //
    properties?: IProperty<any>[];

    //
    // File types accepted for upload.
    //
    fileTypes?: string[];
}

export interface IPropertyCategory {
    //
    // Name of the category.
    //
    name: string;

    //
    // Properties in the category.
    //
    properties: IProperty<any>[];

    //
    // Orientation to display the properties.
    //
    orientation?: "horizontal" | "vertical";
}

//
// Converts a pt value to an em value.
//
export function ptToEm(input: number): number {
    return input / 12; // 12pt = 1em, 12pt = 16px. With a DPI of 96. Relating to MS Word point sizes, thanks to ChatGPT.
}

//
// Converts an em value to pt.
//
export function emToPt(input: number): number {
    return input * 12;
}

//
// Converts a px value to em.
//
export function pxToEm(input: number): number {
    return input / 16; 
}

//
// Converts an px value to pt.
//
export function pxToPt(input: number): number {
    return emToPt(pxToEm(input));
}

// 
//
// Convertors from particular unit types to em.
//
const unitTypeConvertors: any = {    
    pt: (value: number) => {
        const converted = ptToEm(value);
        return `${converted}em`;
    },
    em: (value: number) => {
        return `${value}em`;
    },
};

export function makePadding(value: number | string): any {
    return {
        paddingLeft: value,
        paddingRight: value,
        paddingTop: value,
        paddingBottom: value,
    };
}

export function makeMargin(value: number | string): any {
    return {
        marginLeft: value,
        marginRight: value,
        marginTop: value,
        marginBottom: value,
    };
}

export function makeBorderRadius(value: number | string): any {
    return {
        borderTopLeftRadius: value,
        borderTopRightRadius: value,
        borderBottomLeftRadius: value,
        borderBottomRightRadius: value,
    };
}

export function makeBorderWidth(value: number | string): any {
    return {
        borderLeftWidth: value,
        borderRightWidth: value,
        borderTopWidth: value,
        borderBottomWidth: value,
    };
}

export interface IStylingProperties {  //todo: This typing might be better done with a JSON schema.

    display?: string; //todo: Want to support this properly.
    flex?: string;
    flexDirection?: Direction;
    flexGrow?: number | string;

    alignItems?: ElementAlignment;
    alignSelf?: ElementAlignment;
    justifyContent?: ContentJustification;

    padding?: number | string;
    paddingLeft?: number | string;
    paddingRight?: number | string;
    paddingTop?: number | string;
    paddingBottom?: number | string;

    margin?: number | string;
    marginLeft?: number | string;
    marginRight?: number | string;
    marginTop?: number | string;
    marginBottom?: number | string;

    textAlign?: TextAlignment;

    color?: string;
    backgroundColor?: string;

    fontSize?: number | string;
    fontWeight?: number | string;
    lineHeight?: number | string;

    width?: number | string;
    height?: number | string;
    minWidth?: number | string;
    minHeight?: number | string;
    maxWidth?: number | string;
    maxHeight?: number | string;

    borderColor?: string;
    borderLeftWidth?: number | string;
    borderRightWidth?: number | string;
    borderTopWidth?: number | string;
    borderBottomWidth?: number | string;
    borderStyle?: BorderStyle;
   
    borderRadius?: number | string;
    borderTopLeftRadius?: number | string;
    borderTopRightRadius?: number | string;
    borderBottomLeftRadius?: number | string;
    borderBottomRightRadius?: number | string;

    textDecoration?: TextDecoration;

    gap?: number | string;
    columnGap?: number | string;
    rowGap?: number | string;    

    fontFamily?: string;
}

//
// Specifies the the target of a link.
//
export type LinkTarget = "url" | "page" | "submit";

//
// Represents a clickable link.
//
export interface ILink {

    //
    // Determines the target of the link.
    //
    target?: LinkTarget;

    //
    // URL to link to.
    //
    url?: string;

    //
    // Number of the page to link to.
    //
    page?: number;
}

export interface IImageProperty {
    //
    // URL to an external image.
    //
    url?: string;

    //
    // ID for an uploaded image.
    //
    assetId?: string;

    //
    // Width of the image.
    //
    width?: number;

    //
    // Height of the image.
    //
    height?: number;

    //
    // Aspect ratio of the image.
    //
    aspectRatio?: number;
}

export interface IGeneralWidgetProperties {

    //
    // Context for text elements.
    //
    content?: string;

    //
    // AI prompt to generate content for the element.
    //
    prompt?: string;

    //
    // Tag to use for this element when AI text is generated.
    //
    tag?: string;

    //
    // Source for videos.
    //
    videoUrl?: string;

    //
    // Image for the widget.
    //
    image?: IImageProperty;

    //
    // Overlay image for the widget.
    //
    overlay?: IImageProperty;

    //
    // Sub items of the widget.
    //
    items?: any[];

    //
    // Background image for the widget.
    //
    backgroundImage?: IImageProperty;

    //
    // Aspect ratio for the widget, if needed.
    //
    aspectRatio?: number;

    //
    // Alt text for images.
    //
    alt?: string;

    //
    // Details for button and link elements.
    //
    link?: ILink;

    //
    // Allow multiple files to be selected.
    //
    allowMultipleFiles?: boolean;

    //
    // Files that are accepted for upload.
    //
    acceptedFiles?: AcceptedFileTypes;

    //
    // The name of a field widget.
    //
    fieldName?: string;
}

export interface IPropertyBag extends IStylingProperties, IGeneralWidgetProperties {
    
    //
    // Form properties.
    //
    type?: string;
    placeholder?: string;
    required?: boolean;
    min?: string | number;
    max?: string | number;
    step?: string | number;
    inputType?: "text" | "number" | "email" | "phone-number";

    //
    // The style variant of the widget to use.
    //
    variant?: string;

    //
    // Properties to apply when the element is hovered.
    //
    hover?: IStylingProperties;

    //
    // Properties to apply for small screens.
    //
    small?: IStylingProperties;

    //
    // Properties to apply for medium screens.
    //
    medium?: IStylingProperties;

    //
    // Properties to apply for large screens.
    //
    large?: IStylingProperties;

    //
    // Properties to apply when the screen is in portrait orientation.
    //
    portrait?: IStylingProperties;

    //
    // Properties to apply when the screen is in landscape orientation.
    //
    landscape?: IExpandedStylingProperties;

    //
    // Allowed image types. 
    //
    allowed?: "any" | "portrait" | "landscape" | "square";
}

export interface IExpandedStylingProperties extends IStylingProperties {
}

export interface IExpandedPropertyBag extends IExpandedStylingProperties, IGeneralWidgetProperties {

    //
    // Form properties.
    //
    type?: string;
    placeholder?: string;
    required?: boolean;
    min?: string | number;
    max?: string | number;
    step?: string | number;

    //
    // Hover properties for the widget.
    //
    hover?: IExpandedStylingProperties;

    //
    // Properties to apply for small screens.
    //
    small?: IExpandedStylingProperties;

    //
    // Properties to apply for medium screens.
    //
    medium?: IExpandedStylingProperties;

    //
    // Properties to apply for large screens.
    //
    large?: IExpandedStylingProperties;

    //
    // Properties to apply when the screen is in portrait orientation.
    //
    portrait?: IExpandedStylingProperties;

    //
    // Properties to apply when the screen is in landscape orientation.
    //
    landscape?: IExpandedStylingProperties;
}

//
// Sets missing defaults in the input property bag expanding the set of properties.
// Also converts units to "em"
//
function convertProperties(expandedProperties: any): void {

    for (const [key, value] of Object.entries(expandedProperties)) {
        // That way we can change it to % or something else.
        if (typeof value === "number") { 
            //
            // Convert numbers with a unit type to "em".
            // 
            // todo: This just assumes that all number values are points. Maybe it shouldn't?
            //
            const unitTypeConvertor = unitTypeConvertors["pt"];
            expandedProperties[key] = unitTypeConvertor(value);
        }
    }

    if (expandedProperties.hover) {
        convertProperties(expandedProperties.hover);
    }

    if (expandedProperties.small) {
        convertProperties(expandedProperties.small);
    }

    if (expandedProperties.medium) {
        convertProperties(expandedProperties.medium);
    }

    if (expandedProperties.large) {
        convertProperties(expandedProperties.large);
    }

    if (expandedProperties.portrait) {
        convertProperties(expandedProperties.portrait);
    }

    if (expandedProperties.landscape) {
        convertProperties(expandedProperties.landscape);
    }
}

//
// Expand the properties for a widget.
//
export function expandProperties(properties: IPropertyBag): IExpandedPropertyBag {

    const expandedProperties: IExpandedPropertyBag =_.cloneDeep(properties || {});
    convertProperties(expandedProperties);

    return expandedProperties;
}

