import { Button } from '@mui/material';
import { SeedQuestions } from '../seed-questions';
import { Preview } from '../preview';
import { clientUpload } from '../../../v1/lib/upload/clientUpload';
import { IWidget, useAuth } from 'widgets-base';
import { ProgressButton } from './components/progress-button';
import { useRef } from 'react';

//
// Get all the images from the tree of widgets.
//
function* pullImagesFromWidgetHierarchy(widgets: IWidget[]): Generator<IWidget> {
    for (const widget of widgets) {
        if (widget.xtype === "image") {
            yield widget;
        }

        if (widget.children) {
            yield* pullImagesFromWidgetHierarchy(widget.children);
        }
    }
}

//
// Renders an image that can be replaced with drag and drop.
//
function ImageDropReplace({ src, srcId, style, onAssetUploaded }: { src?: string, srcId?: string, style?: any, onAssetUploaded?: (assetId: string) => void }) {

    const { makeAssetUrl } = useAuth();
    const inputRef = useRef<HTMLInputElement>(undefined);

    function handleDragOver(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
    }

    async function handleDrop(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();

        const file = e.dataTransfer.files[0];
        const asset = await clientUpload(file);
        onAssetUploaded(asset._id);
    }

    function onClickImage() {
        inputRef.current.click();
    }

    async function onChangeImage(event: React.ChangeEvent<HTMLInputElement>) {
        const file = event.target.files[0];
        const asset = await clientUpload(file);
        onAssetUploaded(asset._id);
    }

    let srcUrl;
    if (srcId) {
        srcUrl = makeAssetUrl(srcId);
    }
    else if (src) {
        srcUrl = src;
    }
    else {
        srcUrl = "/images/placeholder-image.svg";
    }

    return (
        <div
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            >
            <input 
                ref={inputRef}
                className="hidden"
                type="file" 
                onChange={onChangeImage} 
            />                
            <img 
                src={srcUrl} 
                style={style}
                onClick={onClickImage} 
                />
        </div>
    );
}

export function ChooseAssets({ onNext, onBack, onGenerateAssets, campaign, setCampaign, generating, imageSearch, setImageSearch, pageTheme }) {

    return (
        <div className="flex flex-row overflow-hidden">
            <div
                className="flex flex-col h-screen overflow-auto pr-10 pb-40"
                style={{ width: "500px" }}
                >
                <SeedQuestions
                    seeds={[]}
                    answers={() => {}}
                    setAnswer={() => {}}
                    imageSearch={imageSearch}
                    setImageSearch={setImageSearch}
                    />

                <div className="flex flex-row mt-6">
                    <Button
                        variant="contained"
                        onClick={onNext}
                        >
                        Continue
                    </Button>

                    <Button
                        sx={{ ml: 1 }}
                        variant="outlined"
                        onClick={onBack}
                        >
                        Back
                    </Button>

                    <ProgressButton
                        onClick={onGenerateAssets}
                        generating={generating}
                        >
                        Generate again
                    </ProgressButton>
                </div>

                <div className="flex flex-col mt-20">
                    <div className="text-lg">
                        Drag and drop to replace generated assets with your own assets:
                    </div>

                    <div className="grid grid-cols-3 mt-5 p-1">
                        <div 
                            className="border border-solid border-gray-200 p-1"
                            >
                            <div className="mb-1 text-xs">
                                Background image
                            </div>
                            <ImageDropReplace
                                src={campaign.views[0].backgroundImageUrl}
                                srcId={campaign.views[0].backgroundImageId}
                                style={{ maxHeight: "80px" }}
                                onAssetUploaded={uploadedAssetId => {
                                    //
                                    // Patch the new background image into the campaign.
                                    //
                                    setCampaign({
                                        ...campaign,
                                        views: [{
                                            ...campaign.views[0],
                                            backgroundImageUrl: undefined,
                                            backgroundImageId: uploadedAssetId,
                                        }],
                                        lastUpdate: Date.now(),
                                    });
                                }}
                                />
                        </div>


                        {Array.from(pullImagesFromWidgetHierarchy(campaign.views[0].widgets)).map(imageWidget => {
                            return (
                                <div 
                                    key={imageWidget.id}
                                    className="border border-solid border-gray-200 p-1"
                                    >
                                    <div className="mb-1 text-xs">
                                        {imageWidget.name}
                                    </div>
                                    <ImageDropReplace
                                        src={imageWidget.properties?.image?.url || imageWidget.properties?.src}
                                        srcId={imageWidget.properties?.image?.assetId}
                                        style={{ maxHeight: "80px" }}
                                        onAssetUploaded={uploadedAssetId => {
                                            //
                                            // Patch the new image into the campaign.
                                            // It's a bit ugly to mutate the campaign like this, but it's the easiest way to get it working.
                                            //
                                            delete imageWidget.properties.src;
                                            delete imageWidget.properties.image;
                                            imageWidget.properties.image = {
                                                assetId: uploadedAssetId,
                                            };

                                            setCampaign({
                                                ...campaign,
                                                lastUpdate: Date.now(),
                                            });
                                        }}
                                        />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            <Preview
                generating={generating}
                campaign={campaign}
                pageTheme={pageTheme}
                fontSize="6px"
                height="calc(100vh - 100px)"
                maxWidth="600px"
                />
        </div>
    );
}

