import { getWidgetType } from "../../../builder/widget/widget-types";
import NavbarWidget from "../Widgets/CombinedWidget/NavbarWidget"
import DrawForm from "../Widgets/Forms/DrawForm"
import FeedbackForm from "../Widgets/Forms/FeedbackForm"
import AudioWidget from "../Widgets/General/AudioWidget"
import ButtonWidget from "../Widgets/General/ButtonWidget"
import ColumnTextWidget from "../Widgets/General/ColumnText"
import CountdownWidget from "../Widgets/General/CountdownWidget"
import DigitalCardWidget from "../Widgets/General/DigitalCardWidget"
import EventsWidget from "../Widgets/General/EventsWidget"
import IframeWidget from "../Widgets/General/IframeWidget"
import ImageWidget from "../Widgets/General/ImageWidget"
import LinkWidget from "../Widgets/General/LinkWidget"
import SocialWidget from "../Widgets/General/SocialWidget"
import SpotifyWidget from "../Widgets/General/SpotifyWidget"
import SwiperWidget from "../Widgets/General/SwiperWidget"
import MultipleTextWidget from "../Widgets/General/TextWidget/MultipleTextWidget"
import TextWidget from "../Widgets/General/TextWidget/TextWidget"
import VideoWidget from "../Widgets/General/VideoWidget"
import DoublePolls from "../Widgets/Polls/DoublePolls"
import MultiplePolls from "../Widgets/Polls/MultiplePolls"
import BeautyCountdownWidget from '../Widgets/General/CountdownWidget/BeautyCountdown'
import SubscribeForm from "../Widgets/Forms/SubscribeForm"
import ProductForm from "../Widgets/Forms/ProductForm"
import ProductCard from "../Widgets/CombinedWidget/ProductCard"
import Hero1 from "../../sections/hero1/hero1-ui"
import Hero2 from "../../sections/hero2/hero2-ui"
import FormContainer from "../Widgets/Forms/oldForm/FormContainer";
import ContainerWidget from "../Widgets/General/ContainerWidget";
import { useWidget, generateFontImportStyle, IWidget, useDragContext } from "widgets-base";

//
// Renders a single primitive widget.
//
export function RenderEditorWidget() {

    const { widget, expandedProperties } = useWidget();
    const { dragProps, setRef, onSelect } = useDragContext();

    let widgetType = widget.xtype;
    if (widgetType !== undefined) {
        const newWidgetType = getWidgetType(widgetType);
        if (newWidgetType) {
            //
            // A new style widget.
            //
            return (
                <>
                    {generateFontImportStyle(expandedProperties)}
                    <newWidgetType.Editor />
                </>
            );
        }
        else {
            // throw new Error(`Widget type not found "${widget.xtype}"`);
        }
    }
    else {
        widgetType = widget.type;
    }

    return (
        <div 
            className="flex w-full"
            {...dragProps}
            ref={setRef}
            onClick={event => {
                event.stopPropagation();
                event.preventDefault();
                onSelect();
            }}
            >
            <div className="flex w-full pointer-events-none select-none">
                {renderOldStyleWidget(widget, widgetType)}
            </div>
        </div>
    );
}

//
// Renders an old style widget.
//
function renderOldStyleWidget(widget: IWidget, widgetType: string) {
    switch (widgetType) {
        case 'image':
            return <ImageWidget />;
        case 'rich text':
            return <TextWidget />;
        case 'multiple text':
            return <MultipleTextWidget />;
        case 'column text':
            return <ColumnTextWidget />;
        case 'social':
            return <SocialWidget />;
        case 'button':
        case 'old-button':
            return <ButtonWidget />;
        case 'countdown':
            return <CountdownWidget />;
        case 'styled countdown':
            return <BeautyCountdownWidget />;
        case 'events':
            return <EventsWidget />;
        case 'profile card':
            return <DigitalCardWidget editMode />;
        case 'audio':
            return <AudioWidget />;
        case 'spotify':
            return <SpotifyWidget />;
        case 'video':
            return <VideoWidget />;
        case 'iframe':
            return <IframeWidget />;
        case 'link text':
            return <LinkWidget />;
        case 'swiper':
            return <SwiperWidget />;
        case 'container':
            return <ContainerWidget editMode />;
        case 'form':
        case 'old-form':
            return <FormContainer />;
        case 'subscribe form':
            return <SubscribeForm editMode />;
        case 'product form':
            return <ProductForm editMode />;
        case 'draw form':
            return <DrawForm editMode />;
        case 'feedback form':
            return <FeedbackForm editMode />;
        case 'polls':
        case 'multiple polls':
            return <MultiplePolls editMode />;
        case 'double polls':
            return <DoublePolls editMode />;
        case 'product1':
            return <ProductCard />;
        case 'navbar':
            return <NavbarWidget />;

        case 'hero/wide': {
            return <Hero1 editMode />;
        }

        case 'hero/narrow': {
            return <Hero2 editMode />;
        }

        default:
            throw new Error(`Unknown old style widget type ${widget.type}`);
    }
}
