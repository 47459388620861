import { Divider } from '@mui/material'
import { Link } from 'react-router-dom'
import { RouteNames } from '../../../router'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { ListItem, PageContainer } from './styles'

const DocBuilder = () => {
    return (
        <PageContainer>
            <h1>Builder</h1>
            <Divider />
            <div className="content">
                <h1>Builder's components</h1>
                <h2>Navbar</h2>
                <div>
                    The builder page has its own navigation bar:
                    <ListItem>
                        <li>
                            <strong>Home.</strong> Returns you to the home page of the dashboard.
                            (we recommend periodically saving your progress so as not to lose it)
                        </li>
                        <li>
                            <strong>Settings.</strong> Includes your campaign settings. It includes
                            the name of the campaign, its description, thumbnail preview image,
                            redirect settings and analytics connections.
                        </li>
                        <li>
                            <strong>Basic widgets.</strong> Basic widgets are simple widgets of the
                            type of text, image, or links. When you open this panel, you will be
                            presented with a list of widgets that you need. Just choose what will
                            help you achieve your goals. Widgets are added according to the drag and
                            drop principle. More about this in the{' '}
                            <Link to={RouteNames.DOC_WIDGETS}>
                                <strong>drag and drop widgets</strong>
                            </Link>{' '}
                            section.
                        </li>
                        <li>
                            <strong>Sectional widgets.</strong> These are more complex widgets like
                            draws, forms or polls, which include complex work logic as well as some
                            integrations.
                        </li>
                        <li>
                            <strong>Templates.</strong> This tab includes public templates and
                            custom private templates. Used on the principle of drag and drop. Learn
                            more about templates in the{' '}
                            <Link to={RouteNames.DOC_TEMPLATES}>
                                <strong>Templates</strong>
                            </Link>{' '}
                            article.
                        </li>
                        <li>
                            <strong>QR Design.</strong> Everything related to setting up a QR code.
                            Avoid dark background colors as it may affect camera detection. After
                            deploying the campaign in this section, you can upload an image of the
                            generated QR code.
                        </li>
                    </ListItem>
                </div>
                <Divider />
                <h2>Quick campaign setup section</h2>
                <img
                    className="image"
                    src="/images/docs/builder-section1.png"
                    alt="builder setup"
                />
                <p>
                    Located at the bottom of the screen. Includes the name of your campaign - it
                    changes depending on the campaign settings. Information about the number of
                    active pages in your campaign, it also reveals a list of your pages for quick
                    navigation between them. The button for adding pages to the campaign - actually
                    adds a new empty page :). The screen size selection buttons will show you how
                    your campaign will look on different devices. When dragging widgets, this panel
                    will also inform you about how much time and money you saved using ready-made
                    widgets.
                </p>
                <Divider />
                <h2>Page setup section</h2>
                <img
                    className="image"
                    src="/images/docs/builder-section2.gif"
                    alt="builder setup"
                />
                <p>
                    Quick Page Setup displays the title of the current page. Using the function
                    buttons, you can set the background color, set the background image from the
                    gallery, which can be configured on the brand settings page. Copy button - copy
                    the current page with all nested widgets. The delete button, respectively,
                    deletes the current page with all nested widgets
                </p>
                <Divider />
                <h2>Canvas</h2>
                <img
                    className="image"
                    src="/images/docs/builder-section3.png"
                    alt="builder setup"
                />
                <p>
                    This is the area for dragging widgets. It is here that all widgets are
                    displayed, and it also serves as a preview of the important page. The canvas is
                    also equipped with a sorting function, so you can sort your widgets at any time
                    as you wish.
                </p>
                <div>
                    The main actions for the canvas here are:
                    <ListItem>
                        <li>
                            <strong>CLEAR.</strong> Clears the page of all widgets.
                        </li>
                        <li>
                            <strong>EDIT.</strong> Button to switch to editing widgets. During the
                            drag and drop stage, you cannot edit widgets. Read more about editing
                            widgets{' '}
                            <Link to={RouteNames.DOC_EDIT_MODE}>
                                <strong>here.</strong>
                            </Link>
                        </li>
                        <li>
                            <strong>PREVIEW.</strong> Opens a preview of your campaign, i.e. how it
                            will look after deployment. We recommend that you save your progress
                            when switching to the preview.
                        </li>
                        <li>
                            <strong>SAVE.</strong> Saves all progress on working with the campaign.
                        </li>
                        <li>
                            <strong>DEPLOY.</strong> Deploy your campaign, generates a QR code and a
                            link that you can share. Link and QR code are generated only once. This
                            way, when redeploying, the link and code will remain the same.
                        </li>
                    </ListItem>
                </div>
                <p>
                    If you have any difficulties with working in the constructor, there is always a
                    help button <HelpOutlineIcon /> in the lower right corner with useful links to
                    documentation, video examples, as well as to our blog, which may inspire you to
                    new crazy designs.
                </p>
            </div>
        </PageContainer>
    )
}

export default DocBuilder
