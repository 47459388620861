import { BorderStyle, ElementAlignment, IProperty, TextAlignment, TextDecoration } from './property'
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

export const stdPadding: IProperty<number> = {
    id: "padding",
    styleId: ["paddingLeft", "paddingRight", "paddingTop", "paddingBottom"],
    name: "Padding",
    type: "linkedNumbers",
    unit: "pt",
    defaultValue: 0,
    orientation: "vertical",
};

export const stdMargin: IProperty<number> = {
    id: "margin",
    styleId: ["marginLeft", "marginRight", "marginTop", "marginBottom"],
    name: "Margin",
    type: "linkedNumbers",
    unit: "pt",
    defaultValue: 0,
    orientation: "vertical",
};

export const stdElementAlignment: IProperty<ElementAlignment> = {
    id: 'alignSelf',
    name: 'Alignment',
    type: 'option',
    required: true,
	defaultValue: "center",
    values: [
        {
            name: 'Start',
            value: 'flex-start',
            icon: <FormatAlignLeftIcon />,
        },
        {
            name: 'Center',
            value: 'center',
            icon: <FormatAlignCenterIcon />,
        },
        {
            name: 'End',
            value: 'flex-end',
            icon: <FormatAlignRightIcon />,
        },
        {
            name: 'Stretch',
            value: 'stretch',
            icon: <FormatAlignJustifyIcon />,
        },
    ],
};

export const stdTextAlignment: IProperty<TextAlignment> = {
    id: "textAlign",
    name: "Text alignment",
    type: "option",
    defaultValue: "center",
    orientation: "vertical",
    values: [
        {
            name: "Left",
            value: "left",
            icon: <FormatAlignLeftIcon />,
        },
        {
            name: "Center",
            value: "center",
            icon: <FormatAlignCenterIcon />,
        },
        {
            name: "Right",
            value: "right",
            icon: <FormatAlignRightIcon />,
        },
        {
            name: "Justify",
            value: "justify",
            icon: <FormatAlignJustifyIcon />,
        },
    ],
};

//
// Makes a color property.
//
export function makeColorProperty(mode: "normal" | "hover", field: "color" | "borderColor" | "backgroundColor", name: string): IProperty<string> {
    const id = mode === "normal"
        ? field
        : `${mode}.${field}`;
    return {
        id,
        name,
        type: "color",
        defaultValue: "white",
        orientation: "horizontal",
    };
}

export const stdFontFamily: IProperty<string> = {
    id: "fontFamily",
    name: "Font",
    type: "font",
    defaultValue: "Arial",
    orientation: "vertical",
};

export const stdFontSize: IProperty<number> = {
    id: "fontSize",
    name: "Font size",
    type: "number",
    unit: "pt",
    range: [1, 100, 1],
    defaultValue: 12,
    orientation: "vertical",
};

export const stdLineHeight: IProperty<number> = {
    id: "lineHeight",
    name: "Line height",
    type: "number",
    unit: "pt",
    range: [1, 100, 1],
    defaultValue: 12,
    orientation: "vertical",
};

export const stdSize: IProperty<number> = {
    id: "size",
    name: "Size",
    type: "size",
    unit: "pt",
    range: [1, 2000, 1],
    defaultValue: undefined,
    orientation: "vertical",
};

export const stdWidth: IProperty<number> = {
    id: "width",
    name: "Width",
    type: "number",
    unit: "pt",
    range: [1, 2000, 1],
    defaultValue: 20,
    orientation: "vertical",
};

export const stdHeight: IProperty<number> = {
    id: "height",
    name: "Height",
    type: "number",
    unit: "pt",
    range: [1, 2000, 1],
    defaultValue: 1,
    orientation: "vertical",
};

export const stdBorderWidth: IProperty<number> = {
    id: "borderWidth",
    styleId: ["borderLeftWidth", "borderRightWidth", "borderTopWidth", "borderBottomWidth"],
    name: "Border width",
    type: "linkedNumbers",
    unit: "pt",
    defaultValue: 0,
    required: true,    
    orientation: "vertical",
};

export const stdBorderStyle: IProperty<BorderStyle> = {
    id: "borderStyle",
    name: "Style",
    type: "option",
    defaultValue: "solid",
    values: [
        {
            name: "Hidden",
            value: "hidden",
        },
        {
            name: "Solid",
            value: "solid",
        },
        {
            name: "Dotted",
            value: "dotted",
        },
        {
            name: "Dashed",
            value: "dashed",
        },
        {
            name: "Double",
            value: "double",
        },
        {
            name: "Groove",
            value: "groove",
        },
        {
            name: "Ridge",
            value: "ridge",
        },
        {
            name: "Inset",
            value: "inset",
        },
        {
            name: "Outset",
            value: "outset",
        },
    ],
    required: true,    
    orientation: "vertical",
};

export const stdBorderRadius: IProperty<number> = {
    id: "borderRadius",
    styleId: ["borderTopLeftRadius", "borderTopRightRadius", "borderBottomLeftRadius", "borderBottomRightRadius"],
    name: "Radius",
    type: "borderRadius",
    unit: "pt",
    defaultValue: 0,
    required: true,    
    orientation: "vertical",
};

export const stdTextDecoration: IProperty<TextDecoration> = {
    id: "textDecoration",
    name: "Text decoration",
    type: "option",
    defaultValue: "underline",
    values: [
        {
            name: "Underline",
            value: "underline",
        },
        {
            name: "Overline",
            value: "overline",
        },
        {
            name: "Line through",
            value: "line-through",
        },
        {
            name: "Blink",
            value: "blink",
        },
        {
            name: "None",
            value: "none",
        },
    ],
    orientation: "vertical",
};


export const stdGroupProperties: IProperty<any>[] = [
    {
        id: "gap",
        name: "Gap",
        type: "number",
        unit: 'pt',
        range: [0, 50, 1],
        defaultValue: 3,        
        required: true, 
        orientation: "vertical",   
    },
    {
        id: 'justifyContent',
        name: 'Justification',
        type: 'option',
        required: true,
        defaultValue: 'center',
        orientation: "vertical",
        values: [
            {
                name: 'Start',
                value: 'flex-start',
                icon: <FormatAlignLeftIcon />,
            },
            {
                name: 'Center',
                value: 'center',
                icon: <FormatAlignCenterIcon />,
            },
            {
                name: 'End',
                value: 'flex-end',
                icon: <FormatAlignRightIcon />,
            },
        ],
    },
];