import { ChangeEvent, useState, FC } from 'react'
import validator from 'validator'
import Select from 'react-select'
import styled from 'styled-components'
import { checkInputValid } from '../../../../../helpers/validation'
import { InputBox } from '../../../../common/InputBox'
import { ToggleButton } from '../../../../common/ToggleButton'
import { WidgetSettingContainer } from '../../../../Design/WidgetSettingContainer'
import { useWidget } from 'widgets-base'

interface Props {
}

const inputTypes = [
    { label: 'Text field', value: 'text' },
    { label: 'Email', value: 'email' },
    { label: 'Phone number', value: 'number' },
]

const InputSetting: FC<Props> = () => {

    const { widget, setWidget } = useWidget();
    const [error, setError] = useState<string>('')

    const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newField = {
            ...widget,
            settings: { ...widget.settings, [e.target.name]: e.target.value },
        }

        setWidget(newField);
    }

    const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
        const newField = {
            ...widget,
            settings: { ...widget.settings, required: e.target.checked },
        }

        setWidget(newField);
    }

    const handleTextBlur = (value: string, attr: string) => () => {
        setError('')
        if (attr === 'name') {
            if (validator.isEmpty(value) || value === '') {
                setError('Input name can not be empty')
                return
            }
            if (!checkInputValid(value, 'alphanumericWithDashSpace')) {
                setError('Please set valid input name without special characters')
            }
        }
    }

    const handleChangeCategory = (value: { label: string; value: string }) => {
        const newField = {
            ...widget,
            settings: { ...widget.settings, type: value.value },
        }

        setWidget(newField);
    }

    return (
        <WidgetSettingContainer padding={0}>
            <InputBox
                label="Input box label"
                name="label"
                placeholder="Name"
                onChange={handleTextChange}
                type="text"
                value={widget?.settings?.label}
                onBlur={handleTextBlur(widget?.settings?.label, 'label')}
            />
            <InputBox
                label="Field name"
                name="name"
                placeholder="Customers name"
                onChange={handleTextChange}
                type="text"
                value={widget?.settings?.name}
                onBlur={handleTextBlur(widget?.settings?.name, 'name')}
                error={error}
            />
            <InputBox
                label="Placeholder text"
                name="placeholder"
                placeholder="Tell us why (optional)"
                onChange={handleTextChange}
                type="text"
                value={widget?.settings?.placeholder}
            />
            <SelectContainer>
                <label>Input field type</label>
                <Select
                    options={inputTypes}
                    defaultValue={widget?.settings?.type}
                    onChange={handleChangeCategory}
                />
            </SelectContainer>
            <ToggleContainer>
                <ToggleButton isToggled={widget?.settings?.required} onChange={handleCheckbox} />
                <span>Field is required</span>
            </ToggleContainer>
        </WidgetSettingContainer>
    )
}

export default InputSetting

const SelectContainer = styled.div`
    & > label {
        font-size: 14px;
        font-weight: bold;
        color: #3c4b61;
    }
    & > div {
        margin-top: 5px;
        & > div {
            background-color: #fafaff;
            border-radius: 10px;
            border-color: #dfe2f2;
        }
    }
`

export const ToggleContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 18px;

    & > span {
        font-size: 14px;
        font-weight: bold;
        color: #3c4b61;
        margin-bottom: 4px;
        margin-left: 10px;
    }
`

