export const validateSingleInput = (oInput, _validFileExtensions): boolean => {
    var sFileName = oInput.name
    if (sFileName.length > 0) {
        var blnValid = false
        for (var j = 0; j < _validFileExtensions.length; j++) {
            var sCurExtension = _validFileExtensions[j]
            if (
                sFileName
                    .substr(sFileName.length - sCurExtension.length, sCurExtension.length)
                    .toLowerCase() == sCurExtension.toLowerCase()
            ) {
                blnValid = true
                break
            }
        }

        if (!blnValid) {
            return false
        }
    }

    return true
}

export const checkFileTypes = (files: FileList, fileType: string) => {
    let isValid = []
    for (let i = 0; i < files.length; i++) {
        let validFileExtensions = []
        let valid = true

        switch (fileType) {
            case 'image':
                validFileExtensions = ['jpeg', 'png', 'gif', 'webp', 'svg+xml', 'svg', 'jpg']
                break
            case 'audio':
                validFileExtensions = ['mpeg', 'ogg', 'mp3', 'wav']
                break
            case 'video':
                validFileExtensions = ['mp4', 'webm', 'ogg', 'wmv', 'mov', 'avi', 'mpeg', 'mpg']
                break
            case 'document':
                validFileExtensions = [
                    'doc',
                    'csv',
                    'pdf',
                    'docx',
                    'txt',
                    'ppt',
                    'xlsx',
                    'xlsm',
                    'pptx',
                ]
                break
            default:
                break
        }

        valid = validateSingleInput(files[i], validFileExtensions)

        if (!valid) {
            isValid.push(valid)
        }
    }

    return isValid
}
