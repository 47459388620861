import { FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import { ChangeEvent } from 'react'
import styled from 'styled-components'
import { useForm } from '../../../../../builder/context/form-state-old'
import { useWidget } from 'widgets-base'

interface Props {
}

export const RadioField = ({}: Props) => {

    const { widget } = useWidget();
    const { getFieldValue, setFieldValue } = useForm();

    const name = widget?.settings?.name
    const label = widget?.settings?.label
    const options: { label: string; value: string }[] = widget?.settings?.options

    const handleSelectChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setFieldValue(name, e.target.value);
    }

    const width = widget?.appearanceSettings?.width + '%'
    const font = widget?.appearanceSettings?.font || { family: 'Open Sans' }
    const modFontFamily = font.family?.replace(/\s/g, '+')
    const fontUrl = widget?.appearanceSettings?.font?.url
    const paddingTop = widget?.appearanceSettings?.paddingTop / 10 + 'em'
    const paddingBottom = widget?.appearanceSettings?.paddingBottom / 10 + 'em'
    const paddingLeft = widget?.appearanceSettings?.paddingLeft / 10 + 'em'
    const paddingRight = widget?.appearanceSettings?.paddingRight / 10 + 'em'
    const marginTop = widget?.appearanceSettings?.marginTop / 10 + 'em'
    const marginBottom = widget?.appearanceSettings?.marginBottom / 10 + 'em'
    const marginLeft = widget?.appearanceSettings?.marginLeft / 10 + 'em'
    const marginRight = widget?.appearanceSettings?.marginRight / 10 + 'em'

    const borderTopLeftRadius = widget?.appearanceSettings?.borderTopLeftRadius / 10 + 'em'
    const borderTopRightRadius = widget?.appearanceSettings?.borderTopRightRadius / 10 + 'em'
    const borderBottomLeftRadius = widget?.appearanceSettings?.borderBottomLeftRadius / 10 + 'em'
    const borderBottomRightRadius = widget?.appearanceSettings?.borderBottomRightRadius / 10 + 'em'
    const borderTopWidth = widget?.appearanceSettings?.borderTopWidth / 10 + 'em'
    const borderBottomWidth = widget?.appearanceSettings?.borderBottomWidth / 10 + 'em'
    const borderLeftWidth = widget?.appearanceSettings?.borderLeftWidth / 10 + 'em'
    const borderRightWidth = widget?.appearanceSettings?.borderRightWidth / 10 + 'em'
    const borderWidth = borderTopWidth && borderRightWidth && borderBottomWidth && borderLeftWidth
    const alignment = widget?.appearanceSettings?.alignment
    const fontSize = widget.appearanceSettings ? widget.appearanceSettings.font.size + 'px' : '14px'
    const spacing = widget?.appearanceSettings?.spacing / 10 + 'em'
    const columns = widget?.appearanceSettings?.columns

    const color = Array.isArray(widget.colourSettings['text colour'])
        ? widget.colourSettings['text colour'][0]
        : widget.colourSettings['text colour']
    const borderColor = Array.isArray(widget.colourSettings['border colour'])
        ? widget.colourSettings['border colour'][0]
        : widget.colourSettings['border colour']
    const labelColour = Array.isArray(widget.colourSettings['label colour'])
        ? widget.colourSettings['label colour'][0]
        : widget.colourSettings['label colour']
    const backgroundColor = Array.isArray(widget.colourSettings['background colour'])
        ? widget.colourSettings['background colour'][0]
        : widget.colourSettings['background colour']
    const iconColour = Array.isArray(widget?.colourSettings['icon colour'])
        ? widget?.colourSettings['icon colour'][0]
        : widget?.colourSettings['icon colour']

    const weight = Number(widget?.appearanceSettings?.font?.weight) || 400
    const border = `${borderWidth} solid ${borderColor}`
    const iconSize = widget?.appearanceSettings?.iconSize / 10 + 'em'

    return (
        <Wrapper
            align={alignment}
            marginTop={marginTop}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            marginRight={marginRight}
        >
            <Container
                width={width}
                weight={weight}
                paddingTop={paddingTop}
                paddingBottom={paddingBottom}
                paddingLeft={paddingLeft}
                paddingRight={paddingRight}
                marginTop={marginTop}
                marginBottom={marginBottom}
                marginLeft={marginLeft}
                marginRight={marginRight}
                borderTopLeftRadius={borderTopLeftRadius}
                borderBottomLeftRadius={borderBottomLeftRadius}
                borderTopRightRadius={borderTopRightRadius}
                borderBottomRightRadius={borderBottomRightRadius}
                border={border}
                backgroundColor={backgroundColor}
            >
                <label id="demo-radio-buttons-group-label">{label}</label>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name={name}
                    value={getFieldValue(name) || false}
                    onChange={handleSelectChange}
                >
                    {options.map((option, index) => {
                        return (
                            <FormControlLabel
                                key={index}
                                value={option.value}
                                control={<Radio />}
                                label={option.label}
                            />
                        )
                    })}
                </RadioGroup>
            </Container>
        </Wrapper>
    )
}

const Wrapper = styled.div<{
    align: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
}>`
    display: flex;
    align-items: ${(props) =>
        props.align === 'left' ? 'flex-start' : props.align === 'center' ? 'center' : 'flex-end'};
    justify-content: center;
    width: 100%;
    cursor: pointer;
    flex-direction: column;
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
`
const Container = styled.div<{
    width: string
    paddingTop: string
    paddingBottom: string
    paddingLeft: string
    paddingRight: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
    borderTopLeftRadius: string
    borderBottomLeftRadius: string
    borderTopRightRadius: string
    borderBottomRightRadius: string
    border: string
    backgroundColor: string
    weight: number
}>`
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border: ${(props) => props.border};
    background-color: ${(props) => props.backgroundColor};
    font-weight: ${(props) => +props.weight};
    width: ${(props) => props.width};
    padding-top: ${(props) => props.paddingTop};
    padding-bottom: ${(props) => props.paddingBottom};
    padding-left: ${(props) => props.paddingLeft};
    padding-right: ${(props) => props.paddingRight};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
    border-top-left-radius: ${(props) => props.borderTopLeftRadius};
    border-top-right-radius: ${(props) => props.borderTopRightRadius};
    border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
    input {
        width: 100%;
        border-radius: inherit;
    }
`
