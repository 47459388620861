import { FC } from 'react'
import { Helmet } from 'react-helmet-async'

interface Props {
    title: string
}

export const SEO: FC<Props> = ({ title }) => {
    return (
        <Helmet>
            <title>{`deployable | ${title}`}</title>
            <link rel="canonical" href="https://www.deployable.co/" />
            <meta property="og:title" content="quicklinks" />
        </Helmet>
    )
}
