import React from 'react'
import styled from 'styled-components'

interface Props {
    name: string
    text: string
    checked: boolean
    handleChange: (value: any) => void
}

const CheckboxInput = ({ name, text, checked, handleChange }: Props) => {
    return (
        <InputContainer>
            <input
                type="checkbox"
                checked={checked}
                onChange={handleChange}
                name={name}
                id={name}
            />
            <label
                htmlFor={name}
                className="ml-4 mt-1 text-sm text-grayish font-medium"
                dangerouslySetInnerHTML={{ __html: text }}
            ></label>
        </InputContainer>
    )
}

export default CheckboxInput

const InputContainer = styled.div`
    display: flex;
    align-items: center;

    input {
        border: 1px solid rgba(11, 70, 84, 0.24);
        border-radius: 3px;
        width: 15px;
        height: 15px;
        cursor: pointer;
    }
`
