import { createContext, ReactNode, useContext, useState } from "react";
import { IProperty, useWidget, IPropertyBag } from "widgets-base";

export interface IPropertyHook<PropertyValueT> {
    //
    // The property definition.
    //
    property: IProperty<PropertyValueT>;

    //
    // The current value of the property, or undefined if not set.
    //
    propertyValue: PropertyValueT | undefined;

    //
    // Sets the current value of the property, enables the proeprty.
    //
    setPropertyValue: (newValue: PropertyValueT) => void;

    //
    // Clears the current value of the proeprty, unsettting/disabling the property.
    //
    clearPropertyValue: () => void;
}

const PropertyContext = createContext<IPropertyHook<any>>(null);

export interface IProps {
    //
    // The property to edit.
    //
    property: IProperty<any>;

    //
    // The index of the property in the parent, if the parent is an array.
    //
    parentIndex?: number;

    children: ReactNode | ReactNode[];
}

export function PropertyContextProvider({ property, parentIndex, children }: IProps) {
    
    const parentProperty = useProperty();
    const { widget, updateProperties } = useWidget();

    let initPropertyValue;
    
    if (parentProperty) {
        if (parentIndex !== undefined) {
            //
            // Gets the property value from the parent array element.
            //
            initPropertyValue = parentProperty.propertyValue[parentIndex]?.[property.id];
        }
        else {
            //
            // Gets the property value from the parent object.
            //
            initPropertyValue = parentProperty.propertyValue?.[property.id];
        }
    }
    else {
        //
        // Get the property value from the widget.
        //
        initPropertyValue = widget.properties?.[property.id];
    }

    const [propertyValue, _setPropertyValue] = useState<any>(initPropertyValue);

    function setPropertyValue(newValue: any): void {
        _setPropertyValue(newValue);

        console.log(`Updated property value: `);
        console.log(newValue);

        const newProperties: Partial<IPropertyBag> = {
            [property.id]: newValue,
        };

        console.log("Updated properties:");
        console.log(newProperties);

        if (parentProperty) {
            if (parentIndex !== undefined) {
                console.log(`Updating field ${property.id} of parent array at index ${parentIndex}`);
                //
                // Sets the field of the element of the parent array.
                //
                parentProperty.setPropertyValue([
                    ...parentProperty.propertyValue.slice(0, parentIndex),
                    {
                        ...parentProperty.propertyValue[parentIndex],
                        [property.id]: newValue,
                    },
                    ...parentProperty.propertyValue.slice(parentIndex + 1),
                ]);
            }
            else {
                console.log(`Updating field ${property.id} of parent object.`);
                //
                // Sets the field of the parent property.
                //
                parentProperty.setPropertyValue({
                    ...parentProperty.propertyValue,
                    [property.id]: newValue,
                });
            }
        }
        else {
            //
            // Update the property in the widget.
            //
            updateProperties(newProperties);
        }
    }

    function clearPropertyValue(): void {
        setPropertyValue(undefined);
    }
    
    const value: IPropertyHook<any> = {
        property, 
        propertyValue,
        setPropertyValue,
        clearPropertyValue,
    };
    
    return (
        <PropertyContext.Provider 
            value={value}
        >
            {children}
        </PropertyContext.Provider>
    );    
}

export function useProperty<PropertyValueT = any>(): IPropertyHook<PropertyValueT> {
    const context = useContext(PropertyContext);
    return context;
}
