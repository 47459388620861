import React, { FC } from 'react'
import styled from 'styled-components'
import { EventsList } from './EventsList'
import { useWidget } from 'widgets-base'

interface Props {
    editMode?: boolean
}

const EventsWidget: FC<Props> = ({ editMode = false }) => {

    const { widget } = useWidget();
    const fontFamily = widget?.appearanceSettings.font.family || 'Poppins'
    const modFontFamily = fontFamily?.replace(/\s/g, '+')

    return (
        <EventsSection id={widget.id} editMode={editMode}>
            <style>
                {`@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`}
            </style>
            <EventsList />
        </EventsSection>
    )
}

export default EventsWidget

const EventsSection = styled.section<{
    editMode: boolean
}>`
    width: inherit;
    pointer-events: ${(props) => (props.editMode ? 'none' : 'auto')};
`
