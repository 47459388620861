import { FC } from 'react'
import { WidgetSettingContainer } from '../../../Design/WidgetSettingContainer'
import ButtonGeneralSetting from '../../../EditMode/Settings/ButtonGeneralSetting'
import SidebarTabs from '../../../EditMode/SidebarTabs'
import ButtonDesignSetting from './ButtonDesignSetting'

interface Props {
}

const ButtonSetting: FC<Props> = () => {
    return (
        <WidgetSettingContainer padding={0}>
            <SidebarTabs
                setting={<ButtonGeneralSetting />}
                design={<ButtonDesignSetting />}
            />
        </WidgetSettingContainer>
    )
}

export default ButtonSetting
