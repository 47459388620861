import { FC } from 'react'
import { useWidget } from 'widgets-base';

interface Props {
    editMode?: boolean
    preview?: string
}

const Hero1: FC<Props> = ({ editMode = false, preview }) => {
    
    const { widget } = useWidget();
    const backgroundColor = widget?.colourSettings?.['background color'][0]
    const fontFamily = widget?.appearanceSettings?.font?.family
    const fontUrl = widget?.appearanceSettings?.font?.url
    const modFontFamily = fontUrl?.replace(/\s/g, '+')

    const onRedirect = () => {
        const url = widget.settings.link
        !editMode && window.open(url, '_blank', 'noreferrer')
    }

    return (
        <div
            style={{
                backgroundColor,
                width: "100%",
            }}
            >
            <div
                className="mx-auto flex flex-col md:flex-row"
                style={{
                    maxWidth: "1300px",
                    padding: ".8em",
                    fontSize: `${widget.appearanceSettings.font.size}px`,
                }}
                >
                <div
                    className="flex flex-col items-start justify-center"
                    >
                    <h1 
                        style={{
                            "WebkitTextSizeAdjust": "100%",
                            "WebkitFontSmoothing": "antialiased",
                            "boxSizing": "border-box",
                            "margin": ".67em 0",
                            "color": widget.colourSettings['pretitle color'][0], // TODO: Why aren't the other colors used?
                            "textAlign": widget.appearanceSettings.align as any,
                            "marginTop": "0",
                            "marginBottom": "0",
                            // Can't change the font for different sections, so font is hardcoded for headings.
                            "fontFamily": "Matter, sans-serif",
                            "fontSize": `${widget.appearanceSettings.font.size * 3.33}px`,
                            "fontWeight": "600",
                            "lineHeight": "1"
                        }}
                        dangerouslySetInnerHTML={{ __html: widget.settings.text.pretitle }}
                        >
                    </h1>
                    <h1 
                        style={{
                            "WebkitTextSizeAdjust": "100%",
                            "WebkitFontSmoothing": "antialiased",
                            "textAlign": widget.appearanceSettings.align as any,
                            "fontFamily": "Matter, sans-serif",
                            "fontSize": `${widget.appearanceSettings.font.size * 3.33}px`,
                            "fontWeight": "600",
                            "lineHeight": "1",
                            "boxSizing": "border-box",
                            "color": widget.colourSettings['title color'][0],
                        }}
                        dangerouslySetInnerHTML={{ __html: widget.settings.text.title }}
                        >
                    </h1>

                    <p
                        style={{
                            "WebkitTextSizeAdjust": "100%",
                            "fontWeight": "400",
                            "WebkitFontSmoothing": "antialiased",
                            "boxSizing": "border-box",
                            "marginBottom": ".8em",
                            "maxWidth": "600px",
                            "color": widget.colourSettings['content color'][0],
                            "textAlign": widget.appearanceSettings.align as any,
                            "marginTop": "1.8em",
                            "marginRight": "auto",
                            "fontFamily": "Matter, sans-serif",
                            "fontSize": `${widget.appearanceSettings.font.size}px`,
                            "lineHeight": widget.appearanceSettings.font.lineHeight,
                            "marginLeft": "0"
                        }}
                        dangerouslySetInnerHTML={{ __html: widget.settings.text.content }}
                        >
                    </p>

                    <button
                        style={{
                            "WebkitTextSizeAdjust": "100%",
                            "fontWeight": "400",
                            "WebkitFontSmoothing": "antialiased",
                            "textAlign": "center",
                            "fontSize": `${widget.appearanceSettings.font.size}px`,
                            "boxSizing": "border-box",
                            "fontFamily": "DM Sans, sans-serif",
                            "transition": "color .3s",
                            "color": "#fff",
                            "lineHeight": "inherit",
                            "cursor": "pointer",
                            "border": "0",
                            "textDecoration": "none",
                            "display": "inline-block",
                            "backgroundColor": widget.colourSettings['button color'][0],
                            "borderRadius": "1.5em",
                            "alignSelf": "flex-start",
                            "marginTop": "1.5em",
                            "padding": "1.5em 3em"
                        }}
                        onClick={onRedirect}
                        dangerouslySetInnerHTML={{ __html: widget.settings.text.button }}
                        >
                    </button>

                </div>
                <img
                    className="p-4"
                    src={widget.settings.src}
                    alt={widget.name} 
                    style={{
                        width: `${widget?.appearanceSettings?.width}%`,
                        maxWidth: "650px",
                    }}
                    >
                </img>
            </div>
        </div>
        // 
        // Old containers:
        //
        // <CombinedWidgetSection>
        //     <style>
        //         {!fontUrl
        //             ? `@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`
        //             : `@font-face {
        //                 font-family: '${fontFamily}';
        //                 src: url('${fontUrl}');
        //                 font-style: normal;
        //               }

        //               .header-text {
        //                 font-family: '${fontFamily}';
        //               }`}
        //     </style>
        //     <MainContainer style={containerStyles}>
        //         <TextContainer>
        //             <TextList textStyles={textStyles}>
        //                 <div className="text">
        //                     --text---
        //                 </div>
        //             </TextList>
        //             --button--
        //         </TextContainer>
        //         <ImageContainer>
        //          --img--
        //         </ImageContainer>
        //     </MainContainer>
        // </CombinedWidgetSection>
    )
}

export default Hero1

//
// Old styling:
//

// const CombinedWidgetSection = styled.section`
//     width: inherit;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// `

// const MainContainer = styled.div`
//     display: flex;
//     gap: 20px;
// `
// const TextContainer = styled.div`
//     width: 50%;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-between;
//     margin: 15px 0;

//     button {
//         width: 100%;
//         padding: 10px;
//         border-radius: 8px;
//         margin: 15px 0;
//         transition: all 0.2s ease-in-out;
//         outline: none;
//         &:hover {
//             transform: translateX(5px);
//         }
//     }
// `
// const TextList = styled.div<{
//     textStyles: {
//         main: number
//         title: number
//         content: number
//         pretitleColor: string
//         color: string
//         align: string
//         lineHeight: number
//         gap: string
//         font: string
//     }
// }>`
//     width: 100%;
//     display: flex;
//     flex-direction: column;
//     gap: 35px;

//     .text {
//         display: flex;
//         flex-direction: column;
//         gap: ${(props) => props.textStyles.gap + 'px'};
//         word-break: break-word;
//         word-wrap: break-word;
//         overflow-wrap: break-word;

//         h3 {
//             font-size: ${(props) => props.textStyles.main + 'px'};
//             color: ${(props) => props.textStyles.pretitleColor};
//             font-family: ${(props) => props.textStyles.font};
//             text-align: ${(props) => props.textStyles.align};
//             line-height: ${(props) => props.textStyles.lineHeight};
//         }
//         h1 {
//             font-size: ${(props) => props.textStyles.title + 'px'};
//             color: ${(props) => props.textStyles.color};
//             font-family: ${(props) => props.textStyles.font};
//             text-align: ${(props) => props.textStyles.align};
//             line-height: ${(props) => props.textStyles.lineHeight};
//         }
//         p {
//             font-size: ${(props) => props.textStyles.content + 'px'};
//             color: ${(props) => props.textStyles.color};
//             font-family: ${(props) => props.textStyles.font};
//             text-align: ${(props) => props.textStyles.align};
//             line-height: ${(props) => props.textStyles.lineHeight};
//         }
//     }
// `
// const ImageContainer = styled.div`
//     width: 50%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     img {
//         width: 100%;
//     }
// `
