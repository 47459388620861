import { nanoid } from 'nanoid'
import { INavbar } from '../../../modules/widgets/general/INavbar'
import { ElementAlignment } from 'widgets-base'

export class Navbar implements INavbar {
    name = 'Navbar'
    type = 'navbar'
    id = nanoid()
    category = 'general'
    properties = {
        alignSelf: "stretch" as ElementAlignment,
    }
    settings = {
        pages: [],
        src: '/images/deployable-logo.png',
    }
    appearanceSettings = {
        width: 90,
        marginTop: 0,
        isBurger: false,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        font: {
            family: 'Poppins',
            size: 14,
            weight: 400,
        },
    }
    colourSettings = {
        'background colour': ['transparent', 'transparent', 'transparent'],
        'text colour': ['black', 'black', 'black'],
    }
}
