import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { capitalizeFirstLetter } from '../../v1/helpers/capitalizeFirstLetter';
import styled from 'styled-components';
import { IWidgetCategory, IWidgetTemplate } from '../widget/widget-templates';
import { TemplateWidget } from '../../v1/components/new/template-widget';
import { RenderContextProvider } from 'widgets-base';
import SearchInput from '../../v1/components/common/SearchInput';
import { ChangeEvent, useState } from 'react';

export interface IWidgetPaletteProps {
    //
    // The widget categories to render in the pallete.
    //
    widgetCategories: IWidgetCategory[];
}

export function WidgetPalette({ widgetCategories }: IWidgetPaletteProps) {

    const [searchValue, setSearchValue] = useState('')

    function onSearchWidgets(e: ChangeEvent<HTMLInputElement>) {
        setSearchValue(e.target.value);
    }

    function clearSearchResult() {
        setSearchValue('');
    }

    //
    // Filter a widget by the search term.
    //
    function filterWidget(widgetTemplate: IWidgetTemplate) {
        if (!searchValue) {
            return true;
        }

        const searchLwr = searchValue.toLowerCase();

        if (widgetTemplate.widget.name && widgetTemplate.widget.name.toLowerCase().includes(searchLwr)) {
            return true;
        }

        if (widgetTemplate.widget.xtype && widgetTemplate.widget.xtype.toLowerCase().includes(searchLwr)) {
            return true;
        }

        if (widgetTemplate.widget.type && widgetTemplate.widget.type.toLowerCase().includes(searchLwr)) {
            return true;
        }

        return false;
    }

    //
    // Filter widgets by the search term.
    //
    function filterWidgets(widgetTemplates: IWidgetTemplate[]) {
        return widgetTemplates.filter(filterWidget);
    }

    //
    // Renders a category of widgets.
    //
    function renderWidgetCategory(category: IWidgetCategory) {

        const filteredWidgets = filterWidgets(category.widgets);
        if (filteredWidgets.length === 0) {
            return undefined;
        }
    
        return (
            <Accordion
                key={category.name} 
                defaultExpanded={true}
                disableGutters={true}
                elevation={0}
                sx={{
                    '&:before': {
                        display: 'none',
                    },
                }}
                >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                        padding: "2px",
                    }}
                    >
                    <Typography>{capitalizeFirstLetter(category.name)}</Typography>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        padding: "2px",
                    }}
                    >
                    <div
                        className={`flex flex-${category.style || "col"} flex-wrap`}
                        style={{
                            display: 'flex',
                            position: 'relative',
                            gap: "20px",
                            rowGap: "11px",
                            columnGap: "11px",
                        }}
                        >
                        {filteredWidgets.map(template => (
                            <RenderContextProvider
                                key={template.widget.id} 
                                defaultRenderContext={{
                                    screen: "large",
                                    orientation: "landscape",
                                }}
                                >
                                <TemplateWidget
                                    template={template}
                                />
                            </RenderContextProvider>
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>
        );
    }
    
    return (
        <div className='p-4'>
            <SearchInput
                width='100%'
                value={searchValue}
                onChange={onSearchWidgets}
                placeholder='Search...'
                clear={clearSearchResult}
                />
            <AccordionsContainer>
                {widgetCategories.map(renderWidgetCategory)}
            </AccordionsContainer>
        </div>
    )
}

const AccordionsContainer = styled.div`
	width: 100%;
	overflow-y: hidden;
	overflow-x: hidden;
	padding-bottom: 50px;
`