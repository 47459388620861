import { nanoid } from 'nanoid'
import { IRadio } from '../../../modules/widgets/forms/IRadio'

export class RadioModel implements IRadio {
    id = nanoid()
    name = 'radio button'
    type = 'radio'
    icon = '/icons/widgets/radio button.svg'
    category = 'form'
    colourSettings = {
        'label colour': ['rgba(0,0,0,1)', '#FFA7E1', '#0426FF'],
        'text colour': ['rgba(0,0,0,1)', '#FFA7E1', '#0426FF'],
        'border colour': ['rgba(0,0,0,0)', '#FFA7E1', '#0426FF'],
        'background colour': ['transparent', '#FFA7E1', '#0426FF'],
        'icon colour': ['rgba(0,0,0,1)', '#FFA7E1', '#0426FF'],
    }
    appearanceSettings = {
        font: {
            family: 'Poppins',
            size: 14,
            weight: 300,
        },
        width: 90,
        height: 0,
        alignment: 'center',
        spacing: 5,
        columns: 1,
        border: true,
        borderRadius: true,
        margin: true,
        padding: true,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
        borderBottomLeftRadius: 2,
        borderBottomRightRadius: 2,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        iconSize: 10,
    }
    settings = {
        options: [
            {
                label: 'option 1',
                value: 'option 1',
                state: false,
            },
        ],
        defaultValue: { label: 'option 1', value: 'option 1' },
        placeholder: 'placeholder text',
        required: false,
        name: 'radio button',
        label: 'Radio Button Group',
    }
}
