import { createGlobalStyle, css } from 'styled-components'
import { color, typography } from './styles'
import reset from 'styled-reset'

export const bodyStyles = css`
    font-family: ${typography.type.primary};
    font-display: block;
    // Removed font size that is causing a difference between v1 and v2.
    // TODO: Might want to restore this later.
    // font-size: 10px !important;
    color: ${color.darkest};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-overflow-scrolling: touch;
    * {
        box-sizing: border-box;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: ${typography.weight.regular};
        margin: 0;
        padding: 0;
    }

    button,
    input,
    textarea,
    select {
        outline: none;
        font-family: ${typography.type.primary};
    }
    sub,
    sup {
        font-size: 0.8em;
    }
    sub {
        bottom: -0.2em;
    }
    sup {
        top: -0.2em;
    }
    b {
        font-weight: ${typography.weight.bold};
    }
    em {
        font-style: italic;
    }
    strong {
        font-weight: bold;
    }
    hr {
        border: none;
        border-top: 1px solid ${color.border};
        clear: both;
        margin-bottom: 1.25rem;
    }
    code,
    pre {
        font-family: ${typography.type.code};
        font-size: ${typography.size.s2 - 1}px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    code {
        display: inline-block;
        padding-left: 2px;
        padding-right: 2px;
        vertical-align: baseline;
        color: ${color.secondary};
    }
    pre {
        line-height: 18px;
        padding: 11px 1rem;
        white-space: pre-wrap;
        background: rgba(0, 0, 0, 0.05);
        color: ${color.darkest};
        border-radius: 3px;
        margin: 1rem 0;
    }
`

export const GlobalStyle = createGlobalStyle`
    ${reset};
    
    html {
        box-sizing: border-box;
      }
    body {
      ${bodyStyles}
    }
    * {
        margin: 0;
        padding: 0;
        letter-spacing: 0;
    }
    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }
    a {
        text-decoration: none;
    }
    .MuiButtonBase-root,
    .MuiTypography-body1,
    .MuiTabs-root,
    .MuiTab-root,
    .MuiButtonBase-root:active {
      font-family: 'Matter', sans-serif !important;
      outline: none;
      letter-spacing: 0;
    }
    input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            display: none;
            -webkit-appearance: none;
            margin: 0;
        }
`

export const theme = {
    colors: {
        primary: '#6478F9',
        secondary: '#3AE29B',
        orange: '#FF7D34',
        danger: '#ff0000',
    },
    text: {
        primary: '#192536',
        secondary: '#3C4B61',
        light: '#717F95',
        danger: '#ff0000',
    },
    lineColour: '#DFE2F2',
    fontSize: {
        small: '1.2rem',
        medium: '1.4rem',
        large: '1.6rem',
    },
}
