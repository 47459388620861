import { FC } from 'react'
import { WidgetSettingContainer } from '../../../Design/WidgetSettingContainer'
import DigitalCardGeneralSetting from '../../../EditMode/Settings/DigitalCardGeneralSetting'
import SidebarTabs from '../../../EditMode/SidebarTabs'
import DigitalCardDesignSetting from './DigitalCardDesignSetting'

interface Props {
}

const DigitalCardSetting: FC<Props> = () => {
    return (
        <WidgetSettingContainer padding={0}>
            <SidebarTabs
                setting={<DigitalCardGeneralSetting />}
                design={<DigitalCardDesignSetting />}
            />
        </WidgetSettingContainer>
    )
}

export default DigitalCardSetting
