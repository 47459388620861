import {useState} from 'react'
import {styled, Theme, CSSObject} from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItemButton from '@mui/material/ListItemButton'
import {ButtonProps, IconButton} from '@mui/material'

import {Logo} from './Logo'
import AccountNav from './AccountNav'
import {NavLinks} from './NavLinks'
import {Notifications} from './Notifications'

const drawerWidth = 250

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
})

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    })
)

const ColorButton = styled(ListItemButton)<ButtonProps>(({theme}) => ({
    minHeight: 48,
    px: 2.5,
    margin: '5px',
    borderRadius: '8px',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
        backgroundColor: 'rgba(100, 120, 249, 0.15)',
        boxShadow: '0px 0px 5px 3px rgba(0, 0, 0, 0.3)',
        transform: 'translateY(-2px)',
    },
}))

export const MiniDrawer = ({ children }) => {
    const navbarStateFromLS = JSON.parse(localStorage.getItem('isOpenNavbar'));
    const isOpenNavbar = typeof navbarStateFromLS === "boolean" ? navbarStateFromLS : true;

    const [openNavbar, setOpenNavbar] = useState(isOpenNavbar);

    const handleToggleDrawer = () => {
        setOpenNavbar((prev) => {
            localStorage.setItem('isOpenNavbar', JSON.stringify(!prev))
            return !prev
        })
    }
    return (
        <Box sx={{display: 'flex', position: 'relative'}}>
            <Drawer 
                variant="permanent" 
                open={openNavbar} 
                sx={{
                    position: 'relative',
                    zIndex: 3000,
                }}>
                <DrawerHeader>
                    <Logo isMobile={!openNavbar} toggle={handleToggleDrawer} isOpen={openNavbar}/>
                </DrawerHeader>
                <Divider/>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        justifyContent: 'space-between',
                    }}
                >
                    <List>
                        <NavLinks openNavbar={openNavbar}/>
                        <Divider/>
                    </List>

                    <List>
                        <Notifications openNavbar={openNavbar} />
                        <Divider/>
                        <AccountNav openNavbar={openNavbar}/>
                    </List>
                </Box>
            </Drawer>
            <Box component="main" sx={{flexGrow: 1, margin: '20px 0', position: 'relative'}}>
                {children}
            </Box>
        </Box>
    )
}
