import { Divider } from '@mui/material'
import { PageContainer } from './styles'

const DocSlack = () => {
    return (
        <PageContainer>
            <h1>Sending messages using Incoming Webhooks</h1>
            <span>
                Incoming Webhooks are a simple way to post messages from apps into Slack. Creating
                an Incoming Webhook gives you a unique URL to which you send a JSON payload with the
                message text and some options. We're going to walk through a really quick 4-step
                process (if you've already done some of these things it'll be even easier) that will
                have you posting messages using Incoming Webhooks in a few minutes.
            </span>
            <Divider />
            <div className="content">
                <h2>1. Create a Slack app (if you don't have one already)</h2>
                <p>
                    Go to page{' '}
                    <a target="_blank" rel="noreferrer" href="https://api.slack.com/">
                        api.slack.com
                    </a>{' '}
                    and click on the Create an app button. You won't get very far without completing
                    this step, but luckily it's very easy, they even have a nice green Create new
                    app button that you can click. Pick a name, choose a workspace to associate your
                    app with (bearing in mind that you'll probably be posting lots of test messages,
                    so you might want to create a channel for sandbox use), and then click Create
                    App. If you've already created one, you can use it too.
                </p>
                <img className="image" src="/images/docs/slack1.png" alt="slack step" />
                <h2>2. Enable Incoming Webhooks</h2>
                <p>
                    After creating, you'll be redirected to the settings page for your new app (if
                    you are using an existing app, just load its settings via your app's management
                    dashboard). From here select the Incoming Webhooks feature, and click the
                    Activate Incoming Webhooks toggle to switch it on.{' '}
                </p>
                <img className="image" src="/images/docs/slack2.png" alt="slack step" />
                <h2>3. Create an Incoming Webhook</h2>
                <p>
                    Now that Incoming Webhooks are enabled, the settings page should refresh and
                    some extra options will appear. One of those options will be a really helpful
                    button marked Add New Webhook to Workspace, and you should click it. What this
                    button does is trigger a shortcut version of the installation flow for Slack
                    apps, one that is completely self-contained so that you don't have to actually
                    build any code to generate an Incoming Webhook URL. Go ahead and pick a channel
                    that the app will post to, and then click to Authorize your app. If you need to
                    add the Incoming Webhook to a private channel you must first be in that channel.
                </p>
                <img className="image" src="/images/docs/slack3.png" alt="slack step" />
                <p>
                    You'll be sent back to your app settings, and you should now see a new entry
                    under the Webhook URLs for Your Workspace section, with a Webhook URL that'll
                    look something like this
                    https://hooks.slack.com/services/T00000000/B00000000/XXXXXXXXXXXXXXXXXXXXXXXX.
                    That URL is your shiny new Incoming Webhook, one that's specific to a single
                    user, and a single channel. We've kind of run out of cookies, but nice work
                    anyway! Let's see how you can actually use that webhook to post a message.
                </p>
                <h2>4. Use your Incoming Webhook URL in forms or games to post a message</h2>
                <p>
                    The generated webhook can be used in the form widget settings. When switching to
                    form editing mode, you can specify it in a special field for integrations and
                    all submissions will be sent to your Slack channel. You can also receive
                    information about the players in your draws. When creating a game, save your
                    webhook in the Actions after game section. Enjoy!
                </p>
                <img className="image" src="/images/docs/slack4.png" alt="slack step" />
            </div>
        </PageContainer>
    )
}

export default DocSlack
