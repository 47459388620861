import { getFilenameFromUrl } from "./path";
import { IImageProperty } from "./property";

//
// Gets the full URL of an image.
//
export function getImageUrl(imageProperty: IImageProperty | undefined, isStaticExport: boolean, makeAssetUrl: (url: string) => string) {
    if (isStaticExport) {
        if (imageProperty?.assetId) {
            return `assets/${imageProperty.assetId}`;
        }
        else if (imageProperty?.url) {
            const url = new URL(makeAssetUrl(imageProperty.url));
            return `assets/${getFilenameFromUrl(url.pathname)}`;
        }
    }
    else {
        if (imageProperty?.assetId) {
            return makeAssetUrl(imageProperty.assetId);
        }
        else {
            return imageProperty?.url;
        }
    }
}
