import { nanoid } from "nanoid";
import { makeStyledElement, IPropertyBag, IExpandedPropertyBag, IRenderContext, IPageTheme, useWidget, useRenderContext, usePageTheme, useDragContext, IWidgetType, makeColorProperty, stdFontFamily, stdFontSize, stdLineHeight, stdTextAlignment, stdElementAlignment, stdPadding, stdMargin } from "widgets-base";

const Div = makeStyledElement("div");

//
// Style elements created on the fly that are cached for reuse.
//
const cachedElements = {};

//
// Make a styled element on the fly and cache it.
//
function makeElement(tag: string) {
    const cachedElement = cachedElements[tag];
    if (cachedElement !== undefined) {
        return cachedElement;
    }

    const element = makeStyledElement(tag);
    cachedElements[tag] = element;
    return element;
}

//
// Force common elements to be created to avoid warnings in the log.
//
makeElement("h1");
makeElement("h2");
makeElement("p");
makeElement("u");
makeElement("strong");
makeElement("em");

// 
// Rebuilds an element.
//
function rebuildElement(widgetId: string, el: ChildNode, index: number, properties: IPropertyBag, expandedProperties: IExpandedPropertyBag, renderContext: IRenderContext, pageTheme: IPageTheme) {

    if (el.nodeType === Node.TEXT_NODE) {
        return  <span key={index}>{el.textContent}</span>;
    }

    const tag = el.nodeName.toLowerCase();
    if (tag === "br") {
        return <br key={index} />;
    }


    const Element = makeElement(tag);
    return (
        <Element
            key={index}
            widgetId={widgetId}
            elementName={tag}
            variantName={properties.variant || "default"}
			pageTheme={pageTheme}
            renderContext={renderContext}
            >
            {Array.from(el.childNodes).map((child, index) => rebuildElement(widgetId, child, index, properties, expandedProperties, renderContext, pageTheme))}
        </Element>
    );
}

//
// Rebuilds HTML with proper elements.
//
function rebuildHtmlText(widgetId: string, text: string, properties: IPropertyBag, expandedProperties: IExpandedPropertyBag, renderContext: IRenderContext, pageTheme: IPageTheme) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');
    return Array.from(doc.body.childNodes).map((el, index) => rebuildElement(widgetId, el, index, properties, expandedProperties, renderContext, pageTheme));
}

function Static() {

    const { widget, properties, expandedProperties } = useWidget();
    const { renderContext } = useRenderContext();
	const { pageTheme } = usePageTheme();

    //todo: Applying the attributes at the top level for the chatbot will wipe out any custom styles.
    return (
        <Div
            widgetId={widget.id}
            properties={expandedProperties}
            renderContext={renderContext}
            {...widget.attributes || {}}
            >
            {rebuildHtmlText(widget.id, properties?.content || "", properties, expandedProperties, renderContext, pageTheme)}
        </Div>
    );
}

function Editor() {

    const { widget, properties, expandedProperties } = useWidget();
    const { renderContext } = useRenderContext();
	const { pageTheme } = usePageTheme();
    const { dragProps, setRef, onSelect } = useDragContext();

    return (
        <Div
            {...dragProps}
            ref={setRef}
            onClick={event => {
                event.stopPropagation();
                event.preventDefault();
                onSelect();
            }}
            widgetId={widget.id}
            properties={expandedProperties}
            renderContext={renderContext}
            >
            {rebuildHtmlText(widget.id, properties?.content || "", properties, expandedProperties, renderContext, pageTheme)}
        </Div>
    );
}

const widgetType: IWidgetType = {
    name: 'Text',
    Static: Static,
    Preview: Static,
    Editor: Editor,
    Template: Static,
    template: {
        id: `widget-${nanoid()}`,
        xtype: 'text',
        properties: {
            content: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>",
            alignSelf: "center",
            marginTop: 12,
            marginBottom: 12,
            marginLeft: 12,
            marginRight: 12,
            paddingTop: 12,
            paddingBottom: 12,
            paddingLeft: 12,
            paddingRight: 12,
        },
    },
    properties: [
        {
            id: "content",
            name: "Content",
            type: "rich-text",
            required: true,
            defaultValue: "",
            orientation: "vertical",
        },
        {
            id: "prompt",
            name: "Prompt",
            type: "text",
            defaultValue: "",
        },
        {
            id: "tag",
            name: "Tag",
            type: "text",
            defaultValue: "",
        },
    ],
    propertyCategories: [
        {
            name: "Colors",
            properties: [
                makeColorProperty("normal", "color", "Color"),
            ],
        },
        {
            name: "Design",
            properties: [
                stdFontFamily,
                stdFontSize,
                stdLineHeight,
                stdTextAlignment,
            ],
        },
        {
            name: "Placement",
            properties: [
                stdElementAlignment,
                stdPadding,
                stdMargin,
            ],
        },
    ],
};

export default widgetType;
