import { API_URL } from '../../../lib/http'

export const uploadFontFile = async (file: File) => {
    let formData = new FormData()
    formData.append('true', file)

    const result = await fetch(`${API_URL}/uploadFonts`, {
        method: 'POST',
        body: formData,
        credentials: 'include',
    })

    const { data } = await result.json()

    return data.url
}
