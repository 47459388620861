import { Divider } from '@mui/material'
import { ChangeEvent, FC } from 'react'
import styled from 'styled-components'
import { InputBox } from '../../common/InputBox'
import { WidgetSettingContainer } from '../../Design/WidgetSettingContainer'
import AnswerList from './AnswerList'
import { useWidget } from 'widgets-base'

interface Props {
}

const PollsGeneralSetting: FC<Props> = () => {

    const { widget, setWidget } = useWidget();
    
    const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
        const newWidget = {
            ...widget,
            settings: {
                ...widget.settings,
                [event.target.name]: event.target.value,
            },
        }

        setWidget(newWidget);
    }

    const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
        const newWidget = {
            ...widget,
            name: event.target.value,
        }

        setWidget(newWidget);
    }

    return (
        <WidgetSettingContainer padding={0}>
            {widget?.type === 'multiple polls' && (
                <DescriptionSettingBlock>
                    <InputBox
                        label="Poll header"
                        width="100%"
                        name="label"
                        onChange={onChangeValue}
                        type="text"
                        value={widget?.settings?.label}
                    />
                    <InputBox
                        label="Poll title"
                        width="100%"
                        name="title"
                        onChange={onChangeValue}
                        type="text"
                        value={widget?.settings?.title}
                    />
                    <InputBox
                        label="Poll subtitle"
                        width="100%"
                        name="subtitle"
                        onChange={onChangeValue}
                        type="text"
                        value={widget?.settings?.subtitle}
                    />
                    <InputBox
                        label="Button text"
                        width="100%"
                        name="buttonText"
                        onChange={onChangeValue}
                        type="text"
                        value={widget?.settings?.buttonText}
                    />
                    <InputBox
                        label="Button link"
                        width="100%"
                        name="buttonLink"
                        onChange={onChangeValue}
                        type="text"
                        value={widget?.settings?.buttonLink}
                    />
                    <Divider />
                </DescriptionSettingBlock>
            )}
            <InputBox
                label="Poll name"
                width="100%"
                name="name"
                onChange={handleChangeName}
                type="text"
                value={widget?.name}
            />
            <InputBox
                label="Main question"
                width="100%"
                name="question"
                onChange={onChangeValue}
                type="text"
                value={widget?.settings?.question}
            />
            <Divider />
            <AnswerList answers={widget?.settings?.data} />
        </WidgetSettingContainer>
    )
}

export default PollsGeneralSetting

const DescriptionSettingBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 13px;
`
