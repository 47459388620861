import React, { FC, useState } from 'react'
import styled from 'styled-components'
import moment, { MomentInput } from 'moment'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { IEvent } from '../../../../modules/widgets/general/IEvents'
import { Modal } from './EventModal'
import ShareSocial from './ShareSocial'
import { useWidget } from 'widgets-base'

interface Props {
}

export const EventsList: FC<Props> = () => {
    const [openEvent, setOpenEvent] = useState<boolean>(false)
    const [currentEvent, setCurrentEvent] = useState<IEvent>()

    const { widget } = useWidget();
    const appearanceSettings = widget?.appearanceSettings
    const colourSettings = widget?.colourSettings

    const toggleModal = (event: IEvent) => () => {
        setOpenEvent((prev) => !prev)
        setCurrentEvent(event)
    }

    const getDayEvent = (date: MomentInput) => {
        const day = moment(date).format('ll')
        return day
    }

    const getTimeEvent = (date: MomentInput) => {
        const time = moment(date).format('LT')
        return time
    }

    const getFullDate = (date: MomentInput) => {
        const fullDate = moment(date).format('LLL')
        return fullDate
    }

    const sortedEvents = [...widget?.settings?.eventsList]?.sort((a, b) =>
        a.date > b.date ? 1 : -1
    )

    const mode: string = widget?.settings?.mode
    const fontFamily = appearanceSettings?.font?.family || 'Poppins'

    const backgroundColor = colourSettings['background colour'][0]
    const textColor = colourSettings['text colour'][0]
    const markerColor = colourSettings['date marker colour'][0]
    const markerTextColor = colourSettings['text marker colour'][0]
    const borderTopLeftRadius = appearanceSettings?.borderTopLeftRadius + 'px'
    const borderTopRightRadius = appearanceSettings?.borderTopRightRadius + 'px'
    const borderBottomLeftRadius = appearanceSettings?.borderBottomLeftRadius + 'px'
    const borderBottomRightRadius = appearanceSettings?.borderBottomRightRadius + 'px'
    const borderTopWidth = appearanceSettings.borderTopWidth / 10 + 'em'
    const borderBottomWidth = appearanceSettings.borderBottomWidth / 10 + 'em'
    const borderLeftWidth = appearanceSettings.borderLeftWidth / 10 + 'em'
    const borderRightWidth = appearanceSettings.borderRightWidth / 10 + 'em'
    const borderWidth = borderTopWidth && borderRightWidth && borderBottomWidth && borderLeftWidth

    return (
        <EventsContainer fontFamily={fontFamily} textColor={textColor}>
            <label>{widget?.settings?.label}</label>
            <div className="event_list">
                {sortedEvents?.map((item: IEvent) => {
                    const isPastEvent = new Date().getTime() > new Date(item.date).getTime()
                    return (
                        <EventItem
                            key={item.id}
                            onClick={toggleModal(item)}
                            borderTopLeftRadius={borderTopLeftRadius}
                            borderTopRightRadius={borderTopRightRadius}
                            borderBottomLeftRadius={borderBottomLeftRadius}
                            borderBottomRightRadius={borderBottomRightRadius}
                            border={`${borderWidth} solid #000`}
                            backgroundColor={backgroundColor}
                            isPastEvent={isPastEvent}
                        >
                            <div className="pass_event">PAST EVENT</div>
                            {!item.unsetDate && (
                                <DateEvent
                                    borderTopRightRadius={borderTopRightRadius}
                                    borderBottomLeftRadius={borderBottomLeftRadius}
                                    markerColor={markerColor}
                                    markerTextColor={markerTextColor}
                                >
                                    <span>{getDayEvent(item.date)}</span>
                                </DateEvent>
                            )}
                            {mode === 'large' && (
                                <EventImage
                                    url={item.image}
                                    borderTopLeftRadius={borderTopLeftRadius}
                                    borderTopRightRadius={borderTopRightRadius}
                                />
                            )}
                            <EventDescription>
                                <p>{item.label}</p>
                                <Details mode={mode}>
                                    <ul>
                                        {item?.category?.map((cat, index) => (
                                            <li key={index}>{cat}</li>
                                        ))}
                                    </ul>
                                    {!item.unsetDate && (
                                        <div className="time">
                                            <AccessTimeIcon fontSize="small" />
                                            <span>{getTimeEvent(item.date)}</span>
                                        </div>
                                    )}
                                </Details>
                            </EventDescription>
                            <Modal isOpen={openEvent}>
                                <ModalContent>
                                    <h1>{currentEvent?.label}</h1>
                                    <EventImage
                                        url={currentEvent?.image}
                                        borderTopLeftRadius="0px"
                                        borderTopRightRadius="0px"
                                    />
                                    <ul>
                                        {currentEvent?.category?.map((cat, index) => (
                                            <li key={index}>{cat}</li>
                                        ))}
                                    </ul>
                                    <Description>{currentEvent?.description}</Description>
                                    {!currentEvent?.unsetDate && (
                                        <TimeEvent>
                                            <span>
                                                <AccessTimeIcon fontSize="small" />
                                                WHEN
                                            </span>
                                            <span>{getFullDate(currentEvent?.date)}</span>
                                        </TimeEvent>
                                    )}
                                    <ShareSocial event={currentEvent} />
                                </ModalContent>
                            </Modal>
                        </EventItem>
                    )
                })}
            </div>
        </EventsContainer>
    )
}

const ModalContent = styled.div`
    width: 100%;
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #000;

    h1 {
        font-size: 25px;
        margin: 20px 0;
        font-weight: 600;
        text-align: left;
        width: 90%;
    }
    ul {
        display: flex;
        margin-top: 15px;
        width: 90%;
        justify-content: flex-end;
        align-items: center;
        direction: rtl;
        float: left;
    }
    li {
        font-size: 14px;
        line-height: 19px;
        color: rgba(0, 0, 0);
        opacity: 0.5;
        margin-right: 25px;
    }
    li:not(:first-child) {
        list-style-type: square;
    }
`

const TimeEvent = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 12px;
    color: #6e6e6e;
    padding: 10px 0;
    border-bottom: 1px solid #cacaca;
    margin-bottom: 18px;

    svg {
        margin-right: 3px;
    }
`

const Description = styled.span`
    width: 90%;
    margin: 20px 0;
    text-align: justify;
    font-size: 12px;
    line-height: 1.2;
`

const EventsContainer = styled.div<{
    fontFamily: string
    textColor: string
}>`
    width: 100%;
    display: flex;
    font-family: ${(props) => props.fontFamily};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.textColor};
    flex-wrap: wrap;

    label {
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .event_list {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;
    }
`

const Details = styled.div<{
    mode: string
}>`
    display: flex;
    align-items: ${(props) => (props.mode === 'large' ? 'center' : 'flex-start')};
    justify-content: space-between;
    flex-direction: ${(props) => (props.mode === 'large' ? 'row' : 'column')};
    width: 100%;
    ul {
        display: flex;
        max-width: 60%;
        flex-wrap: wrap;
        justify-content: flex-end;
        direction: rtl;
        float: left;
    }
    li {
        font-size: 14px;
        line-height: 19px;
        opacity: 0.5;
        margin-right: 25px;
    }
    li {
        list-style-type: square;
    }
`

const EventImage = styled.div<{
    url: string
    borderTopLeftRadius: string
    borderTopRightRadius: string
}>`
    height: 180px;
    background-image: ${(props) => `url(${props.url})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: ${(props) => props.borderTopLeftRadius};
    border-top-right-radius: ${(props) => props.borderTopRightRadius};
    width: 100%;
`

const EventItem = styled.div<{
    borderTopLeftRadius: string
    borderTopRightRadius: string
    borderBottomLeftRadius: string
    borderBottomRightRadius: string
    border: string
    backgroundColor: string
    isPastEvent: boolean
}>`
    width: 90%;
    max-width: 350px;
    border: ${(props) => props.border};
    border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
    border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
    border-top-left-radius: ${(props) => props.borderTopLeftRadius};
    border-top-right-radius: ${(props) => props.borderTopRightRadius};
    background-color: ${(props) => props.backgroundColor};
    position: relative;
    margin-bottom: 15px;

    .pass_event {
        position: absolute;
        width: 100%;
        height: 100%;
        display: ${(props) => (props.isPastEvent ? 'flex' : 'none')};
        align-items: center;
        justify-content: center;
        font-size: 35px;
        background-color: azure;
        color: #000;
        font-weight: 600;
        opacity: 0.7;
        border-radius: inherit;
        z-index: 4;
    }
`

const EventDescription = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 25px;

    p {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .time {
        display: flex;
        padding: 3px 10px;
        border-radius: 10px;
        background-color: #dddddd;
        align-items: center;
        justify-content: center;

        & > span {
            font-size: 12px;
            margin-left: 3px;
        }
    }
`

const DateEvent = styled.div<{
    borderTopRightRadius: string
    borderBottomLeftRadius: string
    markerColor: string
    markerTextColor: string
}>`
    position: absolute;
    top: 0;
    right: 0;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.markerColor};
    color: ${(props) => props.markerTextColor};
    border-radius: 50%;
    border-top-right-radius: ${(props) => props.borderTopRightRadius};
    border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
    z-index: 2;

    span {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
    }
`
