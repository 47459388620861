import { Tooltip } from '@mui/material'
import { useState } from 'react'
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined'
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined'
import styled from 'styled-components'
import { getColorValue } from '../../../helpers/checkColorValue'
import { ColorPicker, ColorPickerCallBack } from '../../Design/ColorPicker'
import { CustomModal } from '../../common/CustomModal'
import { AssetsImages } from '../../Design/asset-picker'
import { useCampaign } from '../../../../builder/context/campaign'
import { IView } from 'widgets-base'
import { IAsset } from '../../../modules/branding/IAssets'

const BackgrondSettings = () => {
    const [openColorPicker, setOpenColorPicker] = useState<boolean>(false)
    const [openAssetsModal, setOpenAssetsModal] = useState<boolean>(false)
    const { campaign, currentPageIndex, updatePage } = useCampaign();

    function toggleOpenColorPicker(): void {
        setOpenColorPicker((prev) => !prev)
    }

    function onChangePageColor(color: ColorPickerCallBack): void {
        const value = color.rgb
        const { r, g, b, a } = value
        const newColor = `rgb(${r},${g},${b},${a})`
        updatePage({
            backgroundImageUrl: undefined,
            backgroundImageId: undefined,
            backgroundColour: newColor,
        });
    };

    function toggleOpenAssetsModal() {
        setOpenAssetsModal((prev) => !prev)
    }

    function handleChangeBackgroundImage(image: IAsset): void {
        const newPage: Partial<IView> = {
            backgroundImageUrl: image.fileUrl,
            backgroundColour: undefined,
            backgroundImageId: image.fileUrl === undefined ? image._id : undefined,
        };
        updatePage(newPage);
        toggleOpenAssetsModal();
    }

    return (
        <Container>
            <Tooltip title="Background color" arrow>
                <div onClick={toggleOpenColorPicker}>
                    <ColorLensOutlinedIcon color="inherit" />
                </div>
            </Tooltip>
            {openColorPicker && (
                <ColorPicker
                    color={getColorValue(campaign?.views[currentPageIndex]?.backgroundColour)}
                    handleColorChange={onChangePageColor}
                    toggleOpen={toggleOpenColorPicker}
                />
            )}
            <Tooltip title="Background image" arrow>
                <div onClick={toggleOpenAssetsModal}>
                    <AddPhotoAlternateOutlinedIcon color="inherit" />
                </div>
            </Tooltip>

            <CustomModal
                visible={openAssetsModal}
                toggleVisible={toggleOpenAssetsModal}
                title="Assets modal"
            >
                <AssetsModalContent>
                    <h3>Select an image for the background</h3>
                    <AssetsImages onChangeAsset={handleChangeBackgroundImage} />
                </AssetsModalContent>
            </CustomModal>
        </Container>
    )
}

export default BackgrondSettings;

const Container = styled.div`
    position: relative;
    display: flex;
    align-items: center;

    .color_picker {
        bottom: -300px;
    }
`
const AssetsModalContent = styled.div`
    background-color: #fff;
    border-radius: 8px;
    max-width: 700px;
    max-height: 60vh;
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    overflow-y: auto;

    & > h3 {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 18px;
    }

    .assets_list {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        min-height: 300px;

        p {
            color: #aaa;
            font-size: 14px;
            margin-bottom: 10px;
        }

        span, label {
            color: #0933ee;
            font-weight: bold;
            cursor: pointer;
        }

				input[id^='uploadImage'] {
            display: none;
        }
    }

    .assets_grid_list {
        gap: 10px;
        grid-auto-rows: auto;
        display: grid;
        grid-template-columns: repeat(9, minmax(0, 1fr));
        justify-items: stretch;
        min-height: 300px;

        & > img:nth-child(2n + 1) {
            grid-area: span 7 / span 3 / auto / auto;
        }

        & > img:nth-child(7) {
            grid-area: span 5 / span 3 / auto / auto;
        }

        & > img {
            grid-area: span 12 / span 3 / auto / auto;
            border-radius: 12px;
            max-width: 100%;
            transition: all 0.2s ease-in-out;
        }

        & > img:hover {
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
            transform: translateY(-5px);
            cursor: pointer;
        }
    }
`
