import instance from '../lib/http'
import { IAsset } from '../v1/modules/branding/IAssets'
import { IBranding } from '../v1/modules/IBranding'

export default class BrandingService { // todo: Get rid of this.
    static async getAssets(): Promise<IAsset[]> {
        const { data } = await instance.get('/getAssets');
        return data;
    }

    static async getBrandSetting(): Promise<IBranding> {
        const { data } = await instance.get('/getBranding');
        return data;
    }

    static async deleteImage(_id: string, name: string): Promise<void> {
        await instance.post('/deleteImage', { _id, name });
    }

    static async saveBrandSetting(data: IBranding): Promise<void> {
        await instance.post('/saveBranding', { data });
    }
}
