import styled from 'styled-components'

export const VerifyEmailWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
`
export const VerifyEmailContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;

    h1 {
        color: #3ae29b;
        font-size: 2.5rem;
        font-weight: 700;
        margin-bottom: 18px;
    }

    p {
        font-weight: 500;
        font-size: 1rem;
    }

    & > div {
        margin-bottom: 0;
        margin-top: 40px;
    }
`
