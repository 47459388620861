import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import TableRowsIcon from '@mui/icons-material/TableRows';
import { StaticWidgets } from "../v1/components/new/static-widgets";
import { MoveableWidgets } from "../v1/components/new/moveable-widgets";
import { makeStyledElement, useWidget, useRenderContext, usePageTheme, useAuth, getImageUrl, useDragContext, determineDirection, IWidgetType, makeFullAssetUrl, stdGroupProperties, makeColorProperty, stdBorderWidth, stdBorderStyle, stdBorderRadius, stdElementAlignment, stdPadding, stdMargin, stdWidth, stdHeight } from 'widgets-base';

const Div = makeStyledElement("div");

function Static() {

    const { widget, properties, expandedProperties } = useWidget();
    const { renderContext } = useRenderContext();
    const { pageTheme } = usePageTheme();
    const { makeAssetUrl } = useAuth();

    let widgetClassName = `group flex flex-grow `;

    const backgroundImageUrl = getImageUrl(properties.backgroundImage, renderContext.isStaticExport, makeAssetUrl);

    const style: any = {
        overflow: "hidden",
    };
    if (backgroundImageUrl) {
        style.backgroundImage = `url(${backgroundImageUrl})`;
        style.backgroundSize = "cover";
        style.backgroundPosition = "center";
        style.backgroundRepeat = "no-repeat";
    }

    return (
        <Div
            widgetId={widget.id}
            className={widgetClassName}
            elementName="group"
            variantName={properties.variant || "default"}
            pageTheme={pageTheme}
            properties={expandedProperties}
            renderContext={renderContext}
            data-name={widget.name}
            style={style}
            >
            <StaticWidgets
                widgets={widget.children}
                />
        </Div>
    );
}

//todo: there should be a separate path here for preview widgets.

function Editor() {

    const { widget, widgetPath, properties, expandedProperties } = useWidget();
    const { renderContext } = useRenderContext();
    const { pageTheme } = usePageTheme();
    const { dragProps, setRef, onSelect } = useDragContext();
    const { makeAssetUrl } = useAuth();
    
	let direction = determineDirection(properties, renderContext);
    let widgetClassName = `group flex flex-grow `;

    const groupStyle: any = {};

    if (widget.children?.length === 0) {
        let direction = determineDirection(properties, renderContext);
        if (direction === "column") {
            if (!groupStyle.minWidth) {
                groupStyle.minWidth = "175px";
            }
            if (!groupStyle.minHeight) {
                groupStyle.minHeight = "250px";
            }
        }
        else {
            if (!groupStyle.minWidth) {
                groupStyle.minWidth = "250px";
            }
            if (!groupStyle.minHeight) {
                groupStyle.minHeight = "170px";
            }
        }
    }
    
    const backgroundImageUrl = getImageUrl(properties.backgroundImage, renderContext.isStaticExport, makeAssetUrl);

    const style: any = {};
    if (backgroundImageUrl) {
        style.backgroundImage = `url(${backgroundImageUrl})`;
        style.backgroundSize = "cover";
        style.backgroundPosition = "center";
        style.backgroundRepeat = "no-repeat";
    }

    return (
        <Div
            {...dragProps}
            ref={setRef}
            onClick={event => {
                event.stopPropagation();
                event.preventDefault();
                onSelect();
            }}
            widgetId={widget.id}
            className={widgetClassName}
            elementName="group"
            variantName={properties.variant || "default"}
            pageTheme={pageTheme}
            properties={expandedProperties}
            renderContext={renderContext}
            >
            <MoveableWidgets
                key={`${widget.id}-${widget.lastUpdate}`}
                groupId={widget.id}
                widgets={widget.children}
                direction={direction}
                parentWidgetPath={widgetPath}
                />
        </Div>
    );
}

function Template() {

    const { widget } = useWidget();

    if (widget.children?.length === 0) {
        return (
            <div className="empty-group flex flex-row items-center justify-center">
                <span className="pr-2">Group</span>
                <ViewColumnIcon />
                <TableRowsIcon />
            </div>
        );
    }
    else {
        return <Static />;
    }
}

const widgetType: IWidgetType = {
    name: 'Group',
    Static: Static,
    Preview: Static,
    Editor: Editor,
    Template: Template,
    collectAssets: (widget, assetIds, assetUrls) => {
        if (widget.properties?.backgroundImage?.assetId) {
            assetIds.add(widget.properties?.backgroundImage?.assetId);
        }
        else if (widget.properties?.backgroundImage?.url) {
            assetUrls.add(makeFullAssetUrl(widget.properties?.backgroundImage?.url));
        }
    },
    properties: [
        {
            id: "flexDirection",
            name: "Direction",
            type: "option",
            values: [{
                    name: "Row", 
                    value: "row", 
                    icon: <ViewColumnIcon />,
                },
                {
                    name: "Column", 
                    value: "column",
                    icon: <TableRowsIcon />,
                },
            ],
            required: true,
            defaultValue: "row",
        },
    ],
    propertyCategories: [
        {
            name: "Items",
            properties: stdGroupProperties,
        },
        {
            name: "Colors",
            properties: [
                makeColorProperty("normal", "color", "Color"),
                makeColorProperty("normal", "borderColor", "Border"),
                makeColorProperty("normal", "backgroundColor", "Background"),
                {
                    id: "src",
                    name: "Background image",
                    type: "image",
                    defaultValue: "",
                },
            ],
        },
        {
            name: "Border",
            properties: [
                stdBorderWidth,
                stdBorderStyle,
                stdBorderRadius,
            ],
        },
        {
            name: "Placement",
            properties: [
                stdElementAlignment,
                stdPadding,
                stdMargin,
                stdWidth,
                stdHeight,
            ],
        },
    ],
};


export default widgetType;
