import React from 'react'
import {useNavigate} from 'react-router-dom'
import styled from 'styled-components'
import {capitalizeFirstLetter} from '../../helpers/capitalizeFirstLetter'
import {RouteNames} from '../../../router'
import {Button} from '../common/Button'
import { useAuth, UserRoles } from 'widgets-base'

const DashboardHeader = () => {
    const {currentUser} = useAuth()
    const navigate = useNavigate()

    const handleClickToUpgrade = () => {
        navigate(RouteNames.BILLING)
    }

    const getStatusColor = (status: string) => {
        const currentPlan = status?.toLowerCase()
        switch (currentPlan) {
            case UserRoles.FREE:
                return {button: '#a3b0ff', border: '#3e58ff'}
            case UserRoles.LITE:
                return {button: '#a3fffa', border: '#0efcf0'}
            case UserRoles.PREMIUM:
                return {button: '#abffa3', border: 'rgba(30,255,0,0.78)'}
            case UserRoles.ADMIN:
                return {button: '#aeffa3', border: 'rgba(30,255,0,0.78)'}
            default:
                return {button: '#a3b0ff', border: '#3e58ff'}
        }
    }

    return (
        <HeaderContainer>
            <h1>{`Welcome back, ${capitalizeFirstLetter(
                currentUser?.firstname
            )} ${capitalizeFirstLetter(currentUser?.lastname)}`}</h1>
            <Button
                width="max-content"
                size="small"
                type="button"
                variant="primary"
                onClick={handleClickToUpgrade}
            >
                <p>
                    Your current plan: <span>{currentUser?.role?.toUpperCase()}</span>
                </p>
                {currentUser?.role === UserRoles.FREE || currentUser?.role === UserRoles.LITE ? (
                    <span>UPGRADE PLAN</span>
                ) : null}
            </Button>
        </HeaderContainer>
    )
}

export default DashboardHeader

const HeaderContainer = styled.div<{
    // statusColor: { button: string; border: string }
}>`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    background-color: #fff;
    justify-content: flex-start;
    gap: 10px;
    h1 {
        font-size: 16px;
        font-weight: 700;
        color: green;
    }
    button {
        display: flex;
        flex-direction: column;
        background: none;
        & > p {
            font-size: 12px;
            color: green;
            span {
                font-size: 16px;
                color: green;
            }
        }
        & > span {
            color: green;
            margin-top: 5px;
        }
    }

    @media (max-width: 720px) {
        flex-direction: column;
        padding-top: 10px;
        button {
            width: 100%;
        }
    }
`
