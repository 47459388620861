import { useEffect, useState } from "react";
import { useProperty } from "../../context/property";
import { IPropertyType } from "../property-type";

//
// An editor for text properties.
//
function PropertyTextEditor() {

    const { propertyValue, setPropertyValue } = useProperty();
    const [ text, setText ] = useState<string>(propertyValue || "");

    useEffect(() => {
        const timer = setTimeout(() => {
            const curValue = propertyValue || "";
            if (curValue !== text) {
                setPropertyValue(text);
            }       
        }, 1000);

        return () => {
            clearTimeout(timer);
        };

    }, [text]);

    useEffect(() => {
        const newText = propertyValue || "";
        if (newText !== text) {
            setText(newText);
        }

    }, [propertyValue]);

    return (
        <input
            className="w-full"
            style={{
                fontFamily: "Matter, \"Helvetica Neue\", Helvetica, Arial, sans-serif",
                borderRadius: "4px",
                border: "1px solid rgb(223, 226, 242)",
                color: "rgb(113, 127, 149)",
                padding: "5px 6px",
                fontSize: "14px",
            }}
            value={text}
            onChange={event => {
                setText(event.target.value);
            }}
        />
    );
}

const text: IPropertyType = {
    editor: () => <PropertyTextEditor />,
};

export default text;
