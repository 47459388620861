import styled from 'styled-components'
import { useForm } from "../../builder/context/form-state-new";
import { useWidget, useRenderContext, usePageTheme, useDragContext, makeStyledElement, IWidgetType, makeColorProperty, stdBorderWidth, stdBorderStyle, stdBorderRadius, stdFontFamily, stdFontSize, stdLineHeight, stdTextAlignment, stdElementAlignment, stdPadding, stdMargin, stdWidth, stdHeight } from 'widgets-base';

function Static() {

    const { widget, properties, expandedProperties, parentWidget } = useWidget();
    const form = useForm();
    const { renderContext } = useRenderContext();
    const { pageTheme } = usePageTheme();

    return (
        <Div
            widgetId={widget.id}
            elementName="checkbox"
            variantName={properties.variant || "default"}
            pageTheme={pageTheme}
            properties={expandedProperties}
            renderContext={renderContext}
            >
            <input
                id={parentWidget?.id}
                type="checkbox"
                name={parentWidget?.properties?.fieldName}
                required={expandedProperties.required}
                checked={form?.getFieldValue(parentWidget?.properties?.fieldName) || false}
                onChange={event => {
                    if (form) {
                        form.setFieldValue(parentWidget?.properties?.fieldName, event.target.checked ? "checked" : "");
                    }
                }}
                />
        </Div>
    );
}

function Editor() {

    const { widget, properties, expandedProperties } = useWidget();
    const { renderContext } = useRenderContext();
    const { pageTheme } = usePageTheme();
    const { dragProps, setRef, onSelect } = useDragContext();

    return (
        <Div
            {...dragProps}
            ref={setRef}
            onClick={event => {
                event.stopPropagation();
                event.preventDefault();
                onSelect();
            }}
            widgetId={widget.id}
            elementName="checkbox"
            variantName={properties.variant || "default"}
            pageTheme={pageTheme}
            properties={expandedProperties}
            renderContext={renderContext}
            >
            <input
                type="checkbox"
                name={widget.name}
                readOnly
                />
        </Div>
    );
}

function Template() {

    const { widget, properties, expandedProperties } = useWidget();
    const { renderContext } = useRenderContext();
    const { pageTheme } = usePageTheme();

    return (
        <Div
            widgetId={widget.id}
            elementName="checkbox"
            variantName={properties.variant || "default"}
            pageTheme={pageTheme}
            properties={expandedProperties}
            renderContext={renderContext}
            >
            <input
                type="checkbox"
                name={widget.name}
                readOnly
                />
        </Div>
    );
}

const Div = styled(makeStyledElement("div"))`
    .template-widget & input  {        
        width: 8px;
        height: 8px;
    }
`;

const widgetType: IWidgetType = {
    name: 'Checkbox',
    Static: Static,
    Preview: Static,
    Editor: Editor,
    Template: Template,
    isFormWidget: true,
    affinity: [ "field" ], // A checkbox can only be placed in a field.
    properties: [
        {
            id: "placeholder",
            name: "Placeholder",
            type: "text",
            required: true,
            defaultValue: "",
        },
        {
            id: "required",
            name: "Required",
            type: "boolean",
            required: true, 
            defaultValue: false,
        },
    ],
    propertyCategories: [
        {
            name: "Colors",
            properties: [
                makeColorProperty("normal", "color", "Color"),
                makeColorProperty("hover", "color", "Color / Hover"),
                makeColorProperty("normal", "borderColor", "Border"),
                makeColorProperty("hover", "borderColor", "Border / Hover"),
                makeColorProperty("normal", "backgroundColor", "Background"),
                makeColorProperty("hover", "backgroundColor", "Background / Hover"),
            ],
        },
        {
            name: "Border",
            properties: [
                stdBorderWidth,
                stdBorderStyle,
                stdBorderRadius,
            ],
        },
        {
            name: "Design",
            properties: [
                stdFontFamily,
                stdFontSize,
                stdLineHeight,
                stdTextAlignment,
            ],
        },
        {
            name: "Placement",
            properties: [
                stdElementAlignment,
                stdPadding,
                stdMargin,
                stdWidth,
                stdHeight,
            ],
        },
    ],
};

export default widgetType;