import { FC } from 'react'
import styled from 'styled-components'
import { CheckboxField } from './FormFields/CheckboxField'
import { DatePickerField } from './FormFields/DatePickerField'
import { InputField } from './FormFields/InputField'
import { RadioField } from './FormFields/RadioField'
import { RatingField } from './FormFields/RatingField'
import { SelectField } from './FormFields/SelectField'
import { TextareaField } from './FormFields/TextareaField'
import { UploadField } from './FormFields/UploadField'
import { IWidget } from 'widgets-base'
import { WidgetContextProvider } from '../../../../builder/context/widget'

interface Props {
    widgets: IWidget[]
    gap?: string
    borderFieldsColor?: string
    backgroundFieldsColor?: string
}

const RenderFormWidgets: FC<Props> = ({
    widgets,
    gap = '12px',
    borderFieldsColor = 'transparent',
    backgroundFieldsColor = '#fff',
}) => {

    return (
        <Container gap={gap}>
            {widgets?.map((widget) => {
                if (!widget) return null
                const widgetType = widget.xtype || widget.type

                switch (widgetType) {
                    case 'input box':
                        return (
                            <WidgetContextProvider
                                key={widget.id}
                                widget={widget}
                            >
                                <InputField
                                    key={widget.id}
                                    borderFieldsColor={borderFieldsColor}
                                    backgroundFieldsColor={backgroundFieldsColor}
                                />
                            </WidgetContextProvider>
                        )
                    case 'text area':
                        return (
                            <WidgetContextProvider
                                key={widget.id}
                                widget={widget}
                            >
                                <TextareaField
                                    key={widget.id}
                                    borderFieldsColor={borderFieldsColor}
                                    backgroundFieldsColor={backgroundFieldsColor}
                                />
                            </WidgetContextProvider>
                        )
                    case 'select':
                        return (
                            <WidgetContextProvider
                                key={widget.id}
                                widget={widget}
                            >
                                <SelectField
                                    key={widget.id}
                                    borderFieldsColor={borderFieldsColor}
                                    backgroundFieldsColor={backgroundFieldsColor}
                                />
                            </WidgetContextProvider>
                        )
                    case 'radio':
                        return (
                            <WidgetContextProvider
                                key={widget.id}
                                widget={widget}
                            >
                                <RadioField key={widget.id} />
                            </WidgetContextProvider>
                        );
                    case 'checkbox':
                        return (
                            <WidgetContextProvider
                                key={widget.id}
                                widget={widget}
                            >
                                <CheckboxField key={widget.id} />
                            </WidgetContextProvider>
                        );
                    case 'upload':
                        return (
                            <WidgetContextProvider
                                key={widget.id}
                                widget={widget}
                            >
                                <UploadField
                                    key={widget.id}
                                    borderFieldsColor={borderFieldsColor}
                                    backgroundFieldsColor={backgroundFieldsColor}
                                />
                            </WidgetContextProvider>
                        )
                    case 'rating':
                        return (
                            <WidgetContextProvider
                                key={widget.id}
                                widget={widget}
                            >
                                <RatingField key={widget.id} />
                            </WidgetContextProvider>
                        );
                    case 'datepicker':
                        return (
                            <WidgetContextProvider
                                key={widget.id}
                                widget={widget}
                            >
                                <DatePickerField
                                    key={widget.id}
                                    borderFieldsColor={borderFieldsColor}
                                    backgroundFieldsColor={backgroundFieldsColor}
                                />
                            </WidgetContextProvider>
                        )
                    default:
                        return null
                }
            })}
        </Container>
    )
}

export default RenderFormWidgets

const Container = styled.div<{
    gap: string
}>`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${(props) => props.gap};
    margin: 10px 0;
`
