import { FC, ReactNode } from 'react'
import styled from 'styled-components'

interface Props {
    children: ReactNode
    padding: number
}

export const WidgetSettingContainer: FC<Props> = ({ padding, children }) => {
    return <ContainerDiv padding={padding}>{children}</ContainerDiv>
}

const ContainerDiv = styled.div<{
    padding: number
}>`
    width: 100%;
    padding: ${(props) => props.padding + 'px'};
    display: flex;
    flex-direction: column;
    gap: 15px;

    .MuiButtonBase-root {
        border-radius: 10px;
        letter-spacing: 0;
        text-transform: none;
    }

    .MuiInputBase-root {
        border-radius: 12px;
    }

    .input_box,
    .toggle_container {
        margin-bottom: 0;
    }
`
