import { createContext, ReactNode, useContext, useState } from "react";
import { IRenderContext } from "../lib/render-context";

export interface IRenderContextHook {
    //
    // The current render context.
    //
    renderContext: IRenderContext;

    //
    // Sets the current render context.
    //
    setRenderContext: (renderContext: IRenderContext) => void;
}

const RenderContext = createContext<IRenderContextHook>(null);

export interface IRenderContextProps {
    //
    // The default render context.
    //
    defaultRenderContext?: IRenderContext;

    children: ReactNode | ReactNode[];
}

export function RenderContextProvider({ defaultRenderContext, children }: IRenderContextProps) {

    const [renderContext, setRenderContext] = useState<IRenderContext>(defaultRenderContext || {
        screen: "large",
        orientation: "landscape",
    });

    const value: IRenderContextHook = {
        renderContext, 
        setRenderContext,
    };

    return (
        <RenderContext.Provider 
            value={value}
        >
            {children}
        </RenderContext.Provider>
    );    
}

export function useRenderContext(): IRenderContextHook {
    const context = useContext(RenderContext);
    if (!context) {
        throw new Error(`Render context is not set! Add RenderContextProvider to the component tree.`);
    }
    return context;
}
