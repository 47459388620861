import { createContext, ReactNode, useContext, useState } from "react";
import FormService from "../../services/FormService";
import { Toast } from "../../v1/helpers/Toast";
import { formFileUpload } from "../../v1/lib/forms/formFileUpload";
import { flattenTree } from "../lib/utils";
import { useCampaign } from "./campaign";
import validator from 'validator'
import { checkValidEmail, checkValidPhoneNumber } from "../../v1/helpers/validation";
import { getWidgetType } from "../widget/widget-types";
import { FormFields, IFields } from "../../v1/modules/widgets/forms/IForm";
import _ from "../../lib/lodash";
import { IWidget, useWidget } from "widgets-base";

export interface IFormDetails {
    campaignId: string;
    schemaVersion: number;
    formId: string;
    formName: string;
    formType: string;
}

export interface IFormValues {
    [key: string]: any;
}

export interface IFormValuesHook {

    //
    // The current values of the form.
    //
    formValues: IFormValues;

    //
    // Sets new values for the form.
    //
    setFormState(newFormState: IFormValues): void;

    //
    // Updates particular fields in the form state.
    //
    updateFormState(newFields: Partial<IFormValues>): void;

    //
    // Gets the value for a field.
    //
    getFieldValue(fieldName: string): any | undefined;

    //
    // Sets a single field value in the form.
    //
    setFieldValue(fieldName: string, value: any): void;

    //
    // Clears all form value.
    //
    clearForm(): void;

    //
    // Set to true when the form is being submitted.
    //
    submitting: boolean;

    //
    // Validates the form.
    //
    validateForm(): string | undefined;

    //
    // Submits the form.
    //
    submitForm(): Promise<void>;

    //
    // Gets the current form state in the old style format to keep old forms working.
    //
    getFormState(): FormFields;
}

const FormStateContext = createContext<IFormValuesHook>(null);

export interface IProps {
    //fio:
    // formDetails: IFormDetails;
    children: ReactNode | ReactNode[];
}

let nextId = 0;

export function FormStateContextProvider({ children }: IProps) {

    const { campaign } = useCampaign();
    const { widget } = useWidget();
    const [formValues, _setFormValues] = useState<IFormValues>({}); 
    const [submitting, setSubmitting] = useState<boolean>(false);

    const id = nextId++;
    // console.log(`[${id}]: FormStateContextProvider ${widget.id}`);

    function setFormValues(newFormState: IFormValues): void {
        _setFormValues(newFormState);
    }

    //
    // Updates particular values in the form state.
    //
    function updateFormValues(newFields: Partial<IFormValues>): void {
        setFormValues({
            ...formValues,
            ...newFields,
        });
    }

    //
    // Gets the value for a field.
    //
    function getFieldValue(fieldName: string): any | undefined {
        return formValues[fieldName];
    }

    //
    // Sets a single field value in the form.
    //
    function setFieldValue(fieldName: string, value: any): void {

        console.log(`[${id}]: setFieldValue: "${fieldName}" = "${value}"`);

        updateFormValues({ 
            ...formValues,
            [fieldName]: value,
         });
    }

    //
    // Clears all form fields.
    //
    function clearForm(): void {
        setFormValues({});
    }

    //
    // Returns an error message for an invalid form.
    // Otherwise returns undefined if there is no error.
    //
    function _validateForm(formFields: IWidget[]): string | undefined {

        const fieldValues = Object.entries(formValues);
        if (fieldValues.length === 0) {
            return `Form is empty. Please provide input.`;
        }

        for (const formWidget of formFields) {
            const fieldName = formWidget.name;
            const fieldType = formWidget.xtype;
            const fieldProperties = formWidget.properties;
            const fieldValue = formValues[fieldName];
            if (fieldProperties.required) {
                if (fieldType === 'upload' && fieldValue.length === 0) {
                    return `${fieldName} is missing a file. Please provide input.`;
                } 
                else if (fieldType === 'checkbox') {
                    if (fieldValue.length === 0) { //todo: Can this just be a single boolean in the new system?
                        return `${fieldName} is missing. Please provide input.`;
                    }
                } 
                else if (fieldType !== 'upload') {
                    const type = typeof fieldValue;
                    if (type === 'string') {
                        if (validator.isEmpty(fieldValue) || fieldValue === '' || fieldValue === undefined || fieldValue === null) {
                            return `${fieldName} is missing. Please provide input.`;
                        }
                    }
                }
            }

            if (fieldValue && fieldProperties.inputType === "email" && !checkValidEmail(fieldValue)) {
                return `${fieldName} is not a valid email address. Please enter a valid email`;
            }
    
            if (fieldValue && fieldProperties.inputType === "phone-number" && !checkValidPhoneNumber(fieldValue)) {
                return `${fieldName} is not a valid phone number. Please enter a valid phone number`;
            }
        }

        return undefined;
    }

    //
    // Validates the form.
    //
    function validateForm(): string | undefined {
        const formFields = flattenTree(widget.children)
            .filter(widget => getWidgetType(widget.xtype).isFormWidget);

        return _validateForm(formFields);
    }

    //
    // Submits the form.
    //
    async function submitForm(): Promise<void> {

        setSubmitting(true);

        try {
            const formFields = flattenTree(widget.children)
                .filter(widget => getWidgetType(widget.xtype).isFormWidget);

            const error = _validateForm(formFields);
            if (error) {
                Toast(error, 'error');
                return;
            }

            const filesToUpload = formFields.filter(field => field.type === 'xupload')
                .map(field => {
                    const fieldValue = formValues[field.name];
                    return {
                        fieldName: field.name,
                        files: fieldValue.files,
                        fileType: fieldValue.fileType,
                    };
                });

            let userId = campaign.uid;
            if (!userId) {
                userId = campaign.creatorUID;
            }

            const uploadData = await formFileUpload(
                filesToUpload,
                userId, //todo: Why is user id needed? Shouldn't form id be enough?
                campaign._id, //todo: Why is campaign id needed? Shouldn't form id be enough?
                widget.id,
            );

            if (filesToUpload.length > 0 && uploadData.length === 0) {
                Toast('Please select file.', 'error', widget.id)
                return;
            }

            const formValuesForUpload = _.cloneDeep(formValues);

            for (const data of uploadData) {
                formValuesForUpload[data.fieldName] = data.data;
            }

            const fields: IFields[] = formFields.filter(field => formValuesForUpload[field.name] !== undefined)
                .map(field => {
                    return {
                        id: field.id,
                        fieldName: field.name,
                        value: formValuesForUpload[field.name],
                        required: field.properties.required,
                        fieldType: field.type,
                        inputType: field.properties.inputType,
                        fieldLabel: field.name,
                    };
                });

            await FormService.submitForm({
                fields,
                uid: userId, //todo: Why is the userid needed?
                campaignId: campaign._id,
                formId: widget.id,
                formName: widget.name,
            });

            //
            //todo:
            // if (widget.settings.isIntegrate) {
            //     // send message to Slack
            //     await sendFormToSlack(
            //         widget.settings.integrates.slack,
            //         fields,
            //         widget.name,
            //         campaign.campaignName,
            //         campaign.metaImageUrl
            //     );
            // }

            clearForm();
            Toast('Form submitted!', 'success', widget.id)
        } 
        catch (error) {
            console.error(`Error submitting form:`);
            console.error(error);
            Toast(error.message, 'error', widget.id)
        }
        finally {
            setSubmitting(false);
        }
    }

    //
    // Gets the current form state in the old style format to keep old forms working.
    //
    function getFormState(): FormFields {
        const fields: IFields[] = (widget.fields || []).map((field: IFields, index) => {
            return {
                id: field.id,
                fieldName: field.fieldName,
                value: formValues[field.fieldName],
                required: field.required,
                fieldType: field.fieldType,
                inputType: field.inputType,
                fieldLabel: field.fieldName,
                defaultValue: field.defaultValue,
            };
        });

        return {
            campaignId: campaign._id,
            formId: widget.id,
            formName: widget.name,
            fields,
        };
    }

    const value: IFormValuesHook = {
        formValues: formValues,
        setFormState: setFormValues,
        updateFormState: updateFormValues,
        getFieldValue,
        setFieldValue,
        clearForm,
        submitting,
        validateForm,
        submitForm,
        getFormState,
    };
    
    return (
        <FormStateContext.Provider 
            value={value}
        >
            {children}
        </FormStateContext.Provider>
    );    
}

export function useForm(): IFormValuesHook | undefined {
    const context = useContext<IFormValuesHook | undefined>(FormStateContext);
    return context;
}
