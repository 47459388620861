import React, { FC } from 'react'
import styled, { css, keyframes } from 'styled-components'

interface Props {
    isDeployed: boolean
}
const pulseOrange = keyframes`
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 128, 56, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 128, 56, 0.5);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(255, 128, 56, 0);
      box-shadow: 0 0 0 10px rgba(255, 128, 56, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(255, 128, 56, 0);
      box-shadow: 0 0 0 0 rgba(255, 128, 56, 0);
  }
`

const pulseGreen = keyframes`
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(58, 226, 155, 0.5);
    box-shadow: 0 0 0 0 rgba(58, 226, 155, 0.5);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(58, 226, 155, 0);
      box-shadow: 0 0 0 10px rgba(58, 226, 155, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(58, 226, 155, 0);
      box-shadow: 0 0 0 0 rgba(58, 226, 155, 0);
  }
`

const EclipseStyle = styled.input.attrs((props) => ({}))<{ isDeployed: boolean }>`
    background-color: ${(props) => (props.isDeployed ? '#3ae29b' : '#FF8038')};
    border-radius: 50%;
    box-shadow: 0px 0px 5px ${(props) => (props.isDeployed ? '#3ae29b' : '#FF8038')};
    width: 10px;
    height: 10px;
    color: red;
    border: none;
    animation: ${(props) =>
        props.isDeployed ? css`1s ${pulseGreen} infinite` : css`1s ${pulseOrange} infinite`};
`

export const DeploySignal: FC<Props> = ({ isDeployed }) => {
    return <EclipseStyle isDeployed={isDeployed} />
}
