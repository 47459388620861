import { FilesToUpload } from '../../modules/widgets/forms/IForm'
import FormService from '../../../services/FormService'

export const formFileUpload = async (
    filesToUpload: FilesToUpload[],
    uid: string,
    campaignId: string,
    formId: string
): Promise<any[]> => {
    let urls: { fieldName: string; data: { url: string; fileName: string }[] }[] = []

    if (!filesToUpload.length) {
        return urls
    }

    for (let i = 0; i < filesToUpload.length; i++) {
        const item = filesToUpload[i]

        if (!item.files) {
            return urls
        }
        const formData = new FormData()
        const _files = item.files
        const fileType = item.fileType
        const fieldName = item.fieldName

        _files.forEach((item) => formData.append('file', item))

        try {
            const { data } = await FormService.uploadFormFiles(
                formData,
                uid,
                campaignId,
                formId,
                fileType
            )

            urls.push({
                fieldName,
                data: data.data,
            })

            return urls
        } catch (error) {
            console.log(error.message)
            throw new Error(error.message)
        }
    }
}
