import { FC } from 'react'
import styled from 'styled-components'
import { useWidget } from 'widgets-base';

interface Props {
    align: string
}

const Alignment: FC<Props> = ({ align }) => {

    const { widget, setWidget } = useWidget();

    const onChangeAlign = (alignment: string) => () => {
        const newWidget = {
            ...widget,
            appearanceSettings: {
                ...widget.appearanceSettings,
                align: alignment,
            },
        }

        setWidget(newWidget);
    }

    return (
        <Container>
            <label>Alignment</label>
            <div className="blocks">
                <div
                    className={align === 'left' ? 'active_option' : 'align_option'}
                    onClick={onChangeAlign('left')}
                >
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <g
                            strokeLinecap="round"
                            strokeWidth="1.5"
                            stroke={align === 'left' ? '#6478F9' : '#717F95'}
                            fill="none"
                            strokeLinejoin="round"
                        >
                            <path d="M18 17H6" />
                            <path d="M12 12H6" />
                            <path d="M18 7H6" />
                        </g>
                    </svg>
                </div>

                <div
                    className={align === 'center' ? 'active_option' : 'align_option'}
                    onClick={onChangeAlign('center')}
                >
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <g
                            strokeLinecap="round"
                            strokeWidth="1.5"
                            stroke={align === 'center' ? '#6478F9' : '#717F95'}
                            fill="none"
                            strokeLinejoin="round"
                        >
                            <path d="M18 17H6" />
                            <path d="M15 12H9" />
                            <path d="M18 7H6" />
                        </g>
                    </svg>
                </div>

                <div
                    className={align === 'right' ? 'active_option' : 'align_option'}
                    onClick={onChangeAlign('right')}
                >
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <g
                            strokeLinecap="round"
                            strokeWidth="1.5"
                            stroke={align === 'right' ? '#6478F9' : '#717F95'}
                            fill="none"
                            strokeLinejoin="round"
                        >
                            <path d="M6 7h12" />
                            <path d="M12 12h6" />
                            <path d="M6 17h12" />
                        </g>
                    </svg>
                </div>

                <div
                    className={align === 'justify' ? 'active_option' : 'align_option'}
                    onClick={onChangeAlign('justify')}
                >
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <g
                            strokeLinecap="round"
                            strokeWidth="1.5"
                            stroke={align === 'justify' ? '#6478F9' : '#717F95'}
                            fill="none"
                            strokeLinejoin="round"
                        >
                            <path d="M18 17H6" />
                            <path d="M6 12h12" />
                            <path d="M10.5 7H6" />
                        </g>
                    </svg>
                </div>
            </div>
        </Container>
    )
}

export default Alignment

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #717f95;

    & > label {
        font-size: 12px;
        color: #3c4b61;
        margin-bottom: 7px;
        font-weight: bold;
    }

    .blocks {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .align_option {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #717f95;
        border-radius: 8px;
        cursor: pointer;
        svg {
            width: 23px;
        }
    }

    .active_option {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #6478f9;
        border-radius: 8px;
        cursor: pointer;
    }
`
