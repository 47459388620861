import { nanoid } from 'nanoid'
import { IInput } from '../../../modules/widgets/forms/IInput'

export class InputModel implements IInput {
    constructor(
        name: string,
        label: string,
        placeholder: string,
        borderTopLeftRadius?: number,
        borderTopRightRadius?: number,
        borderBottomLeftRadius?: number,
        borderBottomRightRadius?: number,
        borderColor?: string
    ) {
        this.settings.name = name
        this.settings.label = label
        this.settings.placeholder = placeholder
        this.appearanceSettings.borderTopLeftRadius = borderTopLeftRadius
        this.appearanceSettings.borderTopRightRadius = borderTopRightRadius
        this.appearanceSettings.borderBottomLeftRadius = borderBottomLeftRadius
        this.appearanceSettings.borderBottomRightRadius = borderBottomRightRadius
        this.colourSettings['border colour'] = [borderColor, '#FFA7E1', '#0426FF']
    }
    id = nanoid()
    name = 'input box'
    type = 'input box'
    icon = '/icons/widgets/input.svg'
    category = 'form'
    colourSettings = {
        'label colour': ['rgba(0,0,0,1)', '#FFA7E1', '#0426FF'],
        'text colour': ['rgba(0,0,0,1)', '#FFA7E1', '#0426FF'],
        'background colour': ['#f3f3f3', '#FFA7E1', '#0426FF'],
        'border colour': ['rgba(0,0,0,1)', '#FFA7E1', '#0426FF'],
    }
    appearanceSettings = {
        border: true,
        borderRadius: true,
        margin: true,
        padding: true,
        width: 90,
        height: 0,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
        borderBottomLeftRadius: 2,
        borderBottomRightRadius: 2,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        alignment: 'center',
        font: {
            family: 'Poppins',
            size: 14,
            weight: 400,
        },
    }
    settings = {
        placeholder: 'placeholder text',
        required: false,
        type: 'text',
        name: 'input name',
        label: 'Input Label',
        defaultValue: '',
        minimumValue: 0,
        maximumValue: 10,
        stepValue: 1,
    }
}
