import { ChangeEvent, FC } from 'react'
import styled from 'styled-components'
import { useWidget } from 'widgets-base'

interface Props {
    columns: number
    name?: string
}

const Columns: FC<Props> = ({ columns = 1, name = 'buttonSpacing' }) => {

    const { widget, setWidget } = useWidget();

    const onChangeColumns = (col: number) => () => {
        const newWidget = {
            ...widget,
            appearanceSettings: {
                ...widget.appearanceSettings,
                columns: col,
            },
        }

        setWidget(newWidget);
    }

    const onChangeSpacing = (event: ChangeEvent<HTMLInputElement>) => {
        const newWidget = {
            ...widget,
            appearanceSettings: {
                ...widget.appearanceSettings,
                [event.target.name]: +event.target.value,
            },
        }

        setWidget(newWidget);
    }

    return (
        <Container>
            <label>Columns</label>
            <div className="columns">
                <div
                    className={columns === 1 ? 'active_column' : 'column'}
                    onClick={onChangeColumns(1)}
                >
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect width="16" height="16" fill={columns === 1 ? '#6478F9' : '#717F95'} />
                    </svg>
                </div>
                <div
                    className={columns === 2 ? 'active_column' : 'column'}
                    onClick={onChangeColumns(2)}
                >
                    <svg
                        width="21"
                        height="16"
                        viewBox="0 0 21 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect width="10" height="16" fill={columns === 2 ? '#6478F9' : '#727F95'} />
                        <rect
                            x="11"
                            width="10"
                            height="16"
                            fill={columns === 2 ? '#6478F9' : '#717F95'}
                        />
                    </svg>
                </div>
                <div
                    className={columns === 3 ? 'active_column' : 'column'}
                    onClick={onChangeColumns(3)}
                >
                    <svg
                        width="21"
                        height="16"
                        viewBox="0 0 21 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect
                            width="6.22222"
                            height="16"
                            fill={columns === 3 ? '#6478F9' : '#717F95'}
                        />
                        <rect
                            x="7"
                            width="6.22222"
                            height="16"
                            fill={columns === 3 ? '#6478F9' : '#717F95'}
                        />
                        <rect
                            x="14"
                            width="6.22222"
                            height="16"
                            fill={columns === 3 ? '#6478F9' : '#717F95'}
                        />
                    </svg>
                </div>
            </div>

            <GapBlock isActive={columns !== 1}>
                <label>Gap between columns</label>
                <div className="field">
                    <input
                        name={name}
                        type="number"
                        disabled={columns === 1}
                        value={widget?.appearanceSettings?.buttonSpacing}
                        onChange={onChangeSpacing}
                    />
                    <span>px</span>
                </div>
            </GapBlock>
        </Container>
    )
}

export default Columns

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #717f95;
    gap: 10px;

    & > label {
        font-size: 12px;
        color: #3c4b61;
        font-weight: bold;
    }

    .columns {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .column {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #717f95;
        border-radius: 8px;
        cursor: pointer;
    }

    .active_column {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #6478f9;
        border-radius: 8px;
        cursor: pointer;
    }
`

const GapBlock = styled.div<{
    isActive: boolean
}>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #717f95;
    pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
    opacity: ${(props) => (props.isActive ? 1 : 0.5)};

    & > label {
        font-size: 12px;
        color: #3c4b61;
        margin-bottom: 7px;
        font-weight: bold;
    }

    .field {
        display: flex;
        align-items: center;
        padding: 5px;
        height: 32px;
        width: 78px;
        background: #ffffff;
        border: 1px solid #dfe2f2;
        border-radius: 8px;
        margin-right: 6px;
        input {
            border: none;
            width: 40px;
            font-size: 12px;
            color: #3c4b61;
            font-weight: bold;
        }
        span {
            font-size: 12px;
            color: #3c4b61;
            border-left: 1px solid #dfe2f2;
            padding: 0 5px;
            font-weight: bold;
        }
    }
`
