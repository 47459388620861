import { ChangeEvent, useCallback, useState } from 'react'
import styled from 'styled-components'
import FileSaver from 'file-saver'
import { QRCode } from 'react-qrcode-logo'
import FormatColorFillOutlinedIcon from '@mui/icons-material/FormatColorFillOutlined'
import { Divider } from '@mui/material'
import { Button } from '../../common/Button'
import { ToggleButton } from '../../common/ToggleButton'
import { ColorPicker, ColorPickerCallBack } from '../../Design/ColorPicker'
import { useCampaign } from '../../../../builder/context/campaign'

const QRDesignTab = () => {
    const [openColorPicker, setOpenColorPicker] = useState<boolean>(false)

    const { campaign, updateCampaign } = useCampaign();

    const toggleOpenColorPicker = useCallback(() => {
        setOpenColorPicker((prev) => !prev)
    }, [])

    const resizeQRImage = (): string => {
        const canvas = document.getElementById('react-qrcode-logo') as HTMLCanvasElement;
        const dataURL = canvas.toDataURL('image/png', 1);
        return dataURL
    }

    const onSaveQRCode = () => {
        FileSaver.saveAs(resizeQRImage(), `${campaign.campaignName}.png`)
    }

    const onChangeQRColor = (color: ColorPickerCallBack) => {
        const value = color.rgb
        const { r, g, b, a } = value
        const newColor = `rgb(${r},${g},${b},${a})`
        updateCampaign({
            qrcode: { 
                ...campaign.qrcode, 
                fgColor: newColor,
            },
        })
    };

    const handleQRStyleChange = (event: ChangeEvent<HTMLInputElement>) => {
        updateCampaign({
            qrcode: {
                ...campaign.qrcode,
                qrStyle: event.target.checked ? 'squares' : 'dots',
            },
        });
    }

    const width = (campaign.qrcode.logoWidth * 100) / campaign.qrcode.size
    const height = (campaign.qrcode.logoHeight * 100) / campaign.qrcode.size

    return (
        <Wrapper>
            <p>
                Design and configure the look and feel of your QR code.
                <br />
                Tip: Avoid dark background colours as it may affect camera detection.
            </p>
            <Divider />

            <QRContainer>
                <QRBorder>
                    <QRCode
                        value={campaign.deployUrl}
                        ecLevel={campaign.qrcode.ecLevel || 'M'}
                        enableCORS={campaign.qrcode.enableCORS || true}
                        size={200}
                        quietZone={campaign.qrcode.quietZone || 10}
                        bgColor={campaign.qrcode.bgColor || '#fff'}
                        fgColor={campaign.qrcode.fgColor || '#000'}
                        logoImage={campaign.qrcode.logoImage || ''}
                        logoWidth={(width * 200) / 100}
                        logoHeight={(height * 200) / 100}
                        logoOpacity={campaign.qrcode.logoOpacity || 1}
                        qrStyle={campaign.qrcode.qrStyle || 'squares'}
                    />
                </QRBorder>
                <Button
                    width="100%"
                    size="small"
                    type="button"
                    variant="primary"
                    onClick={onSaveQRCode}
                >
                    Save QR Code
                </Button>
                <DeployedLinkContainer>
                    <h2>Deployed Link</h2>
                    <a href={campaign.deployUrl}>
                        <p>{campaign.deployUrl}</p>
                    </a>
                    <Button width="100%" size="small" variant="primary" type="button">
                        <a
                            style={{ color: '#fff', textDecoration: 'none' }}
                            href={campaign.deployUrl}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Go to Link
                        </a>
                    </Button>
                </DeployedLinkContainer>
                <QRSettings>
                    <FlexContainer onClick={toggleOpenColorPicker}>
                        <span>Fill Colour</span>
                        <FormatColorFillOutlinedIcon />
                        {openColorPicker && (
                            <ColorPicker
                                color={campaign.qrcode.fgColor || '#000'}
                                handleColorChange={onChangeQRColor}
                                toggleOpen={toggleOpenColorPicker}
                            />
                        )}
                    </FlexContainer>
                    <FlexContainer>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span>QR Style</span>
                            <p>{campaign.qrcode.qrStyle}</p>
                        </div>
                        <ToggleButton
                            isToggled={campaign.qrcode.qrStyle === 'squares' ? true : false}
                            onChange={handleQRStyleChange}
                        />
                    </FlexContainer>
                </QRSettings>
            </QRContainer>
        </Wrapper>
    )
}

export default QRDesignTab

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;

    & > p {
        font-size: 13px;
        color: #adadad;
        text-align: center;
    }

    & > hr {
        margin-bottom: 18px;
    }
`

const FlexContainer = styled.div`
    display: flex;
    align-items: center;

    & > span,
    & > div {
        margin-right: 10px;
    }

    span {
        font-size: 14px;
        font-weight: bold;
    }
    svg {
        cursor: pointer;
    }
`

const QRBorder = styled.div`
    display: flex;
    width: 100%;
    background: #fafaff;
    justify-content: center;
    border: 1px solid #dfe2f2;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 20px;
`

const QRContainer = styled.div`
    display: flex;
    width: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 20px;

    h3 {
        font-size: 16px;
        font-weight: bold;
    }

    & > p {
        font-size: 12px;
        color: #adadad;
        text-align: center;
    }
`

const QRSettings = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 18px 0;
`

const DeployedLinkContainer = styled.div`
    width: 100%;
    overflow-wrap: break-word;
    margin-top: 30px;

    h2 {
        font-size: 15px;
        text-align: left;
        font-weight: bold;
    }

    p {
        font-size: 11px;
        margin-top: 10px;
        text-align: left;
    }

    a {
        text-decoration: underline;
        color: #0044ff;
    }
`
