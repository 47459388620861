import { ChangeEvent, FC, useState } from 'react'
import styled from 'styled-components'
import { checkUrl } from '../../helpers/validation'
import { InputBox } from '../common/InputBox'
import { ContentBlock } from './ContentBlock'
import { IBranding } from '../../modules/IBranding'
import { ISocial } from 'widgets-base'

interface Props {
    social: ISocial;
    branding: IBranding;
    setBranding: (branding: IBranding) => void;
}

const socialIcons = {
    facebook: '/icons/social/facebook-icon.png',
    twitter: '/icons/social/twitter.png',
    instagram: '/icons/social/instagram.png',
    linkedin: '/icons/social/linkedin.png',
    whatsapp: '/icons/social/whatsapp.png',
    tiktok: '/icons/social/tik-tok.png',
    youtube: '/icons/social/youtube.png',
    pinterest: '/icons/social/painters.png',
    spotify: '/icons/social/spotify.png',
}

const SocialSetting: FC<Props> = ({ social, branding, setBranding }) => {
    const [error, setError] = useState<{ [key: string]: string | null }>({})

    function handleChangeSocial(event: ChangeEvent<HTMLInputElement>): void {
        const name = event.target.name;
        const value = event.target.value;
        setBranding({
            ...branding,
            social: {
                ...branding.social,
                [name]: value,
            },
        });
    }

    function checkSocialLink(name: string, type: string, value: string): void {
        const url = checkUrl(name, type, value);
        if (url === 'Invalid URL') {
            setError((prevState) => {
                return { ...prevState, [name]: url }
            });
        } 
        else {
            setError((prevState) => {
                return { ...prevState, [name]: null }
            });
        }
    }

    function onCheckSocialLink(name: string): void {
        if (!name) {
            return;
        }

        checkSocialLink(name, 'url', social[name]);
    }

    return (
        <ContentBlock title="Social link setting">
            <LinksList>
                {Object.keys(social).map((key) => (
                    <div key={key} className="link_item">
                        <img width={30} height={30} src={socialIcons?.[key]} alt={key || 'Social'} />
                        <InputBox
                            type="text"
                            value={social?.[key] || ''}
                            name={key || ''}
                            onChange={handleChangeSocial}
                            onBlur={() => onCheckSocialLink(key)}
                            error={error?.[key]}
                            width="100%"
                            placeholder="https://"
                        />
                    </div>
                ))}
            </LinksList>
        </ContentBlock>
    );
}

export default SocialSetting

const LinksList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    .link_item {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
    .input_box {
        margin-bottom: 0;
    }
`
