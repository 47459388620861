import { MenuItem, Select, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useProperty } from "../../context/property";
import { IPropertyType } from "../property-type";
import { useCampaign } from "../../context/campaign";
import { ILink, LinkTarget } from "widgets-base";

//
// An editor for link properties.
//

function PropertyLinkEditor() {

    const { campaign } = useCampaign();
    const { property, propertyValue, setPropertyValue } = useProperty<ILink>();

    const target = propertyValue?.target || property.defaultValue.target || "url";

    return (
        <div className="flex flex-col w-full">
            
            <Select
                className="h-7"
                style={{
                    width: "200px",
                }}
                size="small"
                value={target}
                onChange={event => {
                    setPropertyValue({
                        ...propertyValue,
                        target: event.target.value as LinkTarget,
                    });
                }}
                MenuProps={{
                    style: {
                        zIndex: 10000,
                    },
                }}
            >
                <MenuItem
                    value="url"
                >
                    Redirect
                </MenuItem>
                <MenuItem
                    value="page"
                >
                    Go to page
                </MenuItem>
                <MenuItem
                    value="submit"
                >
                    Submit form
                </MenuItem>
            </Select>

            {target === "url" && (
                <input
                    className="mt-1 w-full"
                    style={{
                        fontFamily: "Matter, \"Helvetica Neue\", Helvetica, Arial, sans-serif",
                        borderRadius: "4px",
                        border: "1px solid rgb(223, 226, 242)",
                        color: "rgb(113, 127, 149)",
                        padding: "5px 6px",
                        fontSize: "14px",
                    }}
                    value={propertyValue?.url}
                    onChange={event => {
                        setPropertyValue({
                            ...propertyValue,
                            url: event.target.value,
                        });
                    }}
                />
            )}

            {target === "page" && (
                <Select 
                    className="mt-1 h-7"
                    style={{
                        width: "200px",
                    }}
                    size="small"
                    value={propertyValue?.page || property.defaultValue.page}
                    onChange={event => {
                        setPropertyValue({
                            ...propertyValue,
                            page: event.target.value as number,
                        });
                    }}
                    MenuProps={{
                        style: {
                            zIndex: 10000,
                        },
                    }}
                    >
                    {campaign.views.map((page, index) => {
                        return (
                            <MenuItem 
                                key={index}
                                value={index}
                                >
                                {page.name}
                            </MenuItem>
                        );
                    })}
                </Select>
            )}
        </div>
    );
}


const text: IPropertyType = {
    editor: () => <PropertyLinkEditor />,
};

export default text;