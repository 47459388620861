import styled from 'styled-components'

export const FormsPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 5%;
    & > h1 {
        font-weight: 600;
        font-size: 32px;
        width: 100%;
        box-sizing: content-box;
        line-height: 38px;
        color: #3ae29b;
        margin-bottom: 7px;
        text-align: left;
    }

    & > p {
        width: 100%;
        font-size: 25px;
        font-weight: bold;
        color: #6478f9;
        span {
            color: #3ae29b;
        }
        text-align: left;
    }

    & > span {
        font-size: 13px;
        color: #cacaca;
        width: 100%;
        text-align: left;
        margin-bottom: 30px;
    }

    .MuiButtonBase-root {
        letter-spacing: 0;
    }

    .search__input {
        margin-bottom: 15px;
        margin-left: auto;
    }

    @media (max-width: 800px) {
        .search__input {
            width: 100%;
            input {
                width: 100%;
            }
        }
    }
`

export const CampaignsList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
`

export const CampaignContainer = styled.div`
    display: flex;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #cacaca;
    padding: 15px;
    gap: 15px;
    justify-content: space-around;
    & > img {
        object-fit: contain;
        width: 300px;
        height: 250px;
        border-radius: 10px;
    }

    .campaign__forms {
        display: flex;
        flex-direction: column;
        gap: 15px;

        & > h3 {
            font-size: 18px;
            font-weight: bold;
        }

        .form__item {
            width: 100%;
            width: 250px;
            padding: 10px;
            border-radius: 10px;
            border: 1px solid #cacaca;
            display: flex;
            flex-direction: column;
            gap: 10px;
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            p {
                font-size: 16px;
                font-weight: bold;
            }
            span {
                font-size: 14px;
                color: #6478f9;
                font-weight: bolder;
            }

            &:hover {
                transform: translateY(-5px);
                box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.1);
            }
        }
    }

    .campaign__description {
        display: flex;
        flex-direction: column;
        gap: 10px;
        h1 {
            font-size: 25px;
            font-weight: bold;
        }
        & > span {
            font-size: 14px;
            color: #b1b1b1;
        }
        .campaign__analitics {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin: 20px 0;

            .campaign__scans {
                display: flex;
                align-items: center;
                gap: 15px;
                font-size: 16px;
                color: #ff8038;
            }

            .campaign__form_count {
                display: flex;
                align-items: center;
                gap: 15px;
                font-size: 16px;
                color: #6478f9;
            }
        }
    }

    @media (max-width: 800px) {
        flex-direction: column;
        align-items: center;
    }
`

export const Loader = styled.div`
    position: relative;
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 100px 0;
    & > span {
        font-size: 14px;
        color: #a09e9e;
    }
    svg {
        height: 100px;
        width: 150px;
        margin-bottom: 10px;
        path {
            stroke: #cbd5e1;
        }
    }
`

export const NoDataComponent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 15px;

    span {
        font-size: 16px;
        font-weight: bold;
        color: #828282;
    }
`
