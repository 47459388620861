import { IWidget } from "widgets-base"


export enum LinkAction {
    REDIRECT = 'redirect',
    GO_TO_PAGE = 'go to page',
    GO_TO_EMAIL = 'set email',
}

export interface ILinkSettings {
    id: string
    linkTitle: string
    linkUrl: string
    type: LinkAction
    layerIndex: number
    viewIndex: number
    widgetSettings: { [key: string]: any }
}

export interface ILink extends IWidget {
    id: string
    type: string
    name: string
    category?: string
    settings?: {
        content: string
        link: ILinkSettings
    }
    appearanceSettings?: {
        align: string
        width: number
        height: number
        paddingTop: number
        paddingBottom: number
        paddingLeft: number
        paddingRight: number
        marginTop: number
        marginBottom: number
        marginLeft: number
        marginRight: number
        font: {
            family: string
            url?: string
            spacing?: number
            weight: string
            size: number
            lineHeight?: number
        }
    }
    colourSettings?: {
        textColour: string[]
    }
}
