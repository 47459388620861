import { ChangeEvent, FC, useState } from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import { capitalizeFirstLetter } from '../../../helpers/capitalizeFirstLetter'
import { checkInputValid, checkValidEmail } from '../../../helpers/validation'
import { ILinkSettings, LinkAction } from '../../../modules/widgets/general/ILink'
import { InputBox } from '../../common/InputBox'
import { useWidget, IView } from 'widgets-base'
import { useCampaign } from '../../../../builder/context/campaign'

const linkActionType = [
    { label: 'Redirect', value: 'redirect' },
    { label: 'Toggle popup', value: 'toggle popup' },
    { label: 'Go to page', value: 'go to page' },
    { label: 'Set email', value: 'set email' },
]

interface Props {
    action: ILinkSettings
}

const LinkActionSetting: FC<Props> = ({ action }) => {

    const { campaign } = useCampaign();
    const { widget, setWidget } = useWidget();
    const [error, setError] = useState<string | null>(null)

    const pagesOptions = campaign.views.map((page: IView, index: number) => ({
        label: page.name,
        value: `${index}`,
    }))

    const onHandleChangeActionType = (value: { label: string; value: string }) => {
        const newWidget = {
            ...widget,
            settings: {
                ...widget.settings,
                link: {
                    ...widget.settings.link,
                    type: value.value,
                },
            },
        }

        setWidget(newWidget);
    }

    const onHandleChangeRedirectPage = (value: { label: string; value: string }) => {
        const newWidget = {
            ...widget,
            settings: {
                ...widget.settings,
                link: {
                    ...widget.settings.link,
                    viewIndex: +value.value,
                },
            },
        }

        setWidget(newWidget);
    }

    const onHandleChangeRedirectPopup = (value: { label: string; value: string }) => {
        const newWidget = {
            ...widget,
            settings: {
                ...widget.settings,
                link: {
                    ...widget.settings.link,
                    layerIndex: +value.value,
                },
            },
        }

        setWidget(newWidget);
    }

    const checkUrl = (e: ChangeEvent<HTMLInputElement>): void => {
        setError(null)
        if (e.target.value === '') {
            setError('Please enter valid web link')
            return
        }
        if (e.target.value.slice(0, 3) === 'www') {
            const url = 'https://' + e.target.value
            const isValidUrl = checkInputValid(url, 'url')
            if (!isValidUrl) {
                setError('Please enter valid web link')
                return
            }
        }
        if (e.target.value.substring(0, 8) !== 'https://') {
            setError('Please add https:// in front of your link')
        } else {
            setError(null)
        }
    }

    const checkEmail = (e: ChangeEvent<HTMLInputElement>) => {
        const email = e.target.value
        const isValidEmail = checkValidEmail(email)
        if (!isValidEmail) {
            setError('Please enter valid email')
        } else {
            setError(null)
        }
    }

    const onChangeRedirectLink = (e: ChangeEvent<HTMLInputElement>) => {
        const newWidget = {
            ...widget,
            settings: {
                ...widget.settings,
                link: {
                    ...widget.settings.link,
                    linkUrl: e.target.value,
                },
            },
        }

        setWidget(newWidget);
    }

    const defaultAction = {
        label: capitalizeFirstLetter(action?.type as string),
        value: action?.type as string,
    }
    const defaultPage = { label: `Page ${action?.viewIndex + 1}`, value: `${action?.viewIndex}` }
    const defaultPopup = {
        label: `Popup ${action?.layerIndex + 1}`,
        value: `${action?.layerIndex}`,
    }

    return (
        <Container>
            <label>Redirect on click action</label>
            <Select
                options={linkActionType}
                defaultValue={defaultAction}
                onChange={onHandleChangeActionType}
            />
            {action?.type === LinkAction.REDIRECT && (
                <InputBox
                    value={action.linkUrl}
                    name="linkUrl"
                    type="text"
                    onChange={onChangeRedirectLink}
                    onBlur={checkUrl}
                    error={error}
                />
            )}
            {action?.type === LinkAction.GO_TO_PAGE && (
                <Select
                    options={pagesOptions}
                    defaultValue={defaultPage}
                    onChange={onHandleChangeRedirectPage}
                />
            )}
            {action?.type === LinkAction.GO_TO_EMAIL && (
                <InputBox
                    value={action.linkUrl}
                    name="linkUrl"
                    type="text"
                    onChange={onChangeRedirectLink}
                    onBlur={checkEmail}
                    error={error}
                />
            )}
        </Container>
    )
}

export default LinkActionSetting

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #717f95;
    gap: 10px;

    & > label {
        font-size: 12px;
        color: #3c4b61;
        font-weight: bold;
    }

    & > div {
        width: 100%;
        & > div {
            border-radius: 10px;
        }
    }
`
