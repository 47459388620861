import React, { FC, memo, useRef } from 'react'
import styled from 'styled-components'
import Vimeo from '@u-wave/react-vimeo'
import { useWidget } from 'widgets-base'

interface Props {
    editMode?: boolean
}

const VideoWidget: FC<Props> = ({ editMode }) => {
    const iframeRef = useRef<HTMLIFrameElement>(null)
    const videoRef = useRef<HTMLVideoElement>(null)

    const { widget } = useWidget();
    const width = widget?.appearanceSettings?.width + '%'
    const paddingTop = widget?.appearanceSettings?.paddingTop / 10 + 'em'
    const paddingBottom = widget?.appearanceSettings?.paddingBottom / 10 + 'em'
    const paddingLeft = widget?.appearanceSettings?.paddingLeft / 10 + 'em'
    const paddingRight = widget?.appearanceSettings?.paddingRight / 10 + 'em'
    const borderTopLeftRadius = widget?.appearanceSettings?.borderTopLeftRadius / 10 + 'em'
    const borderBottomLeftRadius = widget?.appearanceSettings?.borderBottomLeftRadius / 10 + 'em'
    const borderTopRightRadius = widget?.appearanceSettings?.borderTopRightRadius / 10 + 'em'
    const borderBottomRightRadius = widget?.appearanceSettings?.borderBottomRightRadius / 10 + 'em'
    const src = widget?.settings.src
    const platform = widget?.settings.platform
    const thumbnail = widget?.settings.thumbnail

    const styles = {
        paddingTop,
        paddingBottom,
        paddingLeft,
        paddingRight,
        borderTopLeftRadius,
        borderBottomLeftRadius,
        borderTopRightRadius,
        borderBottomRightRadius,
    }

    return (
        <VideoSection id={widget.id}>
            {platform === 'youtube' && (
                <Container
                    width={width}
                    paddingTop={paddingTop}
                    paddingBottom={paddingBottom}
                    paddingLeft={paddingLeft}
                    paddingRight={paddingRight}
                    borderTopLeftRadius={borderTopLeftRadius}
                    borderBottomLeftRadius={borderBottomLeftRadius}
                    borderTopRightRadius={borderTopRightRadius}
                    borderBottomRightRadius={borderBottomRightRadius}
                >
                    <iframe
                        ref={iframeRef}
                        style={{ width: '100%' }}
                        height="100%"
                        src={src}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="YouTube video player"
                    />
                    {editMode && thumbnail !== '' && (
                        <img src={thumbnail} style={{ width, ...styles }} alt="thumbnail" />
                    )}
                </Container>
            )}

            {platform === 'vimeo' && (
                <Container
                    width={width}
                    paddingTop={paddingTop}
                    paddingBottom={paddingBottom}
                    paddingLeft={paddingLeft}
                    paddingRight={paddingRight}
                    borderTopLeftRadius={borderTopLeftRadius}
                    borderBottomLeftRadius={borderBottomLeftRadius}
                    borderTopRightRadius={borderTopRightRadius}
                    borderBottomRightRadius={borderBottomRightRadius}
                >
                    <Vimeo
                        video={src}
                        style={{ width: '100%' }}
                        height="100%"
                        responsive={true}
                        autoplay
                        loop
                        muted
                        controls={true}
                    />
                    {editMode && thumbnail !== '' && (
                        <img src={thumbnail} style={{ width, ...styles }} alt="thumbnail" />
                    )}
                </Container>
            )}

            {platform === '' && (
                <Container
                    width={width}
                    paddingTop={paddingTop}
                    paddingBottom={paddingBottom}
                    paddingLeft={paddingLeft}
                    paddingRight={paddingRight}
                    borderTopLeftRadius={borderTopLeftRadius}
                    borderBottomLeftRadius={borderBottomLeftRadius}
                    borderTopRightRadius={borderTopRightRadius}
                    borderBottomRightRadius={borderBottomRightRadius}
                >
                    <video
                        ref={videoRef}
                        src={src}
                        controls
                        autoPlay={editMode}
                        muted
                        loop
                        width="100%"
                        height=""
                        playsInline
                        style={{
                            width: '100%',
                            borderTopLeftRadius,
                            borderBottomLeftRadius,
                            borderTopRightRadius,
                            borderBottomRightRadius,
                        }}
                    ></video>
                    {editMode && thumbnail !== '' && (
                        <img src={thumbnail} style={{ width, ...styles }} alt="thumbnail" />
                    )}
                </Container>
            )}
        </VideoSection>
    )
}

export default memo(VideoWidget)

const VideoSection = styled.section`
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Container = styled.div.attrs((props) => ({}))<{
    width: string
    paddingTop: string
    paddingBottom: string
    paddingLeft: string
    paddingRight: string
    borderTopLeftRadius: string
    borderBottomLeftRadius: string
    borderTopRightRadius: string
    borderBottomRightRadius: string
}>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${(props) => props.width};
    height: 400px;
    padding-top: ${(props) => props.paddingTop};
    padding-bottom: ${(props) => props.paddingBottom};
    padding-left: ${(props) => props.paddingLeft};
    padding-right: ${(props) => props.paddingRight};
    border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
    border-top-left-radius: ${(props) => props.borderTopLeftRadius};
    border-top-right-radius: ${(props) => props.borderTopRightRadius};
    border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};

    iframe {
        border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
        border-top-left-radius: ${(props) => props.borderTopLeftRadius};
        border-top-right-radius: ${(props) => props.borderTopRightRadius};
        border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
    }
`
