import { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { ThemeProvider } from 'styled-components'
import { ToastContainer } from 'react-toastify'
import { HelmetProvider } from 'react-helmet-async'
import { theme, GlobalStyle } from './styles/theme.config'

import App from './App'
import { AuthContextProvider } from 'live/build/hooks/auth'
import { ConfirmContextProvider } from 'live/build/hooks/confirm';
import { CustomConfirm } from 'live/build/v1/components/common/ConfirmModal'
import { LoaderRocket } from 'live/build/v1/components/common/LoaderRocket'

//connect styles
import './styles/index.css'
import './styles/v2/icon-style.css'
import './styles/v2/index.css'


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <BrowserRouter>
        <AuthContextProvider>
            <ConfirmContextProvider>
                <RecoilRoot>
                    <Suspense fallback={<LoaderRocket />}>
                        <ThemeProvider theme={theme}>
                            <HelmetProvider>
                                <GlobalStyle />
                                <App />
                                <CustomConfirm />
                                <ToastContainer
                                    className="toaster-container"
                                    position="bottom-center"
                                    autoClose={111111100}
                                    hideProgressBar={true}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                />
                            </HelmetProvider>
                        </ThemeProvider>
                    </Suspense>
                </RecoilRoot>
            </ConfirmContextProvider>
        </AuthContextProvider>
    </BrowserRouter>
)
