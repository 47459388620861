import { IPropertyType } from "./property-type";
import text from "./editors/text";
import textarea from "./editors/textarea";
import richText from "./editors/rich-text";
import option from "./editors/option";
import color from "./editors/color";
import font from "./editors/font";
import number from "./editors/number";
import boolean from "./editors/boolean";
import image from "./editors/image";
import asset from "./editors/asset";
import link from "./editors/link";
import linkedNumbers from "./editors/linked-numbers";
import borderRadius from "./editors/border-radius";
import size from "./editors/size";
import array from "./editors/array";

//
// Look up table for properties by type.
//
export const propertyTypes: { [index: string]: IPropertyType } = {
    text,
    "rich-text": richText,
    option,
    color,
    font,
    number,
    boolean, 
    image,
    link,
    linkedNumbers,
    borderRadius,
    size,
    array,
    asset,
    textarea,
};