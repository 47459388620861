import { nanoid } from "nanoid";
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import TableRowsIcon from '@mui/icons-material/TableRows';
import { StaticWidgets } from "../v1/components/new/static-widgets";
import { MoveableWidgets } from "../v1/components/new/moveable-widgets";
import { makeStyledElement, useWidget, useRenderContext, usePageTheme, useDragContext, determineDirection, IWidgetType, makePadding, stdGroupProperties, makeColorProperty, stdElementAlignment, stdPadding, stdMargin, stdWidth, stdHeight } from "widgets-base";
import { useCampaign } from "../builder/context/campaign";

const Link = makeStyledElement("a");

function Static() {

    const { widget, properties, expandedProperties } = useWidget();
    const { renderContext } = useRenderContext();
    const { setCurrentPageIndex } = useCampaign();
    const { pageTheme } = usePageTheme();
    
    let widgetClassName = `link flex flex-grow `;

    if (expandedProperties.link?.target === "url") {
        return (
            <Link
                widgetId={widget.id}
                className={widgetClassName}
                href={expandedProperties.link?.url as string}
                elementName="link"
                variantName={properties.variant || "default"}
                pageTheme={pageTheme}
                properties={expandedProperties}
                renderContext={renderContext}
                >
                <StaticWidgets
                    widgets={widget.children}
                />
            </Link>
        );
    }
    else {
        return (
            <Link
                widgetId={widget.id}
                className={widgetClassName}
                href="#"
                onClick={event => {
                    event.preventDefault();
                    setCurrentPageIndex(expandedProperties.link?.page);
                }}
                elementName="link"
                variantName={properties.variant || "default"}
                pageTheme={pageTheme}
                properties={expandedProperties}
                renderContext={renderContext}
                >
                <StaticWidgets
                    widgets={widget.children}
                    />
            </Link>
        );
    }
}

function Editor() {

    const { widget, widgetPath, properties, expandedProperties } = useWidget();
    const { renderContext } = useRenderContext();
    const { pageTheme } = usePageTheme();
    const { dragProps, setRef, onSelect } = useDragContext();

	let direction = determineDirection(properties, renderContext);
    let widgetClassName = `link flex flex-grow `;


    const groupStyle: any = {};

    if (widget.children?.length === 0) {
        if (direction === "column") {
            if (!groupStyle.minWidth) {
                groupStyle.minWidth = "175px";
            }
            if (!groupStyle.minHeight) {
                groupStyle.minHeight = "250px";
            }
        }
        else {
            if (!groupStyle.minWidth) {
                groupStyle.minWidth = "250px";
            }
            if (!groupStyle.minHeight) {
                groupStyle.minHeight = "170px";
            }
        }
    }

    return (
        <Link
            {...dragProps}
            ref={setRef}
            onClick={event => {
                event.stopPropagation();
                event.preventDefault();
                onSelect();
            }}
            widgetId={widget.id}
            className={widgetClassName}
            elementName="link"
            variantName={properties.variant || "default"}
            pageTheme={pageTheme}
            properties={expandedProperties}
            renderContext={renderContext}
            style={groupStyle}
            >
            <MoveableWidgets
                key={`${widget.id}-${widget.lastUpdate}`}
                groupId={widget.id}
                widgets={widget.children}
                direction={direction}
                parentWidgetPath={widgetPath}
                />
        </Link>
    );
}

function Template() {

    const { widget, properties, expandedProperties } = useWidget();
    const { renderContext } = useRenderContext();
    const { pageTheme } = usePageTheme();

    let widgetClassName = `link flex `;

    return (
        <Link
            widgetId={widget.id}
            className={widgetClassName}
            elementName="link"
            variantName={properties.variant || "default"}
            pageTheme={pageTheme}
            properties={expandedProperties}
            renderContext={renderContext}
            >
            <StaticWidgets
                widgets={widget.children}
                />
        </Link>
    );
}

const widgetType: IWidgetType = {
    name: 'Link',
    Static: Static,
    Preview: Static,
    Editor: Editor,
    Template: Template,
    template: {
        id: `widget-${nanoid()}`,
        xtype: 'link',
        grouped: true,
        properties: {
            link: {
                target: "url",
                url: "https://www.google.com",
                page: 0,
            },
            flexDirection: "row",
            ...makePadding(8),
            gap: 3,
            color: "#6478f9",
            alignSelf: "center",
        },
        children: [
            {
                id: `widget-${nanoid()}`,
                xtype: 'text',
                properties: {
                    content: "Your link text",
                },
            },
        ],
    },
    properties: [
        {
            id: "link",
            name: "Link",
            type: "link",
            required: true,
            defaultValue: {
                action: "url",
                url: "https://www.google.com",
                page: 0,
            },
        },
        {
            id: "flexDirection",
            name: "Direction",
            type: "option",
            values: [{
                name: "Row",
                value: "row",
                icon: <ViewColumnIcon />,
            },
            {
                name: "Column",
                value: "column",
                icon: <TableRowsIcon />,
            },
            ],
            required: true,
            defaultValue: "row",
        },
    ],
    propertyCategories: [
        {
            name: "Items",
            properties: stdGroupProperties,
        },
        {
            name: "Colors",
            properties: [
                makeColorProperty("normal", "color", "Color"),
                makeColorProperty("hover", "color", "Color / Hover"),
                makeColorProperty("normal", "borderColor", "Border"),
                makeColorProperty("hover", "borderColor", "Border / Hover"),
                makeColorProperty("normal", "backgroundColor", "Background"),
                makeColorProperty("hover", "backgroundColor", "Background / Hover"),
            ],
        },
        {
            name: "Placement",
            properties: [
                stdElementAlignment,
                stdPadding,
                stdMargin,
                stdWidth,
                stdHeight,
            ],
        },
    ],
};

export default widgetType;
