import { FC } from 'react'
import { Tooltip } from '@mui/material'
import { Toast } from '../../../helpers/Toast'
import styled, {css, keyframes} from 'styled-components'
import { useCampaign } from '../../../../builder/context/campaign'

interface Props {
}

const SaveButtonCustom: FC<Props> = ({}) => {

    const { loading, saveCampaign, modified } = useCampaign();

    const onSaveCampaign = async () => {
        try {
            await saveCampaign();
            Toast('Campaign saved', 'success')
        } 
        catch (error) {
            console.log(error)
            Toast('Error saving campaign', 'error')
        }
    }

    return (
        <Tooltip title={modified ? "Save project" : "Project is saved"} arrow>
            <RoundIconButton onClick={onSaveCampaign} loadingProcces={loading}>
                {!modified ? (
                    <img src="/icons/save-ok.svg" alt="save campaign" />
                ) : (
                    <img src="/icons/save-purple.svg" alt="save campaign" />
                )}
            </RoundIconButton>
        </Tooltip>
    )
}

export default SaveButtonCustom


const spin = keyframes`
      0% {
        transform: rotateY(0);
      }
      100% {
        transform: rotateY(180deg);
      }
`

export const RoundIconButton = styled.button<{
    loadingProcces?: boolean
}>`
    border-radius: 50%;
    height: 36px;
    width: 36px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0.25rem;
    outline: none;
    border: none;
    color: #fff;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        background-color: #fff;
        box-shadow: inset 0 0 5px 1px rgba(100, 120, 249, 0.4);
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
    }

    img {
        animation: ${(props) => (props.loadingProcces ? css`0.5s ${spin} infinite` : 'none')};
    }
`

