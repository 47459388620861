import { motion } from 'framer-motion'
import { ChangeEvent, FC, useState } from 'react'
import styled from 'styled-components'
import { useWidget } from 'widgets-base'

interface Props {
    topValue: number
    bottomValue: number
    leftValue: number
    rightValue: number
}

const Margins: FC<Props> = ({
    topValue = 0,
    bottomValue = 0,
    leftValue = 0,
    rightValue = 0,
}) => {

    const { widget, setWidget } = useWidget();
    const [value, setValue] = useState<number | string>('')
    const [isCustomBorder, setCustomState] = useState<boolean>(false)

    const checkCustomState = () => {
        setCustomState((prev) => !prev)
    }

    const handleChangeAllSpacing = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = +event.target.value
        setValue(newValue)

        const newWidget = {
            ...widget,
            appearanceSettings: {
                ...widget.appearanceSettings,
                marginTop: newValue,
                marginBottom: newValue,
                marginLeft: newValue,
                marginRight: newValue,
            },
        }

        setWidget(newWidget);
    }

    const handleChangeCustomSpacing = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = +event.target.value
        const name = event.target.name
        const newWidget = {
            ...widget,
            appearanceSettings: {
                ...widget.appearanceSettings,
                [name]: newValue,
            },
        }

        setWidget(newWidget);
    }

    return (
        <Container>
            <label>Margins</label>
            <ActiveContainer isCustomBorder={isCustomBorder}>
                <div className="field">
                    <input
                        disabled={isCustomBorder}
                        placeholder="0"
                        type="number"
                        name="all"
                        value={value}
                        onChange={handleChangeAllSpacing}
                    />
                    <span>px</span>
                </div>
                <div className="icon" onClick={checkCustomState}>
                    <svg
                        width="9"
                        height="17"
                        viewBox="0 0 9 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M8 6C8 5.07407 8 5.33333 8 4.88889C8 4.33333 8 1 4.5 1C1 1 1 4.33333 1 4.88889V6"
                            stroke={isCustomBorder ? '#6478F9' : '#717F95'}
                            strokeLinecap="round"
                        />
                        <path
                            d="M8 11C8 11.9259 8 11.6667 8 12.1111C8 12.6667 8 16 4.5 16C1 16 1 12.6667 1 12.1111V11"
                            stroke={isCustomBorder ? '#6478F9' : '#717F95'}
                            strokeLinecap="round"
                        />
                        <line
                            x1="4.5"
                            y1="7.5"
                            x2="4.5"
                            y2="10.5"
                            stroke={isCustomBorder ? '#6478F9' : '#717F95'}
                            strokeLinecap="round"
                        />
                    </svg>
                </div>
            </ActiveContainer>

            {isCustomBorder && (
                <MultiSelectContainer
                    initial={{ opacity: 0, y: 50, scale: 0.3 }}
                    animate={{ opacity: 1, y: 0, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
                >
                    <div className="custom">
                        <div className="top">
                            <div className="field">
                                <input
                                    name="marginTop"
                                    type="number"
                                    value={topValue}
                                    onChange={handleChangeCustomSpacing}
                                />
                                <span>px</span>
                            </div>
                            <span>Top</span>
                        </div>
                        <div className="right-left">
                            <div className="flex_container">
                                <div className="field">
                                    <input
                                        name="marginLeft"
                                        type="number"
                                        value={leftValue}
                                        onChange={handleChangeCustomSpacing}
                                    />
                                    <span>px</span>
                                </div>
                                <span>Left</span>
                            </div>
                            <div className="flex_container">
                                <div className="field">
                                    <input
                                        name="marginRight"
                                        type="number"
                                        value={rightValue}
                                        onChange={handleChangeCustomSpacing}
                                    />
                                    <span>px</span>
                                </div>
                                <span>Right</span>
                            </div>
                        </div>
                        <div className="bottom">
                            <div className="field">
                                <input
                                    name="marginBottom"
                                    type="number"
                                    value={bottomValue}
                                    onChange={handleChangeCustomSpacing}
                                />
                                <span>px</span>
                            </div>
                            <span>Bottom</span>
                        </div>
                    </div>
                </MultiSelectContainer>
            )}
        </Container>
    )
}

export default Margins

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #717f95;

    & > label {
        font-size: 12px;
        color: #3c4b61;
        margin-bottom: 7px;
        font-weight: bold;
    }
`

const ActiveContainer = styled.div<{
    isCustomBorder: boolean
}>`
    display: flex;
    height: 32px;

    .field {
        display: flex;
        align-items: center;
        padding: 5px;
        width: 78px;
        background: #ffffff;
        border: 1px solid #dfe2f2;
        border-radius: 8px;
        margin-right: 6px;
        opacity: ${(props) => (props.isCustomBorder ? 0.5 : 1)};
        input {
            border: none;
            width: 40px;
            font-size: 12px;
            color: #3c4b61;
            font-weight: bold;
        }
        span {
            font-size: 12px;
            color: #3c4b61;
            border-left: 1px solid #dfe2f2;
            padding: 0 5px;
            font-weight: bold;
        }
    }

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        border: ${(props) => (props.isCustomBorder ? `2px solid #6478F9` : `1px solid #dfe2f2`)};
        border-radius: 8px;
        width: 32px;
        height: 32px;
        cursor: pointer;
    }
`

const MultiSelectContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    width: 100%;

    .custom {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        .top,
        .right-left,
        .bottom,
        .flex_container {
            display: flex;
            flex-direction: column;
            align-items: center;

            & > span {
                margin-top: 2px;
                font-size: 12px;
                line-height: 14px;
                color: #717f95;
            }

            .field {
                display: flex;
                align-items: center;
                justify-content: center;
                background: #ffffff;
                border: 1px solid #dfe2f2;
                border-radius: 8px;
                padding: 5px;
                width: 78px;
                height: 32px;
                background: #ffffff;
                border: 1px solid #dfe2f2;
                border-radius: 8px;

                input {
                    border: none;
                    width: 40px;
                    font-size: 12px;
                    color: #3c4b61;
                    font-weight: bold;
                }
                span {
                    font-size: 12px;
                    color: #3c4b61;
                    border-left: 1px solid #dfe2f2;
                    padding: 0 5px;
                    font-weight: bold;
                }
            }
        }

        .right-left {
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
        }
    }
`
