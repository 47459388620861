//
// Generates the style for a widget based on its properties.
//

import { BorderStyle, ContentJustification, Direction, ElementAlignment, IExpandedPropertyBag, IExpandedStylingProperties, IPropertyBag, TextAlignment, TextDecoration } from "./property";
import { IRenderContext } from "./render-context";
import styled, { css as _css, FlattenSimpleInterpolation, StyledComponent } from "styled-components";

//
// Generates a style that can import a font.
//
export function generateFontImportStyle(properties: IExpandedPropertyBag): JSX.Element | undefined {
    if (properties.fontFamily === undefined) {
        return undefined;
    }

    let fontFamily = properties.fontFamily;
    fontFamily = fontFamily.replace(/\s/g, '+');

    return (
        <style>
            {`@import url(https://fonts.googleapis.com/css2?family=${fontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`}
        </style>
    );
}

//
// Determines the direction of layout for a group widget.
//
export function determineDirection(properties: IPropertyBag, renderContext: IRenderContext): Direction {
    if (!properties) {
        return "row";
    }

    let direction = properties.flexDirection || "row";
    if (properties[renderContext.orientation]?.flexDirection) {
        direction = properties[renderContext.orientation].flexDirection;
    }
    if (properties[renderContext.screen]?.flexDirection) {
        direction = properties[renderContext.screen].flexDirection;
    }
    return direction;
}

//
// Copy whitelisted fields to a new object.
//
function makeObject<InputT, OutputT>(input: InputT, fields: string[]): OutputT {
    const output: any = {};

    if (input !== undefined) {
        for (const field of fields) {
            const value = input[field];
            if (value !== undefined) {
                output[field] = value;
            }
        }
    }

    return output;
}

export interface IWidgetStyle {

    flex?: string;
    flexDirection?: Direction;
    flexGrow?: number | string;
    alignSelf?: ElementAlignment;
    width?: number | string;
    height?: number | string;
    minWidth?: number | string;
    minHeight?: number | string;
    maxWidth?: number | string;
    maxHeight?: number | string;
    margin?: number | string;
    marginLeft?: number | string;
    marginRight?: number | string;
    marginTop?: number | string;
    marginBottom?: number | string;

    alignItems?: ElementAlignment;
    justifyContent?: ContentJustification;

    padding?: number | string;
    paddingLeft?: number | string;
    paddingRight?: number | string;
    paddingTop?: number | string;
    paddingBottom?: number | string;

    textAlign?: TextAlignment;

    color?: string;
    backgroundColor?: string;

    fontSize?: number | string;
    fontWeight?: number | string;
    lineHeight?: number | string;

    borderColor?: string;
    borderLeftWidth?: number | string;
    borderRightWidth?: number | string;
    borderTopWidth?: number | string;
    borderBottomWidth?: number | string;
    borderStyle?: BorderStyle;

    borderRadius?: number | string;
    borderTopLeftRadius?: number | string;
    borderTopRightRadius?: number | string;
    borderBottomLeftRadius?: number | string;
    borderBottomRightRadius?: number | string;

    textDecoration?: TextDecoration;

    gap?: number | string;

    fontFamily?: string;
}

const widgetStyleFields = [
    "flex",
    "flexDirection",
    "flexGrow",
    "alignSelf",
    "width",
    "height",
    "minWidth",
    "minHeight",
    "maxWidth",
    "maxHeight",
    "margin",
    "marginLeft",
    "marginRight",
    "marginTop",
    "marginBottom",
    "flexDirection",
    "alignItems",
    "justifyContent",
    "padding",
    "paddingLeft",
    "paddingRight",
    "paddingTop",
    "paddingBottom",
    "textAlign",
    "color",
    "backgroundColor",
    "fontSize",
    "fontWeight",
    "lineHeight",
    "borderColor",
    "borderLeftWidth",
    "borderRightWidth",
    "borderTopWidth",
    "borderBottomWidth",
    "borderStyle",
    "borderRadius",
    "borderTopLeftRadius",
    "borderTopRightRadius",
    "borderBottomLeftRadius",
    "borderBottomRightRadius",
    "textDecoration",
    "gap",
    "columnGap",
    "rowGap",
    "fontFamily",
];

//
// Extract styles for the widget itself.
//
export function extractWidgetStyle(properties?: IExpandedStylingProperties): IWidgetStyle {
    return makeObject<IExpandedStylingProperties, IWidgetStyle>(properties, widgetStyleFields);
}

//
// Temporary function to make refactor simpler.
//
export function css(style: IWidgetStyle): FlattenSimpleInterpolation {
    return _css(style as any);
}

let probeMap = {};

//
// Clear the styles probe.
//
export function clearProbe() {
    probeMap = {};
}

//
// Show the styles probe.
//
export function showProbe() {
    console.log(`@@@ STYLES PROBE @@@`);
    console.log(probeMap);
}


//
// For debugging styles.
//
export function probe<T>(id: string, name: string, value: T): T {
    // console.log(`[probe]:`, id, name, value);
    if (probeMap[id] === undefined) {
        probeMap[id] = [];
    }
    probeMap[id].push({ name, value });
    return value;
}

//
// Colors for a variant of a particular element.
//
export interface IVariantColors {
    color?: string;
    backgroundColor?: string;
    borderColor?: string;
}

//
// Colors variants of a particular element.
//
export interface IElementColors {
    [key: string]: IVariantColors;
}

//
// Colors for various named elements in the page.
//
export interface IPageThemeColors {
    //TODO: page background color could go here.

    //
    // Default colors for the body.
    //
    body?: IElementColors;

    //
    // Colors for named elements.
    //
    elements?: {
        [key: string]: IElementColors;
    };
}

//
// Font for a variant of a particular element.
//
export interface IVariantFont {
    fontFamily?: string;
    fontSize?: number | string;
    fontWeight?: number | string;
    lineHeight?: number | string;
    textDecoration?: TextDecoration;
}

//
// Font variants of a particular element.
//
export interface IElementFonts {
    [key: string]: IVariantFont;
}

//
// Fonts for various named elements in the page.
//
export interface IPageThemeFonts {
    //
    // Default fonts to apply to the body.
    //
    body?: IVariantFont;

    //
    // Fonts for named elements.
    //
    elements?: {
        [key: string]: IElementFonts;
    };
}

//
// Structure for a particular variant of an element.
//
export interface IVariantStructure {
    paddingTop?: number | string;
    paddingBottom?: number | string;
    paddingLeft?: number | string;
    paddingRight?: number | string;

    borderStyle?: BorderStyle;
    borderLeftWidth?: number | string;
    borderRightWidth?: number | string;
    borderTopWidth?: number | string;
    borderBottomWidth?: number | string;
    borderTopLeftRadius?: number | string;
    borderTopRightRadius?: number | string;
    borderBottomLeftRadius?: number | string;
    borderBottomRightRadius?: number | string;
}

//
// Structure for a particular element.
//
export interface IElementStructure {
    [key: string]: IVariantStructure;
}

//
// Default styles for the structure (e.g. padding) of the page.
//
export interface IPageThemeStructure {
    //
    // Structure for named elements.
    //
    elements?: {
        [key: string]: IElementStructure;
    };
}

//
// Defines the theme for the current page.
//
export interface IPageTheme {

    //
    // Colors for various named elements in the page.
    //
    colors?: IPageThemeColors;

    //
    // Fonts for various named elements in the page.
    //
    fonts?: IPageThemeFonts;

    //
    // Default styles for the structure (e.g. padding) of the page.
    //
    structure?: IPageThemeStructure;
}

//
// Extracts the style for a particular variant of an element from the page theme.
//
export function extractDefaultThemeStyle(pageTheme: IPageTheme | undefined, elementName: string, variantName: string): IWidgetStyle {
    // console.log(`Building style for ${elementName} / ${variantName}`);

    const elementColors = pageTheme?.colors?.elements?.[elementName];
    const variantColors = elementColors?.[variantName] || elementColors?.default;
    const elementFonts = pageTheme?.fonts?.elements?.[elementName];
    const variantFont = elementFonts?.[variantName] || elementFonts?.default;
    const elementStructure = pageTheme?.structure?.elements?.[elementName];
    const variantStructure = elementStructure?.[variantName] || elementStructure?.default;

    return {
        color: variantColors?.color,
        backgroundColor: variantColors?.backgroundColor,
        borderColor: variantColors?.borderColor,
        fontFamily: variantFont?.fontFamily,
        fontSize: variantFont?.fontSize,
        fontWeight: variantFont?.fontWeight,
        lineHeight: variantFont?.lineHeight,
        textDecoration: variantFont?.textDecoration,
        paddingTop: variantStructure?.paddingTop,
        paddingBottom: variantStructure?.paddingBottom,
        paddingLeft: variantStructure?.paddingLeft,
        paddingRight: variantStructure?.paddingRight,
        borderStyle: variantStructure?.borderStyle,
        borderLeftWidth: variantStructure?.borderLeftWidth,
        borderRightWidth: variantStructure?.borderRightWidth,
        borderTopWidth: variantStructure?.borderTopWidth,
        borderBottomWidth: variantStructure?.borderBottomWidth,
        borderTopLeftRadius: variantStructure?.borderTopLeftRadius,
        borderTopRightRadius: variantStructure?.borderTopRightRadius,
        borderBottomLeftRadius: variantStructure?.borderBottomLeftRadius,
        borderBottomRightRadius: variantStructure?.borderBottomRightRadius,
    };
}

//
// Extracts the style for a particular variant of an element from the page theme.
//
export function extractVariantThemeStyle(pageTheme: IPageTheme | undefined, elementName: string, variantName: string): IWidgetStyle {
    // console.log(`Building style for ${elementName} / ${variantName}`);

    const elementColors = pageTheme?.colors?.elements?.[elementName];
    const variantColors = elementColors?.[variantName];
    const elementFonts = pageTheme?.fonts?.elements?.[elementName];
    const variantFont = elementFonts?.[variantName];
    const elementStructure = pageTheme?.structure?.elements?.[elementName];
    const variantStructure = elementStructure?.[variantName];

    return {
        color: variantColors?.color,
        backgroundColor: variantColors?.backgroundColor,
        borderColor: variantColors?.borderColor,
        fontFamily: variantFont?.fontFamily,
        fontSize: variantFont?.fontSize,
        fontWeight: variantFont?.fontWeight,
        lineHeight: variantFont?.lineHeight,
        textDecoration: variantFont?.textDecoration,
        paddingTop: variantStructure?.paddingTop,
        paddingBottom: variantStructure?.paddingBottom,
        paddingLeft: variantStructure?.paddingLeft,
        paddingRight: variantStructure?.paddingRight,
        borderStyle: variantStructure?.borderStyle,
        borderLeftWidth: variantStructure?.borderLeftWidth,
        borderRightWidth: variantStructure?.borderRightWidth,
        borderTopWidth: variantStructure?.borderTopWidth,
        borderBottomWidth: variantStructure?.borderBottomWidth,
        borderTopLeftRadius: variantStructure?.borderTopLeftRadius,
        borderTopRightRadius: variantStructure?.borderTopRightRadius,
        borderBottomLeftRadius: variantStructure?.borderBottomLeftRadius,
        borderBottomRightRadius: variantStructure?.borderBottomRightRadius,
    };
}

//
// Gets the style for the entire page.
//
export function extractPageStyle(pageTheme: IPageTheme): IWidgetStyle {
    const defaultColors = pageTheme.colors?.body?.default;
    const defaultFonts = pageTheme.fonts?.body;
    return {
        color: defaultColors?.color,
        backgroundColor: defaultColors?.backgroundColor,
        fontFamily: defaultFonts?.fontFamily,
        fontSize: defaultFonts?.fontSize,
        fontWeight: defaultFonts?.fontWeight,
        lineHeight: defaultFonts?.lineHeight,
    };
}

export interface IStyledElementProps { 
    //
    // The theme for the entire page.
    //
    pageTheme: IPageTheme;

    //
    // The element name being rendered.
    //
    elementName: string;

    //
    // The variant style to use for the element.
    //
    variantName: string;

    //
    // Indivdiual properties for the element.
    //
    properties: IExpandedPropertyBag;

    //
    // The current render context.
    //
    renderContext: IRenderContext;
}

//
// Create a styled element injecting necessary styles.
//
export function makeStyledElement(elementName: string): StyledComponent<any, any, IStyledElementProps, never> {
    const styledElement = styled[elementName]<{ widgetId: string, pageTheme: IPageTheme, elementName: string, variantName: string, properties?: IExpandedPropertyBag, renderContext: IRenderContext }>`

        ${props => probe(props.widgetId, `${props.elementName}-${props.variantName}-theme-style`, css(extractDefaultThemeStyle(props.pageTheme, props.elementName, props.variantName)))}

        ${props => props.properties && probe(props.widgetId, `${props.elementName}-${props.variantName}-individual-style`, css(extractWidgetStyle(props.properties)))}

        ${props => {
            if (props.renderContext.isStaticExport) {
                return probe(props.widgetId, `${props.elementName}-${props.variantName}-static`, _css`
                    @media (max-width: 639px) {
                        ${css(extractVariantThemeStyle(props.pageTheme, props.elementName, "small"))}
                        ${css(extractWidgetStyle(props.properties?.small))}
                    }

                    @media (min-width: 640px) and (max-width: 767px) {
                        ${css(extractVariantThemeStyle(props.pageTheme, props.elementName, "medium"))}
                        ${css(extractWidgetStyle(props.properties?.medium))}
                    }

                    @media (min-width: 768px) {
                        ${css(extractVariantThemeStyle(props.pageTheme, props.elementName, "large"))}
                        ${css(extractWidgetStyle(props.properties?.large))}
                    }

                    @media (orientation: portrait) {
                        ${css(extractVariantThemeStyle(props.pageTheme, props.elementName, "portrait"))}
                        ${css(extractWidgetStyle(props.properties?.portrait))}
                    }

                    @media (orientation: landscape) {
                        ${css(extractVariantThemeStyle(props.pageTheme, props.elementName, "landscape"))}
                        ${css(extractWidgetStyle(props.properties?.landscape))}
                    }
                `);
            }
            else {
                return probe(props.widgetId, `${props.elementName}-${props.variantName}-non-static`, _css`
                    ${css(extractVariantThemeStyle(props.pageTheme, props.elementName, props.renderContext.screen))}
                    ${css(extractVariantThemeStyle(props.pageTheme, props.elementName, props.renderContext.orientation))}
                    ${css(extractWidgetStyle(props.properties?.[props.renderContext.screen]))}
                    ${css(extractWidgetStyle(props.properties?.[props.renderContext.orientation]))}
                `);
            }
        }}

        ${props => probe(props.widgetId, `${props.elementName}-hover`, _css`
            &:hover {
                ${css(extractVariantThemeStyle(props.pageTheme, props.elementName, "hover"))}
                ${css(extractWidgetStyle(props.properties?.hover))}
            }
        `)}
    `;
    return styledElement;
}