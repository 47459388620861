import { useWidget, useRenderContext } from 'widgets-base';
import { FormStateContextProvider } from '../../../../../../builder/context/form-state-old'

import ContainerWidget from '../../../General/ContainerWidget'

interface Props {
}

export default function FormContainer({}: Props) {

    const { widget } = useWidget();
    const { renderContext } = useRenderContext();

    if (renderContext.isStaticExport) {
        return undefined;
    }

    return (
        <FormStateContextProvider>
            <form style={{ outline: 'none', width: '100%' }} name={widget.name}>
                <ContainerWidget />
            </form>
        </FormStateContextProvider>
    )
}