import React, {FC, memo} from 'react'
import styled from 'styled-components'
import {EditableText} from '../../../common/EditableText'
import { useWidget } from 'widgets-base';
import { useCampaign } from '../../../../../builder/context/campaign';

interface Props {
}

const MultipleTextWidget: FC<Props> = () => {

    const { widget } = useWidget();
    const fontFamily = widget.appearanceSettings?.font?.family || 'Poppins'
    const modFontFamily = fontFamily?.replace(/\s/g, '+')
    const fontUrl = widget.appearanceSettings?.font?.url
    const appearanceSettings = widget?.appearanceSettings
    const colourSettings = widget?.colourSettings
    const width = appearanceSettings?.width + '%'
    const fontSize = appearanceSettings?.font?.size || 14
    const align = appearanceSettings?.align
    const marginTop = appearanceSettings?.marginTop / 10 + 'em'
    const marginBottom = appearanceSettings?.marginBottom / 10 + 'em'
    const marginLeft = appearanceSettings?.marginLeft / 10 + 'em'
    const marginRight = appearanceSettings?.marginRight / 10 + 'em'
    const paddingTop = appearanceSettings?.paddingTop / 10 + 'em'
    const paddingBottom = appearanceSettings?.paddingBottom / 10 + 'em'
    const paddingLeft = appearanceSettings?.paddingLeft / 10 + 'em'
    const paddingRight = appearanceSettings?.marginRight / 10 + 'em'
    const weight = appearanceSettings?.font?.weight || 300
    const letterSpacing = appearanceSettings?.font?.spacing + 'px' || '8px'
    const lineHeight = appearanceSettings?.font?.lineHeight || 17
    const color = colourSettings?.textColour[0]

    const stylesChild = {
        weight,
        letterSpacing,
        lineHeight,
    }

    const stylesParent = {
        align,
        width,
        color,
        paddingTop,
        paddingBottom,
        paddingLeft,
        paddingRight,
        marginTop,
        marginBottom,
        marginLeft,
        marginRight,
        fontSize,
        fontFamily,
    }

    const { setWidget_deprecated } = useCampaign();

    const setWidgetSetting = ({name, value}: { name: string, value: string }) => {
        const newWidget = {
            ...widget,
            settings: {
                ...widget.settings,
                [name]: value,
            },
        }

        setWidget_deprecated(newWidget);
    }

    return (
        <TextSection className="old-multiple-text-widget" id={widget.id}>
            <style>
                {!fontUrl
                    ? `@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`
                    : `@font-face {
                        font-family: '${fontFamily}';
                        src: url('${fontUrl}');
                        font-style: normal;
                      }
                      
                      .header-text {
                        font-family: '${fontFamily}';
                      }`}
            </style>
            <Container globalStyles={stylesParent} contentStyles={stylesChild}>
                {widget.settings.pretitle && (
                    <article className="pretitle">
                        <span>{widget.settings.pretitle}</span>
                    </article>
                )}
                {widget.settings.title && (
                    <article className="title">
                        <h1>{widget.settings.title}</h1>
                    </article>
                )}
                {widget.settings.content && (
                    <article className="content">
                        <EditableText
                            text={widget.settings.content}
                            onTextChanged={text => {
                                setWidgetSetting({
                                    name: "content",
                                    value: text,
                                });
                            }}
                        />
                    </article>
                )}
                {widget.settings.info && (
                    <article className="info">
                        <span>{widget.settings.info}</span>
                    </article>
                )}
            </Container>
        </TextSection>
    )
}

export default memo(MultipleTextWidget)

const TextSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`
const Container = styled.div<{
    globalStyles: {
        align: string
        width: string
        color: string
        paddingTop: string
        paddingBottom: string
        paddingLeft: string
        paddingRight: string
        marginTop: string
        marginBottom: string
        marginLeft: string
        marginRight: string
        fontSize: number
        fontFamily: string
    }
    contentStyles: {
        weight: string | number
        letterSpacing: string
        lineHeight: number
    }
}>`
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    padding: 0 10%;
    font-family: ${(props) => props.globalStyles.fontFamily};
    color: ${(props) => props.globalStyles.color};
    width: ${(props) => props.globalStyles.width};
    margin-top: ${(props) => props.globalStyles.marginTop};
    margin-bottom: ${(props) => props.globalStyles.marginBottom};
    margin-left: ${(props) => props.globalStyles.marginLeft};
    margin-right: ${(props) => props.globalStyles.marginRight};
    padding-top: ${(props) => props.globalStyles.paddingTop};
    padding-left: ${(props) => props.globalStyles.paddingLeft};
    padding-bottom: ${(props) => props.globalStyles.paddingBottom};
    padding-right: ${(props) => props.globalStyles.paddingRight};
    text-align: ${(props) => props.globalStyles.align};
    line-height: ${(props) => props.contentStyles.lineHeight}px;
    font-weight: ${(props) => props.contentStyles.weight};

    article:not(:last-child) {
        margin-bottom: 15px;
    }

    .pretitle {
        font-size: ${(props) => props.globalStyles.fontSize / 2 + 'px'};
    }

    .title {
        font-size: ${(props) => props.globalStyles.fontSize + 'px'};
        h1 {
            font-weight: 700;
        }
    }

    .content {
        font-size: ${(props) => props.globalStyles.fontSize / 1.2 + 'px'};
        
        p {
            letter-spacing: ${(props) => props.contentStyles.letterSpacing};
            line-height: ${(props) => props.contentStyles.lineHeight + 'px'};
        }
    }

    .info {
        font-size: ${(props) => props.globalStyles.fontSize / 2 + 'px'};
    }
`
