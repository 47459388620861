
//
// Gets the base filename from a URL.
//
export function getFilenameFromUrl(url: string): string {
    const pathSegments = url.replace(/%2f/gi, "/").split('/');
    return pathSegments[pathSegments.length - 1];
}

//
// Adds the current origin to the URL if it's a relative URL.
//
export function makeFullAssetUrl(url: string) {
    if (url.startsWith('/')) {
        // url = window.location.origin + url;
        url = "https://deploy.link" + url;
    }
    return url;
}
