import { FC } from 'react'
import FormsNotify from '../Notifications/FormsNotify'

interface Props {
    openNavbar: boolean
}

export const Notifications: FC<Props> = ({ openNavbar }) => {
    return (
        <>
            <FormsNotify
                openNavbar={openNavbar}
                storageKey="notific_form_key"
                notificationKey="createdAt"
                notificationMsg="formName"
                heading="A new form has arrived"
                showDate
            />
        </>
    )
}
