import { IWidget } from "widgets-base"


export enum EventsMode {
    LARGE = 'large',
    SMALL = 'small',
}

export interface IEvent {
    id: string
    label: string
    image: string
    date: Date
    category: string[]
    description: string
    unsetDate?: boolean
}

export interface IEvents extends IWidget {
    id: string
    type: string
    name: string
    category?: string
    settings?: {
        eventsList: IEvent[]
        mode: EventsMode
        label: string
    }
    appearanceSettings?: {
        borderTopLeftRadius: number
        borderTopRightRadius: number
        borderBottomLeftRadius: number
        borderBottomRightRadius: number
        borderTopWidth: number
        borderBottomWidth: number
        borderLeftWidth: number
        borderRightWidth: number
        font: {
            family: string
            size: number
            weight?: number
            spacing?: number
        }
        lineHeight: number
    }
    colourSettings?: {
        'text colour': string[]
        'background colour': string[]
        'date marker colour': string[]
        'text marker colour': string[]
    }
}
