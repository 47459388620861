import { atom } from 'recoil'

export const debugModeState = atom<boolean>({
    key: 'builderDebugMode',
    default: false,
});

export const debugJsonState = atom<string | undefined>({
    key: "builderDebugJson",
    default: undefined,
});