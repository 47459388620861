//
// The details of an image.
//
export interface IImageDetails {
    width: number;
    height: number;
    aspectRatio: number;
}

//
// Loads an image and gets its details.
//
export function loadImageDetails(url: string): Promise<IImageDetails> {
    return new Promise<IImageDetails>((resolve, reject) => {
        const img = new Image();
  
        img.onload = () => {
            resolve({
                width: img.width,
                height: img.height,
                aspectRatio: img.height / img.width,
            });
        };
      
        img.src = url;
    });
}