import { Tooltip } from '@mui/material'
import { FC, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { Toast } from '../../../helpers/Toast'
import CampaignsService from '../../../../services/CampaignsService'
import { Button } from '../../common/Button'
import DeploySussesModal from './DeploySussesModal'
import { useConfirm } from '../../../../hooks/confirm'
import { serializeProject } from '../../../../lib/deploy'
import { UserRoles, useAuth } from 'widgets-base'
import { useCampaign } from '../../../../builder/context/campaign'

interface Props {
}

const DeployButton: FC<Props> = ({}) => {
    
    const { campaign, updateCampaign, saveCampaign } = useCampaign();
    const [loading, setLoading] = useState<boolean>(false)
    const [openDeployModal, setOpenDeployModal] = useState<boolean>(false)
    const { isConfirmed } = useConfirm();
    const { currentUser } = useAuth();

    function toggleOpenDeployModal(): void {
        setOpenDeployModal((prev) => !prev)
    }

    const liveBaseUrl = `https://live.deploy.link`;

    //
    // Make the canonical URL for the page.
    // This is suitable for use in a QR code.
    //
    function makeCanonicalDeployUrl(): string {
        return `${liveBaseUrl}/live/${campaign._id}`
    }

    //
    // Encodes the campaign name for use in a URL.
    //
    function encodeCampaignNameForURL(campaignName: string): string {
        return campaignName.replace(/\s+/g, '-');
    }

    //
    // Make the pretty "display URL" for the page.
    //
    function makeDisplayDeployUrl(): string {
        if (currentUser.role.toLowerCase() === UserRoles.FREE) {
            return makeCanonicalDeployUrl();
        } 
        else {
            //
            // Premium users get a pretty URL.
            //
            return `${liveBaseUrl}/${currentUser.username}/${encodeCampaignNameForURL(campaign.campaignName)}`;
        }
    }

    //
    // Statically renders the page and deploys it to S3.
    //
    async function doStaticDeployment() {
        console.log(`Doing static deployment!`); 
        try {
            setLoading(true);

            const serializedProject = serializeProject(campaign);

            if (currentUser.role.toLowerCase() !== UserRoles.FREE) {
                //
                // Premium users get a pretty URL.
                //
                serializedProject.prettyURL = {
                    accountName: currentUser.username,
                    campaignName: encodeCampaignNameForURL(campaign.campaignName),
                };
            }
        
            await CampaignsService.deployStatic(campaign._id, serializedProject, campaign);

            updateCampaign({ 
                deployUrl: makeDisplayDeployUrl(),
                canonicalUrl: makeCanonicalDeployUrl(),
                status: 'published',
                deployDate: new Date().toISOString(),
             });
            saveCampaign();
            toggleOpenDeployModal();
        }
        catch (error) {
            console.error(`Failed to deploy campaign ${campaign._id}`);
            console.error(error);
            Toast('Error deploying campaign', 'error');
        }
        finally {
            setLoading(false);
        }
    }

    const onDeployProject = async () => {
        if (campaign.status === 'published') {
            const confirmed = await isConfirmed(
                'Are you sure you want to deploy an already published project?'
            )
            if (!confirmed) return
        }

        //
        // Only new campaigns at the moment will go through static deployment.
        //
        await doStaticDeployment();
    }

    return (
        <Tooltip title="Deploy project" arrow>
            <ButtonWrapper loadingStatus={loading}>
                <Button
                    size="x-small"
                    variant="primary"
                    onClick={onDeployProject}
                    disabled={loading}
                    width="120px"
                    className="button-deploy"
                    borderRadius="50px"
                    type="button"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        className="button-deploy__logo"
                        viewBox="0 0 20 25"
                        fill="none"
                    >
                        <g className="button-deploy__logo--complete">
                            <g
                                transform="translate(5.976 28.664)"
                                className="button-deploy__logo--trails"
                            >
                                <path
                                    d="M153.97,738.46l1.692.055v6.115l-1.692-1.147"
                                    transform="translate(-153.97 -738.46)"
                                    fill="#fff"
                                />
                                <path
                                    d="M337.1,738.46l-1.692.055v6.115l1.692-1.147"
                                    transform="translate(-328.367 -738.46)"
                                    fill="#fff"
                                />
                                <path
                                    d="M243.98,738.46h1.692V747l-.846.939-.846-.971"
                                    transform="translate(-240.486 -738.46)"
                                    fill="#fff"
                                />
                            </g>
                            <g className="button-deploy__logo--rocket">
                                <path
                                    d="M12 2.75C12.33 2.75 12.66 2.87001 12.92 3.10001C13.81 3.89001 15.27 5.41999 16.27 7.54999C16.48 7.99999 16.67 8.47 16.83 8.97C16.96 9.39 17.07 9.80999 17.16 10.24C17.63 12.55 17.11 14.94 16.38 17.14C16.1 18 15.88 18.99 15.37 19.75C15.34 19.79 15.32 19.83 15.3 19.86C15.03 20.22 14.61 20.43 14.16 20.43C14.16 20.43 13.08 20.43 12 20.43C10.92 20.43 9.83998 20.43 9.83998 20.43C9.38998 20.43 8.97 20.22 8.7 19.86C8.68 19.82 8.65999 19.79 8.62999 19.75C8.11999 18.99 7.89998 18.01 7.61998 17.14C6.89998 14.94 6.36998 12.55 6.83998 10.24C6.92998 9.80999 7.04 9.39 7.17 8.97C7.33 8.47 7.52 7.99999 7.73 7.54999C8.73 5.41999 10.19 3.90001 11.08 3.10001C11.34 2.87001 11.67 2.75 12 2.75Z"
                                    stroke="#FFFFFF"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M15.37 19.75L17.16 21.07C17.43 21.27 17.76 21.31 18.07 21.2C18.2 21.14 18.31 21.07 18.4 20.98C18.53 20.85 18.62 20.69 18.66 20.5L19.3 17.33C19.39 16.9 19.27 16.48 19 16.15L17.29 14.16C17.3 14.14 17.3 14.12 17.3 14.1"
                                    stroke="#FFFFFF"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M6.70002 14.15L4.99 16.14C4.72 16.47 4.60001 16.9 4.69001 17.32L5.32999 20.49C5.38999 20.81 5.62002 21.07 5.92002 21.19C6.23002 21.3 6.55999 21.26 6.82999 21.06L8.62 19.74"
                                    stroke="#FFFFFF"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M12 11.79C13.0935 11.79 13.98 10.9035 13.98 9.81C13.98 8.71647 13.0935 7.82999 12 7.82999C10.9065 7.82999 10.02 8.71647 10.02 9.81C10.02 10.9035 10.9065 11.79 12 11.79Z"
                                    stroke="#FFFFFF"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M8.04004 16.74H15.96"
                                    stroke="#FFFFFF"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </g>
                        </g>
                    </svg>
                    {loading ? (
                        <p style={{ marginLeft: 5 }}>Deploying...</p>
                    ) : (
                        <p style={{ marginLeft: 5 }}>Deploy Link</p>
                    )}
                </Button>
                {openDeployModal && (
                    <DeploySussesModal
                        visible={openDeployModal}
                        toggleVisible={toggleOpenDeployModal}
                    />
                )}
            </ButtonWrapper>
        </Tooltip>
    )
}

export default DeployButton

const rocketAnimation = keyframes`
    0% {
      transform: rotate(49deg 18px 17px);
    }
    25% {
      transform: rotate(35deg) translateX(6px);
    }
    50% {
      transform: translateY(-45px) translateX(6px);
    }
    60% {
      transform: translateY(30px) translateX(6px);
    }
    70% {
      opacity: 0;
    }
    90% {
      transform: translateY(0px) translateX(6px);
      opacity: 1;
    }
    100% {
      transform: rotate(45deg 18px 17px);
    }
`
const loadDots = keyframes`
  to {
    clip-path: inset(0 -1ch 0 0)
  }
`

const ButtonWrapper = styled.div<{
    loadingStatus: boolean
}>`
    pointer-events: ${(props) => (props.loadingStatus ? 'none' : 'auto')};

    .hidden {
        display: none;
    }

    .button-deploy {
        transition: all 0.5s cubic-bezier(0.68, 0.15, 0.31, 1.05);
        background-color: ${(props) => props.loadingStatus && '#adadad'};
        cursor: pointer;
        .button-deploy__logo--complete {
            animation: ${(props) =>
                props.loadingStatus
                    ? css`
                          ${rocketAnimation} 2s cubic-bezier(0.68, 0.15, 0.31, 1.05) infinite
                      `
                    : 'none'};
        }
        .button-deploy__logo {
            width: 24px;
        }
        p {
            margin-right: 5px;
            clip-path: ${(props) => props.loadingStatus && 'inset(0 1ch 0 0)'};
            animation: ${(props) =>
                props.loadingStatus
                    ? css`
                          ${loadDots} 1s steps(3) infinite
                      `
                    : 'none'};
        }
    }
`
