import { Preview } from '../preview';

export function FinalPreview({ campaign, generating, pageTheme }) {

    return (
        <div className="flex flex-col w-full items-center overflow-hidden">
            <Preview 
                generating={generating}
                campaign={campaign}
                pageTheme={pageTheme}
                fontSize="4px"
                height="600px"
                width="500px"
                />
        </div>
    );
}

