import moment from 'moment'
import { capitalizeFirstLetter } from '../../helpers/capitalizeFirstLetter'
import { IFields } from '../../modules/widgets/forms/IForm'

export const sendFormToSlack = async (
    webhookUrl: string,
    fields: IFields[],
    formName: string,
    campaignName: string,
    campaignImage?: string
) => {
    const incomminData = fields.map((field) => {
        if (field.fieldType === 'upload') {
            return {
                type: 'section',
                block_id: field.id,
                text: {
                    type: 'mrkdwn',
                    text: `${capitalizeFirstLetter(field.fieldName)}: *<${field.value[0].url}|${
                        field.value[0].fileName
                    }>*`,
                },
            }
        } else {
            return {
                type: 'section',
                block_id: field.id,
                text: {
                    type: 'mrkdwn',
                    text: `${capitalizeFirstLetter(field.fieldName)}: *${field.value}*`,
                },
            }
        }
    })

    const message = {
        blocks: [
            {
                type: 'header',
                block_id: '122',
                text: {
                    type: 'plain_text',
                    text: `${capitalizeFirstLetter(formName)}`,
                },
            },
            {
                type: 'section',
                text: {
                    type: 'mrkdwn',
                    text: `:calendar: |   *Submited: ${moment().format('LLL')}*  | :calendar: `,
                },
            },
            {
                type: 'divider',
            },
            {
                type: 'section',
                block_id: '12dsds2',
                text: {
                    type: 'mrkdwn',
                    text: `👋 New submission from campaign *${capitalizeFirstLetter(
                        campaignName
                    )}*`,
                },
                accessory: {
                    type: 'image',
                    image_url: campaignImage,
                    alt_text: 'Campaign thumbnail',
                },
            },
            {
                type: 'divider',
            },
            {
                type: 'section',
                text: {
                    type: 'mrkdwn',
                    text: ':pushpin: *VALUE OF FIELDS FOR YOUR INFORMATION*',
                },
            },
            ...incomminData,
        ],
    }

    fetch(webhookUrl, {
        method: 'post',
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
        body: JSON.stringify(message),
    })
        .then((res) => console.log(res))
        .catch((error) => console.log(error))
}
