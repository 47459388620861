import { FC } from 'react'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { Link } from 'react-router-dom'
import { Tooltip } from '@mui/material'

interface Props {
    path: string
}

export const DocsHint: FC<Props> = ({ path }) => {
    return (
        <Tooltip title="View docs" placement="top">
            <span style={{ cursor: 'pointer' }}>
                <Link target="_blank" to={path} style={{ color: 'inherit' }}>
                    <HelpOutlineOutlinedIcon />
                </Link>
            </span>
        </Tooltip>
    )
}
