import { nanoid } from 'nanoid'
import { ISpotify } from '../../../modules/widgets/general/ISpotify'

export class Spotify implements ISpotify {
    constructor(public mode: string) {
        this.settings.mode = mode
    }
    id = nanoid()
    name = 'spotify'
    type = 'spotify'
    category = 'general'
    settings = {
        title: 'frame title',
        uri: 'artist/7z2avKuuiMAT4XZJFv8Rvh?utm_source=generator',
        theme: false,
        mode: 'large',
    }
    appearanceSettings = {
        width: 300,
        height: 380,
        align: 'center',
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 15,
        marginBottom: 15,
        marginLeft: 0,
        marginRight: 0,
        font: {
            family: 'Poppins',
            size: 14,
            weight: '400',
        },
    }
    colourSettings = {
        labelColor: ['#000', '#FFA7E1', '#0426FF'],
    }
}
