import { Divider } from '@mui/material'
import { ChangeEvent, FC, useState } from 'react'
import styled from 'styled-components'
import { InputBox } from '../../../common/InputBox'
import { WidgetSettingContainer } from '../../../Design/WidgetSettingContainer'
import { Margins } from '../../../EditMode/Design'
import { useWidget } from 'widgets-base'

interface Props {
}

const SpotifySetting: FC<Props> = () => {

    const { widget, updateWidget } = useWidget();
    const [error, setError] = useState<string>('')

    const findStartIndex = (value: string): number | undefined => {
        if (!value) {
            return
        }

        const checksWords = ['artist', 'playlist', 'track', 'show', 'album']
        for (let i = 0; i < checksWords.length; i++) {
            const indexOfValue = value.indexOf(checksWords[i])
            if (indexOfValue !== -1) {
                return indexOfValue
            }
        }
    }

    const handleChangeSetting = (e: ChangeEvent<HTMLInputElement>) => {
        setError('')
        let value = e.target.value
        if (e.target.name === 'uri') {
            const startIndex = findStartIndex(value)
            const endIndex = e.target.value.indexOf('?')

            if (!startIndex || endIndex === -1) {
                setError('Incorrect link')
                return
            }

            value = e.target.value.slice(startIndex, endIndex) + '?utm_source=generator'
        }

        updateWidget({
            settings: {
                ...widget.settings,
                [e.target.name]: value,
            },
        });
    }

    return (
        <WidgetSettingContainer padding={18}>
            <InputBox
                label="Frame title"
                value={widget?.settings?.title}
                name="title"
                type="text"
                onChange={handleChangeSetting}
            />
            <URIWrapp>
                <InputBox
                    label="Artist/playlist uri"
                    width="100%"
                    name="uri"
                    onChange={handleChangeSetting}
                    type="text"
                    value={widget?.settings?.uri}
                    error={error}
                />
                <span>
                    Copy and paste the link using your Spotify playlist.
                    <br /> Click three dots -&gt; Share -&gt; Copy link to artist/playlist/track
                </span>
            </URIWrapp>
            <Divider />
            <Margins
                topValue={widget.appearanceSettings.marginTop}
                bottomValue={widget.appearanceSettings.marginBottom}
                leftValue={widget.appearanceSettings.marginLeft}
                rightValue={widget.appearanceSettings.marginRight}                
            />
        </WidgetSettingContainer>
    )
}

export default SpotifySetting

const URIWrapp = styled.div`
    margin-bottom: 18px;

    .input_box {
        margin-bottom: 10px;
    }
    & > span {
        font-weight: 400;
        font-size: 11px;
        color: #717f95;
    }
`
