import validator from 'validator'
import { checkValidEmail, checkValidPhoneNumber } from '../../helpers/validation'
import { FormFields } from '../../modules/widgets/forms/IForm'

export const formFieldCheckRequired = (formState: FormFields): string[] => {
    let errors = []

    if (!formState.fields || !formState.formId) {
        errors.push('Please test this form in a saved or deployed campaign.')
    }

    if (formState.fields.length === 0) {
        errors.push(`Form is empty. Please provide input.`)
    }

    formState.fields.map((field) => {
        if (field.required) {
            if (field.fieldType === 'upload' && field.value.length === 0) {
                errors.push(`${field.fieldLabel} is missing a file. Please provide input.`)
                return errors
            } else if (field.fieldType === 'checkbox') {
                if (field.value.length === 0) {
                    errors.push(`${field.fieldLabel} is missing. Please provide input.`)
                    return errors
                }
            } else if (field.fieldType !== 'upload') {
                const type = typeof field.value
                if (type === 'string') {
                    if (
                        validator.isEmpty(field.value) ||
                        field.value === '' ||
                        field.value === undefined
                    ) {
                        errors.push(`${field.fieldLabel} is missing. Please provide input.`)
                        return errors
                    }
                }
            }
        }

        if (field.inputType === 'Email' && !checkValidEmail(field.value)) {
            errors.push(
                `${field.fieldLabel} is not a valid email address. Please enter a valid email`
            )
            return errors
        }

        if (field.inputType === 'Phone Number' && !checkValidPhoneNumber(field.value)) {
            errors.push(
                `${field.fieldLabel} is not a valid phone number. Please enter a valid phone number`
            )
            return errors
        }
    })

    return errors
}
