import styled from 'styled-components'

export const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 100vh;

    @media (max-width: 600px) {
        grid-template-columns: 1fr;
    }
`

export const LeftContainer = styled.section`
    padding: 2rem;
`

export const ForgotPassContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    justify-content: center;

    h1 {
        text-align: center;
    }
`
export const ForgotPassFooter = styled.div`
    margin-top: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: ${(props) => props.theme.text.light};
    font-size: 11px;
    padding-top: 0.5rem;

    a {
        color: ${(props) => props.theme.colors.primary};
    }
`
