import { Divider } from '@mui/material'
import { ChangeEvent, FC } from 'react'
import { InputBox } from '../../../common/InputBox'
import { WidgetSettingContainer } from '../../../Design/WidgetSettingContainer'
import { Alignment, Colors, Fonts, Margins, Sizer, Width } from '../../../EditMode/Design'
import LinkActionSetting from '../../../EditMode/Settings/LinkActionSetting'
import { useWidget } from 'widgets-base'

interface Props {
}

const LinkSetting: FC<Props> = () => {

    const { widget, setWidget } = useWidget();

    const onChangeLinkName = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const newWidget = {
            ...widget,
            settings: {
                ...widget.settings,
                link: {
                    ...widget.settings.link,
                    linkTitle: value,
                },
            },
        }

        setWidget(newWidget);
    }

    return (
        <WidgetSettingContainer padding={18}>
            <InputBox
                type="text"
                label="Link name"
                value={widget?.settings?.link?.linkTitle}
                onChange={onChangeLinkName}
                name="linkTitle"
            />
            <LinkActionSetting action={widget?.settings?.link} />
            <Divider />
            <div style={{ display: 'flex', gap: '10px' }}>
                <Fonts
                    label="Select font"
                    defaultFont={widget?.appearanceSettings?.font?.family}
                    
                />
                <Sizer
                    
                    label="Font size"
                    value={widget?.appearanceSettings?.font?.size}
                    name="size"
                />
            </div>
            <Alignment  align={widget?.appearanceSettings?.align} />
            <Width  width={widget?.appearanceSettings?.width} />
            <Margins
                topValue={widget?.appearanceSettings?.marginTop}
                bottomValue={widget?.appearanceSettings?.marginBottom}
                leftValue={widget?.appearanceSettings?.marginLeft}
                rightValue={widget?.appearanceSettings?.marginRight}
                
            />
            <Divider />
            <Colors colors={widget?.colourSettings}  />
        </WidgetSettingContainer>
    )
}

export default LinkSetting
