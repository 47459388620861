import { AxiosResponse } from 'axios'
import instance from '../lib/http'
import { dedupWidgets } from '../builder/context/campaign';
import { ISerializedProject } from '../lib/deploy';
import { ICampaign } from 'widgets-base';

export default class CampaignsService {
    static async getCampaigns(): Promise<AxiosResponse<ICampaign[]>> {
        const response = await instance.get('/getCampaigns');
        response.data = response.data.map((campaign: ICampaign) => dedupWidgets(campaign));
        return response;
    }

    static async getTotalViews(): Promise<AxiosResponse> {
        return instance.get('/getTotalViews')
    }

    static async getCampaign(campaignId: string): Promise<AxiosResponse> {
        return instance.post('/getCampaign', { campaignId })
    }

    static async deleteCampaign(
        campaignId: string,
        campaignName: string,
        username: string
    ): Promise<AxiosResponse> {
        return instance.post('/deleteCampaign', { campaignId, campaignName, username })
    }

    static async saveCampaign(data: ICampaign): Promise<AxiosResponse> {
        return instance.post('/saveCampaign', { data })
    }

    static async deployCampaign(data: ICampaign): Promise<AxiosResponse> {
        return instance.post('/deploy', { data })
    }

    static async deployStatic(campaignId: string, project: ISerializedProject, campaign: ICampaign): Promise<AxiosResponse> {
        return instance.post('/deploy-static', { campaignId, project, campaign });
    }

    static async updateScans(uid: string, campaignId: string): Promise<AxiosResponse> {
        return instance.post('/updateScans', { uid, campaignId })
    }

    static async getLiveCampaign(username: string, campaignName: string): Promise<AxiosResponse> {
        return instance.post('/getLiveCampaign', { username, campaignName })
    }

    static async getFreePublicCampaign(campaignId: string): Promise<AxiosResponse> {
        return instance.post('/getPublicCampaign', { campaignId })
    }

    static async makePrimaryLink(username: string, campaignId: string): Promise<AxiosResponse> {
        return instance.post('/makePrimaryLink', { username, campaignId })
    }

    static async enableRedirect(campaignId: string, redirectUrl: string): Promise<AxiosResponse> {
        return instance.post('/enable-redirect', { campaignId, redirectUrl });
    }

    static async disableRedirect(campaignId: string): Promise<AxiosResponse> {
        return instance.post('/disable-redirect', { campaignId });
    }
}
