import { FC } from 'react'
import { WidgetSettingContainer } from '../../../Design/WidgetSettingContainer'
import SidebarTabs from '../../../EditMode/SidebarTabs'
import FormDesignSetting from '../FormDesignSetting'
import SubscribeGeneralSetting from './SubscribeGeneralSetting'

interface Props {
}

const SubscribeFormSetting: FC<Props> = () => {
    return (
        <WidgetSettingContainer padding={0}>
            <SidebarTabs
                setting={<SubscribeGeneralSetting />}
                design={<FormDesignSetting />}
            />
        </WidgetSettingContainer>
    )
}

export default SubscribeFormSetting
