import { ReactNode, useState } from "react";
import { useCampaign } from "./campaign";
import { findTreeItem } from "../lib/utils";
import { IWidget, useWidget, IExpandedPropertyBag, expandProperties, IWidgetHook, WidgetContext, IWidgetAPI } from "widgets-base";
import { getWidgetType } from "../widget/widget-types";
import http from "../../lib/http";

export interface IWidgetContextProviderProps {
    children: ReactNode | ReactNode[];

    //
    // Specifies the current widget.
    //
    widget: IWidget;

    //
    // The path to the widget within the widget tree.
    //
    widgetPath?: number[];

    //
    // Set to true to disable dragging this widget.
    //
    disableDrag?: boolean;

    //
    // Set to true to disable dropping into this widget.
    //
    disableDrop?: boolean;
}

export function WidgetContextProvider({ children, widget, widgetPath, disableDrag, disableDrop }: IWidgetContextProviderProps) {
    
    const parentWidget = useWidget();

    const { campaign, currentPageIndex, setWidget: _setWidgetCampaign, updateWidget: _updateWidgetCampaign } = useCampaign();
    const [ properties, _setProperties ] = useState<any>(widget.properties);
    const [ expandedProperties, _setExpandedProperties ] = useState<IExpandedPropertyBag>(expandProperties(widget.properties));

    //
    // Determines the current widgets path into the tree.
    //
    function determineWidgetPath() {
        if (widgetPath !== undefined) {
            // We already know the widget path.
            return widgetPath;
        }

        // Figure out the widget path on the fly.
        const widgets = campaign.views[currentPageIndex].widgets;
        const result = findTreeItem(widgets, w => w.id === widget.id); //TODO: Could elminate this search by tracking the path of the widget.
        if (!result) {
            throw new Error(`Failed to find widget in tree! ${widget.id}`);
        }

        return result.path;
    }

    //
    // Sets the entire data for the widget.
    //
    function setWidget(newWidget: IWidget): void {
        _setWidgetCampaign(determineWidgetPath(), newWidget)
    }

    //
    // Updates specifed fields of the widget.
    //
    function updateWidget(newFields: Partial<IWidget>): void {
        _updateWidgetCampaign(determineWidgetPath(), widget, newFields);
    }

    //
    // Sets the properties of the widget.
    //
    function setProperties(newProperties: any): void {
        updateWidget({
            properties: newProperties,
        });

        _setProperties(newProperties);
        _setExpandedProperties(expandProperties(newProperties));
    }

    //
    // Make an API the widget can use to talk to the backend.
    //
    function makeWidgetApi(widget: IWidget): IWidgetAPI { //todo: This code duplicated from campaign.tsx.
        const api: IWidgetAPI = {
            //
            // Get data from the backend.
            // Automatically routed under the widgets endpoint.
            //
            async get<ResultT = any>(path: string): Promise<ResultT> {
                const fullRoute = `widgets/${widget.xtype}${path}`;
                const { data } = await http.get(fullRoute);
                return data;                
            },

            //
            // Post data to the backend.
            // Automatically routed under the widgets endpoint.
            //
            async post<ResultT = any, PayloadT = any>(path: string, payload: PayloadT): Promise<ResultT> {
                const fullRoute = `widgets/${widget.xtype}${path}`;
                const { data } = await http.post(fullRoute, payload);
                return data;
            },
        };
        
        return api;
    }
        
    //
    // Update particular properties.
    //
    function updateProperties(newProperties: any): void {

        console.log(`Updating properties for widget ${widget.id} to:`);
        console.log(newProperties);

        //
        // Allow widget type to set dependent properties.
        //
        const widgetType = getWidgetType(widget.xtype);
        if (widgetType) {
            function addAdditionalProperties(additionalProperties: any) {
                console.log(`Adding addition properties to widget ${widget.id}:`);
                console.log(additionalProperties);

                newProperties = {
                    ...newProperties,
                    ...additionalProperties,
                };
            }

            if (widgetType.onPropertyUpdate) {
                for (const [propertyId, propertyValue] of Object.entries(newProperties)) {
                    widgetType.onPropertyUpdate(widget, campaign, propertyId, propertyValue, addAdditionalProperties, makeWidgetApi(widget))
                        .catch(error => {
                            console.log(`Failed to update property ${propertyId} for widget ${widget.id} / ${widget.xtype}`);
                            console.error(error);
                        });
                }
            }
        }
        
        setProperties({
            ...properties,
            ...newProperties,
        });

    }

    function isParentGrouped(): boolean {
        return parentWidget ? parentWidget.isGrouped() : false;
    }

    function isGrouped(): boolean {
        return widget.grouped || isParentGrouped();
    }

    //
    // Get ancestors of the current widget.
    //
    function getAncestors(): IWidget[] {
        return parentWidget ? [parentWidget.widget].concat(parentWidget.getAncestors()) : [];
    }

    const value: IWidgetHook = {
        widget, 
        disableDrop: disableDrop || parentWidget?.disableDrop || false,
        disableDrag: disableDrag || parentWidget?.disableDrag || false,
        parentWidget: parentWidget?.widget,
        isGrouped,
        isParentGrouped,
        hasParent: () => parentWidget ? true : false,
        getAncestors,
        widgetPath,
        setWidget,
        updateWidget,
        properties,
        expandedProperties,
        setProperties,
        updateProperties,
    };
    
    return (
        <WidgetContext.Provider 
            value={value}
        >
            {children}
        </WidgetContext.Provider>
    );
}
