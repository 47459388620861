import React, { useEffect, useState } from "react";
import { PropertyContextProvider, useProperty } from "../context/property";
import { getWidgetName, getWidgetType } from "../widget/widget-types";
import { propertyTypes } from "./property-types";
import ClearIcon from '@mui/icons-material/Clear';
import { Dialog, DialogContent, DialogTitle, IconButton, TextField, Tooltip } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import { useWidget, IProperty, IPropertyCategory } from "widgets-base";

interface IAdvancedStylingEditorProps {
    //
    // Set to true to open the dialog.
    //
    open: boolean;

    //
    // Event raised when the dialog is closed.
    //
    onClose(): void;
}

//
// Allows editing of advanced styles.
//
function AdvancedStylingEditor({ open, onClose }: IAdvancedStylingEditorProps) {

    const { properties, setProperties } = useWidget();
    const [ text, setText ] = useState(JSON.stringify(properties, null, 4));
    const [ error, setError ] = useState<boolean>(false);

    useEffect(() => {
        setText(JSON.stringify(properties, null, 4));
        setError(false);
    }, [properties]);

    useEffect(() => {
        try {
            if (open && JSON.stringify(properties, null, 4) !== text) {
                setProperties(JSON.parse(text));
                setError(false);
            }
        }
        catch (err) {
            console.error(`Failed to parse JSON`);
            console.error(err);
            setError(true);
        }
    }, [text]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth={true}
            maxWidth="md"
            sx={{
                zIndex: 5000,
            }}
        >
            <DialogTitle>
                Advanced styling

                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <TextField
                    error={error}
                    fullWidth
                    autoCorrect="off"
                    spellCheck="false"
                    value={text}
                    multiline
                    onChange={event => {
                        setText(event.target.value);
                    }}
                />
            </DialogContent>
        </Dialog>
    );
}

//
// Allows the widget name to be edited.
//
function WidgetNameInput() {

    const { widget, updateWidget } = useWidget();

    const [open, setOpen] = useState(false);

    let style: any = {
        color: "rgb(60, 75, 97)",
    };

    style.fontSize = "12px";
    style.lineHeight = "12px";

    return (
        <div
            className="property-editor property-name flex flex-row items-start p-4 pb-0 pt-3"
            style={style}
        >
            <div className="flex flex-col flex-grow">
                <div className="mb-2">Name</div>
                <input
                    className="w-full"
                    style={{
                        fontFamily: "Matter, \"Helvetica Neue\", Helvetica, Arial, sans-serif",
                        borderRadius: "4px",
                        border: "1px solid rgb(223, 226, 242)",
                        color: "rgb(113, 127, 149)",
                        padding: "5px 6px",
                        fontSize: "14px",
                        maxWidth: "200px",
                    }}
                    value={getWidgetName(widget, "")}
                    onChange={event => {
                        updateWidget({ name: event.target.value });
                    }}
                />
            </div>

            <IconButton
                size="small"
                sx={{
                    padding: 0,
                    margin: 0,
                    marginLeft: "8px",
                }}
                onClick={() => {
                    setOpen(true);
                }}
            >
                <SettingsIcon />
            </IconButton>

            <AdvancedStylingEditor 
                open={open}
                onClose={() => setOpen(false)}
                />
        </div>
    );
}

//
// Edits a single property.
//
export function PropertyEditor() {

    const { property, clearPropertyValue } = useProperty();

    const propertyType = propertyTypes[property.type];
    if (!propertyType) {
        console.error(`No property type "${property.type}"`);
        return;
    }

    const propertyTypeEditor = propertyType.editor;
    if (!propertyTypeEditor) {
        console.error(`No property editor for "${property.type}"`);
        return;
    }

    if (property.orientation === "horizontal") {
        let style: any = {
            color: "rgb(60, 75, 97)",
        };

        style.fontSize = "12px";
        style.lineHeight = "12px";

        return (
            <div
                className={`property-editor property-${property.id} flex flex-row items-center`}
                style={style}
            >
                <div className={`w-24 mb-1`}>{property.name}</div>
                <div className="flex flex-row flex-grow ml-1">
                    {propertyTypeEditor && propertyTypeEditor(property.config || {})}
                </div>

                {!property.required &&
                    <Tooltip
                        title="Clears the property, resetting it to the default"
                        placement="left-start"
                        PopperProps={{
                            style: {
                                zIndex: 8000,
                            },
                        }}
                        arrow
                    >
                        <IconButton
                            size="small"
                            onClick={() => {
                                clearPropertyValue();
                            }}
                        >
                            <ClearIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                }
            </div>
        );
    }
    else {
        let style: any = {
            color: "rgb(60, 75, 97)",
        };

        style.fontSize = "12px";
        style.lineHeight = "12px";

        return (
            <div className="flex flex-row items-end mt-2 mb-1">
                <div
                    className={`property-editor property-${property.id} flex flex-col items-start w-full`}
                    style={style}
                >
                    <div className={`w-24 mb-1`}>{property.name}</div>
                    <div className={"flex flex-col flex-grow w-full mt-1"}>
                        {propertyTypeEditor && propertyTypeEditor(property.config || {})}
                    </div>
                </div>

                {!property.required &&
                    <Tooltip
                        title="Clears the property, resetting it to the default"
                        placement="left-start"
                        PopperProps={{
                            style: {
                                zIndex: 8000,
                            },
                        }}
                        arrow
                    >
                        <IconButton
                            size="small"
                            onClick={() => {
                                clearPropertyValue();
                            }}
                        >
                            <ClearIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                }
            </div>
        );
    }
}

function renderProperties(properties: IProperty<any>[]) {

    return properties.map((property, index) => {
        return (
            <PropertyContextProvider
                key={index}
                property={property}
            >
                <PropertyEditor />
            </PropertyContextProvider>
        );
    });
}

function renderPropertyCategories(propertyCategories: IPropertyCategory[]) {

    return propertyCategories.map((category, index) => {
        return (
            <div
                key={`${category.name}-${index}`}
                className="flex flex-col"
                style={{
                    borderBottom: "1px solid #dfe2f2",
                    padding: "18px 18px 22px 18px",
                }}
            >
                {renderProperties(category.properties)}
            </div>
        );
    });
}

export function PropertiesEditor() {

    const { widget } = useWidget();

    const widgetType = getWidgetType(widget.xtype);
    if (!widgetType) {
        console.error(`No type for widget "${widget.xtype}"`);
        return;
    }

    return (
        <>
            <div className="text-sm font-normal flex flex-col w-full">
                <WidgetNameInput />
            </div>

            {widgetType.properties &&
                <div
                    className="text-sm font-normal flex flex-col w-full"
                    style={{
                        borderBottom: "1px solid #dfe2f2",
                        padding: "18px 18px 22px 18px",
                    }}
                >
                    {renderProperties(widgetType.properties)}
                </div>
            }

            {widgetType.propertyCategories &&
                <div className="text-sm font-normal flex flex-col w-full">
                    {renderPropertyCategories(widgetType.propertyCategories)}
                </div>
            }
        </>
    );
}
