import { FC, SetStateAction } from 'react'
import styled from 'styled-components'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useWidget } from 'widgets-base'

interface Props {
    value: string
}

const TextEditor: FC<Props> = ({ value = '' }) => {

    const { widget, setWidget } = useWidget();

    const handleEditorChange = (state: SetStateAction<string>) => {
        const newWidget = {
            ...widget,
            settings: {
                content: state,
            },
        }

        setWidget(newWidget);
    }

    return (
        <EditorContainer>
            <label>Edit text</label>
            <ReactQuill theme="snow" value={value} onChange={handleEditorChange} />
        </EditorContainer>
    )
}

export default TextEditor

const EditorContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    & > label {
        font-size: 12px;
        color: #3c4b61;
        margin-bottom: 7px;
        font-weight: bold;
    }

    .ql-toolbar {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .ql-container {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
`
