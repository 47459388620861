import { FC } from 'react'
import styled from 'styled-components'
import { Toast } from '../../helpers/Toast'
import { Button } from '../common/Button'
import { CustomModal } from '../common/CustomModal'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { useNavigate } from 'react-router-dom'
import { RouteNames } from '../../../router'
import { DocsHint } from '../Docs/DocsHint'

interface Props {
    openModal: boolean
    toggleModalOpen: () => void
    iframeCode: string
    buttonCode?: string
    isDraw?: boolean
}

const EmbedModal: FC<Props> = ({
    openModal,
    toggleModalOpen,
    iframeCode,
    buttonCode
}) => {
    const navigate = useNavigate()

    const fallbackCopyTextToClipboard = (code: string) => {
        let textArea = document.createElement('textarea')
        textArea.value = code

        // Avoid scrolling to bottom
        textArea.style.top = '0'
        textArea.style.left = '0'
        textArea.style.position = 'fixed'

        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()

        let successful = document.execCommand('copy')
        if (successful) {
            Toast(`Code copy to clipboard`, 'success')
        } else {
            Toast('Oops, unable to copy ', 'error')
        }
        document.body.removeChild(textArea)
    }

    const copyToClipboard = (code: string) => () => {
        if (navigator.clipboard !== undefined) {
            navigator.clipboard
                .writeText(code)
                .then(() => {
                    Toast(`Code copy to clipboard`, 'success')
                })
                .catch((err) => {
                    console.log(err)
                    Toast('Error copying link', 'error')
                })
        } else {
            fallbackCopyTextToClipboard(code)
        }
    }

    return (
        <CustomModal title="Embed modal" visible={openModal} toggleVisible={toggleModalOpen}>
            <EmbedModalContent>
                <div className="embed__header">
                    <h1>
                        Embed code in your page
                        <DocsHint path={RouteNames.DOC_EMBED_CAMPAIGN} />
                    </h1>
                    <span onClick={toggleModalOpen}>
                        <CloseOutlinedIcon fontSize="medium" />
                    </span>
                </div>
                <span>
                    Use this code to embed link of your campaign or your draws to your own landing
                    page or website
                </span>
                <div className="code-container">
                    <p>Copy this html code</p>
                    <div className="copy-field">
                        <span>{iframeCode}</span>
                    </div>
                    <Button width="100%" variant="invert" onClick={copyToClipboard(iframeCode)}>
                        COPY
                    </Button>
                </div>
                <span>
                    Use this html code to embed a button with a link to your campaign
                </span>
                <div className="code-container">
                    <p>Copy this html code</p>
                    <div className="copy-field">
                        <span>{buttonCode}</span>
                    </div>
                    <Button
                        width="100%"
                        variant="invert"
                        onClick={copyToClipboard(buttonCode)}
                    >
                        COPY
                    </Button>
                </div>
            </EmbedModalContent>
        </CustomModal>
    )
}

export default EmbedModal

const EmbedModalContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 25px;
    background-color: #fff;
    border-radius: 10px;
    max-width: 550px;
    width: 100%;
    gap: 25px;

    & > span {
        font-size: 13px;
        color: #a0a0a0;
    }

    .embed__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h1 {
            font-size: 28px;
            font-weight: bold;
            position: relative;

            span {
                font-size: 14px;
                border-radius: 50%;
                background-color: #cacaca;
                color: #fff;
                width: 20px;
                height: 20px;
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                right: -25px;
                top: 0;
            }
        }

        & > span {
            cursor: pointer;
        }
    }

    .code-container {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;
        font-size: 14px;
        font-weight: bold;
        color: #3c4b61;

        .copy-field {
            background-color: #fafaff;
            border-radius: 10px;
            border: 1px solid #dfe2f2;
            color: #717f95;
            padding: 10px 12px;
            font-size: 14px;
            width: 100%;
            font-weight: 400;
        }
    }
`
