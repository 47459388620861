import { Popover } from '@mui/material'
import { useState, MouseEvent, FC } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { RouteNames } from '../../../router'
import { Blue, SoftBlue } from '../../../styles/v2/styles'
import Icon from '../common/Icons'
import EmbedModal from './EmbedModal'
import { ICampaign, UserRoles, useAuth } from 'widgets-base'

interface Props {
    data: ICampaign;
    deleteCampaign: (data: ICampaign) => Promise<void>;
    makePrimaryLink: (campaignId: string, campaignName: string, username?: string) => Promise<void>;
}

const PopOver: FC<Props> = ({ data, deleteCampaign, makePrimaryLink }) => {
    
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const [openEmbedModal, setOpenEmbedModal] = useState<boolean>(false)
    const { currentUser } = useAuth()

    const toggleEmbedModalOpen = () => {
        setOpenEmbedModal((prev) => !prev)
    }

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClosePopover = () => {
        setAnchorEl(null)
    }

    const onDeleteProject = (data: ICampaign) => () => {
        handleClosePopover()
        deleteCampaign(data)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    const getEditLink = () => {
        return `${RouteNames.BUILDER}/${data._id}`
    }

    const onMakePrimaryLink = () => {
        handleClosePopover()
        makePrimaryLink(data._id, data.campaignName)
    }

    const onEnbedCampaign = () => {
        handleClosePopover()
        toggleEmbedModalOpen()
    }

    const iframeCode = `<iframe width="420" height="520" frameborder="0" allowFullScreen=true src='${`https://live.deploy.link/embed/${data._id}`}'></iframe>`
    const buttonCode = `<div style="text-align: center"><a style="background-color: #6478F9; color: #ffffff; text-decoration: none; font-size: 16px; padding: 10px 16px; border-radius: 4px; line-height: 20px; text-align: center" href="${data.deployUrl}" target="_blank">View campaign</a></div>`

    return (
        <PopOverComponent aria-describedby={id}>
            <Icon size={26} name="dots" onClick={handleClick} />
            <Popover
                id={id}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                style={{ borderRadius: '9px' }}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
            >
                <PopOverItemContainer>
                    <PopOverItem onClick={onMakePrimaryLink}>Make primary link</PopOverItem>
                    <PopOverItem onClick={onDeleteProject(data)}>Delete</PopOverItem>
                    <PopOverItem onClick={handleClosePopover}>
                        <Link to={getEditLink()}>Edit</Link>
                    </PopOverItem>
                    <PopOverItem onClick={handleClosePopover}>
                        <Link to={RouteNames.FORMS}>View Forms</Link>
                    </PopOverItem>
                    {data.status === 'published' && currentUser.role !== UserRoles.FREE && (
                        <PopOverItem onClick={onEnbedCampaign}>Embed</PopOverItem>
                    )}
                </PopOverItemContainer>
            </Popover>
            {currentUser.role !== UserRoles.FREE && (
                <EmbedModal
                    openModal={openEmbedModal}
                    toggleModalOpen={toggleEmbedModalOpen}
                    iframeCode={iframeCode}
                    buttonCode={buttonCode}
                />
            )}
        </PopOverComponent>
    )
}

export default PopOver

const PopOverItemContainer = styled.div`
    padding: 13px 12px 13px 14px;
    background: #ffffff;
    border: 1px solid #e3e7ff;
    box-sizing: border-box;
    background-color: #fff;
`

const PopOverItem = styled.p`
    font-family: 'Matter';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 29px;
    cursor: pointer;
    text-transform: capitalize;
    white-space: pre;
    width: 150px;
    color: ${SoftBlue};
    transition: color 0.2s ease-in-out;
    & > a {
        color: ${SoftBlue};
    }

    &:hover {
        color: ${Blue};
        font-weight: 700;
        & > a {
            color: ${Blue};
            font-weight: 700;
        }
    }
`
const PopOverComponent = styled.div`
    position: relative;
`
