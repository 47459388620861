export const businessCardTemplate: any = {
    "templateName": "Business card",
    "templateThumbnail" : "/images/templates/business-card.png",
    "_id" : "T1NykBwMn3EeltSlKx1vy",
    "userId" : "Hf7F58y0nlVatZxg4L7N6ESu5AL2",
    "uid" : "Hf7F58y0nlVatZxg4L7N6ESu5AL2",
    "fontFamilies" : [
        {
            "family" : "Poppins"
        }
    ],
    "views" : [
        {
            "name" : "Page 1",
            "id" : "NUDWSiqk94tt8J_xjH1lJ",
            "backgroundColour" : "rgb(255,255,255,1)",
            "backgroundImageUrl" : "",
            "widgets" : [
                {
                    "name" : "navbar",
                    "type" : "navbar",
                    "id" : "k03z9BY6HzcoiujK4BsVV",
                    "category" : "general",
                    "properties" : {
                        "alignSelf" : "stretch"
                    },
                    "settings" : {
                        "pages" : [

                        ],
                        "src" : "/images/deployable-logo.png"
                    },
                    "appearanceSettings" : {
                        "width" : 90,
                        "marginTop" : 0,
                        "isBurger" : false,
                        "marginBottom" : 0,
                        "marginLeft" : 0,
                        "marginRight" : 0,
                        "paddingTop" : 0,
                        "paddingBottom" : 0,
                        "paddingLeft" : 0,
                        "paddingRight" : 0,
                        "font" : {
                            "family" : "Poppins",
                            "size" : 14,
                            "weight" : 400
                        }
                    },
                    "colourSettings" : {
                        "background colour" : [
                            "transparent",
                            "transparent",
                            "transparent"
                        ],
                        "text colour" : [
                            "black",
                            "black",
                            "black"
                        ]
                    },
                    "children" : [

                    ],
                    "widgets" : [

                    ]
                },
                {
                    "id" : "eb3ea2037013d202dae275c29ee27177b985cdd367c71f637c0381ba4965870c",
                    "name" : "intro-2",
                    "xtype" : "group",
                    "properties" : {
                        "columnGap" : 12,
                        "rowGap" : 12,
                        "flexDirection" : "column",
                        "justifyContent" : "flex-start",
                        "alignItems" : "center",
                        "paddingBottom" : 24,
                        "paddingLeft" : 18,
                        "paddingRight" : 18,
                        "display" : "flex",
                        "small" : {
                            "backgroundAttachment" : "scroll"
                        }
                    },
                    "children" : [
                        {
                            "id" : "abb05b0e05b0c26c752bba68ea6b843983385b8ee944993bf979b6ef37b6f2ed",
                            "name" : "image-wrapper-8",
                            "xtype" : "group",
                            "properties" : {
                                "justifyContent" : "center",
                                "alignItems" : "center",
                                "display" : "flex",
                                "small" : {
                                    "backgroundAttachment" : "scroll"
                                }
                            },
                            "children" : [
                                {
                                    "id" : "759f573cc36db45c97a3ce2be1ababc25e2b55ea41fb0c81c4886a4428752ab9",
                                    "name" : "pricing-1",
                                    "xtype" : "image",
                                    "properties" : {
                                        "borderTopWidth" : 0,
                                        "borderRightWidth" : 0,
                                        "borderBottomWidth" : 0,
                                        "borderLeftWidth" : 0,
                                        "borderTopStyle" : "initial",
                                        "borderRightStyle" : "initial",
                                        "borderBottomStyle" : "initial",
                                        "borderLeftStyle" : "initial",
                                        "borderTopColor" : "initial",
                                        "borderRightColor" : "initial",
                                        "borderBottomColor" : "initial",
                                        "borderLeftColor" : "initial",
                                        "borderImageSource" : "initial",
                                        "borderImageSlice" : "initial",
                                        "borderImageWidth" : "initial",
                                        "borderImageOutset" : "initial",
                                        "borderImageRepeat" : "initial",
                                        "maxWidth" : "100%",
                                        "verticalAlign" : "middle",
                                        "display" : "flex",
                                        "objectFit" : "cover",
                                        "flexDirection" : "column",
                                        "justifyContent" : "center",
                                        "alignItems" : "center",
                                        "small" : {
                                            "display" : "inline-block",
                                            "backgroundAttachment" : "scroll"
                                        },
                                        "src" : "https://storage.googleapis.com/quicklinks---staging.appspot.com/assets/content/Hf7F58y0nlVatZxg4L7N6ESu5AL2/noun-user-514033.svg",
                                        "alt" : "",
                                        "maxHeight" : "100%",
                                        "borderTopLeftRadius" : 222,
                                        "borderTopRightRadius" : 222,
                                        "borderBottomLeftRadius" : 222,
                                        "borderBottomRightRadius" : 222,
                                        "width" : 225,
                                        "height" : 225,
                                        "aspectRatio" : 1
                                    },
                                    "children" : [

                                    ],
                                    "lastUpdate" : 1694741345287.0,
                                    "widgets" : [

                                    ]
                                }
                            ],
                            "widgets" : [

                            ]
                        },
                        {
                            "id" : "6e61f098df836a7f52fe50833d8eef2ffc764ac259f852d724c7cd3811e1949b",
                            "name" : "name-2",
                            "xtype" : "group",
                            "properties" : {
                                "flexDirection" : "column",
                                "justifyContent" : "flex-start",
                                "alignItems" : "center",
                                "display" : "flex",
                                "small" : {
                                    "backgroundAttachment" : "scroll"
                                }
                            },
                            "children" : [
                                {
                                    "id" : "5fe8a3f44843e0b51ddbf9ea9ad594155df90470a10d08773caedc10f58d1f50",
                                    "name" : "text-33",
                                    "xtype" : "group",
                                    "properties" : {
                                        "fontSize" : 30,
                                        "fontWeight" : "600",
                                        "lineHeight" : "180%",
                                        "small" : {
                                            "backgroundAttachment" : "scroll"
                                        }
                                    },
                                    "children" : [
                                        {
                                            "id" : "1820fba7e152458ba71e94da875de3f18fc47b94967f8fc6d159165e13595142",
                                            "xtype" : "label",
                                            "properties" : {
                                                "content" : "<p>Your Name</p>",
                                                "maxWidth" : "100%"
                                            },
                                            "lastUpdate" : 1694740250700.0,
                                            "children" : [

                                            ],
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "widgets" : [

                                    ]
                                },
                                {
                                    "id" : "93e024996d635c76403417f5f594dc5d75b2e04ac09c24ec6e09b4b999d9532c",
                                    "name" : "text-34",
                                    "xtype" : "group",
                                    "properties" : {
                                        "fontSize" : 10.5,
                                        "textAlign" : "center",
                                        "fontWeight" : "400",
                                        "lineHeight" : "100%",
                                        "small" : {
                                            "backgroundAttachment" : "scroll"
                                        }
                                    },
                                    "children" : [
                                        {
                                            "id" : "eb4eae8e22205f781c320b8ade40afbd84a8a4e4c84c6663997b604d2b79dbe0",
                                            "xtype" : "label",
                                            "properties" : {
                                                "content" : "<p>Job Title</p>",
                                                "maxWidth" : "100%",
                                                "fontSize" : 16
                                            },
                                            "lastUpdate" : 1694740266887.0,
                                            "children" : [

                                            ],
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "widgets" : [

                                    ]
                                }
                            ],
                            "widgets" : [

                            ]
                        },
                        {
                            "id" : "b15afcaa077383c19997b42f33a501c9131890071ee7b5a613c40fb8525714e4",
                            "name" : "pricing",
                            "xtype" : "group",
                            "properties" : {
                                "columnGap" : 6,
                                "rowGap" : 6,
                                "justifyContent" : "center",
                                "alignItems" : "flex-end",
                                "display" : "flex",
                                "small" : {
                                    "backgroundAttachment" : "scroll"
                                }
                            },
                            "children" : [
                                {
                                    "id" : "556f6b86a370cfe211ccd14a00165334272a57a6db352fa7e6dbfb6963a67398",
                                    "name" : "text-35",
                                    "xtype" : "group",
                                    "properties" : {
                                        "fontSize" : 18,
                                        "textAlign" : "center",
                                        "fontWeight" : "700",
                                        "lineHeight" : "100%",
                                        "small" : {
                                            "backgroundAttachment" : "scroll"
                                        }
                                    },
                                    "children" : [
                                        {
                                            "id" : "a7b85cfd4f6887f5dc60977539ceaf3e49f7841aaf019ff8828cdacf6edf8d86",
                                            "xtype" : "label",
                                            "properties" : {
                                                "content" : "<p>Company Name</p>",
                                                "maxWidth" : "100%"
                                            },
                                            "lastUpdate" : 1694740274633.0,
                                            "children" : [

                                            ],
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "widgets" : [

                                    ]
                                }
                            ],
                            "widgets" : [

                            ]
                        },
                        {
                            "id" : "0d8c50ebc9c17941dfee4813d745786bbac3d890616f16c095415db4e7891946",
                            "name" : "description-4",
                            "xtype" : "group",
                            "properties" : {
                                "columnGap" : 7.5,
                                "rowGap" : 7.5,
                                "justifyContent" : "flex-start",
                                "alignItems" : "flex-start",
                                "display" : "flex",
                                "small" : {
                                    "backgroundAttachment" : "scroll"
                                }
                            },
                            "children" : [
                                {
                                    "id" : "a4c0bda805bdfb6b3f7d7044129b109db21a031adb98f79f2a7ba75b74cb064e",
                                    "name" : "text-36",
                                    "xtype" : "group",
                                    "properties" : {
                                        "fontSize" : 12,
                                        "textAlign" : "center",
                                        "fontWeight" : "500",
                                        "lineHeight" : "140%",
                                        "small" : {
                                            "backgroundAttachment" : "scroll"
                                        }
                                    },
                                    "children" : [
                                        {
                                            "id" : "01bc8ba70a71640f8f6e8524333632000b79d9a5f9b09fcdfbdeb6701a7be97a",
                                            "xtype" : "label",
                                            "properties" : {
                                                "content" : "<p>Let's stay in touch</p>",
                                                "maxWidth" : "100%"
                                            },
                                            "lastUpdate" : 1694740295031.0,
                                            "children" : [

                                            ],
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "widgets" : [

                                    ]
                                }
                            ],
                            "widgets" : [

                            ]
                        },
                        {
                            "id" : "2c9a6d63f6b303ad649fd7e63511f1f0b8513c5aa1071cb8cbe0bbe8881584f6",
                            "name" : "button-3",
                            "xtype" : "button",
                            "properties" : {
                                "link" : {
                                    "target" : "url",
                                    "url" : "#",
                                    "page" : 0
                                },
                                "borderTopWidth" : 0.75,
                                "borderRightWidth" : 0.75,
                                "borderBottomWidth" : 0.75,
                                "borderLeftWidth" : 0.75,
                                "borderTopStyle" : "solid",
                                "borderRightStyle" : "solid",
                                "borderBottomStyle" : "solid",
                                "borderLeftStyle" : "solid",
                                "borderTopColor" : "rgb(33, 33, 33)",
                                "borderRightColor" : "rgb(33, 33, 33)",
                                "borderBottomColor" : "rgb(33, 33, 33)",
                                "borderLeftColor" : "rgb(33, 33, 33)",
                                "borderImageSource" : "initial",
                                "borderImageSlice" : "initial",
                                "borderImageWidth" : "initial",
                                "borderImageOutset" : "initial",
                                "borderImageRepeat" : "initial",
                                "justifyContent" : "center",
                                "alignItems" : "center",
                                "paddingTop" : 12,
                                "paddingRight" : 18,
                                "paddingBottom" : 12,
                                "paddingLeft" : 18,
                                "textDecorationLine" : "none",
                                "textDecorationThickness" : "initial",
                                "textDecorationStyle" : "initial",
                                "textDecorationColor" : "initial",
                                "display" : "flex",
                                "small" : {
                                    "backgroundAttachment" : "scroll"
                                }
                            },
                            "children" : [
                                {
                                    "id" : "7a2b12d9a860528354342001c9220b8dec95eb82bc004b55e701995382ff8222",
                                    "name" : "text-38",
                                    "xtype" : "group",
                                    "properties" : {
                                        "fontSize" : 9,
                                        "letterSpacing" : 1.5,
                                        "fontWeight" : "500",
                                        "lineHeight" : "140%",
                                        "small" : {
                                            "backgroundAttachment" : "scroll"
                                        }
                                    },
                                    "children" : [
                                        {
                                            "id" : "16aebaa3e5bc20d307a133022b6c4f64c7f395636d673f550bcfea9b85629008",
                                            "xtype" : "label",
                                            "properties" : {
                                                "content" : "<p>Save Contact</p>",
                                                "maxWidth" : "100%"
                                            },
                                            "lastUpdate" : 1694740305675.0,
                                            "children" : [

                                            ],
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "widgets" : [

                                    ]
                                }
                            ],
                            "widgets" : [

                            ]
                        }
                    ],
                    "widgets" : [

                    ]
                },
                {
                    "id" : "3c297a00a2926dbce761ef8024e7c8d3563f51db86a4ca9aeabfb3e7ab36fd53",
                    "name" : "features-2",
                    "xtype" : "group",
                    "properties" : {
                        "columnGap" : 12,
                        "rowGap" : 12,
                        "flexDirection" : "column",
                        "justifyContent" : "flex-start",
                        "alignItems" : "center",
                        "paddingLeft" : 12,
                        "paddingRight" : 12,
                        "display" : "flex",
                        "small" : {
                            "backgroundAttachment" : "scroll"
                        },
                        "alignSelf" : "center"
                    },
                    "children" : [
                        {
                            "id" : "eacbd0c473710bb3061e25a346a81727aa28c4c401d6a762213857dff098aea7",
                            "name" : "pricing-feature-2",
                            "xtype" : "group",
                            "properties" : {
                                "columnGap" : 6,
                                "rowGap" : 6,
                                "justifyContent" : "flex-start",
                                "alignItems" : "flex-start",
                                "display" : "flex",
                                "small" : {
                                    "backgroundAttachment" : "scroll"
                                }
                            },
                            "children" : [
                                {
                                    "id" : "3891fc0c5a61cf1e655dc28a8527849a62a9c8e56d4167e866964f6c94f0d5e9",
                                    "name" : "icon",
                                    "xtype" : "group",
                                    "properties" : {
                                        "justifyContent" : "center",
                                        "alignItems" : "center",
                                        "display" : "flex",
                                        "small" : {
                                            "backgroundAttachment" : "scroll"
                                        }
                                    },
                                    "children" : [
                                        {
                                            "id" : "8e247b6729de4e2f47ba91e0788c05477f680a249e32ba3e44a218e3e5f7af67",
                                            "name" : "icon-wrapper-2",
                                            "xtype" : "image",
                                            "properties" : {
                                                "borderTopWidth" : 0,
                                                "borderRightWidth" : 0,
                                                "borderBottomWidth" : 0,
                                                "borderLeftWidth" : 0,
                                                "borderTopStyle" : "initial",
                                                "borderRightStyle" : "initial",
                                                "borderBottomStyle" : "initial",
                                                "borderLeftStyle" : "initial",
                                                "borderTopColor" : "initial",
                                                "borderRightColor" : "initial",
                                                "borderBottomColor" : "initial",
                                                "borderLeftColor" : "initial",
                                                "borderImageSource" : "initial",
                                                "borderImageSlice" : "initial",
                                                "borderImageWidth" : "initial",
                                                "borderImageOutset" : "initial",
                                                "borderImageRepeat" : "initial",
                                                "maxWidth" : "100%",
                                                "verticalAlign" : "middle",
                                                "display" : "flex",
                                                "columnGap" : 7.5,
                                                "rowGap" : 7.5,
                                                "objectFit" : "cover",
                                                "justifyContent" : "center",
                                                "alignItems" : "center",
                                                "small" : {
                                                    "display" : "inline-block",
                                                    "backgroundAttachment" : "scroll"
                                                },
                                                "src" : "https://storage.googleapis.com/quicklinks---staging.appspot.com/assets/content/Hf7F58y0nlVatZxg4L7N6ESu5AL2/noun-email-6091093-(1).svg",
                                                "alt" : "",
                                                "maxHeight" : "100%",
                                                "width" : 22,
                                                "height" : 22,
                                                "aspectRatio" : 1,
                                                "alignSelf" : "center"
                                            },
                                            "children" : [

                                            ],
                                            "lastUpdate" : 1694740941075.0,
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "widgets" : [

                                    ]
                                },
                                {
                                    "id" : "a16b22bd909a205cc80969647bb6c9c5250585808e3c1e6d3c824b2550ffcd94",
                                    "name" : "feature-item",
                                    "xtype" : "group",
                                    "properties" : {
                                        "fontWeight" : "400",
                                        "lineHeight" : "150%",
                                        "small" : {
                                            "backgroundAttachment" : "scroll"
                                        }
                                    },
                                    "children" : [
                                        {
                                            "id" : "48e12aa56664f083c8c2e52df06202eaddf4f16a7206507daa46feb33363c10f",
                                            "xtype" : "label",
                                            "properties" : {
                                                "content" : "<p>Name@email.com</p>",
                                                "maxWidth" : "100%"
                                            },
                                            "lastUpdate" : 1694740930097.0,
                                            "children" : [

                                            ],
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "widgets" : [

                                    ]
                                }
                            ],
                            "widgets" : [

                            ]
                        },
                        {
                            "id" : "68c13b94d40b671e849297321a834741b8c524de24afc810bfedc03e2cee7147",
                            "name" : "pricing-feature-2",
                            "xtype" : "group",
                            "properties" : {
                                "columnGap" : 6,
                                "rowGap" : 6,
                                "justifyContent" : "flex-start",
                                "alignItems" : "flex-start",
                                "display" : "flex",
                                "small" : {
                                    "backgroundAttachment" : "scroll"
                                }
                            },
                            "children" : [
                                {
                                    "id" : "6f9bed5e7edcd388d209142cc537ec46d17d8f6851648273912aab5cfcc03cfe",
                                    "name" : "icon",
                                    "xtype" : "group",
                                    "properties" : {
                                        "justifyContent" : "center",
                                        "alignItems" : "center",
                                        "display" : "flex",
                                        "small" : {
                                            "backgroundAttachment" : "scroll"
                                        }
                                    },
                                    "children" : [
                                        {
                                            "id" : "dc22e712f7bf4673810c6011cc5a2d425b1a3a3bcd7703d58c8aace39faeffd6",
                                            "name" : "icon-wrapper-2",
                                            "xtype" : "image",
                                            "properties" : {
                                                "borderTopWidth" : 0,
                                                "borderRightWidth" : 0,
                                                "borderBottomWidth" : 0,
                                                "borderLeftWidth" : 0,
                                                "borderTopStyle" : "initial",
                                                "borderRightStyle" : "initial",
                                                "borderBottomStyle" : "initial",
                                                "borderLeftStyle" : "initial",
                                                "borderTopColor" : "initial",
                                                "borderRightColor" : "initial",
                                                "borderBottomColor" : "initial",
                                                "borderLeftColor" : "initial",
                                                "borderImageSource" : "initial",
                                                "borderImageSlice" : "initial",
                                                "borderImageWidth" : "initial",
                                                "borderImageOutset" : "initial",
                                                "borderImageRepeat" : "initial",
                                                "maxWidth" : "100%",
                                                "verticalAlign" : "middle",
                                                "display" : "flex",
                                                "columnGap" : 7.5,
                                                "rowGap" : 7.5,
                                                "objectFit" : "cover",
                                                "justifyContent" : "center",
                                                "alignItems" : "center",
                                                "small" : {
                                                    "display" : "inline-block",
                                                    "backgroundAttachment" : "scroll"
                                                },
                                                "src" : "https://storage.googleapis.com/quicklinks---staging.appspot.com/assets/content/Hf7F58y0nlVatZxg4L7N6ESu5AL2/noun-location-6086843.svg",
                                                "alt" : "",
                                                "maxHeight" : "100%",
                                                "width" : 22,
                                                "height" : 22,
                                                "aspectRatio" : 1
                                            },
                                            "children" : [

                                            ],
                                            "lastUpdate" : 1694741005103.0,
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "widgets" : [

                                    ]
                                },
                                {
                                    "id" : "c19b1e23bd075658475f5da3f4651bbf8b3ee5e15a5516e993e2fb5eacfa8014",
                                    "name" : "feature-item",
                                    "xtype" : "group",
                                    "properties" : {
                                        "fontWeight" : "400",
                                        "lineHeight" : "150%",
                                        "small" : {
                                            "backgroundAttachment" : "scroll"
                                        }
                                    },
                                    "children" : [
                                        {
                                            "id" : "2fd624972961d2e675125475cde3347a0f08cee95c9f4ac9fa57d9296bd29a76",
                                            "xtype" : "label",
                                            "properties" : {
                                                "content" : "<p>Office Address</p>",
                                                "maxWidth" : "100%"
                                            },
                                            "lastUpdate" : 1694741017262.0,
                                            "children" : [

                                            ],
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "widgets" : [

                                    ]
                                }
                            ],
                            "widgets" : [

                            ]
                        },
                        {
                            "id" : "4cf503c02d3bb5c08c52fe48df42beffe41a87a3a03a80e4da4195b468dfe036",
                            "name" : "pricing-feature-2",
                            "xtype" : "group",
                            "properties" : {
                                "columnGap" : 6,
                                "rowGap" : 6,
                                "justifyContent" : "flex-start",
                                "alignItems" : "flex-start",
                                "display" : "flex",
                                "small" : {
                                    "backgroundAttachment" : "scroll"
                                }
                            },
                            "children" : [
                                {
                                    "id" : "dd96ee0f6e0c0c234b32ff82fbe64d75d6496c54b91360e85233bd5b76360eb2",
                                    "name" : "icon",
                                    "xtype" : "group",
                                    "properties" : {
                                        "justifyContent" : "center",
                                        "alignItems" : "center",
                                        "display" : "flex",
                                        "small" : {
                                            "backgroundAttachment" : "scroll"
                                        }
                                    },
                                    "children" : [
                                        {
                                            "id" : "b3057a58677b0a8190fdbd453d1d5700b72474e2d3bb81482c8ba97cab53b75d",
                                            "name" : "icon-wrapper-2",
                                            "xtype" : "image",
                                            "properties" : {
                                                "borderTopWidth" : 0,
                                                "borderRightWidth" : 0,
                                                "borderBottomWidth" : 0,
                                                "borderLeftWidth" : 0,
                                                "borderTopStyle" : "initial",
                                                "borderRightStyle" : "initial",
                                                "borderBottomStyle" : "initial",
                                                "borderLeftStyle" : "initial",
                                                "borderTopColor" : "initial",
                                                "borderRightColor" : "initial",
                                                "borderBottomColor" : "initial",
                                                "borderLeftColor" : "initial",
                                                "borderImageSource" : "initial",
                                                "borderImageSlice" : "initial",
                                                "borderImageWidth" : "initial",
                                                "borderImageOutset" : "initial",
                                                "borderImageRepeat" : "initial",
                                                "maxWidth" : "100%",
                                                "verticalAlign" : "middle",
                                                "display" : "flex",
                                                "columnGap" : 7.5,
                                                "rowGap" : 7.5,
                                                "objectFit" : "cover",
                                                "justifyContent" : "center",
                                                "alignItems" : "center",
                                                "small" : {
                                                    "display" : "inline-block",
                                                    "backgroundAttachment" : "scroll"
                                                },
                                                "src" : "https://storage.googleapis.com/quicklinks---staging.appspot.com/assets/content/Hf7F58y0nlVatZxg4L7N6ESu5AL2/noun-phone-6093795.svg",
                                                "alt" : "",
                                                "maxHeight" : "100%",
                                                "width" : 22,
                                                "height" : 22,
                                                "aspectRatio" : 1
                                            },
                                            "children" : [

                                            ],
                                            "lastUpdate" : 1694741063672.0,
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "widgets" : [

                                    ]
                                },
                                {
                                    "id" : "23d745af61043d2288cab1dfb89c01ba316e652c8c997d97da4aae83b6109837",
                                    "name" : "feature-item",
                                    "xtype" : "group",
                                    "properties" : {
                                        "fontWeight" : "400",
                                        "lineHeight" : "150%",
                                        "small" : {
                                            "backgroundAttachment" : "scroll"
                                        }
                                    },
                                    "children" : [
                                        {
                                            "id" : "bd8588ac8965adbcb5deaf06be426f042b02b414397304f3f82b79644c168837",
                                            "xtype" : "label",
                                            "properties" : {
                                                "content" : "<p>Phone Number</p>",
                                                "maxWidth" : "100%"
                                            },
                                            "lastUpdate" : 1694741070976.0,
                                            "children" : [

                                            ],
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "widgets" : [

                                    ]
                                }
                            ],
                            "widgets" : [

                            ]
                        },
                        {
                            "id" : "627ee8055ec80440a49222ab8426a99456541198620ebfee4770a4e8a90aa3db",
                            "name" : "pricing-feature-2",
                            "xtype" : "group",
                            "properties" : {
                                "columnGap" : 6,
                                "rowGap" : 6,
                                "justifyContent" : "flex-start",
                                "alignItems" : "flex-start",
                                "display" : "flex",
                                "small" : {
                                    "backgroundAttachment" : "scroll"
                                }
                            },
                            "children" : [
                                {
                                    "id" : "69d2e7c31cdbbad363fef1d8cd639b1f6fb24cd19a8770318fef307ef762ca39",
                                    "name" : "icon",
                                    "xtype" : "group",
                                    "properties" : {
                                        "justifyContent" : "center",
                                        "alignItems" : "center",
                                        "display" : "flex",
                                        "small" : {
                                            "backgroundAttachment" : "scroll"
                                        }
                                    },
                                    "children" : [
                                        {
                                            "id" : "e2f2ef1f8c116955a180f5e57ec47852b4033668bc3c0a34ffe4df2074f7a10c",
                                            "name" : "icon-wrapper-2",
                                            "xtype" : "image",
                                            "properties" : {
                                                "borderTopWidth" : 0,
                                                "borderRightWidth" : 0,
                                                "borderBottomWidth" : 0,
                                                "borderLeftWidth" : 0,
                                                "borderTopStyle" : "initial",
                                                "borderRightStyle" : "initial",
                                                "borderBottomStyle" : "initial",
                                                "borderLeftStyle" : "initial",
                                                "borderTopColor" : "initial",
                                                "borderRightColor" : "initial",
                                                "borderBottomColor" : "initial",
                                                "borderLeftColor" : "initial",
                                                "borderImageSource" : "initial",
                                                "borderImageSlice" : "initial",
                                                "borderImageWidth" : "initial",
                                                "borderImageOutset" : "initial",
                                                "borderImageRepeat" : "initial",
                                                "maxWidth" : "100%",
                                                "verticalAlign" : "middle",
                                                "display" : "flex",
                                                "columnGap" : 7.5,
                                                "rowGap" : 7.5,
                                                "objectFit" : "cover",
                                                "justifyContent" : "center",
                                                "alignItems" : "center",
                                                "small" : {
                                                    "display" : "inline-block",
                                                    "backgroundAttachment" : "scroll"
                                                },
                                                "src" : "https://storage.googleapis.com/quicklinks---staging.appspot.com/assets/content/Hf7F58y0nlVatZxg4L7N6ESu5AL2/noun-website-6098518.svg",
                                                "alt" : "",
                                                "maxHeight" : "100%",
                                                "width" : 22,
                                                "height" : 22,
                                                "aspectRatio" : 1
                                            },
                                            "children" : [

                                            ],
                                            "lastUpdate" : 1694741159023.0,
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "widgets" : [

                                    ]
                                },
                                {
                                    "id" : "38cfdfad433970708d1498b63cfa5a73257dc8c67921e4584a8948d32735993e",
                                    "name" : "feature-item",
                                    "xtype" : "group",
                                    "properties" : {
                                        "fontWeight" : "400",
                                        "lineHeight" : "150%",
                                        "small" : {
                                            "backgroundAttachment" : "scroll"
                                        }
                                    },
                                    "children" : [
                                        {
                                            "id" : "211fcb68f18b40a7b7a555e088d7f4f20985d2711a11a9c45c30ac0d44dfb0bf",
                                            "xtype" : "label",
                                            "properties" : {
                                                "content" : "<p>Website</p>",
                                                "maxWidth" : "100%"
                                            },
                                            "lastUpdate" : 1694741171796.0,
                                            "children" : [

                                            ],
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "widgets" : [

                                    ]
                                }
                            ],
                            "widgets" : [

                            ]
                        }
                    ],
                    "lastUpdate" : 1694740952963.0,
                    "widgets" : [

                    ]
                },
                {
                    "id" : "0490d801f45c86e5533e44270180ab5a3c6368e3a6a67a8b5f2ad25fdcfdb8f5",
                    "name" : "hero-no-image",
                    "xtype" : "group",
                    "properties" : {
                        "columnGap" : 60,
                        "rowGap" : 60,
                        "flexDirection" : "column",
                        "justifyContent" : "flex-start",
                        "alignItems" : "center",
                        "paddingTop" : 48,
                        "paddingRight" : 18,
                        "paddingBottom" : 48,
                        "paddingLeft" : 18,
                        "display" : "flex",
                        "small" : {
                            "backgroundAttachment" : "scroll"
                        }
                    },
                    "children" : [
                        {
                            "id" : "524c238eb623298ba6fb0403b002176ea7058d717664c5e8c83f1231ec1526fa",
                            "name" : "small-container-2",
                            "xtype" : "group",
                            "properties" : {
                                "maxWidth" : 720,
                                "columnGap" : 30,
                                "rowGap" : 30,
                                "backgroundColor" : "rgb(245, 247, 250)",
                                "borderTopLeftRadius" : 12,
                                "borderTopRightRadius" : 12,
                                "borderBottomRightRadius" : 12,
                                "borderBottomLeftRadius" : 12,
                                "flexDirection" : "column",
                                "justifyContent" : "flex-start",
                                "alignItems" : "center",
                                "paddingTop" : 24,
                                "paddingRight" : 24,
                                "paddingBottom" : 24,
                                "paddingLeft" : 24,
                                "display" : "flex",
                                "small" : {
                                    "backgroundAttachment" : "scroll"
                                }
                            },
                            "children" : [
                                {
                                    "id" : "widget-5szSA1pXI0EaqIMlE-JVD",
                                    "xtype" : "video",
                                    "properties" : {
                                        "src" : "https://www.youtube.com/embed/NgTBFrFA_n4",
                                        "width" : 420,
                                        "height" : 255,
                                        "alignSelf" : "center",
                                        "maxWidth" : "100%"
                                    },
                                    "children" : [

                                    ],
                                    "widgets" : [

                                    ]
                                },
                                {
                                    "id" : "708f70cd597fc567a1c79211163a332f2ddebf1d6761ae306169883744d44d7e",
                                    "name" : "content",
                                    "xtype" : "group",
                                    "properties" : {
                                        "columnGap" : 18,
                                        "rowGap" : 18,
                                        "flexDirection" : "column",
                                        "justifyContent" : "flex-start",
                                        "alignItems" : "center",
                                        "display" : "flex",
                                        "small" : {
                                            "backgroundAttachment" : "scroll"
                                        }
                                    },
                                    "children" : [
                                        {
                                            "id" : "c4aa65e44e746a949cf38bffaa2e99e04b0132aa3d8ed13084b9ef6a7bec67a2",
                                            "name" : "text-12",
                                            "xtype" : "group",
                                            "properties" : {
                                                "textAlign" : "center",
                                                "fontWeight" : "700",
                                                "lineHeight" : "120%",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "68eb0da4d347f847fa6ea18da13e1893ea75179f65be7ad4924ced6589a2dfea",
                                                    "xtype" : "text",
                                                    "properties" : {
                                                        "content" : "<h1>About Me</h1>",
                                                        "maxWidth" : "100%"
                                                    },
                                                    "lastUpdate" : 1694741497625.0,
                                                    "children" : [

                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "widgets" : [

                                            ]
                                        },
                                        {
                                            "id" : "d909da017dbc368ea88a19b2ce2f5d6beea9971c897c231481c751204ba225a5",
                                            "name" : "lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit-suspendisse-varius-enim-in-eros-elementum-tristique",
                                            "xtype" : "group",
                                            "properties" : {
                                                "textAlign" : "center",
                                                "fontWeight" : "400",
                                                "lineHeight" : "150%",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "4eb19339723a24fba2edeaad3abacb07227543489c82c02b43581366bec62015",
                                                    "xtype" : "text",
                                                    "properties" : {
                                                        "content" : "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</p>",
                                                        "maxWidth" : "100%"
                                                    },
                                                    "children" : [

                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "widgets" : [

                                            ]
                                        },
                                        {
                                            "id" : "92300ffebcb0a68d6d0ba5bb6747990923a5c5b5a65ae1ddf77ffcd2c26deb26",
                                            "name" : "button",
                                            "xtype" : "button",
                                            "properties" : {
                                                "link" : {
                                                    "target" : "url",
                                                    "url" : "#",
                                                    "page" : 0
                                                },
                                                "columnGap" : 6,
                                                "rowGap" : 6,
                                                "flexGrow" : "0",
                                                "flexShrink" : "1",
                                                "flexBasis" : "auto",
                                                "justifyContent" : "center",
                                                "alignItems" : "center",
                                                "paddingTop" : 9,
                                                "paddingRight" : 18,
                                                "paddingBottom" : 9,
                                                "paddingLeft" : 18,
                                                "textDecorationLine" : "none",
                                                "textDecorationThickness" : "initial",
                                                "textDecorationStyle" : "initial",
                                                "textDecorationColor" : "initial",
                                                "display" : "flex",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "c52d193d6aec2ae7ea2190dc90e314f433a952aa3f9a6cb265f5b9d06484b89f",
                                                    "name" : "text-13",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "fontSize" : 9,
                                                        "fontWeight" : "500",
                                                        "lineHeight" : "140%",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "295404c352cfa61f8ded43eb93fbc182dd75acd0ccbe9110705dec94c6d1c9ec",
                                                            "xtype" : "text",
                                                            "properties" : {
                                                                "content" : "<p>GET STARTED</p>",
                                                                "maxWidth" : "100%"
                                                            },
                                                            "children" : [

                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "widgets" : [

                                    ]
                                }
                            ],
                            "widgets" : [

                            ]
                        }
                    ],
                    "widgets" : [

                    ]
                },
                {
                    "id" : "eecbc115592387a6ed8f64bdbd83c886af3d8a70ad24c0702deefa13efe5921f",
                    "name" : "contact-form",
                    "xtype" : "group",
                    "properties" : {
                        "columnGap" : 48,
                        "rowGap" : 48,
                        "flexDirection" : "column",
                        "justifyContent" : "flex-start",
                        "alignItems" : "center",
                        "paddingTop" : 48,
                        "paddingRight" : 18,
                        "paddingBottom" : 48,
                        "paddingLeft" : 18,
                        "display" : "flex",
                        "small" : {
                            "backgroundAttachment" : "scroll"
                        }
                    },
                    "children" : [
                        {
                            "id" : "fb2f6afed26c9b8d2e77996c5d3a372a6745a29fddf2bf72db84ba4836904132",
                            "name" : "container-2",
                            "xtype" : "group",
                            "properties" : {
                                "maxWidth" : 900,
                                "columnGap" : 30,
                                "rowGap" : 30,
                                "flexDirection" : "column",
                                "justifyContent" : "flex-start",
                                "alignItems" : "center",
                                "display" : "flex",
                                "small" : {
                                    "backgroundAttachment" : "scroll"
                                },
                                "width" : 350
                            },
                            "children" : [
                                {
                                    "id" : "b34c2b2edeba97a83a402a2e5ed0c8d77e0b96e679746f58ba5d2ea3607d3773",
                                    "name" : "section-title",
                                    "xtype" : "group",
                                    "properties" : {
                                        "maxWidth" : 397.5,
                                        "columnGap" : 12,
                                        "rowGap" : 12,
                                        "flexDirection" : "column",
                                        "justifyContent" : "flex-start",
                                        "alignItems" : "center",
                                        "display" : "flex",
                                        "small" : {
                                            "backgroundAttachment" : "scroll"
                                        }
                                    },
                                    "children" : [
                                        {
                                            "id" : "bc38029dc1113f6e35b517d719e1cd8831db151c47109bd2576dc399a546764b",
                                            "name" : "text-14",
                                            "xtype" : "group",
                                            "properties" : {
                                                "fontWeight" : "700",
                                                "marginBottom" : 7.5,
                                                "lineHeight" : "120%",
                                                "marginTop" : 15,
                                                "textAlign" : "center",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll",
                                                    "fontWeight" : "bold",
                                                    "lineHeight" : 27
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "c2c5e04eb968c72e46155c90494af89e78ba34dd1aba4de91ea719f9090aee31",
                                                    "xtype" : "text",
                                                    "properties" : {
                                                        "content" : "<h2>Get in touch</h2>",
                                                        "maxWidth" : "100%"
                                                    },
                                                    "children" : [

                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "widgets" : [

                                            ]
                                        },
                                        {
                                            "id" : "8f76f911842de9c0dac94e46278d271cdc986756f39ca2222f699cafbd16fbaa",
                                            "name" : "text-15",
                                            "xtype" : "group",
                                            "properties" : {
                                                "fontSize" : 13.5,
                                                "textAlign" : "center",
                                                "fontWeight" : "400",
                                                "lineHeight" : "150%",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "8bef4bed11e712cc005a40a837b26828a5331f7b78b2534935746b2cb934e188",
                                                    "xtype" : "text",
                                                    "properties" : {
                                                        "content" : "<p>Let us know how we can help</p>",
                                                        "maxWidth" : "100%"
                                                    },
                                                    "children" : [

                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "widgets" : [

                                    ]
                                },
                                {
                                    "id" : "42dcbfb430f90f1a0430cf8fcdb82f81e6c13a54239fcac22b46f723c312654b",
                                    "name" : "form-wrapper w-form",
                                    "xtype" : "group",
                                    "properties" : {
                                        "marginBottom" : 11.25,
                                        "maxWidth" : 375,
                                        "columnGap" : 7.5,
                                        "rowGap" : 7.5,
                                        "flexDirection" : "column",
                                        "justifyContent" : "flex-start",
                                        "alignItems" : "flex-start",
                                        "display" : "flex",
                                        "small" : {
                                            "backgroundAttachment" : "scroll"
                                        }
                                    },
                                    "children" : [
                                        {
                                            "id" : "817125118788b181916bee96ba0c9da427158be60fbda77525a7471da55f23dc",
                                            "name" : "form",
                                            "xtype" : "form",
                                            "properties" : {
                                                "columnGap" : 12,
                                                "rowGap" : 12,
                                                "flexDirection" : "column",
                                                "justifyContent" : "flex-start",
                                                "alignItems" : "flex-start",
                                                "display" : "flex",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                },
                                                "width" : 350
                                            },
                                            "children" : [
                                                {
                                                    "id" : "b9f3eb644dc51b5c8581d822273f4e2b535fd43d2e3e2b4ada23f722a778253f",
                                                    "name" : "input-wrapper",
                                                    "xtype" : "field",
                                                    "properties" : {
                                                        "fieldName": "Name",
                                                        "columnGap" : 6,
                                                        "rowGap" : 6,
                                                        "flexDirection" : "column",
                                                        "justifyContent" : "flex-start",
                                                        "alignItems" : "flex-start",
                                                        "display" : "flex",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        },
                                                        "alignSelf" : "stretch"
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "8a0e3a7c584f92e9fbb80dd7e6ea225fe96d379c594c9d56d639eb2c527559ba",
                                                            "name" : "form-block-label",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "display" : "block",
                                                                "marginBottom" : 3.75,
                                                                "fontWeight" : "400",
                                                                "lineHeight" : "150%",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll",
                                                                    "fontWeight" : "bold"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "9b1200a5f96546c1781a9c5d5db126d32c241ba3d227cb7276a8995fae260cc0",
                                                                    "xtype" : "label",
                                                                    "properties" : {
                                                                        "content" : "<p>Name</p>",
                                                                        "maxWidth" : "100%"
                                                                    },
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        },
                                                        {
                                                            "id" : "389087f394ffcfb0b8f8f2e6b00a0e973d23f1e301b59c42ecba39f09fb34d35",
                                                            "name" : "form-text-input w-input",
                                                            "xtype" : "input",
                                                            "properties" : {
                                                                "required": true,
                                                                "fontStyle" : "inherit",
                                                                "fontVariantLigatures" : "inherit",
                                                                "fontVariantCaps" : "inherit",
                                                                "fontVariantNumeric" : "inherit",
                                                                "fontVariantEastAsian" : "inherit",
                                                                "fontVariantAlternates" : "inherit",
                                                                "fontWeight" : "500",
                                                                "fontStretch" : "inherit",
                                                                "lineHeight" : "140%",
                                                                "fontOpticalSizing" : "inherit",
                                                                "fontKerning" : "inherit",
                                                                "fontFeatureSettings" : "inherit",
                                                                "fontVariationSettings" : "inherit",
                                                                "display" : "flex",
                                                                "paddingTop" : 9,
                                                                "paddingRight" : 9,
                                                                "paddingBottom" : 9,
                                                                "paddingLeft" : 9,
                                                                "marginBottom" : 7.5,
                                                                "verticalAlign" : "middle",
                                                                "borderTopWidth" : 0.75,
                                                                "borderRightWidth" : 0.75,
                                                                "borderBottomWidth" : 0.75,
                                                                "borderLeftWidth" : 0.75,
                                                                "borderTopStyle" : "solid",
                                                                "borderRightStyle" : "solid",
                                                                "borderBottomStyle" : "solid",
                                                                "borderLeftStyle" : "solid",
                                                                "borderTopColor" : "rgb(0, 0, 0)",
                                                                "borderRightColor" : "rgb(0, 0, 0)",
                                                                "borderBottomColor" : "rgb(0, 0, 0)",
                                                                "borderLeftColor" : "rgb(0, 0, 0)",
                                                                "borderImageSource" : "initial",
                                                                "borderImageSlice" : "initial",
                                                                "borderImageWidth" : "initial",
                                                                "borderImageOutset" : "initial",
                                                                "borderImageRepeat" : "initial",
                                                                "columnGap" : 6,
                                                                "rowGap" : 6,
                                                                "justifyContent" : "flex-start",
                                                                "alignItems" : "center",
                                                                "small" : {
                                                                    "fontWeight" : "inherit",
                                                                    "lineHeight" : "1.42857",
                                                                    "marginBottom" : 0,
                                                                    "backgroundAttachment" : "scroll",
                                                                    "display" : "block",
                                                                    "paddingTop" : 6,
                                                                    "paddingBottom" : 6,
                                                                    "borderTopColor" : "rgb(204, 204, 204)",
                                                                    "borderRightColor" : "rgb(204, 204, 204)",
                                                                    "borderBottomColor" : "rgb(204, 204, 204)",
                                                                    "borderLeftColor" : "rgb(204, 204, 204)"
                                                                },
                                                                "textAlign" : "center",
                                                                "alignSelf" : "stretch"
                                                            },
                                                            "children" : [

                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "04ee032714447b3969996545e60ce806fc850bd056134d799fe7b3fda34dc017",
                                                    "name" : "input-wrapper",
                                                    "xtype" : "field",
                                                    "properties" : {
                                                        "fieldName": "Email",
                                                        "columnGap" : 6,
                                                        "rowGap" : 6,
                                                        "flexDirection" : "column",
                                                        "justifyContent" : "flex-start",
                                                        "alignItems" : "flex-start",
                                                        "display" : "flex",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        },
                                                        "alignSelf" : "stretch"
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "dbfa865b4e31d4fcf99546f2da4519884b62d517a9ed2b35d642b69748c19e6f",
                                                            "name" : "form-block-label",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "display" : "block",
                                                                "marginBottom" : 3.75,
                                                                "fontWeight" : "400",
                                                                "lineHeight" : "150%",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll",
                                                                    "fontWeight" : "bold"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "b1f03afec651961d8869634c53816d0c0c366215155c8461dd85d57f4a8f63f5",
                                                                    "xtype" : "label",
                                                                    "properties" : {
                                                                        "content" : "<p>Email</p>",
                                                                        "maxWidth" : "100%"
                                                                    },
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        },
                                                        {
                                                            "id" : "6c3e1543a35d82fe3836e6c9ee2576e20e722a53ea92813750b6fc8f5fbf9565",
                                                            "name" : "form-text-input-2 w-input",
                                                            "xtype" : "input",
                                                            "properties" : {
                                                                "required": true,
                                                                "inputType": "email",
                                                                "fontStyle" : "inherit",
                                                                "fontVariantLigatures" : "inherit",
                                                                "fontVariantCaps" : "inherit",
                                                                "fontVariantNumeric" : "inherit",
                                                                "fontVariantEastAsian" : "inherit",
                                                                "fontVariantAlternates" : "inherit",
                                                                "fontWeight" : "500",
                                                                "fontStretch" : "inherit",
                                                                "lineHeight" : "140%",
                                                                "fontOpticalSizing" : "inherit",
                                                                "fontKerning" : "inherit",
                                                                "fontFeatureSettings" : "inherit",
                                                                "fontVariationSettings" : "inherit",
                                                                "display" : "flex",
                                                                "paddingTop" : 9,
                                                                "paddingRight" : 9,
                                                                "paddingBottom" : 9,
                                                                "paddingLeft" : 9,
                                                                "marginBottom" : 7.5,
                                                                "verticalAlign" : "middle",
                                                                "borderTopWidth" : 0.75,
                                                                "borderRightWidth" : 0.75,
                                                                "borderBottomWidth" : 0.75,
                                                                "borderLeftWidth" : 0.75,
                                                                "borderTopStyle" : "solid",
                                                                "borderRightStyle" : "solid",
                                                                "borderBottomStyle" : "solid",
                                                                "borderLeftStyle" : "solid",
                                                                "borderTopColor" : "rgb(0, 0, 0)",
                                                                "borderRightColor" : "rgb(0, 0, 0)",
                                                                "borderBottomColor" : "rgb(0, 0, 0)",
                                                                "borderLeftColor" : "rgb(0, 0, 0)",
                                                                "borderImageSource" : "initial",
                                                                "borderImageSlice" : "initial",
                                                                "borderImageWidth" : "initial",
                                                                "borderImageOutset" : "initial",
                                                                "borderImageRepeat" : "initial",
                                                                "columnGap" : 6,
                                                                "rowGap" : 6,
                                                                "justifyContent" : "flex-start",
                                                                "alignItems" : "flex-start",
                                                                "small" : {
                                                                    "fontWeight" : "inherit",
                                                                    "lineHeight" : "1.42857",
                                                                    "marginBottom" : 0,
                                                                    "backgroundAttachment" : "scroll",
                                                                    "display" : "block",
                                                                    "paddingTop" : 6,
                                                                    "paddingBottom" : 6,
                                                                    "borderTopColor" : "rgb(204, 204, 204)",
                                                                    "borderRightColor" : "rgb(204, 204, 204)",
                                                                    "borderBottomColor" : "rgb(204, 204, 204)",
                                                                    "borderLeftColor" : "rgb(204, 204, 204)"
                                                                },
                                                                "textAlign" : "center",
                                                                "alignSelf" : "stretch"
                                                            },
                                                            "children" : [

                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "efe97e6f4f7e90156718eaca3c2e60e3ec4dfaf99088edf30786beb25e716a1c",
                                                    "name" : "input-wrapper",
                                                    "xtype" : "field",
                                                    "properties" : {
                                                        "fieldName": "Message",
                                                        "columnGap" : 6,
                                                        "rowGap" : 6,
                                                        "flexDirection" : "column",
                                                        "justifyContent" : "flex-start",
                                                        "alignItems" : "flex-start",
                                                        "display" : "flex",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        },
                                                        "alignSelf" : "stretch"
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "4359dea3349271a3007f8a15e374d15c18fc31f789a6f9b5998fcc5b4449fdaa",
                                                            "name" : "form-block-label",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "display" : "block",
                                                                "marginBottom" : 3.75,
                                                                "fontWeight" : "400",
                                                                "lineHeight" : "150%",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll",
                                                                    "fontWeight" : "bold"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "cf984b2b0175e0577891b6824392f4ff25cd7c09a2edb9ba5e3765ca126843c9",
                                                                    "xtype" : "label",
                                                                    "properties" : {
                                                                        "content" : "<p>Message</p>",
                                                                        "maxWidth" : "100%"
                                                                    },
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        },
                                                        {
                                                            "id" : "e9ecda854bb6747c7e7b6a4143c5f104959e69d7b83adc852efb4f1938a75f6b",
                                                            "name" : "form-textarea w-input",
                                                            "xtype" : "text-area",
                                                            "properties" : {
                                                                "required": true,
                                                                "fontStyle" : "inherit",
                                                                "fontVariantLigatures" : "inherit",
                                                                "fontVariantCaps" : "inherit",
                                                                "fontVariantNumeric" : "inherit",
                                                                "fontVariantEastAsian" : "inherit",
                                                                "fontVariantAlternates" : "inherit",
                                                                "fontWeight" : "500",
                                                                "fontStretch" : "inherit",
                                                                "lineHeight" : "140%",
                                                                "fontOpticalSizing" : "inherit",
                                                                "fontKerning" : "inherit",
                                                                "fontFeatureSettings" : "inherit",
                                                                "fontVariationSettings" : "inherit",
                                                                "overflowX" : "auto",
                                                                "overflowY" : "auto",
                                                                "display" : "flex",
                                                                "paddingTop" : 9,
                                                                "paddingRight" : 9,
                                                                "paddingBottom" : 9,
                                                                "paddingLeft" : 9,
                                                                "marginBottom" : 7.5,
                                                                "verticalAlign" : "middle",
                                                                "borderTopWidth" : 0.75,
                                                                "borderRightWidth" : 0.75,
                                                                "borderBottomWidth" : 0.75,
                                                                "borderLeftWidth" : 0.75,
                                                                "borderTopStyle" : "solid",
                                                                "borderRightStyle" : "solid",
                                                                "borderBottomStyle" : "solid",
                                                                "borderLeftStyle" : "solid",
                                                                "borderTopColor" : "rgb(0, 0, 0)",
                                                                "borderRightColor" : "rgb(0, 0, 0)",
                                                                "borderBottomColor" : "rgb(0, 0, 0)",
                                                                "borderLeftColor" : "rgb(0, 0, 0)",
                                                                "borderImageSource" : "initial",
                                                                "borderImageSlice" : "initial",
                                                                "borderImageWidth" : "initial",
                                                                "borderImageOutset" : "initial",
                                                                "borderImageRepeat" : "initial",
                                                                "columnGap" : 6,
                                                                "rowGap" : 6,
                                                                "justifyContent" : "flex-start",
                                                                "alignItems" : "flex-start",
                                                                "small" : {
                                                                    "fontWeight" : "inherit",
                                                                    "lineHeight" : "1.42857",
                                                                    "marginBottom" : 0,
                                                                    "backgroundAttachment" : "scroll",
                                                                    "display" : "block",
                                                                    "paddingTop" : 6,
                                                                    "paddingBottom" : 6,
                                                                    "borderTopColor" : "rgb(204, 204, 204)",
                                                                    "borderRightColor" : "rgb(204, 204, 204)",
                                                                    "borderBottomColor" : "rgb(204, 204, 204)",
                                                                    "borderLeftColor" : "rgb(204, 204, 204)"
                                                                },
                                                                "alignSelf" : "stretch"
                                                            },
                                                            "children" : [

                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "c21dbb8090dcafd3600192c1a56da78cb767026ccda56523677aa34566274fdd",
                                                    "name" : "form-button w-button",
                                                    "xtype" : "submit-button",
                                                    "properties" : {
                                                        "fontStyle" : "inherit",
                                                        "fontVariantLigatures" : "inherit",
                                                        "fontVariantCaps" : "inherit",
                                                        "fontVariantNumeric" : "inherit",
                                                        "fontVariantEastAsian" : "inherit",
                                                        "fontVariantAlternates" : "inherit",
                                                        "fontWeight" : "500",
                                                        "fontStretch" : "inherit",
                                                        "lineHeight" : "140%",
                                                        "fontOpticalSizing" : "inherit",
                                                        "fontKerning" : "inherit",
                                                        "fontFeatureSettings" : "inherit",
                                                        "fontVariationSettings" : "inherit",
                                                        "display" : "flex",
                                                        "paddingTop" : 9,
                                                        "paddingRight" : 18,
                                                        "paddingBottom" : 9,
                                                        "paddingLeft" : 18,
                                                        "borderTopWidth" : 0,
                                                        "borderRightWidth" : 0,
                                                        "borderBottomWidth" : 0,
                                                        "borderLeftWidth" : 0,
                                                        "borderTopStyle" : "initial",
                                                        "borderRightStyle" : "initial",
                                                        "borderBottomStyle" : "initial",
                                                        "borderLeftStyle" : "initial",
                                                        "borderTopColor" : "initial",
                                                        "borderRightColor" : "initial",
                                                        "borderBottomColor" : "initial",
                                                        "borderLeftColor" : "initial",
                                                        "borderImageSource" : "initial",
                                                        "borderImageSlice" : "initial",
                                                        "borderImageWidth" : "initial",
                                                        "borderImageOutset" : "initial",
                                                        "borderImageRepeat" : "initial",
                                                        "textDecorationLine" : "none",
                                                        "textDecorationThickness" : "initial",
                                                        "textDecorationStyle" : "initial",
                                                        "textDecorationColor" : "initial",
                                                        "cursor" : "pointer",
                                                        "borderTopLeftRadius" : 0,
                                                        "borderTopRightRadius" : 0,
                                                        "borderBottomRightRadius" : 0,
                                                        "borderBottomLeftRadius" : 0,
                                                        "appearance" : "button",
                                                        "columnGap" : 6,
                                                        "rowGap" : 6,
                                                        "justifyContent" : "center",
                                                        "alignItems" : "center",
                                                        "small" : {
                                                            "fontWeight" : "inherit",
                                                            "lineHeight" : "inherit",
                                                            "backgroundAttachment" : "scroll",
                                                            "display" : "inline-block",
                                                            "paddingTop" : 6.75,
                                                            "paddingRight" : 11.25,
                                                            "paddingBottom" : 6.75,
                                                            "paddingLeft" : 11.25
                                                        },
                                                        "alignSelf" : "center"
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "ba6da065c17bdaf49ce11de8de2ade1b9e3f8f1481649f2b7c8055f58d3236cf",
                                                            "xtype" : "text",
                                                            "properties" : {
                                                                "content" : "<p>Send message</p>"
                                                            },
                                                            "children" : [

                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "fields" : [

                                            ],
                                            "formId" : "268224d4-6a7f-478e-879c-81c88672544b",
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "widgets" : [

                                    ]
                                }
                            ],
                            "widgets" : [

                            ]
                        }
                    ],
                    "widgets" : [

                    ]
                }
            ]
        }
    ],
    "thumbnail" : null,
    "from_template" : true,
    "campaignName" : "Business Card Template",
    "campaignDescription" : "",
    "metaImageUrl" : "",
    "metaImageFile" : null,
    "screenshot" : "",
    "redirect" : false,
    "redirectUrl" : "",
    "templateCategory" : "",
    "isPublicTemplate" : false,
    "status" : "published",
    "editMode" : true,
    "createdAt" : "2023-09-15T01:33:26.864Z",
    "lastModified" : "2023-09-15T03:15:32.505Z",
    "scans" : 10,
    "deployQRUrl" : "https://storage.googleapis.com/quicklinks---staging.appspot.com/campaign/Hf7F58y0nlVatZxg4L7N6ESu5AL2/T1NykBwMn3EeltSlKx1vy/qrcode/Business%20Card%20Template_1694747731370.png?GoogleAccessId=firebase-adminsdk-w2673%40quicklinks---staging.iam.gserviceaccount.com&Expires=16447017600&Signature=kdnE0UZ8WeRDlGsEd0HBfkj5v0oroYiQAjlFtfy0sgiPtq1Ub4DYdPXOz2XchJs2nPkaUJCtk9xCJ9Jsq3aVBaXo4pNeYI%2BFFmH6qja0LBmh8MNoz8jrY6TMayiac84ahdha5momKDvH%2B8KlrMJU6jNyHs9f0q1jqDZtcxzi%2B%2BSJgqW02WdqaK%2Fr382RPYG9F5OJj%2FI%2FgQa9xBrrw6LnzpNKvljPatnRt8gFUCi4kDQRUzPwwKaM8%2FhZeQZJv8%2FG%2Fm913Gv%2BXrVlReZSSB52WPIJ9x2w03RlY7J7hRpv4Tqr5Vlm%2FV%2FFFYejiS9ZWxHqLizpYYiJ3LvA60mv6B09ww%3D%3D",
    "analytics" : {
        "googleAnalytics" : {
            "enabled" : false,
            "trackingId" : ""
        },
        "tagManagerSettings" : {
            "enabled" : false,
            "appName" : "deploy-link",
            "containerId" : "GTM-",
            "dataLayerName" : "customDataLayer"
        }
    },
    "qrcode" : {
        "value" : "",
        "ecLevel" : "M",
        "enableCORS" : true,
        "size" : 200,
        "quietZone" : 10,
        "bgColor" : "rgba(0,0,0,0)",
        "fgColor" : "#000000",
        "logoImage" : "",
        "logoWidth" : 50,
        "logoHeight" : 50,
        "logoOpacity" : 0.5,
        "qrStyle" : "squares"
    },
    "version" : 4,
    "campaignId" : "T1NykBwMn3EeltSlKx1vy",
    "deployUrl" : "https://staging.deploy.link/v2/samhussey/Business-Card-Template",
    "oldVersion" : 4,
    "lastUpdate" : 1694747726509.0,
    "fonts" : [
        "Poppins"
    ]
}