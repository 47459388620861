import { useEffect } from 'react'
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined'
import { Link, useParams } from 'react-router-dom'

import { Button } from '../../components/common/Button'
import { RouteNames } from '../../../router'
import { SuccessContainer } from './styles'
import UserService from '../../../services/UserService'

import { Toast } from '../../helpers/Toast'
import { useAuth } from 'widgets-base'

const SuccesPayment = () => {
    const { session_id, role } = useParams()
    const { currentUser, setCurrentUser } = useAuth()

    useEffect(() => {
        UserService.getStripeSession(session_id, role)
            .then(({ data }) => {
                setCurrentUser(data) //todo: Does this really need to update the user?
            })
            .catch((error) => {
                console.log(error)
                Toast(error.response.data.message, 'error')
            })
    }, [role, session_id, setCurrentUser])

    return (
        <SuccessContainer>
            <img src="/images/payment-success.gif" alt="success" />
            <h1>Success!</h1>
            <p>Your request has been proccessed successfully</p>
            <span>Thank you for your billing</span>
            <Button width="200px" variant="secondary">
                <Link to={RouteNames.DASHBOARD}>
                    <ReplyOutlinedIcon /> Go to dashboard
                </Link>
            </Button>
        </SuccessContainer>
    )
}

export default SuccesPayment
