import { Button, Card, CardContent } from "@mui/material";
import { Logo } from "../../../v1/components/Navbar/Logo";
import { ICampaign } from "widgets-base";

function ExperienceCard({ title, desc, iconSvg, selected, onClick }) {
    return (
        <Card
            onClick={onClick}
            sx={{
                width: "350px",
                cursor: 'pointer',
                border: selected
                    ? `2px solid blue`
                    : '2px solid transparent',
                '&:hover': {
                    border: selected
                    ? `2px solid blue`
                    : "2px solid #E2E2E2",
                },
            }}
            >
            <CardContent
                sx={{
                    padding: "5px",
                    margin: "0",
                    "&:last-child": {
                        paddingBottom: 0
                    }
                }}
                >
                <div
                    className="flex flex-row items-center"
                    style={{
                        height: "80px",
                    }}
                >
                    <div
                        className="flex flex-col items-center"
                        style={{
                            width: "80px",
                        }}
                    >
                        <span dangerouslySetInnerHTML={{ __html: iconSvg }} />
                    </div>

                    <div className="flex flex-col">
                        <div
                            className="text-base"
                            style={{
                                color: "#FF8038",
                                fontWeight: "700",
                              }}
                            >
                            {title}
                        </div>
                        <div className="mt-1 text-xs">
                            {desc}
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
}

export interface ChooseYourExperienceProps {

    //
    // Templates to choose from.
    //
    templates: ICampaign[];

    //
    // Handles the "next" button.
    //
    onNext: () => void;

    //
    // Index of the selected template.
    //
    selectedTemplateIndex: number;
    
    //
    // Sets the selected template.
    //
    setSelectedTemplateIndex: (index: number) => void;
}

export function ChooseYourExperience({ templates, selectedTemplateIndex, setSelectedTemplateIndex, onNext }: ChooseYourExperienceProps) {

    const left = templates.slice(0, 3);
    const right = templates.slice(3, 6);

    return (
        <div
            className="flex flex-col items-center overflow-hidden mt-1"
            style={{
                width: "800px",
            }}
            >
            <div
                style={{
                    width: "150px",
                }}
                >
                <Logo />
            </div>

            <div 
                className="mt-6 text-5xl"
                style={{
                    "fontWeight": "600",
                }}
                >
                build
                <span
                    style={{
                        "color": "#FF8038",
                    }}
                    >
                    :
                </span>
                with
                <span
                    style={{
                        "color": "#6478F9",
                    }}
                    >
                    ;
                </span>
                    ai
                    <span
                    style={{
                        "color": "#3AE29B",
                    }}
                    >
                    .
                </span>
            </div>

            <div 
                className="mt-4 text-base"
                style={{
                    "color": "#FF8038",
                    "fontWeight": "400",
                }}
                >
                Build powerful audience engagement with our AI Builder, driven by data.
            </div>

            <div className="mt-8 text-xl">
                What are you trying to achieve?
            </div>

            <div
                className="flex flex-row w-full mt-10 gap-x-10"
                style={{
                    maxWidth: "800px",
                }}
            >
                <div className="flex flex-col flex-grow items-center gap-y-5">
                    {left.map((template, index) => {
                        return (
                            <ExperienceCard
                                key={index}
                                selected={index === selectedTemplateIndex}
                                onClick={() => setSelectedTemplateIndex(index)}
                                title={template.campaignName}
                                desc={template.campaignDescription}
                                iconSvg={template.iconSvg}
                            />
                        );
                    })}
                </div>

                <div
                    style={{
                        height: "100%",
                        borderLeft: "1px solid #E5E5E5",
                    }}
                >
                </div>

                <div className="flex flex-col flex-grow items-center gap-y-5">
                    {right.map((template, index) => {
                        return (
                            <ExperienceCard
                                key={index}
                                selected={left.length + index === selectedTemplateIndex}
                                onClick={() => setSelectedTemplateIndex(left.length + index)}
                                title={template.campaignName}
                                desc={template.campaignDescription}
                                iconSvg={template.iconSvg}
                            />
                        );
                    })}
                </div>
            </div>

            <div className="mt-12">
                <Button 
                    variant="contained"
                    onClick={onNext}
                    >
                    Continue
                </Button>
            </div>

        </div>
    );
}
