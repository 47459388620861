import NavbarWidget from "../Widgets/CombinedWidget/NavbarWidget"
import DrawForm from "../Widgets/Forms/DrawForm"
import FeedbackForm from "../Widgets/Forms/FeedbackForm"
import AudioWidget from "../Widgets/General/AudioWidget"
import ButtonWidget from "../Widgets/General/ButtonWidget"
import ColumnTextWidget from "../Widgets/General/ColumnText"
import CountdownWidget from "../Widgets/General/CountdownWidget"
import DigitalCardWidget from "../Widgets/General/DigitalCardWidget"
import EventsWidget from "../Widgets/General/EventsWidget"
import IframeWidget from "../Widgets/General/IframeWidget"
import ImageWidget from "../Widgets/General/ImageWidget"
import LinkWidget from "../Widgets/General/LinkWidget"
import SocialWidget from "../Widgets/General/SocialWidget"
import SpotifyWidget from "../Widgets/General/SpotifyWidget"
import SwiperWidget from "../Widgets/General/SwiperWidget"
import MultipleTextWidget from "../Widgets/General/TextWidget/MultipleTextWidget"
import TextWidget from "../Widgets/General/TextWidget/TextWidget"
import VideoWidget from "../Widgets/General/VideoWidget"
import DoublePolls from "../Widgets/Polls/DoublePolls"
import MultiplePolls from "../Widgets/Polls/MultiplePolls"
import BeautyCountdownWidget from '../Widgets/General/CountdownWidget/BeautyCountdown'
import SubscribeForm from "../Widgets/Forms/SubscribeForm"
import ProductForm from "../Widgets/Forms/ProductForm"
import ProductCard from "../Widgets/CombinedWidget/ProductCard"
import Hero1 from "../../sections/hero1/hero1-ui"
import Hero2 from "../../sections/hero2/hero2-ui"
import { getWidgetType } from "../../../builder/widget/widget-types";
import ContainerWidget from "../Widgets/General/ContainerWidget";
import FormContainer from "../Widgets/Forms/oldForm/FormContainer";
import { useWidget, generateFontImportStyle } from "widgets-base"

//
// Renders a single primitive widget for preview.
//
export function RenderPreviewWidget() { //todo: This could be subsumed one level up now.

    const { widget, expandedProperties } = useWidget();

    let widgetType = widget.xtype;
    if (widgetType !== undefined) {
        const newWidgetType = getWidgetType(widgetType);
        if (newWidgetType) {
            //
            // A new style widget.
            //
            return (
                <>
                    {generateFontImportStyle(expandedProperties)}
                    <newWidgetType.Preview />
                </>
            );
        }
        else {
            // throw new Error(`Widget type not found "${widget.xtype}"`);
        }
    }
    else {
        widgetType = widget.type;
    }

    //
    // An old style widget.
    //

    switch (widgetType) {
        case 'image':
            return <ImageWidget />
        case 'rich text':
            return <TextWidget />
        case 'multiple text':
            return <MultipleTextWidget />
        case 'column text':
            return <ColumnTextWidget />
        case 'social':
            return <SocialWidget />
        case 'button':
        case 'old-button':
            return <ButtonWidget />
        case 'countdown':
            return <CountdownWidget />
        case 'styled countdown':
            return <BeautyCountdownWidget />
        case 'events':
            return <EventsWidget />
        case 'profile card':
            return <DigitalCardWidget />
        case 'audio':
            return <AudioWidget />
        case 'spotify':
            return <SpotifyWidget />
        case 'video':
            return <VideoWidget />
        case 'iframe':
            return <IframeWidget />
        case 'link text':
            return <LinkWidget />
        case 'swiper':
            return <SwiperWidget />
        case 'container':
            return <ContainerWidget />
        case 'form':            
        case 'old-form':
            return <FormContainer />
        case 'subscribe form':
            return <SubscribeForm />
        case 'product form':
            return <ProductForm />
        case 'draw form':
            return <DrawForm />
        case 'feedback form':
            return <FeedbackForm />
        case 'polls':
        case 'multiple polls':
            return <MultiplePolls />
        case 'double polls':
            return <DoublePolls />
        case 'product1':
            return <ProductCard />
        case 'navbar':
            return <NavbarWidget />

        case 'hero/wide': {
            return <Hero1 editMode />
        }

        case 'hero/narrow': {
            return <Hero2 editMode />
        }

        default:
            throw new Error(`Unknown old style widget type ${widget.type}`);
    }
}