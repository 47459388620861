import { FC } from 'react'
import styled from 'styled-components'
import { useWidget } from 'widgets-base';

interface Props {
    align: string
}

const VerticalAlign: FC<Props> = ({ align }) => {

    const { widget, setWidget } = useWidget();

    const onChangeAlign = (alignment: string) => () => {
        const newWidget = {
            ...widget,
            appearanceSettings: {
                ...widget.appearanceSettings,
                font: {
                    ...widget.appearanceSettings.font,
                    verticalAlign: alignment,
                },
            },
        }

        setWidget(newWidget);
    }

    return (
        <Container>
            <label>Vertical alignment</label>
            <div className="blocks">
                <div
                    className={align === 'flex-start' ? 'active_option' : 'align_option'}
                    onClick={onChangeAlign('flex-start')}
                >
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M18 12H6"
                            stroke={align === 'flex-start' ? '#6478F9' : '#717F95'}
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M18 17H6"
                            stroke={align === 'flex-start' ? '#6478F9' : '#717F95'}
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M30 7H6 10H2"
                            stroke={align === 'flex-start' ? '#6478F9' : '#717F95'}
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>

                <div
                    className={align === 'center' ? 'active_option' : 'align_option'}
                    onClick={onChangeAlign('center')}
                >
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M18 17H6"
                            stroke={align === 'center' ? '#6478F9' : '#717F95'}
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M18 7H6"
                            stroke={align === 'center' ? '#6478F9' : '#717F95'}
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M30 12H6 10H2"
                            stroke={align === 'center' ? '#6478F9' : '#717F95'}
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>

                <div
                    className={align === 'flex-end' ? 'active_option' : 'align_option'}
                    onClick={onChangeAlign('flex-end')}
                >
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M18 12H6"
                            stroke={align === 'flex-end' ? '#6478F9' : '#717F95'}
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M30 17H6 10H2"
                            stroke={align === 'flex-end' ? '#6478F9' : '#717F95'}
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M18 7H6"
                            stroke={align === 'flex-end' ? '#6478F9' : '#717F95'}
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
            </div>
        </Container>
    )
}

export default VerticalAlign

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #717f95;

    & > label {
        font-size: 12px;
        color: #3c4b61;
        margin-bottom: 7px;
        font-weight: bold;
    }

    .blocks {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .align_option {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #717f95;
        border-radius: 8px;
        cursor: pointer;
        svg {
            width: 18px;
        }
    }

    .active_option {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #6478f9;
        border-radius: 8px;
        cursor: pointer;
    }
`
