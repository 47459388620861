import { Divider } from '@mui/material'
import { Link } from 'react-router-dom'
import { RouteNames } from '../../../router'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { PageContainer } from './styles'

const DocDrag = () => {
    return (
        <PageContainer>
            <h1>Drag and drop</h1>
            <span>Ease to use</span>
            <Divider />
            <div className="content">
                <h2>Drag & drop</h2>
                <p>
                    Our main goal is ease of use. Just drag and drop the desired widget. Easy,
                    right?
                </p>
                <img
                    width={610}
                    height={320}
                    style={{ boxShadow: '0 0 10px 3px #cacaca' }}
                    src="/images/docs/drag.gif"
                    alt="drag & drop example"
                />
                <p>
                    In our constructor, you can drag and drop an unlimited number of widgets.
                    Dragging is done by holding the mouse button on the desired widget and dropping
                    it onto the canvas. Please note that some of our widgets are clickable, such as
                    video player, audio, slider, etc., so we recommend that you grab the widget by
                    its frame for dragging. Widgets themselves can be dropped in any order.
                </p>
                <p>
                    You cannot interact with widgets in drag and drop mode and on canvas. At this
                    stage, you build and customize your page by filling it with the necessary
                    widgets. There is an{' '}
                    <Link to={RouteNames.DOC_EDIT_MODE}>
                        <strong>edit mode</strong>
                    </Link>{' '}
                    for interacting and editing widgets.
                </p>
                <Divider />
                <h2>Sortable</h2>
                <p>
                    Did you drag a widget to the wrong place? It doesn't matter, our canvas is
                    sortable, so just sort the widgets among themselves in the desired order by
                    dragging and dropping them.
                </p>
                <img
                    width={610}
                    height={320}
                    style={{ boxShadow: '0 0 10px 3px #cacaca' }}
                    src="/images/docs/sortable.gif"
                    alt="sortable example"
                />
                <p>
                    If you have any difficulties with working in the constructor, there is always a
                    help button <HelpOutlineIcon /> in the lower right corner with useful links to
                    documentation, video examples, as well as to our blog, which may inspire you to
                    new crazy designs.
                </p>
            </div>
        </PageContainer>
    )
}

export default DocDrag
