import { Avatar, Button } from '@mui/material'
import { FC, memo, useState } from 'react'
import styled from 'styled-components'
import vCardsJS from 'vcards-js'
import { Toast } from '../../../../helpers/Toast'
import { generatePass } from '../../../../lib/wallet/generatePass'
import { BurgerMenu } from './BurgerMenu'
import { ShareSocial } from './ShareSocial'
import { SocialLinksBlock } from './SocialLinksBlock'
import { WalletButton } from './WalletButton'
import { useWidget, makeFullAssetUrl } from 'widgets-base'
import { useCampaign } from '../../../../../builder/context/campaign'

interface Props {
    preview?: string
    editMode?: boolean
}

const DigitalCardWidget: FC<Props> = ({ editMode = false, preview }) => {

    const { campaign } = useCampaign();
    const { widget } = useWidget();
    const [showSharringSocial, setShowSharringSocial] = useState(false)

    const socialLinks = widget?.settings?.socialUrls
    const appearanceSettings = widget?.appearanceSettings
    const colourSettings = widget?.colourSettings
    const isShadow = false

    const fontFamily = appearanceSettings?.font?.family || 'Poppins'
    const fontSize = appearanceSettings?.font?.size
    const nameSize = fontSize * 2
    const fontWeight = appearanceSettings?.font?.weight
    const modFontFamily = fontFamily?.replace(/\s/g, '+')
    const fontUrl = appearanceSettings?.font?.url

    const backgroundImageUrl = appearanceSettings?.backgroundImageUrl
    const backgroundColor = Array.isArray(colourSettings['background colour'])
        ? colourSettings['background colour'][0]
        : colourSettings['background colour']
    const textColor = Array.isArray(colourSettings['text colour'])
        ? colourSettings['text colour'][0]
        : colourSettings['text colour']
    const iconColor = Array.isArray(colourSettings['icon colour'])
        ? colourSettings['icon colour'][0]
        : colourSettings['icon colour']
    const buttonColor = Array.isArray(colourSettings['button colour'])
        ? colourSettings['button colour'][0]
        : colourSettings['button colour']
    const buttonTextColor = Array.isArray(colourSettings['button text colour'])
        ? colourSettings['button text colour'][0]
        : colourSettings['button text colour']
    const shadowColor = Array.isArray(colourSettings['shadow colour'])
        ? colourSettings['shadow colour'][0]
        : colourSettings['shadow colour']
    const borderColour = Array.isArray(widget.colourSettings['border colour'])
        ? widget.colourSettings['border colour'][0]
        : widget.colourSettings['border colour']

    const paddingTop = appearanceSettings?.paddingTop + 'px'
    const paddingBottom = appearanceSettings?.paddingBottom + 'px'
    const paddingLeft = appearanceSettings?.paddingLeft + 'px'
    const paddingRight = appearanceSettings?.paddingRight + 'px'
    const width = appearanceSettings?.width + '%'
    const marginTop = appearanceSettings?.marginTop / 10 + 'em'
    const marginBottom = appearanceSettings?.marginBottom / 10 + 'em'
    const marginRight = appearanceSettings?.marginRight / 10 + 'em'
    const marginLeft = appearanceSettings?.marginLeft / 10 + 'em'
    const borderTopLeftRadius = appearanceSettings?.borderTopLeftRadius + 'px'
    const borderTopRightRadius = appearanceSettings?.borderTopRightRadius + 'px'
    const borderBottomLeftRadius = appearanceSettings?.borderBottomLeftRadius + 'px'
    const borderBottomRightRadius = appearanceSettings?.borderBottomRightRadius + 'px'
    const borderTopWidth = appearanceSettings.borderTopWidth / 10 + 'em'
    const borderBottomWidth = appearanceSettings.borderBottomWidth / 10 + 'em'
    const borderLeftWidth = appearanceSettings.borderLeftWidth / 10 + 'em'
    const borderRightWidth = appearanceSettings.borderRightWidth / 10 + 'em'
    const borderWidth = borderTopWidth && borderRightWidth && borderBottomWidth && borderLeftWidth
    const letterSpacing = appearanceSettings?.font?.spacing + 'px' || '8px'
    const lineHeight = appearanceSettings?.font?.lineHeight || 1

    const createCVF = () => {
        if (typeof window !== 'undefined' && widget) {
            let vCard = vCardsJS()
            for (const [name, value] of Object.entries(widget!.settings)) {
                if (name === 'photo' && value) {
                    // vCard.photo.attachFromUrl(value as string, 'JPEG')
                } else if (name === 'socialUrls' && widget?.settings?.socialUrls) {
                    for (const [key, link] of Object.entries(widget?.settings?.socialUrls)) {
                        if (link !== '' && typeof link === 'string') {
                            vCard.socialUrls[key] = link
                        }
                    }
                } else {
                    vCard[name] = value
                }
            }

            return vCard.getFormattedString()
        }
    }

    const download = (vCard: string | number | boolean) => {
        // build data url
        const url = 'data:text/vcard;charset=utf-8,' + encodeURIComponent(vCard)
        // ask the browser to download it
        document.location.href = url
    }

    const getVCFFile = () => {
        if (!editMode) {
            download(createCVF())
        }
    }

    const onShowSharringSocial = () => {
        setShowSharringSocial((prev) => !prev)
    }

    const getPass = async () => {
        if (!editMode) {
            const winRef = window.open('', '_blank')
            try {
                const pass = {
                    email: widget?.settings?.email,
                    firstName: widget?.settings?.firstName,
                    lastName: widget?.settings?.lastName,
                    mobile: widget?.settings?.workPhone,
                    profileImage: widget?.settings?.photo,
                    companyLink: window.location.href,
                }

                await generatePass(pass)
                    .then((data: any) => {
                        winRef.location = `https://www.pass2u.net/d/${data?.data?.passId}`
                    })
                    .catch((err) => {
                        Toast(err.message, 'error')
                    })
            } catch (error) {
                Toast(error.message, 'error')
            }
        } else {
            Toast('You cannot generate a pass in edit mode. Please deploy your page.', 'error')
        }
    }

    return (
        <DigitalCardSection
            id={widget.id}
            fontFamily={fontFamily}
            buttonColor={buttonColor}
            buttonTextColor={buttonTextColor}
            previewWidth={preview}
        >
            <style>
                {!fontUrl
                    ? `@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`
                    : `@font-face {
            font-family: '${fontFamily}';
            src: url('${fontUrl}');
            font-style: normal;
          }
          
          .header-text {
            font-family: '${fontFamily}';
          }`}
            </style>
            <Container
                id="card"
                className="CardFront"
                width={width}
                isPreview={editMode}
                fontFamily={fontFamily}
                paddingTop={paddingTop}
                paddingBottom={paddingBottom}
                paddingLeft={paddingLeft}
                paddingRight={paddingRight}
                backgroundImage={`url(${backgroundImageUrl || ''})`}
                backgroundColor={backgroundColor}
                borderTopLeftRadius={borderTopLeftRadius}
                borderTopRightRadius={borderTopRightRadius}
                borderBottomLeftRadius={borderBottomLeftRadius}
                borderBottomRightRadius={borderBottomRightRadius}
                marginTop={marginTop}
                marginBottom={marginBottom}
                marginRight={marginRight}
                marginLeft={marginLeft}
                isShadow={isShadow}
                shadowColor={shadowColor}
                border={`${borderWidth} solid ${borderColour}`}
                previewWidth={preview}
            >
                <AdaptiveContainer previewWidth={preview}>
                    <FlexContainer previewWidth={preview}>
                        <section>
                            <Avatar
                                src={widget?.settings?.photo}
                                style={{ width: '140px', height: '140px' }}
                            />
                        </section>
                        <Username
                            textColor={textColor}
                            fontSize={nameSize + 'px'}
                            fontWeight={fontWeight}
                            letterSpacing={letterSpacing}
                            lineHeight={lineHeight}
                        >
                            {widget?.settings?.firstName + ' ' + widget?.settings?.lastName}
                        </Username>
                        <UserRole
                            textColor={textColor}
                            fontSize={fontSize + 'px'}
                            fontWeight={fontWeight}
                            letterSpacing={letterSpacing}
                            lineHeight={lineHeight}
                        >
                            {widget?.settings?.title}
                        </UserRole>
                    </FlexContainer>

                    <FlexContainer previewWidth={preview}>
                        <TextContent
                            letterSpacing={letterSpacing}
                            lineHeight={lineHeight}
                            textColor={textColor}
                            fontSize={fontSize + 'px'}
                        >
                            {widget?.settings?.note}
                        </TextContent>
                        <TextContent
                            letterSpacing={letterSpacing}
                            lineHeight={lineHeight}
                            textColor={textColor}
                            fontSize={fontSize + 'px'}
                        >
                            <a
                                style={{ color: buttonColor }}
                                href={`mailto:${widget?.settings?.email}`}
                            >
                                {widget?.settings?.email}
                            </a>
                            <a
                                style={{ color: buttonColor }}
                                href={`tel:${widget?.settings?.workPhone}`}
                            >
                                {widget?.settings?.workPhone}
                            </a>
                        </TextContent>
                        {(widget as any)?.addSocial && ( //todo: type??!! ISocial?
                            <SocialLinksBlock
                                socialLinks={socialLinks}
                                iconColor={iconColor}
                                textColor={textColor}
                                fontSize={fontSize + 'px'}
                            />
                        )}
                        <Marginer height="18px" />
                        <Button
                            variant="contained"
                            color="primary"
                            disableElevation
                            onClick={getVCFFile}
                        >
                            Save contact
                        </Button>
                        <WalletButton getPass={getPass} />
                    </FlexContainer>
                </AdaptiveContainer>

                <BurgerMenu />
                <ShareButton showSharringSocial={showSharringSocial} onClick={onShowSharringSocial}>
                    <img width={30} height={30} src={makeFullAssetUrl("/icons/share1.png")} alt="share button" />
                </ShareButton>
                {showSharringSocial && (
                    <ShareSocial
                        campaign={campaign}
                        firstName={widget?.settings?.firstName}
                        lastName={widget?.settings?.lastName}
                        role={widget?.settings?.title}
                        description={widget?.settings?.note}
                        source={widget?.settings?.url}
                    />
                )}
                <Logo src={makeFullAssetUrl("/icons/color_icon.svg")} alt="deployable" />
            </Container>
        </DigitalCardSection>
    )
}

export default memo(DigitalCardWidget)

const DigitalCardSection = styled.section<{
    fontFamily: string
    buttonColor: string
    buttonTextColor: string
    previewWidth: string
}>`
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: center;

    .CardFront {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: ${(props) => props.fontFamily};
        font-display: block;
        width: ${(props) => (props.previewWidth === '414px' ? '100%' : 'inherit')};
    }

    .MuiButtonBase-root {
        width: 80%;
        margin: 1em auto;
        outline: none;
        text-transform: none;
        border-radius: 16px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 29px;
        gap: 10px;
        height: 44px;
        box-shadow: none;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: ${(props) => props.buttonTextColor};
        background-color: ${(props) => props.buttonColor};
        font-family: ${(props) => props.fontFamily} !important;
        font-display: block;
    }
    .MuiButtonBase-root:hover,
    .MuiButton-containedPrimary:hover {
        background-color: ${(props) => props.buttonColor};
    }

    @keyframes fadeInUp {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    .animated {
        animation-duration: 0.6s;
        animation-delay: 700ms;
        animation-fill-mode: both;
        -webkit-animation-duration: 0.6s;
        -webkit-animation-delay: 700ms;
        -webkit-animation-fill-mode: both;
    }

    .fadeInUp {
        opacity: 0;
        animation-name: fadeInUp;
        -webkit-animation-name: fadeInUp;
    }

    section {
        flex-basis: 100%;
        display: flex;
        justify-content: center;
        padding: 20px;
    }

    section > div {
        display: flex;
        width: 4.5rem;
        height: 4.5rem;
        justify-content: center;
        align-items: center;
    }
`

const AdaptiveContainer = styled.div<{
    previewWidth: string
}>`
    display: flex;
    padding: 0 50px;
    width: inherit;
    justify-content: center;
    flex-direction: ${(props) => (props.previewWidth === '414px' ? 'column' : 'row')};

    @media (max-width: 450px) {
        flex-direction: column;
    }
`

const FlexContainer = styled.div<{
    previewWidth: string
}>`
    display: flex;
    flex-direction: column;
    width: ${(props) => (props.previewWidth === '414px' ? '100%' : '50%')};
    @media (max-width: 450px) {
        width: 100%;
    }
`

const Container = styled.div<{
    width: string
    paddingTop?: string
    paddingBottom?: string
    paddingLeft?: string
    paddingRight?: string
    backgroundImage?: string
    backgroundColor?: string
    borderTopLeftRadius: string
    borderTopRightRadius: string
    borderBottomLeftRadius: string
    borderBottomRightRadius: string
    marginTop?: string
    marginBottom?: string
    marginRight?: string
    marginLeft?: string
    fontFamily: string
    isShadow: boolean
    shadowColor: string
    isPreview?: boolean
    border: string
    previewWidth?: string
}>`
    position: relative;
    display: flex;
    align-items: center;
    font-family: ${(props) => props.fontFamily};
    font-display: block;
    width: ${(props) => (props.previewWidth === '414px' ? '100%' : props.width)};
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: ${(props) => props.border};
    box-shadow: ${(props) => (props.isShadow ? `0px 0px 14px 0px ${props.shadowColor}` : 'none')};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    padding-top: ${(props) => props.paddingTop};
    padding-left: ${(props) => props.paddingLeft};
    padding-bottom: ${(props) => props.paddingBottom};
    padding-right: ${(props) => props.paddingRight};
    border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
    border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
    border-top-left-radius: ${(props) => props.borderTopLeftRadius};
    border-top-right-radius: ${(props) => props.borderTopRightRadius};
    background-image: ${(props) => props.backgroundImage};
    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : '#fff')};
    transition: ease 0.4s;
`
const Username = styled.h1<{
    textColor: string
    fontSize: string
    fontWeight: number
    lineHeight: number
    letterSpacing: string
}>`
    font-weight: ${(props) => props.fontWeight};
    color: ${(props) => props.textColor};
    font-size: ${(props) => props.fontSize};
    text-align: center;
    margin-bottom: 3px;
    letter-spacing: ${(props) => props.letterSpacing};
    line-height: ${(props) => props.lineHeight};
`
const UserRole = styled.h4<{
    textColor: string
    fontSize: string
    fontWeight: number
    lineHeight: number
    letterSpacing: string
}>`
    font-weight: ${(props) => props.fontWeight};
    color: ${(props) => props.textColor};
    font-size: ${(props) => props.fontSize};
    text-align: center;
    margin-bottom: 19px;
    opacity: 0.7;
    letter-spacing: ${(props) => props.letterSpacing};
    line-height: ${(props) => props.lineHeight};
`
const TextContent = styled.p<{
    textColor: string
    fontSize: string
    lineHeight: number
    letterSpacing: string
}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 500;
    color: ${(props) => props.textColor};
    font-size: ${(props) => props.fontSize};
    line-height: 22px;
    text-align: center;
    padding: 0 1em;
    margin: 1em 0;
    margin-bottom: 19px;
    letter-spacing: ${(props) => props.letterSpacing};
    line-height: ${(props) => props.lineHeight};
    & > a {
        text-decoration: underline;
    }
    & > a:not(:last-child) {
        margin-bottom: 18px;
    }
`

const Marginer = styled.div.attrs((props) => ({}))<{
    height: string
}>`
    width: inherit;
    height: ${(props) => (props.height ? props.height : 'inherit')};
`

const ShareButton = styled.div<{
    showSharringSocial: boolean
}>`
    position: absolute;
    top: 20px;
    right: 20px;
    border: 2px solid #fff;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    z-index: 8;
    transform: ${(props) => (props.showSharringSocial ? 'rotate(270deg)' : 'rotate(0)')};
    transition: transform 0.2s ease-in-out;

    & > div {
        background-color: red;
        border-radius: 20px;
    }
`

const Logo = styled.img`
    margin-top: 18px;
`
