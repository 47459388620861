import React, { FC } from 'react'
import { useParams } from 'react-router-dom'
import { RouteNames } from '.'
import { Redirect } from '../v1/components/common/Redirect'
import { ProtectedRoute } from '../components/protected-route'
import EmbedDraw from '../v1/pages/Embed'
import ForgotPassword from '../v1/pages/Login/ForgotPassword'
import Login from '../v1/pages/Login/Login'
import { Playground } from '../v1/pages/playground'
import { Testbed } from '../v1/pages/testbed'
import Username from '../v1/pages/Username'
import VerifyEmail from '../v1/pages/VerifyEmail'
import { AuthAction } from '../pages/auth/action'

const RedirectV1: FC<{}> = () => {
    const params = useParams();
    return <Redirect to={`https://v1.deploy.link/${params.username}/${params.campaignName}`} />;
};

const RedirectV2: FC<{}> = () => {
    const params = useParams();
    if (params.username && params.campaignName) {
        return <Redirect to={`https://live.deploy.link/${params.username}/${params.campaignName}`} />;
    }
    else if (params.username) {
        return <Redirect to={`https://live.deploy.link/${params.username}`} />;
    }
    else {
        return <Redirect to={`https://live.deploy.link/live/${params.campaignId}`} />;
    }
};

export const publicRoutes = [
    { path: RouteNames.LOGIN, element: <Login /> },
    { path: RouteNames.FORGOT_PASSWORD, element: <ForgotPassword /> },
    { 
        path: RouteNames.AUTH_ACTION, 
        element: <AuthAction />,
    },
    //TODO: The following two aren't really public routes!
    { 
        path: RouteNames.VERIFY_EMAIL, 
        element: (
            <ProtectedRoute>
                <VerifyEmail />
            </ProtectedRoute>
        ),
    },
    { 
        path: RouteNames.USERNAME, 
        element: (
            <ProtectedRoute>
                <Username /> 
            </ProtectedRoute>
        ),
    },
    
    { path: `${RouteNames.EMBED}/:campaignId`, element: <EmbedDraw /> },
    { 
        path: `/playground/:campaignId`, 
        element: <Playground /> 
    },
    { 
        path: `/testbed/:campaignIndex/:pageIndex`, 
        element: <Testbed /> 
    },

    //
    // Do not remove this otherwise old-style QR codes from V2 will break.
    //
    { path: `/v2/live/:campaignId`, element: <RedirectV2 /> },
    { path: '/v2/:username/:campaignName', element: <RedirectV2 />, },
    { path: '/:username', element: <RedirectV2 />, },

    //
    // Do not remove this otherwise QR codes from V1 will break.
    //
    { 
        path: '/:username/:campaignName', 
        element: <RedirectV1 />,
    },
]
