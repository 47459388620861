import { FC, FormEvent, useState } from 'react'
import styled from 'styled-components'
import { formFieldCheckRequired } from '../../../../helpers/form/formFieldCheckRequired'
import { Toast } from '../../../../helpers/Toast'
import { formFileUpload } from '../../../../lib/forms/formFileUpload'
import { sendFormToSlack } from '../../../../lib/slack/sendFormToSlack'
import { FormFields } from '../../../../modules/widgets/forms/IForm'
import FormService from '../../../../../services/FormService'
import { Button } from '../../../common/Button'
import RenderFormWidgets from '../RenderFormWidget'
import { useCampaign } from '../../../../../builder/context/campaign'
import { FormStateContextProvider, useForm } from '../../../../../builder/context/form-state-old'
import { useRenderContext, useWidget } from 'widgets-base'

interface Props {
    editMode?: boolean
    preview?: string
}

const DrawForm: FC<Props> = ({ editMode = false, preview }) => {

    const { campaign } = useCampaign();
    const { widget } = useWidget();
    const { getFormState, clearForm } = useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const { renderContext } = useRenderContext();

    const appearanceSettings = widget?.appearanceSettings
    const colourSettings = widget?.colourSettings
    const backgroundImageUrl = appearanceSettings.backgroundImageUrl
    const backgroundColor = colourSettings['background colour'][0]
    const borderColor = colourSettings['border colour'][0]
    const textColor = colourSettings?.['text colour']?.[0]
    const buttonColor = colourSettings?.['button colour']?.[0]
    const paddingTop = appearanceSettings.paddingTop / 10 + 'em'
    const paddingBottom = appearanceSettings.paddingBottom / 10 + 'em'
    const paddingLeft = appearanceSettings.paddingLeft / 10 + 'em'
    const paddingRight = appearanceSettings.paddingRight / 10 + 'em'
    const borderTopWidth = appearanceSettings.borderTopWidth / 10 + 'em'
    const borderBottomWidth = appearanceSettings.borderBottomWidth / 10 + 'em'
    const borderLeftWidth = appearanceSettings.borderLeftWidth / 10 + 'em'
    const borderRightWidth = appearanceSettings.borderRightWidth / 10 + 'em'
    const borderWidth = borderTopWidth && borderRightWidth && borderBottomWidth && borderLeftWidth
    const border = `${borderWidth} solid ${borderColor}`
    const marginTop = appearanceSettings.marginTop / 10 + 'em'
    const marginBottom = appearanceSettings.marginBottom / 10 + 'em'
    const marginLeft = appearanceSettings?.marginLeft / 10 + 'em'
    const marginRight = appearanceSettings?.marginRight / 10 + 'em'
    const borderTopLeftRadius = appearanceSettings.borderTopLeftRadius / 10 + 'em'
    const borderTopRightRadius = appearanceSettings.borderTopRightRadius / 10 + 'em'
    const borderBottomLeftRadius = appearanceSettings.borderBottomLeftRadius / 10 + 'em'
    const borderBottomRightRadius = appearanceSettings.borderBottomRightRadius / 10 + 'em'
    const font = widget.appearanceSettings.font || { family: 'Open Sans' }
    const fontUrl = widget.appearanceSettings?.font?.url
    const modFontFamily = font.family.replace(/\s/g, '+')
    const fontSize = appearanceSettings?.font?.size + 'px'
    const labelSize = appearanceSettings?.font?.size * 1.5 + 'px'
    const width = appearanceSettings?.width + '%'
    const align = appearanceSettings?.align
    const imageWidth = appearanceSettings?.image?.width + '%'
    const imageHeight = appearanceSettings?.image?.height + '%'
    const imageBorderTopLeftRadius = appearanceSettings.image.borderTopLeftRadius / 10 + 'em'
    const imageBorderTopRightRadius = appearanceSettings.image.borderTopRightRadius / 10 + 'em'
    const imageBorderBottomLeftRadius = appearanceSettings.image.borderBottomLeftRadius / 10 + 'em'
    const imageBorderBottomRightRadius = appearanceSettings.image.borderBottomRightRadius / 10 + 'em'

    const parentStyles = {
        backgroundImage: `url(${backgroundImageUrl || ''})`,
        // borderTopLeftRadius,
        // borderTopRightRadius,
        // borderBottomLeftRadius,
        // borderBottomRightRadius,
        // borderColor,
        paddingTop,
        paddingBottom,
        paddingLeft,
        paddingRight,
        // border,
        marginTop,
        marginBottom,
        marginLeft,
        marginRight,
        fontFamily: font.family,
        fontSize,
        color: textColor,
    }

    const formBorderStyle = {
        borderTopLeftRadius,
        borderTopRightRadius,
        borderBottomLeftRadius,
        borderBottomRightRadius,
        borderColor,
        border,
    }

    const handleSubmit = async (formState: FormFields): Promise<void> => {
        setLoading(true)

        try {
            const errors = formFieldCheckRequired(formState)

            if (errors.length > 0) {
                errors.forEach((error) => {
                    Toast(error, 'error')
                })
                setLoading(false)
                return
            }

            let filesToUpload = []

            formState.fields.map((field) => {
                if (field.fieldType === 'upload') {
                    filesToUpload.push({
                        fieldName: field.fieldName,
                        files: field.value.files,
                        fileType: field.value.fileType,
                    })
                }
                return filesToUpload
            })

            let userId = campaign.uid
            if (!userId) {
                userId = campaign.creatorUID
            }

            const uploadData = await formFileUpload(
                filesToUpload,
                userId,
                campaign._id,
                formState.formId
            )

            if (filesToUpload.length > 0 && uploadData.length === 0) {
                Toast('Please select file.', 'error', widget.id)
                setLoading(false)
                return
            }

            let fields = formState.fields

            uploadData.forEach((data) => {
                const index = formState.fields.findIndex(
                    (field) => field.fieldName === data.fieldName
                )

                if (index === -1) {
                    return
                }

                const newField = { ...formState.fields[index], value: data.data }

                fields = [
                    ...formState.fields.slice(0, index),
                    newField,
                    ...formState.fields.slice(index + 1),
                ]
            })

            await FormService.submitForm({
                fields,
                uid: userId,
                campaignId: campaign._id,
                formId: formState.formId,
                formName: formState.formName,
            })

            if (widget.settings.isIntegrate) {
                // send message to Slack
                await sendFormToSlack(
                    widget.settings.integrates.slack,
                    fields,
                    formState.formName,
                    campaign.campaignName,
                    campaign.metaImageUrl
                )
            }

            resetFormState()
            setLoading(false)
            Toast(widget.settings.toastMessage || 'Form submitted!', 'success', widget.id)
        } catch (error) {
            resetFormState()
            setLoading(false)
            Toast(error.message, 'error', widget.id)
        }
    }

    const resetFormState = (): void => {
        clearForm();
    }

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (!editMode) {
            handleSubmit(getFormState())
        }
    }

    if (renderContext.isStaticExport) {
        return undefined;
    }

    return (
        <FormSection id={widget.id} align={align} style={parentStyles}>
            <style>
                {!fontUrl
                    ? `@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`
                    : `@font-face {
                font-family: '${font.family}';
                src: url('${fontUrl}');
                font-style: normal;
              }
              
              .header-text {
                font-family: '${font.family}';
              }`}
            </style>
            <Container>
                <FlexContainer
                    previewWidth={preview}
                    labelSize={labelSize}
                    width={width}
                    buttonColor={buttonColor}
                    fontFamily={font.family}
                    textColor={textColor}
                    backgroundColor={backgroundColor}
                    imageWidth={imageWidth}
                    imageHeight={imageHeight}
                    imageBorderTopLeftRadius={imageBorderTopLeftRadius}
                    imageBorderBottomLeftRadius={imageBorderBottomLeftRadius}
                    imageBorderBottomRightRadius={imageBorderBottomRightRadius}
                    imageBorderTopRightRadius={imageBorderTopRightRadius}
                >
                    <img
                        title={widget?.type}
                        src={widget?.settings?.src || '/images/widgets/draw-form.png'}
                        alt="product"
                    />
                    <form name={widget.name} style={formBorderStyle} onSubmit={onSubmit}>
                        {widget?.settings?.label && <label>{widget?.settings?.label}</label>}
                        <div className="fields">
                            <RenderFormWidgets
                                widgets={widget.children || widget.widgets || []}
                                gap={widget?.appearanceSettings?.spacing + 'px'}
                                borderFieldsColor={
                                    widget?.colourSettings?.['border fields colour']?.[0]
                                }
                                backgroundFieldsColor={
                                    widget?.colourSettings?.['background fields colour']?.[0]
                                }
                            />
                        </div>
                        <Button width="80%" loading={loading} type="submit">
                            SUBMIT
                        </Button>
                    </form>
                </FlexContainer>
            </Container>
        </FormSection>
    )
}

export default function ({ editMode = false, preview = undefined }) {
    const { campaign } = useCampaign();
    const { widget } = useWidget();

    return (
        <FormStateContextProvider>
            <DrawForm editMode={editMode} preview={preview} />
        </FormStateContextProvider>
    );
}

const FormSection = styled.section<{
    align: string
}>`
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.align};
    background-color: #292929c9;
`
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 770px;
    width: 100%;
`

const FlexContainer = styled.div<{
    previewWidth: string
    labelSize: string
    width: string
    buttonColor: string
    fontFamily: string
    textColor: string
    backgroundColor: string
    imageBorderTopLeftRadius: string
    imageBorderTopRightRadius: string
    imageBorderBottomLeftRadius: string
    imageBorderBottomRightRadius: string
    imageWidth: string
    imageHeight: string
}>`
    display: flex;
    align-items: center;
    flex-direction: ${(props) => (props.previewWidth === '414px' ? 'column' : 'row')};
    width: ${(props) => props.width};
    gap: 15px;

    form {
        width: ${(props) => (props.previewWidth === '414px' ? '95%' : '50%')};
        max-width: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: ${(props) => (props.previewWidth === '414px' ? '10px' : '0 10px')};
        background-color: ${(props) => props.backgroundColor};
        padding: 10px;

        & > .fields {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 10px 0;
        }

        & > label {
            font-weight: bold;
            font-size: ${(props) => props.labelSize};
            text-align: center;
        }
    }

    img {
        width: ${(props) => (props.previewWidth === '414px' ? '100%' : props.imageWidth)};
        height: ${(props) => props.imageHeight};
        border-top-left-radius: ${(props) => props.imageBorderTopLeftRadius};
        border-bottom-left-radius: ${(props) => props.imageBorderBottomLeftRadius};
        border-top-right-radius: ${(props) => props.imageBorderTopRightRadius};
        border-bottom-right-radius: ${(props) => props.imageBorderBottomRightRadius};
        object-fit: cover;
    }

    input {
        border-radius: 40px;
        padding: 10px 14px;
        border: 1px solid #000000;
    }

    input::placeholder {
        color: ${(props) => props.textColor};
        font-family: ${(props) => props.fontFamily};
        opacity: 0.6;
    }

    button {
        border: 0;
        border-radius: 40px;
        outline: none;
        padding: 10px 14px;
        background-color: ${(props) => props.buttonColor};
        color: #fff;
        font-weight: bold;
        cursor: pointer;
        font-family: ${(props) => props.fontFamily};
    }

    label,
    .MuiTypography-root {
        font-family: ${(props) => props.fontFamily} !important;
    }

    textarea::placeholder {
        font-family: ${(props) => props.fontFamily};
    }

    @media (max-width: 620px) {
        flex-direction: column;
        form,
        img {
            width: 95%;
        }
    }
`
