import { FC, useState } from 'react'
import styled from 'styled-components'
import { capitalizeFirstLetter } from '../../../helpers/capitalizeFirstLetter'
import { getColorValue } from '../../../helpers/checkColorValue'
import { ColorPicker, ColorPickerCallBack } from '../../Design/ColorPicker'
import { useWidget } from 'widgets-base'

interface Props {
    colors: { [key: string]: string[] | string }
}

const Colors: FC<Props> = ({ colors = {} }) => {

    const { widget, setWidget } = useWidget();
    const [openColorPicker, setOpenColorPicker] = useState<boolean>(false)
    const [selectedKey, setSelectedKey] = useState<string>('')

    const toggleColorPicker = () => {
        setOpenColorPicker((prev) => !prev)
    }

    const onChoseKey = (key: string) => () => {
        setSelectedKey(key)
        toggleColorPicker()
    }

    const onChangeColor = (color: ColorPickerCallBack) => {
        const value = color.rgb
        const { r, g, b, a } = value
        const newColor = `rgb(${r},${g},${b},${a})`
        const newColors = [newColor, colors[selectedKey][0], colors[selectedKey][1]]

        const newWidget = {
            ...widget,
            colourSettings: {
                ...widget.colourSettings,
                [selectedKey]: newColors,
            },
        }

        setWidget(newWidget);
    }

    const onSavePreviusColor = (color: string, key: string) => () => {
        const newColors = [color, colors[key][0], colors[key][1]]

        const newWidget = {
            ...widget,
            colourSettings: {
                ...widget.colourSettings,
                [key]: newColors,
            },
        }

        setWidget(newWidget);
    }

    return (
        <Container>
            <label>Colors</label>
            <div className="colors">
                {Object.keys(colors).map((key) => {
                    return (
                        <div key={key} className="color-block">
                            <p>{capitalizeFirstLetter(key)}</p>
                            <div className="color">
                                <img
                                    width={32}
                                    src="/icons/colors-image.png"
                                    alt="color button"
                                    onClick={onChoseKey(key)}
                                />
                                {Array.isArray(colors[key]) ? 
																	(colors[key] as string[]).map((color: string, index: number) => (
                                    <ColorsContainer
                                        key={color + index}
                                        color={color}
                                        style={
                                            index === 0 ? { boxShadow: `0 0 8px 1px ${color}` } : {}
                                        }
                                        onClick={onSavePreviusColor(color, key)}
                                    />
                                )) : (
																<ColorsContainer
																	color={colors[key] as string}
																	style={{ boxShadow: `0 0 8px 1px ${colors[key]}` }}
																	onClick={onSavePreviusColor(colors[key] as string, key)}
															/>)}
                            </div>
                            {openColorPicker && key === selectedKey && (
                                <ColorPicker
                                    color={getColorValue(colors[key])}
                                    handleColorChange={onChangeColor}
                                    toggleOpen={toggleColorPicker}
                                />
                            )}
                        </div>
                    )
                })}
            </div>
        </Container>
    )
}

export default Colors

const Container = styled.div`
    display: flex;
    flex-direction: column;

    & > label {
        font-size: 14px;
        color: #3c4b61;
        margin-bottom: 7px;
        font-weight: bold;
    }

    .colors,
    .color-block {
        display: flex;
        flex-direction: column;
        gap: 7px;
        p {
            font-size: 12px;
            color: #3c4b61;
            margin-bottom: 3px;
            font-weight: bold;
        }
    }

    .color {
        display: flex;
        gap: 0.1px;

        img {
            cursor: pointer;
        }
    }
`

const ColorsContainer = styled.div<{ color: string }>`
    cursor: pointer;
    width: 32px;
    height: 32px;
    background-color: ${(props) => props.color};
    border-radius: 6px;
    margin-left: 6px;
    border: 1px solid #dfe2f2;
`
