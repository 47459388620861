import { AxiosRequestConfig } from 'axios';
import { ReactNode, useEffect, useState } from 'react';
import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut, GoogleAuthProvider, signInWithPopup, sendPasswordResetEmail as _sendPasswordResetEmail, User, confirmPasswordReset as _confirmPasswordReset  } from "firebase/auth";
import http from '../lib/http';
import mixpanel from 'mixpanel-browser';
import { IUser, ISignupForm, IAuthHook, AuthContext } from 'widgets-base';

mixpanel.init("ee2276620c10d8a2e47315d2fd3b7323", { debug: true, track_pageview: true, persistence: 'localStorage' });
 
export const API_URL = process.env.REACT_APP_API_URL;

//
// Example code:
// 
// https://usehooks.com/useAuth/
//

// Initialize Firebase.
const app = initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

// Initialize Firebase Authentication and get a reference to the service.
const auth = getAuth(app);

let nextId = 0;

export interface IAuthContextProviderProps {
    //
    // Initial user to set.
    //
    initialUser?: IUser;

    children: ReactNode | ReactNode[];
}

//
// The current authentication token for API requests.
//
let authToken: string | undefined;

//
// Timer that updates the auth token.
//
let authUpdateTimer: NodeJS.Timeout | undefined;

//
// Updates the current auth token from Firebase (and starts the token update timer).
//
async function updateAuthToken(firebaseUser: User): Promise<void> {
    authToken = await firebaseUser.getIdToken();

    authUpdateTimer = setInterval(() => {
        firebaseUser.getIdToken()
            .then(token => {
                authToken = token;
            })
            .catch(err => {
                console.error(`Failed to update auth token:`);
                console.error(err);
            });
    }, 1000 * 60 * 25); // Update every 25 minutes.
}

//
// Remove the current auth token.
//
function clearAuthToken() {
    if (authUpdateTimer) {
        clearInterval(authUpdateTimer);
        authUpdateTimer = undefined;
    }
    authToken = undefined;
}

http.interceptors.request.use((config: AxiosRequestConfig) => {

    if (!config.headers) {
        config.headers = {};
    }

    if (authToken !== undefined) {
        if (config.headers.Authorization !== undefined) {
            throw new Error(`Authorization header already set.`);
        }

        config.headers.Authorization = `Bearer ${authToken}`;
    }

    return config;
});

export const AuthContextProvider = ({ initialUser, children }: IAuthContextProviderProps) => {

    let id = ++nextId;

    const [currentUser, setCurrentUser] = useState<IUser>(initialUser);
    const [loading, setLoading] = useState(true);

    //
    // Restores the current user form the current authentication token.
    //
    async function restoreCurrentUser(): Promise<void> {

        if (currentUser) {
            console.log(`Current user already established, no need to restore.`);
            return;
        }

        console.log(`Restoring current user.`)

        try {
            setLoading(true);
       
            const { data } = await http.post("/auth/verify");

            if (data.user) {
                //
                // User is verified.
                //
                console.log(`Token verified, setting current user.`);
                setCurrentUser(data.user);
            }
            else {
                //
                // User not verified.
                // Clear the token and navigate to login page.
                //
                console.log(`Not verified, clearing token.`);
                authToken = undefined;
                setCurrentUser(undefined);
            }
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {

        if (currentUser) {
            //
            // Current user already established, no need to verify.
            //
            console.log("Current user already established, no need to verify.");
            setLoading(false);
            return;
        }

        const token = localStorage.getItem("toks") || undefined;
        if (token) {
            console.log(`[${id}]: Restoring user with token ${token}`);

            setLoading(true);

            clearAuthToken();
            authToken = token; // This isn't a Firebase token, so plug it in directly and don't try to update it from Firebase.

            restoreCurrentUser()
                .catch(err => {
                    console.error(`Failed to restore current user from token:`);
                    console.error(err);
                })
                .finally(() => {
                    setLoading(false);
                });
            return;
        }
        else {
            console.log(`[${id}]: No user is not logged in via stored token.`);
        }  
        
        //
        // Listen to see if we get logged into Firebase.
        //
        const unlisten = onAuthStateChanged(auth, (firebaseUser) => {

            if (firebaseUser) {
                mixpanel.identify(firebaseUser.uid);

                updateAuthToken(firebaseUser)
                    .then(() => {
                        restoreCurrentUser();
                    })
                    .catch(err => {
                        console.error(`Failed to restore current user:`);
                        console.error(err);
                    });
            }

            setLoading(false);

            unlisten(); // Remove the event handler.
        });

    }, []);

    //
    // Signup, setting the current and user and storing the token.
    //
    async function signup(signupValues: ISignupForm): Promise<void> {
        
        try {
            setLoading(true);

            console.log(`[${id}]: Signup`);

            const { user: firebaseUser } = await createUserWithEmailAndPassword(auth, signupValues.email, signupValues.password);

            authToken = await firebaseUser.getIdToken();

            const { data } = await http.post('/auth/signedup', {
                userId: firebaseUser.uid,
                email: signupValues.email,
                username: signupValues.username,
                firstname: signupValues.firstname,
                lastname: signupValues.lastname,
                source: "email",
            });

            setCurrentUser(data.user);
        }
        finally {
            setLoading(false)
        }
    }

    //
    // Login, setting the current user and storing the token.
    //
    async function login(email: string, password: string): Promise<void> {
        try {
            setLoading(true)

            console.log(`[${id}]: Login`);

            const { user: firebaseUser } = await signInWithEmailAndPassword(auth, email, password);
            
            authToken = await firebaseUser.getIdToken();

            const { data } = await http.post('/auth/signedin');

            setCurrentUser(data.user);
        }
        finally {
            setLoading(false)
        }
    }

    //
    // Login with Google.
    //
    async function loginWithGoogle(): Promise<void> {
        try {
            setLoading(true)

            console.log(`[${id}]: loginWithGoogle`);

            const provider = new GoogleAuthProvider();
            const { user: firebaseUser } = await signInWithPopup(auth, provider);

            authToken = await firebaseUser.getIdToken();

            const userFullNameArray = firebaseUser.displayName.split(' ');
            const { data } = await http.post('/auth/signedin', {
                firstname: userFullNameArray.length > 1 ? userFullNameArray.slice(0, userFullNameArray.length - 1).join(' ') : undefined,
                lastname: userFullNameArray.length > 0 ? userFullNameArray[userFullNameArray.length - 1] : undefined,
                email: firebaseUser.email,
                avatar: firebaseUser.photoURL, 
                userId: firebaseUser.uid,
                source: "google",
            });

            setCurrentUser(data.user);
        }
        finally {
            setLoading(false)
        }
    }

    // 
    // Signs the user out.
    //
    async function signout(): Promise<void> {
        
        console.log(`[${id}]: signout`);

        await http.post('/auth/signedout');

        await signOut(auth);

        authToken = undefined;
        setCurrentUser(undefined);
    }

    //
    // Sends a password reset email to the requested user.
    //
    async function sendPasswordResetEmail(email: string): Promise<void> {
        //todo: This should go through our backend rather than firebase.
        await _sendPasswordResetEmail(auth, email);
    }

    //
    // Verifies a user's password reset.
    //
    async function confirmPasswordReset(actionCode: string, newPassword: string): Promise<void> {
        await _confirmPasswordReset(auth, actionCode, newPassword);
    }
    
    //
    // Make an authencated URL.
    // Attaches the token as a query parameter.
    //
    function makeAuthenticatedUrl(path: string): string {
        let url = `${API_URL}${path}`;
      
        if (authToken !== undefined) { 
            url += `?t=${authToken}`;
        }

        return url;
    }

    //
    // Make a URL to retrieve an asset.
    //
    function makeAssetUrl(id: string): string {
        if (!id) {
            throw new Error(`Bad asset ID`);
        }

        return makeAuthenticatedUrl(`/asset/${id}`);
    }

    //
    // Makes a URL to retreive a form upload.
    //
    function makeFormUploadUrl(id: string): string {
        if (!id) {
            throw new Error(`Bad asset ID`);
        }

        return makeAuthenticatedUrl(`/form/upload/${id}`);
    }

    const value: IAuthHook = {
        currentUser,
        setCurrentUser,
        loading,
        signup,
        login,
        loginWithGoogle,
        signout,
        sendPasswordResetEmail,
        confirmPasswordReset,
        makeAuthenticatedUrl,
        makeAssetUrl,
        makeFormUploadUrl,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
