import { ChangeEvent, FC } from 'react'
import styled from 'styled-components'
import { IPollsAnswer } from '../../../modules/widgets/polls/IPolls'
import { Button } from '../../common/Button'
import { InputBox } from '../../common/InputBox'
import { useWidget } from 'widgets-base'

interface Props {
    answer: IPollsAnswer
    answerIndex: number
    deleteAnswer: (id: string) => () => void
}

const AnswerSetting: FC<Props> = ({ answer, answerIndex, deleteAnswer }) => {

    const { widget, setWidget } = useWidget();

    const handleAnswerSettingChange = (e: ChangeEvent<HTMLInputElement>): void => {
        let answers = []
        if (answerIndex === 0) {
            answers = [
                {
                    ...answer,
                    [e.target.name]: e.target.value,
                },
                ...widget.settings.data.slice(answerIndex + 1),
            ]
        } else {
            answers = [
                ...widget.settings.data.slice(0, answerIndex),
                {
                    ...answer,
                    [e.target.name]: e.target.value,
                },
                ...widget.settings.data.slice(answerIndex + 1),
            ]
        }

        const newWidget = {
            ...widget,
            settings: {
                ...widget.settings,
                data: answers,
            },
        }

        setWidget(newWidget);
    }

    return (
        <AnswerBlock>
            <InputBox
                label={`Answer ${answerIndex + 1}`}
                width="100%"
                name="option"
                onChange={handleAnswerSettingChange}
                type="text"
                value={answer.option}
            />
            <Button type="button" variant="invert" size="x-small" onClick={deleteAnswer(answer.id)}>
                Remove answer
            </Button>
        </AnswerBlock>
    )
}

export default AnswerSetting

const AnswerBlock = styled.div`
    position: relative;
    padding-top: 10px;
    & > button {
        margin-left: auto;
    }
    div:first-child {
        margin-bottom: 0;
    }
`
