import { Button } from '@mui/material';
import { SeedQuestions } from '../seed-questions';
import { Preview } from '../preview';
import { ProgressButton } from './components/progress-button';

export function ConfirmContent({ onNext, onBack, seeds, onGenerateCampaign, campaign, generating, answers, setAnswer, imageSearch, setImageSearch, pageTheme }) {

    return (
        <div className="flex flex-row overflow-hidden">
            <div
                className="flex flex-col mr-10"
                style={{ width: "500px" }}
                >
                <SeedQuestions
                    seeds={seeds}
                    answers={answers}
                    setAnswer={setAnswer}
                    imageSearch={imageSearch}
                    setImageSearch={setImageSearch}
                    />

                <div className="flex flex-row mt-6">
                    <Button
                        variant="contained"
                        onClick={onNext}
                        >
                        Continue
                    </Button>

                    <Button
                        sx={{ ml: 1 }}
                        variant="outlined"
                        onClick={onBack}
                        >
                        Back
                    </Button>

                    <ProgressButton
                        onClick={onGenerateCampaign}
                        generating={generating}
                        >
                        Generate again
                    </ProgressButton>
                </div>
            </div>

            <Preview 
                generating={generating}
                campaign={campaign}
                pageTheme={pageTheme}
                fontSize="6px"
                height="calc(100vh - 100px)"
                maxWidth="600px"
                />
        </div>
    );
}

