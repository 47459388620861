import { Divider } from '@mui/material'
import { FC } from 'react'
import { WidgetSettingContainer } from '../../../Design/WidgetSettingContainer'
import {
    BorderRadius,
    BorderWidth,
    Colors,
    Fonts,
    Margins,
    Paddings,
    Sizer,
    Width,
} from '../../../EditMode/Design'
import { useWidget } from 'widgets-base'

interface Props {
}

const DigitalCardDesignSetting: FC<Props> = () => {

    const { widget } = useWidget();
    
    return (
        <WidgetSettingContainer padding={0}>
            <div style={{ display: 'flex', gap: '10px' }}>
                <Fonts
                    label="Select font"
                    defaultFont={widget?.appearanceSettings?.font?.family}
                />
                <Sizer
                    label="Font size"
                    value={widget?.appearanceSettings?.font?.size}
                    name="size"
                />
            </div>
            <Divider />
            <Width width={widget?.appearanceSettings?.width} />
            <Margins
                topValue={widget?.appearanceSettings?.marginTop}
                bottomValue={widget?.appearanceSettings?.marginBottom}
                leftValue={widget?.appearanceSettings?.marginLeft}
                rightValue={widget?.appearanceSettings?.marginRight}
            />
            <Paddings
                topValue={widget?.appearanceSettings?.paddingTop}
                bottomValue={widget?.appearanceSettings?.paddingBottom}
                leftValue={widget?.appearanceSettings?.paddingLeft}
                rightValue={widget?.appearanceSettings?.paddingRight}               
            />
            <Divider />
            <BorderWidth
                topValue={widget?.appearanceSettings?.borderTopWidth}
                rightValue={widget?.appearanceSettings?.borderRightWidth}
                leftValue={widget?.appearanceSettings?.borderLeftWidth}
                bottomValue={widget?.appearanceSettings?.borderBottomWidth}               
            />
            <BorderRadius
                topRightValue={widget?.appearanceSettings?.borderTopRightRadius}
                bottomRightValue={widget?.appearanceSettings?.borderBottomRightRadius}
                topLeftValue={widget?.appearanceSettings?.borderTopLeftRadius}
                bottomLeftValue={widget?.appearanceSettings?.borderBottomLeftRadius}
               
            />
            <Divider />
            <Colors colors={widget?.colourSettings} />
        </WidgetSettingContainer>
    )
}

export default DigitalCardDesignSetting
