//
// A paragraph of text that can be edited.
//
import React, { useRef, useState } from 'react';

export interface Props {
    //
    // The editable text to be displayed.
    //
    text: string;

    //
    // Callback when the text has been edited.
    //
    onTextChanged: (text: string) => void;
}

export const EditableText = (props: Props) => {
    const [editing, setEditing] = useState<boolean>(false)
    const textRef = useRef<HTMLParagraphElement>();

    //
    // Starts editing for the text.
    //
    const startEditing = () => {
        setEditing(true);
    }

    //
    // Stops editing for the text.
    //
    const stopEditing = () => {
        setEditing(false);
        props.onTextChanged(textRef.current.textContent);
    };

    return (
        <p 
            ref={textRef}
            suppressContentEditableWarning
            contentEditable={editing}
            onClick={startEditing}
            onBlur={stopEditing}
            onKeyDown={(event) => {
                if (event.key === "Escape") {
                    stopEditing();
                    event.preventDefault();
                }
            }}
        >
            {props.text}
        </p>
    );
};