import { useEffect, useState } from 'react'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'

import BackgroundColorSetting from '../../components/Branding/BackgroundColorSetting'
import FontSetting from '../../components/Branding/FontSetting'
import LogoSetting from '../../components/Branding/LogoSetting'
import SocialSetting from '../../components/Branding/SocialSetting'
import { BackdropLoader } from '../../components/common/BackdropLoader'
import { Button } from '../../components/common/Button'
import { SEO } from '../../components/SEO'
import { Toast } from '../../helpers/Toast'
import { IBranding } from '../../modules/IBranding'
import BrandingService from '../../../services/BrandingService'
import { uploadFontFile } from '../../lib/upload/uploadFontFile'
import { BrandElements, BrandingFooter, BrandingWrapper } from './styles'
import { useAuth } from 'widgets-base'


const BrandSettings = () => {
    const { currentUser } = useAuth();
    const [loading, setLoading] = useState<boolean>(true);
    const [branding, setBranding] = useState<IBranding | undefined>(undefined);

    useEffect(() => {
        if (currentUser) {
            setLoading(true);
            BrandingService.getBrandSetting()
                .then(branding => {
                    setBranding(branding);
                    setLoading(false);
                })
                .catch((error) => {
                    Toast(error.response.data.message, 'error')
                    setLoading(false);
                });
        }
    }, [setBranding, currentUser])

    async function onSaveBrandSetting(): Promise<void> {
        
        setLoading(true);

        const social = {
            facebook: branding.social?.facebook ?? '',
            instagram: branding.social?.instagram ?? '',
            linkedin: branding.social?.linkedin ?? '',
            pinterest: branding.social?.pinterest ?? '',
            spotify: branding.social?.spotify ?? '',
            tiktok: branding.social?.tiktok ?? '',
            twitter: branding.social?.twitter ?? '',
            whatsapp: branding.social?.whatsapp ?? '',
            youtube: branding.social?.youtube ?? ''
        };

        let data: IBranding = { ...branding, social };

        try {
            // check the newly added font file
            if (data.font && data.font.length > 0 && data.font[data.font.length - 1] instanceof File) {
                const newlyAddedFile = data.font[data.font.length - 1]
                // tag uploaded fonts with custom tag
                const updatedFile = new File(
                    [newlyAddedFile],
                    newlyAddedFile.name.split('.')[0] +
                    ' [CUSTOM].' +
                    newlyAddedFile.name.split('.')[1],
                    {
                        type: newlyAddedFile.type,
                        lastModified: newlyAddedFile.lastModified,
                    }
                )

                const url = await uploadFontFile(updatedFile)

                data = {
                    ...branding,
                    font: [
                        ...branding.font,
                        {
                            family: updatedFile.name.split('.')[0],
                            extension: updatedFile.name.split('.')[1],
                            filename: updatedFile.name.split('.')[0],
                            variant: ['normal'],
                            custom: true,
                            url: url || '',
                        },
                    ],
                }

            }
            await BrandingService.saveBrandSetting(data)
            Toast('Settings saved!', 'success')
        } catch (error) {
            console.log(error)
            Toast(error.response.data.message, 'error')
        }
        setLoading(false)
    }

    function renderElements() {
        if (loading) {
            return <BackdropLoader />;
        }
        
        return (
            <div className="brand__content">
                <BrandElements>
                    <LogoSetting 
                        logoImageUrl={branding.logoImageUrl} 
                        logoImageId={branding.logoImageId} 
                        branding={branding}
                        setBranding={setBranding}
                        />
                    <div className="brand__elements">
                        <FontSetting
                            defaultFont={branding.defaultFont?.family}
                            font={branding.font}
                            branding={branding}
                            setBranding={setBranding}
                            />
                        <BackgroundColorSetting
                            branding={branding}
                            setBranding={setBranding}
                            backgroundColour={branding.backgroundColour}
                            backgroundImageUrl={branding.backgroundImageUrl}
                            backgroundImageId={branding.backgroundImageId}
                            />
                    </div>
                    <SocialSetting 
                        branding={branding}
                        setBranding={setBranding}
                        social={branding.social || {
                            facebook: "",
                            instagram: "",
                            linkedin: "",
                            twitter: "",
                            pinterest: "",
                            spotify: "",
                            tiktok: "",
                            whatsapp: "",
                            youtube: "",
                        }} 
                        />
                </BrandElements>
            </div>
        )
    }

    return (
        <BrandingWrapper>
            <SEO title="Branding" />
            <h1>
                Branding
            </h1>

            {renderElements()}
            <BrandingFooter>
                <Button
                    type="button"
                    variant="primary"
                    width="200px"
                    loading={loading}
                    loadingText="Saving..."
                    onClick={onSaveBrandSetting}
                >
                    <SaveOutlinedIcon /> Save settings
                </Button>
            </BrandingFooter>
        </BrandingWrapper>
    )
}

export default BrandSettings
