import { nanoid } from 'nanoid'
import { ITextarea } from '../../../modules/widgets/forms/ITextarea'

export class TextareaModel implements ITextarea {
    constructor(public label?: string, placeholder?: string) {
        this.settings.label = label
        this.settings.placeholder = placeholder
    }
    id = nanoid()
    name = 'text area'
    type = 'text area'
    icon = '/icons/widgets/text area.svg'
    category = 'form'
    colourSettings = {
        'label colour': ['rgba(0,0,0,1)', '#FFA7E1', '#0426FF'],
        'text colour': ['rgba(0,0,0,1)', '#FFA7E1', '#0426FF'],
        'background colour': ['rgb(255 255 255 / 17%)', '#FFA7E1', '#0426FF'],
        'border colour': ['rgba(0,0,0,1)', '#FFA7E1', '#0426FF'],
    }
    appearanceSettings = {
        border: true,
        borderRadius: true,
        margin: true,
        padding: true,
        width: 90,
        height: 0,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        alignment: 'center',
        font: {
            family: 'Poppins',
            size: 14,
            weight: 400,
        },
    }
    settings = {
        placeholder: 'Tell us why (optional)',
        required: false,
        rows: 5,
        maxLength: 1000,
        type: 'text',
        name: 'text area name',
        label: '',
        defaultValue: '',
    }
}
