import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { SEOLive } from "../components/SEOLive";
import { StaticWidgets } from '../components/new/static-widgets';
import { RenderContextProvider, getRenderContext } from 'widgets-base';
import { useCampaign, CampaignContextProvider } from '../../builder/context/campaign';

function PlaygroundCampaign() {

    const { campaign, currentPageIndex, setCampaign } = useCampaign();
    const [json, setJson] = useState<string>("");
    const curPage = campaign.views[currentPageIndex];

    useEffect(() => {
        if (campaign) {
            console.log(`Campaign -> JSON`);
            const data = {
                fonts: campaign.fonts,
                fontFamilies: campaign.fontFamilies,
                page: campaign.views[0],
            };
            setJson(JSON.stringify(data, null, 4));
        }
    }, [campaign]);

    useEffect(() => {
        if (json) {
            console.log(`JSON -> Campaign`);
            const data = JSON.parse(json);
            const newCampaign = {
                ...campaign,
                views: [
                    data.page,
                    ...campaign.views.slice(1),
                ],
                fonts: data.fonts,
                fontFamilies: data.fontFamilies,
            }
            setCampaign(newCampaign);
        }
    }, [json]);

    return (
        <div className="relative">
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                }}
            >
                <SEOLive title={campaign?.campaignName} />

                <div
                    className="main flex flex-col w-full"
                >
                    <RenderContextProvider defaultRenderContext={getRenderContext()}>
                        <StaticWidgets
                            widgets={curPage.widgets}
                        />
                    </RenderContextProvider>
                </div>

            </div>

            <div className="absolute"
                style={{
                    right: "10px",
                    top: "10px",
                    bottom: "20px",
                    width: "600px",
                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                }}
            >
                <textarea
                    className="bg-transparent text-xs absolute"
                    value={json}
                    onChange={evt => setJson(evt.target.value)}
                    style={{
                        top: "5px",
                        bottom: "2px",
                        left: "5px",
                        right: "2px",
                        "whiteSpace": "nowrap",
                        "overflowX": "scroll",
                    }}
                >
                </textarea>
            </div>
        </div>
    );
}

export function Playground() {

    const { campaignId } = useParams();

    return (
        <CampaignContextProvider 
            campaignId={campaignId} 
            loadLive
            enableSaving
            >
            <PlaygroundCampaign />
        </CampaignContextProvider>
    );
}
