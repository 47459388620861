import { FC, useState } from 'react'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { Toast } from '../v1/helpers/Toast'
import { checkInputValid, checkValidEmail } from '../v1/helpers/validation'
import { EmailModalContent } from '../v1/pages/Account/styles'
import { Button } from '../v1/components/common/Button'
import { CustomModal } from '../v1/components/common/CustomModal'
import { InputBox } from '../v1/components/common/InputBox'
import UserService from '../services/UserService'

import { useNavigate } from 'react-router-dom'
import { RouteNames } from '../router'
import { useAuth } from 'widgets-base'


interface Props {
    open: boolean
    toggleOpen: () => void
    email: string
}

const EmailModal: FC<Props> = ({ open, toggleOpen, email }) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [newEmail, setNewEmail] = useState<string>('')
    const [newPassword, setNewPassword] = useState<string>('')
    const [passwordError, setPasswordError] = useState<string>('')
    const [emailError, setEmailError] = useState<string>('')
    const navigate = useNavigate()
    const { signout } = useAuth()

    const checkPasswordValid = (value: string, name: string, type: string, errorMsg: string) => {
        const valid = checkInputValid(value, type)

        if (!valid) {
            setPasswordError(errorMsg)
        } else {
            setPasswordError('')
        }
    }

    const checkEmail = (email: string) => {
        const isValid = checkValidEmail(email)
        if (!isValid) {
            setEmailError('Please enter valid email address')
        } else {
            setEmailError('')
        }
    }

    const onChangeEmailAction = async () => {
        if (passwordError || emailError) {
            Toast('Incorrect field', 'error')
            return
        }

        if (!newEmail || !newPassword) {
            Toast('You have not filled in the required fields', 'error')
            return
        }

        setLoading(true)
        try {
            await UserService.changeEmail(newEmail, newPassword)
            await signout();
            toggleOpen()
            navigate(RouteNames.LOGIN)
        } catch (error) {
            console.log(error)
            Toast(error.response.data.message, 'error')
        }
        setLoading(false)
    }

    return (
        <CustomModal title="Change email modal" visible={open}>
            <EmailModalContent>
                <h1>
                    Account handoff
                    <span onClick={toggleOpen}>
                        <CloseOutlinedIcon />
                    </span>
                </h1>
                <span style={{ marginBottom: '10px' }}>
                    You can use this tool to handoff your account to a new user if your email is no
                    longer of use. Please fill out the details below accurately to complete the
                    Account Handoff process. After changing the email, you will have to re-login to
                    the platform with a new email.
                </span>
                <span>
                    Note* account can only be changed to one that is not registered on the platform
                    for security purposes. Otherwise, for already registered emails, there is a team
                    creation on the Team page.
                </span>
                <div className="container">
                    <h2>Current information</h2>
                    <h3>Current email</h3>
                    <InputBox value={email} readOnly />

                    <h2>New information</h2>
                    <h3>New email</h3>
                    <InputBox
                        value={newEmail}
                        onChange={(e) => {
                            setNewEmail(e.target.value)
                        }}
                        onBlur={() => checkEmail(newEmail)}
                        error={emailError}
                    />
                    <h3>New password</h3>
                    <InputBox
                        type="password"
                        value={newPassword}
                        onChange={(e) => {
                            setNewPassword(e.target.value)
                        }}
                        onBlur={() =>
                            checkPasswordValid(
                                newPassword,
                                'password',
                                'password',
                                'Password must contain uppercase, lowercase, number and symbol.'
                            )
                        }
                        error={passwordError}
                    />
                    <Button
                        variant="secondary"
                        loading={loading}
                        loadingText="Saving..."
                        onClick={onChangeEmailAction}
                    >
                        Change email
                    </Button>
                </div>
            </EmailModalContent>
        </CustomModal>
    )
}

export default EmailModal
