import axios from 'axios'

export const API_URL = process.env.REACT_APP_API_URL;

const instance = axios.create({
    withCredentials: false,
    baseURL: API_URL,
})

export default instance
