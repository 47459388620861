import { ToastContent, toast } from 'react-toastify'

export const Toast = (message: ToastContent, type: string, id?: string): void => {
    switch (type) {
        case 'error':
            if (id && id.trim() !== '' && !toast.isActive(id)) {
                toast.error(message, {
                    position: 'bottom-center',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    className: 'toastBody',
                    toastId: id,
                })
            } else {
                toast.error(message, {
                    position: 'bottom-center',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    className: 'toastBody',
                })
            }

            break

        case 'success':
            if (id && id.trim() !== '' && !toast.isActive(id)) {
                toast.success(message, {
                    position: 'bottom-center',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    className: 'toastBody',
                    toastId: id,
                })
            } else if (id == null) {
                toast.success(message, {
                    position: 'bottom-center',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    className: 'toastBody',
                })
            }

            break
    }
}
