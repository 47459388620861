import { Divider } from '@mui/material'
import { Link } from 'react-router-dom'
import { RouteNames } from '../../../router'
import { ListItem, PageContainer } from './styles'

function DocSubscribe() {
    return (
        <PageContainer>
            <h1>Billing & Plans</h1>
            <span>
                Check out our offers on the{' '}
                <Link to={RouteNames.BILLING}>
                    <strong>tariff plan</strong>
                </Link>{' '}
                selection page.
            </span>
            <Divider />
            <div className="content">
                <h2>Our subscriptions</h2>
                <p>We currently offer 3 main subscriptions: Free, Lite and Premium.</p>
                <h1>FREE</h1>
                <div>
                    We provide by default a perpetual free subscription to use the platform which
                    includes:
                    <ListItem>
                        <li>Access to one deployed page.</li>
                        <li>Access to Drag & Drop Builder.</li>
                        <li>QR code launcher.</li>
                        <li>Access to use forms.</li>
                    </ListItem>
                </div>
                <h1>LITE</h1>
                <div>
                    A Lite subscription is suitable for those who have already become familiar with
                    the platform and want to get a little more features and functionality.
                    Subscription includes:
                    <ListItem>
                        <li>Access to 5 deployed pages.</li>
                        <li>Access to Drag & Drop Builder.</li>
                        <li>QR code launcher.</li>
                        <li>No watermark.</li>
                        <li>Access to use forms.</li>
                        <li>Basic Gamification Pack.</li>
                        <li>Communication pathway with deployable.</li>
                        <li>Unique link username.</li>
                        <li>Meta data for deployed page.</li>
                    </ListItem>
                    *The basic set of gamification includes such games as "scratch card" and "spin
                    to win".
                </div>
                <h1>PREMIUM</h1>
                <div>
                    The PREMIUM tariff plan is suitable for businesses and for those who want to get
                    the most out of the platform. Includes:
                    <ListItem>
                        <li>Unlimited deployed page.</li>
                        <li>Access to Drag & Drop Builder.</li>
                        <li>QR code launcher.</li>
                        <li>No watermark.</li>
                        <li>Full access to gamification widgets.</li>
                        <li>
                            Communication pathway with deployable and feature request opportunities.
                        </li>
                        <li>Access to team projects.</li>
                        <li>Unique link username.</li>
                        <li>Access to use forms.</li>
                        <li>Meta data for deployed page.</li>
                    </ListItem>
                </div>
                <img className="image" src="/images/docs/plans.png" alt="plans" />
                <h2>Payment Process</h2>
                <p>
                    We use automatic billing and payment process using{' '}
                    <a target="_blank" rel="noreferrer" href="https://stripe.com/">
                        stripe.com.
                    </a>{' '}
                    By clicking the subscription button, you pay for the selected tariff plan for a
                    certain period. Please note that you can choose a monthly or yearly subscription
                    period. At the end of the selected period, the subscription is automatically
                    renewed. To cancel your subscription, you can click the Unsubscribe button on
                    the{' '}
                    <Link to={RouteNames.BILLING}>
                        <strong>tariff plan</strong>
                    </Link>{' '}
                    selection page. But please note that when unsubscribing from a free plan to a
                    free one, the restrictions that apply to the free subscription will apply (the
                    number of deployed campaigns will be reduced to one).
                </p>
                <p>
                    Still have questions about payment? email us hello@deploy.link or our{' '}
                    <Link to={RouteNames.FEEDBACK}>
                        <strong>feedback</strong>
                    </Link>{' '}
                    form.
                </p>
            </div>
        </PageContainer>
    )
}

export default DocSubscribe
