import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { IBranding } from "../../v1/modules/IBranding";
import BrandingService from "../../services/BrandingService";
import { useAuth } from "widgets-base";

export interface IBrandingContextHook {
    //
    // The current branding data.
    //
    branding: IBranding;
}

const BrandingContext = createContext<IBrandingContextHook>(null);

export interface IProps {
    children: ReactNode | ReactNode[];
}

export function BrandingContextProvider({ children }: IProps) {

    const { currentUser } = useAuth();
    const [branding, setBranding] = useState<IBranding>(undefined);

    useEffect(() => {
        if (currentUser) {
            BrandingService.getBrandSetting()
                .then(data => {
                    setBranding(data);
                })
                .catch(err => {
                    console.error(`Failed to get brand settings:`);
                    console.error(err);
                });
        }
        
    }, [currentUser]);

    const value: IBrandingContextHook = {
        branding,
    };

    return (
        <BrandingContext.Provider 
            value={value}
            >
            {children}
        </BrandingContext.Provider>
    );    
}

export function useBrandingContext(): IBrandingContextHook {
    const context = useContext(BrandingContext);
    if (!context) {
        throw new Error(`Branding context is not set! Add BrandingContextProvider to the component tree.`);
    }
    return context;
}
