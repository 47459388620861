//
// Text utilities.
//

//
// Truncates text to X characters.
//
export function truncate(text: string, length: number): string {
    if (text.length > length) {
        return text.substring(0, length) + "...";
    }

    return text;
}

//
// Replaces spaces in text with something else.
//
export function replaceSpaces(text: string, replacement: string): string {
    return text.replace(/ /g, replacement);
}

//
// Extracts the tag and content from a HTML string.
//
export function extractTagAndContent(input: string): { tag: string, content: string } {
    var match = input.match(/^<(\w+)>(.*)<\/\1>$/);

    if (match) {
        return {
            tag: match[1],
            content: match[2]
        };
    }

    return {
        tag: "p",
        content: input,
    };
}
