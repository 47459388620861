import {FC, memo} from 'react'
import {Link} from 'react-router-dom'
import styled from 'styled-components'
import { useWidget } from 'widgets-base'

interface Props {
    editMode?: boolean
    preview?: string
}

const ProductCard: FC<Props> = ({ editMode = false, preview }) => {

    const { widget } = useWidget();
    const font = widget.appearanceSettings.font || {family: 'Open Sans'}
    const fontUrl = widget.appearanceSettings?.font?.url
    const modFontFamily = font.family.replace(/\s/g, '+')
    const borderTopWidth = widget.appearanceSettings.borderTopWidth / 10 + 'em'
    const borderBottomWidth = widget.appearanceSettings.borderBottomWidth / 10 + 'em'
    const borderLeftWidth = widget.appearanceSettings.borderLeftWidth / 10 + 'em'
    const borderRightWidth = widget.appearanceSettings.borderRightWidth / 10 + 'em'
    const borderWidth = borderTopWidth && borderRightWidth && borderBottomWidth && borderLeftWidth
    const borderColor = widget.colourSettings['border colour'][0]
    const border = `${borderWidth} solid ${borderColor}`
    const marginTop = widget.appearanceSettings.marginTop / 10 + 'em'
    const marginBottom = widget.appearanceSettings.marginBottom / 10 + 'em'
    const marginLeft = widget.appearanceSettings?.marginLeft / 10 + 'em'
    const marginRight = widget.appearanceSettings?.marginRight / 10 + 'em'
    const borderTopLeftRadius = widget.appearanceSettings.borderTopLeftRadius / 10 + 'em'
    const borderTopRightRadius = widget.appearanceSettings.borderTopRightRadius / 10 + 'em'
    const borderBottomLeftRadius = widget.appearanceSettings.borderBottomLeftRadius / 10 + 'em'
    const borderBottomRightRadius = widget.appearanceSettings.borderBottomRightRadius / 10 + 'em'

    const parentStyles = {
        border,
        marginTop,
        marginBottom,
        marginLeft,
        marginRight,
        borderTopLeftRadius,
        borderTopRightRadius,
        borderBottomLeftRadius,
        borderBottomRightRadius,
    }

    const styles = {
        fontFamily: widget.appearanceSettings.font.family,
        backgroundColor: widget.colourSettings['background colour'][0],
        color: widget.colourSettings['text colour'][0],
        fontSize: widget.appearanceSettings.font.size + 'px',
        titleSize: widget.appearanceSettings.font.size * 1.5 + 'px',
        subtitleSize: widget.appearanceSettings.font.size * 2.1 + 'px',
        borderTopLeftRadius,
        borderTopRightRadius,
        borderBottomLeftRadius,
        borderBottomRightRadius,
    }

    return (
        <ProductCardSection id={widget.id} style={parentStyles}>
            <style>
                {!fontUrl
                    ? `@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`
                    : `@font-face {
                        font-family: '${font.family}';
                        src: url('${fontUrl}');
                        font-style: normal;
                      }
                      
                      .header-text {
                        font-family: '${font.family}';
                      }`}
            </style>
            <FlexContainer
                previewWidth={preview}
                src={widget.settings.src || '/images/widgets/image/backing.jpg'}
                globalStyles={styles}
            >
                <div className="image"/>
                <div className="title">
                    <h2>{widget.settings.pretitle}</h2>
                    <h1>{widget.settings.title}</h1>
                    <span>{widget.settings.description}</span>

                    <button disabled={editMode}>
                        <a href={widget.settings.linkAction} target="_blank">
                            View more
                        </a>
                    </button>
                </div>
            </FlexContainer>
        </ProductCardSection>
    )
}

export default memo(ProductCard)

const ProductCardSection = styled.section`
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
`

const FlexContainer = styled.div<{
    previewWidth: string
    src: string
    globalStyles: {
        fontFamily: string
        color: string
        backgroundColor: string
        fontSize: string
        titleSize: string
        subtitleSize: string
        borderTopLeftRadius: string
        borderTopRightRadius: string
        borderBottomLeftRadius: string
        borderBottomRightRadius: string
    }
}>`
    display: flex;
    flex-direction: ${(props) => (props.previewWidth === '414px' ? 'column' : 'row')};
    min-height: ${(props) => (props.previewWidth === '414px' ? 'auto' : '350px')};
    height: ${(props) => (props.previewWidth === '414px' ? 'auto' : '350px')};
    width: 100%;
    font-family: ${(props) => props.globalStyles.fontFamily};


    .image {
        width: ${(props) => (props.previewWidth === '414px' ? '100%' : '70%')};
        height: ${(props) => (props.previewWidth === '414px' ? '200px' : 'auto')};
        max-height: 350px;
        background-image: ${(props) => `url(${props.src})`};
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        transition: all 0.5s ease-in-out;
        border-top-left-radius: ${(props) => props.globalStyles.borderTopLeftRadius};
        border-bottom-left-radius: ${(props) => props.globalStyles.borderBottomLeftRadius};

        &:hover {
            background-size: 115%;
        }
    }

    .title {
        max-height: 350px;
        width: ${(props) => (props.previewWidth === '414px' ? '100%' : '30%')};
        padding: 20px;
        background-color: ${(props) => props.globalStyles.backgroundColor};
        color: ${(props) => props.globalStyles.color};
        display: flex;
        flex-direction: column;
        border-top-right-radius: ${(props) => props.globalStyles.borderTopRightRadius};
        border-bottom-right-radius: ${(props) => props.globalStyles.borderBottomRightRadius};

        & > h2 {
            font-size: ${(props) => props.globalStyles.titleSize};
            font-weight: bold;
            margin-bottom: 18px;
        }
        & > h1 {
            font-size: ${(props) => props.globalStyles.subtitleSize};
            font-weight: bold;
            margin-bottom: 18px;
        }

        & > span {
            font-size: ${(props) => props.globalStyles.fontSize};
            margin-bottom: 18px;
        }

        & > button {
            border: 2px solid #fff;
            outline: none;
            padding: 7px;
            width: auto;
            border-radius: 8px;
            color: #070142;
            font-family: ${(props) => props.globalStyles.fontFamily};
            font-size: 13px;
            font-weight: bold;
            background-color: #fff;
            cursor: pointer;
            transition: all 0.2s ease-in-out;

            &:hover {
                background-color: #070142;
                color: #fff;
                a {
                    color: #fff;
                }
            }
        }

        @media (max-width: 800px) {
            h1,
            h2 {
                font-size: 20px;
            }
        }
    }

    @media (max-width: 450px) {
        flex-direction: column;
        height: auto;
        max-height: auto;

        .image {
            width: 100%;
            height: 200px;
            border-top-right-radius: ${(props) => props.globalStyles.borderTopRightRadius};
            border-top-left-radius: ${(props) => props.globalStyles.borderTopLeftRadius};
        }
        .title {
            width: 100%;
            border-bottom-right-radius: ${(props) => props.globalStyles.borderBottomRightRadius};
            border-bottom-left-radius: ${(props) => props.globalStyles.borderBottomLeftRadius};
        }
    }
`
