import { MenuItem, Select, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useProperty } from "../../context/property";
import { IPropertyType } from "../property-type";

//
// An editor for properties that are a list of options.
//

function PropertyOptionEditor() {

    const { property, propertyValue, setPropertyValue } = useProperty();

    if (!property.values && property.values.length < 2) {
        throw new Error(`Not enough values for property ${property.type}/${property.id}`);
    }

    if (property.values[0].icon) {
        // Render icon buttons.
        return (
            <ToggleButtonGroup
                size="small"
                exclusive
                value={propertyValue || property.defaultValue}
                onChange={(event, newValue) => {
                    setPropertyValue(newValue)
                }}
                >
                {property.values.map((allowedPropertyValue, index) => {
                    return (
                        <ToggleButton 
                            key={index} 
                            value={allowedPropertyValue.value}
                            >
                            {allowedPropertyValue.icon}
                        </ToggleButton>
                    );
                })}
            </ToggleButtonGroup>
        );
    }
    else {
        // Render a dropdown/select.
        return (
            <Select 
                className="h-7"
                style={{
                    width: "200px",
                }}
                size="small"
                value={propertyValue || property.defaultValue}
                onChange={event => {
                    setPropertyValue(event.target.value);
                }}
                MenuProps={{
                    style: {
                        zIndex: 10000,
                    },
                }}
                >
                {property.values.map((propertyValue, index) => {
                    return (
                        <MenuItem 
                            key={`${propertyValue.name}-${index}`}
                            value={propertyValue.value}
                            >
                            {propertyValue.name}
                        </MenuItem>
                    );
                })}
            </Select>
        );
    }
}


const text: IPropertyType = {
    editor: () => <PropertyOptionEditor />,
};

export default text;