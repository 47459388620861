export const checkFileSize = (files: FileList, limit: number): boolean => {
    let totalFileSize = 0
    for (let i = 0; i < files.length; i++) {
        totalFileSize += files[i].size
    }

    if (totalFileSize / (1024 * 1024) > limit) {
        return false
    }

    return true
}
