import { useMemo } from 'react'
import RichTextSetting from '../Widgets/General/TextWidget/RichTextSetting'
import MultiTextSetting from '../Widgets/General/TextWidget/MultiTextSetting'
import ImageSetting from '../Widgets/General/ImageWidget/ImageSetting'
import ButtonSetting from '../Widgets/General/ButtonWidget/ButtonSetting'
import LinkSetting from '../Widgets/General/LinkWidget/LinkSetting'
import SwiperSetting from '../Widgets/General/SwiperWidget/SwiperSetting'
import SocialSetting from '../Widgets/General/SocialWidget/SocialSetting'
import CountdownSetting from '../Widgets/General/CountdownWidget/CountdownSetting'
import EventSetting from '../Widgets/General/EventsWidget/EventSetting'
import DigitalCardSetting from '../Widgets/General/DigitalCardWidget/DigitalCardSetting'
import VideoSetting from '../Widgets/General/VideoWidget/VideoSetting'
import AudioSetting from '../Widgets/General/AudioWidget/AudioSetting'
import SpotifySetting from '../Widgets/General/SpotifyWidget/SpotifySetting'
import IframeSetting from '../Widgets/General/IframeWidget/IframeSetting'
import SubscribeFormSetting from '../Widgets/Forms/SubscribeForm/SubscribeFormSetting'
import ProductFormSetting from '../Widgets/Forms/ProductForm/ProductFormSetting'
import PollsSetting from '../Widgets/Polls/PollsSetting'
import ProductSetting from '../Widgets/CombinedWidget/ProductCard/ProductSetting'
import NavbarSetting from '../Widgets/CombinedWidget/NavbarWidget/NavbarSetting'
import ColumnTextSetting from '../Widgets/General/ColumnText/ColumnTextSetting'
import Hero1Settings from '../../sections/hero1/hero1-settings'
import Hero2Settings from '../../sections/hero2/hero2-settings'
import { useCampaign } from '../../../builder/context/campaign'

const RenderSettings = () => {

    const { selectedWidget } = useCampaign();

    const renderWidgetSettings = useMemo(() => {
        switch (selectedWidget?.type) {
            case 'rich text':
                return <RichTextSetting />
            case 'multiple text':
                return <MultiTextSetting />
            case 'column text':
                return <ColumnTextSetting />
            case 'image':
                return <ImageSetting />
            case 'button':
            case 'old-button':
                return <ButtonSetting />
            case 'link text':
                return <LinkSetting />
            case 'swiper':
                return <SwiperSetting />
            case 'social':
                return <SocialSetting />
            case 'countdown':
            case 'styled countdown':
                return <CountdownSetting />
            case 'events':
                return <EventSetting />
            case 'profile card':
                return <DigitalCardSetting />
            case 'video':
                return <VideoSetting />
            case 'audio':
                return <AudioSetting  />
            case 'spotify':
                return <SpotifySetting />
            case 'iframe':
                return <IframeSetting />
            case 'subscribe form':
                return <SubscribeFormSetting />
            case 'product form':
            case 'feedback form':
            case 'draw form':
                return <ProductFormSetting />
            case 'multiple polls':
            case 'double polls':
                return <PollsSetting />
            case 'product1':
                return <ProductSetting />
            case 'navbar':
                return <NavbarSetting />
            case 'hero/wide':
                return <Hero1Settings />
            case 'hero/narrow':
                return <Hero2Settings />
            default:
                return null
        }
    }, [selectedWidget])

    return (
        <div>
            {renderWidgetSettings}
        </div>
    )
}

export default RenderSettings
