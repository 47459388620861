import React, { FC } from 'react'
import { WidgetSettingContainer } from '../../components/Design/WidgetSettingContainer'
import SidebarTabs from '../../components/EditMode/SidebarTabs'
import Hero2DesignSettings from './hero2-design-settings'
import Hero2GeneralSettings from './hero2-general-settings'

interface Props {
}

const Hero2Settings: FC<Props> = () => {
    return (
        <WidgetSettingContainer padding={0}>
            <SidebarTabs
                setting={<Hero2GeneralSettings/>}
                design={<Hero2DesignSettings />}
            />
        </WidgetSettingContainer>
    )
}

export default Hero2Settings
