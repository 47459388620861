import React, { FC } from 'react'
import styled from 'styled-components'
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

interface Props {
    width?: string;
    value: string;
    placeholder?: string;
    name?: string;
    onChange?: (arg0: any) => void;
    onBlur?: (arg0: any) => void;
    clear?: () => void;
}

const SearchInput: FC<Props> = (props) => {
    return (
        <SearchInputContainer 
            width={props.width} 
            className="search__input flex flex-row items-center"
            >
            <StyledInput
                {...props}
                className="mr-1"
                width={props.width}
                value={props.value}
                placeholder={props.placeholder}
                onChange={props.onChange}
            />
            {(props.value && props.value.length > 0) 
                && <ClearIcon 
                    className="cursor-pointer text-gray-500"
                    onClick={props.clear} 
                    />
                || <SearchIcon 
                className="text-gray-400"
                    />
            }
        </SearchInputContainer>
    )
}

export default SearchInput

const SearchInputContainer = styled.div<{
    width: string
}>`
    width: ${(props) => props.width};
    margin-bottom: 15px;
`

const StyledInput = styled.input.attrs((props) => ({}))<{
    width?: string
}>`
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.lineColour};
    color: ${(props) => props.theme.text.light};
    padding: 12px 12px;
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: ${(props) => (props.width ? props.width : 'auto')};
    ::placeholder {
        color: ${(props) => props.theme.text.light};
    }
    font-size: 15px;
    padding-right: 30px;

    @media (max-width: 720px) {
        input {
            width: 100%;
        }
    }
`
