import { Divider } from '@mui/material'
import { ChangeEvent, FC } from 'react'
import styled from 'styled-components'
import { ToggleButton } from '../../../common/ToggleButton'
import { WidgetSettingContainer } from '../../../Design/WidgetSettingContainer'
import { Colors, Columns, Margins, Width } from '../../../EditMode/Design'
import { useWidget } from 'widgets-base'

interface Props {
}

const SocialDesignSetting: FC<Props> = () => {

    const { widget, setWidget } = useWidget();

    const handleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
        const newWidget = {
            ...widget,
            appearanceSettings: {
                ...widget.appearanceSettings,
                [event.target.name]: event.target.checked,
            },
        }

        setWidget(newWidget);
    }

    const handleChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
        let currentIconSize = +event.target.value
        if (currentIconSize > 40) {
            currentIconSize = 40
        } else if (currentIconSize < 1) {
            currentIconSize = 1
        }
        const newSettings = {
            ...widget,
            appearanceSettings: {
                ...widget.appearanceSettings,
                iconHeight: currentIconSize,
                iconWidth: currentIconSize,
            },
        }

        setWidget(newSettings);
    }

    return (
        <WidgetSettingContainer padding={0}>
            <Width width={widget?.appearanceSettings?.width} />
            <Container>
                <label>Icon size</label>
                <div className="field">
                    <input
                        name="iconSize"
                        type="number"
                        max={40}
                        min={0}
                        value={widget?.appearanceSettings?.iconWidth}
                        onChange={handleChangeValue}
                    />
                    <span>px</span>
                </div>
            </Container>
            <ToggleContainer>
                <ToggleButton
                    isToggled={widget?.appearanceSettings?.flex}
                    onChange={handleCheckbox}
                    name="flex"
                />
                <span>Inline</span>
            </ToggleContainer>
            {!widget?.appearanceSettings?.flex && (
                <Columns
                    name="iconSpacing"
                    columns={widget?.appearanceSettings?.columns}
                />
            )}
            <Divider />
            <Margins
                topValue={widget?.appearanceSettings?.marginTop}
                bottomValue={widget?.appearanceSettings?.marginBottom}
                leftValue={widget?.appearanceSettings?.marginLeft}
                rightValue={widget?.appearanceSettings?.marginRight}
            />
            <Divider />
            {!widget?.appearanceSettings?.useIcon && (
                <Colors colors={widget?.colourSettings} />
            )}
        </WidgetSettingContainer>
    )
}

export default SocialDesignSetting

const Container = styled.div`
    display: flex;
    flex-direction: column;

    & > label {
        font-size: 12px;
        color: #3c4b61;
        margin-bottom: 7px;
        font-weight: bold;
    }

    .field {
        display: flex;
        align-items: center;
        padding: 5px;
        height: 32px;
        width: 78px;
        background: #ffffff;
        border: 1px solid #dfe2f2;
        border-radius: 8px;
        margin-right: 6px;
        input {
            border: none;
            width: 40px;
            font-size: 12px;
            color: #3c4b61;
            font-weight: bold;
        }
        span {
            font-size: 12px;
            color: #3c4b61;
            border-left: 1px solid #dfe2f2;
            padding: 0 5px;
            font-weight: bold;
        }
    }
`

export const ToggleContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 18px;

    & > span {
        font-size: 14px;
        font-weight: bold;
        color: #3c4b61;
        margin-bottom: 4px;
        margin-left: 10px;
    }
`
