import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import _ from "lodash";
import { PreviewMultiple } from '../preview';
import { useState } from 'react';
import { SketchPicker } from 'react-color';
import { ProgressButton } from './components/progress-button';

function ModalColorPicker({ propertyValue, setPropertyValue, onCloseModal }) {

    return (
        <div
            className="absolute select-none"
            style={{
                top: "34px",
                zIndex: "100",
            }}
        >
            <div
                className="fixed"
                style={{
                    left: "0",
                    right: "0",
                    top: "0",
                    bottom: "0",
                }}
                onClick={event => {
                    onCloseModal();
                    event.preventDefault();
                }}
            />
            <SketchPicker
                color={propertyValue}
                onChangeComplete={color => {
                    setPropertyValue(color.hex);
                }}
            />
        </div>
    );
}

//
// An editor for color properties.
//
function ColorEditor({ propertyValue, setPropertyValue }) { //todo: Be nice to share this code.

    const [openColorPicker, setOpenColorPicker] = useState<boolean>(false);

    return (
        <>
            <div
                className="cursor-pointer"
                style={{
                    width: "30px",
                    height: "30px",
                    backgroundColor: propertyValue,
                    border: "1px solid #dfe2f2",
                    borderRadius: "8px",
                }}
                onClick={() => setOpenColorPicker(!openColorPicker)}
            />

            {openColorPicker
                && <ModalColorPicker
                    propertyValue={propertyValue}
                    setPropertyValue={value => {
                        setPropertyValue(value);
                    }}
                    onCloseModal={() => {
                        setOpenColorPicker(false);
                    }}
                />
            }
        </>
    );
}

//
// Settings for the choosing a color scheme.
//
function ColorSchemeSettings({ onGenerateColors, onGenerateFromColor }) {

    const [value, setValue] = useState("choose");
    const [brandColor, setBrandColor] = useState("blue");

    function onChange(event: React.ChangeEvent<HTMLInputElement>) {
        setValue(event.target.value);

        if (event.target.value === "brand") {
            onGenerateFromColor(brandColor);
        }
        else {
            onGenerateColors();
        }
    };

    function onChangeColor(color: string) {
        setBrandColor(color);

        onGenerateFromColor(color);
    }

    return (
        <div className="flex flex-col">
            <RadioGroup
                value={value}
                onChange={onChange}
                >
                <FormControlLabel value="choose" control={<Radio size="small" />} label="Color palette" />

                <div className="flex flex-row items-center">
                    <FormControlLabel value="brand" control={<Radio size="small" />} label="Brand color" />
                    {value === "brand"
                        && <ColorEditor
                            propertyValue={brandColor}
                            setPropertyValue={onChangeColor}
                            />
                    }
                </div>
            </RadioGroup>

        </div>
    );
}

//
// Choose a color scheme.
//
export function ChooseColors({ onBack, onNext, onGenerateColors, onGenerateFromColor, themes, selectedThemeIndex, setSelectedThemeIndex, campaign, generating }) {

    return (
        <div
            className="flex flex-col overflow-hidden"
            >
            <PreviewMultiple
                themes={themes}
                selectedThemeIndex={selectedThemeIndex}
                generating={generating}
                campaign={campaign}
                setSelectedThemeIndex={setSelectedThemeIndex}
                />

                <div className="flex flex-col mt-2 ml-2">
                    <ColorSchemeSettings
                        onGenerateColors={onGenerateColors}
                        onGenerateFromColor={onGenerateFromColor}
                        />

                    <div className="flex flex-row mt-2">
                        
                        <Button
                            variant="contained"
                            onClick={onNext}
                            >
                            Continue
                        </Button>

                        <Button
                            sx={{ ml: 1 }}
                            variant="outlined"
                            onClick={onBack}
                            >
                            Back
                        </Button>

                        <ProgressButton
                            onClick={onGenerateColors}
                            generating={generating}
                            >
                            Generate again
                        </ProgressButton>
                    </div>
                </div>
        </div>
    );
}

