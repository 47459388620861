import { Divider } from '@mui/material'
import { nanoid } from 'nanoid'
import { FC, Fragment, useCallback } from 'react'
import { useRecoilValue } from 'recoil'
import styled from 'styled-components'
import { IPolls, IPollsAnswer } from '../../../modules/widgets/polls/IPolls'
import AddElementButton from '../../EditMode/Settings/AddElementButton'
import AnswerSetting from './AnswerSetting'
import { useWidget } from 'widgets-base'

interface Props {
    answers: IPollsAnswer[]
}

const AnswerList: FC<Props> = ({ answers }) => {

    const { widget, setWidget } = useWidget();

    const onAddNewAnswer = () => {
        const newAnswer = { id: nanoid(), option: 'New answer', votes: 0 }
        const newSettings = {
            ...widget,
            settings: {
                ...widget.settings,
                data: [...widget.settings.data, newAnswer],
            },
        }

        setWidget(newSettings);
    }

    const deleteAnswer = useCallback(
        (id: string) => () => {
            if (widget.settings.data.length === 1) {
                return
            }
            const filteredAnswers = widget.settings.data.filter(
                (answer: IPollsAnswer) => answer.id !== id
            )
            const newSetting = {
                ...widget,
                settings: {
                    ...widget.settings,
                    data: filteredAnswers,
                },
            }

            setWidget(newSetting);
        },
        [setWidget, widget]
    )

    return (
        <Container>
            <label>Answer list</label>
            {answers?.map((answer: IPollsAnswer, index: number) => (
                <Fragment key={answer?.id}>
                    <AnswerSetting
                        answer={answer}
                        answerIndex={index}
                        deleteAnswer={deleteAnswer}
                    />
                    <Divider />
                </Fragment>
            ))}
            {widget?.type === 'double polls' && answers.length >= 2 ? null : (
                <AddElementButton name="Add new answer" onClick={onAddNewAnswer} />
            )}
        </Container>
    )
}

export default AnswerList

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-end;

    & > label {
        font-size: 18px;
        font-weight: bold;
        color: #3c4b61;
    }
`
