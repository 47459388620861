import { FC, ReactNode } from 'react'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: 'none',
    outline: 'none',
    boxShadow: 24,
    borderRadius: 8,
}

interface Props {
    visible: boolean
    toggleVisible?: (value: boolean) => void
    children: ReactNode
    title: string
}

export const CustomModal: FC<Props> = ({ children, visible, toggleVisible, title }) => {
    return (
        <Modal
            aria-labelledby={title}
            aria-describedby="transition-modal-description"
            open={visible}
            onClose={toggleVisible}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={visible}>
                <Box sx={style}>{children}</Box>
            </Fade>
        </Modal>
    )
}
