import { Divider, TextField } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import { ChangeEvent, FC } from 'react'
import { InputBox } from '../../../common/InputBox'
import { WidgetSettingContainer } from '../../../Design/WidgetSettingContainer'
import {Colors, Fonts, Margins, Orientation, Paddings, Sizer, Width} from '../../../EditMode/Design'
import styled from 'styled-components'
import { useWidget } from 'widgets-base'

interface Props {
}

const CountdownSetting: FC<Props> = () => {

    const { widget, setWidget } = useWidget();

    const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
        const newWidget = {
            ...widget,
            settings: {
                ...widget.settings,
                [event.target.name]: event.target.value,
            },
        }

        setWidget(newWidget);
    }

    const handleChangeStartDate = (newValue: Date | null) => {
        const value = moment(newValue).format('YYYY-MM-DD HH:mm:ss')
        const newWidget = {
            ...widget,
            settings: {
                ...widget.settings,
                endDate: value,
            },
        }

        setWidget(newWidget);
    }

    return (
        <WidgetSettingContainer padding={18}>
            <InputBox
                label="Countdown end text"
                name="endDateText"
                type="text"
                max={40}
                min={0}
                value={widget?.settings?.endDateText}
                onChange={onChangeValue}
            />
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Label>End of countdown</Label>
                <DateTimePicker
                    label="End of countdown date"
                    value={widget?.settings?.endDate}
                    onChange={handleChangeStartDate}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
            <Orientation align={widget?.appearanceSettings?.align} />
            <Divider />
            <div style={{ display: 'flex', gap: '10px' }}>
                <Fonts
                    label="Select font"
                    defaultFont={widget?.appearanceSettings?.font?.family}
                    
                />
                <Sizer
                    
                    label="Font size"
                    value={widget?.appearanceSettings?.font?.size}
                    name="size"
                />
            </div>
            {/*<Width width={widget?.appearanceSettings?.width}/>*/}
            <Margins
                topValue={widget?.appearanceSettings?.marginTop}
                bottomValue={widget?.appearanceSettings?.marginBottom}
                leftValue={widget?.appearanceSettings?.marginLeft}
                rightValue={widget?.appearanceSettings?.marginRight}
                
            />
            <Paddings
                topValue={widget?.appearanceSettings?.paddingTop}
                bottomValue={widget?.appearanceSettings?.paddingBottom}
                leftValue={widget?.appearanceSettings?.paddingLeft}
                rightValue={widget?.appearanceSettings?.paddingRight}
                
            />
            <Divider />
            <Colors colors={widget?.colourSettings} />
        </WidgetSettingContainer>
    )
}

export default CountdownSetting

export const Label = styled.label`
    font-size: 14px;
    font-weight: bold;
    color: #3c4b61;
    margin-bottom: 4px;
`
