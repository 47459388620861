import { nanoid } from "nanoid";
import { IWidget, makePadding, makeMargin, makeBorderRadius } from "widgets-base";

export const feedbackForm: IWidget = {
    id: `widget-${nanoid()}`,
    name: 'Feedback',
    xtype: 'group',
    source: "feedback-form-section",
    grouped: true,
    properties: {
        flexDirection: "row",
        ...makePadding(8),
        alignSelf: "center",
        color: "white",
        backgroundColor: "#74369E",
        small: {
            flexDirection: "column",
        },
    },
    children: [
        {
            id: `widget-${nanoid()}`,
            xtype: 'image',
            properties: {
                image: {
                    url: "/images/widgets/forms/feedback.png",
                },
                maxWidth: 250,

                small: {
                    marginTop: 24,
                },
            },
        },
        {
            id: `widget-${nanoid()}`,
            xtype: 'form',
            name: "Feedback form",
            grouped: true,
            properties: {
                flexDirection: "column",
                ...makeMargin(12),
                ...makePadding(12),
                gap: 12,
                minWidth: 175,
                maxWidth: 250,
                justifyContent: "center",
            },
            children: [
                {
                    id: `widget-${nanoid()}`,
                    xtype: 'text',
                    properties: {
                        content: "<b>We would love to hear about your experience</b>",
                        fontSize: 20,
                        textAlign: "center",
                        alignSelf: "stretch",
                    },
                },
                {
                    id: `widget-${nanoid()}`,
                    xtype: 'field',
                    grouped: true,
                    properties: {
                        fieldName: "Feedback",
                        flexDirection: "column",
                        alignSelf: "stretch",
                    },
                    children: [
                        {
                            id: `widget-${nanoid()}`,
                            xtype: 'text-area',
                            properties: {
                                placeholder: "Enter your feedback",
                                required: true,
                                fontSize: 10,
                                backgroundColor: "#8C58AE",
                                ...makeBorderRadius(12),
                                ...makePadding(12),
                                alignSelf: "stretch",
                            },
                        },
                    ],
                },
                {
                    id: `widget-${nanoid()}`,
                    xtype: 'submit-button',
                    grouped: true,
                    properties: {
                        alignSelf: "stretch",
                        justifyContent: "center",
                        ...makeBorderRadius(40),
                        ...makePadding(10),
                        marginLeft: 6,
                        marginRight: 6,
                        marginTop: 16,
                        marginBottom: 6,
                        color: "#74369E",
                        backgroundColor: "white",
                        hover: {
                            backgroundColor: "#3E3E3E",
                        },
                    },
                    children: [
                        {
                            id: `widget-${nanoid()}`,
                            xtype: 'text',
                            properties: {
                                content: "<p>Submit feedback</p>",
                            },
                        },
                    ],
                },
            ],
        },
    ],
};