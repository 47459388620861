import { SpeechBubble } from "./components/speech-bubble";

export function InitialCopy({ seed, answers, setAnswer, answerIndex, showGabby }) {
    return (
        <div className="flex flex-col w-full items-center overflow-hidden">

            {showGabby && (
                <SpeechBubble
                    title="Gabby - Product Manager"
                    text="Bring the Magic, Ken!"
                    avatar="/ai-generator/characters/gabby.png"
                    />
            )}

            <SpeechBubble
                title="Ken - Head of Audience Incentives"
                text={seed.question}
                avatar="/ai-generator/characters/ken.png"
                reverse={true}
                />

            <div className="flex flex-col mt-4">

                <div className="prominent-text text-neutral-400 text-xl tracking-wider ml-4 mt-6">
                    Write Your Prompt Here
                </div>
                <div className="mt-1">
                    <textarea 
                        className="p-4 text-xl text-white placeholder-white leading-9 tracking-wide bg-blue-500 rounded-lg"
                        placeholder={seed.placeholder}
                        style={{
                            width: "500px",
                            minHeight: "100px",
                        }}
                        value={answers[answerIndex]}
                        onChange={(e) => setAnswer(answerIndex, e.target.value)}
                        >
                    </textarea>
                </div>
            </div>

            <SpeechBubble
                title="Buzz - Head of Good Tips"
                text={seed.example}
                avatar="/ai-generator/characters/buzz.png"
                />            
        </div>
    );
}

