import { FC, ReactNode } from 'react'
import styled from 'styled-components'

interface ContentBlockPropsType {
    title?: string
    children: ReactNode
    width?: string
}

export const ContentBlock: FC<ContentBlockPropsType> = ({ title, width = '380px', children }) => {
    return (
        <ContentBlockContainer width={width} className="sections">
            <h2>{title}</h2>
            <Content>{children}</Content>
        </ContentBlockContainer>
    )
}

const ContentBlockContainer = styled.div<{
    width: string
}>`
    display: flex;
    flex-direction: column;
    width: ${(props) => props.width};
    height: max-content;
    background: #fafaff;
    border: 1px solid #e3e7ff;
    border-radius: 12px;
    margin: 14px;
    padding: 25px 28px;
    h2 {
        margin-bottom: 20px;
        font-weight: bold;
        font-size: 18px;
        line-height: 26px;
        color: #3c4b61;
    }
    @media (max-width: 435px) {
        width: 85%;
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;

    .font__list {
        width: 100%;
    }
`
