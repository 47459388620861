import { RenderContextProvider, getRenderContext } from 'widgets-base';
import { numCategories, numSections, categories } from '../../sections/sections';
import { StaticWidget } from '../components/new/static-widget';
import { PageThemeProvider, defaultPageTheme } from '../../builder/context/page-theme';
import { Campaign } from '../models/Campaign';
import { CampaignContextProvider } from '../../builder/context/campaign';
import { WidgetContextProvider } from '../../builder/context/widget';

function Sections() {

    return (
        <PageThemeProvider pageTheme={defaultPageTheme}>
            <div
                className="main flex flex-col w-full p-1"
                >
                <div className="text-2xl text-blue-600">New sections preview</div>
                <div className="p-1 text-sm text-blue-600">Total categories: {numCategories}</div>
                <div className="p-1 text-sm text-blue-600">Total sections: {numSections}</div>
                {categories.map(category => {
                    return (
                        <RenderContextProvider 
                            key={category.name}
                            defaultRenderContext={getRenderContext()}
                            >
                            {category.widgets.map(template => {
                                return (
                                    <WidgetContextProvider
                                        key={template.widget.id}
                                        widget={template.widget}
                                        >
                                        <hr />
                                        <div className="text-lg text-blue-600">{template.widget.name} ({template.widget.aux})</div>
                                        <StaticWidget />
                                    </WidgetContextProvider>
                                );
                            })}
                        </RenderContextProvider>
                    );
                })}
            </div>
        </PageThemeProvider>
    );
}

export function SectionsPage() {
    const campaign = new Campaign( // Shouldn't really need this to view sections.
        "1234",
        [],
        [],
        null,
        false
    );

    return (
        <CampaignContextProvider
            campaign={campaign}
            enableSaving={false}
        >
            <Sections />
        </CampaignContextProvider>
    );
}
