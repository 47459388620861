import { Divider } from '@mui/material'
import { FC } from 'react'
import { WidgetSettingContainer } from '../../../Design/WidgetSettingContainer'
import {
    Colors,
    Fonts,
    Margins,
    TextEditor,
    Width,
    Sizer,
    Alignment, LetterSpace, LineHeight,
} from '../../../EditMode/Design'
import { useWidget } from 'widgets-base'

interface Props {
}

const RichTextSetting: FC<Props> = () => {

    const { widget } = useWidget();

    return (
        <WidgetSettingContainer padding={18}>
            <TextEditor  value={widget?.settings?.content} />
            <Divider />
            <div style={{ display: 'flex', gap: '10px' }}>
                <Fonts
                    label="Select font"
                    defaultFont={widget?.appearanceSettings?.font?.family}
                />
                <Sizer
                    
                    label="Font size"
                    value={widget?.appearanceSettings?.font?.size}
                    name="size"
                />
            </div>
            <LetterSpace
                
                label={'Letter Space'}
                value={String(widget?.appearanceSettings?.font?.spacing)}
            />
            <LineHeight
                
                label={'Line Height'}
                value={`${widget?.appearanceSettings?.font?.lineHeight}`}
                name={'lineHeight'} />
            <Alignment align={widget?.appearanceSettings?.align} />
            <Width  width={widget?.appearanceSettings?.width} />
            <Margins
                topValue={widget?.appearanceSettings?.marginTop}
                bottomValue={widget?.appearanceSettings?.marginBottom}
                leftValue={widget?.appearanceSettings?.marginLeft}
                rightValue={widget?.appearanceSettings?.marginRight}
            />
            <Divider />
            <Colors colors={widget?.colourSettings}  />
        </WidgetSettingContainer>
    )
}

export default RichTextSetting
