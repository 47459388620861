import { AxiosResponse } from 'axios'
import instance from '../lib/http'

export default class AuthService {

    static async createUsername(username: string, uid: string): Promise<AxiosResponse> {
        return instance.post('/createUsername', { username, uid })
    }

    static async sendVerifyMail(email: string): Promise<AxiosResponse> {
        return instance.post('/sendEmailVerify', { email })
    }
}
