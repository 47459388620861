import {  ChangeEvent } from 'react'
import styled from 'styled-components'
import { useForm } from '../../../../../builder/context/form-state-old'
import { useWidget } from 'widgets-base'

interface Props {
    borderFieldsColor?: string
    backgroundFieldsColor?: string
}

export const InputField = ({ borderFieldsColor, backgroundFieldsColor }: Props) => {

    const { widget } = useWidget();
    const { getFieldValue, setFieldValue } = useForm();

    const settings = widget.settings
    const placeholder = settings.placeholder
    const type = settings.type
    const required = settings.required
    const name = settings.name

    const appearanceSettings = widget.appearanceSettings
    const width = appearanceSettings.width + '%'
    const paddingTop = appearanceSettings.paddingTop / 10 + 'em'
    const paddingBottom = appearanceSettings.paddingBottom / 10 + 'em'
    const paddingLeft = appearanceSettings.paddingLeft / 10 + 'em'
    const paddingRight = appearanceSettings.paddingRight / 10 + 'em'
    const marginTop = appearanceSettings.marginTop / 10 + 'em'
    const marginBottom = appearanceSettings.marginBottom / 10 + 'em'
    const marginLeft = appearanceSettings.marginLeft / 10 + 'em'
    const marginRight = appearanceSettings.marginRight / 10 + 'em'
    const borderTopLeftRadius = appearanceSettings.borderTopLeftRadius / 10 + 'em'
    const borderTopRightRadius = appearanceSettings.borderTopRightRadius / 10 + 'em'
    const borderBottomLeftRadius = appearanceSettings.borderBottomLeftRadius / 10 + 'em'
    const borderBottomRightRadius = appearanceSettings.borderBottomRightRadius / 10 + 'em'
    const borderTopWidth = appearanceSettings.borderTopWidth / 10 + 'em'
    const borderBottomWidth = appearanceSettings.borderBottomWidth / 10 + 'em'
    const borderLeftWidth = appearanceSettings.borderLeftWidth / 10 + 'em'
    const borderRightWidth = appearanceSettings.borderRightWidth / 10 + 'em'
    const borderWidth = `${borderTopWidth} ${borderRightWidth} ${borderBottomWidth} ${borderLeftWidth}`
    const alignment = appearanceSettings.alignment

    const label = settings.label
    const minimumValue = settings.minimumValue
    const maximumValue = settings.maximumValue
    const stepValue = settings.stepValue
    const weight = Number(appearanceSettings?.font?.weight) || 400

    const handleTextChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setFieldValue(name, e.target.value);
    }

    return (
        <Wrapper
            align={alignment}
            marginTop={marginTop}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            marginRight={marginRight}
            className="input_box"
        >
            <Container
                width={width}
                marginTop={marginTop}
                marginBottom={marginBottom}
                marginLeft={marginLeft}
                marginRight={marginRight}
                borderTopLeftRadius={borderTopLeftRadius}
                borderBottomLeftRadius={borderBottomLeftRadius}
                borderTopRightRadius={borderTopRightRadius}
                borderBottomRightRadius={borderBottomRightRadius}
            >
                {label && (
                    <label
                        style={{
                            width: '100%',
                            marginBottom: '0.5em',
                            fontWeight: weight,
                        }}
                        htmlFor={name}
                    >
                        {label}
                    </label>
                )}
                <input
                    onChange={handleTextChange}
                    name={name}
                    type={type}
                    style={{
                        backgroundColor: backgroundFieldsColor,
                        borderWidth: borderWidth,
                        borderColor: borderFieldsColor,
                        paddingBottom,
                        paddingLeft,
                        paddingRight,
                        paddingTop,
                    }}
                    placeholder={placeholder}
                    required={required}
                    min={minimumValue}
                    max={maximumValue}
                    step={stepValue}
                    value={getFieldValue(name) || ""}
                />
            </Container>
        </Wrapper>
    )
}

const Wrapper = styled.div<{
    align: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
}>`
    display: flex;
    align-items: ${(props) =>
        props.align === 'left' ? 'flex-start' : props.align === 'center' ? 'center' : 'flex-end'};
    justify-content: center;
    width: 100%;
    cursor: pointer;
    flex-direction: column;
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
`
const Container = styled.div<{
    width: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
    borderTopLeftRadius: string
    borderBottomLeftRadius: string
    borderTopRightRadius: string
    borderBottomRightRadius: string
}>`
    position: relative;
    display: flex;
    flex-direction: column;
    width: ${(props) => props.width};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
    border-top-left-radius: ${(props) => props.borderTopLeftRadius};
    border-top-right-radius: ${(props) => props.borderTopRightRadius};
    border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
    input {
        width: 100%;
        border-radius: inherit;
        padding: 1em;
    }
`
