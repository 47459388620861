import React from 'react'
import { PuffLoader } from 'react-spinners'
import styled from 'styled-components'

export const BackdropLoader = () => {
    return (
        <LoaderContsiner>
            <PuffLoader color="#5495f5" size={150} />
        </LoaderContsiner>
    )
}

const LoaderContsiner = styled.div`
    display: none;
    position: fixed;
    z-index: 99999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > span {
        color: #ffffff;
        font-size: 24px;
        margin-top: 18px;
    }
`
