import { FC, memo, MouseEvent } from 'react'
import styled from 'styled-components'
import { useRedirectAction } from '../../../../hooks/useRedirectAction'
import { LinkAction } from '../../../../modules/widgets/general/ILink'
import { useWidget } from 'widgets-base'

interface Props {
    editMode?: boolean
}

const LinkWidget: FC<Props> = ({ editMode }) => {

    const { widget } = useWidget();
    const handleAction = useRedirectAction(widget?.settings?.link)

    const handleClickOn = (e: MouseEvent<HTMLElement>) => {
        if (!editMode) {
            handleAction.action()
        }
    }

    const getContent = (type: string) => {
        if (type === LinkAction.GO_TO_EMAIL) {
            return `<a href=mailto:${widget.settings.link.linkUrl}>${widget.settings.link.linkTitle}</a>`
        } else if (type === LinkAction.REDIRECT) {
            return `<a href=${widget.settings.link.linkUrl} target='_blank'>${widget.settings.link.linkTitle}</a>`
        } else {
            return `<a>${widget.settings.link.linkTitle}</a>`
        }
    }

    const appearanceSettings = widget.appearanceSettings
    const colourSettings = widget.colourSettings
    const width = appearanceSettings.width + '%'
    const align = appearanceSettings.align
    const paddingTop = appearanceSettings.paddingTop / 10 + 'em'
    const paddingBottom = appearanceSettings.paddingBottom / 10 + 'em'
    const paddingLeft = appearanceSettings.paddingLeft / 10 + 'em'
    const paddingRight = appearanceSettings.paddingRight / 10 + 'em'
    const marginTop = appearanceSettings.marginTop / 10 + 'em'
    const marginBottom = appearanceSettings.marginBottom / 10 + 'em'
    const marginLeft = appearanceSettings.marginLeft / 10 + 'em'
    const marginRight = appearanceSettings.marginRight / 10 + 'em'
    const content = getContent(widget?.settings?.link?.type)

    const fontFamily = appearanceSettings.font.family || 'Poppins'
    const fontUrl = appearanceSettings?.font?.url
    const modFontFamily = fontFamily?.replace(/\s/g, '+')
    const color = colourSettings.textColour[0]
    const letterSpacing = appearanceSettings.font.spacing + 'px'
    const fontSize = widget.appearanceSettings ? widget.appearanceSettings.font.size + 'px' : '14px'
    const weight = appearanceSettings?.font?.weight
    const lineHeight = appearanceSettings?.font?.lineHeight || 1

    return (
        <LinkSection id={widget.id}>
            <style>
                {`@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`}
            </style>
            <Container
                width={width}
                paddingTop={paddingTop}
                paddingBottom={paddingBottom}
                paddingLeft={paddingLeft}
                paddingRight={paddingRight}
                marginTop={marginTop}
                marginBottom={marginBottom}
                marginLeft={marginLeft}
                marginRight={marginRight}
                onClick={handleClickOn}
            >
                <HeaderLink
                    fontFamily={fontFamily}
                    color={color}
                    fontSize={fontSize}
                    weight={weight}
                    align={align}
                    letterSpacing={letterSpacing}
                    lineHeight={lineHeight}
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            </Container>
        </LinkSection>
    )
}

export default memo(LinkWidget)

const LinkSection = styled.section`
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
`

const HeaderLink = styled.div<{
    fontFamily: string
    color: string
    fontSize: string
    letterSpacing: string
    align: string
    weight: string
    lineHeight: number
}>`
    font-family: ${(props) => props.fontFamily};
    font-size: ${(props) => props.fontSize};
    color: ${(props) => props.color};
    text-align: ${(props) => props.align};
    & > a {
        font-weight: ${(props) => +props.weight};
        letter-spacing: ${(props) => props.letterSpacing};
        text-decoration-line: underline;
        line-height: ${(props) => props.lineHeight};
        color: ${(props) => props.color};
    }
`

const Container = styled.div<{
    width: string
    paddingTop: string
    paddingBottom: string
    paddingLeft: string
    paddingRight: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
}>`
    position: relative;
    cursor: pointer;
    width: ${(props) => props.width};
    padding-top: ${(props) => props.paddingTop};
    padding-bottom: ${(props) => props.paddingBottom};
    padding-left: ${(props) => props.paddingLeft};
    padding-right: ${(props) => props.paddingRight};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    a {
        width: 100%;
        height: 100%;
        border-radius: inherit;
    }
`
