import { FC, FormEvent, useState } from 'react'
import styled from 'styled-components'
import { formFieldCheckRequired } from '../../../../helpers/form/formFieldCheckRequired'
import { Toast } from '../../../../helpers/Toast'
import { formFileUpload } from '../../../../lib/forms/formFileUpload'
import { sendFormToSlack } from '../../../../lib/slack/sendFormToSlack'
import { FormFields } from '../../../../modules/widgets/forms/IForm'
import FormService from '../../../../../services/FormService'
import { Button } from '../../../common/Button'
import RenderFormWidgets from '../RenderFormWidget'


import { FormStateContextProvider, useForm } from '../../../../../builder/context/form-state-old'
import { useWidget, useRenderContext } from 'widgets-base'
import { useCampaign } from '../../../../../builder/context/campaign'


interface Props {
    editMode?: boolean
}

const SubscribeForm: FC<Props> = ({ editMode = false }) => {
    
    const { campaign } = useCampaign();
    const { widget } = useWidget();
    const { getFormState, clearForm } = useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const { renderContext } = useRenderContext();

    const appearanceSettings = widget?.appearanceSettings
    const colourSettings = widget?.colourSettings
    const width = appearanceSettings.width + '%'
    const backgroundImageUrl = widget?.settings?.src
    const backgroundColor = colourSettings['background colour'][0]
    const borderColor = colourSettings['border colour'][0]
    const textColor = colourSettings?.['text colour']?.[0]
    const buttonColor = colourSettings?.['button colour']?.[0]
    const paddingTop = appearanceSettings.paddingTop / 10 + 'em'
    const paddingBottom = appearanceSettings.paddingBottom / 10 + 'em'
    const paddingLeft = appearanceSettings.paddingLeft / 10 + 'em'
    const paddingRight = appearanceSettings.paddingRight / 10 + 'em'
    const borderTopWidth = appearanceSettings.borderTopWidth / 10 + 'em'
    const borderBottomWidth = appearanceSettings.borderBottomWidth / 10 + 'em'
    const borderLeftWidth = appearanceSettings.borderLeftWidth / 10 + 'em'
    const borderRightWidth = appearanceSettings.borderRightWidth / 10 + 'em'
    const borderWidth = borderTopWidth && borderRightWidth && borderBottomWidth && borderLeftWidth
    const marginTop = appearanceSettings.marginTop / 10 + 'em'
    const marginBottom = appearanceSettings.marginBottom / 10 + 'em'
    const marginLeft = appearanceSettings?.marginLeft / 10 + 'em'
    const marginRight = appearanceSettings?.marginRight / 10 + 'em'
    const borderTopLeftRadius = appearanceSettings.borderTopLeftRadius / 10 + 'em'
    const borderTopRightRadius = appearanceSettings.borderTopRightRadius / 10 + 'em'
    const borderBottomLeftRadius = appearanceSettings.borderBottomLeftRadius / 10 + 'em'
    const borderBottomRightRadius = appearanceSettings.borderBottomRightRadius / 10 + 'em'
    const font = widget.appearanceSettings.font || { family: 'Open Sans' }
    const fontSize = widget.appearanceSettings.font.size + 'px' || '8px'
    const fontUrl = widget.appearanceSettings?.font?.url
    const modFontFamily = font.family.replace(/\s/g, '+')
    const border = `${borderWidth} solid ${borderColor}`

    const parentStyles = {
        backgroundImage: `url(${backgroundImageUrl || ''})`,
        borderTopLeftRadius,
        borderTopRightRadius,
        borderBottomLeftRadius,
        borderBottomRightRadius,
        borderColor,
        paddingTop,
        paddingBottom,
        paddingLeft,
        paddingRight,
        border,
        marginTop,
        marginBottom,
        marginLeft,
        marginRight,
        fontFamily: font.family,
        fontSize,
        textColor,
    }

    const handleSubmit = async (formState: FormFields): Promise<void> => {
        setLoading(true)

        try {
            const errors = formFieldCheckRequired(formState)

            if (errors.length > 0) {
                errors.forEach((error) => {
                    Toast(error, 'error')
                })
                setLoading(false)
                return
            }

            let filesToUpload = []

            formState.fields.map((field) => {
                // check if is file upload type - upload separately
                if (field.fieldType === 'upload') {
                    filesToUpload.push({
                        fieldName: field.fieldName,
                        files: field.value.files,
                        fileType: field.value.fileType,
                    })
                }
                return filesToUpload
            })

            let userId = campaign.uid
            if (!userId) {
                userId = campaign.creatorUID
            }

            const uploadData = await formFileUpload(
                filesToUpload,
                userId,
                campaign._id,
                formState.formId
            )

            if (filesToUpload.length > 0 && uploadData.length === 0) {
                Toast('Please select file.', 'error', widget.id)
                setLoading(false)
                return
            }

            let fields = formState.fields

            uploadData.forEach((data) => {
                const index = formState.fields.findIndex(
                    (field) => field.fieldName === data.fieldName
                )

                if (index === -1) {
                    return
                }

                const newField = { ...formState.fields[index], value: data.data }

                fields = [
                    ...formState.fields.slice(0, index),
                    newField,
                    ...formState.fields.slice(index + 1),
                ]
            })

            await FormService.submitForm({
                fields,
                uid: userId,
                campaignId: campaign._id,
                formId: formState.formId,
                formName: formState.formName,
            })

            if (widget.settings.isIntegrate) {
                // send message to Slack
                await sendFormToSlack(
                    widget.settings.integrates.slack,
                    fields,
                    formState.formName,
                    campaign.campaignName,
                    campaign.metaImageUrl
                )
            }

            resetFormState()
            setLoading(false)
            Toast(widget.settings.toastMessage || 'Form submitted!', 'success', widget.id)
        } catch (error) {
            resetFormState()
            setLoading(false)
            Toast(error.message, 'error', widget.id)
        }
    }

    const resetFormState = (): void => {
        clearForm();
    }

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (!editMode) {
            handleSubmit(getFormState())
        }
    }

    if (renderContext.isStaticExport) {
        return undefined;
    }

    return (
        <FormSection id={widget.id} backgroundColor={backgroundColor} style={parentStyles}>
            <style>
                {!fontUrl
                    ? `@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`
                    : `@font-face {
                        font-family: '${font.family}';
                        src: url('${fontUrl}');
                        font-style: normal;
                      }
                      
                      .header-text {
                        font-family: '${font.family}';
                      }`}
            </style>
            <Container
                inLine={widget?.children?.length > 1}
                width={width}
                textColor={textColor}
                buttonColor={buttonColor}
                align={widget?.appearanceSettings?.align}
                fontFamily={font.family}
            >
                <h1>{widget?.settings?.label}</h1>
                <span>Subscribe for updates</span>
                <form name={widget.name} onSubmit={onSubmit}>
                    <RenderFormWidgets
                        widgets={widget.children || widget.widgets || []}
                        gap={widget?.appearanceSettings?.spacing + 'px'}
                        borderFieldsColor={widget?.colourSettings?.['border fields colour']?.[0]}
                        backgroundFieldsColor={
                            widget?.colourSettings?.['background fields colour']?.[0]
                        }
                    />
                    <Button
                        loading={loading}
                        width={widget.children?.length > 1 ? '80%' : '135px'}
                        type="submit"
                        style={{ fontFamily: font.family }}
                    >
                        SUBSCRIBE
                    </Button>
                </form>
            </Container>
        </FormSection>
    )
}

export default function ({ editMode = false }) {
    const { campaign } = useCampaign();
    const { widget } = useWidget();

    return (
        <FormStateContextProvider>
            <SubscribeForm editMode={editMode} />
        </FormStateContextProvider>
    );
}

const FormSection = styled.section<{
    backgroundColor: string
}>`
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.backgroundColor};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`

const Container = styled.div<{
    inLine: boolean
    width: string
    textColor: string
    buttonColor: string
    align: string
    fontFamily: string
}>`
    display: flex;
    flex-direction: column;
    align-items: ${(props) => props.align};
    justify-content: center;
    max-width: 700px;
    width: ${(props) => props.width};
    color: ${(props) => props.textColor};

    h1 {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 24px;
        margin-bottom: 5px;
        text-align: center;
    }

    & > span {
        margin-bottom: 10px;
    }

    form {
        width: 100%;
        max-width: 500px;
        display: flex;
        padding: 0 10px;
        align-items: center;
        flex-direction: ${(props) => (props.inLine ? 'column' : 'row')};
    }

    input {
        border: 0;
        border-radius: 40px;
        padding: 10px 14px;
        margin-right: 10px;
        flex: 1;
    }

    input::placeholder {
        color: ${(props) => props.textColor};
        font-family: ${(props) => props.fontFamily};
        opacity: 0.6;
    }

    button {
        border: 0;
        border-radius: 40px;
        outline: none;
        padding: 13px;
        background-color: ${(props) => props.buttonColor};
        color: ${(props) => props.textColor};
        font-weight: bold;
        cursor: pointer;
    }

    label,
    .MuiTypography-root {
        font-family: ${(props) => props.fontFamily} !important;
    }
`
