import text from "../../widgets/text";
import label from "../../widgets/label";
import button from "../../widgets/button";
import group from "../../widgets/group";
import image from "../../widgets/image";
import link from "../../widgets/link";
import video from "../../widgets/video";
import form from "../../widgets/form";
import field from "../../widgets/field";
import input from "../../widgets/fields/input";
import checkbox from "../../widgets/fields/checkbox";
import submitButton from "../../widgets/fields/submit-button";
import fileUpload from "../../widgets/fields/file-upload";
import textArea from "../../widgets/fields/text-area";
import { isDescendent } from "../lib/utils";
import spinToWin from 'spin-to-win-1';
import scratchie from 'scratchie-1';
import chatbot from 'chatbot-1';
import { IWidgetType, IWidget } from "widgets-base";

//
// Details of each widget type.
//
const widgetTypes: { [index: string]: IWidgetType } = {
    text,
    label,
    button,
    group,
    image,
    link,
    video,
    form,
    input,
    checkbox,
    "submit-button": submitButton,
    field,
    "text-area": textArea,
    "file-upload": fileUpload,
    "spin-to-win@1": spinToWin,
    "scratchie@1": scratchie,
    "chatbot@1": chatbot,
};


//
// Get the type of a widget.
//
export function getWidgetType(widgetType: string): IWidgetType | undefined {
    if (widgetType === undefined) {
        throw new Error(`Widget type is undefined`);
    }
    return widgetTypes[widgetType];
}

//
// Gets the name of the widget.
//
export function getWidgetName(widget: IWidget, defaultValue: string = "Unknown"): string {
    if (widget.name) {
        return widget.name;
    }

    const widgetType = getWidgetType(widget.xtype);
    if (widgetType) {
        return widgetType.name;
    }

    return defaultValue;
}

//
// Returns true if the requested widget is a group widget.
//
export function isGroupWidget(widget: IWidget): boolean {
    const type = widget.xtype;
    return type === "group"
        || type === "form"
        || type === "button"
        || type === "link";
}

//
// Returns true if the widget is a new style widget.
//
export function isNewStyleWidget(widget: IWidget): boolean {
    return !!widget.xtype;
}

//
// A "ruling" that was made allowing a widget to be dropped or not.
//
export interface IDropRuling {
    //
    // Set to true if the widget drop is allowed.
    //
    allowed: boolean;

    //
    // If not allowed, this is the reason why.
    //
    noDropMsg?: string;
}

//
// Determine if one widget can be dropped inside another.
//
export function determineDropRuling(droppedWidget: IWidget | undefined, targetAncestry: IWidget[]): IDropRuling {

    if (!droppedWidget) {
        return {
            allowed: false,
        };
    }

    if (targetAncestry.length > 0) {
        if (isDescendent(droppedWidget, targetAncestry[0])) {
            // Disable dropping when this is widget is a descendent of the widget being dragged.
            return {
                allowed: false,
                noDropMsg: "Can't place into self",
            };
        }

        for (const ancestor of targetAncestry) {
            if (ancestor.grouped) {
                // Disable dropping when an ancestor is locked.
                return {
                    allowed: false,
                    noDropMsg: "Parent is locked",
                };
            }
        }
   }

    const droppedWidgetType = getWidgetType(droppedWidget.xtype || droppedWidget.type);
    if (droppedWidgetType) {
        if (droppedWidgetType.antiAffinity && droppedWidgetType.antiAffinity.length > 0) {
            // Disable dropping when the widget being dragged is not allowed to be placed inside the target widget.
            for (const ancestor of targetAncestry) {
                const targetWidgetType = ancestor.xtype || ancestor.type;
                if (droppedWidgetType.antiAffinity.indexOf(targetWidgetType) >= 0) {
                    return {
                        allowed: false,
                        noDropMsg: `Can't place a "${droppedWidgetType.name}" into a ${targetWidgetType}`,
                    };
                }
            }
        }

        if (droppedWidgetType.affinity && droppedWidgetType.affinity.length > 0) {
            // Disable dropping when the widget being dragged is only allowed to be placed inside the target widget.
            for (const ancestor of targetAncestry) {
                const targetWidgetType = ancestor.xtype || ancestor.type;
                if (droppedWidgetType.affinity.indexOf(targetWidgetType) >= 0) {
                    return { allowed: true };
                }
            }

            // Required ancestor was not found.
            return {
                allowed: false,
                noDropMsg: 
                    droppedWidgetType.affinity.length > 1 
                        ? `Can only place a "${droppedWidgetType.name}" into one of ${droppedWidgetType.affinity.join(", ")}`
                        : `Can only place a "${droppedWidgetType.name}" into a ${droppedWidgetType.affinity[0]}`,
            };
        }
    }

    return {
        allowed: true,
    };
}


//
// Finds the parent widget (if any) that is marked as the selection target.
//
export function findSelectionTarget(widgets: IWidget[]): number | undefined {
    for (let widgetIndex = 0; widgetIndex < widgets.length; widgetIndex++) {
        const widget = widgets[widgetIndex];
        const widgetType = getWidgetType(widget.xtype || widget.type);
        if (widgetType) {
            if (widgetType.selectionTarget) {
                // 
                // This widget assumes the selection.
                //
                return widgetIndex;
            }
        }
    }

    return undefined;
}