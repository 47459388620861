export const numCategories = 1;
export const numSections = 1;
export const categories: any = [
    {
        "name": "New sections",
        "widgets": [
            {
                "widget": {
                    "id": "298455a172981635fbf1686fae0d7144072a8dbe6f5f28f71711e2ca7502185a",
                    "name": "hero-heading-left",
                    "xtype": "group",
                    "properties": {
                        "columnGap": 60,
                        "rowGap": 60,
                        "justifyContent": "center",
                        "alignItems": "flex-start",
                        "paddingTop": 48,
                        "paddingRight": 18,
                        "paddingBottom": 48,
                        "paddingLeft": 18,
                        "display": "flex",
                        "small": {
                            "flexDirection": "column",
                            "alignItems": "center"
                        }
                    },
                    "children": [
                        {
                            "id": "0898de2b2b0f906eac91fffea34d039f43c2ca36462513937c532e399789cde3",
                            "name": "container",
                            "xtype": "group",
                            "properties": {
                                "maxWidth": 900,
                                "columnGap": 60,
                                "rowGap": 60,
                                "justifyContent": "flex-start",
                                "alignItems": "center",
                                "display": "flex",
                                "small": {
                                    "flexDirection": "column"
                                }
                            },
                            "children": [
                                {
                                    "id": "75012a599738c280d6837e87db804de80e47a4274fe4ba4fce1976707428d9e2",
                                    "name": "column",
                                    "xtype": "group",
                                    "properties": {
                                        "columnGap": 18,
                                        "rowGap": 18,
                                        "flexDirection": "column",
                                        "justifyContent": "flex-start",
                                        "alignItems": "flex-start",
                                        "display": "flex",
                                        "width": "100%",
                                    },
                                    "children": [
                                        {
                                            "id": "49ff58d359b77eaf5a1a67bb408bde4b10494cb7822a608ba46fbb3cf635546d",
                                            "name": "column",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 18,
                                                "rowGap": 18,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "4dc4d75a1df7a83436fb671ab6feef84c70299474f181a262af8ce38ee2132c5",
                                                    "name": "text",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "marginTop": 15,
                                                        "marginBottom": 7.5,
                                                        "fontWeight": "700",
                                                        "small": {
                                                            "marginTop": "0.67em",
                                                            "marginBottom": "0.67em",
                                                            "fontWeight": "bold"
                                                        }
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "cb1ef888aad0984918a47905b8f3ae9cccdb2a2d9e6abf15aa929069cc6bbbc1",
                                                            "xtype": "text",
                                                            "properties": {
                                                                "content": "<h1>Title Copy Goes Here Be Awesome</h1>",
                                                                "prompt": "What’s a good name for this business?",
                                                                "tag": "h1",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "e533b46e7edeb9342f604e6c2ced4c30de756919f14f2fd639dadccb7831ed5d",
                                                    "name": "text-6",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "fontSize": 13.5,
                                                        "marginBottom": 7.5,
                                                        "fontWeight": "400"
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "649a52466382d02b38257a5577eb097c61b936696363b13ca08608584673c04f",
                                                            "xtype": "text",
                                                            "properties": {
                                                                "content": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.</p>",
                                                                "prompt": "Give me a tagline for the business to go underneath the main heading.",
                                                                "tag": "p",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "id": "e0d421e0aa9fd6dd973b064eb5253f3ceb64ffa8caef2074da8209befd76c9b3",
                                            "name": "actions",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 12,
                                                "rowGap": 12,
                                                "flexGrow": "0",
                                                "flexShrink": "1",
                                                "flexBasis": "auto",
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "paddingTop": 12,
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "9ffb184dbc32c01e0a786671e36d5d6bf9cf0410341992855c3a236045fb5ca6",
                                                    "name": "button",
                                                    "xtype": "button",
                                                    "properties": {
                                                        "link": {
                                                            "target": "url",
                                                            "url": "#",
                                                            "page": 0
                                                        },
                                                        "columnGap": 6,
                                                        "rowGap": 6,
                                                        "flexGrow": "0",
                                                        "flexShrink": "1",
                                                        "flexBasis": "auto",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "paddingTop": 9,
                                                        "paddingRight": 18,
                                                        "paddingBottom": 9,
                                                        "paddingLeft": 18,
                                                        "textDecorationLine": "none",
                                                        "textDecorationThickness": "initial",
                                                        "textDecorationStyle": "initial",
                                                        "textDecorationColor": "initial",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "830399246b319c911e0144cffc41ef3b1e43623afefa7a8d99f00e8e5d6e5d2a",
                                                            "name": "text-7",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 9,
                                                                "fontWeight": "500"
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "7ba720110308f6102f424571418b410b47360dfebed09859348ba5a9b5e22f22",
                                                                    "xtype": "text",
                                                                    "properties": {
                                                                        "content": "<p>GET STARTED</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "id": "517e76fa5b89663720ecd7bbc8e57ad063559520f0a919c2d7c929accd1a5fb7",
                                    "name": "column-3",
                                    "xtype": "group",
                                    "properties": {
                                        "columnGap": 7.5,
                                        "rowGap": 7.5,
                                        "justifyContent": "flex-start",
                                        "alignItems": "flex-start",
                                        "display": "flex",
                                    },
                                    "children": [
                                        {
                                            "id": "7055a53cd148d3f089dfeb72764a9d9f609ac2667805ab692a5b808176df25d5",
                                            "name": "image-wrapper",
                                            "xtype": "group",
                                            "properties": {
                                                "flexDirection": "column",
                                                "justifyContent": "center",
                                                "alignItems": "center",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "3264232abc69d6bb0bb297f2f94c624818f9cb566dda3c77b394f0db815cf94e",
                                                    "name": "image",
                                                    "xtype": "image",
                                                    "properties": {
                                                        "borderTopWidth": 0,
                                                        "borderRightWidth": 0,
                                                        "borderBottomWidth": 0,
                                                        "borderLeftWidth": 0,
                                                        "borderTopStyle": "initial",
                                                        "borderRightStyle": "initial",
                                                        "borderBottomStyle": "initial",
                                                        "borderLeftStyle": "initial",
                                                        "borderTopColor": "initial",
                                                        "borderRightColor": "initial",
                                                        "borderBottomColor": "initial",
                                                        "borderLeftColor": "initial",
                                                        "borderImageSource": "initial",
                                                        "borderImageSlice": "initial",
                                                        "borderImageWidth": "initial",
                                                        "borderImageOutset": "initial",
                                                        "borderImageRepeat": "initial",
                                                        "maxWidth": "100%",
                                                        "verticalAlign": "middle",
                                                        "display": "flex",
                                                        "objectFit": "cover",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f91002d439872d9cfc2c9b_Image.png",
                                                        "alt": "",
                                                        "maxHeight": "100%",
                                                        "minWidth": 350,
                                                    },
                                                    "children": []
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                "scale": "5px"
            },
            {
                "widget": {
                    "id": "f21cf92580424163e53fb6d72311a7c207953a4fdd8a558bf5235e8d3c1357af",
                    "name": "hero-heading-right-2",
                    "xtype": "group",
                    "properties": {
                        "columnGap": 60,
                        "rowGap": 60,
                        "justifyContent": "center",
                        "alignItems": "flex-start",
                        "paddingTop": 48,
                        "paddingRight": 18,
                        "paddingBottom": 48,
                        "paddingLeft": 18,
                        "display": "flex",
                        "small": {
                            "flexDirection": "column",
                            "alignItems": "center"
                        }
                    },
                    "children": [
                        {
                            "id": "1caa916a3534379303755d5bacafc9dbd0b1cff0002587bf41c7d624ab41e14f",
                            "name": "container-6",
                            "xtype": "group",
                            "properties": {
                                "maxWidth": 900,
                                "columnGap": 60,
                                "rowGap": 60,
                                "justifyContent": "flex-start",
                                "alignItems": "center",
                                "display": "flex",
                                "small": {
                                    "flexDirection": "column"
                                }
                            },
                            "children": [
                                {
                                    "id": "24537ef71cbfe4d205130d440271e944999832661ba5817849fcf3f0d47ebb50",
                                    "name": "column-22",
                                    "xtype": "group",
                                    "properties": {
                                        "columnGap": 7.5,
                                        "rowGap": 7.5,
                                        "justifyContent": "flex-start",
                                        "alignItems": "flex-start",
                                        "display": "flex",
                                    },
                                    "children": [
                                        {
                                            "id": "65c594f42691fa0b750f0ea0741f0657f11edfef0852d31297c1ac325c0339e8",
                                            "name": "image-wrapper-15",
                                            "xtype": "group",
                                            "properties": {
                                                "flexDirection": "column",
                                                "justifyContent": "center",
                                                "alignItems": "center",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "2dabc5db23aea0a03efb62dded8867bd587f10ce8898dbb6675f3c9e824973ea",
                                                    "name": "image-22",
                                                    "xtype": "image",
                                                    "properties": {
                                                        "borderTopWidth": 0,
                                                        "borderRightWidth": 0,
                                                        "borderBottomWidth": 0,
                                                        "borderLeftWidth": 0,
                                                        "borderTopStyle": "initial",
                                                        "borderRightStyle": "initial",
                                                        "borderBottomStyle": "initial",
                                                        "borderLeftStyle": "initial",
                                                        "borderTopColor": "initial",
                                                        "borderRightColor": "initial",
                                                        "borderBottomColor": "initial",
                                                        "borderLeftColor": "initial",
                                                        "borderImageSource": "initial",
                                                        "borderImageSlice": "initial",
                                                        "borderImageWidth": "initial",
                                                        "borderImageOutset": "initial",
                                                        "borderImageRepeat": "initial",
                                                        "maxWidth": "100%",
                                                        "verticalAlign": "middle",
                                                        "display": "flex",
                                                        "objectFit": "cover",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910993109a0be17c4bbec_Image.png",
                                                        "alt": "",
                                                        "maxHeight": "100%"
                                                    },
                                                    "children": []
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "id": "cbcc00ca76a64b18b8f001f14076cc39e324f00a3695c320000fdc38843744bc",
                                    "name": "column-25",
                                    "xtype": "group",
                                    "properties": {
                                        "columnGap": 18,
                                        "rowGap": 18,
                                        "flexDirection": "column",
                                        "justifyContent": "flex-start",
                                        "alignItems": "flex-start",
                                        "display": "flex",
                                        "width": "100%",
                                    },
                                    "children": [
                                        {
                                            "id": "5dc0996cbedaecb49475cfaaad2b4b7d25037755d12142a391a4f3adf4f76ce9",
                                            "name": "column-25",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 18,
                                                "rowGap": 18,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "a151f1f26816703f4305486ad689a0ca8ee5c55abebf38e56717dd7bce11cf46",
                                                    "name": "text-56",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "fontWeight": "700",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "ddf511ec61fdcbc2fb699fa9235ac489e70ad019aa78b5e0a168cce806bbf2d4",
                                                            "xtype": "text",
                                                            "properties": {
                                                                "content": "<h1>Title Copy Goes Here Be Awesome</h1>",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "be70b6db4bbd073a406e39ba571750ec258eb9f573db653ac3b025bdde0473ce",
                                                    "name": "text-57",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "fontSize": 13.5,
                                                        "fontWeight": "400"
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "9337131eee5fe38267cf6d65f2fcd6e4f6759dbf32794e405af637325fe95d4f",
                                                            "xtype": "text",
                                                            "properties": {
                                                                "content": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat.</p>",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "id": "b9f246c2976cfe4b87f74709c43db8fab2c62c6d65fc199cdcf69174f0170a7a",
                                            "name": "actions",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 12,
                                                "rowGap": 12,
                                                "flexGrow": "0",
                                                "flexShrink": "1",
                                                "flexBasis": "auto",
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "paddingTop": 12,
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "7395d1dca24bf5adb13573e47bb085aad6a4f8c6db194ea66aa9b02d3b28d009",
                                                    "name": "button-7",
                                                    "xtype": "button",
                                                    "properties": {
                                                        "link": {
                                                            "target": "url",
                                                            "url": "#",
                                                            "page": 0
                                                        },
                                                        "columnGap": 6,
                                                        "rowGap": 6,
                                                        "flexGrow": "0",
                                                        "flexShrink": "1",
                                                        "flexBasis": "auto",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "paddingTop": 9,
                                                        "paddingRight": 18,
                                                        "paddingBottom": 9,
                                                        "paddingLeft": 18,
                                                        "textDecorationLine": "none",
                                                        "textDecorationThickness": "initial",
                                                        "textDecorationStyle": "initial",
                                                        "textDecorationColor": "initial",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "ed9b8735a122748c78f53aee7a4bc07bba5a9191401717d236ef7f7171d7758a",
                                                            "name": "text-17",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 9,
                                                                "fontWeight": "500"
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "b423beefd6636077b66952ca4b2402d348e089fa8097057b90277bdb8f325218",
                                                                    "xtype": "text",
                                                                    "properties": {
                                                                        "content": "<p>GET STARTED</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                "scale": "5px"
            },
            {
                "widget": {
                    "id": "98055f2666ee6b98c27587f65d1706671c96655cb69f7f32927928862d31a47c",
                    "name": "hero-stack",
                    "xtype": "group",
                    "properties": {
                        "columnGap": 60,
                        "rowGap": 60,
                        "flexDirection": "column",
                        "justifyContent": "flex-start",
                        "alignItems": "center",
                        "paddingTop": 48,
                        "paddingRight": 18,
                        "paddingBottom": 48,
                        "paddingLeft": 18,
                        "display": "flex",
                    },
                    "children": [
                        {
                            "id": "5ccb26d5fa9aa3264ad453770e4c10a58758fbd564d6e82f9fda18be018fd61d",
                            "name": "small-container",
                            "xtype": "group",
                            "properties": {
                                "maxWidth": 720,
                                "columnGap": 30,
                                "rowGap": 30,
                                "flexDirection": "column",
                                "justifyContent": "flex-start",
                                "alignItems": "center",
                                "display": "flex",
                            },
                            "children": [
                                {
                                    "id": "40e5c40372383b4f448d8f7faa1a4cec74d794fe0294a643959b82277a79f9d7",
                                    "name": "title-section",
                                    "xtype": "group",
                                    "properties": {
                                        "columnGap": 18,
                                        "rowGap": 18,
                                        "flexDirection": "column",
                                        "justifyContent": "flex-start",
                                        "alignItems": "center",
                                        "display": "flex",
                                    },
                                    "children": [
                                        {
                                            "id": "0ef157dc775326493e6fbee710f6e670dbf5c4c88263ef42ddf3118f75138a66",
                                            "name": "title-section",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 18,
                                                "rowGap": 18,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "center",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "5976481244612d3f1ecac8f249f394e95ed9bd91efa1bccaefd9942736d4ba38",
                                                    "name": "text-10",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "textAlign": "center",
                                                        "fontWeight": "700"
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "3b1a22f196f9eaaea39d5658a6077bf032cae591a3af5ccf22f3cdafecce80c5",
                                                            "xtype": "text",
                                                            "properties": {
                                                                "content": "<h1>Title Copy Goes Here</h1>",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "c8677442625d4f84f3998aee05fd10eddbc2f8fa4a96b972e73bdb129ccd2b02",
                                                    "name": "lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit-suspendisse-varius-enim-in-eros-elementum-tristique",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "textAlign": "center",
                                                        "fontWeight": "400"
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "3ae9881fd8d37360d01765c5b2cf0977028f18bdf8a25f68285c0c75038c055c",
                                                            "xtype": "text",
                                                            "properties": {
                                                                "content": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</p>",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "id": "98680b2e99aadda5e8e196943fd749ee3ed0b0c8e8d40e4fcc8b2ebd2872f07d",
                                            "name": "actions",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 12,
                                                "rowGap": 12,
                                                "flexGrow": "0",
                                                "flexShrink": "1",
                                                "flexBasis": "auto",
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "paddingTop": 12,
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "b4910288fb90e40213dc05f5f7416eeaa265313dcab9e89de8b7af350cd41ea2",
                                                    "name": "button",
                                                    "xtype": "button",
                                                    "properties": {
                                                        "link": {
                                                            "target": "url",
                                                            "url": "#",
                                                            "page": 0
                                                        },
                                                        "columnGap": 6,
                                                        "rowGap": 6,
                                                        "flexGrow": "0",
                                                        "flexShrink": "1",
                                                        "flexBasis": "auto",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "paddingTop": 9,
                                                        "paddingRight": 18,
                                                        "paddingBottom": 9,
                                                        "paddingLeft": 18,
                                                        "textDecorationLine": "none",
                                                        "textDecorationThickness": "initial",
                                                        "textDecorationStyle": "initial",
                                                        "textDecorationColor": "initial",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "ae55c93fcedee8795ea335baf222883de252c85148fd4fd7d7a5246fc4806d94",
                                                            "name": "text-11",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 9,
                                                                "fontWeight": "500"
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "c047b3ef4470bd16e25ee149ab711366eaf6fd95020c7221c20bee6946be8966",
                                                                    "xtype": "text",
                                                                    "properties": {
                                                                        "content": "<p>GET STARTED</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "id": "335d847f6b10f2caff28240ad3adc3ae2b7d46d3a3f041fda06cc4023c9d934a",
                                    "name": "image-wrapper-2",
                                    "xtype": "group",
                                    "properties": {
                                        "flexDirection": "column",
                                        "justifyContent": "flex-start",
                                        "alignItems": "flex-start",
                                        "display": "flex",
                                    },
                                    "children": [
                                        {
                                            "id": "333d349729959dcd01a9fbe760d6025d526f5348ba84eeeec222882203d33d43",
                                            "name": "image-2",
                                            "xtype": "image",
                                            "properties": {
                                                "borderTopWidth": 0,
                                                "borderRightWidth": 0,
                                                "borderBottomWidth": 0,
                                                "borderLeftWidth": 0,
                                                "borderTopStyle": "initial",
                                                "borderRightStyle": "initial",
                                                "borderBottomStyle": "initial",
                                                "borderLeftStyle": "initial",
                                                "borderTopColor": "initial",
                                                "borderRightColor": "initial",
                                                "borderBottomColor": "initial",
                                                "borderLeftColor": "initial",
                                                "borderImageSource": "initial",
                                                "borderImageSlice": "initial",
                                                "borderImageWidth": "initial",
                                                "borderImageOutset": "initial",
                                                "borderImageRepeat": "initial",
                                                "maxWidth": "100%",
                                                "verticalAlign": "middle",
                                                "display": "flex",
                                                "objectFit": "cover",
                                                "flexDirection": "column",
                                                "justifyContent": "center",
                                                "alignItems": "center",
                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910a082a4d59b14adb3aa_Image.png",
                                                "alt": "",
                                                "maxHeight": "100%"
                                            },
                                            "children": []
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                "scale": "5px"
            },
            {
                "widget": {
                    "id": "0490d801f45c86e5533e44270180ab5a3c6368e3a6a67a8b5f2ad25fdcfdb8f5",
                    "name": "hero-no-image",
                    "xtype": "group",
                    "properties": {
                        "columnGap": 60,
                        "rowGap": 60,
                        "flexDirection": "column",
                        "justifyContent": "flex-start",
                        "alignItems": "center",
                        "paddingTop": 48,
                        "paddingRight": 18,
                        "paddingBottom": 48,
                        "paddingLeft": 18,
                        "display": "flex",
                    },
                    "children": [
                        {
                            "id": "524c238eb623298ba6fb0403b002176ea7058d717664c5e8c83f1231ec1526fa",
                            "name": "small-container-2",
                            "xtype": "group",
                            "properties": {
                                "maxWidth": 720,
                                "columnGap": 30,
                                "rowGap": 30,
                                "backgroundColor": "rgb(245, 247, 250)",
                                "borderTopLeftRadius": 12,
                                "borderTopRightRadius": 12,
                                "borderBottomRightRadius": 12,
                                "borderBottomLeftRadius": 12,
                                "flexDirection": "column",
                                "justifyContent": "flex-start",
                                "alignItems": "center",
                                "paddingTop": 24,
                                "paddingRight": 24,
                                "paddingBottom": 24,
                                "paddingLeft": 24,
                                "display": "flex",
                            },
                            "children": [
                                {
                                    "id": "708f70cd597fc567a1c79211163a332f2ddebf1d6761ae306169883744d44d7e",
                                    "name": "content",
                                    "xtype": "group",
                                    "properties": {
                                        "columnGap": 18,
                                        "rowGap": 18,
                                        "flexDirection": "column",
                                        "justifyContent": "flex-start",
                                        "alignItems": "center",
                                        "display": "flex",
                                    },
                                    "children": [
                                        {
                                            "id": "c4aa65e44e746a949cf38bffaa2e99e04b0132aa3d8ed13084b9ef6a7bec67a2",
                                            "name": "text-12",
                                            "xtype": "group",
                                            "properties": {
                                                "textAlign": "center",
                                                "fontWeight": "700"
                                            },
                                            "children": [
                                                {
                                                    "id": "68eb0da4d347f847fa6ea18da13e1893ea75179f65be7ad4924ced6589a2dfea",
                                                    "xtype": "text",
                                                    "properties": {
                                                        "content": "<h1>Title Copy Goes Here</h1>",
                                                        "maxWidth": "100%"
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            "id": "d909da017dbc368ea88a19b2ce2f5d6beea9971c897c231481c751204ba225a5",
                                            "name": "lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit-suspendisse-varius-enim-in-eros-elementum-tristique",
                                            "xtype": "group",
                                            "properties": {
                                                "textAlign": "center",
                                                "fontWeight": "400"
                                            },
                                            "children": [
                                                {
                                                    "id": "4eb19339723a24fba2edeaad3abacb07227543489c82c02b43581366bec62015",
                                                    "xtype": "text",
                                                    "properties": {
                                                        "content": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</p>",
                                                        "maxWidth": "100%"
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            "id": "92300ffebcb0a68d6d0ba5bb6747990923a5c5b5a65ae1ddf77ffcd2c26deb26",
                                            "name": "button",
                                            "xtype": "button",
                                            "properties": {
                                                "link": {
                                                    "target": "url",
                                                    "url": "#",
                                                    "page": 0
                                                },
                                                "columnGap": 6,
                                                "rowGap": 6,
                                                "flexGrow": "0",
                                                "flexShrink": "1",
                                                "flexBasis": "auto",
                                                "justifyContent": "center",
                                                "alignItems": "center",
                                                "paddingTop": 9,
                                                "paddingRight": 18,
                                                "paddingBottom": 9,
                                                "paddingLeft": 18,
                                                "textDecorationLine": "none",
                                                "textDecorationThickness": "initial",
                                                "textDecorationStyle": "initial",
                                                "textDecorationColor": "initial",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "c52d193d6aec2ae7ea2190dc90e314f433a952aa3f9a6cb265f5b9d06484b89f",
                                                    "name": "text-13",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "fontSize": 9,
                                                        "fontWeight": "500"
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "295404c352cfa61f8ded43eb93fbc182dd75acd0ccbe9110705dec94c6d1c9ec",
                                                            "xtype": "text",
                                                            "properties": {
                                                                "content": "<p>GET STARTED</p>",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                "scale": "5px"
            },
            {
                "widget": {
                    "id": "eecbc115592387a6ed8f64bdbd83c886af3d8a70ad24c0702deefa13efe5921f",
                    "name": "contact-form",
                    "xtype": "group",
                    "properties": {
                        "columnGap": 48,
                        "rowGap": 48,
                        "flexDirection": "column",
                        "justifyContent": "flex-start",
                        "alignItems": "center",
                        "paddingTop": 48,
                        "paddingRight": 18,
                        "paddingBottom": 48,
                        "paddingLeft": 18,
                        "display": "flex",
                    },
                    "children": [
                        {
                            "id": "fb2f6afed26c9b8d2e77996c5d3a372a6745a29fddf2bf72db84ba4836904132",
                            "name": "container-2",
                            "xtype": "group",
                            "properties": {
                                "maxWidth": 900,
                                "columnGap": 30,
                                "rowGap": 30,
                                "flexDirection": "column",
                                "justifyContent": "flex-start",
                                "alignItems": "center",
                                "display": "flex",
                                "width": 350
                            },
                            "children": [
                                {
                                    "id": "b34c2b2edeba97a83a402a2e5ed0c8d77e0b96e679746f58ba5d2ea3607d3773",
                                    "name": "section-title",
                                    "xtype": "group",
                                    "properties": {
                                        "maxWidth": 397.5,
                                        "columnGap": 12,
                                        "rowGap": 12,
                                        "flexDirection": "column",
                                        "justifyContent": "flex-start",
                                        "alignItems": "center",
                                        "display": "flex",
                                    },
                                    "children": [
                                        {
                                            "id": "bc38029dc1113f6e35b517d719e1cd8831db151c47109bd2576dc399a546764b",
                                            "name": "text-14",
                                            "xtype": "group",
                                            "properties": {
                                                "fontWeight": "700",
                                                "marginBottom": 7.5,
                                                "marginTop": 15,
                                                "textAlign": "center"
                                            },
                                            "children": [
                                                {
                                                    "id": "c2c5e04eb968c72e46155c90494af89e78ba34dd1aba4de91ea719f9090aee31",
                                                    "xtype": "text",
                                                    "properties": {
                                                        "content": "<h2>Get in touch</h2>",
                                                        "maxWidth": "100%"
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            "id": "8f76f911842de9c0dac94e46278d271cdc986756f39ca2222f699cafbd16fbaa",
                                            "name": "text-15",
                                            "xtype": "group",
                                            "properties": {
                                                "fontSize": 13.5,
                                                "textAlign": "center",
                                                "fontWeight": "400"
                                            },
                                            "children": [
                                                {
                                                    "id": "8bef4bed11e712cc005a40a837b26828a5331f7b78b2534935746b2cb934e188",
                                                    "xtype": "text",
                                                    "properties": {
                                                        "content": "<p>Let us know how we can help</p>",
                                                        "maxWidth": "100%"
                                                    }
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "id": "42dcbfb430f90f1a0430cf8fcdb82f81e6c13a54239fcac22b46f723c312654b",
                                    "name": "form-wrapper w-form",
                                    "xtype": "group",
                                    "properties": {
                                        "marginBottom": 11.25,
                                        "maxWidth": 375,
                                        "columnGap": 7.5,
                                        "rowGap": 7.5,
                                        "flexDirection": "column",
                                        "justifyContent": "flex-start",
                                        "alignItems": "flex-start",
                                        "display": "flex",
                                    },
                                    "children": [
                                        {
                                            "id": "817125118788b181916bee96ba0c9da427158be60fbda77525a7471da55f23dc",
                                            "name": "form",
                                            "xtype": "form",
                                            "properties": {
                                                "columnGap": 12,
                                                "rowGap": 12,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "display": "flex",
                                                "width": 350
                                            },
                                            "children": [
                                                {
                                                    "id": "b9f3eb644dc51b5c8581d822273f4e2b535fd43d2e3e2b4ada23f722a778253f",
                                                    "name": "input-wrapper",
                                                    "xtype": "field",
                                                    "properties": {
                                                        "fieldName" : "Name",
                                                        "columnGap": 6,
                                                        "rowGap": 6,
                                                        "flexDirection": "column",
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "flex-start",
                                                        "display": "flex",
                                                        "alignSelf": "stretch"
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "8a0e3a7c584f92e9fbb80dd7e6ea225fe96d379c594c9d56d639eb2c527559ba",
                                                            "name": "form-block-label",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "display": "block",
                                                                "marginBottom": 3.75,
                                                                "fontWeight": "400"
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "9b1200a5f96546c1781a9c5d5db126d32c241ba3d227cb7276a8995fae260cc0",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Name</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "389087f394ffcfb0b8f8f2e6b00a0e973d23f1e301b59c42ecba39f09fb34d35",
                                                            "name": "form-text-input w-input",
                                                            "xtype": "input",
                                                            "properties": {
                                                                "required": "true",
                                                                "fontStyle": "inherit",
                                                                "fontVariantLigatures": "inherit",
                                                                "fontVariantCaps": "inherit",
                                                                "fontVariantNumeric": "inherit",
                                                                "fontVariantEastAsian": "inherit",
                                                                "fontVariantAlternates": "inherit",
                                                                "fontWeight": "500",
                                                                "fontStretch": "inherit",
                                                                "fontOpticalSizing": "inherit",
                                                                "fontKerning": "inherit",
                                                                "fontFeatureSettings": "inherit",
                                                                "fontVariationSettings": "inherit",
                                                                "display": "flex",
                                                                "paddingTop": 9,
                                                                "paddingRight": 9,
                                                                "paddingBottom": 9,
                                                                "paddingLeft": 9,
                                                                "marginBottom": 7.5,
                                                                "verticalAlign": "middle",
                                                                "borderTopWidth": 0.75,
                                                                "borderRightWidth": 0.75,
                                                                "borderBottomWidth": 0.75,
                                                                "borderLeftWidth": 0.75,
                                                                "borderTopStyle": "solid",
                                                                "borderRightStyle": "solid",
                                                                "borderBottomStyle": "solid",
                                                                "borderLeftStyle": "solid",
                                                                "borderTopColor": "rgb(0, 0, 0)",
                                                                "borderRightColor": "rgb(0, 0, 0)",
                                                                "borderBottomColor": "rgb(0, 0, 0)",
                                                                "borderLeftColor": "rgb(0, 0, 0)",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "columnGap": 6,
                                                                "rowGap": 6,
                                                                "justifyContent": "flex-start",
                                                                "alignItems": "center",
                                                                "small": {
                                                                    "fontWeight": "inherit",
                                                                    "marginBottom": 0,
                                                                    "display": "block",
                                                                    "paddingTop": 6,
                                                                    "paddingBottom": 6,
                                                                    "borderTopColor": "rgb(204, 204, 204)",
                                                                    "borderRightColor": "rgb(204, 204, 204)",
                                                                    "borderBottomColor": "rgb(204, 204, 204)",
                                                                    "borderLeftColor": "rgb(204, 204, 204)"
                                                                },
                                                                "textAlign": "center",
                                                                "alignSelf": "stretch"
                                                            },
                                                            "children": []
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "04ee032714447b3969996545e60ce806fc850bd056134d799fe7b3fda34dc017",
                                                    "name": "input-wrapper",
                                                    "xtype": "field",
                                                    "properties": {
                                                        "fieldName" : "Email",
                                                        "columnGap": 6,
                                                        "rowGap": 6,
                                                        "flexDirection": "column",
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "flex-start",
                                                        "display": "flex",
                                                        "alignSelf": "stretch"
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "dbfa865b4e31d4fcf99546f2da4519884b62d517a9ed2b35d642b69748c19e6f",
                                                            "name": "form-block-label",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "display": "block",
                                                                "marginBottom": 3.75,
                                                                "fontWeight": "400"
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "b1f03afec651961d8869634c53816d0c0c366215155c8461dd85d57f4a8f63f5",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Email</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "6c3e1543a35d82fe3836e6c9ee2576e20e722a53ea92813750b6fc8f5fbf9565",
                                                            "name": "form-text-input-2 w-input",
                                                            "xtype": "input",
                                                            "properties": {
                                                                "inputType": "email",
                                                                "required": "true",
                                                                "fontStyle": "inherit",
                                                                "fontVariantLigatures": "inherit",
                                                                "fontVariantCaps": "inherit",
                                                                "fontVariantNumeric": "inherit",
                                                                "fontVariantEastAsian": "inherit",
                                                                "fontVariantAlternates": "inherit",
                                                                "fontWeight": "500",
                                                                "fontStretch": "inherit",
                                                                "fontOpticalSizing": "inherit",
                                                                "fontKerning": "inherit",
                                                                "fontFeatureSettings": "inherit",
                                                                "fontVariationSettings": "inherit",
                                                                "display": "flex",
                                                                "paddingTop": 9,
                                                                "paddingRight": 9,
                                                                "paddingBottom": 9,
                                                                "paddingLeft": 9,
                                                                "marginBottom": 7.5,
                                                                "verticalAlign": "middle",
                                                                "borderTopWidth": 0.75,
                                                                "borderRightWidth": 0.75,
                                                                "borderBottomWidth": 0.75,
                                                                "borderLeftWidth": 0.75,
                                                                "borderTopStyle": "solid",
                                                                "borderRightStyle": "solid",
                                                                "borderBottomStyle": "solid",
                                                                "borderLeftStyle": "solid",
                                                                "borderTopColor": "rgb(0, 0, 0)",
                                                                "borderRightColor": "rgb(0, 0, 0)",
                                                                "borderBottomColor": "rgb(0, 0, 0)",
                                                                "borderLeftColor": "rgb(0, 0, 0)",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "columnGap": 6,
                                                                "rowGap": 6,
                                                                "justifyContent": "flex-start",
                                                                "alignItems": "flex-start",
                                                                "small": {
                                                                    "fontWeight": "inherit",
                                                                    "marginBottom": 0,
                                                                    "display": "block",
                                                                    "paddingTop": 6,
                                                                    "paddingBottom": 6,
                                                                    "borderTopColor": "rgb(204, 204, 204)",
                                                                    "borderRightColor": "rgb(204, 204, 204)",
                                                                    "borderBottomColor": "rgb(204, 204, 204)",
                                                                    "borderLeftColor": "rgb(204, 204, 204)"
                                                                },
                                                                "textAlign": "center",
                                                                "alignSelf": "stretch"
                                                            },
                                                            "children": []
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "efe97e6f4f7e90156718eaca3c2e60e3ec4dfaf99088edf30786beb25e716a1c",
                                                    "name": "input-wrapper",
                                                    "xtype": "field",
                                                    "properties": {
                                                        "fieldName" : "Message",
                                                        "columnGap": 6,
                                                        "rowGap": 6,
                                                        "flexDirection": "column",
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "flex-start",
                                                        "display": "flex",
                                                        "alignSelf": "stretch"
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "4359dea3349271a3007f8a15e374d15c18fc31f789a6f9b5998fcc5b4449fdaa",
                                                            "name": "form-block-label",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "display": "block",
                                                                "marginBottom": 3.75,
                                                                "fontWeight": "400"
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "cf984b2b0175e0577891b6824392f4ff25cd7c09a2edb9ba5e3765ca126843c9",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Message</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "e9ecda854bb6747c7e7b6a4143c5f104959e69d7b83adc852efb4f1938a75f6b",
                                                            "name": "form-textarea w-input",
                                                            "xtype": "text-area",
                                                            "properties": {
                                                                "required": "true",
                                                                "fontStyle": "inherit",
                                                                "fontVariantLigatures": "inherit",
                                                                "fontVariantCaps": "inherit",
                                                                "fontVariantNumeric": "inherit",
                                                                "fontVariantEastAsian": "inherit",
                                                                "fontVariantAlternates": "inherit",
                                                                "fontWeight": "500",
                                                                "fontStretch": "inherit",
                                                                "fontOpticalSizing": "inherit",
                                                                "fontKerning": "inherit",
                                                                "fontFeatureSettings": "inherit",
                                                                "fontVariationSettings": "inherit",
                                                                "overflowX": "auto",
                                                                "overflowY": "auto",
                                                                "display": "flex",
                                                                "paddingTop": 9,
                                                                "paddingRight": 9,
                                                                "paddingBottom": 9,
                                                                "paddingLeft": 9,
                                                                "marginBottom": 7.5,
                                                                "verticalAlign": "middle",
                                                                "borderTopWidth": 0.75,
                                                                "borderRightWidth": 0.75,
                                                                "borderBottomWidth": 0.75,
                                                                "borderLeftWidth": 0.75,
                                                                "borderTopStyle": "solid",
                                                                "borderRightStyle": "solid",
                                                                "borderBottomStyle": "solid",
                                                                "borderLeftStyle": "solid",
                                                                "borderTopColor": "rgb(0, 0, 0)",
                                                                "borderRightColor": "rgb(0, 0, 0)",
                                                                "borderBottomColor": "rgb(0, 0, 0)",
                                                                "borderLeftColor": "rgb(0, 0, 0)",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "columnGap": 6,
                                                                "rowGap": 6,
                                                                "justifyContent": "flex-start",
                                                                "alignItems": "flex-start",
                                                                "small": {
                                                                    "fontWeight": "inherit",
                                                                    "marginBottom": 0,
                                                                    "display": "block",
                                                                    "paddingTop": 6,
                                                                    "paddingBottom": 6,
                                                                    "borderTopColor": "rgb(204, 204, 204)",
                                                                    "borderRightColor": "rgb(204, 204, 204)",
                                                                    "borderBottomColor": "rgb(204, 204, 204)",
                                                                    "borderLeftColor": "rgb(204, 204, 204)"
                                                                },
                                                                "alignSelf": "stretch"
                                                            },
                                                            "children": []
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "c21dbb8090dcafd3600192c1a56da78cb767026ccda56523677aa34566274fdd",
                                                    "name": "form-button w-button",
                                                    "xtype": "submit-button",
                                                    "properties": {
                                                        "fontStyle": "inherit",
                                                        "fontVariantLigatures": "inherit",
                                                        "fontVariantCaps": "inherit",
                                                        "fontVariantNumeric": "inherit",
                                                        "fontVariantEastAsian": "inherit",
                                                        "fontVariantAlternates": "inherit",
                                                        "fontWeight": "500",
                                                        "fontStretch": "inherit",
                                                        "fontOpticalSizing": "inherit",
                                                        "fontKerning": "inherit",
                                                        "fontFeatureSettings": "inherit",
                                                        "fontVariationSettings": "inherit",
                                                        "display": "flex",
                                                        "paddingTop": 9,
                                                        "paddingRight": 18,
                                                        "paddingBottom": 9,
                                                        "paddingLeft": 18,
                                                        "borderTopWidth": 0,
                                                        "borderRightWidth": 0,
                                                        "borderBottomWidth": 0,
                                                        "borderLeftWidth": 0,
                                                        "borderTopStyle": "initial",
                                                        "borderRightStyle": "initial",
                                                        "borderBottomStyle": "initial",
                                                        "borderLeftStyle": "initial",
                                                        "borderTopColor": "initial",
                                                        "borderRightColor": "initial",
                                                        "borderBottomColor": "initial",
                                                        "borderLeftColor": "initial",
                                                        "borderImageSource": "initial",
                                                        "borderImageSlice": "initial",
                                                        "borderImageWidth": "initial",
                                                        "borderImageOutset": "initial",
                                                        "borderImageRepeat": "initial",
                                                        "textDecorationLine": "none",
                                                        "textDecorationThickness": "initial",
                                                        "textDecorationStyle": "initial",
                                                        "textDecorationColor": "initial",
                                                        "cursor": "pointer",
                                                        "borderTopLeftRadius": 0,
                                                        "borderTopRightRadius": 0,
                                                        "borderBottomRightRadius": 0,
                                                        "borderBottomLeftRadius": 0,
                                                        "appearance": "button",
                                                        "columnGap": 6,
                                                        "rowGap": 6,
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "small": {
                                                            "fontWeight": "inherit",
                                                            "display": "inline-block",
                                                            "paddingTop": 6.75,
                                                            "paddingRight": 11.25,
                                                            "paddingBottom": 6.75,
                                                            "paddingLeft": 11.25
                                                        },
                                                        "alignSelf": "center"
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "ba6da065c17bdaf49ce11de8de2ade1b9e3f8f1481649f2b7c8055f58d3236cf",
                                                            "xtype": "text",
                                                            "properties": {
                                                                "content": "<p>Send message</p>"
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                "scale": "5px"
            },
            {
                "widget": {
                    "id": "bf1d021c9b70adefded7005a159aa34babb151faffea07d04353be5e862daf36",
                    "name": "newsletter-form",
                    "xtype": "group",
                    "properties": {
                        "columnGap": 48,
                        "rowGap": 48,
                        "backgroundColor": "rgb(245, 247, 250)",
                        "flexDirection": "column",
                        "justifyContent": "flex-start",
                        "alignItems": "center",
                        "paddingTop": 48,
                        "paddingRight": 18,
                        "paddingBottom": 48,
                        "paddingLeft": 18,
                        "display": "flex",
                    },
                    "children": [
                        {
                            "id": "597345edef07202e7d6532040b602d29a3395a33cb926ee19c39537a1d7b6c8a",
                            "name": "container-2",
                            "xtype": "group",
                            "properties": {
                                "maxWidth": 900,
                                "columnGap": 30,
                                "rowGap": 30,
                                "flexDirection": "column",
                                "justifyContent": "flex-start",
                                "alignItems": "center",
                                "display": "flex",
                            },
                            "children": [
                                {
                                    "id": "a5f31823c3645e8f9f1dcc8d39f6746cf92a83052d84db842f30056f3c14d785",
                                    "name": "section-title",
                                    "xtype": "group",
                                    "properties": {
                                        "maxWidth": 397.5,
                                        "columnGap": 12,
                                        "rowGap": 12,
                                        "flexDirection": "column",
                                        "justifyContent": "flex-start",
                                        "alignItems": "center",
                                        "display": "flex",
                                    },
                                    "children": [
                                        {
                                            "id": "0adc02d86f4e301b87d512f983dda2a4e33900def2c02f803571b62df27cf017",
                                            "name": "get-in-touch",
                                            "xtype": "group",
                                            "properties": {
                                                "fontWeight": "700",
                                                "marginBottom": 7.5,
                                                "marginTop": 15,
                                                "textAlign": "center"
                                            },
                                            "children": [
                                                {
                                                    "id": "03487b6619fbd03ad46ac21c6f14367757fc84f2c9088a78517db46986567c2f",
                                                    "xtype": "label",
                                                    "properties": {
                                                        "content": "<h2>Join 10,000+ subscribers</h2>",
                                                        "maxWidth": "100%"
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            "id": "d1431c350d80d20dbcbaeed5416a057f781e0bc3de2920e054b8f2b275b3dc33",
                                            "name": "text-18",
                                            "xtype": "group",
                                            "properties": {
                                                "fontSize": 13.5,
                                                "textAlign": "center",
                                                "fontWeight": "400"
                                            },
                                            "children": [
                                                {
                                                    "id": "b4a8993a3bf376a1e95d2b0d1fb4cdc75d68056d75835677c061bc2129c99d5a",
                                                    "xtype": "label",
                                                    "properties": {
                                                        "content": "<p>Join our mailing list to get the latest news</p>",
                                                        "maxWidth": "100%"
                                                    }
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "id": "584a55f9f21e3364530af303162d67faeff25c60f50af7d6b9c0ebb205cba336",
                                    "name": "form-wrapper-2 w-form",
                                    "xtype": "group",
                                    "properties": {
                                        "marginBottom": 11.25,
                                        "maxWidth": 300,
                                        "columnGap": 12,
                                        "rowGap": 12,
                                        "flexDirection": "column",
                                        "justifyContent": "flex-start",
                                        "alignItems": "center",
                                        "display": "flex",
                                    },
                                    "children": [
                                        {
                                            "id": "b5991a02a60c5282dadf44a4ad4265c2aa107a705e1e72384b49df86fe79a3f8",
                                            "name": "form-2",
                                            "xtype": "form",
                                            "properties": {
                                                "columnGap": 12,
                                                "rowGap": 12,
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "c576c2201e3742d1a639be501083b1fa8f8d9091c5916577b0ad129a869b4ff5",
                                                    "name": "input-wrapper",
                                                    "xtype": "field",
                                                    "properties": {
                                                        "fieldName": "Email",
                                                        "columnGap": 6,
                                                        "rowGap": 6,
                                                        "flexDirection": "column",
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "flex-start",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "1e5094c4ed0f48ea215b71a21cc64814cb5f06b9d7410c4a60419abd61aa7721",
                                                            "name": "form-text-input-3 w-input",
                                                            "xtype": "input",
                                                            "properties": {
                                                                "inputType": "email",
                                                                "required": true,
                                                                "fontStyle": "inherit",
                                                                "fontVariantLigatures": "inherit",
                                                                "fontVariantCaps": "inherit",
                                                                "fontVariantNumeric": "inherit",
                                                                "fontVariantEastAsian": "inherit",
                                                                "fontVariantAlternates": "inherit",
                                                                "fontWeight": "500",
                                                                "fontStretch": "inherit",
                                                                "fontOpticalSizing": "inherit",
                                                                "fontKerning": "inherit",
                                                                "fontFeatureSettings": "inherit",
                                                                "fontVariationSettings": "inherit",
                                                                "display": "flex",
                                                                "paddingTop": 9,
                                                                "paddingRight": 9,
                                                                "paddingBottom": 9,
                                                                "paddingLeft": 9,
                                                                "marginBottom": 7.5,
                                                                "verticalAlign": "middle",
                                                                "borderTopWidth": 0.75,
                                                                "borderRightWidth": 0.75,
                                                                "borderBottomWidth": 0.75,
                                                                "borderLeftWidth": 0.75,
                                                                "borderTopStyle": "solid",
                                                                "borderRightStyle": "solid",
                                                                "borderBottomStyle": "solid",
                                                                "borderLeftStyle": "solid",
                                                                "borderTopColor": "rgb(0, 0, 0)",
                                                                "borderRightColor": "rgb(0, 0, 0)",
                                                                "borderBottomColor": "rgb(0, 0, 0)",
                                                                "borderLeftColor": "rgb(0, 0, 0)",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "columnGap": 6,
                                                                "rowGap": 6,
                                                                "justifyContent": "flex-start",
                                                                "alignItems": "flex-start",
                                                                "small": {
                                                                    "fontWeight": "inherit",
                                                                    "marginBottom": 0,
                                                                    "display": "block",
                                                                    "paddingTop": 6,
                                                                    "paddingBottom": 6,
                                                                    "borderTopColor": "rgb(204, 204, 204)",
                                                                    "borderRightColor": "rgb(204, 204, 204)",
                                                                    "borderBottomColor": "rgb(204, 204, 204)",
                                                                    "borderLeftColor": "rgb(204, 204, 204)"
                                                                }
                                                            },
                                                            "children": []
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "2cc50f3e6e12eaad8a85cb15f7955048d3f2a55bd6e20d308ac8a7c0f6a5f769",
                                                    "name": "form-button-2 w-button",
                                                    "xtype": "submit-button",
                                                    "properties": {
                                                        "fontStyle": "inherit",
                                                        "fontVariantLigatures": "inherit",
                                                        "fontVariantCaps": "inherit",
                                                        "fontVariantNumeric": "inherit",
                                                        "fontVariantEastAsian": "inherit",
                                                        "fontVariantAlternates": "inherit",
                                                        "fontWeight": "500",
                                                        "fontStretch": "inherit",
                                                        "fontOpticalSizing": "inherit",
                                                        "fontKerning": "inherit",
                                                        "fontFeatureSettings": "inherit",
                                                        "fontVariationSettings": "inherit",
                                                        "display": "flex",
                                                        "paddingTop": 9,
                                                        "paddingRight": 18,
                                                        "paddingBottom": 9,
                                                        "paddingLeft": 18,
                                                        "borderTopWidth": 0,
                                                        "borderRightWidth": 0,
                                                        "borderBottomWidth": 0,
                                                        "borderLeftWidth": 0,
                                                        "borderTopStyle": "initial",
                                                        "borderRightStyle": "initial",
                                                        "borderBottomStyle": "initial",
                                                        "borderLeftStyle": "initial",
                                                        "borderTopColor": "initial",
                                                        "borderRightColor": "initial",
                                                        "borderBottomColor": "initial",
                                                        "borderLeftColor": "initial",
                                                        "borderImageSource": "initial",
                                                        "borderImageSlice": "initial",
                                                        "borderImageWidth": "initial",
                                                        "borderImageOutset": "initial",
                                                        "borderImageRepeat": "initial",
                                                        "textDecorationLine": "none",
                                                        "textDecorationThickness": "initial",
                                                        "textDecorationStyle": "initial",
                                                        "textDecorationColor": "initial",
                                                        "cursor": "pointer",
                                                        "borderTopLeftRadius": 0,
                                                        "borderTopRightRadius": 0,
                                                        "borderBottomRightRadius": 0,
                                                        "borderBottomLeftRadius": 0,
                                                        "appearance": "button",
                                                        "columnGap": 6,
                                                        "rowGap": 6,
                                                        "flexGrow": "0",
                                                        "flexShrink": "1",
                                                        "flexBasis": "auto",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "small": {
                                                            "fontWeight": "inherit",
                                                            "display": "inline-block",
                                                            "paddingTop": 6.75,
                                                            "paddingRight": 11.25,
                                                            "paddingBottom": 6.75,
                                                            "paddingLeft": 11.25
                                                        }
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "ad29f26ae39664a5f6e2f5f3c87f8e003038d7b3e8b9bcda67c7517e7e095457",
                                                            "xtype": "text",
                                                            "properties": {
                                                                "content": "<p>Subscribe</p>"
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "id": "7a54705d80041e34831199c73ba3276f662f28f997e2fb56ebd6242e92d04704",
                                            "name": "text-20",
                                            "xtype": "group",
                                            "properties": {
                                                "fontSize": 10.5,
                                                "textAlign": "center",
                                                "fontWeight": "400"
                                            },
                                            "children": [
                                                {
                                                    "id": "832bee446a07e4da3ede9be25af408e8fd26d3f687933e046d923c95eda7aac1",
                                                    "xtype": "label",
                                                    "properties": {
                                                        "content": "<p>Join our mailing list to get the latest news</p>",
                                                        "maxWidth": "100%"
                                                    }
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                "scale": "5px"
            },
            {
                "widget": {
                    "id": "626ea5988e4aebf0e62bd1c6f5037d3d428861754e1ecd2118cb4faafcc94f92",
                    "name": "team-circles",
                    "xtype": "group",
                    "properties": {
                        "columnGap": 48,
                        "rowGap": 48,
                        "flexDirection": "column",
                        "justifyContent": "flex-start",
                        "alignItems": "center",
                        "paddingTop": 48,
                        "paddingRight": 18,
                        "paddingBottom": 48,
                        "paddingLeft": 18,
                        "display": "flex",
                    },
                    "children": [
                        {
                            "id": "19b8cdea554de0c103d9e8705f975e1593ac61a1c19383043cb389d8a560d764",
                            "name": "container-3",
                            "xtype": "group",
                            "properties": {
                                "maxWidth": 900,
                                "columnGap": 48,
                                "rowGap": 48,
                                "flexDirection": "column",
                                "justifyContent": "flex-start",
                                "alignItems": "center",
                                "display": "flex",
                            },
                            "children": [
                                {
                                    "id": "6dec98cae6c2da6a0576ad27fee48548d9d79cea1944b4c72f22d4acfb935479",
                                    "name": "title-section-2",
                                    "xtype": "group",
                                    "properties": {
                                        "maxWidth": 397.5,
                                        "columnGap": 12,
                                        "rowGap": 12,
                                        "flexDirection": "column",
                                        "justifyContent": "flex-start",
                                        "alignItems": "center",
                                        "display": "flex",
                                    },
                                    "children": [
                                        {
                                            "id": "abf27c492d29db3f407e7daab70c13fd43130a551339f6c62f00364f6583fb9c",
                                            "name": "text-21",
                                            "xtype": "group",
                                            "properties": {
                                                "fontSize": 24,
                                                "textAlign": "center",
                                                "fontWeight": "700",
                                            },
                                            "children": [
                                                {
                                                    "id": "58a290a95819fe9199a63c6ee259a74ca225bd97bb1a478d90ce74ce40c858f5",
                                                    "xtype": "label",
                                                    "properties": {
                                                        "content": "<p>Team section</p>",
                                                        "maxWidth": "100%"
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            "id": "99f3151663e3e7b0f789d4a54720932b9087c2092188d2d275cade524c7f0014",
                                            "name": "text-22",
                                            "xtype": "group",
                                            "properties": {
                                                "fontSize": 12,
                                                "textAlign": "center",
                                                "fontWeight": "400",
                                            },
                                            "children": [
                                                {
                                                    "id": "2726b9d1ee272aee506e80a40a3749101a0cb6fdd3c67bfe8bb40b7517373c0e",
                                                    "xtype": "label",
                                                    "properties": {
                                                        "content": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse tincidunt sagittis eros. Quisque quis euismod lorem.</p>",
                                                        "maxWidth": "100%"
                                                    }
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "id": "4b5697e56107227f9e0958f9e195befe999fef175c367d134f74bba3a3129d07",
                                    "name": "columns",
                                    "xtype": "group",
                                    "properties": {
                                        "columnGap": 36,
                                        "rowGap": 36,
                                        "justifyContent": "center",
                                        "alignItems": "flex-start",
                                        "display": "flex",
                                        "small": {
                                            "flexDirection": "column",
                                            "alignItems": "center"
                                        }
                                    },
                                    "children": [
                                        {
                                            "id": "cdea4fbbd028a455007fbcacac42705877b48c8de12a5ad2a58bfb7b1d00f917",
                                            "name": "card",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 18,
                                                "rowGap": 18,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "center",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "e3776159923c824921e990f7e038ea696c012b8aee02ff5d21f9a1c01a91f0f1",
                                                    "name": "image-wrapper-3",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "maxWidth": 202.5,
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "9f0462e76b4b4039d7319bd2270be7fb3257f515ce54d283e1b70a4a9cdb8a9a",
                                                            "name": "image-3",
                                                            "xtype": "image",
                                                            "properties": {
                                                                "borderTopWidth": 0,
                                                                "borderRightWidth": 0,
                                                                "borderBottomWidth": 0,
                                                                "borderLeftWidth": 0,
                                                                "borderTopStyle": "initial",
                                                                "borderRightStyle": "initial",
                                                                "borderBottomStyle": "initial",
                                                                "borderLeftStyle": "initial",
                                                                "borderTopColor": "initial",
                                                                "borderRightColor": "initial",
                                                                "borderBottomColor": "initial",
                                                                "borderLeftColor": "initial",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "maxWidth": "100%",
                                                                "verticalAlign": "middle",
                                                                "display": "flex",
                                                                "objectFit": "cover",
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "small": {
                                                                    "maxWidth": "100%",
                                                                },
                                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910be0c655c229829aa6d_Image.png",
                                                                "alt": "",
                                                                "maxHeight": "100%"
                                                            },
                                                            "children": []
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "ffe6164fd3923ab2f1eae13e0309dcb4e2674773ff39f7fc84273942c8cfdc4e",
                                                    "name": "content-2",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 12,
                                                        "rowGap": 12,
                                                        "flexDirection": "column",
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "4494a73631e93c63982503935bccaef95b56b0efce020d2178c9176847d810af",
                                                            "name": "info",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "flexDirection": "column",
                                                                "justifyContent": "flex-start",
                                                                "alignItems": "center",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "ff8a325a646e8b3215a66e11a2f544adfe9089c805686c64b4a729fbc14f2eb5",
                                                                    "name": "text-23",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontSize": 15,
                                                                        "textAlign": "center",
                                                                        "fontWeight": "700",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "792b1c0e1cacd961c6280714550abb7bdf8e9547d105a7ba45204f774fef6ad0",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>Full name</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "id": "2328342049a23189debc9e26905eb480ed41b76f609f74c0e26301f886ada366",
                                                                    "name": "text-24",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontSize": 13.5,
                                                                        "textAlign": "center",
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "4efc0128f8963dbeb4d05893f8a502bbdd6ecd5e10cdbacc26da07b73886144d",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>Job title</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "43f89bbc2a3e69cb7ce2cd72b2d39932e0750a5fca1676adf919baac996ae729",
                                                            "name": "text-22",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 12,
                                                                "textAlign": "center",
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "ffbafed40d09716a51f8804a3ccf58c85d85565b8d778799021b13e7c9c06cf1",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "id": "94f7e883a2b7a405e196c7929f93f684adfd53d56a516981dedb3308e043caa7",
                                            "name": "card",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 18,
                                                "rowGap": 18,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "center",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "11b11ee375aa874abcede5a0001560fec8e10a6aaf9738ec7b351a1308474d96",
                                                    "name": "image-wrapper-3",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "maxWidth": 202.5,
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "75492d354cca98fd0d3959a3590f3112eea8abdc94a76b090b76c3522ea3a161",
                                                            "name": "image-3",
                                                            "xtype": "image",
                                                            "properties": {
                                                                "borderTopWidth": 0,
                                                                "borderRightWidth": 0,
                                                                "borderBottomWidth": 0,
                                                                "borderLeftWidth": 0,
                                                                "borderTopStyle": "initial",
                                                                "borderRightStyle": "initial",
                                                                "borderBottomStyle": "initial",
                                                                "borderLeftStyle": "initial",
                                                                "borderTopColor": "initial",
                                                                "borderRightColor": "initial",
                                                                "borderBottomColor": "initial",
                                                                "borderLeftColor": "initial",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "maxWidth": "100%",
                                                                "verticalAlign": "middle",
                                                                "display": "flex",
                                                                "objectFit": "cover",
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "small": {
                                                                    "maxWidth": "100%",
                                                                },
                                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910be0c655c229829aa6d_Image.png",
                                                                "alt": "",
                                                                "maxHeight": "100%"
                                                            },
                                                            "children": []
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "e4157610eb2817c8a469ec93db8099affb4072c26f09cf1934ad0f956016f3de",
                                                    "name": "content-2",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 12,
                                                        "rowGap": 12,
                                                        "flexDirection": "column",
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "a1d1a163524ec03e0febd25dfc4d896b71182f513d80aa29b951f5574033860e",
                                                            "name": "info",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "flexDirection": "column",
                                                                "justifyContent": "flex-start",
                                                                "alignItems": "center",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "e838fa9be1e59fa9ad70e6123a7136be00085dc6f043431f634a338ffa062ad4",
                                                                    "name": "text-23",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontSize": 15,
                                                                        "textAlign": "center",
                                                                        "fontWeight": "700",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "f0775c3a8311157354d9abdd39b25c7efdf6f1af0ccf5bef502df25f280a3a55",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>Full name</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "id": "291afa5640d52358d44188ebfd350733052c61a01bfde489231426e8ef965e7c",
                                                                    "name": "text-24",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontSize": 13.5,
                                                                        "textAlign": "center",
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "5183486701d5a18b0732ceb4cf7f732924866951fe5adb8b161dbfb2aafde5a9",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>Job title</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "2031f343a9a4a8e77dda70e59f385604a6b2d133dc59b1e9e3c8d9a8996fdce8",
                                                            "name": "text-22",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 12,
                                                                "textAlign": "center",
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "5238ce038e71061c5902aa29a84f3bdc442f6c23674387ee9237d8a798a6df7a",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "id": "c1e775b81df8916d1f4b0a8a9ecfdb9e8f93ab5f835795623b2bb5026d274eb9",
                                            "name": "card",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 18,
                                                "rowGap": 18,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "center",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "69af2f9fae5832abf137e8ee5ca3d80a3c63d727b94ab8c83654a8c02c686875",
                                                    "name": "image-wrapper-3",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "maxWidth": 202.5,
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "e476937bd2a3b10453c807f33f7b568eb3163c73cad241af8b93b47e5327c22f",
                                                            "name": "image-3",
                                                            "xtype": "image",
                                                            "properties": {
                                                                "borderTopWidth": 0,
                                                                "borderRightWidth": 0,
                                                                "borderBottomWidth": 0,
                                                                "borderLeftWidth": 0,
                                                                "borderTopStyle": "initial",
                                                                "borderRightStyle": "initial",
                                                                "borderBottomStyle": "initial",
                                                                "borderLeftStyle": "initial",
                                                                "borderTopColor": "initial",
                                                                "borderRightColor": "initial",
                                                                "borderBottomColor": "initial",
                                                                "borderLeftColor": "initial",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "maxWidth": "100%",
                                                                "verticalAlign": "middle",
                                                                "display": "flex",
                                                                "objectFit": "cover",
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "small": {
                                                                    "maxWidth": "100%",
                                                                },
                                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910be0c655c229829aa6d_Image.png",
                                                                "alt": "",
                                                                "maxHeight": "100%"
                                                            },
                                                            "children": []
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "819a365ef708c35d2ffffe56f9cd68cfff04d39899e3266f075ad6da898cfe38",
                                                    "name": "content-2",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 12,
                                                        "rowGap": 12,
                                                        "flexDirection": "column",
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "38d5c22b24957adcb69d7e550c7871fa3cf0e35a8bc101c4da2e474c865916ec",
                                                            "name": "info",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "flexDirection": "column",
                                                                "justifyContent": "flex-start",
                                                                "alignItems": "center",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "c9cc1a94c6a81f5870b3653367639947f15cf14922e79deaae83f6f5d4809869",
                                                                    "name": "text-23",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontSize": 15,
                                                                        "textAlign": "center",
                                                                        "fontWeight": "700",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "d9fbedb59027d70baa8fb866bb8889e043d7247c0e3c2caa668eb9c6787d3f90",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>Full name</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "id": "f709f5b9a9723463d0f3d08c41df30b6a093184c5328086205ca775aaa9aad0b",
                                                                    "name": "text-24",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontSize": 13.5,
                                                                        "textAlign": "center",
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "0f80b5d20ea2758ae58674db20853560d19d4efa3cd4fe78bb18dbed21622fe0",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>Job title</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "6a0897e6d750e5f601446e10afd6940318ec131554aa6982a9ab1a3df960a401",
                                                            "name": "text-22",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 12,
                                                                "textAlign": "center",
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "f4bbea8197f2899557135d5a29be36637e16399d025a6502b19ac869f6b242ce",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                "scale": "5px"
            },
            {
                "widget": {
                    "id": "cd36287365332a581715902a1cbb3d38f033ae62ea81b99bc39655e3a3bfc48c",
                    "name": "team-rectangles",
                    "xtype": "group",
                    "properties": {
                        "columnGap": 48,
                        "rowGap": 48,
                        "backgroundColor": "rgb(245, 247, 250)",
                        "flexDirection": "column",
                        "justifyContent": "flex-start",
                        "alignItems": "center",
                        "paddingTop": 48,
                        "paddingRight": 18,
                        "paddingBottom": 48,
                        "paddingLeft": 18,
                        "display": "flex",
                    },
                    "children": [
                        {
                            "id": "be114b3c03f237d9735905601bf6c7aeabc1bfcdf0a43c7814088c71ceabe639",
                            "name": "container-3",
                            "xtype": "group",
                            "properties": {
                                "maxWidth": 900,
                                "columnGap": 48,
                                "rowGap": 48,
                                "flexDirection": "column",
                                "justifyContent": "flex-start",
                                "alignItems": "center",
                                "display": "flex",
                            },
                            "children": [
                                {
                                    "id": "df7743b6ab9f90ccf3f854bbd68d928676884e789fc7c482c42287034d7b84e1",
                                    "name": "section-title",
                                    "xtype": "group",
                                    "properties": {
                                        "maxWidth": 397.5,
                                        "columnGap": 12,
                                        "rowGap": 12,
                                        "flexDirection": "column",
                                        "justifyContent": "flex-start",
                                        "alignItems": "center",
                                        "display": "flex",
                                    },
                                    "children": [
                                        {
                                            "id": "3f5b6ebe6f692fa1a02095c69a511acb7e6bde907b2494e0ad2804228eb2bb20",
                                            "name": "text-25",
                                            "xtype": "group",
                                            "properties": {
                                                "fontSize": 24,
                                                "textAlign": "center",
                                                "fontWeight": "700",
                                            },
                                            "children": [
                                                {
                                                    "id": "0415363430a5154d4919568cb8bdca80ccfaa42e53b7fccaaaa4a92cf53cedcd",
                                                    "xtype": "label",
                                                    "properties": {
                                                        "content": "<p>Team section</p>",
                                                        "maxWidth": "100%"
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            "id": "d0d5fe4aaad4bedfaadb53a2f49c34ac299d52d86c34917341e596bb9e7d7644",
                                            "name": "text-26",
                                            "xtype": "group",
                                            "properties": {
                                                "fontSize": 13.5,
                                                "textAlign": "center",
                                                "fontWeight": "400",
                                            },
                                            "children": [
                                                {
                                                    "id": "addbe41dbbfbaa6460126c72f5eb064699166ddcb50332f6d9d75f6fc4bfef9b",
                                                    "xtype": "label",
                                                    "properties": {
                                                        "content": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse tincidunt sagittis eros. Quisque quis euismod lorem.</p>",
                                                        "maxWidth": "100%"
                                                    }
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "id": "1d01948eeaad587e5d0ba365301b94e12301833738de8dc97a3b0304d2f6eee9",
                                    "name": "columns-2",
                                    "xtype": "group",
                                    "properties": {
                                        "columnGap": 36,
                                        "rowGap": 36,
                                        "justifyContent": "flex-start",
                                        "alignItems": "flex-start",
                                        "display": "flex",
                                        "small": {
                                            "flexDirection": "column",
                                            "alignItems": "center"
                                        }
                                    },
                                    "children": [
                                        {
                                            "id": "e7a282d42a280fadb6d5ef4545e67698a7fb39799fabf811cdb7029beb9ff84d",
                                            "name": "card-2",
                                            "xtype": "group",
                                            "properties": {
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "86c69d9241f5365f969dcde45b99fa1111ea505bcefd25f2f2bf03b1a7acfb1e",
                                                    "name": "image-wrapper-4",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 7.5,
                                                        "rowGap": 7.5,
                                                        "flexDirection": "column",
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "flex-start",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "22ae16cbd7582bacfcf3d4a668557bc86841893e349fbaa3272dd81d66f27548",
                                                            "name": "image-4",
                                                            "xtype": "image",
                                                            "properties": {
                                                                "borderTopWidth": 0,
                                                                "borderRightWidth": 0,
                                                                "borderBottomWidth": 0,
                                                                "borderLeftWidth": 0,
                                                                "borderTopStyle": "initial",
                                                                "borderRightStyle": "initial",
                                                                "borderBottomStyle": "initial",
                                                                "borderLeftStyle": "initial",
                                                                "borderTopColor": "initial",
                                                                "borderRightColor": "initial",
                                                                "borderBottomColor": "initial",
                                                                "borderLeftColor": "initial",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "maxWidth": "100%",
                                                                "verticalAlign": "middle",
                                                                "display": "flex",
                                                                "objectFit": "cover",
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910ccd439872d9cfc5d96_Image.png",
                                                                "alt": "",
                                                                "maxHeight": "100%"
                                                            },
                                                            "children": []
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "ba2e4cc2256a9f72f299ed8c318e031da9cf03aa3aa458221079d77a148d519a",
                                                    "name": "info-2",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "flexDirection": "column",
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "flex-start",
                                                        "paddingTop": 18,
                                                        "paddingRight": 18,
                                                        "paddingBottom": 18,
                                                        "paddingLeft": 18,
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "f7059d1f3aa823bfd4e32d8d38f602a97f66dad2c9a281b72b827c1d208c4455",
                                                            "name": "name",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontWeight": "600",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "6095e3b5fa71027db111ea1b34a6b5aa9771fb46e9533a5f49c9eb0398e88249",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Full name</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "18b4109bc814ed78d0d26b2b1b280a66ef18cf0ea8ea4aea47731d0b60049e13",
                                                            "name": "description",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "9bb294a6bc71b7d844ea01f8f5c31ebfe157ec5495131a8f233d66f55cdd5d7c",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "id": "b19b25e6c699fadc01ad5fdbfa037c43e432a01726cbd06ddd14975aff0f40f0",
                                            "name": "card-2",
                                            "xtype": "group",
                                            "properties": {
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "65c8009b50f772feb7e9051b31c15000e49ba344fbc159065cb3e3f9d97d536d",
                                                    "name": "image-wrapper-4",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 7.5,
                                                        "rowGap": 7.5,
                                                        "flexDirection": "column",
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "flex-start",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "773d3f2898e675c9b372c2f953bc7dab2141a797d7fa72be23956f9f36f10673",
                                                            "name": "image-4",
                                                            "xtype": "image",
                                                            "properties": {
                                                                "borderTopWidth": 0,
                                                                "borderRightWidth": 0,
                                                                "borderBottomWidth": 0,
                                                                "borderLeftWidth": 0,
                                                                "borderTopStyle": "initial",
                                                                "borderRightStyle": "initial",
                                                                "borderBottomStyle": "initial",
                                                                "borderLeftStyle": "initial",
                                                                "borderTopColor": "initial",
                                                                "borderRightColor": "initial",
                                                                "borderBottomColor": "initial",
                                                                "borderLeftColor": "initial",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "maxWidth": "100%",
                                                                "verticalAlign": "middle",
                                                                "display": "flex",
                                                                "objectFit": "cover",
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910ccd439872d9cfc5d96_Image.png",
                                                                "alt": "",
                                                                "maxHeight": "100%"
                                                            },
                                                            "children": []
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "3a9d5d61cab0361ae4844514660188a921139c980089029be1997599c13ace84",
                                                    "name": "info-2",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "flexDirection": "column",
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "flex-start",
                                                        "paddingTop": 18,
                                                        "paddingRight": 18,
                                                        "paddingBottom": 18,
                                                        "paddingLeft": 18,
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "0bf740d5a4d2e896b731d2c2d781e6da8d7ac6964ff46cd80134d5d274f20834",
                                                            "name": "name",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontWeight": "600",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "7c700ebf845cf5877cee0e0a3d891f79c4593dbbd078015b5d6c3cf214a7f0dc",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Full name</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "7e59eea7943779c460d5c6fc35709096d5b396a7974f2a855ea530a580ae68ce",
                                                            "name": "description",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "cd95fe99bbb3201946172a26b1da1441640827fe5bfc729379243bee4dfd25a7",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "id": "83caaf608e8771bcd702778848155e859b9c5df180ceafbe0ac7aaafc14e37b8",
                                            "name": "card-2",
                                            "xtype": "group",
                                            "properties": {
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "83107c157b85e88415af530a5efb69ed58f14670bf682195fba5b4444944beeb",
                                                    "name": "image-wrapper-4",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 7.5,
                                                        "rowGap": 7.5,
                                                        "flexDirection": "column",
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "flex-start",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "df2af532a2fba4b9a495d813f43545e7913471139f2af6728db4e5baee8296df",
                                                            "name": "image-4",
                                                            "xtype": "image",
                                                            "properties": {
                                                                "borderTopWidth": 0,
                                                                "borderRightWidth": 0,
                                                                "borderBottomWidth": 0,
                                                                "borderLeftWidth": 0,
                                                                "borderTopStyle": "initial",
                                                                "borderRightStyle": "initial",
                                                                "borderBottomStyle": "initial",
                                                                "borderLeftStyle": "initial",
                                                                "borderTopColor": "initial",
                                                                "borderRightColor": "initial",
                                                                "borderBottomColor": "initial",
                                                                "borderLeftColor": "initial",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "maxWidth": "100%",
                                                                "verticalAlign": "middle",
                                                                "display": "flex",
                                                                "objectFit": "cover",
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910ccd439872d9cfc5d96_Image.png",
                                                                "alt": "",
                                                                "maxHeight": "100%"
                                                            },
                                                            "children": []
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "7b772ab0487adfe547aff32936d7b2046b0726408084d4c993ee850ab5a64216",
                                                    "name": "info-2",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "flexDirection": "column",
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "flex-start",
                                                        "paddingTop": 18,
                                                        "paddingRight": 18,
                                                        "paddingBottom": 18,
                                                        "paddingLeft": 18,
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "b0744b94c3ac5c0679a2a19339a9fadf73af59a66cf98bc623ea737d8897fff2",
                                                            "name": "name",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontWeight": "600",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "6bc54856cf3d4bb9c2de30ea8ed271ea93bf87356d02f9743a0454170cfa4d05",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Full name</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "ff615d97de4a5fd39ebb306e5d2f5946a480d5768411d52c518b8cf12d6b842f",
                                                            "name": "description",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "90362ae632370363fddf0c3073bc86538952b8fb4b3d3815efae06064c211f6d",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                "scale": "5px"
            },
            {
                "widget": {
                    "id": "b261c6187a5951357b0a4fdb2d2058e51e1738cbe4f21755e509c240e7f6cfd7",
                    "name": "logos-quote-block",
                    "xtype": "group",
                    "properties": {
                        "columnGap": 60,
                        "rowGap": 60,
                        "justifyContent": "center",
                        "alignItems": "flex-start",
                        "paddingTop": 48,
                        "paddingRight": 18,
                        "paddingBottom": 48,
                        "paddingLeft": 18,
                        "display": "flex",
                    },
                    "children": [
                        {
                            "id": "018d23f322cee730c386e6ba06c414ee19e69f4749023d38022ff898432fceca",
                            "name": "columns-3",
                            "xtype": "group",
                            "properties": {
                                "maxWidth": 720,
                                "columnGap": 60,
                                "rowGap": 60,
                                "justifyContent": "center",
                                "alignItems": "center",
                                "display": "flex",
                                "small": {
                                    "flexDirection": "column"
                                }
                            },
                            "children": [
                                {
                                    "id": "e6b9014c4856592cc2c402ecc546225208b70bcd13b9ea4ebb5e549db3c38646",
                                    "name": "column",
                                    "xtype": "group",
                                    "properties": {
                                        "columnGap": 18,
                                        "rowGap": 18,
                                        "flexDirection": "column",
                                        "justifyContent": "flex-start",
                                        "alignItems": "flex-start",
                                        "display": "flex",
                                    },
                                    "children": [
                                        {
                                            "id": "270d2c98cc88df3fff9417c11dc604f2341283a07c3bf4050c75d839899c6772",
                                            "name": "content-3",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 18,
                                                "rowGap": 18,
                                                "backgroundColor": "rgb(245, 247, 250)",
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "paddingTop": 18,
                                                "paddingRight": 18,
                                                "paddingBottom": 18,
                                                "paddingLeft": 18,
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "61ae25d91389d2fd020bd81e4abfd9642f5cb0116ff947a70e44e087aceb866f",
                                                    "name": "quote",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "fontWeight": "700",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "20aba91b4ff560b9eeb9db37548d5ec22be0b4c0f949b615c236554b6d003b9b",
                                                            "xtype": "label",
                                                            "properties": {
                                                                "content": "<p>“Aliquet consectetur id magna ac integer. Aliquet consectetur id magna ac integer.”</p>",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "62c0600ab1357473a793923e06ad3bb2e1d23259d95af3e113eccae775e3fdbf",
                                                    "name": "author",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 12,
                                                        "rowGap": 12,
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "096f8f1a0f96d1fadcaf735f94533d1225cdda56b8e4a657dba297785c66e638",
                                                            "name": "image-5",
                                                            "xtype": "image",
                                                            "properties": {
                                                                "borderTopWidth": 0,
                                                                "borderRightWidth": 0,
                                                                "borderBottomWidth": 0,
                                                                "borderLeftWidth": 0,
                                                                "borderTopStyle": "initial",
                                                                "borderRightStyle": "initial",
                                                                "borderBottomStyle": "initial",
                                                                "borderLeftStyle": "initial",
                                                                "borderTopColor": "initial",
                                                                "borderRightColor": "initial",
                                                                "borderBottomColor": "initial",
                                                                "borderLeftColor": "initial",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "maxWidth": "100%",
                                                                "verticalAlign": "middle",
                                                                "display": "flex",
                                                                "objectFit": "cover",
                                                                "flexDirection": "column",
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910d43109a0be17c4c7a9_Image.png",
                                                                "alt": "",
                                                                "maxHeight": "100%"
                                                            },
                                                            "children": []
                                                        },
                                                        {
                                                            "id": "1c37e05653c27e28fb9c2d53d7e247e5897b51e52dd614e5db94f5a2849adbd9",
                                                            "name": "text-27",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": null,
                                                                "maxWidth": 231,
                                                                "columnGap": 3,
                                                                "rowGap": 3,
                                                                "flexDirection": "column",
                                                                "justifyContent": "flex-start",
                                                                "alignItems": "flex-start",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "9dd34f9a1d819176a25f814e5bd69251640c84d08a2a0d7e42513d3b455e7935",
                                                                    "name": "text-28",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontSize": 10.5,
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "e976eaffcac43330849df3679616a2abbded6bb845394170d60b80d59435396a",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>Full Name, Title</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "id": "873629cdcc9d14a25eb4cbf76bdf16dbc85a7fc139d17091996f03e9fd9439c0",
                                                                    "name": "text-28",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontSize": 10.5,
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "b073bd98ff29adb211550afadcf4d4c87eb33422b8a69c7cf2d5de49fe638388",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>Company</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "id": "43b16e654977dfb4984ae615a2c4404498bb4c5571b237ad8c522a73d31d6a07",
                                    "name": "column-5",
                                    "xtype": "group",
                                    "properties": {
                                        "columnGap": 18,
                                        "rowGap": 18,
                                        "justifyContent": "flex-start",
                                        "alignItems": "center",
                                        "display": "flex",
                                    },
                                    "children": [
                                        {
                                            "id": "ed23775373c051b43c960da582ad47bb1b34cdc5360627636d3a2a97c2635aff",
                                            "name": "content-4",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 36,
                                                "rowGap": 36,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "b732bb72086a711721d614a9d76d5dc87682e9cf9e1f9cae69dd038222a6180f",
                                                    "name": "logo-wrapper",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "flexDirection": "column",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "a533d3f13f4b3fe872ecc29c4034e0274caa8dd86fddf906493f9bdb07e1ed3e",
                                                            "name": "logo-2",
                                                            "xtype": "image",
                                                            "properties": {
                                                                "borderTopWidth": 0,
                                                                "borderRightWidth": 0,
                                                                "borderBottomWidth": 0,
                                                                "borderLeftWidth": 0,
                                                                "borderTopStyle": "initial",
                                                                "borderRightStyle": "initial",
                                                                "borderBottomStyle": "initial",
                                                                "borderLeftStyle": "initial",
                                                                "borderTopColor": "initial",
                                                                "borderRightColor": "initial",
                                                                "borderBottomColor": "initial",
                                                                "borderLeftColor": "initial",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "maxWidth": "100%",
                                                                "verticalAlign": "middle",
                                                                "display": "flex",
                                                                "objectFit": "cover",
                                                                "flexGrow": "0",
                                                                "flexShrink": "1",
                                                                "flexBasis": "auto",
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910d5af037b619f23746d_Logo.png",
                                                                "alt": "",
                                                                "maxHeight": "100%"
                                                            },
                                                            "children": []
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "89094afa4118e5fa88f18175ddd7c0cbadaeb753d446502cad9061055703cfab",
                                                    "name": "logo-wrapper",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "flexDirection": "column",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "22b40c64280251175aeac61536bc97536e5b434930c4b825044f2ddc3c7c3e66",
                                                            "name": "logo-2",
                                                            "xtype": "image",
                                                            "properties": {
                                                                "borderTopWidth": 0,
                                                                "borderRightWidth": 0,
                                                                "borderBottomWidth": 0,
                                                                "borderLeftWidth": 0,
                                                                "borderTopStyle": "initial",
                                                                "borderRightStyle": "initial",
                                                                "borderBottomStyle": "initial",
                                                                "borderLeftStyle": "initial",
                                                                "borderTopColor": "initial",
                                                                "borderRightColor": "initial",
                                                                "borderBottomColor": "initial",
                                                                "borderLeftColor": "initial",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "maxWidth": "100%",
                                                                "verticalAlign": "middle",
                                                                "display": "flex",
                                                                "objectFit": "cover",
                                                                "flexGrow": "0",
                                                                "flexShrink": "1",
                                                                "flexBasis": "auto",
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910d6964a1dec11b930b2_Logo.png",
                                                                "alt": "",
                                                                "maxHeight": "100%"
                                                            },
                                                            "children": []
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "1864e96f071e84fb5b5bfc6f227901a6504dd259f1550d7206d46d7394ed0109",
                                                    "name": "logo-wrapper",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "flexDirection": "column",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "7777064dd1c68450e906e3cb0d498b965d8800eaa3de9a9e6acf8c59832c77e1",
                                                            "name": "logo-2",
                                                            "xtype": "image",
                                                            "properties": {
                                                                "borderTopWidth": 0,
                                                                "borderRightWidth": 0,
                                                                "borderBottomWidth": 0,
                                                                "borderLeftWidth": 0,
                                                                "borderTopStyle": "initial",
                                                                "borderRightStyle": "initial",
                                                                "borderBottomStyle": "initial",
                                                                "borderLeftStyle": "initial",
                                                                "borderTopColor": "initial",
                                                                "borderRightColor": "initial",
                                                                "borderBottomColor": "initial",
                                                                "borderLeftColor": "initial",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "maxWidth": "100%",
                                                                "verticalAlign": "middle",
                                                                "display": "flex",
                                                                "objectFit": "cover",
                                                                "flexGrow": "0",
                                                                "flexShrink": "1",
                                                                "flexBasis": "auto",
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910d6657d18d0cc8ee778_Logo.png",
                                                                "alt": "",
                                                                "maxHeight": "100%"
                                                            },
                                                            "children": []
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "id": "e185bd25ceed5c75957b065db3f3219d1ba845f132c9a98ef0d0879d940d298a",
                                            "name": "content-4",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 36,
                                                "rowGap": 36,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "19f2aeee857e7cd79598a8ba737cc59d1fcdae8849ed58f3d0ad54f0145b1363",
                                                    "name": "logo-wrapper",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "flexDirection": "column",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "569f227eb892af5bf8746e6ed778e6962384afa3e1137fb4f9db3df074da3477",
                                                            "name": "logo-2",
                                                            "xtype": "image",
                                                            "properties": {
                                                                "borderTopWidth": 0,
                                                                "borderRightWidth": 0,
                                                                "borderBottomWidth": 0,
                                                                "borderLeftWidth": 0,
                                                                "borderTopStyle": "initial",
                                                                "borderRightStyle": "initial",
                                                                "borderBottomStyle": "initial",
                                                                "borderLeftStyle": "initial",
                                                                "borderTopColor": "initial",
                                                                "borderRightColor": "initial",
                                                                "borderBottomColor": "initial",
                                                                "borderLeftColor": "initial",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "maxWidth": "100%",
                                                                "verticalAlign": "middle",
                                                                "display": "flex",
                                                                "objectFit": "cover",
                                                                "flexGrow": "0",
                                                                "flexShrink": "1",
                                                                "flexBasis": "auto",
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910d724bcef7ae84c3cf9_Logo.png",
                                                                "alt": "",
                                                                "maxHeight": "100%"
                                                            },
                                                            "children": []
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "28fcd870f446a93f06159479604b9be89919f0aa87a119a26ab68769ede3cc3e",
                                                    "name": "logo-wrapper",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "flexDirection": "column",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "e9c9fc723201105fb07b75834a5ccedd5db2d43b139e2ca8753860a95a63eb4b",
                                                            "name": "logo-2",
                                                            "xtype": "image",
                                                            "properties": {
                                                                "borderTopWidth": 0,
                                                                "borderRightWidth": 0,
                                                                "borderBottomWidth": 0,
                                                                "borderLeftWidth": 0,
                                                                "borderTopStyle": "initial",
                                                                "borderRightStyle": "initial",
                                                                "borderBottomStyle": "initial",
                                                                "borderLeftStyle": "initial",
                                                                "borderTopColor": "initial",
                                                                "borderRightColor": "initial",
                                                                "borderBottomColor": "initial",
                                                                "borderLeftColor": "initial",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "maxWidth": "100%",
                                                                "verticalAlign": "middle",
                                                                "display": "flex",
                                                                "objectFit": "cover",
                                                                "flexGrow": "0",
                                                                "flexShrink": "1",
                                                                "flexBasis": "auto",
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910d81eee357972216ccf_Logo.png",
                                                                "alt": "",
                                                                "maxHeight": "100%"
                                                            },
                                                            "children": []
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "069c101e90302409f20963b0635833b4d84b4865bc343cdb469d3df9ab84a943",
                                                    "name": "logo-wrapper",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "flexDirection": "column",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "c06175544bf9500a1666a9efe3897fd8b61731546de127395626e00f61c7ace7",
                                                            "name": "logo-2",
                                                            "xtype": "image",
                                                            "properties": {
                                                                "borderTopWidth": 0,
                                                                "borderRightWidth": 0,
                                                                "borderBottomWidth": 0,
                                                                "borderLeftWidth": 0,
                                                                "borderTopStyle": "initial",
                                                                "borderRightStyle": "initial",
                                                                "borderBottomStyle": "initial",
                                                                "borderLeftStyle": "initial",
                                                                "borderTopColor": "initial",
                                                                "borderRightColor": "initial",
                                                                "borderBottomColor": "initial",
                                                                "borderLeftColor": "initial",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "maxWidth": "100%",
                                                                "verticalAlign": "middle",
                                                                "display": "flex",
                                                                "objectFit": "cover",
                                                                "flexGrow": "0",
                                                                "flexShrink": "1",
                                                                "flexBasis": "auto",
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910d93109a0be17c4c882_Logo.png",
                                                                "alt": "",
                                                                "maxHeight": "100%"
                                                            },
                                                            "children": []
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                "scale": "5px"
            },
            {
                "widget": {
                    "id": "65d6cc2adbb61846199f807ddb7d2d26ec99900bc7010821117b5a895171fb87",
                    "name": "logo-title",
                    "xtype": "group",
                    "properties": {
                        "columnGap": 24,
                        "rowGap": 24,
                        "flexDirection": "column",
                        "justifyContent": "flex-start",
                        "alignItems": "center",
                        "paddingTop": 48,
                        "paddingRight": 18,
                        "paddingBottom": 48,
                        "paddingLeft": 18,
                        "display": "flex",
                    },
                    "children": [
                        {
                            "id": "e641e4365819c4858a48065af9773b6fb23fe6ba1dbb081bc3d084321109e606",
                            "name": "title",
                            "xtype": "group",
                            "properties": {
                                "textAlign": "center",
                                "fontWeight": "700",
                            },
                            "children": [
                                {
                                    "id": "18147657d01ddbbfd9b60b4c6af57ec64087b775f960d869711d98d33f9956a8",
                                    "xtype": "label",
                                    "properties": {
                                        "content": "<p>Clients Section</p>",
                                        "maxWidth": "100%"
                                    }
                                }
                            ]
                        },
                        {
                            "id": "9351165994094323288955d35c381a9bdb2a3c3b5146956b3997db00cd4bdc3d",
                            "name": "content-5",
                            "xtype": "group",
                            "properties": {
                                "columnGap": 18,
                                "rowGap": 18,
                                "flexDirection": "column",
                                "justifyContent": "center",
                                "alignItems": "center",
                                "display": "flex",
                            },
                            "children": [
                                {
                                    "id": "3eec78c528061cc21850b400544bf6c7f24e9cd2f1f50d994375b92d01d4d356",
                                    "name": "logos",
                                    "xtype": "group",
                                    "properties": {
                                        "columnGap": 36,
                                        "rowGap": 36,
                                        "justifyContent": "center",
                                        "alignItems": "center",
                                        "display": "flex",
                                        "small": {
                                            "flexDirection": "column"
                                        }
                                    },
                                    "children": [
                                        {
                                            "id": "0ae7c1057b5e1e4e74e9287e5233a74384e0cc16678b27e120d14cbc24e88405",
                                            "name": "logo-wrapper",
                                            "xtype": "group",
                                            "properties": {
                                                "flexDirection": "column",
                                                "justifyContent": "center",
                                                "alignItems": "center",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "5418807fd3699a323de95cc6ac538275ad8cbe0e573b87f9654acbb88b2cd924",
                                                    "name": "logo-2",
                                                    "xtype": "image",
                                                    "properties": {
                                                        "borderTopWidth": 0,
                                                        "borderRightWidth": 0,
                                                        "borderBottomWidth": 0,
                                                        "borderLeftWidth": 0,
                                                        "borderTopStyle": "initial",
                                                        "borderRightStyle": "initial",
                                                        "borderBottomStyle": "initial",
                                                        "borderLeftStyle": "initial",
                                                        "borderTopColor": "initial",
                                                        "borderRightColor": "initial",
                                                        "borderBottomColor": "initial",
                                                        "borderLeftColor": "initial",
                                                        "borderImageSource": "initial",
                                                        "borderImageSlice": "initial",
                                                        "borderImageWidth": "initial",
                                                        "borderImageOutset": "initial",
                                                        "borderImageRepeat": "initial",
                                                        "maxWidth": "100%",
                                                        "verticalAlign": "middle",
                                                        "display": "flex",
                                                        "objectFit": "cover",
                                                        "flexGrow": "0",
                                                        "flexShrink": "1",
                                                        "flexBasis": "auto",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910df24bcef7ae84c3f25_Logo.png",
                                                        "alt": "",
                                                        "maxHeight": "100%"
                                                    },
                                                    "children": []
                                                }
                                            ]
                                        },
                                        {
                                            "id": "f2c5390f890e74b4c61046ec1035901ad063d4c5f52fc024ad3506eaa74580cd",
                                            "name": "logo-wrapper",
                                            "xtype": "group",
                                            "properties": {
                                                "flexDirection": "column",
                                                "justifyContent": "center",
                                                "alignItems": "center",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "45dc8a843d139971c8912973c89a81ae9322fbdf3c742ecdc0ed034cd9d3011a",
                                                    "name": "logo-2",
                                                    "xtype": "image",
                                                    "properties": {
                                                        "borderTopWidth": 0,
                                                        "borderRightWidth": 0,
                                                        "borderBottomWidth": 0,
                                                        "borderLeftWidth": 0,
                                                        "borderTopStyle": "initial",
                                                        "borderRightStyle": "initial",
                                                        "borderBottomStyle": "initial",
                                                        "borderLeftStyle": "initial",
                                                        "borderTopColor": "initial",
                                                        "borderRightColor": "initial",
                                                        "borderBottomColor": "initial",
                                                        "borderLeftColor": "initial",
                                                        "borderImageSource": "initial",
                                                        "borderImageSlice": "initial",
                                                        "borderImageWidth": "initial",
                                                        "borderImageOutset": "initial",
                                                        "borderImageRepeat": "initial",
                                                        "maxWidth": "100%",
                                                        "verticalAlign": "middle",
                                                        "display": "flex",
                                                        "objectFit": "cover",
                                                        "flexGrow": "0",
                                                        "flexShrink": "1",
                                                        "flexBasis": "auto",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910e05f8b4861180059a9_Logo.png",
                                                        "alt": "",
                                                        "maxHeight": "100%"
                                                    },
                                                    "children": []
                                                }
                                            ]
                                        },
                                        {
                                            "id": "6164c69132cc95db85526d61e2a27c9aa04a8832ab2e08ee88d4c676988a79b2",
                                            "name": "logo-wrapper",
                                            "xtype": "group",
                                            "properties": {
                                                "flexDirection": "column",
                                                "justifyContent": "center",
                                                "alignItems": "center",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "abf65a51edc657024acc3ced09e0af3f2c955515989d5d2318cb38af4e8fb36e",
                                                    "name": "logo-2",
                                                    "xtype": "image",
                                                    "properties": {
                                                        "borderTopWidth": 0,
                                                        "borderRightWidth": 0,
                                                        "borderBottomWidth": 0,
                                                        "borderLeftWidth": 0,
                                                        "borderTopStyle": "initial",
                                                        "borderRightStyle": "initial",
                                                        "borderBottomStyle": "initial",
                                                        "borderLeftStyle": "initial",
                                                        "borderTopColor": "initial",
                                                        "borderRightColor": "initial",
                                                        "borderBottomColor": "initial",
                                                        "borderLeftColor": "initial",
                                                        "borderImageSource": "initial",
                                                        "borderImageSlice": "initial",
                                                        "borderImageWidth": "initial",
                                                        "borderImageOutset": "initial",
                                                        "borderImageRepeat": "initial",
                                                        "maxWidth": "100%",
                                                        "verticalAlign": "middle",
                                                        "display": "flex",
                                                        "objectFit": "cover",
                                                        "flexGrow": "0",
                                                        "flexShrink": "1",
                                                        "flexBasis": "auto",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910e0bb698441ee7e4340_Logo.png",
                                                        "alt": "",
                                                        "maxHeight": "100%"
                                                    },
                                                    "children": []
                                                }
                                            ]
                                        },
                                        {
                                            "id": "ee85a62bbeb21651226f8125da45876abdbbc81aa90996223c5163856946c5b3",
                                            "name": "logo-wrapper",
                                            "xtype": "group",
                                            "properties": {
                                                "flexDirection": "column",
                                                "justifyContent": "center",
                                                "alignItems": "center",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "a84625235254d943b9c8293c385c52dd7513791aa280301323dc214592b1e771",
                                                    "name": "logo-2",
                                                    "xtype": "image",
                                                    "properties": {
                                                        "borderTopWidth": 0,
                                                        "borderRightWidth": 0,
                                                        "borderBottomWidth": 0,
                                                        "borderLeftWidth": 0,
                                                        "borderTopStyle": "initial",
                                                        "borderRightStyle": "initial",
                                                        "borderBottomStyle": "initial",
                                                        "borderLeftStyle": "initial",
                                                        "borderTopColor": "initial",
                                                        "borderRightColor": "initial",
                                                        "borderBottomColor": "initial",
                                                        "borderLeftColor": "initial",
                                                        "borderImageSource": "initial",
                                                        "borderImageSlice": "initial",
                                                        "borderImageWidth": "initial",
                                                        "borderImageOutset": "initial",
                                                        "borderImageRepeat": "initial",
                                                        "maxWidth": "100%",
                                                        "verticalAlign": "middle",
                                                        "display": "flex",
                                                        "objectFit": "cover",
                                                        "flexGrow": "0",
                                                        "flexShrink": "1",
                                                        "flexBasis": "auto",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910e15f01ec67313f879c_Logo.png",
                                                        "alt": "",
                                                        "maxHeight": "100%"
                                                    },
                                                    "children": []
                                                }
                                            ]
                                        },
                                        {
                                            "id": "04d1e1bd2bee3c2c1852d3eceeb1f727abd017f4a89deca665cbe39f236ed5e8",
                                            "name": "logo-wrapper",
                                            "xtype": "group",
                                            "properties": {
                                                "flexDirection": "column",
                                                "justifyContent": "center",
                                                "alignItems": "center",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "1b48028424096da66584a0008e2cbaa0ee2e58ca0cd6310425c9447b2caf4ad2",
                                                    "name": "logo-2",
                                                    "xtype": "image",
                                                    "properties": {
                                                        "borderTopWidth": 0,
                                                        "borderRightWidth": 0,
                                                        "borderBottomWidth": 0,
                                                        "borderLeftWidth": 0,
                                                        "borderTopStyle": "initial",
                                                        "borderRightStyle": "initial",
                                                        "borderBottomStyle": "initial",
                                                        "borderLeftStyle": "initial",
                                                        "borderTopColor": "initial",
                                                        "borderRightColor": "initial",
                                                        "borderBottomColor": "initial",
                                                        "borderLeftColor": "initial",
                                                        "borderImageSource": "initial",
                                                        "borderImageSlice": "initial",
                                                        "borderImageWidth": "initial",
                                                        "borderImageOutset": "initial",
                                                        "borderImageRepeat": "initial",
                                                        "maxWidth": "100%",
                                                        "verticalAlign": "middle",
                                                        "display": "flex",
                                                        "objectFit": "cover",
                                                        "flexGrow": "0",
                                                        "flexShrink": "1",
                                                        "flexBasis": "auto",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910e2964a1dec11b932b3_Logo.png",
                                                        "alt": "",
                                                        "maxHeight": "100%"
                                                    },
                                                    "children": []
                                                }
                                            ]
                                        },
                                        {
                                            "id": "32647943f31e67865dd102545a954fc933de2d58629970114802a18341890050",
                                            "name": "logo-wrapper",
                                            "xtype": "group",
                                            "properties": {
                                                "flexDirection": "column",
                                                "justifyContent": "center",
                                                "alignItems": "center",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "ec9f9c18720a5f2bd990f24f32778fda0b0254accb986b8820f469295a41d895",
                                                    "name": "logo-2",
                                                    "xtype": "image",
                                                    "properties": {
                                                        "borderTopWidth": 0,
                                                        "borderRightWidth": 0,
                                                        "borderBottomWidth": 0,
                                                        "borderLeftWidth": 0,
                                                        "borderTopStyle": "initial",
                                                        "borderRightStyle": "initial",
                                                        "borderBottomStyle": "initial",
                                                        "borderLeftStyle": "initial",
                                                        "borderTopColor": "initial",
                                                        "borderRightColor": "initial",
                                                        "borderBottomColor": "initial",
                                                        "borderLeftColor": "initial",
                                                        "borderImageSource": "initial",
                                                        "borderImageSlice": "initial",
                                                        "borderImageWidth": "initial",
                                                        "borderImageOutset": "initial",
                                                        "borderImageRepeat": "initial",
                                                        "maxWidth": "100%",
                                                        "verticalAlign": "middle",
                                                        "display": "flex",
                                                        "objectFit": "cover",
                                                        "flexGrow": "0",
                                                        "flexShrink": "1",
                                                        "flexBasis": "auto",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910e2ac6c6fffee42dd76_Logo.png",
                                                        "alt": "",
                                                        "maxHeight": "100%"
                                                    },
                                                    "children": []
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                "scale": "5px"
            },
            {
                "widget": {
                    "id": "f50c9776290d072e3c8bf60be1c01d432c0877e39cc5755b38a918df9d944fdf",
                    "name": "gallery-overview-2",
                    "xtype": "group",
                    "properties": {
                        "columnGap": 60,
                        "rowGap": 60,
                        "justifyContent": "center",
                        "alignItems": "flex-start",
                        "paddingTop": 48,
                        "paddingRight": 18,
                        "paddingBottom": 48,
                        "paddingLeft": 18,
                        "display": "flex",
                    },
                    "children": [
                        {
                            "id": "b1f598fdfbe3e6d0e08bf437e911fc52cd00a9ba340cf18fc7c4727b1b2f33a2",
                            "name": "columns-12",
                            "xtype": "group",
                            "properties": {
                                "maxWidth": 705,
                                "columnGap": 42,
                                "rowGap": 42,
                                "justifyContent": "flex-start",
                                "alignItems": "flex-start",
                                "display": "flex",
                                "small": {
                                    "flexDirection": "column",
                                    "alignItems": "center"
                                }
                            },
                            "children": [
                                {
                                    "id": "05150bc2f64069b1164ffbfa584fb3c8e9794d8c4a0bae6b7df8ef092f2f90c5",
                                    "name": "column-26",
                                    "xtype": "group",
                                    "properties": {
                                        "maxWidth": 195,
                                        "columnGap": 18,
                                        "rowGap": 18,
                                        "flexDirection": "column",
                                        "justifyContent": "flex-start",
                                        "alignItems": "flex-start",
                                        "display": "flex",
                                    },
                                    "children": [
                                        {
                                            "id": "ff7fe1dba14b7a3b902d6b98dd874eabed4d3ea1ebd2d5d4908561c25423879b",
                                            "name": "content-15",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 24,
                                                "rowGap": 24,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "b784e206fbc31db30a17cfc0f52aa12b8c3fc9f0bd8f5d2aac99a87a18f78ea5",
                                                    "name": "list-items",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 12,
                                                        "rowGap": 12,
                                                        "flexDirection": "column",
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "flex-start",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "c6ddf0db97abb1817f4c2736d2bea22d81c0c5a0703898c8ded1ee95a16418b7",
                                                            "name": "text-58",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 18,
                                                                "fontWeight": "700",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "e179bd0fed930682b64b302d0ea122377a992b8440cdc9001e034ae2c5b3aaa6",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Gallery Section</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "a6ce223e04b9c43173f4073127d376c9539a488fae48e07b0fc3d3037e288b5c",
                                                            "name": "description-8",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "820f23cec935c4f732a1bfa7a0f3c3fc2917fa973c19f86e949835a088625acd",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "8e5a968a51c04defcf16d5ece554ffdefe63011ff40bcc48902fdb09b77a78c6",
                                                            "name": "description-8",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "6b3961fd6ec07ca16614bfba7eb1f9b75bb9ef95643097455e1139ee4ff4f655",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Etiam sodales ac felis id interdum\n              Etiam sodales ac felis id interdum\n              Etiam sodales ac felis id interdum</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "cbdd4d3285571ff5fac4e611a42283b220c7e0deead2e1460780aad0df07f608",
                                                    "name": "button-8 w-button",
                                                    "xtype": "button",
                                                    "properties": {
                                                        "link": {
                                                            "target": "url",
                                                            "url": "#",
                                                            "page": 0
                                                        },
                                                        "display": "flex",
                                                        "paddingTop": 9,
                                                        "paddingRight": 18,
                                                        "paddingBottom": 9,
                                                        "paddingLeft": 18,
                                                        "borderTopWidth": 0,
                                                        "borderRightWidth": 0,
                                                        "borderBottomWidth": 0,
                                                        "borderLeftWidth": 0,
                                                        "borderTopStyle": "initial",
                                                        "borderRightStyle": "initial",
                                                        "borderBottomStyle": "initial",
                                                        "borderLeftStyle": "initial",
                                                        "borderTopColor": "initial",
                                                        "borderRightColor": "initial",
                                                        "borderBottomColor": "initial",
                                                        "borderLeftColor": "initial",
                                                        "borderImageSource": "initial",
                                                        "borderImageSlice": "initial",
                                                        "borderImageWidth": "initial",
                                                        "borderImageOutset": "initial",
                                                        "borderImageRepeat": "initial",
                                                        "textDecorationLine": "none",
                                                        "textDecorationThickness": "initial",
                                                        "textDecorationStyle": "initial",
                                                        "textDecorationColor": "initial",
                                                        "cursor": "pointer",
                                                        "borderTopLeftRadius": 0,
                                                        "borderTopRightRadius": 0,
                                                        "borderBottomRightRadius": 0,
                                                        "borderBottomLeftRadius": 0,
                                                        "columnGap": 6,
                                                        "rowGap": 6,
                                                        "flexGrow": "0",
                                                        "flexShrink": "1",
                                                        "flexBasis": "auto",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "small": {
                                                            "paddingTop": 6.75,
                                                            "paddingRight": 11.25,
                                                            "paddingBottom": 6.75,
                                                            "paddingLeft": 11.25
                                                        }
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "c02d097576f82a3bdb169e4d063b642b57966a56bd655b563730650502d5f048",
                                                            "name": "text-17",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 9,
                                                                "fontWeight": "500",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "1a52df3608832277cf32aea38e1b93f7b663b2956e4a2b9024de5ecb45d4487d",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>BOOK A DEMO</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "id": "22a39ef4de8625f502f5e525dd5e9de0df7edae5d5a998065d045833eae3a89a",
                                    "name": "small-columns-7",
                                    "xtype": "group",
                                    "properties": {
                                        "columnGap": 30,
                                        "rowGap": 30,
                                        "justifyContent": "flex-start",
                                        "alignItems": "flex-start",
                                        "display": "flex",
                                        "small": {
                                            "flexDirection": "column",
                                            "alignItems": "center"
                                        }
                                    },
                                    "children": [
                                        {
                                            "id": "37515d5da0a2ab42fb2cfb550745c83748357d8a47bdf27f48a597be616ce052",
                                            "name": "column-27",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 18,
                                                "rowGap": 18,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "02d9daa8d4bef63ab77f042ff5b4d9d5c7d8f5eb4092276cbb69958edfe869f9",
                                                    "name": "image-wrapper-16",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "flexDirection": "column",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "2e8dfa990f8b9beed53cd2f7f4cc8c661694cb1555dc0c0a4350660b43c42181",
                                                            "name": "image-23",
                                                            "xtype": "image",
                                                            "properties": {
                                                                "borderTopWidth": 0,
                                                                "borderRightWidth": 0,
                                                                "borderBottomWidth": 0,
                                                                "borderLeftWidth": 0,
                                                                "borderTopStyle": "initial",
                                                                "borderRightStyle": "initial",
                                                                "borderBottomStyle": "initial",
                                                                "borderLeftStyle": "initial",
                                                                "borderTopColor": "initial",
                                                                "borderRightColor": "initial",
                                                                "borderBottomColor": "initial",
                                                                "borderLeftColor": "initial",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "maxWidth": "100%",
                                                                "verticalAlign": "middle",
                                                                "display": "flex",
                                                                "objectFit": "cover",
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "small": {
                                                                    "maxWidth": "100%",
                                                                },
                                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910ea234d990641d6e630_Image.png",
                                                                "alt": "",
                                                                "maxHeight": "100%"
                                                            },
                                                            "children": []
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "cb148c669f1726fe2be5d79d2f906de4168e07eb75749ac72edd5065559984df",
                                                    "name": "image-wrapper-16",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "flexDirection": "column",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "87c5851ae883396891497f2e15b44e9769e48fc2d13efd3a43f5f40fe0905546",
                                                            "name": "image-24",
                                                            "xtype": "image",
                                                            "properties": {
                                                                "borderTopWidth": 0,
                                                                "borderRightWidth": 0,
                                                                "borderBottomWidth": 0,
                                                                "borderLeftWidth": 0,
                                                                "borderTopStyle": "initial",
                                                                "borderRightStyle": "initial",
                                                                "borderBottomStyle": "initial",
                                                                "borderLeftStyle": "initial",
                                                                "borderTopColor": "initial",
                                                                "borderRightColor": "initial",
                                                                "borderBottomColor": "initial",
                                                                "borderLeftColor": "initial",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "maxWidth": "100%",
                                                                "verticalAlign": "middle",
                                                                "display": "flex",
                                                                "objectFit": "cover",
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "small": {
                                                                    "maxWidth": "100%",
                                                                },
                                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910ebaffe7c9cd4c4bc04_Image.png",
                                                                "alt": "",
                                                                "maxHeight": "100%"
                                                            },
                                                            "children": []
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "3fc1e24854605823f3221836909eddf346b6212cdddf340455f9e8f86b854e1c",
                                                    "name": "image-wrapper-16",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "flexDirection": "column",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "153301fd854ead774d31281904fa4e1f89622a5dd1a455f0e6fe0cac0f96ad54",
                                                            "name": "image-25",
                                                            "xtype": "image",
                                                            "properties": {
                                                                "borderTopWidth": 0,
                                                                "borderRightWidth": 0,
                                                                "borderBottomWidth": 0,
                                                                "borderLeftWidth": 0,
                                                                "borderTopStyle": "initial",
                                                                "borderRightStyle": "initial",
                                                                "borderBottomStyle": "initial",
                                                                "borderLeftStyle": "initial",
                                                                "borderTopColor": "initial",
                                                                "borderRightColor": "initial",
                                                                "borderBottomColor": "initial",
                                                                "borderLeftColor": "initial",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "maxWidth": "100%",
                                                                "verticalAlign": "middle",
                                                                "display": "flex",
                                                                "objectFit": "cover",
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "small": {
                                                                    "maxWidth": "100%",
                                                                },
                                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910ea234d990641d6e630_Image.png",
                                                                "alt": "",
                                                                "maxHeight": "100%"
                                                            },
                                                            "children": []
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "14f34e401453a8c0f348046337f160cec8bcf892949ebc30e46b553d8c88cbce",
                                                    "name": "image-wrapper-16",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "flexDirection": "column",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "bcf0e5e0ec0cd63b5f3a302943f33ba8d9afab44b38dd36c0b7165bd64f8bf98",
                                                            "name": "image-26",
                                                            "xtype": "image",
                                                            "properties": {
                                                                "borderTopWidth": 0,
                                                                "borderRightWidth": 0,
                                                                "borderBottomWidth": 0,
                                                                "borderLeftWidth": 0,
                                                                "borderTopStyle": "initial",
                                                                "borderRightStyle": "initial",
                                                                "borderBottomStyle": "initial",
                                                                "borderLeftStyle": "initial",
                                                                "borderTopColor": "initial",
                                                                "borderRightColor": "initial",
                                                                "borderBottomColor": "initial",
                                                                "borderLeftColor": "initial",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "maxWidth": "100%",
                                                                "verticalAlign": "middle",
                                                                "display": "flex",
                                                                "objectFit": "cover",
                                                                "justifyContent": "flex-start",
                                                                "alignItems": "flex-start",
                                                                "small": {
                                                                    "maxWidth": "100%",
                                                                },
                                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910ec3109a0be17c4ce01_Image.png",
                                                                "alt": "",
                                                                "maxHeight": "100%"
                                                            },
                                                            "children": []
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "id": "d67e20fc2a52c159499447bc1fa2060f030fca61d508bec48ca58ad5c9ab369b",
                                            "name": "column-27",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 18,
                                                "rowGap": 18,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "47cb87e81b15e09f53e044da9ed6c261a71cdf9eee05d31da54bf028315a6543",
                                                    "name": "image-wrapper-16",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "flexDirection": "column",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "e2e1145e46f1806e138ab52bdcbd45d7f08e1e107ddc2d4ef63469cc369441f7",
                                                            "name": "image-23",
                                                            "xtype": "image",
                                                            "properties": {
                                                                "borderTopWidth": 0,
                                                                "borderRightWidth": 0,
                                                                "borderBottomWidth": 0,
                                                                "borderLeftWidth": 0,
                                                                "borderTopStyle": "initial",
                                                                "borderRightStyle": "initial",
                                                                "borderBottomStyle": "initial",
                                                                "borderLeftStyle": "initial",
                                                                "borderTopColor": "initial",
                                                                "borderRightColor": "initial",
                                                                "borderBottomColor": "initial",
                                                                "borderLeftColor": "initial",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "maxWidth": "100%",
                                                                "verticalAlign": "middle",
                                                                "display": "flex",
                                                                "objectFit": "cover",
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "small": {
                                                                    "maxWidth": "100%",
                                                                },
                                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910ebaffe7c9cd4c4bc04_Image.png",
                                                                "alt": "",
                                                                "maxHeight": "100%"
                                                            },
                                                            "children": []
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "3ab88a8edbd03656a2bc0d1f44e6d49419599be44697807c002cf6f140b7c0a0",
                                                    "name": "image-wrapper-17",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "5c56bc5aa95728c60be6195e3fd117ddfd48c5b92469f3ed1f838937674d6e30",
                                                            "name": "image-27",
                                                            "xtype": "image",
                                                            "properties": {
                                                                "borderTopWidth": 0,
                                                                "borderRightWidth": 0,
                                                                "borderBottomWidth": 0,
                                                                "borderLeftWidth": 0,
                                                                "borderTopStyle": "initial",
                                                                "borderRightStyle": "initial",
                                                                "borderBottomStyle": "initial",
                                                                "borderLeftStyle": "initial",
                                                                "borderTopColor": "initial",
                                                                "borderRightColor": "initial",
                                                                "borderBottomColor": "initial",
                                                                "borderLeftColor": "initial",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "maxWidth": "100%",
                                                                "verticalAlign": "middle",
                                                                "display": "flex",
                                                                "objectFit": "cover",
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "small": {
                                                                    "maxWidth": "100%",
                                                                },
                                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910ec3109a0be17c4ce01_Image.png",
                                                                "alt": "",
                                                                "maxHeight": "100%"
                                                            },
                                                            "children": []
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "3a3d56c3548b291a8957f5ffb1f005d0884965bc82f4f5697dc8c46ef628c787",
                                                    "name": "image-wrapper-16",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "flexDirection": "column",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "f9fcf40fcdb152d50dd739ab5c850eeca631e756cfc88ccc4e302988a949d990",
                                                            "name": "image-28",
                                                            "xtype": "image",
                                                            "properties": {
                                                                "borderTopWidth": 0,
                                                                "borderRightWidth": 0,
                                                                "borderBottomWidth": 0,
                                                                "borderLeftWidth": 0,
                                                                "borderTopStyle": "initial",
                                                                "borderRightStyle": "initial",
                                                                "borderBottomStyle": "initial",
                                                                "borderLeftStyle": "initial",
                                                                "borderTopColor": "initial",
                                                                "borderRightColor": "initial",
                                                                "borderBottomColor": "initial",
                                                                "borderLeftColor": "initial",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "maxWidth": "100%",
                                                                "verticalAlign": "middle",
                                                                "display": "flex",
                                                                "objectFit": "cover",
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "small": {
                                                                    "maxWidth": "100%",
                                                                },
                                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910ebaffe7c9cd4c4bc04_Image.png",
                                                                "alt": "",
                                                                "maxHeight": "100%"
                                                            },
                                                            "children": []
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "6c42baffa6e6d46c138a5496af9050a69fe6318912063f5859cffdf68cba6d46",
                                                    "name": "image-wrapper-16",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "flexDirection": "column",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "1fe546103c2c84cf8e4f5b7ba05bbdabaf1db9e88def3f156c095f78dcda2822",
                                                            "name": "image-29",
                                                            "xtype": "image",
                                                            "properties": {
                                                                "borderTopWidth": 0,
                                                                "borderRightWidth": 0,
                                                                "borderBottomWidth": 0,
                                                                "borderLeftWidth": 0,
                                                                "borderTopStyle": "initial",
                                                                "borderRightStyle": "initial",
                                                                "borderBottomStyle": "initial",
                                                                "borderLeftStyle": "initial",
                                                                "borderTopColor": "initial",
                                                                "borderRightColor": "initial",
                                                                "borderBottomColor": "initial",
                                                                "borderLeftColor": "initial",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "maxWidth": "100%",
                                                                "verticalAlign": "middle",
                                                                "display": "flex",
                                                                "objectFit": "cover",
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "small": {
                                                                    "maxWidth": "100%",
                                                                },
                                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910ea234d990641d6e630_Image.png",
                                                                "alt": "",
                                                                "maxHeight": "100%"
                                                            },
                                                            "children": []
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                "scale": "5px"
            },
            {
                "widget": {
                    "id": "1052d4b36eac9aefbefb9c092e4cf853e07a2c2c9200cf594f23662ce761464e",
                    "name": "gallery-scroll",
                    "xtype": "group",
                    "properties": {
                        "columnGap": 60,
                        "rowGap": 60,
                        "justifyContent": "center",
                        "alignItems": "flex-start",
                        "paddingTop": 48,
                        "paddingRight": 18,
                        "paddingBottom": 48,
                        "paddingLeft": 18,
                        "display": "flex",
                    },
                    "children": [
                        {
                            "id": "e855c42fc94ff56680e160ae3d600f7dd6e51e693572f2124f91ea6424bc3887",
                            "name": "columns-5",
                            "xtype": "group",
                            "properties": {
                                "maxWidth": 705,
                                "columnGap": 60,
                                "rowGap": 60,
                                "justifyContent": "center",
                                "alignItems": "flex-start",
                                "display": "flex",
                                "small": {
                                    "flexDirection": "column",
                                    "alignItems": "center"
                                }
                            },
                            "children": [
                                {
                                    "id": "8ea54b64aedb1c63faecd535697bad6b36d621ebf4511a7ca68c39a16dc96d0c",
                                    "name": "column-7",
                                    "xtype": "group",
                                    "properties": {
                                        "maxWidth": 195,
                                        "columnGap": 18,
                                        "rowGap": 18,
                                        "flexDirection": "column",
                                        "justifyContent": "flex-start",
                                        "alignItems": "flex-start",
                                        "display": "flex",
                                    },
                                    "children": [
                                        {
                                            "id": "688f2053bf5fddddf87b315150eb017b63b33ceb8db633b2d430cb0c576ebe29",
                                            "name": "content-7",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 18,
                                                "rowGap": 18,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "20dadb041b8c73747067ab599f3a3e327bb4704799b9b69bc0c3e53c37cc5884",
                                                    "name": "text-30",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "fontSize": 18,
                                                        "fontWeight": "500",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "28782b504b945b23fc3fd9a0041fee8fb6ee279d2270b912191f14fab5118810",
                                                            "xtype": "label",
                                                            "properties": {
                                                                "content": "<p>Virtual booths</p>",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "24ecc87fd7b3f81a55c96a4f72556e9b20c4ab4a1bdcbb9e4017c4ec980744b2",
                                                    "name": "text-30",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "fontSize": 18,
                                                        "fontWeight": "500",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "24d90f479749c84c3b32d8bae22ab58dfdd4c1cea3ec7d319943f58dad769740",
                                                            "xtype": "label",
                                                            "properties": {
                                                                "content": "<p>Access to dashboard</p>",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "704c73d70498d73bb95db28b926da54039b725ddf180b0e697ea36ad37b3d227",
                                                    "name": "text-30",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "fontSize": 18,
                                                        "fontWeight": "500",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "288605ab2ca7b777f3c6c547e3c3d8d3dd92702a9650143bf17d4be4e7184a21",
                                                            "xtype": "label",
                                                            "properties": {
                                                                "content": "<p>Lead retrieval</p>",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "61a3ce1a8f56fff9d22cf5f8131fdbfeec675900179f9f666360c7c04dc1e279",
                                                    "name": "text-30",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "fontSize": 18,
                                                        "fontWeight": "500",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "134453c66eb0e936583134cad470cdd52cfc55aa387478621d3c9d0eb4e5d3ac",
                                                            "xtype": "label",
                                                            "properties": {
                                                                "content": "<p>Lead scoring</p>",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "bab87061122885eba88bcaa4e19c5c1483ee012af6609b2f57b1335cafe397a8",
                                                    "name": "text-30",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "fontSize": 18,
                                                        "fontWeight": "500",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "8f86d2f75ca9838f9646a66ba3a2ad862dba46541d1d947ecd4263e16c28b7eb",
                                                            "xtype": "label",
                                                            "properties": {
                                                                "content": "<p>Sponsored sections</p>",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "id": "3d572e96f2e75b37718c874ccc6a88b52c8d2acebee0c89e278b2af3c9aea5b4",
                                    "name": "column-8",
                                    "xtype": "group",
                                    "properties": {
                                        "columnGap": 18,
                                        "rowGap": 18,
                                        "justifyContent": "center",
                                        "alignItems": "flex-start",
                                        "display": "flex",
                                    },
                                    "children": [
                                        {
                                            "id": "d11493051a89b52cc36deaaad5565afc251b5653c750482bf82b48aa41ba3429",
                                            "name": "content-8",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 18,
                                                "rowGap": 18,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "center",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "e85f9d2c6d247f73ac8e3bc4f42147adb414e3e0a13ad7dd57554da0c4704a4a",
                                                    "name": "image-wrapper-7",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "flexDirection": "column",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                        "position": "relative",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "238d57ae77bf098b2edc7cd6c087b2c7ba1472ae668bfc5fbd8e64ff9d905e23",
                                                            "name": "image-13",
                                                            "xtype": "image",
                                                            "properties": {
                                                                "borderTopWidth": 0,
                                                                "borderRightWidth": 0,
                                                                "borderBottomWidth": 0,
                                                                "borderLeftWidth": 0,
                                                                "borderTopStyle": "initial",
                                                                "borderRightStyle": "initial",
                                                                "borderBottomStyle": "initial",
                                                                "borderLeftStyle": "initial",
                                                                "borderTopColor": "initial",
                                                                "borderRightColor": "initial",
                                                                "borderBottomColor": "initial",
                                                                "borderLeftColor": "initial",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "maxWidth": "100%",
                                                                "verticalAlign": "middle",
                                                                "display": "flex",
                                                                "objectFit": "cover",
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910f7689e7ca5ef3ead87_Image.png",
                                                                "alt": "",
                                                                "maxHeight": "100%"
                                                            },
                                                            "children": []
                                                        },
                                                        {
                                                            "id": "83dc4c8e2514197ebe758408a8d86f6019650c3db07d861cd27b3551cfe088db",
                                                            "name": "text-31",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 18,
                                                                "fontWeight": "700",
                                                                "position": "absolute",
                                                                "top": 246,
                                                                "left": 12,
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "45b76928aa17454af9387e7e8b9a0514f074dc9dcf0bc10769d95fde79aa2899",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Virtual booths</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "48937338e8f39b97746352920ca5c2ad8b3390a4568e8c86160aebfb6070d809",
                                                    "name": "image-wrapper-7",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "flexDirection": "column",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                        "position": "relative",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "b4cc56e7d70d943ce4dd6a825b6723f1f32e104bd2139a9edb7d3933bdc78031",
                                                            "name": "image-13",
                                                            "xtype": "image",
                                                            "properties": {
                                                                "borderTopWidth": 0,
                                                                "borderRightWidth": 0,
                                                                "borderBottomWidth": 0,
                                                                "borderLeftWidth": 0,
                                                                "borderTopStyle": "initial",
                                                                "borderRightStyle": "initial",
                                                                "borderBottomStyle": "initial",
                                                                "borderLeftStyle": "initial",
                                                                "borderTopColor": "initial",
                                                                "borderRightColor": "initial",
                                                                "borderBottomColor": "initial",
                                                                "borderLeftColor": "initial",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "maxWidth": "100%",
                                                                "verticalAlign": "middle",
                                                                "display": "flex",
                                                                "objectFit": "cover",
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910f868a2877bd6d93d46_Image.png",
                                                                "alt": "",
                                                                "maxHeight": "100%"
                                                            },
                                                            "children": []
                                                        },
                                                        {
                                                            "id": "b1c99d09fda693f5e58f4f2f6862b4ccba97e4f2582d9c6336564fbbd883a14b",
                                                            "name": "text-31",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 18,
                                                                "fontWeight": "700",
                                                                "position": "absolute",
                                                                "top": 246,
                                                                "left": 12,
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "298d955e6c129de8ba086ec0e17db673e9b3e1fa1cdb424d497462a964fff894",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Access to dashboard</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "e212735ac3c47446004b88d8e7744fbf6bb4ffdd604476a35ab9d503ab21e1fc",
                                                    "name": "image-wrapper-7",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "flexDirection": "column",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                        "position": "relative",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "6fc1e6ca80f6260edec66e10d324cc57d3e3cabbddee7f70a8207d139c55ad99",
                                                            "name": "image-13",
                                                            "xtype": "image",
                                                            "properties": {
                                                                "borderTopWidth": 0,
                                                                "borderRightWidth": 0,
                                                                "borderBottomWidth": 0,
                                                                "borderLeftWidth": 0,
                                                                "borderTopStyle": "initial",
                                                                "borderRightStyle": "initial",
                                                                "borderBottomStyle": "initial",
                                                                "borderLeftStyle": "initial",
                                                                "borderTopColor": "initial",
                                                                "borderRightColor": "initial",
                                                                "borderBottomColor": "initial",
                                                                "borderLeftColor": "initial",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "maxWidth": "100%",
                                                                "verticalAlign": "middle",
                                                                "display": "flex",
                                                                "objectFit": "cover",
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910f7689e7ca5ef3ead87_Image.png",
                                                                "alt": "",
                                                                "maxHeight": "100%"
                                                            },
                                                            "children": []
                                                        },
                                                        {
                                                            "id": "4b3c7da2c7cc12830488565d08727319b2b5513270ecaaa0dbd127ae2689b590",
                                                            "name": "text-31",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 18,
                                                                "fontWeight": "700",
                                                                "position": "absolute",
                                                                "top": 246,
                                                                "left": 12,
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "5df22b70b50c5c04a8bae92cde660efe6f6f7b942d9f24eecb00e0b8a2bc3261",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Lead retrieval</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "570141871d5d97026a5ed3af37f43bd50ee9a383ca96d1d39707b91c7af3662f",
                                                    "name": "image-wrapper-7",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "flexDirection": "column",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                        "position": "relative",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "c1088cdc9ec47faae230d934ffd7c8a148c3eabd28dc6bc890e65bbb46b83623",
                                                            "name": "image-13",
                                                            "xtype": "image",
                                                            "properties": {
                                                                "borderTopWidth": 0,
                                                                "borderRightWidth": 0,
                                                                "borderBottomWidth": 0,
                                                                "borderLeftWidth": 0,
                                                                "borderTopStyle": "initial",
                                                                "borderRightStyle": "initial",
                                                                "borderBottomStyle": "initial",
                                                                "borderLeftStyle": "initial",
                                                                "borderTopColor": "initial",
                                                                "borderRightColor": "initial",
                                                                "borderBottomColor": "initial",
                                                                "borderLeftColor": "initial",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "maxWidth": "100%",
                                                                "verticalAlign": "middle",
                                                                "display": "flex",
                                                                "objectFit": "cover",
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910f8b6b0743baa7f3810_Image.png",
                                                                "alt": "",
                                                                "maxHeight": "100%"
                                                            },
                                                            "children": []
                                                        },
                                                        {
                                                            "id": "42658d0f1bfd3116edebc6bbbb71ae713a28f5ddf30a7cba0e9c68a67214d0f4",
                                                            "name": "text-31",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 18,
                                                                "fontWeight": "700",
                                                                "position": "absolute",
                                                                "top": 246,
                                                                "left": 12,
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "9f53733e81e04f072b5522351d502d0ef0da89c6cc95e04cacabb158809c694e",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Lead scoring</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "c0849dc8a5749bd4e251ee6de4f14845eabb2bdaf01ece0dca080518ff965a03",
                                                    "name": "image-wrapper-7",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "flexDirection": "column",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                        "position": "relative",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "d89c507bd7997e92a1fc6bfa95a4ec7c85eda395a72467a9efabea20752415cb",
                                                            "name": "image-13",
                                                            "xtype": "image",
                                                            "properties": {
                                                                "borderTopWidth": 0,
                                                                "borderRightWidth": 0,
                                                                "borderBottomWidth": 0,
                                                                "borderLeftWidth": 0,
                                                                "borderTopStyle": "initial",
                                                                "borderRightStyle": "initial",
                                                                "borderBottomStyle": "initial",
                                                                "borderLeftStyle": "initial",
                                                                "borderTopColor": "initial",
                                                                "borderRightColor": "initial",
                                                                "borderBottomColor": "initial",
                                                                "borderLeftColor": "initial",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "maxWidth": "100%",
                                                                "verticalAlign": "middle",
                                                                "display": "flex",
                                                                "objectFit": "cover",
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910f7689e7ca5ef3ead87_Image.png",
                                                                "alt": "",
                                                                "maxHeight": "100%"
                                                            },
                                                            "children": []
                                                        },
                                                        {
                                                            "id": "8c608bba158528ba5693a42d59cab2305d32e56e5e2f0fd4dbd6555ea938a75d",
                                                            "name": "text-31",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 18,
                                                                "fontWeight": "700",
                                                                "position": "absolute",
                                                                "top": 246,
                                                                "left": 12,
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "e71e8af944874e48a44852032f098e0760e57d1f7d1953dbfb16cac372f725e6",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Sponsored sections</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                "scale": "5px"
            },
            {
                "widget": {
                    "id": "a4ba3eb222ea4c5b055f9665decd31bb4613f35c773c8915e2373bd2d194fa5a",
                    "name": "features-list",
                    "xtype": "group",
                    "properties": {
                        "columnGap": 60,
                        "rowGap": 60,
                        "justifyContent": "center",
                        "alignItems": "flex-start",
                        "paddingTop": 48,
                        "paddingRight": 18,
                        "paddingBottom": 48,
                        "paddingLeft": 18,
                        "display": "flex",
                    },
                    "children": [
                        {
                            "id": "bd15c6ab1e14ac95c7ff3995bfe65da13236cc65434000c26d31144d5ffb7646",
                            "name": "columns-6",
                            "xtype": "group",
                            "properties": {
                                "maxWidth": 720,
                                "columnGap": 60,
                                "rowGap": 60,
                                "justifyContent": "center",
                                "alignItems": "flex-start",
                                "display": "flex",
                                "small": {
                                    "flexDirection": "column",
                                    "alignItems": "center"
                                }
                            },
                            "children": [
                                {
                                    "id": "e9ab34fb47b65c3ec983d95b54613f6b3169a7176917a09b0b60166fca6f2665",
                                    "name": "column",
                                    "xtype": "group",
                                    "properties": {
                                        "columnGap": 18,
                                        "rowGap": 18,
                                        "flexDirection": "column",
                                        "justifyContent": "flex-start",
                                        "alignItems": "flex-start",
                                        "display": "flex",
                                    },
                                    "children": [
                                        {
                                            "id": "e9fc500fa63af19c22c66037528fdeb28eba5383a6b9010af73b53be5d676231",
                                            "name": "content-9",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 18,
                                                "rowGap": 18,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "paddingTop": 18,
                                                "paddingBottom": 18,
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "861c22f0e5d6bcc2995cd8f1426d9a1ba23a76071c630cb15d50e0f92c305676",
                                                    "name": "intro",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 12,
                                                        "rowGap": 12,
                                                        "flexDirection": "column",
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "flex-start",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "cf3d497b4664c7a8b8a5a0b27cba7f24e913525e2ec90674e408345c7414aac9",
                                                            "name": "title-2",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontWeight": "700",                                                                
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "0b90dacccf7478b37ce2010f8765c930e799c1db6eeb881bf3af61ca02a090fc",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Features section</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "089081d04f0bb6544c63435eda27143fee28fe4faeaba0309415a8cf7bcb2b36",
                                                            "name": "description-3",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "cc44d2f98966702b4ce93c169ec1d19de1a7c838375aa3adf1c095d4e56e2425",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse tincidunt sagittis eros. Quisque quis euismod lorem. Etiam sodales ac felis id interdum.</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "ad8dc426137dff576eee44715d2938937fa8b832df1c7b56289ce999ef5e8f5b",
                                                    "name": "description-3",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "fontWeight": "400",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "6021e5327a0866079f344ca814700ac4abcc20e8c9238c119866c33cf066be73",
                                                            "xtype": "label",
                                                            "properties": {
                                                                "content": "<p>Learn more</p>",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "id": "1aac194b2b051642f70eff217988b5992f21ee0892f69f383acd4746e9712407",
                                    "name": "intro",
                                    "xtype": "group",
                                    "properties": {
                                        "columnGap": 12,
                                        "rowGap": 12,
                                        "flexDirection": "column",
                                        "justifyContent": "flex-start",
                                        "alignItems": "flex-start",
                                        "display": "flex",
                                    },
                                    "children": [
                                        {
                                            "id": "772c89396f86da3515fcc26677f6c8ad5bb92e1ee05a977c9cca2caae8643b41",
                                            "name": "feature",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 18,
                                                "rowGap": 18,
                                                "justifyContent": "flex-start",
                                                "alignItems": "center",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "c153cb7952ccb241447ac7195f6e9b2eff42b854f26d662d92f2e43ff2a354d6",
                                                    "name": "image-14",
                                                    "xtype": "image",
                                                    "properties": {
                                                        "borderTopWidth": 0,
                                                        "borderRightWidth": 0,
                                                        "borderBottomWidth": 0,
                                                        "borderLeftWidth": 0,
                                                        "borderTopStyle": "initial",
                                                        "borderRightStyle": "initial",
                                                        "borderBottomStyle": "initial",
                                                        "borderLeftStyle": "initial",
                                                        "borderTopColor": "initial",
                                                        "borderRightColor": "initial",
                                                        "borderBottomColor": "initial",
                                                        "borderLeftColor": "initial",
                                                        "borderImageSource": "initial",
                                                        "borderImageSlice": "initial",
                                                        "borderImageWidth": "initial",
                                                        "borderImageOutset": "initial",
                                                        "borderImageRepeat": "initial",
                                                        "maxWidth": "100%",
                                                        "verticalAlign": "middle",
                                                        "display": "flex",
                                                        "objectFit": "cover",
                                                        "flexDirection": "column",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f91502933ff86cc6cd257f_Image.png",
                                                        "alt": "",
                                                        "maxHeight": "100%"
                                                    },
                                                    "children": []
                                                },
                                                {
                                                    "id": "d7f3985a25a2bd77c7b2f1fa3a780ce6e189d4dc7733577d147b33a7ea86178a",
                                                    "name": "description-3",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "fontWeight": "400",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "fa7dec0f78bb054c8193af5c4822b6ae9b3de5a2be937a9fb831b8e27441a818",
                                                            "xtype": "label",
                                                            "properties": {
                                                                "content": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse tincidunt sagittis eros.</p>",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "id": "4f97a3558e4b527de2d775db333c6b7769fe5f07cb16b4f37c7809ec4cf2f979",
                                            "name": "feature",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 18,
                                                "rowGap": 18,
                                                "justifyContent": "flex-start",
                                                "alignItems": "center",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "cea90c66754457a32dbcefed4f936669f048eb2ca9ce6ada873dae688c825a32",
                                                    "name": "image-14",
                                                    "xtype": "image",
                                                    "properties": {
                                                        "borderTopWidth": 0,
                                                        "borderRightWidth": 0,
                                                        "borderBottomWidth": 0,
                                                        "borderLeftWidth": 0,
                                                        "borderTopStyle": "initial",
                                                        "borderRightStyle": "initial",
                                                        "borderBottomStyle": "initial",
                                                        "borderLeftStyle": "initial",
                                                        "borderTopColor": "initial",
                                                        "borderRightColor": "initial",
                                                        "borderBottomColor": "initial",
                                                        "borderLeftColor": "initial",
                                                        "borderImageSource": "initial",
                                                        "borderImageSlice": "initial",
                                                        "borderImageWidth": "initial",
                                                        "borderImageOutset": "initial",
                                                        "borderImageRepeat": "initial",
                                                        "maxWidth": "100%",
                                                        "verticalAlign": "middle",
                                                        "display": "flex",
                                                        "objectFit": "cover",
                                                        "flexDirection": "column",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f91502933ff86cc6cd257f_Image.png",
                                                        "alt": "",
                                                        "maxHeight": "100%"
                                                    },
                                                    "children": []
                                                },
                                                {
                                                    "id": "469579af82ad2df5aa0c665bb2841964498248495ee5072ddd33f1fc19cfce45",
                                                    "name": "description-3",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "fontWeight": "400",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "313f4a2eb04828ae27bbb38f63588ef4a7dc16e185825f1719d10fd3527fc11c",
                                                            "xtype": "label",
                                                            "properties": {
                                                                "content": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse tincidunt sagittis eros.</p>",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "id": "f6a9834828816188f7fd6c122005a48047d12d4f6c226a3a5727aac3c0aeb8d4",
                                            "name": "feature",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 18,
                                                "rowGap": 18,
                                                "justifyContent": "flex-start",
                                                "alignItems": "center",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "46079d9a10ae701ecf05ad48ff7e92dee1a16ac1d8b3f0febb0f9402e1455c60",
                                                    "name": "image-14",
                                                    "xtype": "image",
                                                    "properties": {
                                                        "borderTopWidth": 0,
                                                        "borderRightWidth": 0,
                                                        "borderBottomWidth": 0,
                                                        "borderLeftWidth": 0,
                                                        "borderTopStyle": "initial",
                                                        "borderRightStyle": "initial",
                                                        "borderBottomStyle": "initial",
                                                        "borderLeftStyle": "initial",
                                                        "borderTopColor": "initial",
                                                        "borderRightColor": "initial",
                                                        "borderBottomColor": "initial",
                                                        "borderLeftColor": "initial",
                                                        "borderImageSource": "initial",
                                                        "borderImageSlice": "initial",
                                                        "borderImageWidth": "initial",
                                                        "borderImageOutset": "initial",
                                                        "borderImageRepeat": "initial",
                                                        "maxWidth": "100%",
                                                        "verticalAlign": "middle",
                                                        "display": "flex",
                                                        "objectFit": "cover",
                                                        "flexDirection": "column",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f91502933ff86cc6cd257f_Image.png",
                                                        "alt": "",
                                                        "maxHeight": "100%"
                                                    },
                                                    "children": []
                                                },
                                                {
                                                    "id": "7f3b2ebd4eff34b5239c331512b122945f0fed0062e242620fbfde1509a22c8d",
                                                    "name": "description-3",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "fontWeight": "400",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "63c8df619e0d1c19edc93150243c924c67451a0603c8621433063196ab5de0cf",
                                                            "xtype": "label",
                                                            "properties": {
                                                                "content": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse tincidunt sagittis eros.</p>",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                "scale": "5px"
            },
            {
                "widget": {
                    "id": "db1acc77224eeeb78e8d8630c6c2eadf0f14662ade4693503a15cec415dd0703",
                    "name": "features-metrics",
                    "xtype": "group",
                    "properties": {
                        "columnGap": 24,
                        "rowGap": 24,
                        "backgroundColor": "rgb(245, 247, 250)",
                        "flexDirection": "column",
                        "justifyContent": "flex-start",
                        "alignItems": "center",
                        "paddingTop": 48,
                        "paddingRight": 18,
                        "paddingBottom": 48,
                        "paddingLeft": 18,
                        "display": "flex",
                    },
                    "children": [
                        {
                            "id": "74f620cfe7c8724a1d9b7a7dc190910885574ceedc6191e3730f11a336efd12e",
                            "name": "small-container-3",
                            "xtype": "group",
                            "properties": {
                                "maxWidth": 720,
                                "columnGap": 18,
                                "rowGap": 18,
                                "flexDirection": "column",
                                "justifyContent": "center",
                                "alignItems": "center",
                                "display": "flex",
                            },
                            "children": [
                                {
                                    "id": "be3f4e4c8f0e24ce257abf4317fc63f3cd8c9e4377fc443bce3b775e244cb694",
                                    "name": "columns-7",
                                    "xtype": "group",
                                    "properties": {
                                        "justifyContent": "space-between",
                                        "alignItems": "center",
                                        "display": "flex",
                                        "small": {
                                            "flexDirection": "column"
                                        }
                                    },
                                    "children": [
                                        {
                                            "id": "581b51d4dcd8a6f7e6de4b963e35a99efd4da59ed3804a95b67c482351d593a6",
                                            "name": "metric",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 3,
                                                "rowGap": 3,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "center",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "d12945cb30b9593171e4daebd8308aaeb7a58a33f9f1c20dc1a8462ea074f0d8",
                                                    "name": "metric-value",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "textAlign": "center",
                                                        "fontWeight": "700",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "74041173bea0f271e7112d0eacd5e902e91d812e9ffc6c69c478b7f2859b6622",
                                                            "xtype": "label",
                                                            "properties": {
                                                                "content": "<p>12%</p>",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "97355ba6d6ef6d3847aa7bcfa36921fae08425ed00dbe9c4c84de0c9fc755ae9",
                                                    "name": "text-32",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "fontSize": 10.5,
                                                        "textAlign": "center",
                                                        "fontWeight": "400",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "21a2ee4abf55d0fdd73f63ff13bac7c7bf382be4bfc851173807b1074a259a54",
                                                            "xtype": "label",
                                                            "properties": {
                                                                "content": "<p>Metric description</p>",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "id": "f8e7919957a00fa8cdcff8dacd1f7947568c4308dc9a6b18c4b43c93364d7a4c",
                                            "name": "metric",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 3,
                                                "rowGap": 3,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "center",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "ba5edaed182145ef5431a1abd98104ca80d35848c024842e8abc355e12d32021",
                                                    "name": "metric-value",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "textAlign": "center",
                                                        "fontWeight": "700",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "c72d7f688217c0b82fad100190eeee6b305def052cf1ebeb9ab2fcb2101c07c0",
                                                            "xtype": "label",
                                                            "properties": {
                                                                "content": "<p>87%</p>",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "e61c873ae6b4c2a4d4e3afcc00539d32c439bf2ac42c0b4f5c3032601a772e4b",
                                                    "name": "text-32",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "fontSize": 10.5,
                                                        "textAlign": "center",
                                                        "fontWeight": "400",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "12350a08bd9814cfbfdb00c6d011959e0f8566e5f17176124b3fce23f3724ae5",
                                                            "xtype": "label",
                                                            "properties": {
                                                                "content": "<p>Metric description</p>",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "id": "2446e8505bb883f3064f858b8ded6cf7685101887861be578f191ca936f960cd",
                                            "name": "metric",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 3,
                                                "rowGap": 3,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "center",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "aeba471c0b57e92333f1e458b7c2f71b787f21a39a9cdf2960ecbe5a58c8f86e",
                                                    "name": "metric-value",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "textAlign": "center",
                                                        "fontWeight": "700",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "d6d97a994c63e9aed808b183cc97b66cb7696aca1d90d1547371ced92597c8b3",
                                                            "xtype": "label",
                                                            "properties": {
                                                                "content": "<p>$5000</p>",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "a973c1155a180c1d4ee0958dd52329b66fe4bdbbb0969050f25a2665896c5308",
                                                    "name": "text-32",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "fontSize": 10.5,
                                                        "textAlign": "center",
                                                        "fontWeight": "400",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "f75f5fc373381663f2dadf80ce2a97e1a343cadf0766d440f3303e5a35943edf",
                                                            "xtype": "label",
                                                            "properties": {
                                                                "content": "<p>Metric description</p>",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "id": "c8a7995e1b2167613e54e1f92dbccbefda8e2eb6bac62bd67c19c3abb0527e4f",
                                            "name": "metric",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 3,
                                                "rowGap": 3,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "center",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "21ba5cd8dd997dbe638ec946dd5b071e85e8b2dc92245e90ccce7cbff507de51",
                                                    "name": "metric-value",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "textAlign": "center",
                                                        "fontWeight": "700",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "ff61869f8e9b8b9efbcc4e558e0f9ef5dc67c78782bc1692bffd1b4f3d427ea6",
                                                            "xtype": "label",
                                                            "properties": {
                                                                "content": "<p>87%</p>",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "4cd93a34c1a81570d2cabf9290bbc530b3c4d76a947da4c9fd90fdbb974c9afa",
                                                    "name": "text-32",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "fontSize": 10.5,
                                                        "textAlign": "center",
                                                        "fontWeight": "400",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "1b28911c1ea7fbacde82d940a5f32f37a36eb6be1a30c488989fde2869f488f9",
                                                            "xtype": "label",
                                                            "properties": {
                                                                "content": "<p>Metric description</p>",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                "scale": "5px"
            },
            {
                "widget": {
                    "id": "ce2c875551571a0bc880c738e839bea22022724b1f15107fc86da6e2d97c96b4",
                    "name": "pricing-comparison",
                    "xtype": "group",
                    "properties": {
                        "columnGap": 60,
                        "rowGap": 60,
                        "justifyContent": "center",
                        "alignItems": "flex-start",
                        "paddingTop": 48,
                        "paddingRight": 18,
                        "paddingBottom": 48,
                        "paddingLeft": 18,
                        "display": "flex",
                    },
                    "children": [
                        {
                            "id": "4d14928d474333cdaecfa06ecb10dbee717876cfadd7eda05407bafc40622dae",
                            "name": "columns-8",
                            "xtype": "group",
                            "properties": {
                                "maxWidth": 720,
                                "justifyContent": "space-between",
                                "alignItems": "center",
                                "display": "flex",
                                "small": {
                                    "flexDirection": "column"
                                }
                            },
                            "children": [
                                {
                                    "id": "eecd9fa93dd0fa2da864f5209ad9c03e629d16b3a472385b0dd65dacfd4e479b",
                                    "name": "column-9",
                                    "xtype": "group",
                                    "properties": {
                                        "maxWidth": 195,
                                        "columnGap": 18,
                                        "rowGap": 18,
                                        "flexDirection": "column",
                                        "justifyContent": "flex-start",
                                        "alignItems": "flex-start",
                                        "paddingTop": 24,
                                        "paddingBottom": 24,
                                        "display": "flex",
                                        "boxShadow": null,
                                    },
                                    "children": [
                                        {
                                            "id": "d12ba93dc5bcc2a491a75e9a7cd73099d8fd5152e2dae1fd1514659524dde21d",
                                            "name": "intro-2",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 12,
                                                "rowGap": 12,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "center",
                                                "paddingBottom": 24,
                                                "paddingLeft": 18,
                                                "paddingRight": 18,
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "1f62e0bcb3b277154d4c2a6969e864e4bfe401e46c1903cba9564e409499f939",
                                                    "name": "image-wrapper-8",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "9b0d23391033f326b6ffa3d2554468ae0640ed142370d72f4d9ecfbbda37c6f2",
                                                            "name": "pricing-1",
                                                            "xtype": "image",
                                                            "properties": {
                                                                "borderTopWidth": 0,
                                                                "borderRightWidth": 0,
                                                                "borderBottomWidth": 0,
                                                                "borderLeftWidth": 0,
                                                                "borderTopStyle": "initial",
                                                                "borderRightStyle": "initial",
                                                                "borderBottomStyle": "initial",
                                                                "borderLeftStyle": "initial",
                                                                "borderTopColor": "initial",
                                                                "borderRightColor": "initial",
                                                                "borderBottomColor": "initial",
                                                                "borderLeftColor": "initial",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "maxWidth": "100%",
                                                                "verticalAlign": "middle",
                                                                "display": "flex",
                                                                "objectFit": "cover",
                                                                "flexDirection": "column",
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f91502933ff86cc6cd257f_Image.png",
                                                                "alt": "",
                                                                "maxHeight": "100%"
                                                            },
                                                            "children": []
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "93fccc040af22a66df3477a1377214de8b063945e00c750c66a9335c4034747c",
                                                    "name": "name-2",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "flexDirection": "column",
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "8d7d600199644146be68cb91996c39101910c67b7edb053a02db6dc5415babde",
                                                            "name": "text-33",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 30,
                                                                "fontWeight": "600",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "d7b6fc152a63b099c196862359a43cab23dd0da209310a890c1d77b2774a4092",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Startup</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "e503840cbf89cf33f2f75954ea25d0bb323f0c7b91b3c4d5f74167a3396e4dbe",
                                                            "name": "text-34",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 10.5,
                                                                "textAlign": "center",
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "1660f5a8aedb2b2b868858877e8bc822d14f38d5f2be16d01d264b075b34e0a9",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Starting at</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "03d88cc3552e87267619d43d58017a8795202bfa83540d68997ebddf71c9cea9",
                                                    "name": "pricing",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 6,
                                                        "rowGap": 6,
                                                        "justifyContent": "center",
                                                        "alignItems": "flex-end",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "d75a2cf861d14cd7c67503441099f707c85f5b7ec8cc5446e5b9a960886fca54",
                                                            "name": "text-35",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 18,
                                                                "textAlign": "center",
                                                                "fontWeight": "700",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "858cc339f7286adc379f8e8d24ecc4af7ff5231e756f30b2e09a6079b155c6dd",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>$599/mo</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "67d42c4b0e63a18c6c92191f18b8c941e243df7899e007423222d1feb7e4f4c4",
                                                    "name": "description-4",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 7.5,
                                                        "rowGap": 7.5,
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "flex-start",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "ee1003e4039e0220887efe5b4f6fb42c679ea44d7c4f1dd4a92241e0aa6b0def",
                                                            "name": "text-36",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 12,
                                                                "textAlign": "center",
                                                                "fontWeight": "500",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "b1bb0ffbafe350d84e0827a8f83ac2b855f594ccc0b8578002b390a75026d273",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Lorem ipsum dolor</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "4b8bd4543560d31e2732130d39890f2378366a209790e3c405af6d6ddb9d84d7",
                                                    "name": "button-2",
                                                    "xtype": "button",
                                                    "properties": {
                                                        "link": {
                                                            "target": "url",
                                                            "url": "#",
                                                            "page": 0
                                                        },
                                                        "borderTopWidth": 0.75,
                                                        "borderRightWidth": 0.75,
                                                        "borderBottomWidth": 0.75,
                                                        "borderLeftWidth": 0.75,
                                                        "borderTopStyle": "solid",
                                                        "borderRightStyle": "solid",
                                                        "borderBottomStyle": "solid",
                                                        "borderLeftStyle": "solid",
                                                        "borderTopColor": "rgb(33, 33, 33)",
                                                        "borderRightColor": "rgb(33, 33, 33)",
                                                        "borderBottomColor": "rgb(33, 33, 33)",
                                                        "borderLeftColor": "rgb(33, 33, 33)",
                                                        "borderImageSource": "initial",
                                                        "borderImageSlice": "initial",
                                                        "borderImageWidth": "initial",
                                                        "borderImageOutset": "initial",
                                                        "borderImageRepeat": "initial",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "paddingTop": 12,
                                                        "paddingRight": 18,
                                                        "paddingBottom": 12,
                                                        "paddingLeft": 18,
                                                        "textDecorationLine": "none",
                                                        "textDecorationThickness": "initial",
                                                        "textDecorationStyle": "initial",
                                                        "textDecorationColor": "initial",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "a9e4e9d836ebfc2adbcd9a04ce81e256eae9779a7139b5d1024b73cbd83ece64",
                                                            "name": "text-37",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 9,
                                                                "letterSpacing": 1.5,
                                                                "fontWeight": "500",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "8cf589b3a8b310bdf063567a6a19e9aee3d455e3dbd5598adb01481f3bf3932f",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>TALK TO AN EXPERT</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "id": "bee1168e12a7a0eefd6ca2420eec7cf02dcc03550b735ac40e07a1f124924c2c",
                                            "name": "features",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 12,
                                                "rowGap": 12,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "center",
                                                "paddingLeft": 18,
                                                "paddingRight": 18,
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "882ec8f4642322244c9c79fe8390643a037de16daee4d94d4c02277db983f22b",
                                                    "name": "pricing-feature",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 6,
                                                        "rowGap": 6,
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "72bc053e281d143f8a7af0454b5e19dda63a78e3065ad9b261b73dbfcf79d154",
                                                            "name": "icon",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "a3b3e8cf978604634a5f06025ba866133ce133f034ffb1c24f8cf57e59bb10c6",
                                                                    "name": "icon-wrapper",
                                                                    "xtype": "image",
                                                                    "properties": {
                                                                        "borderTopWidth": 0,
                                                                        "borderRightWidth": 0,
                                                                        "borderBottomWidth": 0,
                                                                        "borderLeftWidth": 0,
                                                                        "borderTopStyle": "initial",
                                                                        "borderRightStyle": "initial",
                                                                        "borderBottomStyle": "initial",
                                                                        "borderLeftStyle": "initial",
                                                                        "borderTopColor": "initial",
                                                                        "borderRightColor": "initial",
                                                                        "borderBottomColor": "initial",
                                                                        "borderLeftColor": "initial",
                                                                        "borderImageSource": "initial",
                                                                        "borderImageSlice": "initial",
                                                                        "borderImageWidth": "initial",
                                                                        "borderImageOutset": "initial",
                                                                        "borderImageRepeat": "initial",
                                                                        "maxWidth": "100%",
                                                                        "verticalAlign": "middle",
                                                                        "display": "flex",
                                                                        "objectFit": "cover",
                                                                        "justifyContent": "center",
                                                                        "alignItems": "center",
                                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f9151a9b70c5578ab88486_Icon-Wrapper.svg",
                                                                        "alt": "",
                                                                        "maxHeight": "100%"
                                                                    },
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "a186cb5ce32207cd37a419836cfd565f3793644124abaa06e6193a4f0e74a1f1",
                                                            "name": "feature-item",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontWeight": "400",                                                                
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "0f3515a87ae25e8b389b5fac9c73437bd4dc6cd9e101723847fac6f4debb3890",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Lorem ipsum dolor sit amet</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "3fd624d9e1a8a53f29814f83ff7f03abc6ec931cd850bc315fe45c522608d440",
                                                    "name": "pricing-feature",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 6,
                                                        "rowGap": 6,
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "b0881a5038b0bf8bbb4690a5f69e9d4f82a695c9455f01eb35a2b5dc02f18fab",
                                                            "name": "icon",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "00c66c349d3dcb5adcd334cf4cc5987454ca0331997e12d1759082411ff7b635",
                                                                    "name": "icon-wrapper",
                                                                    "xtype": "image",
                                                                    "properties": {
                                                                        "borderTopWidth": 0,
                                                                        "borderRightWidth": 0,
                                                                        "borderBottomWidth": 0,
                                                                        "borderLeftWidth": 0,
                                                                        "borderTopStyle": "initial",
                                                                        "borderRightStyle": "initial",
                                                                        "borderBottomStyle": "initial",
                                                                        "borderLeftStyle": "initial",
                                                                        "borderTopColor": "initial",
                                                                        "borderRightColor": "initial",
                                                                        "borderBottomColor": "initial",
                                                                        "borderLeftColor": "initial",
                                                                        "borderImageSource": "initial",
                                                                        "borderImageSlice": "initial",
                                                                        "borderImageWidth": "initial",
                                                                        "borderImageOutset": "initial",
                                                                        "borderImageRepeat": "initial",
                                                                        "maxWidth": "100%",
                                                                        "verticalAlign": "middle",
                                                                        "display": "flex",
                                                                        "objectFit": "cover",
                                                                        "justifyContent": "center",
                                                                        "alignItems": "center",
                                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f9151a9b70c5578ab88486_Icon-Wrapper.svg",
                                                                        "alt": "",
                                                                        "maxHeight": "100%"
                                                                    },
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "a711ff13b9b6e62bd19085b94565da5e92bd2bbc6367dcce86f4da9944f72cbd",
                                                            "name": "feature-item",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "98478aef31c54114b884bc90bf71495fc2bf85c36615f3d45288f9a7d77916b4",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Lorem ipsum dolor sit amet</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "901093e0191a4625ae7616cf630a5a16b96a5615772979ee6048a9027ecfc884",
                                                    "name": "pricing-feature",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 6,
                                                        "rowGap": 6,
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "763db33add2cb7e3fa547e0a97d2422d96898fd8fd19671b7c98fd0992eeb510",
                                                            "name": "icon",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "99a6f2fda36ca92d2d2d801cfcee15bb6760d326067de2f3b2f8ccde574dd42f",
                                                                    "name": "icon-wrapper",
                                                                    "xtype": "image",
                                                                    "properties": {
                                                                        "borderTopWidth": 0,
                                                                        "borderRightWidth": 0,
                                                                        "borderBottomWidth": 0,
                                                                        "borderLeftWidth": 0,
                                                                        "borderTopStyle": "initial",
                                                                        "borderRightStyle": "initial",
                                                                        "borderBottomStyle": "initial",
                                                                        "borderLeftStyle": "initial",
                                                                        "borderTopColor": "initial",
                                                                        "borderRightColor": "initial",
                                                                        "borderBottomColor": "initial",
                                                                        "borderLeftColor": "initial",
                                                                        "borderImageSource": "initial",
                                                                        "borderImageSlice": "initial",
                                                                        "borderImageWidth": "initial",
                                                                        "borderImageOutset": "initial",
                                                                        "borderImageRepeat": "initial",
                                                                        "maxWidth": "100%",
                                                                        "verticalAlign": "middle",
                                                                        "display": "flex",
                                                                        "objectFit": "cover",
                                                                        "justifyContent": "center",
                                                                        "alignItems": "center",
                                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f9151a9b70c5578ab88486_Icon-Wrapper.svg",
                                                                        "alt": "",
                                                                        "maxHeight": "100%"
                                                                    },
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "47cc4163e38eeac85c1e2c29cbd68982bc8946c7934b63565c676eb338379efc",
                                                            "name": "feature-item",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "51ba2d2628d031e923d0a4e88772d340640ab95216febceeae2ebd1f0a799625",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Lorem ipsum dolor sit amet</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "c7ba4329b0df6c150a93a79638c0c0838e3be80d3277e2c390fb79b9b5a32ac7",
                                                    "name": "pricing-feature",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 6,
                                                        "rowGap": 6,
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "d9a47c34d17c8d9fd01d6320121fae23c05309d649719aeb216c2293cf5260c2",
                                                            "name": "icon",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "fd529328e91462c252557af923fb7ccbfd50ed723657c9395a3cad1af2b75581",
                                                                    "name": "icon-wrapper",
                                                                    "xtype": "image",
                                                                    "properties": {
                                                                        "borderTopWidth": 0,
                                                                        "borderRightWidth": 0,
                                                                        "borderBottomWidth": 0,
                                                                        "borderLeftWidth": 0,
                                                                        "borderTopStyle": "initial",
                                                                        "borderRightStyle": "initial",
                                                                        "borderBottomStyle": "initial",
                                                                        "borderLeftStyle": "initial",
                                                                        "borderTopColor": "initial",
                                                                        "borderRightColor": "initial",
                                                                        "borderBottomColor": "initial",
                                                                        "borderLeftColor": "initial",
                                                                        "borderImageSource": "initial",
                                                                        "borderImageSlice": "initial",
                                                                        "borderImageWidth": "initial",
                                                                        "borderImageOutset": "initial",
                                                                        "borderImageRepeat": "initial",
                                                                        "maxWidth": "100%",
                                                                        "verticalAlign": "middle",
                                                                        "display": "flex",
                                                                        "objectFit": "cover",
                                                                        "justifyContent": "center",
                                                                        "alignItems": "center",
                                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f9151a9b70c5578ab88486_Icon-Wrapper.svg",
                                                                        "alt": "",
                                                                        "maxHeight": "100%"
                                                                    },
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "7c9999b37b2b1f1e2ffac7f24f434f54ff62a5d24e1111928d0002edc71f8889",
                                                            "name": "feature-item",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "2b3f8d38d7a8a631cbdc8fd5c4b4dae72d2c0555a0acc06e4826b1cd2ddde15c",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Lorem ipsum dolor sit amet</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "79f0861093907a0cb3c0e37919d6a093c48d6ede144024c704cc9a084509c094",
                                                    "name": "pricing-feature",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 6,
                                                        "rowGap": 6,
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "7e20e6b7792e89174f65054c36ac81d366840c0cf47dbe0b8301d3cf43c9ee93",
                                                            "name": "icon",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "63dc44bcd04ce9703db48b32a3b4aa22627722e6cd4ca22501920052a497fe58",
                                                                    "name": "icon-wrapper",
                                                                    "xtype": "image",
                                                                    "properties": {
                                                                        "borderTopWidth": 0,
                                                                        "borderRightWidth": 0,
                                                                        "borderBottomWidth": 0,
                                                                        "borderLeftWidth": 0,
                                                                        "borderTopStyle": "initial",
                                                                        "borderRightStyle": "initial",
                                                                        "borderBottomStyle": "initial",
                                                                        "borderLeftStyle": "initial",
                                                                        "borderTopColor": "initial",
                                                                        "borderRightColor": "initial",
                                                                        "borderBottomColor": "initial",
                                                                        "borderLeftColor": "initial",
                                                                        "borderImageSource": "initial",
                                                                        "borderImageSlice": "initial",
                                                                        "borderImageWidth": "initial",
                                                                        "borderImageOutset": "initial",
                                                                        "borderImageRepeat": "initial",
                                                                        "maxWidth": "100%",
                                                                        "verticalAlign": "middle",
                                                                        "display": "flex",
                                                                        "objectFit": "cover",
                                                                        "justifyContent": "center",
                                                                        "alignItems": "center",
                                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f9151a9b70c5578ab88486_Icon-Wrapper.svg",
                                                                        "alt": "",
                                                                        "maxHeight": "100%"
                                                                    },
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "bbd29b02939f516e22278f3bfca0482822abc775e81c63727b3bbf6162c6c5b1",
                                                            "name": "feature-item",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "ac55b9a71887d5538202c49fb51af05f9285f5439fa7a98cdd51b57ece00a5be",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Lorem ipsum dolor sit amet</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "64256b567c6ec3f5afea79d84909c16f686584cef8af240c1e28fc5b0988060a",
                                                    "name": "pricing-feature",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 6,
                                                        "rowGap": 6,
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "22f7c2e57f362b3a6dd4984e018b2b8af41f84459b92a610d1e964e492e096b9",
                                                            "name": "icon",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "af0718d6f99aa1a9544c2220fe8cd115384a677e179b4380ff66f0588ae4dc0e",
                                                                    "name": "icon-wrapper",
                                                                    "xtype": "image",
                                                                    "properties": {
                                                                        "borderTopWidth": 0,
                                                                        "borderRightWidth": 0,
                                                                        "borderBottomWidth": 0,
                                                                        "borderLeftWidth": 0,
                                                                        "borderTopStyle": "initial",
                                                                        "borderRightStyle": "initial",
                                                                        "borderBottomStyle": "initial",
                                                                        "borderLeftStyle": "initial",
                                                                        "borderTopColor": "initial",
                                                                        "borderRightColor": "initial",
                                                                        "borderBottomColor": "initial",
                                                                        "borderLeftColor": "initial",
                                                                        "borderImageSource": "initial",
                                                                        "borderImageSlice": "initial",
                                                                        "borderImageWidth": "initial",
                                                                        "borderImageOutset": "initial",
                                                                        "borderImageRepeat": "initial",
                                                                        "maxWidth": "100%",
                                                                        "verticalAlign": "middle",
                                                                        "display": "flex",
                                                                        "objectFit": "cover",
                                                                        "justifyContent": "center",
                                                                        "alignItems": "center",
                                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f9151a9b70c5578ab88486_Icon-Wrapper.svg",
                                                                        "alt": "",
                                                                        "maxHeight": "100%"
                                                                    },
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "4df2d677fb16a5f018fb1f65d9fd9d78944f99e053472760697bf2488c9c7eff",
                                                            "name": "feature-item",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "0cc65a93be2aa90890073b4fdb79d57c51d11bd957dffe1598b8f63625a3493f",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Lorem ipsum dolor sit amet</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "id": "7fd40c019e6d193ad1f74f425e6a806718e615cf135173200a52a82814215ea8",
                                    "name": "column-10",
                                    "xtype": "group",
                                    "properties": {
                                        "maxWidth": 195,
                                        "columnGap": 18,
                                        "rowGap": 18,
                                        "flexDirection": "column",
                                        "justifyContent": "flex-start",
                                        "alignItems": "flex-start",
                                        "paddingTop": 24,
                                        "paddingBottom": 24,
                                        "display": "flex",
                                        "boxShadow": null,
                                    },
                                    "children": [
                                        {
                                            "id": "eb3ea2037013d202dae275c29ee27177b985cdd367c71f637c0381ba4965870c",
                                            "name": "intro-2",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 12,
                                                "rowGap": 12,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "center",
                                                "paddingBottom": 24,
                                                "paddingLeft": 18,
                                                "paddingRight": 18,
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "abb05b0e05b0c26c752bba68ea6b843983385b8ee944993bf979b6ef37b6f2ed",
                                                    "name": "image-wrapper-8",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "759f573cc36db45c97a3ce2be1ababc25e2b55ea41fb0c81c4886a4428752ab9",
                                                            "name": "pricing-1",
                                                            "xtype": "image",
                                                            "properties": {
                                                                "borderTopWidth": 0,
                                                                "borderRightWidth": 0,
                                                                "borderBottomWidth": 0,
                                                                "borderLeftWidth": 0,
                                                                "borderTopStyle": "initial",
                                                                "borderRightStyle": "initial",
                                                                "borderBottomStyle": "initial",
                                                                "borderLeftStyle": "initial",
                                                                "borderTopColor": "initial",
                                                                "borderRightColor": "initial",
                                                                "borderBottomColor": "initial",
                                                                "borderLeftColor": "initial",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "maxWidth": "100%",
                                                                "verticalAlign": "middle",
                                                                "display": "flex",
                                                                "objectFit": "cover",
                                                                "flexDirection": "column",
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f9151b02a1a3793287bfdb_pricing-2.png",
                                                                "alt": "",
                                                                "maxHeight": "100%"
                                                            },
                                                            "children": []
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "6e61f098df836a7f52fe50833d8eef2ffc764ac259f852d724c7cd3811e1949b",
                                                    "name": "name-2",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "flexDirection": "column",
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "5fe8a3f44843e0b51ddbf9ea9ad594155df90470a10d08773caedc10f58d1f50",
                                                            "name": "text-33",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 30,
                                                                "fontWeight": "600",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "1820fba7e152458ba71e94da875de3f18fc47b94967f8fc6d159165e13595142",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Growth</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "93e024996d635c76403417f5f594dc5d75b2e04ac09c24ec6e09b4b999d9532c",
                                                            "name": "text-34",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 10.5,
                                                                "textAlign": "center",
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "eb4eae8e22205f781c320b8ade40afbd84a8a4e4c84c6663997b604d2b79dbe0",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Starting at</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "b15afcaa077383c19997b42f33a501c9131890071ee7b5a613c40fb8525714e4",
                                                    "name": "pricing",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 6,
                                                        "rowGap": 6,
                                                        "justifyContent": "center",
                                                        "alignItems": "flex-end",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "556f6b86a370cfe211ccd14a00165334272a57a6db352fa7e6dbfb6963a67398",
                                                            "name": "text-35",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 18,
                                                                "textAlign": "center",
                                                                "fontWeight": "700",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "a7b85cfd4f6887f5dc60977539ceaf3e49f7841aaf019ff8828cdacf6edf8d86",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>$1,499/mo</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "0d8c50ebc9c17941dfee4813d745786bbac3d890616f16c095415db4e7891946",
                                                    "name": "description-4",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 7.5,
                                                        "rowGap": 7.5,
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "flex-start",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "a4c0bda805bdfb6b3f7d7044129b109db21a031adb98f79f2a7ba75b74cb064e",
                                                            "name": "text-36",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 12,
                                                                "textAlign": "center",
                                                                "fontWeight": "500",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "01bc8ba70a71640f8f6e8524333632000b79d9a5f9b09fcdfbdeb6701a7be97a",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Lorem ipsum dolor</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "2c9a6d63f6b303ad649fd7e63511f1f0b8513c5aa1071cb8cbe0bbe8881584f6",
                                                    "name": "button-3",
                                                    "xtype": "button",
                                                    "properties": {
                                                        "link": {
                                                            "target": "url",
                                                            "url": "#",
                                                            "page": 0
                                                        },
                                                        "borderTopWidth": 0.75,
                                                        "borderRightWidth": 0.75,
                                                        "borderBottomWidth": 0.75,
                                                        "borderLeftWidth": 0.75,
                                                        "borderTopStyle": "solid",
                                                        "borderRightStyle": "solid",
                                                        "borderBottomStyle": "solid",
                                                        "borderLeftStyle": "solid",
                                                        "borderTopColor": "rgb(33, 33, 33)",
                                                        "borderRightColor": "rgb(33, 33, 33)",
                                                        "borderBottomColor": "rgb(33, 33, 33)",
                                                        "borderLeftColor": "rgb(33, 33, 33)",
                                                        "borderImageSource": "initial",
                                                        "borderImageSlice": "initial",
                                                        "borderImageWidth": "initial",
                                                        "borderImageOutset": "initial",
                                                        "borderImageRepeat": "initial",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "paddingTop": 12,
                                                        "paddingRight": 18,
                                                        "paddingBottom": 12,
                                                        "paddingLeft": 18,
                                                        "textDecorationLine": "none",
                                                        "textDecorationThickness": "initial",
                                                        "textDecorationStyle": "initial",
                                                        "textDecorationColor": "initial",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "7a2b12d9a860528354342001c9220b8dec95eb82bc004b55e701995382ff8222",
                                                            "name": "text-38",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 9,
                                                                "letterSpacing": 1.5,
                                                                "fontWeight": "500",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "16aebaa3e5bc20d307a133022b6c4f64c7f395636d673f550bcfea9b85629008",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>TALK TO AN EXPERT</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "id": "3c297a00a2926dbce761ef8024e7c8d3563f51db86a4ca9aeabfb3e7ab36fd53",
                                            "name": "features-2",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 12,
                                                "rowGap": 12,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "center",
                                                "paddingLeft": 12,
                                                "paddingRight": 12,
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "eacbd0c473710bb3061e25a346a81727aa28c4c401d6a762213857dff098aea7",
                                                    "name": "pricing-feature-2",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 6,
                                                        "rowGap": 6,
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "flex-start",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "3891fc0c5a61cf1e655dc28a8527849a62a9c8e56d4167e866964f6c94f0d5e9",
                                                            "name": "icon",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "8e247b6729de4e2f47ba91e0788c05477f680a249e32ba3e44a218e3e5f7af67",
                                                                    "name": "icon-wrapper-2",
                                                                    "xtype": "image",
                                                                    "properties": {
                                                                        "borderTopWidth": 0,
                                                                        "borderRightWidth": 0,
                                                                        "borderBottomWidth": 0,
                                                                        "borderLeftWidth": 0,
                                                                        "borderTopStyle": "initial",
                                                                        "borderRightStyle": "initial",
                                                                        "borderBottomStyle": "initial",
                                                                        "borderLeftStyle": "initial",
                                                                        "borderTopColor": "initial",
                                                                        "borderRightColor": "initial",
                                                                        "borderBottomColor": "initial",
                                                                        "borderLeftColor": "initial",
                                                                        "borderImageSource": "initial",
                                                                        "borderImageSlice": "initial",
                                                                        "borderImageWidth": "initial",
                                                                        "borderImageOutset": "initial",
                                                                        "borderImageRepeat": "initial",
                                                                        "maxWidth": "100%",
                                                                        "verticalAlign": "middle",
                                                                        "display": "flex",
                                                                        "columnGap": 7.5,
                                                                        "rowGap": 7.5,
                                                                        "objectFit": "cover",
                                                                        "justifyContent": "center",
                                                                        "alignItems": "center",
                                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f9151a9b70c5578ab88486_Icon-Wrapper.svg",
                                                                        "alt": "",
                                                                        "maxHeight": "100%"
                                                                    },
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "a16b22bd909a205cc80969647bb6c9c5250585808e3c1e6d3c824b2550ffcd94",
                                                            "name": "feature-item",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "48e12aa56664f083c8c2e52df06202eaddf4f16a7206507daa46feb33363c10f",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Etiam sodales ac felis id interdum</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "68c13b94d40b671e849297321a834741b8c524de24afc810bfedc03e2cee7147",
                                                    "name": "pricing-feature-2",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 6,
                                                        "rowGap": 6,
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "flex-start",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "6f9bed5e7edcd388d209142cc537ec46d17d8f6851648273912aab5cfcc03cfe",
                                                            "name": "icon",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "dc22e712f7bf4673810c6011cc5a2d425b1a3a3bcd7703d58c8aace39faeffd6",
                                                                    "name": "icon-wrapper-2",
                                                                    "xtype": "image",
                                                                    "properties": {
                                                                        "borderTopWidth": 0,
                                                                        "borderRightWidth": 0,
                                                                        "borderBottomWidth": 0,
                                                                        "borderLeftWidth": 0,
                                                                        "borderTopStyle": "initial",
                                                                        "borderRightStyle": "initial",
                                                                        "borderBottomStyle": "initial",
                                                                        "borderLeftStyle": "initial",
                                                                        "borderTopColor": "initial",
                                                                        "borderRightColor": "initial",
                                                                        "borderBottomColor": "initial",
                                                                        "borderLeftColor": "initial",
                                                                        "borderImageSource": "initial",
                                                                        "borderImageSlice": "initial",
                                                                        "borderImageWidth": "initial",
                                                                        "borderImageOutset": "initial",
                                                                        "borderImageRepeat": "initial",
                                                                        "maxWidth": "100%",
                                                                        "verticalAlign": "middle",
                                                                        "display": "flex",
                                                                        "columnGap": 7.5,
                                                                        "rowGap": 7.5,
                                                                        "objectFit": "cover",
                                                                        "justifyContent": "center",
                                                                        "alignItems": "center",
                                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f9151a9b70c5578ab88486_Icon-Wrapper.svg",
                                                                        "alt": "",
                                                                        "maxHeight": "100%"
                                                                    },
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "c19b1e23bd075658475f5da3f4651bbf8b3ee5e15a5516e993e2fb5eacfa8014",
                                                            "name": "feature-item",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "2fd624972961d2e675125475cde3347a0f08cee95c9f4ac9fa57d9296bd29a76",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Etiam sodales ac felis id interdum</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "4cf503c02d3bb5c08c52fe48df42beffe41a87a3a03a80e4da4195b468dfe036",
                                                    "name": "pricing-feature-2",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 6,
                                                        "rowGap": 6,
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "flex-start",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "dd96ee0f6e0c0c234b32ff82fbe64d75d6496c54b91360e85233bd5b76360eb2",
                                                            "name": "icon",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "b3057a58677b0a8190fdbd453d1d5700b72474e2d3bb81482c8ba97cab53b75d",
                                                                    "name": "icon-wrapper-2",
                                                                    "xtype": "image",
                                                                    "properties": {
                                                                        "borderTopWidth": 0,
                                                                        "borderRightWidth": 0,
                                                                        "borderBottomWidth": 0,
                                                                        "borderLeftWidth": 0,
                                                                        "borderTopStyle": "initial",
                                                                        "borderRightStyle": "initial",
                                                                        "borderBottomStyle": "initial",
                                                                        "borderLeftStyle": "initial",
                                                                        "borderTopColor": "initial",
                                                                        "borderRightColor": "initial",
                                                                        "borderBottomColor": "initial",
                                                                        "borderLeftColor": "initial",
                                                                        "borderImageSource": "initial",
                                                                        "borderImageSlice": "initial",
                                                                        "borderImageWidth": "initial",
                                                                        "borderImageOutset": "initial",
                                                                        "borderImageRepeat": "initial",
                                                                        "maxWidth": "100%",
                                                                        "verticalAlign": "middle",
                                                                        "display": "flex",
                                                                        "columnGap": 7.5,
                                                                        "rowGap": 7.5,
                                                                        "objectFit": "cover",
                                                                        "justifyContent": "center",
                                                                        "alignItems": "center",
                                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f9151a9b70c5578ab88486_Icon-Wrapper.svg",
                                                                        "alt": "",
                                                                        "maxHeight": "100%"
                                                                    },
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "23d745af61043d2288cab1dfb89c01ba316e652c8c997d97da4aae83b6109837",
                                                            "name": "feature-item",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "bd8588ac8965adbcb5deaf06be426f042b02b414397304f3f82b79644c168837",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Etiam sodales ac felis id interdum</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "627ee8055ec80440a49222ab8426a99456541198620ebfee4770a4e8a90aa3db",
                                                    "name": "pricing-feature-2",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 6,
                                                        "rowGap": 6,
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "flex-start",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "69d2e7c31cdbbad363fef1d8cd639b1f6fb24cd19a8770318fef307ef762ca39",
                                                            "name": "icon",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "e2f2ef1f8c116955a180f5e57ec47852b4033668bc3c0a34ffe4df2074f7a10c",
                                                                    "name": "icon-wrapper-2",
                                                                    "xtype": "image",
                                                                    "properties": {
                                                                        "borderTopWidth": 0,
                                                                        "borderRightWidth": 0,
                                                                        "borderBottomWidth": 0,
                                                                        "borderLeftWidth": 0,
                                                                        "borderTopStyle": "initial",
                                                                        "borderRightStyle": "initial",
                                                                        "borderBottomStyle": "initial",
                                                                        "borderLeftStyle": "initial",
                                                                        "borderTopColor": "initial",
                                                                        "borderRightColor": "initial",
                                                                        "borderBottomColor": "initial",
                                                                        "borderLeftColor": "initial",
                                                                        "borderImageSource": "initial",
                                                                        "borderImageSlice": "initial",
                                                                        "borderImageWidth": "initial",
                                                                        "borderImageOutset": "initial",
                                                                        "borderImageRepeat": "initial",
                                                                        "maxWidth": "100%",
                                                                        "verticalAlign": "middle",
                                                                        "display": "flex",
                                                                        "columnGap": 7.5,
                                                                        "rowGap": 7.5,
                                                                        "objectFit": "cover",
                                                                        "justifyContent": "center",
                                                                        "alignItems": "center",
                                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f9151a9b70c5578ab88486_Icon-Wrapper.svg",
                                                                        "alt": "",
                                                                        "maxHeight": "100%"
                                                                    },
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "38cfdfad433970708d1498b63cfa5a73257dc8c67921e4584a8948d32735993e",
                                                            "name": "feature-item",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "211fcb68f18b40a7b7a555e088d7f4f20985d2711a11a9c45c30ac0d44dfb0bf",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Etiam sodales ac felis id interdum</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "60fbf7890611fcd5e62ee04f82bce748486262849126e159e1a47af22dd931e2",
                                                    "name": "pricing-feature-2",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 6,
                                                        "rowGap": 6,
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "flex-start",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "d0ae33c1da4a1959510b96cd80f80d9042c806db2faa8e70ffaecc7576949c02",
                                                            "name": "icon",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "b6ceaafc4a0c827366fce94326aae343a8fd31318d9f84a7834e4efb89d05b60",
                                                                    "name": "icon-wrapper-2",
                                                                    "xtype": "image",
                                                                    "properties": {
                                                                        "borderTopWidth": 0,
                                                                        "borderRightWidth": 0,
                                                                        "borderBottomWidth": 0,
                                                                        "borderLeftWidth": 0,
                                                                        "borderTopStyle": "initial",
                                                                        "borderRightStyle": "initial",
                                                                        "borderBottomStyle": "initial",
                                                                        "borderLeftStyle": "initial",
                                                                        "borderTopColor": "initial",
                                                                        "borderRightColor": "initial",
                                                                        "borderBottomColor": "initial",
                                                                        "borderLeftColor": "initial",
                                                                        "borderImageSource": "initial",
                                                                        "borderImageSlice": "initial",
                                                                        "borderImageWidth": "initial",
                                                                        "borderImageOutset": "initial",
                                                                        "borderImageRepeat": "initial",
                                                                        "maxWidth": "100%",
                                                                        "verticalAlign": "middle",
                                                                        "display": "flex",
                                                                        "columnGap": 7.5,
                                                                        "rowGap": 7.5,
                                                                        "objectFit": "cover",
                                                                        "justifyContent": "center",
                                                                        "alignItems": "center",
                                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f9151a9b70c5578ab88486_Icon-Wrapper.svg",
                                                                        "alt": "",
                                                                        "maxHeight": "100%"
                                                                    },
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "1c5b4708a0d85336dba388b0f8e1da7033f41f50f77dd62e1aed593b55955218",
                                                            "name": "feature-item",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "7ab232d8eadba8ef76470cf4b23bbc44fa6102614dfa563b74ede6bd18de599b",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Etiam sodales ac felis id interdum</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "5d40aeb9a7728d5a3883c45a38cba094826a7ac3d89f211157802c7df131d2f4",
                                                    "name": "pricing-feature-2",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 6,
                                                        "rowGap": 6,
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "flex-start",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "847cbee4561ba6374e50ba340c2001f48a826df9f3dc8fc1a76a6cc03827fad0",
                                                            "name": "icon",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "dff6b8add37790ecea5274bed04dbd695361ed7708d31ee1431ff2eecf958e22",
                                                                    "name": "icon-wrapper-2",
                                                                    "xtype": "image",
                                                                    "properties": {
                                                                        "borderTopWidth": 0,
                                                                        "borderRightWidth": 0,
                                                                        "borderBottomWidth": 0,
                                                                        "borderLeftWidth": 0,
                                                                        "borderTopStyle": "initial",
                                                                        "borderRightStyle": "initial",
                                                                        "borderBottomStyle": "initial",
                                                                        "borderLeftStyle": "initial",
                                                                        "borderTopColor": "initial",
                                                                        "borderRightColor": "initial",
                                                                        "borderBottomColor": "initial",
                                                                        "borderLeftColor": "initial",
                                                                        "borderImageSource": "initial",
                                                                        "borderImageSlice": "initial",
                                                                        "borderImageWidth": "initial",
                                                                        "borderImageOutset": "initial",
                                                                        "borderImageRepeat": "initial",
                                                                        "maxWidth": "100%",
                                                                        "verticalAlign": "middle",
                                                                        "display": "flex",
                                                                        "columnGap": 7.5,
                                                                        "rowGap": 7.5,
                                                                        "objectFit": "cover",
                                                                        "justifyContent": "center",
                                                                        "alignItems": "center",
                                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f9151a9b70c5578ab88486_Icon-Wrapper.svg",
                                                                        "alt": "",
                                                                        "maxHeight": "100%"
                                                                    },
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "270ecfc3f923c231b9ecd78d14ff033b902c41cf3977772843f6a8f33b144502",
                                                            "name": "feature-item",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "e4373ac63d742a793b119ce0ac16217fdd81cfa3206ee63a3faa050d0dd9da07",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Etiam sodales ac felis id interdum</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "id": "165917e8234fd98fbd9c18e36b726560b3e13c07ab84635d16162c90bd8abb23",
                                    "name": "column-9",
                                    "xtype": "group",
                                    "properties": {
                                        "maxWidth": 195,
                                        "columnGap": 18,
                                        "rowGap": 18,
                                        "flexDirection": "column",
                                        "justifyContent": "flex-start",
                                        "alignItems": "flex-start",
                                        "paddingTop": 24,
                                        "paddingBottom": 24,
                                        "display": "flex",
                                        "boxShadow": null,
                                    },
                                    "children": [
                                        {
                                            "id": "31c573787795d92bd058c36ff6ac1e537ca435c9be35fb756814ae9410cb82b5",
                                            "name": "intro-2",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 12,
                                                "rowGap": 12,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "center",
                                                "paddingBottom": 24,
                                                "paddingLeft": 18,
                                                "paddingRight": 18,
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "6135dd55a1c8ea230e9470e0fe0007cb0592952ef92d2edfe92c62096e7d5068",
                                                    "name": "image-wrapper-8",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "24a80c796f0944553779d012da63b89e3fa85dea0728a43c6eec54907ad08f05",
                                                            "name": "pricing-1",
                                                            "xtype": "image",
                                                            "properties": {
                                                                "borderTopWidth": 0,
                                                                "borderRightWidth": 0,
                                                                "borderBottomWidth": 0,
                                                                "borderLeftWidth": 0,
                                                                "borderTopStyle": "initial",
                                                                "borderRightStyle": "initial",
                                                                "borderBottomStyle": "initial",
                                                                "borderLeftStyle": "initial",
                                                                "borderTopColor": "initial",
                                                                "borderRightColor": "initial",
                                                                "borderBottomColor": "initial",
                                                                "borderLeftColor": "initial",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "maxWidth": "100%",
                                                                "verticalAlign": "middle",
                                                                "display": "flex",
                                                                "objectFit": "cover",
                                                                "flexDirection": "column",
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f91502933ff86cc6cd257f_Image.png",
                                                                "alt": "",
                                                                "maxHeight": "100%"
                                                            },
                                                            "children": []
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "06a7f63f40e6cc2047428595104b84d1e08d3e213d3b146be23e0cf56a692ddc",
                                                    "name": "name-2",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "flexDirection": "column",
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "4ae71101d2b37f397852dd6b42940aaa77e20f7f9f0c8280e4fe1b673d8c3206",
                                                            "name": "text-33",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 30,
                                                                "fontWeight": "600",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "2da99b50ff3bea1704564802db7e6d5638a4492b617f9ee5a1e9dcb1a9b99a29",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Enterprise</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "d5b19d05b3cdee02e05313b9d129922e6e16ef5772c5d5283458e982febf5789",
                                                            "name": "text-34",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 10.5,
                                                                "textAlign": "center",
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "f508c199cebbf6e20e2e88f2294619dac0f5b5137f4a99697b47efef90218363",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Starting at</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "dc19b57b8483957d813ab76a77d029a7e2362cec24337cb59239322e1ea851f4",
                                                    "name": "pricing",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 6,
                                                        "rowGap": 6,
                                                        "justifyContent": "center",
                                                        "alignItems": "flex-end",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "69e84349dc39bca3f9bf120efaac4e52a369761d3c3a726137583025be9ca145",
                                                            "name": "text-35",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 18,
                                                                "textAlign": "center",
                                                                "fontWeight": "700",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "1d03afe1dbec52fdb868cc92ffa723698cf1ad79e175361054ceae8fddf71489",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>$2,499/mo</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "498897f39784dc5eef6111bb3963ef0620064ce8135cc66bd1e63ce815016a1f",
                                                    "name": "description-4",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 7.5,
                                                        "rowGap": 7.5,
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "flex-start",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "a26600a270a167aaa38162765773409e25bcd3d075944df5a8448b3f3bc2d1a7",
                                                            "name": "text-36",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 12,
                                                                "textAlign": "center",
                                                                "fontWeight": "500",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "d68167a0286a47928a80633b00e468281af20085d064e2ddc3f8e18f17ad695a",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Lorem ipsum dolor</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "0fa16385c489af1557867f36adedcd0e1adc49ab5fad0ca4a258a2ee3886b016",
                                                    "name": "button-2",
                                                    "xtype": "button",
                                                    "properties": {
                                                        "link": {
                                                            "target": "url",
                                                            "url": "#",
                                                            "page": 0
                                                        },
                                                        "borderTopWidth": 0.75,
                                                        "borderRightWidth": 0.75,
                                                        "borderBottomWidth": 0.75,
                                                        "borderLeftWidth": 0.75,
                                                        "borderTopStyle": "solid",
                                                        "borderRightStyle": "solid",
                                                        "borderBottomStyle": "solid",
                                                        "borderLeftStyle": "solid",
                                                        "borderTopColor": "rgb(33, 33, 33)",
                                                        "borderRightColor": "rgb(33, 33, 33)",
                                                        "borderBottomColor": "rgb(33, 33, 33)",
                                                        "borderLeftColor": "rgb(33, 33, 33)",
                                                        "borderImageSource": "initial",
                                                        "borderImageSlice": "initial",
                                                        "borderImageWidth": "initial",
                                                        "borderImageOutset": "initial",
                                                        "borderImageRepeat": "initial",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "paddingTop": 12,
                                                        "paddingRight": 18,
                                                        "paddingBottom": 12,
                                                        "paddingLeft": 18,
                                                        "textDecorationLine": "none",
                                                        "textDecorationThickness": "initial",
                                                        "textDecorationStyle": "initial",
                                                        "textDecorationColor": "initial",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "401c8e34a3d894aeb78dc216577523fb9260fcaa33adcf40eac2bab5848ef0d9",
                                                            "name": "text-37",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 9,
                                                                "letterSpacing": 1.5,
                                                                "fontWeight": "500",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "9b22fc9be5d793ccd1f645e2366c871709ea8a0e4d4062c7535d9b3dd00738a7",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>TALK TO AN EXPERT</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "id": "0599abc45df8fa52b113eb2bf73ec7e94a53912c4b939a70c1e2982022d0c036",
                                            "name": "features",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 12,
                                                "rowGap": 12,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "center",
                                                "paddingLeft": 18,
                                                "paddingRight": 18,
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "4a3f9dd336ce6fb50b9142295f94a812991e8213255c1350477db892d92a2165",
                                                    "name": "pricing-feature",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 6,
                                                        "rowGap": 6,
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "002834e71d2afe83d49c1afe7b00556d7f6b8b681fb9ee52c6bf499ee212a7bb",
                                                            "name": "icon",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "2d5cd1ca73127bd0223ed7e19a6ac0385b731d73c0336cd0a6081c39b8603449",
                                                                    "name": "icon-wrapper",
                                                                    "xtype": "image",
                                                                    "properties": {
                                                                        "borderTopWidth": 0,
                                                                        "borderRightWidth": 0,
                                                                        "borderBottomWidth": 0,
                                                                        "borderLeftWidth": 0,
                                                                        "borderTopStyle": "initial",
                                                                        "borderRightStyle": "initial",
                                                                        "borderBottomStyle": "initial",
                                                                        "borderLeftStyle": "initial",
                                                                        "borderTopColor": "initial",
                                                                        "borderRightColor": "initial",
                                                                        "borderBottomColor": "initial",
                                                                        "borderLeftColor": "initial",
                                                                        "borderImageSource": "initial",
                                                                        "borderImageSlice": "initial",
                                                                        "borderImageWidth": "initial",
                                                                        "borderImageOutset": "initial",
                                                                        "borderImageRepeat": "initial",
                                                                        "maxWidth": "100%",
                                                                        "verticalAlign": "middle",
                                                                        "display": "flex",
                                                                        "objectFit": "cover",
                                                                        "justifyContent": "center",
                                                                        "alignItems": "center",
                                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f9151a9b70c5578ab88486_Icon-Wrapper.svg",
                                                                        "alt": "",
                                                                        "maxHeight": "100%"
                                                                    },
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "837826cd56789687536fbcdac537698b9e8efed74641b29f4f94e229836673cd",
                                                            "name": "feature-item",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "aa6c61be0f4258927b5cf15aa70ffd5ddc231c4032502a5f1b1d5698551496df",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Lorem ipsum dolor sit amet</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "365adf1fd8dc0e32649124acce30707d40fea3baf09c70bc1e49f729de250d6c",
                                                    "name": "pricing-feature",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 6,
                                                        "rowGap": 6,
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "2683df6a0f544478af001b1d68da1e4896cc31a3ab0a686228f59a7d45fa996f",
                                                            "name": "icon",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "640d2afcd920d5a436425f46c1a7a74d130ba88bd712d3c4e2fb3d8375d72acd",
                                                                    "name": "icon-wrapper",
                                                                    "xtype": "image",
                                                                    "properties": {
                                                                        "borderTopWidth": 0,
                                                                        "borderRightWidth": 0,
                                                                        "borderBottomWidth": 0,
                                                                        "borderLeftWidth": 0,
                                                                        "borderTopStyle": "initial",
                                                                        "borderRightStyle": "initial",
                                                                        "borderBottomStyle": "initial",
                                                                        "borderLeftStyle": "initial",
                                                                        "borderTopColor": "initial",
                                                                        "borderRightColor": "initial",
                                                                        "borderBottomColor": "initial",
                                                                        "borderLeftColor": "initial",
                                                                        "borderImageSource": "initial",
                                                                        "borderImageSlice": "initial",
                                                                        "borderImageWidth": "initial",
                                                                        "borderImageOutset": "initial",
                                                                        "borderImageRepeat": "initial",
                                                                        "maxWidth": "100%",
                                                                        "verticalAlign": "middle",
                                                                        "display": "flex",
                                                                        "objectFit": "cover",
                                                                        "justifyContent": "center",
                                                                        "alignItems": "center",
                                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f9151a9b70c5578ab88486_Icon-Wrapper.svg",
                                                                        "alt": "",
                                                                        "maxHeight": "100%"
                                                                    },
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "c4ec3b901d1c2557b85039237c32c75dfb82fe0c722ac18c4100022304e7f4f3",
                                                            "name": "feature-item",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "fd136385b524a767413a8f98f796c93039d936249eb7151128e116529fd815fb",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Lorem ipsum dolor sit amet</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "dbf062609e8560fc690e9d26db22d958be12d9419131ab1b36329a1a299076df",
                                                    "name": "pricing-feature",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 6,
                                                        "rowGap": 6,
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "4df41c92117518a7530c4c848dc20eaf9399be93e3c9612b726bf20e880753d6",
                                                            "name": "icon",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "eb83a03a9ff9e6bd8751f19e3059b48a55dc176b9abf8a2917f126e0739564e5",
                                                                    "name": "icon-wrapper",
                                                                    "xtype": "image",
                                                                    "properties": {
                                                                        "borderTopWidth": 0,
                                                                        "borderRightWidth": 0,
                                                                        "borderBottomWidth": 0,
                                                                        "borderLeftWidth": 0,
                                                                        "borderTopStyle": "initial",
                                                                        "borderRightStyle": "initial",
                                                                        "borderBottomStyle": "initial",
                                                                        "borderLeftStyle": "initial",
                                                                        "borderTopColor": "initial",
                                                                        "borderRightColor": "initial",
                                                                        "borderBottomColor": "initial",
                                                                        "borderLeftColor": "initial",
                                                                        "borderImageSource": "initial",
                                                                        "borderImageSlice": "initial",
                                                                        "borderImageWidth": "initial",
                                                                        "borderImageOutset": "initial",
                                                                        "borderImageRepeat": "initial",
                                                                        "maxWidth": "100%",
                                                                        "verticalAlign": "middle",
                                                                        "display": "flex",
                                                                        "objectFit": "cover",
                                                                        "justifyContent": "center",
                                                                        "alignItems": "center",
                                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f9151a9b70c5578ab88486_Icon-Wrapper.svg",
                                                                        "alt": "",
                                                                        "maxHeight": "100%"
                                                                    },
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "6d0403c27777da6710d93385e47b9941d9edecef8668ddbe2708454b0b016675",
                                                            "name": "feature-item",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "a093ba1c758345216bcd3bb67fe78833846a7ee1e8a5cb531fa8ca34c6a53171",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Lorem ipsum dolor sit amet</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "3979b3e549e221c5f6e1485298bf2fa7cf83f8cd6cf193750892236a90e17e9b",
                                                    "name": "pricing-feature",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 6,
                                                        "rowGap": 6,
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "f3fdd44bc48347924a0dd0d7003a2d4c8ccede4311258686ea04857a651e66a4",
                                                            "name": "icon",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "9c33bfe3460aec0628d7fd6c4d71e5f326a6de49d9d639f2a46954b3e236ea7b",
                                                                    "name": "icon-wrapper",
                                                                    "xtype": "image",
                                                                    "properties": {
                                                                        "borderTopWidth": 0,
                                                                        "borderRightWidth": 0,
                                                                        "borderBottomWidth": 0,
                                                                        "borderLeftWidth": 0,
                                                                        "borderTopStyle": "initial",
                                                                        "borderRightStyle": "initial",
                                                                        "borderBottomStyle": "initial",
                                                                        "borderLeftStyle": "initial",
                                                                        "borderTopColor": "initial",
                                                                        "borderRightColor": "initial",
                                                                        "borderBottomColor": "initial",
                                                                        "borderLeftColor": "initial",
                                                                        "borderImageSource": "initial",
                                                                        "borderImageSlice": "initial",
                                                                        "borderImageWidth": "initial",
                                                                        "borderImageOutset": "initial",
                                                                        "borderImageRepeat": "initial",
                                                                        "maxWidth": "100%",
                                                                        "verticalAlign": "middle",
                                                                        "display": "flex",
                                                                        "objectFit": "cover",
                                                                        "justifyContent": "center",
                                                                        "alignItems": "center",
                                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f9151a9b70c5578ab88486_Icon-Wrapper.svg",
                                                                        "alt": "",
                                                                        "maxHeight": "100%"
                                                                    },
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "f644cc890df88d78a64583cf3aec743ffb805b698c21e09810aecadcbf3dc96b",
                                                            "name": "feature-item",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "d50e6dacfa94b7565266b470cedc14c605f97d11e7bf57d10294a1ea870c7e8b",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Lorem ipsum dolor sit amet</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "b64a6b6d2296934489d6908bd3b4b23b3c4b013f82bbb3a12ad711d8b2a02bde",
                                                    "name": "pricing-feature",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 6,
                                                        "rowGap": 6,
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "f7b0b80560d58e5e32a37414bd8ac35d699f85cb8b648b71dc936c02218b8ef2",
                                                            "name": "icon",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "b8b8e6c75c6967e0171b656559acd5ca70d93615330ac51023ba3eb4e59d94e4",
                                                                    "name": "icon-wrapper",
                                                                    "xtype": "image",
                                                                    "properties": {
                                                                        "borderTopWidth": 0,
                                                                        "borderRightWidth": 0,
                                                                        "borderBottomWidth": 0,
                                                                        "borderLeftWidth": 0,
                                                                        "borderTopStyle": "initial",
                                                                        "borderRightStyle": "initial",
                                                                        "borderBottomStyle": "initial",
                                                                        "borderLeftStyle": "initial",
                                                                        "borderTopColor": "initial",
                                                                        "borderRightColor": "initial",
                                                                        "borderBottomColor": "initial",
                                                                        "borderLeftColor": "initial",
                                                                        "borderImageSource": "initial",
                                                                        "borderImageSlice": "initial",
                                                                        "borderImageWidth": "initial",
                                                                        "borderImageOutset": "initial",
                                                                        "borderImageRepeat": "initial",
                                                                        "maxWidth": "100%",
                                                                        "verticalAlign": "middle",
                                                                        "display": "flex",
                                                                        "objectFit": "cover",
                                                                        "justifyContent": "center",
                                                                        "alignItems": "center",
                                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f9151a9b70c5578ab88486_Icon-Wrapper.svg",
                                                                        "alt": "",
                                                                        "maxHeight": "100%"
                                                                    },
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "ef0f8c043c88b94a8eff7cf848cf3c47414a5fa95e63675760c2862dd4ce38bb",
                                                            "name": "feature-item",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "72000e6e5f1d54c606601b23ace9c2cae56ede947291453d46464a1296ef1c5c",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Lorem ipsum dolor sit amet</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "01a1c5488885c369eb55d9b66962e87ef93dcb7d1a874d99289a69190f472015",
                                                    "name": "pricing-feature",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 6,
                                                        "rowGap": 6,
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "be939086c77fbc1a61923f7fb685911c8e40329606ebd4a12a5b54ba07305873",
                                                            "name": "icon",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "123ebb40ee41792014c85219c89c222d9f835bc14f7f04ac0dcdcba1e0308ffc",
                                                                    "name": "icon-wrapper",
                                                                    "xtype": "image",
                                                                    "properties": {
                                                                        "borderTopWidth": 0,
                                                                        "borderRightWidth": 0,
                                                                        "borderBottomWidth": 0,
                                                                        "borderLeftWidth": 0,
                                                                        "borderTopStyle": "initial",
                                                                        "borderRightStyle": "initial",
                                                                        "borderBottomStyle": "initial",
                                                                        "borderLeftStyle": "initial",
                                                                        "borderTopColor": "initial",
                                                                        "borderRightColor": "initial",
                                                                        "borderBottomColor": "initial",
                                                                        "borderLeftColor": "initial",
                                                                        "borderImageSource": "initial",
                                                                        "borderImageSlice": "initial",
                                                                        "borderImageWidth": "initial",
                                                                        "borderImageOutset": "initial",
                                                                        "borderImageRepeat": "initial",
                                                                        "maxWidth": "100%",
                                                                        "verticalAlign": "middle",
                                                                        "display": "flex",
                                                                        "objectFit": "cover",
                                                                        "justifyContent": "center",
                                                                        "alignItems": "center",
                                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f9151a9b70c5578ab88486_Icon-Wrapper.svg",
                                                                        "alt": "",
                                                                        "maxHeight": "100%"
                                                                    },
                                                                    "children": []
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "867bccfd9eb11ab5036bbf939042e3aa51e7edffe9f12a8138fa5554f1199679",
                                                            "name": "feature-item",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "5a8054a6b10f54f97eb64a83f41d9cefb7ee3d7e67fba1dea3391a152fa4b111",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Lorem ipsum dolor sit amet</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                "scale": "5px"
            },
            {
                "widget": {
                    "id": "e8ceb1edca9248e81b5a5ddd1dad1d6631898427422ccaa78781becd90429b9a",
                    "name": "pricing-items",
                    "xtype": "group",
                    "properties": {
                        "columnGap": 60,
                        "rowGap": 60,
                        "justifyContent": "center",
                        "alignItems": "flex-start",
                        "paddingTop": 48,
                        "paddingRight": 18,
                        "paddingBottom": 48,
                        "paddingLeft": 18,
                        "display": "flex",
                    },
                    "children": [
                        {
                            "id": "f37345c2eab8f2744bedaff1b989ee60b218c0cada95890911c24568d8b19d3d",
                            "name": "small-container-4",
                            "xtype": "group",
                            "properties": {
                                "maxWidth": 720,
                                "columnGap": 30,
                                "rowGap": 30,
                                "flexDirection": "column",
                                "justifyContent": "center",
                                "alignItems": "flex-start",
                                "display": "flex",
                            },
                            "children": [
                                {
                                    "id": "9e966aad2e1918c61f3bdaa994b9867c4c8b1d1c56144dd3f736ae97fed4735d",
                                    "name": "pricing-item",
                                    "xtype": "group",
                                    "properties": {
                                        "columnGap": 30,
                                        "rowGap": 30,
                                        "backgroundColor": "rgb(245, 247, 250)",
                                        "justifyContent": "flex-start",
                                        "alignItems": "center",
                                        "paddingTop": 30,
                                        "paddingRight": 24,
                                        "paddingBottom": 30,
                                        "paddingLeft": 24,
                                        "display": "flex",
                                        "small": {
                                            "flexDirection": "column"
                                        }
                                    },
                                    "children": [
                                        {
                                            "id": "ef09b2201378e2f5ef15e9963be241c3230c715ea78620d7706aa0c92017a8cd",
                                            "name": "image-wrapper-9",
                                            "xtype": "group",
                                            "properties": {
                                                "maxWidth": 195,
                                                "flexDirection": "column",
                                                "justifyContent": "center",
                                                "alignItems": "center",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "a6e7a7af7999b6ded9fe918253e9fd1ac0b6c64ad81882d15371093b11e9fb1f",
                                                    "name": "image-15",
                                                    "xtype": "image",
                                                    "properties": {
                                                        "borderTopWidth": 0,
                                                        "borderRightWidth": 0,
                                                        "borderBottomWidth": 0,
                                                        "borderLeftWidth": 0,
                                                        "borderTopStyle": "initial",
                                                        "borderRightStyle": "initial",
                                                        "borderBottomStyle": "initial",
                                                        "borderLeftStyle": "initial",
                                                        "borderTopColor": "initial",
                                                        "borderRightColor": "initial",
                                                        "borderBottomColor": "initial",
                                                        "borderLeftColor": "initial",
                                                        "borderImageSource": "initial",
                                                        "borderImageSlice": "initial",
                                                        "borderImageWidth": "initial",
                                                        "borderImageOutset": "initial",
                                                        "borderImageRepeat": "initial",
                                                        "maxWidth": "100%",
                                                        "verticalAlign": "middle",
                                                        "display": "flex",
                                                        "objectFit": "cover",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f915244f19a7a6e6b124cf_image.png",
                                                        "alt": "",
                                                        "maxHeight": "100%"
                                                    },
                                                    "children": []
                                                }
                                            ]
                                        },
                                        {
                                            "id": "de5f61d2516a49d9eda9ec3c67f448740c69fdbf052631bf896ea05ab5f3592b",
                                            "name": "info-3",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 12,
                                                "rowGap": 12,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "paddingTop": 12,
                                                "paddingRight": 12,
                                                "paddingBottom": 12,
                                                "paddingLeft": 12,
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "92ff9fc2cf38dd7dbce32c3f002b4e2ae100cd39399870890a14129d23e7a6f2",
                                                    "name": "text-39",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "fontSize": 10.5,
                                                        "fontWeight": "400",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "57e9f660f85f791a46d26501e607fd929d8b293850b1881201cf9ec4a51e9ef8",
                                                            "xtype": "label",
                                                            "properties": {
                                                                "content": "<p>Dual Income</p>",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "831ca1116a9657873e1727d53d96fbf9b1c718fd872ad08d18c868662f55e4ac",
                                                    "name": "pricing-info",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "justifyContent": "space-between",
                                                        "alignItems": "center",
                                                        "paddingTop": 12,
                                                        "paddingBottom": 12,
                                                        "display": "flex",
                                                        "boxShadow": null,
                                                        "small": {
                                                            "flexDirection": "column"
                                                        }
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "47f7ee21ecef439c6f7296817ad17189f7754b0339d6d5f4fd80cfc2523e9b11",
                                                            "name": "text-40",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 18,
                                                                "fontWeight": "700",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "8f1c2207d5fd48565f6305985b28085d94659b325a88e7113f2aca1066215587",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Aberglasslyn, NSW</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "3f4e4cdd326f0c9382cc3021339fab5ab70464b3b652ce02f70f94ea01b78315",
                                                            "name": "text-40",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 18,
                                                                "fontWeight": "700",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "1a51180e321d7990260a4b568160bba9aeba269bbc4eaaca22e8fcbc639f920f",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>$723,99</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "e2e7363270481ec130a7bc2809b9d47a9e536e7073b96b889030968c047100ec",
                                                    "name": "pricing-details",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "justifyContent": "space-between",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                        "small": {
                                                            "flexDirection": "column"
                                                        }
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "0ae20d0c6a47490a8b1791b4ae62c9101199e7efc7030f5d1ab6840662a44713",
                                                            "name": "detail",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "columnGap": 3,
                                                                "rowGap": 3,
                                                                "flexDirection": "column",
                                                                "justifyContent": "flex-start",
                                                                "alignItems": "flex-start",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "d5bbe1efe4702ef7dfd2cbe0ef5becaa7f7c8cb9e1e3c2c7e4b375f44291e530",
                                                                    "name": "text-39",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontSize": 10.5,
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "38be7bd8c9c430eef0500db782e41ba5995301aed95f22b97621f40ee3c7d781",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>$18,245</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "id": "dfcc308ae667baafa9ff3f9c06e6c5c7bc739e984c82f79c33ef531676b0dc40",
                                                                    "name": "description-5",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "2cc7e47724b8070c0bd014f4a971f45e47200fc0a3b116fe20b731d7b7a077e5",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>Project Income</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "403417f57389ee23fa0ea0a0779774f5dbfaf1d6e9aead348782a5de02279d76",
                                                            "name": "detail",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "columnGap": 3,
                                                                "rowGap": 3,
                                                                "flexDirection": "column",
                                                                "justifyContent": "flex-start",
                                                                "alignItems": "flex-start",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "361b9508cb360742e644d8ed3a375c01263138b1fec1452028c4a2601f280c04",
                                                                    "name": "text-39",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontSize": 10.5,
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "44d64043f44e114164b823be5b10a91f473fb5bc3814f3cb3920bdc98d4f2fc5",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>8%</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "id": "49ea6d02229a54dc047181e43113158574b7e5e05c2b6ca69eb4b6c7429e5256",
                                                                    "name": "description-5",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "5b342b54603f30f075ba24a5f34563cf6258ecd7e915b0c4fcb28f68479c3b00",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>Project yield</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "b31c8002500edf6ee65a4c8a17d1d6e102816a6e9e0e4384e750cc10e5ee82d7",
                                                            "name": "detail",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "columnGap": 3,
                                                                "rowGap": 3,
                                                                "flexDirection": "column",
                                                                "justifyContent": "flex-start",
                                                                "alignItems": "flex-start",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "8e9df8653a4a62a733545d989851b7b9677d774dfd4a59774d6110ec842dd776",
                                                                    "name": "text-39",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontSize": 10.5,
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "dd2e64c3ed55f344064d3f7cc7abc458917b373b39ff3a7d42d79afccb7c8d2d",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>12.2%</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "id": "5a7f67bf30193750f0e4f15dc8021d52db856793e8aa5340fef2ac7b8de709b9",
                                                                    "name": "description-5",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "1bef5511a7262b554e7c9f8b9cb9a5bc2c4bc21446a90b105f3cbb876b752015",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>Project growth</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "id": "f20c6de5f5888ca915e6f79c5e3557b81e040b8144d465d643f10d4f41830ecb",
                                    "name": "pricing-item",
                                    "xtype": "group",
                                    "properties": {
                                        "columnGap": 30,
                                        "rowGap": 30,
                                        "backgroundColor": "rgb(245, 247, 250)",
                                        "justifyContent": "flex-start",
                                        "alignItems": "center",
                                        "paddingTop": 30,
                                        "paddingRight": 24,
                                        "paddingBottom": 30,
                                        "paddingLeft": 24,
                                        "display": "flex",
                                        "small": {
                                            "flexDirection": "column"
                                        }
                                    },
                                    "children": [
                                        {
                                            "id": "539f8767326623556b397f7f5eb5d0a36aac8f0bf5e1e0810ec8ae113af1f10f",
                                            "name": "image-wrapper-9",
                                            "xtype": "group",
                                            "properties": {
                                                "maxWidth": 195,
                                                "flexDirection": "column",
                                                "justifyContent": "center",
                                                "alignItems": "center",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "afb6e864b5ff11543e4f16f2bf925632a00486382726f30b9b885cd7d5fd1481",
                                                    "name": "image-15",
                                                    "xtype": "image",
                                                    "properties": {
                                                        "borderTopWidth": 0,
                                                        "borderRightWidth": 0,
                                                        "borderBottomWidth": 0,
                                                        "borderLeftWidth": 0,
                                                        "borderTopStyle": "initial",
                                                        "borderRightStyle": "initial",
                                                        "borderBottomStyle": "initial",
                                                        "borderLeftStyle": "initial",
                                                        "borderTopColor": "initial",
                                                        "borderRightColor": "initial",
                                                        "borderBottomColor": "initial",
                                                        "borderLeftColor": "initial",
                                                        "borderImageSource": "initial",
                                                        "borderImageSlice": "initial",
                                                        "borderImageWidth": "initial",
                                                        "borderImageOutset": "initial",
                                                        "borderImageRepeat": "initial",
                                                        "maxWidth": "100%",
                                                        "verticalAlign": "middle",
                                                        "display": "flex",
                                                        "objectFit": "cover",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f915244f19a7a6e6b124cf_image.png",
                                                        "alt": "",
                                                        "maxHeight": "100%"
                                                    },
                                                    "children": []
                                                }
                                            ]
                                        },
                                        {
                                            "id": "8bbd9d4f44492d82543b4f4bb8a9f12f85ef89398a964e00687ff5c3462793ac",
                                            "name": "info-3",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 12,
                                                "rowGap": 12,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "paddingTop": 12,
                                                "paddingRight": 12,
                                                "paddingBottom": 12,
                                                "paddingLeft": 12,
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "356fecc69b072210d53ae7120cd823ed9ad87b9fd895a96d222aa173f6767397",
                                                    "name": "text-39",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "fontSize": 10.5,
                                                        "fontWeight": "400",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "0b52caab9be32cdbe9989c538f1ba65e71ec0f6d4661dc4514198945c6488e66",
                                                            "xtype": "label",
                                                            "properties": {
                                                                "content": "<p>Dual Income</p>",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "35539d4e08d463a39dae8d4c39642122ceec273b38fd295f3b4ca1bad6230ca8",
                                                    "name": "pricing-info-2",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "justifyContent": "space-between",
                                                        "alignItems": "center",
                                                        "paddingTop": 12,
                                                        "paddingBottom": 12,
                                                        "display": "flex",
                                                        "boxShadow": null,
                                                        "small": {
                                                            "flexDirection": "column"
                                                        }
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "c56855e0de0edafff735e472d20260e0ef6f1f914aafff03c24a492a1ac68a60",
                                                            "name": "text-40",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 18,
                                                                "fontWeight": "700",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "202ac26e8b5d02017e7c8bcc14557f14eda2b2d0d7332d33cc67d38c4187a761",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Aberglasslyn, NSW</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "c8112dd40ca6df9942df8f56f6409f4149182fa0b72c58beab89a733403156bd",
                                                            "name": "text-40",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 18,
                                                                "fontWeight": "700",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "3b032a287c31a6ab9702ae2da0d26529a5736bab173310a7635149ef3e93cade",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>$723,99</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "a44d1c3f857456e55cd789d8fdd49b4ecfc015583ef234f1b98752d0159c23b9",
                                                    "name": "pricing-details",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "justifyContent": "space-between",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                        "small": {
                                                            "flexDirection": "column"
                                                        }
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "d5df3db86962fe2fa2958f596c0ebd2899f67c598bd5e11cb672c38432bfe59b",
                                                            "name": "detail",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "columnGap": 3,
                                                                "rowGap": 3,
                                                                "flexDirection": "column",
                                                                "justifyContent": "flex-start",
                                                                "alignItems": "flex-start",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "5f79fd32fe1861e09b20370626c5d23e839225ec49d783b9ceb64e121b7691b5",
                                                                    "name": "text-39",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontSize": 10.5,
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "06d76e42d61d9c52bb2f4eca00b908365cdb7197d9a6d2e73181e1ce458651f6",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>$18,245</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "id": "4fdc05f7be771850dc8b1c4166ff07e129fb9d84bb9d58eb8791b0e5cf498918",
                                                                    "name": "description-5",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "64e75032546d46b971f79ffcea9e1266536aa5e798f9b6ae8117aedd74d227a8",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>Project Income</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "0aeef0b6643abf4b35dedc17f2bd891d78d422273e99e16b95d154b8639a605d",
                                                            "name": "detail",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "columnGap": 3,
                                                                "rowGap": 3,
                                                                "flexDirection": "column",
                                                                "justifyContent": "flex-start",
                                                                "alignItems": "flex-start",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "c72623f55afd89c8146f28fe3250d71b812709e4d9ac40708b9a2146cf3a8811",
                                                                    "name": "text-39",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontSize": 10.5,
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "20e56e010fedb62cd4cbd83c089d3c0657d79db33e36122ec923dfbba04bfa42",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>8%</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "id": "1bc97fb93bfb029038d8b24154333a2e0631ceec7013293dc462eb4119c4fba9",
                                                                    "name": "description-5",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "2f0d86c9d57c2be2a4ef888297eaf44d68098a0ebf9b584d5a0acc59507d6444",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>Project yield</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "61b5aa08e1aa17cb520ddb47cb1260dcaf6cfa05f610660320291cd53cfb6b00",
                                                            "name": "detail",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "columnGap": 3,
                                                                "rowGap": 3,
                                                                "flexDirection": "column",
                                                                "justifyContent": "flex-start",
                                                                "alignItems": "flex-start",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "f2df6ef98d4b897d791914ebf82864bfd9d6eacd15dae27778a78108d2cab32e",
                                                                    "name": "text-39",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontSize": 10.5,
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "f5f80c8fad5f34f962e667265d4c7cb97d79a5be2d7b87a76fbcfc3454c8a181",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>12.2%</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "id": "c8cb0025dafd235ec6a924ee821fbaee221f09d0fe4e4554b97d1d4703871d72",
                                                                    "name": "description-5",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "1b89435a1283ad3dc18a81f5c2c204f2761562d7782e468c3e70f8e1d8812b54",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>Project growth</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "id": "d3b159f7d477985d62d53a9074ac622529548b1b227eeaeb6c3624753b1c5070",
                                    "name": "pricing-item",
                                    "xtype": "group",
                                    "properties": {
                                        "columnGap": 30,
                                        "rowGap": 30,
                                        "backgroundColor": "rgb(245, 247, 250)",
                                        "justifyContent": "flex-start",
                                        "alignItems": "center",
                                        "paddingTop": 30,
                                        "paddingRight": 24,
                                        "paddingBottom": 30,
                                        "paddingLeft": 24,
                                        "display": "flex",
                                        "small": {
                                            "flexDirection": "column"
                                        }
                                    },
                                    "children": [
                                        {
                                            "id": "9f0fc7c72f4a0722ed94b970bca850cbf0dfb1c25fd64d1b6a2bbf458e0cce9f",
                                            "name": "image-wrapper-9",
                                            "xtype": "group",
                                            "properties": {
                                                "maxWidth": 195,
                                                "flexDirection": "column",
                                                "justifyContent": "center",
                                                "alignItems": "center",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "1640c130ba541a97147e2ae356052b0e2a11b080892e1759534a5abe49918cc9",
                                                    "name": "image-15",
                                                    "xtype": "image",
                                                    "properties": {
                                                        "borderTopWidth": 0,
                                                        "borderRightWidth": 0,
                                                        "borderBottomWidth": 0,
                                                        "borderLeftWidth": 0,
                                                        "borderTopStyle": "initial",
                                                        "borderRightStyle": "initial",
                                                        "borderBottomStyle": "initial",
                                                        "borderLeftStyle": "initial",
                                                        "borderTopColor": "initial",
                                                        "borderRightColor": "initial",
                                                        "borderBottomColor": "initial",
                                                        "borderLeftColor": "initial",
                                                        "borderImageSource": "initial",
                                                        "borderImageSlice": "initial",
                                                        "borderImageWidth": "initial",
                                                        "borderImageOutset": "initial",
                                                        "borderImageRepeat": "initial",
                                                        "maxWidth": "100%",
                                                        "verticalAlign": "middle",
                                                        "display": "flex",
                                                        "objectFit": "cover",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f915244f19a7a6e6b124cf_image.png",
                                                        "alt": "",
                                                        "maxHeight": "100%"
                                                    },
                                                    "children": []
                                                }
                                            ]
                                        },
                                        {
                                            "id": "ce841416153b33076f8b12dbe675779bd372479a8981fa43f47443d6fbdcbf8e",
                                            "name": "info-3",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 12,
                                                "rowGap": 12,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "paddingTop": 12,
                                                "paddingRight": 12,
                                                "paddingBottom": 12,
                                                "paddingLeft": 12,
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "2f799b53932bfd021625039b4fd0c22d5000e9177a69727aee60cf5e94655502",
                                                    "name": "text-39",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "fontSize": 10.5,
                                                        "fontWeight": "400",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "f0c04c6ead0eb3773bfd4a05ff3021bdb3ce69563ccebba395d2bdedb6ad7132",
                                                            "xtype": "label",
                                                            "properties": {
                                                                "content": "<p>Dual Income</p>",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "7b4e781eee41e473dc9dee0d8136e49c45323bd2857642195dc03be545008f22",
                                                    "name": "pricing-info",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "justifyContent": "space-between",
                                                        "alignItems": "center",
                                                        "paddingTop": 12,
                                                        "paddingBottom": 12,
                                                        "display": "flex",
                                                        "boxShadow": null,
                                                        "small": {
                                                            "flexDirection": "column"
                                                        }
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "7da9d23722df44b4e77db2f0c39d3295dde65c62f2f19383222cc6fadcf05238",
                                                            "name": "text-40",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 18,
                                                                "fontWeight": "700",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "2464fcf5e05ed9785937a616f65d4951379127e8e8583c31fc330acaecd9f403",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Aberglasslyn, NSW</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "6efd8f2df381edae539e54bf8bd87b5f59ed8f93b8add310776cccd0ae70dc3a",
                                                            "name": "text-40",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 18,
                                                                "fontWeight": "700",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "58b6d6c90c17b1d16e018d12b72f6ab778ea10272cdb3ab96218a54afdb0c4aa",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>$723,99</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "26f37d205535af28777fb0ebc72bc23b8926643de81ffc24769a804a9c6ad2d0",
                                                    "name": "pricing-details",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "justifyContent": "space-between",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                        "small": {
                                                            "flexDirection": "column"
                                                        }
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "62d1d996660e20d69213d455557688f35107a24bff0e36739f134492c99f9ed9",
                                                            "name": "detail",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "columnGap": 3,
                                                                "rowGap": 3,
                                                                "flexDirection": "column",
                                                                "justifyContent": "flex-start",
                                                                "alignItems": "flex-start",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "c130f688f57eada11a9088bf6ce2269b819bbc69909b3046e0f386fac68c080d",
                                                                    "name": "text-39",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontSize": 10.5,
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "3563738603c95aa5dc6dd15d7f53a0c78ad10de61034c9f7e22097569722486e",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>$18,245</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "id": "8cbeb1066ee4ed5c246ecb2344f776ae41963c0ed16d5cb904aa418d3848ea25",
                                                                    "name": "description-5",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "37fc824a8e6f251b304bafc090283a5e3ae55588a9371f91e4c27ea1d8e4ad93",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>Project Income</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "9dccc3ad8629f55fd7d72136c3238afd9537f5364d4e0be03feef2f2b64be154",
                                                            "name": "detail",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "columnGap": 3,
                                                                "rowGap": 3,
                                                                "flexDirection": "column",
                                                                "justifyContent": "flex-start",
                                                                "alignItems": "flex-start",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "3d7ed07c4730e86e4149fea1aae76f2da0cdbca7200e11835f555bde2a324696",
                                                                    "name": "text-39",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontSize": 10.5,
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "866dcabb275147bac61802469b5c00d99b6ca6571ecf95d6753751a7fdf343d3",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>8%</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "id": "d498675322e523d393a75a5b0b2036b6b0e1862ff88133c0c8702d0862dd9f07",
                                                                    "name": "description-5",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "6da7dab2e20256017c31092639ebe643f63cf05fa39b6a4a2202793c8aa2dd3c",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>Project yield</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "fe0407c5437bda75b54bb0a9ef7930cad48164827bb8cb4edc83461eb8c4e3bf",
                                                            "name": "detail",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "columnGap": 3,
                                                                "rowGap": 3,
                                                                "flexDirection": "column",
                                                                "justifyContent": "flex-start",
                                                                "alignItems": "flex-start",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "cae2af71b64bd455c7e51a6d3401684616d85b6d2ba513446d1021b12100c4fe",
                                                                    "name": "text-39",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontSize": 10.5,
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "25851ec9859f9893bbb9db639738c89d73711b0925438d0d77cdf097b417da85",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>12.2%</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "id": "27a0a2f72c54acd8953c35122bec96fb566d56f8afd6f5d3d504abe0ee0e2620",
                                                                    "name": "description-5",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "d329214b8a17d2da7759435343219781733034204f1e6abc6457f6db0e786070",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>Project growth</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                "scale": "5px"
            },
            {
                "widget": {
                    "id": "2f84c7b78469ad777e5c21eee9ef7437e38acafc976b0f643d6a75518268d136",
                    "name": "testimonial-column-dark",
                    "xtype": "group",
                    "properties": {
                        "columnGap": 30,
                        "rowGap": 30,
                        "flexDirection": "column",
                        "justifyContent": "flex-start",
                        "alignItems": "center",
                        "paddingTop": 48,
                        "paddingRight": 18,
                        "paddingBottom": 48,
                        "paddingLeft": 18,
                        "display": "flex",
                    },
                    "children": [
                        {
                            "id": "c2e46e72eb6e48967b3b6028ce8afbbd133b418882f9564cfe08a8f82da011bc",
                            "name": "title-3",
                            "xtype": "group",
                            "properties": {
                                "textAlign": "center",
                                "fontWeight": "700",
                            },
                            "children": [
                                {
                                    "id": "11e291c437eacdac07e64258723ca7e468c21a167e9f5eb8d48a69445eaeb63e",
                                    "xtype": "label",
                                    "properties": {
                                        "content": "<p>Testimonial section title</p>",
                                        "maxWidth": "100%"
                                    }
                                }
                            ]
                        },
                        {
                            "id": "7e32dbee802080315a51917e5e4194d5c6fb024371906914dc532b82c32d5d35",
                            "name": "columns-9",
                            "xtype": "group",
                            "properties": {
                                "maxWidth": 720,
                                "columnGap": 24,
                                "rowGap": 24,
                                "justifyContent": "center",
                                "alignItems": "flex-start",
                                "display": "flex",
                                "small": {
                                    "flexDirection": "column",
                                    "alignItems": "center"
                                }
                            },
                            "children": [
                                {
                                    "id": "025813ee780f6ebe1cb2b09ecaaedc46d298e5146846abdbdf54e515b4b1b217",
                                    "name": "column-11",
                                    "xtype": "group",
                                    "properties": {
                                        "columnGap": 18,
                                        "rowGap": 18,
                                        "flexDirection": "column",
                                        "justifyContent": "flex-start",
                                        "alignItems": "flex-start",
                                        "display": "flex",
                                    },
                                    "children": [
                                        {
                                            "id": "ed7bb4f910dbc02575a8c3a47b209109a3f287b8f1611f540d7bbe45f2b6fd7a",
                                            "name": "small-columns-2 small-columns-3",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 18,
                                                "rowGap": 18,
                                                "backgroundColor": "rgb(245, 247, 250)",
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "paddingTop": 18,
                                                "paddingRight": 18,
                                                "paddingBottom": 18,
                                                "paddingLeft": 18,
                                                "display": "flex",
                                                "small": {
                                                    "flexDirection": "column",
                                                    "alignItems": "center"
                                                }
                                            },
                                            "children": [
                                                {
                                                    "id": "d99a133d042cdd2b042b1772c0544a214ffc5900a2db8b8c3565bf6d660299b6",
                                                    "name": "image-16",
                                                    "xtype": "image",
                                                    "properties": {
                                                        "borderTopWidth": 0,
                                                        "borderRightWidth": 0,
                                                        "borderBottomWidth": 0,
                                                        "borderLeftWidth": 0,
                                                        "borderTopStyle": "initial",
                                                        "borderRightStyle": "initial",
                                                        "borderBottomStyle": "initial",
                                                        "borderLeftStyle": "initial",
                                                        "borderTopColor": "initial",
                                                        "borderRightColor": "initial",
                                                        "borderBottomColor": "initial",
                                                        "borderLeftColor": "initial",
                                                        "borderImageSource": "initial",
                                                        "borderImageSlice": "initial",
                                                        "borderImageWidth": "initial",
                                                        "borderImageOutset": "initial",
                                                        "borderImageRepeat": "initial",
                                                        "maxWidth": "100%",
                                                        "verticalAlign": "middle",
                                                        "display": "flex",
                                                        "objectFit": "cover",
                                                        "flexDirection": "column",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f915316961a682144e1de8_Image.png",
                                                        "alt": "",
                                                        "maxHeight": "100%"
                                                    },
                                                    "children": []
                                                },
                                                {
                                                    "id": "7165dd6ad52eede5f8c1baa35e35e485c0aeeef3007205977e1fb94be5c80d8c",
                                                    "name": "quote-2",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 69,
                                                        "rowGap": 69,
                                                        "flexDirection": "column",
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "flex-start",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "b6489353178d26fc449899579193b9d99847ab0163ef31acc5209e52cef98032",
                                                            "name": "text-41",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 10.5,
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "758818ac8017cb61710eb6029cdd0c340d9c7cae91c91dce6d6488e0b14f39fa",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>“Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat.”</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "fc9e6c2a4b76d9c7143eb6a6f941a949b7610f498dedee100a175fbf26e5f419",
                                                            "name": "author-2",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "columnGap": 3,
                                                                "rowGap": 3,
                                                                "flexDirection": "column",
                                                                "justifyContent": "flex-start",
                                                                "alignItems": "flex-start",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "601d2a20f7d05e36d7d1214c5a4d99260ba6f01f9401e83a4a8aa9c1e3e7016e",
                                                                    "name": "text-42",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontSize": 18,
                                                                        "fontWeight": "700",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "b56e8b0b11e2de6ce37c2fb1af57de04f9aa6a3cbdb7b0c864df4910691cac52",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>Author Name</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "id": "140d0416046a07f221cd1d1ce6a540284d01d7fe70a2f4c6d223608552512391",
                                                                    "name": "text-41",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontSize": 10.5,
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "0c5d5e68dc5af2516bed8c3d7b4e379e22f9fc571220fbd24075b8a09aeb50bc",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>Former VP of Company</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "id": "e1bc5a0fb0ff776c599dab55405f6dd52ec05959648117fb6db88c7f69db3791",
                                    "name": "small-columns-4",
                                    "xtype": "group",
                                    "properties": {
                                        "columnGap": 24,
                                        "rowGap": 24,
                                        "justifyContent": "flex-start",
                                        "alignItems": "flex-start",
                                        "display": "flex",
                                        "small": {
                                            "flexDirection": "column",
                                            "alignItems": "center"
                                        }
                                    },
                                    "children": [
                                        {
                                            "id": "9a6c191ab271e64ebae9ff8990ed2629becf6e0133e417633b0f1dc9b1f3de0c",
                                            "name": "column-12",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 15,
                                                "rowGap": 15,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "dd2b920e6398c064926cda2eaa9ed2ef6f040238ebeca3ce5d8f069758c96de9",
                                                    "name": "content-10",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 18,
                                                        "rowGap": 18,
                                                        "backgroundColor": "rgb(245, 247, 250)",
                                                        "flexDirection": "column",
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "flex-start",
                                                        "paddingTop": 18,
                                                        "paddingRight": 18,
                                                        "paddingBottom": 18,
                                                        "paddingLeft": 18,
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "d737a2e2ef41129609e65db369c5988fd2537b114e909359d3bcecaa4486530c",
                                                            "name": "quote-3",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "columnGap": 54,
                                                                "rowGap": 54,
                                                                "flexDirection": "column",
                                                                "justifyContent": "flex-start",
                                                                "alignItems": "flex-start",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "06a2ec78464df981f47eff8dcef36c3c3085997eb017507cf4fc00ef45f938d2",
                                                                    "name": "text-41",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontSize": 10.5,
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "8fc99ad709f27b69f7d67d58c800035fdf9c5fc2be3a31a51e25788db78eadf6",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>“Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat.”</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "id": "e4a0e8231a49d2a1ee68d01434aaafb1c2da293801068f4126048b420d1b581f",
                                                                    "name": "author-3",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "columnGap": 6,
                                                                        "rowGap": 6,
                                                                        "justifyContent": "flex-start",
                                                                        "alignItems": "center",
                                                                        "display": "flex",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "06172768b714c15561d6aec98216c8dbbb388a15e303c6feb0e2557be4c07a41",
                                                                            "name": "image-17",
                                                                            "xtype": "image",
                                                                            "properties": {
                                                                                "borderTopWidth": 0,
                                                                                "borderRightWidth": 0,
                                                                                "borderBottomWidth": 0,
                                                                                "borderLeftWidth": 0,
                                                                                "borderTopStyle": "initial",
                                                                                "borderRightStyle": "initial",
                                                                                "borderBottomStyle": "initial",
                                                                                "borderLeftStyle": "initial",
                                                                                "borderTopColor": "initial",
                                                                                "borderRightColor": "initial",
                                                                                "borderBottomColor": "initial",
                                                                                "borderLeftColor": "initial",
                                                                                "borderImageSource": "initial",
                                                                                "borderImageSlice": "initial",
                                                                                "borderImageWidth": "initial",
                                                                                "borderImageOutset": "initial",
                                                                                "borderImageRepeat": "initial",
                                                                                "maxWidth": "100%",
                                                                                "verticalAlign": "middle",
                                                                                "display": "flex",
                                                                                "objectFit": "cover",
                                                                                "borderTopLeftRadius": 75,
                                                                                "borderTopRightRadius": 75,
                                                                                "borderBottomRightRadius": 75,
                                                                                "borderBottomLeftRadius": 75,
                                                                                "flexDirection": "column",
                                                                                "justifyContent": "center",
                                                                                "alignItems": "center",
                                                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f9153118798502820d6826_Image.png",
                                                                                "alt": "",
                                                                                "maxHeight": "100%"
                                                                            },
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "id": "5fdfbc9f824bae8aa6b078c0ec735a792db99aea43409fd3e4ba71503e02c711",
                                                                            "name": "author-2",
                                                                            "xtype": "group",
                                                                            "properties": {
                                                                                "columnGap": 3,
                                                                                "rowGap": 3,
                                                                                "flexDirection": "column",
                                                                                "justifyContent": "flex-start",
                                                                                "alignItems": "flex-start",
                                                                                "display": "flex",
                                                                            },
                                                                            "children": [
                                                                                {
                                                                                    "id": "ce36fb6cd4aa598b79ee6adce49416230dfd9a97ed75eeb14e97a99532c633ac",
                                                                                    "name": "text-43",
                                                                                    "xtype": "group",
                                                                                    "properties": {
                                                                                        "fontSize": 9.75,
                                                                                        "fontWeight": "700",
                                                                                    },
                                                                                    "children": [
                                                                                        {
                                                                                            "id": "39950930cd53ec8ef55a0c98922c07f37fbf2e9ccd9efe8d91b4604676fadf2e",
                                                                                            "xtype": "label",
                                                                                            "properties": {
                                                                                                "content": "<p>Author Name</p>",
                                                                                                "maxWidth": "100%"
                                                                                            }
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "id": "ea92fdffc392d9d9833e26a60ffb5df270c37946cbcec6d92c3076d20b820e22",
                                                                                    "name": "text-44",
                                                                                    "xtype": "group",
                                                                                    "properties": {
                                                                                        "fontSize": 8.25,
                                                                                        "fontWeight": "400",
                                                                                    },
                                                                                    "children": [
                                                                                        {
                                                                                            "id": "138e7f2a913de2d2313d2fbe1dbd2bd065538a36f7d1f74e13c92ce896064ebc",
                                                                                            "xtype": "label",
                                                                                            "properties": {
                                                                                                "content": "<p>VP of Company</p>",
                                                                                                "maxWidth": "100%"
                                                                                            }
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "id": "c348e143907938999cef42cfcfeb16b9e8c8d42f1ef45db6fd7967009fbe6fb9",
                                            "name": "column-12",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 15,
                                                "rowGap": 15,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "410005a412e5842ce0b457266eb835db1fbfaeaf43b17e20d5a06aaedd577fe0",
                                                    "name": "content-10",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 18,
                                                        "rowGap": 18,
                                                        "backgroundColor": "rgb(245, 247, 250)",
                                                        "flexDirection": "column",
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "flex-start",
                                                        "paddingTop": 18,
                                                        "paddingRight": 18,
                                                        "paddingBottom": 18,
                                                        "paddingLeft": 18,
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "3b89ab658465899cad4fbc3f0568fcd50401dbdbf2cb41fafe82ea359fc3068d",
                                                            "name": "quote-3",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "columnGap": 54,
                                                                "rowGap": 54,
                                                                "flexDirection": "column",
                                                                "justifyContent": "flex-start",
                                                                "alignItems": "flex-start",
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "e8d8bbd9c5e10be78ced0589a794506b7f7ccead4b4447863c492b687b8dc797",
                                                                    "name": "text-41",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontSize": 10.5,
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "7b1bc4f157c5fcc09ea9810b72ed7139d047dea5f3fe85d9c9547e3cfe23ff12",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>“Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat.”</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "id": "42711df2b32ed36807dcabccdbc01f1183fb2291879dc959360e98e7c3337dda",
                                                                    "name": "author-3",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "columnGap": 6,
                                                                        "rowGap": 6,
                                                                        "justifyContent": "flex-start",
                                                                        "alignItems": "center",
                                                                        "display": "flex",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "e08287b79c3735eb3afe1ed2f6b352106d1e48ffd3914adea7b5b86c9897d22b",
                                                                            "name": "image-17",
                                                                            "xtype": "image",
                                                                            "properties": {
                                                                                "borderTopWidth": 0,
                                                                                "borderRightWidth": 0,
                                                                                "borderBottomWidth": 0,
                                                                                "borderLeftWidth": 0,
                                                                                "borderTopStyle": "initial",
                                                                                "borderRightStyle": "initial",
                                                                                "borderBottomStyle": "initial",
                                                                                "borderLeftStyle": "initial",
                                                                                "borderTopColor": "initial",
                                                                                "borderRightColor": "initial",
                                                                                "borderBottomColor": "initial",
                                                                                "borderLeftColor": "initial",
                                                                                "borderImageSource": "initial",
                                                                                "borderImageSlice": "initial",
                                                                                "borderImageWidth": "initial",
                                                                                "borderImageOutset": "initial",
                                                                                "borderImageRepeat": "initial",
                                                                                "maxWidth": "100%",
                                                                                "verticalAlign": "middle",
                                                                                "display": "flex",
                                                                                "objectFit": "cover",
                                                                                "borderTopLeftRadius": 75,
                                                                                "borderTopRightRadius": 75,
                                                                                "borderBottomRightRadius": 75,
                                                                                "borderBottomLeftRadius": 75,
                                                                                "flexDirection": "column",
                                                                                "justifyContent": "center",
                                                                                "alignItems": "center",
                                                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f9153118798502820d6826_Image.png",
                                                                                "alt": "",
                                                                                "maxHeight": "100%"
                                                                            },
                                                                            "children": []
                                                                        },
                                                                        {
                                                                            "id": "a43e65b4f125eaf381b0bc5523d9c6822fa2b318c61b9f4397350889235c617e",
                                                                            "name": "author-2",
                                                                            "xtype": "group",
                                                                            "properties": {
                                                                                "columnGap": 3,
                                                                                "rowGap": 3,
                                                                                "flexDirection": "column",
                                                                                "justifyContent": "flex-start",
                                                                                "alignItems": "flex-start",
                                                                                "display": "flex",
                                                                            },
                                                                            "children": [
                                                                                {
                                                                                    "id": "91d2897f1fe21f55fff55091e0e1fa7de42b80156870d1236f3c5e8ff9399900",
                                                                                    "name": "text-43",
                                                                                    "xtype": "group",
                                                                                    "properties": {
                                                                                        "fontSize": 9.75,
                                                                                        "fontWeight": "700",
                                                                                    },
                                                                                    "children": [
                                                                                        {
                                                                                            "id": "5cdcbbf584df3856c316721eb1e4f7c69fd823782325bb49ffc9f73c423adcdf",
                                                                                            "xtype": "label",
                                                                                            "properties": {
                                                                                                "content": "<p>Author Name</p>",
                                                                                                "maxWidth": "100%"
                                                                                            }
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "id": "c9a7cb79b18c212af234d22615d7cbe3c43569ff5f9bf488422aeeb80a59b276",
                                                                                    "name": "text-44",
                                                                                    "xtype": "group",
                                                                                    "properties": {
                                                                                        "fontSize": 8.25,
                                                                                        "fontWeight": "400",
                                                                                    },
                                                                                    "children": [
                                                                                        {
                                                                                            "id": "24a259fbb1d2b946ac4e3ef581ebfb6625a0760e475db73eeccc1452c0d1e16c",
                                                                                            "xtype": "label",
                                                                                            "properties": {
                                                                                                "content": "<p>VP of Company</p>",
                                                                                                "maxWidth": "100%"
                                                                                            }
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                "scale": "5px"
            },
            {
                "widget": {
                    "id": "f5293db9b8ac77bf5274d74e796ba54a5b9d2603cad73c4821309007eb2d319a",
                    "name": "testimonial-column-light",
                    "xtype": "group",
                    "properties": {
                        "columnGap": 30,
                        "rowGap": 30,
                        "flexDirection": "column",
                        "justifyContent": "flex-start",
                        "alignItems": "center",
                        "paddingTop": 48,
                        "paddingRight": 18,
                        "paddingBottom": 48,
                        "paddingLeft": 18,
                        "display": "flex",
                    },
                    "children": [
                        {
                            "id": "73c808a1236a415776c69c93344e0a13e59c79f3ebd12a8e5fe4cf3a647da681",
                            "name": "title-4",
                            "xtype": "group",
                            "properties": {
                                "textAlign": "center",
                                "fontWeight": "700",
                            },
                            "children": [
                                {
                                    "id": "80e3ca0fb202f64cbf4af86305b088468fc31e6830d4875d8888cf869e37ebc7",
                                    "xtype": "label",
                                    "properties": {
                                        "content": "<p>Testimonial section</p>",
                                        "maxWidth": "100%"
                                    }
                                }
                            ]
                        },
                        {
                            "id": "60b55beceff11c765409b97aa5be303ebec71700fc3c4a0f42eb137a000da0f1",
                            "name": "columns-10",
                            "xtype": "group",
                            "properties": {
                                "maxWidth": 720,
                                "columnGap": 24,
                                "rowGap": 24,
                                "justifyContent": "center",
                                "alignItems": "flex-start",
                                "display": "flex",
                                "small": {
                                    "flexDirection": "column",
                                    "alignItems": "center"
                                }
                            },
                            "children": [
                                {
                                    "id": "0d5725f58c5622e6795e37c6981f51602c44c83ec646197a93b296f39f9c0053",
                                    "name": "column-13",
                                    "xtype": "group",
                                    "properties": {
                                        "columnGap": 18,
                                        "rowGap": 18,
                                        "flexDirection": "column",
                                        "justifyContent": "flex-start",
                                        "alignItems": "flex-start",
                                        "display": "flex",
                                    },
                                    "children": [
                                        {
                                            "id": "c7ecb8a25fc5cc734a44261b0320f5a4137bc2b7efc10957c10c1720d0164987",
                                            "name": "content-11",
                                            "xtype": "group",
                                            "properties": {
                                                "borderTopWidth": 0.75,
                                                "borderRightWidth": 0.75,
                                                "borderBottomWidth": 0.75,
                                                "borderLeftWidth": 0.75,
                                                "borderTopStyle": "solid",
                                                "borderRightStyle": "solid",
                                                "borderBottomStyle": "solid",
                                                "borderLeftStyle": "solid",
                                                "borderTopColor": "rgb(228, 235, 243)",
                                                "borderRightColor": "rgb(228, 235, 243)",
                                                "borderBottomColor": "rgb(228, 235, 243)",
                                                "borderLeftColor": "rgb(228, 235, 243)",
                                                "borderImageSource": "initial",
                                                "borderImageSlice": "initial",
                                                "borderImageWidth": "initial",
                                                "borderImageOutset": "initial",
                                                "borderImageRepeat": "initial",
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "paddingTop": 18,
                                                "paddingRight": 18,
                                                "paddingBottom": 18,
                                                "paddingLeft": 18,
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "d8e2b359c69b8bc05520b374c013074130597dc514594c9c5b496c2103281b11",
                                                    "name": "quote-4",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 39,
                                                        "rowGap": 39,
                                                        "flexDirection": "column",
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "flex-start",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "a5095ad1fb4bdabb35ca53ff5adb3d332c97f9fe24739a703d5971e630c0f6ca",
                                                            "name": "text-41",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 10.5,
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "534a6ea49c7f6e8e3a6685308beb42e78b716f1b50a3d864b63a57ac1c7104f4",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>“Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat.”</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "428211274d74b72c4aecf009744d2930c829c2402387f2881d4c6c635edf5bbc",
                                                            "name": "author-4",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "columnGap": 6,
                                                                "rowGap": 6,
                                                                "backgroundColor": "rgb(245, 247, 250)",
                                                                "justifyContent": "flex-start",
                                                                "alignItems": "center",
                                                                "paddingTop": 18,
                                                                "paddingRight": 12,
                                                                "paddingBottom": 18,
                                                                "paddingLeft": 12,
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "8b6e1e58fa86cfe5c7da514eae41b6668a412c598a7510d56579eeebe02ecb56",
                                                                    "name": "image-18",
                                                                    "xtype": "image",
                                                                    "properties": {
                                                                        "borderTopWidth": 0,
                                                                        "borderRightWidth": 0,
                                                                        "borderBottomWidth": 0,
                                                                        "borderLeftWidth": 0,
                                                                        "borderTopStyle": "initial",
                                                                        "borderRightStyle": "initial",
                                                                        "borderBottomStyle": "initial",
                                                                        "borderLeftStyle": "initial",
                                                                        "borderTopColor": "initial",
                                                                        "borderRightColor": "initial",
                                                                        "borderBottomColor": "initial",
                                                                        "borderLeftColor": "initial",
                                                                        "borderImageSource": "initial",
                                                                        "borderImageSlice": "initial",
                                                                        "borderImageWidth": "initial",
                                                                        "borderImageOutset": "initial",
                                                                        "borderImageRepeat": "initial",
                                                                        "maxWidth": "100%",
                                                                        "verticalAlign": "middle",
                                                                        "display": "flex",
                                                                        "objectFit": "cover",
                                                                        "borderTopLeftRadius": 75,
                                                                        "borderTopRightRadius": 75,
                                                                        "borderBottomRightRadius": 75,
                                                                        "borderBottomLeftRadius": 75,
                                                                        "flexDirection": "column",
                                                                        "justifyContent": "center",
                                                                        "alignItems": "center",
                                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f9153118798502820d6826_Image.png",
                                                                        "alt": "",
                                                                        "maxHeight": "100%"
                                                                    },
                                                                    "children": []
                                                                },
                                                                {
                                                                    "id": "690527d6885b074424e3dd3f28b3c5fe80f67db8deaa3e0eb6444fdb2f96f900",
                                                                    "name": "info-4",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "columnGap": 3,
                                                                        "rowGap": 3,
                                                                        "flexDirection": "column",
                                                                        "justifyContent": "flex-start",
                                                                        "alignItems": "flex-start",
                                                                        "display": "flex",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "a72aebbc2c6b6643d320b02e97eaf7977a73b4a8b201d73d013edf61d976e0d2",
                                                                            "name": "text-45",
                                                                            "xtype": "group",
                                                                            "properties": {
                                                                                "fontSize": 15,
                                                                                "fontWeight": "700",
                                                                            },
                                                                            "children": [
                                                                                {
                                                                                    "id": "597d20cca7956e873b922121166d84d5dea65253d483a9af482048ad79b0f0e7",
                                                                                    "xtype": "label",
                                                                                    "properties": {
                                                                                        "content": "<p>Author Name</p>",
                                                                                        "maxWidth": "100%"
                                                                                    }
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "id": "7e5d0fd4ae8b4a86d1ea4871db2f8cefae20aed0c7810ba378d69c327a95c273",
                                                                            "name": "text-41",
                                                                            "xtype": "group",
                                                                            "properties": {
                                                                                "fontSize": 10.5,
                                                                                "fontWeight": "400",
                                                                            },
                                                                            "children": [
                                                                                {
                                                                                    "id": "07ada03693e7d5b88a9872a214cc9066d8fc01fe89646b2631ed74629d6a2636",
                                                                                    "xtype": "label",
                                                                                    "properties": {
                                                                                        "content": "<p>VP of Company</p>",
                                                                                        "maxWidth": "100%"
                                                                                    }
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "id": "7a0f0ded0f83219fb418c9f6b6fe2bd48f3a77a8bae93c7327e2ee9f12101048",
                                    "name": "column-13",
                                    "xtype": "group",
                                    "properties": {
                                        "columnGap": 18,
                                        "rowGap": 18,
                                        "flexDirection": "column",
                                        "justifyContent": "flex-start",
                                        "alignItems": "flex-start",
                                        "display": "flex",
                                    },
                                    "children": [
                                        {
                                            "id": "2e3847a4a32a164370fe1f258724180d531f6a0205ec1e08fc1de933fb5927b6",
                                            "name": "content-11",
                                            "xtype": "group",
                                            "properties": {
                                                "borderTopWidth": 0.75,
                                                "borderRightWidth": 0.75,
                                                "borderBottomWidth": 0.75,
                                                "borderLeftWidth": 0.75,
                                                "borderTopStyle": "solid",
                                                "borderRightStyle": "solid",
                                                "borderBottomStyle": "solid",
                                                "borderLeftStyle": "solid",
                                                "borderTopColor": "rgb(228, 235, 243)",
                                                "borderRightColor": "rgb(228, 235, 243)",
                                                "borderBottomColor": "rgb(228, 235, 243)",
                                                "borderLeftColor": "rgb(228, 235, 243)",
                                                "borderImageSource": "initial",
                                                "borderImageSlice": "initial",
                                                "borderImageWidth": "initial",
                                                "borderImageOutset": "initial",
                                                "borderImageRepeat": "initial",
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "paddingTop": 18,
                                                "paddingRight": 18,
                                                "paddingBottom": 18,
                                                "paddingLeft": 18,
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "3542067efe23dfdd2b3fb968d4ed26c6ccf75f3ee23c6a73c204aa8fe5d30984",
                                                    "name": "quote-4",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 39,
                                                        "rowGap": 39,
                                                        "flexDirection": "column",
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "flex-start",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "4df2f9901d8def059c8b6a3efcd9e065096bedbedfb5c995075dfacbd7049c54",
                                                            "name": "text-41",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 10.5,
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "26b58a20e54034ae4bff8d03a8b8c3a8d76445e91ce94a8cb4855c7291c06bc1",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>“Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat.”</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "1612809372041711ecf9e64b866bf5818c763472d46ef179bf5128b45db72208",
                                                            "name": "author-4",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "columnGap": 6,
                                                                "rowGap": 6,
                                                                "backgroundColor": "rgb(245, 247, 250)",
                                                                "justifyContent": "flex-start",
                                                                "alignItems": "center",
                                                                "paddingTop": 18,
                                                                "paddingRight": 12,
                                                                "paddingBottom": 18,
                                                                "paddingLeft": 12,
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "a3dcf2c9b85c3c8e6bb5d460787d9fd6dd4ce4258759fbfd6dd08ec554e392ea",
                                                                    "name": "image-18",
                                                                    "xtype": "image",
                                                                    "properties": {
                                                                        "borderTopWidth": 0,
                                                                        "borderRightWidth": 0,
                                                                        "borderBottomWidth": 0,
                                                                        "borderLeftWidth": 0,
                                                                        "borderTopStyle": "initial",
                                                                        "borderRightStyle": "initial",
                                                                        "borderBottomStyle": "initial",
                                                                        "borderLeftStyle": "initial",
                                                                        "borderTopColor": "initial",
                                                                        "borderRightColor": "initial",
                                                                        "borderBottomColor": "initial",
                                                                        "borderLeftColor": "initial",
                                                                        "borderImageSource": "initial",
                                                                        "borderImageSlice": "initial",
                                                                        "borderImageWidth": "initial",
                                                                        "borderImageOutset": "initial",
                                                                        "borderImageRepeat": "initial",
                                                                        "maxWidth": "100%",
                                                                        "verticalAlign": "middle",
                                                                        "display": "flex",
                                                                        "objectFit": "cover",
                                                                        "borderTopLeftRadius": 75,
                                                                        "borderTopRightRadius": 75,
                                                                        "borderBottomRightRadius": 75,
                                                                        "borderBottomLeftRadius": 75,
                                                                        "flexDirection": "column",
                                                                        "justifyContent": "center",
                                                                        "alignItems": "center",
                                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f9153118798502820d6826_Image.png",
                                                                        "alt": "",
                                                                        "maxHeight": "100%"
                                                                    },
                                                                    "children": []
                                                                },
                                                                {
                                                                    "id": "aca4f184780ac79ea8395714d84bcb9c4d3d777eb02f8fe4f58344b85178f019",
                                                                    "name": "info-4",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "columnGap": 3,
                                                                        "rowGap": 3,
                                                                        "flexDirection": "column",
                                                                        "justifyContent": "flex-start",
                                                                        "alignItems": "flex-start",
                                                                        "display": "flex",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "c94b909c002207dc9a819231be613dee1cafde4cdc833dc0665befdbbcd7d6f8",
                                                                            "name": "text-45",
                                                                            "xtype": "group",
                                                                            "properties": {
                                                                                "fontSize": 15,
                                                                                "fontWeight": "700",
                                                                            },
                                                                            "children": [
                                                                                {
                                                                                    "id": "e79254901278a0b8ac1e5fc33e183004aadb57b123768062dd8cd02e3c3187bf",
                                                                                    "xtype": "label",
                                                                                    "properties": {
                                                                                        "content": "<p>Author Name</p>",
                                                                                        "maxWidth": "100%"
                                                                                    }
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "id": "b67e871d7076a87306457c5343f062ed8694c0984dbf0494e6792961fb07f50b",
                                                                            "name": "text-41",
                                                                            "xtype": "group",
                                                                            "properties": {
                                                                                "fontSize": 10.5,
                                                                                "fontWeight": "400",
                                                                            },
                                                                            "children": [
                                                                                {
                                                                                    "id": "dc9c98ccd531835ea62c1a96091a024231b6724d6885d4985d83ab42f375e7d5",
                                                                                    "xtype": "label",
                                                                                    "properties": {
                                                                                        "content": "<p>VP of Company</p>",
                                                                                        "maxWidth": "100%"
                                                                                    }
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "id": "8f166f7d0f584a86f05c669e16b0fce510601bf300b7038f70d12fcdc5b1a975",
                                    "name": "column-13",
                                    "xtype": "group",
                                    "properties": {
                                        "columnGap": 18,
                                        "rowGap": 18,
                                        "flexDirection": "column",
                                        "justifyContent": "flex-start",
                                        "alignItems": "flex-start",
                                        "display": "flex",
                                    },
                                    "children": [
                                        {
                                            "id": "2d3795d3be94fd849d7ee6132392472264a77282bad788782b6f8f5b450b75a5",
                                            "name": "content-11",
                                            "xtype": "group",
                                            "properties": {
                                                "borderTopWidth": 0.75,
                                                "borderRightWidth": 0.75,
                                                "borderBottomWidth": 0.75,
                                                "borderLeftWidth": 0.75,
                                                "borderTopStyle": "solid",
                                                "borderRightStyle": "solid",
                                                "borderBottomStyle": "solid",
                                                "borderLeftStyle": "solid",
                                                "borderTopColor": "rgb(228, 235, 243)",
                                                "borderRightColor": "rgb(228, 235, 243)",
                                                "borderBottomColor": "rgb(228, 235, 243)",
                                                "borderLeftColor": "rgb(228, 235, 243)",
                                                "borderImageSource": "initial",
                                                "borderImageSlice": "initial",
                                                "borderImageWidth": "initial",
                                                "borderImageOutset": "initial",
                                                "borderImageRepeat": "initial",
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "paddingTop": 18,
                                                "paddingRight": 18,
                                                "paddingBottom": 18,
                                                "paddingLeft": 18,
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "1c36c421ef30c482f7b4bacce4d222d95fb8a538b0bb045718b5cfd0e18d5982",
                                                    "name": "quote-4",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 39,
                                                        "rowGap": 39,
                                                        "flexDirection": "column",
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "flex-start",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "4b1f86d3299e7a56a060ef95fd720927b699b947215206d5a2dbbab4b85f98e7",
                                                            "name": "text-41",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 10.5,
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "b57a57c973abd07acf821f57b772f5d32bd8734f576e05a86012a2e93134aef9",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>“Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat.”</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "72cad2d166bc18d6193d12c9c4a8ea630f0401d22e522bffe2cb22ba2bbf0383",
                                                            "name": "author-4",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "columnGap": 6,
                                                                "rowGap": 6,
                                                                "backgroundColor": "rgb(245, 247, 250)",
                                                                "justifyContent": "flex-start",
                                                                "alignItems": "center",
                                                                "paddingTop": 18,
                                                                "paddingRight": 12,
                                                                "paddingBottom": 18,
                                                                "paddingLeft": 12,
                                                                "display": "flex",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "8afc3039cfa49c87ab593d4b57d039087d317a332d41d84c1c3521fa1dfed24a",
                                                                    "name": "image-18",
                                                                    "xtype": "image",
                                                                    "properties": {
                                                                        "borderTopWidth": 0,
                                                                        "borderRightWidth": 0,
                                                                        "borderBottomWidth": 0,
                                                                        "borderLeftWidth": 0,
                                                                        "borderTopStyle": "initial",
                                                                        "borderRightStyle": "initial",
                                                                        "borderBottomStyle": "initial",
                                                                        "borderLeftStyle": "initial",
                                                                        "borderTopColor": "initial",
                                                                        "borderRightColor": "initial",
                                                                        "borderBottomColor": "initial",
                                                                        "borderLeftColor": "initial",
                                                                        "borderImageSource": "initial",
                                                                        "borderImageSlice": "initial",
                                                                        "borderImageWidth": "initial",
                                                                        "borderImageOutset": "initial",
                                                                        "borderImageRepeat": "initial",
                                                                        "maxWidth": "100%",
                                                                        "verticalAlign": "middle",
                                                                        "display": "flex",
                                                                        "objectFit": "cover",
                                                                        "borderTopLeftRadius": 75,
                                                                        "borderTopRightRadius": 75,
                                                                        "borderBottomRightRadius": 75,
                                                                        "borderBottomLeftRadius": 75,
                                                                        "flexDirection": "column",
                                                                        "justifyContent": "center",
                                                                        "alignItems": "center",
                                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f9153118798502820d6826_Image.png",
                                                                        "alt": "",
                                                                        "maxHeight": "100%"
                                                                    },
                                                                    "children": []
                                                                },
                                                                {
                                                                    "id": "b9b019cf2ff341d6d9311e440ede5cb6fb91d9cbd44fbd5a1bd00b462d60230c",
                                                                    "name": "info-4",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "columnGap": 3,
                                                                        "rowGap": 3,
                                                                        "flexDirection": "column",
                                                                        "justifyContent": "flex-start",
                                                                        "alignItems": "flex-start",
                                                                        "display": "flex",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "ff7390a2304fd3fc852e86d315f3978ccbc46b561ab2244d4612ed36cf31be37",
                                                                            "name": "text-45",
                                                                            "xtype": "group",
                                                                            "properties": {
                                                                                "fontSize": 15,
                                                                                "fontWeight": "700",
                                                                            },
                                                                            "children": [
                                                                                {
                                                                                    "id": "e917e6ba070005154eb65e312548bc3863513adba69b35efafeba340bb4eee9c",
                                                                                    "xtype": "label",
                                                                                    "properties": {
                                                                                        "content": "<p>Author Name</p>",
                                                                                        "maxWidth": "100%"
                                                                                    }
                                                                                }
                                                                            ]
                                                                        },
                                                                        {
                                                                            "id": "12a4eb36deab3038cfcb33dbbc80a8df234257b2531fcdb7be8d75cbf3b5a0b7",
                                                                            "name": "text-41",
                                                                            "xtype": "group",
                                                                            "properties": {
                                                                                "fontSize": 10.5,
                                                                                "fontWeight": "400",
                                                                            },
                                                                            "children": [
                                                                                {
                                                                                    "id": "87565fe0469a93b2e0a3a6299c37246f26e77b9f715664ae26ce232666000379",
                                                                                    "xtype": "label",
                                                                                    "properties": {
                                                                                        "content": "<p>VP of Company</p>",
                                                                                        "maxWidth": "100%"
                                                                                    }
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                "scale": "5px"
            },
            {
                "widget": {
                    "id": "63941973430537addb47705fbf1334cb7322bbcdeff54f8895f8e3f0888c2a19",
                    "name": "testimonial-image-left",
                    "xtype": "group",
                    "properties": {
                        "columnGap": 60,
                        "rowGap": 60,
                        "justifyContent": "center",
                        "alignItems": "flex-start",
                        "paddingTop": 48,
                        "paddingRight": 18,
                        "paddingBottom": 48,
                        "paddingLeft": 18,
                        "display": "flex",
                    },
                    "children": [
                        {
                            "id": "486ae8751c4d44fa24e6d41ef47c75958fe967e55db2f8b2bff3be2bcdcd8b19",
                            "name": "small-columns-5",
                            "xtype": "group",
                            "properties": {
                                "maxWidth": 720,
                                "columnGap": 30,
                                "rowGap": 30,
                                "justifyContent": "flex-start",
                                "alignItems": "center",
                                "display": "flex",
                                "small": {
                                    "flexDirection": "column"
                                }
                            },
                            "children": [
                                {
                                    "id": "4f7e48669eb16f1343910660eb1288e18e445b03d3c6dbca94312f889afcf3fc",
                                    "name": "column-14",
                                    "xtype": "group",
                                    "properties": {
                                        "maxWidth": 240,
                                        "columnGap": 7.5,
                                        "rowGap": 7.5,
                                        "justifyContent": "flex-start",
                                        "alignItems": "flex-start",
                                        "display": "flex",
                                    },
                                    "children": [
                                        {
                                            "id": "e02e61494ac5f58f56aaeffac7c995bd73ad9076160d4094e13504cfc8aae202",
                                            "name": "image-wrapper-10",
                                            "xtype": "group",
                                            "properties": {
                                                "flexDirection": "column",
                                                "justifyContent": "center",
                                                "alignItems": "center",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "0765d2cec2126cda6ba0c35af0a4c47ba2b189445ce41617b97f6b885a880114",
                                                    "name": "image-19",
                                                    "xtype": "image",
                                                    "properties": {
                                                        "borderTopWidth": 0,
                                                        "borderRightWidth": 0,
                                                        "borderBottomWidth": 0,
                                                        "borderLeftWidth": 0,
                                                        "borderTopStyle": "initial",
                                                        "borderRightStyle": "initial",
                                                        "borderBottomStyle": "initial",
                                                        "borderLeftStyle": "initial",
                                                        "borderTopColor": "initial",
                                                        "borderRightColor": "initial",
                                                        "borderBottomColor": "initial",
                                                        "borderLeftColor": "initial",
                                                        "borderImageSource": "initial",
                                                        "borderImageSlice": "initial",
                                                        "borderImageWidth": "initial",
                                                        "borderImageOutset": "initial",
                                                        "borderImageRepeat": "initial",
                                                        "maxWidth": "100%",
                                                        "verticalAlign": "middle",
                                                        "display": "flex",
                                                        "objectFit": "cover",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f91547ead0a013cb6da36b_Image.png",
                                                        "alt": "",
                                                        "maxHeight": "100%"
                                                    },
                                                    "children": []
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "id": "e7b701f9e2d6d5a83022b2bed7502fd0e3b8182d00bb5f5d8aab77dfd9a14cdf",
                                    "name": "column-13",
                                    "xtype": "group",
                                    "properties": {
                                        "columnGap": 18,
                                        "rowGap": 18,
                                        "flexDirection": "column",
                                        "justifyContent": "flex-start",
                                        "alignItems": "flex-start",
                                        "display": "flex",
                                    },
                                    "children": [
                                        {
                                            "id": "c8397ec73248c16d1df158395e9b9559d0b03498cc0b769ead5e475d60138ed2",
                                            "name": "content-12",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 6,
                                                "rowGap": 6,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "d5e50828fbc0ab23ca4c836a453b9062ddf8dde8895402b4b29997e3e0645123",
                                                    "name": "title-5",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "fontWeight": "700",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "77336578abd51d88061b9bb765bc44b12ad60b24219bd9923a8dfb4bc1fe3f1b",
                                                            "xtype": "label",
                                                            "properties": {
                                                                "content": "<p>Testimonial introduction copy goes here, lorem ipsum dolor sit amet.</p>",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "58983d5bc011d9e497e0ca3b4904315cd27c35c740c63507637287205eb58bec",
                                                    "name": "description-6",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "fontWeight": "400",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "8c35f0f9f46a6834be3bff0495fadfa5986a80907246a667ccf2a75c1be90327",
                                                            "xtype": "label",
                                                            "properties": {
                                                                "content": "<p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse tincidunt sagittis eros. Quisque quis euismod lorem.”</p>",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "id": "608961b3de724e015f63b6e7ae25fbc7f814ad07168527b686ad6096d2ca37cd",
                                            "name": "author-5",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 3,
                                                "rowGap": 3,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "a134e0582a21888ee802b264bb32ea6e1f5ebef1d0914c1f4f666eae1667cfad",
                                                    "name": "text-46",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "fontSize": 18,
                                                        "fontWeight": "700",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "46a619b5c77e23738478471a0f53086ff966ad9223c8e208edc05b7dc1106384",
                                                            "xtype": "label",
                                                            "properties": {
                                                                "content": "<p>Author Name</p>",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "445152bda055c772ab2786b95f920c09be09ba81cb2e5411416fb99f7a5d426b",
                                                    "name": "description-6",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "fontWeight": "400",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "fb14f0c8fc3b68b6b1d45a753d5f91f92571fd68567647aae3201217682d21e2",
                                                            "xtype": "label",
                                                            "properties": {
                                                                "content": "<p>Co-Founder and CEO of Company</p>",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                "scale": "5px"
            },
            {
                "widget": {
                    "id": "881a434897c408f7c3b0f065ccb8afdb40f6e4fb11b525c7fb4a80e7aae57d69",
                    "name": "testimonial-stack",
                    "xtype": "group",
                    "properties": {
                        "columnGap": 60,
                        "rowGap": 60,
                        "justifyContent": "center",
                        "alignItems": "flex-start",
                        "paddingTop": 48,
                        "paddingRight": 18,
                        "paddingBottom": 48,
                        "paddingLeft": 18,
                        "display": "flex",
                    },
                    "children": [
                        {
                            "id": "7c180b71daa97baf18e8acdebfc41fcc96a1c975c3f7a56aea42197bebd0abe3",
                            "name": "small-container-5",
                            "xtype": "group",
                            "properties": {
                                "maxWidth": 720,
                                "columnGap": 18,
                                "rowGap": 18,
                                "backgroundColor": "rgb(245, 247, 250)",
                                "flexDirection": "column",
                                "justifyContent": "flex-start",
                                "alignItems": "center",
                                "paddingTop": 48,
                                "paddingRight": 31.5,
                                "paddingBottom": 48,
                                "paddingLeft": 31.5,
                                "display": "flex",
                            },
                            "children": [
                                {
                                    "id": "29f5849422720a6738f29b371b74d868100255d6962235b8c4c5706177df9340",
                                    "name": "content-wrapper",
                                    "xtype": "group",
                                    "properties": {
                                        "maxWidth": 435,
                                        "columnGap": 30,
                                        "rowGap": 30,
                                        "flexDirection": "column",
                                        "justifyContent": "flex-start",
                                        "alignItems": "flex-start",
                                        "display": "flex",
                                    },
                                    "children": [
                                        {
                                            "id": "731da9c015127627e4c2e37c5895c3a8dfeb991000bfe7121dfb846b9aea5dcf",
                                            "name": "content-13",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 12,
                                                "rowGap": 12,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "center",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "24882a5cd0add277b0be34ffd706d6ccfcc8a5bcad7f6bdd4b2a8b1928373492",
                                                    "name": "title-6",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "textAlign": "center",
                                                        "fontWeight": "700",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "d2acaa27f2c43f436727894e980e6e346817b5407901aa6e02473225766bcc5d",
                                                            "xtype": "label",
                                                            "properties": {
                                                                "content": "<p>Testimonial introduction copy goes here, lorem ipsum dolor sit amet.</p>",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "64d598c5a21a5d656eb5507bdcfcdf6f588cabbc29756c976dc6b5e04b6febe5",
                                                    "name": "description-7",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "textAlign": "center",
                                                        "fontWeight": "400",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "7d528c2c2e6a8cf65fe13c3146cba807602cc9b4c8ad38d454f78a49526914a7",
                                                            "xtype": "label",
                                                            "properties": {
                                                                "content": "<p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse tincidunt sagittis eros. Quisque quis euismod lorem.”</p>",
                                                                "maxWidth": "100%"
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "id": "f266faa234006aa682d5ed9dd321db3efb831c3de4936b9768f9692dfb586548",
                                            "name": "content-13",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 12,
                                                "rowGap": 12,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "center",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "8ca26ef29854efbe15666dad2142d053e9d54c1a2c496c17ace355314d3d5a04",
                                                    "name": "image-wrapper-11",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "7ad2b8c5e9810267543fd17dfb81bc5a1a0e14eb5a06644ccba64ddbdffdbdd5",
                                                            "name": "image-20",
                                                            "xtype": "image",
                                                            "properties": {
                                                                "borderTopWidth": 0,
                                                                "borderRightWidth": 0,
                                                                "borderBottomWidth": 0,
                                                                "borderLeftWidth": 0,
                                                                "borderTopStyle": "initial",
                                                                "borderRightStyle": "initial",
                                                                "borderBottomStyle": "initial",
                                                                "borderLeftStyle": "initial",
                                                                "borderTopColor": "initial",
                                                                "borderRightColor": "initial",
                                                                "borderBottomColor": "initial",
                                                                "borderLeftColor": "initial",
                                                                "borderImageSource": "initial",
                                                                "borderImageSlice": "initial",
                                                                "borderImageWidth": "initial",
                                                                "borderImageOutset": "initial",
                                                                "borderImageRepeat": "initial",
                                                                "maxWidth": "100%",
                                                                "verticalAlign": "middle",
                                                                "display": "flex",
                                                                "objectFit": "cover",
                                                                "flexDirection": "column",
                                                                "justifyContent": "center",
                                                                "alignItems": "center",
                                                                "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f91552dcb0aebf9e23c29b_Image.png",
                                                                "alt": "",
                                                                "maxHeight": "100%"
                                                            },
                                                            "children": []
                                                        }
                                                    ]
                                                },
                                                {
                                                    "id": "613667c20e70d8d5825c97857ee3bf87bf37b3152f943b01b34a0be85c03e70a",
                                                    "name": "info-5",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 3,
                                                        "rowGap": 3,
                                                        "flexDirection": "column",
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "center",
                                                        "display": "flex",
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "581eff3f7817e9e4bd7826ffb89aae8ee31c76c9d359436ec1ccd1c166a25730",
                                                            "name": "text-47",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 18,
                                                                "textAlign": "center",
                                                                "fontWeight": "700",                                                                
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "19b3886c998c728a89c75a9739f847946add2435e77beac89c5c4db3a4a8d641",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Author Name</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "378346df0e3707fd28fbd2b2a4244787ee33e02a947ad314d9b015c34cb6645b",
                                                            "name": "author-job",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "textAlign": "center",
                                                                "fontWeight": "400",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "a94ebe877ff6b2de8591551c4655d9ae2557e9ea3c1c52f864201d993d2d746a",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>Former VP of ads at Company</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                "scale": "5px"
            },
            {
                "widget": {
                    "id": "639f445eb5982444bebfc0896f1b1c0c367c9a48767559c20e24601bf0c265d1",
                    "name": "footer",
                    "xtype": "group",
                    "properties": {
                        "columnGap": 30,
                        "rowGap": 30,
                        "backgroundColor": "rgb(245, 247, 250)",
                        "flexDirection": "column",
                        "justifyContent": "flex-start",
                        "alignItems": "center",
                        "paddingTop": 48,
                        "paddingRight": 18,
                        "paddingBottom": 6,
                        "paddingLeft": 18,
                        "display": "flex",
                    },
                    "children": [
                        {
                            "id": "756121c34e0c5b1650bf3545a906c8cbde25b3116b7841e1da522a8106ac2cab",
                            "name": "columns-11",
                            "xtype": "group",
                            "properties": {
                                "maxWidth": 720,
                                "justifyContent": "space-between",
                                "alignItems": "flex-start",
                                "display": "flex",
                                "small": {
                                    "flexDirection": "column",
                                    "alignItems": "center"
                                }
                            },
                            "children": [
                                {
                                    "id": "7b9fca8d958fe29e065e022bc1d22bf13bd42a2be57686b55ac1871777ac3c0a",
                                    "name": "column-15",
                                    "xtype": "group",
                                    "properties": {
                                        "maxWidth": 240,
                                        "columnGap": 18,
                                        "rowGap": 18,
                                        "flexDirection": "column",
                                        "justifyContent": "flex-start",
                                        "alignItems": "flex-start",
                                        "display": "flex",
                                        "small": {
                                            "alignItems": "center"
                                        }
                                    },
                                    "children": [
                                        {
                                            "id": "93fda0d502e1bf87e9acf1a4740e89d0bb6c44176ea97b09cd07e7f20c3ddfa0",
                                            "name": "logo-wrapper-2",
                                            "xtype": "group",
                                            "properties": {
                                                "flexGrow": "0",
                                                "flexShrink": "1",
                                                "flexBasis": "auto",
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "paddingTop": 12,
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "544f14d9424764ea35491263fe779b4daddb816996afac694f21ffe2c25b1c29",
                                                    "name": "logo-3",
                                                    "xtype": "image",
                                                    "properties": {
                                                        "borderTopWidth": 0,
                                                        "borderRightWidth": 0,
                                                        "borderBottomWidth": 0,
                                                        "borderLeftWidth": 0,
                                                        "borderTopStyle": "initial",
                                                        "borderRightStyle": "initial",
                                                        "borderBottomStyle": "initial",
                                                        "borderLeftStyle": "initial",
                                                        "borderTopColor": "initial",
                                                        "borderRightColor": "initial",
                                                        "borderBottomColor": "initial",
                                                        "borderLeftColor": "initial",
                                                        "borderImageSource": "initial",
                                                        "borderImageSlice": "initial",
                                                        "borderImageWidth": "initial",
                                                        "borderImageOutset": "initial",
                                                        "borderImageRepeat": "initial",
                                                        "maxWidth": "100%",
                                                        "verticalAlign": "middle",
                                                        "display": "flex",
                                                        "objectFit": "cover",
                                                        "justifyContent": "center",
                                                        "alignItems": "center",
                                                        "src": "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f9156066b2464114291436_Logo.png",
                                                        "alt": "",
                                                        "maxHeight": "100%"
                                                    },
                                                    "children": []
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    "id": "eab80fe1b64b6a032696272e1778d93df78332da235d266ca13b2366bee2f87b",
                                    "name": "small-columns-6",
                                    "xtype": "group",
                                    "properties": {
                                        "maxWidth": 450,
                                        "columnGap": 24,
                                        "rowGap": 24,
                                        "justifyContent": "flex-start",
                                        "alignItems": "flex-start",
                                        "paddingTop": 12,
                                        "display": "flex",
                                        "small": {
                                            "flexDirection": "column",
                                            "alignItems": "center"
                                        }
                                    },
                                    "children": [
                                        {
                                            "id": "ffcefae1e4abeba31ab5414149a123d4fc6a574fb2b5a24a19ce39f661b7b681",
                                            "name": "column-13",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 18,
                                                "rowGap": 18,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "f042e4051995da92c6d206580ae52f4b5b61f32f46c44e7c37575e579e452470",
                                                    "name": "content-14",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 12,
                                                        "rowGap": 12,
                                                        "flexDirection": "column",
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "flex-start",
                                                        "display": "flex",
                                                        "small": {
                                                            "alignItems": "center"
                                                        }
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "efdcc640483d61d74ee8bd6e585ef1de0b9620d81e5b541e6135ffd846aa76e3",
                                                            "name": "text-48",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 10.5,
                                                                "fontWeight": "700",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "1cb9cc6e90344c7dd114f6f2c988f541a74afba61db7e67462cd230737d800b5",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>COMPANY</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "949ad6c0ac6359668bf65d34d68d441d00826d4c9ac7969477bd14fc160876c5",
                                                            "name": "footer-links",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "columnGap": 6,
                                                                "rowGap": 6,
                                                                "flexDirection": "column",
                                                                "justifyContent": "flex-start",
                                                                "alignItems": "flex-start",
                                                                "display": "flex",
                                                                "small": {
                                                                    "alignItems": "center"
                                                                }
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "9475b8301f11689bd626dad58440ec61260524296872a106bd6256091aa8a0bd",
                                                                    "name": "link",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "f1c43f1576be3befd4b4e5b940a73bd176e33beaecfba110d7f6910f577f1275",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>How it works</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "id": "14d6d8c58e7f015438a07fad0ae5f3a06c8038675220b3ed34ed4db6da4837e9",
                                                                    "name": "link",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "61a95a21790a358ad8da5b83350228efe5578d3810f7073380ee45ad52c85269",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>Pricing</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "id": "75e48a4865370a488cbdfa00371ef78968e39323731674ea929ce777fa33e77c",
                                                                    "name": "link",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "abef65b12e082de72a6077b9833053cdf4776a9f2495da79870d2b416c63f013",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>Demo</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "id": "05195fd1d941c6ffeabc734f44a3ad1c878528e17a55a3a35369ab44486d16fc",
                                            "name": "column-13",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 18,
                                                "rowGap": 18,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "b92b280ad23d9583a905807735984d355f54289eae5ba970914d3a8638466b64",
                                                    "name": "content-14",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 12,
                                                        "rowGap": 12,
                                                        "flexDirection": "column",
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "flex-start",
                                                        "display": "flex",
                                                        "small": {
                                                            "alignItems": "center"
                                                        }
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "0d91b4cc6b1e6b4110d0caf6f312accd84d01bf2d913313e1fa78f6a148c41b7",
                                                            "name": "text-48",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 10.5,
                                                                "fontWeight": "700",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "d427423452f2c0bd84bca187786162b696e65e2edeae307122131cdddeea8b62",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>RESOURCES</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "40a0bc9b168505393d65ad57ff10ee45424604e0a140769a4d6fc5e5a080ff35",
                                                            "name": "footer-links",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "columnGap": 6,
                                                                "rowGap": 6,
                                                                "flexDirection": "column",
                                                                "justifyContent": "flex-start",
                                                                "alignItems": "flex-start",
                                                                "display": "flex",
                                                                "small": {
                                                                    "alignItems": "center"
                                                                }
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "e0c45a45cbba35b58f5fcffe229b562a8b4707651b4ce5d0f74c8d60d9000e13",
                                                                    "name": "link",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "5481d2cc8579e5a9015dbff18ed9f06f7dbd5d034212919beccec568f7254aee",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>Blog post name goes here</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "id": "ea11026be8899b2737fad2a4984e4f2db66b6077b2947085ca62e490c302c76b",
                                                                    "name": "link",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "2f39ae1d3eb562fc816167cdba5e5270a0d62989be02e2648af5d1c1cba580d7",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>Blog post name goes here</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "id": "4f16ad550f58203e4b177786bcecc16df2432a74ad360841ff93e1a6d5a83156",
                                                                    "name": "link",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "7a898a0c90b8ba360dab72fb3ed741b484e634e91bb699d7a539aca8aba431d2",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>Blog post name goes here</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "id": "1cfa991dc3071435faf64ae3fb4e5bb042d7dfa6bff210453b5b7e6341d48e3f",
                                                                    "name": "link",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "90181cf5af4e37eabe261972e9a89a5fd9307faacb387a52f712ecc34e708c6f",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>See all resources</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            "id": "7e53afcd4c3d89a99f6d1c2858a70d3d9d10bd0ec09e09371af5a414ab6ed53d",
                                            "name": "column-13",
                                            "xtype": "group",
                                            "properties": {
                                                "columnGap": 18,
                                                "rowGap": 18,
                                                "flexDirection": "column",
                                                "justifyContent": "flex-start",
                                                "alignItems": "flex-start",
                                                "display": "flex",
                                            },
                                            "children": [
                                                {
                                                    "id": "46732ab9165dc4671be71730b0f4d716cd6f9a5a09f2bd6a2e0d636044e5c529",
                                                    "name": "content-14",
                                                    "xtype": "group",
                                                    "properties": {
                                                        "columnGap": 12,
                                                        "rowGap": 12,
                                                        "flexDirection": "column",
                                                        "justifyContent": "flex-start",
                                                        "alignItems": "flex-start",
                                                        "display": "flex",
                                                        "small": {
                                                            "alignItems": "center"
                                                        }
                                                    },
                                                    "children": [
                                                        {
                                                            "id": "73a172a050ed92df3a2b4ac20195e17f7728d1d64897fa91a343283201ac9988",
                                                            "name": "text-48",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "fontSize": 10.5,
                                                                "fontWeight": "700",
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "ccc913f4c539de5cfc2894438b760ec50da0f1f0134c864658a05b55f5948195",
                                                                    "xtype": "label",
                                                                    "properties": {
                                                                        "content": "<p>ABOUT</p>",
                                                                        "maxWidth": "100%"
                                                                    }
                                                                }
                                                            ]
                                                        },
                                                        {
                                                            "id": "c445c9d58f7ed39fd6f4c662882025c00637d8fa3abf0165982727f1d2836317",
                                                            "name": "footer-links",
                                                            "xtype": "group",
                                                            "properties": {
                                                                "columnGap": 6,
                                                                "rowGap": 6,
                                                                "flexDirection": "column",
                                                                "justifyContent": "flex-start",
                                                                "alignItems": "flex-start",
                                                                "display": "flex",
                                                                "small": {
                                                                    "alignItems": "center"
                                                                }
                                                            },
                                                            "children": [
                                                                {
                                                                    "id": "3d1ba18364e8b7ad7614aba916e8e620f9a3add37546aa31bc3aa09de9765035",
                                                                    "name": "link",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "84b0971890ca4000eaecd9f7952850b9e5653712d35512ad982addc61116a0d6",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>Terms & Conditions</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    "id": "40089e2bb2789974294c62567b4386a26378fbae26308cbfff574752e1a80fd7",
                                                                    "name": "link",
                                                                    "xtype": "group",
                                                                    "properties": {
                                                                        "fontWeight": "400",
                                                                    },
                                                                    "children": [
                                                                        {
                                                                            "id": "d0967df65f3fbbfc737690db494163a86420e54cb3491d246538e0eb52b919d7",
                                                                            "xtype": "label",
                                                                            "properties": {
                                                                                "content": "<p>Privacy Policy</p>",
                                                                                "maxWidth": "100%"
                                                                            }
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            "id": "b6f5b5165070a8792652654d8a924deb1873d7eaae9268f35dbbe9519988d63a",
                            "name": "copyright",
                            "xtype": "group",
                            "properties": {
                                "maxWidth": 705,
                                "columnGap": 24,
                                "rowGap": 24,
                                "justifyContent": "center",
                                "alignItems": "center",
                                "paddingTop": 12,
                                "paddingBottom": 12,
                                "display": "flex",
                                "boxShadow": null,
                            },
                            "children": [
                                {
                                    "id": "4b70571c7190e5da843a8e43d4d46f730b0191cdf1af260979f7e4f8ff95c6ae",
                                    "name": "text-49",
                                    "xtype": "group",
                                    "properties": {
                                        "fontSize": 10.5,
                                        "textAlign": "center",
                                        "fontWeight": "400"
                                    },
                                    "children": [
                                        {
                                            "id": "814b32de67b19d873de158ff457d396e99a4675fb8bcace294e5ef47d8d206ab",
                                            "xtype": "label",
                                            "properties": {
                                                "content": "<p>Copyright © 2022 Company name</p>",
                                                "maxWidth": "100%"
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                "scale": "5px"
            }
        ]
    }
];