import AssetsSetting from '../../components/Branding/AssetsSetting';
import { SEO } from '../../components/SEO'
import { AssetsWrapper } from './styles'

const Assets = () => {
    return (
        <AssetsWrapper>
            <SEO title="Assets" />
            <h1>Assets</h1>
            <AssetsSetting />
        </AssetsWrapper>
    );
}

export default Assets