import React, { FC } from 'react'
import styled from 'styled-components'
import { IAnalytics } from '../../../modules/IAnalitics'
import { ToggleButton } from '../../common/ToggleButton'

interface Props {
    title: string
    plugin: string
    enabled: boolean
    analytics: IAnalytics
    handleSettingsChange: (name: string, value: string | boolean, plugin: string) => void
}

const PluginCheckbox: FC<Props> = ({ title, plugin, enabled, handleSettingsChange }) => {
    return (
        <Container>
            <ToggleButton
                isToggled={enabled}
                onChange={(e) => handleSettingsChange('enabled', e.target.checked, plugin)}
            />
            <h3>{title}</h3>
        </Container>
    )
}

export default PluginCheckbox

const Container = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0;

    h3 {
        font-size: 14px;
        font-weight: 500;
        margin-left: 5px;
    }
`
