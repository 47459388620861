import { Slider } from "@mui/material";
import { useState } from "react";
import { useProperty } from "../../context/property";
import { IPropertyType } from "../property-type";
import { NumberInput } from "../../components/number-editor";

//
// An editor for number properties.
//
function PropertyNumberEditor() {

    const { property, propertyValue, setPropertyValue } = useProperty();

    return (
        <div
            className="flex flex-row items-center w-full"
            >
            <div 
                className="flex flex-row items-center"
                style={{
                    padding: "5px",
                    width: "80px",
                    height: "32px",
                    border: "1px solid #dfe2f2",
                    borderRadius: "8px",
                    marginRight: "6px",
                }}
                >
                <NumberInput
                    name={property.id}
                    value={propertyValue}
                    onChange={(name, value) => {
                        setPropertyValue(value);
                    }}
                    />
                {property.unit &&
                <div
                        style={{
                            fontSize: "12px",
                            color: "#3c4b61",
                            borderLeft: "1px solid #dfe2f2",
                            padding: "0 5px",
                            fontWeight: "bold",
                        }}
                    >
                        {property.unit}
                </div>
                }
            </div>

            {(typeof propertyValue === "number" && property.range) &&
                <div className="flex flex-grow px-2" >
                    <Slider
                        size="small"
                        min={property.range[0]}
                        max={property.range[1]}
                        step={property.range[2]}
                        value={propertyValue}
                        valueLabelDisplay="auto"
                        onChange={(event, newValue) => {
                            setPropertyValue(newValue);
                        }}
                    />
                </div>
            }
        </div>        
    );
}

const text: IPropertyType = {
    editor: () => <PropertyNumberEditor />,
};

export default text;