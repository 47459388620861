import { nanoid } from 'nanoid'
import { IUpload } from '../../../modules/widgets/forms/IUpload'

export class UploadModel implements IUpload {
    id = nanoid()
    name = 'upload button'
    type = 'upload'
    icon = '/icons/widgets/upload.svg'
    category = 'form'
    colourSettings = {
        'text colour': ['rgba(0,0,0,1)', '#FFA7E1', '#0426FF'],
        'border colour': ['rgba(0,0,0,1)', '#FFA7E1', '#0426FF'],
        'background colour': ['#f3f3f3', '#FFA7E1', '#0426FF'],
    }
    appearanceSettings = {
        font: {
            family: 'Poppins',
            size: 14,
        },
        width: 90,
        height: 0,
        alignment: 'center',
        border: true,
        borderRadius: true,
        margin: true,
        padding: true,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
    }
    settings = {
        fileType: 'image',
        multipleFiles: false,
        placeholder: 'placeholder text',
        required: false,
        name: 'upload',
        label: 'Upload Files',
    }
}
