import React, { FC, memo } from 'react'
import styled from 'styled-components';
import { useWidget } from 'widgets-base';

interface Props {
}

const AudioWidget: FC<Props> = () => {

    const { widget } = useWidget();
    const appearanceSettings = widget?.appearanceSettings
    const colorSettings = widget?.colourSettings
    const width = appearanceSettings?.width + '%'
    const height = appearanceSettings?.height + 'em'
    const src = widget?.settings?.src
    const align = appearanceSettings?.align
    const paddingTop = appearanceSettings?.paddingTop / 10 + 'em'
    const paddingBottom = appearanceSettings?.paddingBottom / 10 + 'em'
    const paddingLeft = appearanceSettings?.paddingLeft / 10 + 'em'
    const paddingRight = appearanceSettings?.paddingRight / 10 + 'em'
    const marginTop = appearanceSettings?.marginTop / 10 + 'em'
    const marginBottom = appearanceSettings?.marginBottom / 10 + 'em'
    const marginLeft = appearanceSettings?.marginLeft / 10 + 'em'
    const marginRight = appearanceSettings?.marginRight / 10 + 'em'

    const fontFamily = appearanceSettings?.font?.family || 'Poppins'
    const modFontFamily = fontFamily?.replace(/\s/g, '+')
    const fontUrl = appearanceSettings?.font?.url
    const fontSize = widget.appearanceSettings ? widget.appearanceSettings.font.size + 'px' : '14px'
    const color = Array.isArray(colorSettings?.labelColor)
        ? colorSettings?.labelColor[0]
        : colorSettings?.labelColor
    const weight = appearanceSettings?.font?.weight
    const letterSpacing = appearanceSettings?.font?.spacing + 'px' || '8px'
    const lineHeight = appearanceSettings?.font?.lineHeight || 1

    const autoPlay = widget?.settings?.autoPlay
    const loop = widget?.settings?.loop
    const muted = widget?.settings?.muted

    const label = widget?.settings?.label

    return (
        <AudioSection id={widget.id}>
            <style>
                {!fontUrl
                    ? `@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`
                    : `@font-face {
            font-family: '${fontFamily}';
            src: url('${fontUrl}');
            font-style: normal;
          }
          
          .header-text {
            font-family: '${fontFamily}';
          }`}
            </style>
            <Container
                width={width}
                height={height}
                fontFamily={fontFamily}
                fontSize={fontSize}
                color={color}
                align={align}
                paddingTop={paddingTop}
                paddingBottom={paddingBottom}
                paddingLeft={paddingLeft}
                paddingRight={paddingRight}
                marginTop={marginTop}
                marginBottom={marginBottom}
                marginLeft={marginLeft}
                marginRight={marginRight}
                weight={weight}
                letterSpacing={letterSpacing}
                lineHeight={lineHeight}
            >
                <figure>
                    <label>{label}</label>
                    <audio controls src={src} autoPlay={autoPlay} muted={muted} loop={loop}>
                        Your browser does not support the
                        <code>audio</code> element.
                    </audio>
                </figure>
            </Container>
        </AudioSection>
    )
}

export default memo(AudioWidget)

const AudioSection = styled.section`
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Container = styled.div<{
    width: string
    height: string
    fontFamily: string
    color: string
    align: string
    fontSize: string
    paddingTop: string
    paddingBottom: string
    paddingLeft: string
    paddingRight: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
    weight: number
    lineHeight: number
    letterSpacing: string
}>`
    position: relative;
    display: flex;
    flex-direction: column;
    width: ${(props) => props.width};
    padding-top: ${(props) => props.paddingTop};
    padding-bottom: ${(props) => props.paddingBottom};
    padding-left: ${(props) => props.paddingLeft};
    padding-right: ${(props) => props.paddingRight};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};

    figure {
        text-align: ${(props) => props.align};
    }

    audio {
        width: 100%;
        height: ${(props) => props.height};
        margin-top: 8px;
    }

    label {
        width: 100%;
        font-size: ${(props) => props.fontSize};
        font-family: ${(props) => props.fontFamily};
        color: ${(props) => props.color};
        font-weight: ${(props) => +props.weight};
        letter-spacing: ${(props) => props.letterSpacing};
        line-height: ${(props) => props.lineHeight};
    }
`
