import styled from 'styled-components'
import {FC, memo, useEffect, useRef, useState} from 'react'
import TabletMacOutlinedIcon from '@mui/icons-material/TabletMacOutlined'
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined'
import LaptopOutlinedIcon from '@mui/icons-material/LaptopOutlined'
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined'
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined'
import AddIcon from '@mui/icons-material/Add'
import {Popover, Tooltip} from '@mui/material'
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined'
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined'
import {Link} from 'react-router-dom'
import {RouteNames} from '../../../../router'
import { useRenderContext, IView } from 'widgets-base'
import { useCampaign } from '../../../../builder/context/campaign'

interface IProps {
}

const PreviewFooter: FC<IProps> = ({}: IProps) => {

    const { campaign, currentPageIndex, setCurrentPageIndex, addPage } = useCampaign();
    const { setRenderContext } = useRenderContext();

    const [showFooter, setShowFooter] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const wrapperRef = useRef<HTMLDivElement>(null)

    const handleOpenPopover = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClosePopover = () => {
        setAnchorEl(null)
    }

    const toggleShowFooter = () => {
        setShowFooter((prev) => !prev)
    }

    useEffect(() => {
        const handler = (event) => {
            if (!wrapperRef.current.contains(event.target)) {
                setShowFooter(false)
            }
        }
        document.addEventListener('mousedown', handler)
        return () => {
            document.removeEventListener('mousedown', handler)
        }
    }, [])

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    return (
        <FooterSettings ref={wrapperRef}>
            <FooterContent showFooter={showFooter}>
                {showFooter ? (
                    <button onClick={toggleShowFooter}>
                        <KeyboardArrowDownOutlinedIcon fontSize="small"/>
                    </button>
                ) : (
                    <button onClick={toggleShowFooter}>
                        <KeyboardArrowUpOutlinedIcon fontSize="small"/>
                    </button>
                )}

                <div className="details">
                    <div className="pages_section">
                        {showFooter && <h2>Pages</h2>}
                        <PagesContainer showPages={showFooter}>
                            {campaign?.views?.map((view: IView, index: number) => (
                                <ViewItem
                                    key={view.id}
                                    showPages={showFooter}
                                    selectedPage={index === currentPageIndex}
                                    onClick={() => setCurrentPageIndex(index)}
                                >
                                    <ViewWidgets>
                                        {view?.widgets?.map((widget) => (
                                            <div key={widget.id}></div>
                                        ))}
                                    </ViewWidgets>
                                    <p>{index + 1}</p>
                                </ViewItem>
                            ))}
                            <AddViewButton showPages={showFooter} onClick={addPage}>
                                <AddIcon fontSize="large"/>
                            </AddViewButton>
                        </PagesContainer>
                    </div>
                </div>

                <FooterInfo>
                    <div className="campaign_info">
                        <ProjectName>{campaign.campaignName}</ProjectName>
                        <FoterItem marginRight="0" onClick={toggleShowFooter}>
                            <p>{campaign?.views?.length}</p>
                            <Tooltip
                                title={`${campaign?.views?.length || 1} pages in current project`}
                                arrow
                            >
                                <LayersOutlinedIcon/>
                            </Tooltip>
                        </FoterItem>
                        <FoterItem marginRight="0" onClick={addPage}>
                            <Tooltip title="Add new page" arrow>
                                <LibraryAddOutlinedIcon/>
                            </Tooltip>
                        </FoterItem>
                    </div>

                    {/* {resultCost.cost !== 0 && (
                        <Tooltip arrow placement="top-end" title="Your savings with widgets">
                            <div className="info">
                                {renderTimeSaved}
                                <p>
                                    Estimated cost:{' '}
                                    <span className="cost">${resultCost.cost} (USD)</span>
                                </p>
                            </div>
                        </Tooltip>
                    )} */}

                    <PreviewVariants>

                        <FoterItem 
                            marginRight="8px" 
                            onClick={() => setRenderContext({
                                screen: "small",
                                orientation: "portrait",
                            })}
                            >
                            <Tooltip title="Mobile portrait preview" arrow>
                                <PhoneIphoneOutlinedIcon/>
                            </Tooltip>
                        </FoterItem>

                        <FoterItem 
                            marginRight="8px" 
                            onClick={() => setRenderContext({
                                screen: "small",
                                orientation: "landscape",
                            })}
                            >
                            <Tooltip title="Mobile landscape preview" arrow>
                                <PhoneIphoneOutlinedIcon
                                    sx={{
                                        transform: "rotate(90deg)"
                                    }}
                                    />
                            </Tooltip>
                        </FoterItem>

                        <FoterItem 
                            marginRight="8px" 
                            onClick={() => setRenderContext({
                                screen: "medium",
                                orientation: "landscape",
                            })}
                            >
                            <Tooltip title="Tablet preview" arrow>
                                <TabletMacOutlinedIcon/>
                            </Tooltip>
                        </FoterItem>
                        <FoterItem
                            marginRight="8px" 
                            onClick={() => setRenderContext({
                                screen: "large",
                                orientation: "landscape",
                            })}
                            >
                            <Tooltip title="Laptop preview" arrow>
                                <LaptopOutlinedIcon/>
                            </Tooltip>
                        </FoterItem>

                        <div className="headerDivider"></div>

                        <FoterItem marginRight="8px" onClick={handleOpenPopover}>
                            <Tooltip title="How does it work?" arrow>
                                <HelpOutlineOutlinedIcon/>
                            </Tooltip>
                        </FoterItem>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClosePopover}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                        >
                            <DocsComponent>
                                <Link to={RouteNames.DOC} target="_blank">
                                    <FindInPageOutlinedIcon/> Learn documentation
                                </Link>
                                <a
                                    href="https://www.youtube.com/@deployableco7405/featured"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <OndemandVideoOutlinedIcon/> Watch video on YouTube
                                </a>
                            </DocsComponent>
                        </Popover>
                    </PreviewVariants>
                </FooterInfo>
            </FooterContent>
        </FooterSettings>
    )
}

export default memo(PreviewFooter)

export const PagesContainer = styled.div<{
    showPages: boolean
}>`
    display: flex;

    & > div:not(:last-child) {
        margin-right: 15px;
    }
`


export const FooterSettings = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 100%;
    bottom: 0;
    background-color: transparent;
    z-index: 3000;
`
export const FooterInfo = styled.div`
    width: 100%;
    padding: 5px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
        font-size: 15px;
        font-weight: bold;
    }

    .campaign_info {
        display: flex;
        align-items: center;
    }
`
export const FooterContent = styled.div<{
    showFooter: boolean
}>`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > button {
        background: #fff;
        margin: auto;
        transform: perspective(120px) rotateX(-45deg) rotateX(90deg);
        width: 100px;
        height: 20px;
        outline: none;
        border: none;
        position: absolute;
        top: -17px;
        cursor: pointer;
        box-shadow: -2px -1px 2px rgba(0, 0, 0, 0.2), 2px -1px 2px rgba(0, 0, 0, 0.2);
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        z-index: 12;
    }

    .details {
        width: 100%;
        display: flex;
        align-items: center;
        transition: all 0.15s ease-in-out;
        width: inherit;
        height: ${(props) => (props.showFooter ? '120px' : '2px')};
        background-color: #fff;
        box-shadow: 2px -1px 2px rgba(0, 0, 0, 0.2);

        .pages_section {
            width: 50%;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            padding: 0 15px;
            z-index: 10;
            overflow-x: auto;

            & > h2 {
                font-size: 12px;
                font-weight: bold;
                padding: 10px 0 5px 0;
            }
        }

        .popups_section {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            padding: 0 15px;
            z-index: 10;
            width: 50%;
            border-left: 1px solid #cacaca;
            overflow-x: auto;

            & > h2 {
                font-size: 12px;
                font-weight: bold;
                padding: 10px 0 5px 0;
            }
        }
    }

    .info {
        display: flex;
        gap: 10px;
        p {
            font-size: 13px;
        }
        .time {
            color: #ff840a;
            font-size: 16px;
            font-weight: bold;
        }
        .cost {
            color: #5df599;
            font-size: 16px;
            font-weight: bold;
        }
    }
`
export const ProjectName = styled.span`
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 10px;
    padding-right: 15px;
`
export const FoterItem = styled.div<{
    marginRight: string
    disabled?: boolean
}>`
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    padding: 5px;
    border-radius: 10px;
    margin-right: ${(props) => props.marginRight};
    &:hover {
        background-color: #d3d2d2;
    }
    pointer-events: ${(props) => props.disabled ? 'none' : 'auto'}
`

export const PreviewVariants = styled.div`
    display: flex;
    align-items: center;

    .headerDivider {
        border-left: 1px solid #b1b1b1;
        height: 20px;
        margin-right: 15px;
    }
`

export const ViewItem = styled.div<{
    showPages: boolean
    selectedPage?: boolean
}>`
    width: 42px;
    transition: background-color 0.2s ease-in-out;
    height: ${(props) => (props.showPages ? '80px' : '0px')};
    border-radius: 10px;
    background-color: ${(props) => (props.selectedPage ? '#bbb4fd' : '#fff')};
    cursor: pointer;
    display: ${(props) => (props.showPages ? 'flex' : 'none')};
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    & > p {
        padding: 5px;
        font-weight: bold;
    }
`

export const AddViewButton = styled.div<{
    showPages: boolean
}>`
    width: 50px;
    height: ${(props) => (props.showPages ? '80px' : '0px')};
    border-radius: 10px;
    display: ${(props) => (props.showPages ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    border: 1px solid #9e9e9e;
    cursor: pointer;
`

export const ViewWidgets = styled.div`
    transition: all 0.5s ease-in-out;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid #bbb4fd;
    width: 100%;
    overflow: hidden;
    border-radius: 5px;
    background-color: #fff;
`


export const DocsComponent = styled.div`
    background-color: #fff;
    font-size: 16px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    a {
        display: flex;
        align-items: center;
        gap: 7px;
        color: #3c4b61;
        transition: all 0.2s ease-in-out;

        &:hover {
            transform: scale(1.05);
        }
    }
`