import { nanoid } from "nanoid";
import { IWidgetType, emToPt, getImageUrl, makeFullAssetUrl, makeStyledElement, pxToEm, stdElementAlignment, stdHeight, stdMargin, stdPadding, stdWidth, useAuth, useDragContext, usePageTheme, useRenderContext, useWidget } from "widgets-base";

const Div = makeStyledElement("div");

function Static() {

    const { widget, properties, expandedProperties } = useWidget();
    const { renderContext } = useRenderContext();
    const { makeAssetUrl } = useAuth();
    const { pageTheme } = usePageTheme();

    const coverImageUrl = getImageUrl(properties?.coverImage, renderContext.isStaticExport, makeAssetUrl);
    const hiddenImageUrl = getImageUrl(properties?.hiddenImage, renderContext.isStaticExport, makeAssetUrl);

    return (
        <Div
            data-type="scratchie@1"
            widgetId={widget.id}
            elementName="image"
            variantName={properties?.variant || "default"}
            pageTheme={pageTheme}
            properties={expandedProperties}
            renderContext={renderContext}
            style={{
                position: "relative",
                width: expandedProperties.width,
                height: expandedProperties.height,
                overflow: "hidden",
                userSelect: "none",
            }}
            >
            <img 
                className="hidden-image"
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    visibility: "hidden",
                }}
                src={hiddenImageUrl}
                />
            <img 
                className="cover-image"
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    visibility: "hidden",
                }}
                src={coverImageUrl}
                />
            <canvas 
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                }}
                >
            </canvas>
        </Div>
    );
}

function Editor() {

    const { widget, properties, expandedProperties } = useWidget();
    const { renderContext } = useRenderContext();
    const { makeAssetUrl } = useAuth();
    const { pageTheme } = usePageTheme();
    const { dragProps, setRef, onSelect } = useDragContext();

    const coverImageUrl = getImageUrl(properties?.coverImage, renderContext.isStaticExport, makeAssetUrl);

    return (
        <Div 
            {...dragProps}
            ref={setRef}
            onClick={onSelect}
            widgetId={widget.id}
            elementName="div"
            variantName={properties?.variant || "default"}
            pageTheme={pageTheme}
            properties={expandedProperties}
            renderContext={renderContext}
            style={{
                position: "relative",
                width: expandedProperties.width,
                height: expandedProperties.height,
                overflow: "hidden",
                userSelect: "none",
            }}
            >
            <img 
                id="cover-image"
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                }}
                src={coverImageUrl}
                />
        </Div>
    );
}

const widgetType: IWidgetType = {
    name: 'Scratchie',
    Static: Static,
    Preview: Static,
    Editor: Editor,
    Template: Static,
    collectAssets: (widget, assetIds, assetUrls) => {
        if (widget.properties?.coverImage?.assetId) {
            assetIds.add(widget.properties?.coverImage?.assetId);
        }
        else if (widget.properties?.coverImage?.url) {
            assetUrls.add(makeFullAssetUrl(widget.properties?.coverImage?.url));
        }

        if (widget.properties?.hiddenImage?.assetId) {
            assetIds.add(widget.properties?.hiddenImage?.assetId);
        }
        else if (widget.properties?.hiddenImage?.url) {
            assetUrls.add(makeFullAssetUrl(widget.properties?.hiddenImage?.url));
        }
    },
    template:  {
        id: nanoid(),
        xtype: 'scratchie@1',
        properties: {
            alignSelf: "center",
            coverImage: {
                url: "/images/widgets/scratch.gif",
            },
            hiddenImage: {
                url: "/images/meditation.png",
            },
            width: emToPt(pxToEm(300)),
            height: emToPt(pxToEm(300)),
        },
    },    
    properties: [
        {
            id: "coverImage",
            name: "Cover image",
            type: "image",
            required: true,
            defaultValue: "",
        },
        {
            id: "hiddenImage",
            name: "Hidden image",
            type: "image",
            required: true,
            defaultValue: "",
        },
    ],
    propertyCategories: [
        {
            name: "Placement",
            properties: [
                stdElementAlignment,
                stdPadding,
                stdMargin,
                stdWidth,
                stdHeight,
            ],
        },
    ],
};

export default widgetType;
