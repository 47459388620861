import {Divider} from '@mui/material'
import {ChangeEvent, FC} from 'react'
import {InputBox} from '../../../common/InputBox'
import {WidgetSettingContainer} from '../../../Design/WidgetSettingContainer'
import Integrations from '../../../EditMode/Integrations'
import AssetsSetting from '../../../EditMode/Settings/AssetsSetting'
import AddFormFieldSection from '../AddFormFieldSection'
import { useWidget, UserRoles, useAuth } from 'widgets-base'

interface Props {
}

const ProductGeneralSetting: FC<Props> = () => {

    const { widget, updateWidget } = useWidget();
    const { currentUser } = useAuth();

    const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
        updateWidget({
            name: event.target.value
        });
    }

    const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
        updateWidget({
            settings: {
                ...widget.settings,
                [event.target.name]: event.target.value,
            },
        });
    }

    return (
        <WidgetSettingContainer padding={0}>
            <AssetsSetting />
            <InputBox
                label="Form name"
                width="100%"
                name="name"
                onChange={handleChangeName}
                type="text"
                value={widget?.name}
            />
            <InputBox
                label="Form label"
                width="100%"
                name="label"
                onChange={onChangeValue}
                type="textarea"
                value={widget?.settings?.label}
            />
            {currentUser.role !== UserRoles.FREE && (
                <Integrations isIntegrate={widget?.settings?.isIntegrate} />
            )}
            <Divider/>
            <AddFormFieldSection />
        </WidgetSettingContainer>
    )
}

export default ProductGeneralSetting
