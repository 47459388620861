import React, { FC } from 'react'
import { IAnalytics } from '../../../modules/IAnalitics'
import PluginSettingsLayout from '../PluginSettingsLayout'
import PluginCheckbox from './PluginCheckbox'
import SettingInput from './SettingInput'

interface Props {
    analytics: IAnalytics
    handleSettingsChange: (name: string, value: string | boolean, plugin: string) => void
}

const GoogleAnalytics: FC<Props> = ({ analytics, handleSettingsChange }) => {
    if (!analytics || !analytics?.googleAnalytics) {
        analytics = {
            ...analytics,
            googleAnalytics: {
                enabled: false,
                trackingId: '',
            },
        }
    }

    return (
        <>
            <PluginCheckbox
                title="Google Analytics"
                plugin="googleAnalytics"
                analytics={analytics}
                enabled={analytics?.googleAnalytics?.enabled}
                handleSettingsChange={handleSettingsChange}
            />
            <PluginSettingsLayout enabled={analytics?.googleAnalytics?.enabled}>
                <SettingInput
                    placeholder="Measurement ID"
                    name="trackingId"
                    value={analytics?.googleAnalytics?.trackingId}
                    plugin="googleAnalytics"
                    handleSettingsChange={handleSettingsChange}
                />
            </PluginSettingsLayout>
        </>
    )
}

export default GoogleAnalytics
