import { ReactNode } from "react";
import styled from "styled-components";
import { useCampaign } from "./campaign";
import { IPageTheme, useAuth, IPageThemeContextHook, PageThemeContext, probe, extractPageStyle, css } from "widgets-base";

//
// The default hardcoded theme for the whole page.
//
export const defaultPageTheme: IPageTheme = {
    colors: {
        body: {
            default: {
                color: "black",
            },
        },
        elements: {
            button: {
                default: {
                    color: "white",
                    backgroundColor: "black",
                },
                primary: {
                    color: "white",
                    backgroundColor: "black",
                },
                secondary: {
                    color: "white",
                    backgroundColor: "black",
                },
                link: {
                    color: "white",
                    backgroundColor: "black",
                },
                upload: {
                    color: "white",
                    backgroundColor: "black",
                },
                submit: {
                    color: "white",
                    backgroundColor: "black",
                },
            },
            input: {
                default: {
                    borderColor: "gray",
                },
            },
            textarea: {
                default: {
                    borderColor: "gray",
                },
            },
        },
    },
    fonts: {
        body: {
        },

        elements: {
            h1: {
                default: {
                    fontSize: "3.5em",
                    fontWeight: "700",
                    lineHeight: "1.25em",
                },
            },
            h2: {
                default: {
                    fontSize: "2.75em",
                    lineHeight: "1.25em",
                },
            },
        },
    },
    structure: {
        elements: {
            button: {
                default: {
                    paddingTop: "1em",
                    paddingBottom: "1em",
                    paddingLeft: "2em",
                    paddingRight: "2em",
                },
            },
            input: {
                default: {
                    paddingTop: ".2em",
                    paddingBottom: ".2em",
                    paddingLeft: ".2em",
                    paddingRight: ".2em",
                    borderStyle: "solid",
                    borderLeftWidth: ".1em",
                    borderRightWidth: ".1em",
                    borderTopWidth: ".1em",
                    borderBottomWidth: ".1em",
                },
            },
            textarea: {
                default: {
                    paddingTop: ".2em",
                    paddingBottom: ".2em",
                    paddingLeft: ".2em",
                    paddingRight: ".2em",
                    borderStyle: "solid",
                    borderLeftWidth: ".1em",
                    borderRightWidth: ".1em",
                    borderTopWidth: ".1em",
                    borderBottomWidth: ".1em",
                },
            },
        },
    },
};

export interface IProps {
    //
    // The default page theme.
    //
    pageTheme?: IPageTheme;

    children: ReactNode | ReactNode[];
}

export function PageThemeProvider({ pageTheme, children }: IProps) {

    const { campaign, currentPageIndex } = useCampaign();
    const { makeAssetUrl } = useAuth();
    const curPage = campaign.views[currentPageIndex];

    if (!pageTheme) {
        throw new Error(`PageThemeProvider expected a page theme!`);
    }

    const value: IPageThemeContextHook = {
        pageTheme,
    };

    let backgroundImageUrl: string | undefined;
    if (curPage.backgroundImageId) {
        backgroundImageUrl = makeAssetUrl(curPage.backgroundImageId);
    }
    else {
        backgroundImageUrl = curPage.backgroundImageUrl;
    }

    function checkColorValue(color: string | string[]) { //todo: Is this still needed?
        if (Array.isArray(color)) {
            return color[0]
        } else {
            return color
        }
    }

    return (
        <PageThemeContext.Provider 
            value={value}
            >
            <Div 
                id="page"
                pageTheme={pageTheme}
                backgroundImageUrl={backgroundImageUrl}
                backgroundColour={curPage.backgroundColour && checkColorValue(curPage.backgroundColour)}
                >
                {children}
            </Div>
        </PageThemeContext.Provider>
    );
}

//
// Create a styled element injecting necessary styles.
//
const Div = styled.div<{ id: string, pageTheme: IPageTheme, backgroundImageUrl?: string, backgroundColour?: string }>`
    ${props => probe(props.id, "page-theme-style", css(extractPageStyle(props.pageTheme)))}

    ${props => props.backgroundImageUrl 
        && `
            background-image: url(${props.backgroundImageUrl});
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        `
    }

    ${props => props.backgroundColour
        && `
        background-color: ${props.backgroundColour};
        `
    }
`;
