import React from 'react'
import { IAnalytics } from '../../../modules/IAnalitics'
import PluginSettingsLayout from '../PluginSettingsLayout'
import PluginCheckbox from './PluginCheckbox'
import SettingInput from './SettingInput'

interface Props {
    analytics: IAnalytics
    handleSettingsChange: (name: string, value: string | boolean, plugin: string) => void
}

const GoogleTagManager = ({ analytics, handleSettingsChange }: Props) => {
    return (
        <>
            <PluginCheckbox
                title="Google Tag Manager"
                plugin="tagManagerSettings"
                enabled={analytics?.tagManagerSettings?.enabled}
                analytics={analytics}
                handleSettingsChange={handleSettingsChange}
            />
            <div className="pb-2" />
            <PluginSettingsLayout enabled={analytics?.tagManagerSettings?.enabled}>
                <div className="pb-2" />
                {/* <SettingInput
                    title="App Name"
                    label="appName"
                    value={analytics?.tagManagerSettings?.appName}
                    plugin="tagManagerSettings"
                    handleSettingsChange={handleSettingsChange}
                /> */}
                <SettingInput
                    placeholder="Container ID"
                    name="containerId"
                    value={analytics?.tagManagerSettings?.containerId}
                    plugin="tagManagerSettings"
                    handleSettingsChange={handleSettingsChange}
                />
                {/* <SettingInput
                    title="Data Layer Name"
                    label="dataLayerName"
                    value={analytics?.tagManagerSettings?.dataLayerName}
                    plugin="tagManagerSettings"
                    handleSettingsChange={handleSettingsChange}
                /> */}
            </PluginSettingsLayout>
        </>
    )
}

export default GoogleTagManager
