import { useParams } from 'react-router-dom';
import { CampaignContextProvider } from './context/campaign';
import BuilderLayout from '../v1/components/BuilderLayout'
import { RenderContextProvider } from 'widgets-base';
import { EditorContextProvider } from './context/editor-context';
import { BrandingContextProvider } from './context/branding-context';
import { RenderWidgetContextProvider } from './context/render-widget';

const BuilderPage = () => {

    const { campaignId } = useParams();

    return (
        <CampaignContextProvider 
            campaignId={campaignId} 
            enableSaving    
            >
            <RenderContextProvider>
                <BrandingContextProvider>
	                <EditorContextProvider>
                        <RenderWidgetContextProvider>
    	                    <BuilderLayout />
                        </RenderWidgetContextProvider>
	                </EditorContextProvider>
                </BrandingContextProvider>
            </RenderContextProvider>
        </CampaignContextProvider>
    )
}

export default BuilderPage;
