import { nanoid } from "nanoid";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import CampaignsService from "../../services/CampaignsService";
import { BackdropLoader } from "../../v1/components/common/BackdropLoader";
import { findTreeItem, removeTreeItem, setTreeItem, spliceTree, updateArray } from "../lib/utils";
import _ from "lodash";
import { IWidgetTemplate } from "../widget/widget-templates";
import { convertCampaign } from "../../lib/convert-campaign";
import { ICampaign, IWidget, IView, Direction, IUser, IWidgetAPI } from "widgets-base";
import { getWidgetType } from "../widget/widget-types";
import http from '../../lib/http';

//
// Workaround to remove duplicated widgets.
// These widgets appear to have been duplicated by the old drag and drop code.
//
export function dedupWidgets(campaign: ICampaign): ICampaign {
    return {
        ...campaign,
        views: campaign.views?.map(view => {

            const widgetSet = new Set<string>();

            return {
                ...view,
                widgets: view.widgets?.filter((widget: IWidget) => {
                    if (widgetSet.has(widget.id)) {
                        // console.log(`Removed copy of widget ${widget.id}`);
                        return false;
                    }

                    widgetSet.add(widget.id);
                    return true;
                }),
            };
        }),
    }
}

export interface IDraggingWidget { //todo: rename to IDraggableDetails.
    //
    // The type of widget being dragged.
    //
    type: "template" | "widget",

    //
    // The widget being dragged.
    //
    widget: IWidget;

    //
    // The path of the widget being dragged.
    //
    widgetPath?: number[];

    //
    // The widget template being dragged.
    //
    template?: IWidgetTemplate;
}

//
// Details of a UI component that can be dropped on.
//
export interface IDroppableDetails {
    //
    // The type of thing being dropped on.
    //
    type: "widget" | "gutter",

    //
    // The widget that is being dropped on.
    //
    widget?: IWidget;

    //
    // The parent (if any) of the widget dropped on.
    //
    parentWidget?: IWidget;

    //
    // Ancestors of the widget being dropped on.
    //
    ancestors?: IWidget[];

    //
    // The path to the widget in the hierarchy.
    //
    widgetPath: number[];

    //
    // The layout direction of the parent widget.
    //
    parentDirection: Direction;
}

export interface ICampaignHook {
    campaignId: string;
    campaign?: ICampaign;
    setCampaign: (campaign: ICampaign) => void;
    updateCampaign: (campaign: Partial<ICampaign>) => void;
    saveCampaign(): Promise<void>;

    //
    // Check if it's ok to exit the builder.
    // Returns true if ok, or false if the user should  save.
    //
    checkOkToExit(): Promise<boolean>;

    selectedWidget?: IWidget;

    //
    // The current "stack" of selected widets.
    // Used to populate the breadcrumb trail.
    //
    selectedWidgetStack: IWidget[];

    //
    // Path to the selected widget.
    //
    selectedWidgetPath: number[];

    //
    // Sets the path of the selected widget.
    //
    setSelectedWidgetPath(widgetPath: number[], widgets: IWidget[]): void;

    clearSelectedWidget: () => void;
    loading: boolean;
    currentPageIndex: number;
    setCurrentPageIndex: (pageIndex: number) => void;
    setPage(page: IView): void;
    updatePage(page: Partial<IView>): void;
    copyPage(): void;
    removePage(id: string): void;
    addPage(): void;
    setWidgets(widgets: IWidget[]): void;

    // 
    // Version to keep old code working.
    //
    setWidget_deprecated(newWidget: IWidget): void;

    //
    // Set the widget at the given path.
    //
    setWidget(widgetPath: number[], newWidget: IWidget): void;

    //
    // Updates the specified fields of the widget at the given path.
    //
    updateWidget(widgetPath: number[], originalWidget: IWidget, newFields: Partial<IWidget>): void;

    //
    // Removes all widgets from the page.
    //
    clearWidgets(): Promise<void>;

    //
    // Adds a widget to the page at the requested path.
    // TODO: Should have to pass in or return the list of widgets here. Need to stack up state changes correctly!
    //
    addWidget(widget: IWidget, widgetPath: number[], widgets: IWidget[]): Promise<IWidget[]>;

    //
    // Removes the request widget from the page.
    //
    removeWidget(widget: IWidget): Promise<void>;
    
    //
    // Assigns new ids to a tree of widgets.
    //
    assignNewIds(widget: IWidget): void;

    //
    // Duplicates the given widget in the page.
    //
    duplicateWidget(widget: IWidget): Promise<void>;
    
    saving: boolean;
    setSaving(saving: boolean): void;
    member?: IUser;
    setMember(member: IUser): void;
    modified: boolean;
    draggingWidget: IDraggingWidget | undefined;
    setDraggingWidget: (draggingWidget: IDraggingWidget | undefined) => void; //todo: This can be move to builder layout.
}

const CampaignContext = createContext<ICampaignHook>(null);

export interface IProps {
    children: ReactNode | ReactNode[];
    campaign?: ICampaign;
    pageIndex?: number;
    campaignId?: string;
    campaignName?: string;
    username?: string;
    loadLive?: boolean;
    enableSaving: boolean;

    //
    // Enables a custom save function.
    //
    saveFunction?: (campaign: ICampaign) => Promise<void>;
}

let nextId = 0;

export function CampaignContextProvider({ children, campaign: preloadedCampaign, pageIndex, campaignId, campaignName, username, loadLive, enableSaving, saveFunction }: IProps) {

    const id = nextId++;
    // console.log(`[${id}]: CampaignContextProvider ${campaignId}`);

    const [_campaign, _setCampaign] = useState<ICampaign>(preloadedCampaign);
    const [selectedWidgetPath, _setSelectedWidgetPath] = useState<number[]>([]);
    const [selectedWidgetStack, _setSelectedWidgetStack] = useState<IWidget[]>([]);
    const [selectedWidget, _setSelectedWidget] = useState<IWidget>(undefined);
    const [loading, setLoading] = useState(preloadedCampaign ? false : true);
    const [member, setMember] = useState<IUser>(undefined);
    const [currentPageIndex, setCurrentPageIndex] = useState<number>(pageIndex || 0);
    const [saving, setSaving] = useState<boolean>(false);
    const [modified, setModified] = useState<boolean>(false);
    const [draggingWidget, setDraggingWidget] = useState<IDraggingWidget | undefined>(undefined);

    //
    // Allows the user to cancel browser reload when their modifications are unsaved.
    //
    // https://vhudyma-blog.eu/show-alert-on-page-reload-and-browser-back-button-click/
    //
    function checkSavedBeforeUnload(event) {
        event.preventDefault();

        const message = "Leave/reload Deployable?\nChanges have not been saved yet.";
        event.returnValue = message;
        return message;
    }

    useEffect(() => {
        if (modified) {
            window.addEventListener("beforeunload", checkSavedBeforeUnload);
        }

        return () => {
            window.removeEventListener("beforeunload", checkSavedBeforeUnload);
        };
    }, [modified]);

    useEffect(() => {
        if (preloadedCampaign) {
            //
            // No need to load campaign.
            //
        }
        else if (loadLive) {

            if (campaignId) {
                console.log(`[${id}]: Loading live campaign ${campaignId}`);

                CampaignsService.getFreePublicCampaign(campaignId)
                    .then(({ data }) => {
                        console.log(`[${id}]: Campaign loaded: ${data._id}`);

                        if (data.redirect) {
                            window.location.replace(data.redirectUrl);
                            return;
                        }

                        setCampaign(convertCampaign(dedupWidgets(data)));

                        if (data.uid) {
                            CampaignsService.updateScans(data.uid, data.campaignId)
                        }

                        if (data.creatorUID) {
                            CampaignsService.updateScans(data.creatorUID, data.campaignId)
                        }

                        setLoading(false)

                        setTimeout(() => {
                            const tawk = (window as any).Tawk_API;
                            if (tawk) {
                                tawk.hideWidget();
                            }
                        }, 1000);
                    })
                    .catch((error) => {
                        setLoading(false);
                        console.error(`Failed to load campaign ${campaignId}`);
                        console.error(error);

                    });
            }
            else {
                console.log(`[${id}]: Loading live campaign ${username}/${campaignName}`);

                CampaignsService.getLiveCampaign(username, campaignName)
                    .then(({ data }) => {
                        console.log(`[${id}]: Campaign loaded ${data.campaignId}`);

                        if (data.redirect) {
                            window.location.replace(data.redirectUrl);
                            return;
                        }

                        setCampaign(convertCampaign(dedupWidgets(data)));

                        if (data.uid) {
                            CampaignsService.updateScans(data.uid, data.campaignId)
                        }

                        if (data.creatorUID) {
                            CampaignsService.updateScans(data.creatorUID, data.campaignId)
                        }

                        setLoading(false)
                        
                        setTimeout(() => {
                            const tawk = (window as any).Tawk_API;
                            if (tawk) {
                                tawk.hideWidget();
                            }
                        }, 1000);
                    })
                    .catch((error) => {
                        setLoading(false);
                        console.error(`Failed to load campaign ${username}/${campaignName}`);
                        console.error(error);
                    });
            }
        }
        else {
            console.log(`[${id}]: Loading campaign ${campaignId}`);

            CampaignsService.getCampaign(campaignId)
                .then(({ data }) => {
                    console.log(`[${id}]: Campaign loaded ${data._id}`);

                    setLoading(false);
                    setCampaign(convertCampaign(dedupWidgets(data)));
                })
                .catch(err => {
                    setLoading(false);
                    console.error(`[${id}]: Error getting campaign ${campaignId}`);
                    console.error(err);
                });
        }
    }, []);

    //
    // Internal function to save the campaign.
    //
    async function _saveCampaign(campaign: ICampaign): Promise<void> {

        if (saveFunction) {
            //
            // Custom save funciton is enabled.
            //
            await saveFunction(campaign);
            setModified(false);
            return;
        }

        if (!enableSaving) {
            throw new Error(`Saving was disabled.`);
        }

        console.log(`[${id}]: Saving campaign ${campaignId}`);
        console.log(campaign);

        if (campaign === undefined || _campaign === null) {
            throw new Error(`[${id}]: Can't save campaign from invalid state ${campaign}`);
        }

        await CampaignsService.saveCampaign(campaign);

        setModified(false);
    }

    //
    // Disabled auto save for now. Will make it optional again later.
    //
    // useEffect(() => {
    //     if (enableSaving) {

    //         if (loading) {
    //             console.log(`[${id}]: Campaign set while loading, not triggering autosave count down.`);
    //             return;
    //         }

    //         console.log(`[${id}]: Campaign updated, triggering autosave count down.`);

    //         //
    //         // Starts the count down to auto save.
    //         //
    //         const timeout = setTimeout(() => {
    //             console.log(`[${id}]: Autosave triggered.`);

    //             _saveCampaign(_campaign);
    //         }, 5000);

    //         return () => {
    //             console.log(`[${id}]: Canceled autosave.`);

    //             clearTimeout(timeout);
    //         };
    //     }
    // }, [_campaign]);


    //
    // Sets the campaign in state and marks it as modified.
    //
    function setCampaign(campaign: ICampaign) {

        // console.log(`[${id}]: Set campaign to:`);
        // console.log(JSON.stringify(campaign, null, 4));

        if (campaign === undefined || campaign === null) {
            throw new Error(`[${id}]: Attempted to set campaign to invalid value ${campaign}`);
        }

        //
        // Forces the campaign to be rerendered.
        //
        campaign = Object.assign({}, campaign, { lastUpdate: Date.now() });

        _setCampaign(campaign);

        if (!loading) {
            setModified(true);
        }
    }

    //
    // Saves the campaign.
    //
    async function saveCampaign(): Promise<void> {
        _saveCampaign(_campaign);
    }

    //
    // Check if it's ok to exit the builder.
    // Returns true if ok, or false if the user should  save.
    //
    async function checkOkToExit(): Promise<boolean> {
        if (!modified) {
            return true;
        }
        
        const message = "Do you want to leave the Deployable page builder?\nChanges have not been saved yet.";
        return window.confirm(message);
    }

    function updateCampaign(newFields: Partial<ICampaign>): void {
        setCampaign({
            ..._campaign,
            ...newFields,
        });
    }

    //
    // Sets the path of the selected widget.
    //
    function setSelectedWidgetPath(widgetPath: number[], widgets: IWidget[]): void {
        if (widgetPath.length === 0) {
            _setSelectedWidget(undefined);
            _setSelectedWidgetPath([]);
            _setSelectedWidgetStack([]);
            return;
        }

        const newWidgetStack: IWidget[] = [];
        let workingWidgets: IWidget[] = widgets;
        let latestWidget: IWidget | undefined = undefined;

        for (const widgetIndex of widgetPath) {
            if (workingWidgets[widgetIndex] === undefined) {
                throw new Error(`[${id}]: Invalid widget path ${widgetPath}`);
            }
            latestWidget = workingWidgets[widgetIndex];
            newWidgetStack.push(latestWidget);
            workingWidgets = latestWidget.children;
        }

        _setSelectedWidget(latestWidget);
        _setSelectedWidgetPath(widgetPath);
        _setSelectedWidgetStack(newWidgetStack);
    }

    function clearSelectedWidget(): void {
        setSelectedWidgetPath([], []);
    }

    function setPage(newPage: IView): void {
        const newPages = updateArray(_campaign.views, currentPageIndex, newPage);
        updateCampaign({
            views: newPages,
        });
    }

    function updatePage(newFields: Partial<IView>): void {
        const oldPage = _campaign.views[currentPageIndex];
        setPage({
            ...oldPage,
            ...newFields,
        });
    }

    function copyPage(): void {
        const newView = {
            ..._campaign.views[currentPageIndex],
            id: nanoid(),
        };

        updateCampaign({
            views: [..._campaign.views, newView],
        });
    }

    function removePage(id: string): void {
        const filteredPages = _campaign.views.filter((view: IView) => view.id !== id);
        setCurrentPageIndex(0)
        updateCampaign({
            views: filteredPages,
        });
    }

    function addPage(): void {
        updateCampaign({
            views: [
                ..._campaign.views,
                {
                    name: `Page ${_campaign.views.length + 1}`,
                    id: nanoid(),
                    backgroundColour: '#fff',
                    widgets: [],
                },
            ],
        })
    }

    function setWidgets(widgets: IWidget[]): void {
        updatePage({
            widgets,
        });
    }

    // 
    // Version to keep old code working.
    //
    function setWidget_deprecated(newWidget: IWidget): void {

        console.log(`Campaign context: Updating widget ${newWidget.id} to:`);
        console.log(newWidget);

        const widgets = _campaign.views[currentPageIndex].widgets;
        const result = findTreeItem(widgets, w => w.id === newWidget.id); //TODO: Could elminate this search by tracking the path of the widget.
        if (!result) {
            throw new Error(`Failed to find widget in tree! ${newWidget.id}`);
        }
        const newWidgets = setTreeItem(widgets, result.path, newWidget);
        updatePage({
            widgets: newWidgets,
        });
        
        if (selectedWidget && selectedWidget.id === newWidget.id) {
            setSelectedWidgetPath(result.path, newWidgets);
        }
    }

    //
    // Set the widget at the given path.
    //
    function setWidget(widgetPath: number[], newWidget: IWidget): void {

        //
        // Force the widget to be rerendered.
        //
        newWidget = Object.assign({}, newWidget, { lastUpdate: Date.now() });

        const widgets = _campaign.views[currentPageIndex].widgets;
        const newWidgets = setTreeItem(widgets, widgetPath, newWidget);

        updatePage({
            widgets: newWidgets,
        });

        if (selectedWidget && selectedWidget.id === newWidget.id) {
            setSelectedWidgetPath(widgetPath, newWidgets);
        }
    }

    //
    // Updates the specified fields of the widget at the given path.
    //
    function updateWidget(widgetPath: number[], originalWidget: IWidget, newFields: Partial<IWidget>): void {

        // console.log(`Campaign context: Updating widget ${originalWidget.id} to:`);
        // console.log(newFields);
        // console.log(widgetPath);

        setWidget(widgetPath, {
            ...originalWidget,
            ...newFields,
        });
    }

    //
    // Make an API the widget can use to talk to the backend.
    //
    function makeWidgetApi(widget: IWidget): IWidgetAPI {
        const api: IWidgetAPI = {
            //
            // Get data from the backend.
            // Automatically routed under the widgets endpoint.
            //
            async get<ResultT = any>(path: string): Promise<ResultT> {
                const fullRoute = `widgets/${widget.xtype}${path}`;
                const { data } = await http.get(fullRoute);
                return data;                
            },

            //
            // Post data to the backend.
            // Automatically routed under the widgets endpoint.
            //
            async post<ResultT = any, PayloadT = any>(path: string, payload: PayloadT): Promise<ResultT> {
                const fullRoute = `widgets/${widget.xtype}${path}`;
                const { data } = await http.post(fullRoute, payload);
                return data;
            },
        };
        
        return api;
    }

    //
    // Triggers the onAdded hook for a new widget.
    //
    async function triggerAddedHook(widget: IWidget): Promise<void> {
        if (!widget.xtype) {
            return;
        }
        const widgetType = getWidgetType(widget.xtype);
        if (widgetType?.onAdded) {
            function addAdditionalProperties(additionalProperties: any): void {
                console.log(`Adding additional properties to widget ${widget.id}:`);
                console.log(additionalProperties);

                widget.properties = {
                    ...widget.properties,
                    ...additionalProperties,
                };
            }

            await widgetType.onAdded(widget, _campaign, addAdditionalProperties, makeWidgetApi(widget));
        }
    }

    //
    // Adds a widget to the page at the requested path.
    //
    async function addWidget(widget: IWidget, widgetPath: number[], widgets: IWidget[]): Promise<IWidget[]> {

        console.log(`Adding widget ${widget.id} to path ${widgetPath}`);
        console.log(widget);

        //
        // Trigger the onAdded callback on the widget type.
        //
        await triggerAddedHook(widget);
        
        //
        // It's a template widget being dropped from the sidebar.
        // Splice in the new widget.
        //
        const newWidgets = spliceTree(
            widgets,
            widgetPath,
            widget                    
        );
        setWidgets(newWidgets);                

        return newWidgets;
    }

    //
    // Removes all widgets from the page.
    //
    async function clearWidgets(): Promise<void> {

        for (const widget of _campaign.views[currentPageIndex].widgets) {
            //
            // Trigger the onRemoved callback on the widget type.
            //
            await triggerRemovedHook(widget);
        }

        setWidgets([]);
        clearSelectedWidget();
    }

    //
    // Trigger the onRemoved callback on the widget type.
    //
    // TODO: This function doesn't need to be async now.
    //
    async function triggerRemovedHook(widget: IWidget): Promise<void> {
        if (!widget.xtype) {
            return;
        }

        const widgetType = getWidgetType(widget.xtype);
        if (widgetType?.onRemoved) {
            widgetType.onRemoved(widget, _campaign, makeWidgetApi(widget))
                .catch(error => {
                    console.error(`Error in onRemoved hook for widget ${widget.id} / ${widget.xtype}`);
                    console.error(error);
                });
        }
    }

    //
    // Removes the request widget from the page.
    //
    async function removeWidget(widget: IWidget): Promise<void> {

        console.log(`Campaign context: Removing widget ${widget.id}.`);

        clearSelectedWidget();

        //
        // Trigger the onRemoved callback on the widget type.
        //
        await triggerRemovedHook(widget);

        const widgets = _campaign.views[currentPageIndex].widgets;
        const [newWidgets] = removeTreeItem(widgets, w => w.id === widget.id);
        updatePage({
            widgets: newWidgets,
        });        
    }

    //
    // Assigns new ids to a tree of widgets.
    //
    function assignNewIds(widget: IWidget): void {
        
        widget.id = `widget-${nanoid()}`;

        if (widget.children) {
            for (const child of widget.children) {
                assignNewIds(child);
            }
        }
    }

    //
    // Duplicates the given widget in the page.
    //
    async function duplicateWidget(widget: IWidget): Promise<void> {

        const widgets = _campaign.views[currentPageIndex].widgets;
        const result = findTreeItem(widgets, w => w.id === widget.id);
        if (!result) {
            // No change.
            return;
        }

        const newWidget = _.cloneDeep(widget);
        assignNewIds(newWidget);

        //
        // Trigger the onAdded callback on the widget type.
        //
        await triggerAddedHook(newWidget);

        const newPath = updateArray(result.path, result.path.length - 1, result.path[result.path.length - 1] + 1);
        const newWidgets = spliceTree(widgets, newPath, newWidget);
        setWidgets(newWidgets);

        setSelectedWidgetPath(newPath, newWidgets);
    }

    const value: ICampaignHook = {
        campaignId,
        campaign: _campaign,
        setCampaign,
        updateCampaign,
        saveCampaign,
        checkOkToExit,
        selectedWidget,
        selectedWidgetStack,
        selectedWidgetPath,
        setSelectedWidgetPath,
        clearSelectedWidget,
        loading,
        currentPageIndex,
        setCurrentPageIndex,
        setPage,
        updatePage,
        copyPage,
        removePage,
        addPage,
        setWidgets,
        setWidget_deprecated,
        setWidget,
        updateWidget,
        addWidget,
        clearWidgets,
        removeWidget,
        assignNewIds,
        duplicateWidget,
        saving,
        setSaving,
        member,
        setMember,
        modified,
        draggingWidget,
        setDraggingWidget,
    };

    return (
        <CampaignContext.Provider
            value={value}
        >
            {loading
                ? <BackdropLoader />
                : children
            }
        </CampaignContext.Provider>
    );
}

export function useCampaign(): ICampaignHook {
    const context = useContext(CampaignContext);
    if (!context) {
        throw new Error(`Campaign context is not set! Add CampaignContextProvider to the component tree.`);
    }
    return context;
}

