import { cornersOfRectangle } from '@dnd-kit/core/dist/utilities/algorithms/helpers'
import React, { useEffect, useRef, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'

interface Props {
    label?: string
    width?: string
    height?: string
    size?: 'small' | 'medium' | 'large'
    value: string
    placeholder?: string
    name: string
    onChange: (name: string, value: string) => void
    error?: string
    onBlur?: (e: any) => void
    onKeyUp?: (e: React.KeyboardEvent) => void
    onFocus?: (e: any) => void
    maxLength?: number
    min?: number
    max?: number
    step?: number
    disabled?: boolean
    readOnly?: boolean
    required?: boolean
    helperText?: string
    defaultValue?: any
}

export const Input = ({
    label,
    size = 'medium',
    name,
    width,
    height,
    disabled,
    value,
    readOnly,
    required,
    helperText,
    step,
    min,
    onFocus,
    onChange,
    ...props
}: Props) => {

    const [ text, setText ] = useState<string>(value);
    const isInitialMount = useRef(true);

    useEffect(() => {
        setText(value);

    }, [value]);
    
    useEffect(() => {

        if (isInitialMount.current) {
            // Don't trigger the timeout on initial render.
            isInitialMount.current = false;
            return;
        }

        if (disabled || text === value) {
            // No need to update when disabled.
            // Or when text hasn't changed.
            return;
        }

        const timeout = setTimeout(() => {
            onChange(name, text);
        }, 300);

        return () => {
            clearTimeout(timeout);
        };
    }, [text, onChange]);    

    return (
        <InputWrapper width={width}>
            {label && <InputLabel>label</InputLabel>}
            <InputBoxStyle
                {...props}
                componentSize={size}
                width={width}
                value={text}
                disabled={disabled}
                readOnly={readOnly}
                step={step}
                required={required}
                onChange={(e) => setText(e.target.value)}
                onFocus={onFocus}
                error={props.error}
            />
            {helperText && <HelperText>{helperText}</HelperText>}
            {props.error && <p className="error-text">{props.error}</p>}
        </InputWrapper>
    )
}

const shake = keyframes`
  25% {transform: translateX(4px)}
  50% {transform: translateX(-4px)}
  75% {transform: translateX(4px)}
`

const InputWrapper = styled.div<{
    width: string
}>`
    display: flex;
    flex-direction: column;
    width: ${(props) => (props.width ? props.width : 'inherit')};
    position: relative;

    .error-text {
        margin: 0.2rem 0;
        color: red;
        position: absolute;
        bottom: -15px;
        left: 0;
    }
`

const InputLabel = styled.label`
    font-size: 14px;
    font-weight: bold;
    color: ${(props) => props.theme.text.secondary};
    margin-bottom: 4px;
`

const InputBoxStyle = styled.input.attrs((props) => ({}))<{
    componentSize: string
    width: string
    error: string
}>`
    background-color: #fafaff;
    border-radius: 10px;
    border: 1px solid ${(props) => (props.error ? 'red' : props.theme.lineColour)};
    color: ${(props) => props.theme.text.light};
    padding: ${(props) =>
        props.componentSize === 'small'
            ? '7px 12px'
            : props.componentSize === 'medium'
            ? '10px 12px'
            : '12px 12px'};
    display: flex;
    align-items: center;
    width: ${(props) => (props.width ? props.width : 'auto')};
    ::placeholder {
        color: ${(props) => props.theme.text.light};
    }
    font-size: 14px;
    animation: ${(props) =>
        props.error
            ? css`
                  ${shake} 300ms
              `
            : 'none'};
`

const TextAreaStyle = styled.textarea.attrs((props) => ({}))<{
    componentSize: string
    width: string
    height?: string
}>`
    background-color: #fafaff;
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.lineColour};
    color: ${(props) => props.theme.text.light};
    padding: ${(props) =>
        props.componentSize === 'small'
            ? '7px 12px'
            : props.componentSize === 'medium'
            ? '10px 12px'
            : '12px 12px'};
    display: flex;
    align-items: center;
    width: ${(props) => (props.width ? props.width : 'auto')};
    ::placeholder {
        color: ${(props) => props.theme.text.light};
    }
    font-size: 14px;
    resize: none;
    height: ${(props) => (props.height ? props.height : 'auto')};
`

const HelperText = styled.span`
    color: #f5393d;
    font-size: 14px;
    margin-top: 5px;
`
