import React, { memo } from 'react'
import styled from 'styled-components'
import { useForm } from '../../../../../../builder/context/form-state-old'
import { useWidget } from 'widgets-base';

interface Props {
}

const Input = ({}: Props) => {

    const { widget } = useWidget();
    const { getFieldValue, setFieldValue } = useForm();

    const settings = widget.settings
    const placeholder = settings.placeholder
    const type = settings.type
    const required = settings.required
    const name = settings.name

    const appearanceSettings = widget.appearanceSettings

    const width = appearanceSettings.width + '%'
    const font = appearanceSettings.font || { family: 'Open Sans' }
    const modFontFamily = font.family?.replace(/\s/g, '+')
    const fontSize = appearanceSettings.font.size ? appearanceSettings.font.size + 'px' : '14px'
    const fontUrl = appearanceSettings?.font?.url
    const paddingTop = appearanceSettings.paddingTop / 10 + 'em'
    const paddingBottom = appearanceSettings.paddingBottom / 10 + 'em'
    const paddingLeft = appearanceSettings.paddingLeft / 10 + 'em'
    const paddingRight = appearanceSettings.paddingRight / 10 + 'em'
    const marginTop = appearanceSettings.marginTop / 10 + 'em'
    const marginBottom = appearanceSettings.marginBottom / 10 + 'em'
    const marginLeft = appearanceSettings.marginLeft / 10 + 'em'
    const marginRight = appearanceSettings.marginRight / 10 + 'em'

    const borderTopLeftRadius = appearanceSettings.borderTopLeftRadius / 10 + 'em'
    const borderTopRightRadius = appearanceSettings.borderTopRightRadius / 10 + 'em'
    const borderBottomLeftRadius = appearanceSettings.borderBottomLeftRadius / 10 + 'em'
    const borderBottomRightRadius = appearanceSettings.borderBottomRightRadius / 10 + 'em'
    const borderTopWidth = appearanceSettings.borderTopWidth / 10 + 'em'
    const borderBottomWidth = appearanceSettings.borderBottomWidth / 10 + 'em'
    const borderLeftWidth = appearanceSettings.borderLeftWidth / 10 + 'em'
    const borderRightWidth = appearanceSettings.borderRightWidth / 10 + 'em'
    const borderWidth = `${borderTopWidth} ${borderRightWidth} ${borderBottomWidth} ${borderLeftWidth}`
    const alignment = appearanceSettings.alignment

    const textColour = Array.isArray(widget.colourSettings['text colour'])
        ? widget.colourSettings['text colour'][0]
        : widget.colourSettings['text colour']
    const borderColour = Array.isArray(widget.colourSettings['border colour'])
        ? widget.colourSettings['border colour'][0]
        : widget.colourSettings['border colour']
    const labelColour = Array.isArray(widget.colourSettings['label colour'])
        ? widget.colourSettings['label colour'][0]
        : widget.colourSettings['label colour']
    const backgroundColour = Array.isArray(widget.colourSettings['background colour'])
        ? widget.colourSettings['background colour'][0]
        : widget.colourSettings['background colour']

    const label = settings.label
    const minimumValue = settings.minimumValue
    const maximumValue = settings.maximumValue
    const stepValue = settings.stepValue
    const weight = Number(appearanceSettings?.font?.weight) || 400
    const letterSpacing = appearanceSettings?.font?.spacing + 'px' || '8px'
    const lineHeight = appearanceSettings?.font?.lineHeight || 1

    const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setFieldValue(name, e.target.value);
    }

    return (
        <Wrapper
            align={alignment}
            marginTop={marginTop}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            marginRight={marginRight}
            fontFamily={font.family}
            className="input_box"
        >
            <style>
                {!fontUrl
                    ? `@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`
                    : `@font-face {
                        font-family: '${font.family}';
                        src: url('${fontUrl}');
                        font-style: normal;
                      }
                      
                      .header-text {
                        font-family: '${font.family}';
                      }`}
            </style>
            <Container
                width={width}
                fontSize={fontSize}
                marginTop={marginTop}
                marginBottom={marginBottom}
                marginLeft={marginLeft}
                marginRight={marginRight}
                borderTopLeftRadius={borderTopLeftRadius}
                borderBottomLeftRadius={borderBottomLeftRadius}
                borderTopRightRadius={borderTopRightRadius}
                borderBottomRightRadius={borderBottomRightRadius}
            >
                {label && (
                    <label
                        style={{
                            width: '100%',
                            fontSize: fontSize,
                            marginBottom: '0.5em',
                            color: labelColour,
                            fontWeight: weight,
                            letterSpacing,
                            lineHeight,
                        }}
                        htmlFor={name}
                    >
                        {label}
                    </label>
                )}
                <input
                    onChange={handleTextChange}
                    name={name}
                    type={type}
                    style={{
                        color: textColour,
                        backgroundColor: backgroundColour,
                        borderWidth: borderWidth,
                        borderColor: borderColour,
                        paddingBottom,
                        paddingLeft,
                        paddingRight,
                        paddingTop,
                        letterSpacing,
                        lineHeight,
                    }}
                    placeholder={placeholder}
                    required={required}
                    min={minimumValue}
                    max={maximumValue}
                    step={stepValue}
                    value={getFieldValue(name) || ""}
                />
            </Container>
        </Wrapper>
    )
}

export default memo(Input)

const Wrapper = styled.div<{
    align: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
    fontFamily: string
}>`
    display: flex;
    align-items: ${(props) =>
        props.align === 'left' ? 'flex-start' : props.align === 'center' ? 'center' : 'flex-end'};
    justify-content: center;
    width: 100%;
    cursor: pointer;
    flex-direction: column;
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    font-family: ${(props) => props.fontFamily};
`
const Container = styled.div<{
    width: string
    fontSize: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
    borderTopLeftRadius: string
    borderBottomLeftRadius: string
    borderTopRightRadius: string
    borderBottomRightRadius: string
}>`
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: ${(props) => props.fontSize};
    width: ${(props) => props.width};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
    border-top-left-radius: ${(props) => props.borderTopLeftRadius};
    border-top-right-radius: ${(props) => props.borderTopRightRadius};
    border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
    input {
        width: 100%;
        border-radius: inherit;
        padding: 1em;
    }
`
