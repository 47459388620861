import { Divider } from '@mui/material'
import { PageContainer } from './styles'

const DocEditMode = () => {
    return (
        <PageContainer>
            <h1>Editing of widgets</h1>
            <span>Customization and settings of widgets</span>
            <Divider />
            <div className="content">
                <h2>Switching to edit mode</h2>
                <p>
                    While in the constructor, at the stage of dragging widgets, you do not have the
                    ability to edit them. To switch to editing widgets, there is a EDIT button on
                    the top panel of the canvas on the left.
                </p>
                <img className="image" src="/images/docs/edit.png" alt="edit" />
                <p>
                    You will be redirected to widget editing mode. A panel with a list of widgets
                    for editing will appear on the left side. All widgets on the canvas will in turn
                    appear blurry. The Back to constructor button will save your widget settings
                    progress and take you back to the Builder.
                </p>
                <img className="image" src="/images/docs/edit-mode.png" alt="edit mode" />
                <p>
                    When you select a widget for editing, it becomes clear on the canvas for a
                    visual preview of the changes. A panel with the main settings for the current
                    widget will open on the left side.
                </p>
                <img className="image" src="/images/docs/edit-mode1.png" alt="edit mode" />
                <Divider />
                <h2>Editing features</h2>
                <p>
                    Some widgets can be designed in edit mode. Widgets such as forms have dynamic
                    field additions. You can independently add the field that you need in an
                    unlimited number.
                </p>
                <img className="image" src="/images/docs/forms.gif" alt="forms build" />
                <p>
                    Upon completion of making changes and building the campaign, click on the Deploy
                    button to generate a QR code and a unique link to your campaign.
                </p>
            </div>
        </PageContainer>
    )
}

export default DocEditMode
