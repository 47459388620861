import { ChangeEvent, FC, useState } from 'react'
import styled from 'styled-components'
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

import { Button } from '../common/Button'
import SearchInput from '../common/SearchInput'
import { Toast } from '../../helpers/Toast'
import { IForms } from '../../modules/IForm'
import FormService from '../../../services/FormService'
import { RouteNames } from '../../../router'
import { ISubmission } from '../../modules/ISubmission'
import { useConfirm } from '../../../hooks/confirm'

interface Props {
    form: IForms
    refreshData: () => Promise<void>
    campaignId: string
    submissions: ISubmission[];
}

const createBlobForDowload = (data: string, formId: string) => {
    const blob = new Blob([data])
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    a.setAttribute('download', `deployable-${formId}.csv`)
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
}

const FormDetailsActions: FC<Props> = ({ refreshData, form, campaignId, submissions }) => {
    const [searchValue, setSearchValue] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [isExport, setIsExport] = useState<boolean>(false)
    const { isConfirmed } = useConfirm();
    const navigate = useNavigate()

    const handleChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value)
    }

    const downloadCsv = async () => {
        const formFieldsToDB = form.fields.map((field) => field.fieldName)
        setIsExport(true)
        try {
            const { data } = await FormService.dowloadCSV(campaignId, form.id, formFieldsToDB)
            createBlobForDowload(data, form.id)
        } catch (error) {
            console.log(error)
            Toast(error.response.data.message, 'error')
        }
        setIsExport(false)
    }

    const onRemoveForm = async () => {
        try {
            const confirmed = await isConfirmed(
                `Are you sure you want to delete this form? All data will be lost.`
            )

            if (confirmed) {
                const fieldIds = submissions.map((field) => field._id)
                setLoading(true)
                await FormService.deleteForm(campaignId, fieldIds)
                navigate(RouteNames.FORMS)
            }
        } catch (error) {
            console.log(error)
            Toast(error.response.data.message, 'error')
        }
        setLoading(false)
    }

    return (
        <Container>
            <div className="left__section">
                <SearchInput
                    value={searchValue}
                    onChange={handleChangeSearchValue}
                    placeholder="Search fields"
                    width="300px"
                />
                <Button type="button" variant="primary" onClick={refreshData}>
                    <CachedOutlinedIcon fontSize="small" /> Refresh data
                </Button>
            </div>
            <div className="right__section">
                <Button
                    type="button"
                    variant="orange"
                    loading={loading}
                    loadingText="Removing..."
                    onClick={onRemoveForm}
                >
                    <DeleteOutlineOutlinedIcon fontSize="small" /> Remove form
                </Button>
                <Button type="button" variant="invert" onClick={downloadCsv}>
                    <FileDownloadOutlinedIcon fontSize="small" />{' '}
                    {isExport ? 'Exporting...' : 'Export CSV'}
                </Button>
            </div>
        </Container>
    )
}

export default FormDetailsActions

const Container = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin: 15px 0;
    justify-content: space-between;

    button {
        margin: 0;
    }

    .left__section {
        display: flex;
        gap: 15px;
        .search__input {
            margin-bottom: 0;
        }
    }

    .right__section {
        display: flex;
        gap: 15px;
    }

    @media (max-width: 800px) {
        flex-direction: column;
        gap: 15px;
        button {
            width: 100%;
        }
        .left__section {
            flex-direction: column;
            .search__input {
                width: 100%;
                input {
                    width: 100%;
                }
            }
        }
        .right__section {
            flex-direction: column;
        }
    }
`
