import { FC, ChangeEvent, useState } from 'react'
import styled from 'styled-components'
import { Divider } from '@mui/material'
import { ToggleButton } from '../common/ToggleButton'
import { InputBox } from '../common/InputBox'
import { CustomModal } from '../common/CustomModal'
import { Toast } from '../../helpers/Toast'
import { DocsHint } from '../Docs/DocsHint'
import { RouteNames } from '../../../router'
import { useWidget } from 'widgets-base'

interface Props {
    isIntegrate: boolean
}

const Integrations: FC<Props> = ({ isIntegrate }) => {

    const { widget, setWidget } = useWidget();
    const [openTooltip, setOpenTooltip] = useState<boolean>(false)
    const [error, setError] = useState<string>('')

    const toggleOpenTooltip = () => {
        setOpenTooltip((prev) => !prev)
    }

    const validateSlackURL = (url: string) => url.includes('https://hooks.slack.com')

    const onChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
        const newWidget = {
            ...widget,
            settings: {
                ...widget.settings,
                [event.target.name]: event.target.checked,
            },
        }
        setWidget(newWidget);
    }

    const onChangeIntegrations = (event: ChangeEvent<HTMLInputElement>) => {
        const newWidget = {
            ...widget,
            settings: {
                ...widget.settings,
                integrates: {
                    ...widget.settings.integrates,
                    [event.target.name]: event.target.value,
                },
            },
        }
        setWidget(newWidget);
    }

    const validateSlackValue = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const isValid = validateSlackURL(value)
        if (!isValid && value) {
            Toast(`Invalid webhook URL. URL must have 'https://hooks.slack.com'`, 'error')
            setError('Invalid webhook URL')
        } else {
            setError('')
        }
    }

    return (
        <>
            <ToggleContainer style={{ marginBottom: 0 }}>
                <ToggleButton
                    isToggled={isIntegrate}
                    onChange={onChangeCheckbox}
                    name="isIntegrate"
                />
                <span>Integration with messengers</span>
            </ToggleContainer>
            {isIntegrate && (
                <SlackContainer>
                    <p>
                        Slack integration <DocsHint path={RouteNames.DOC_SLACK} />
                    </p>
                    <InputBox
                        width="100%"
                        name="slack"
                        onChange={onChangeIntegrations}
                        onBlur={validateSlackValue}
                        type="text"
                        error={error}
                        placeholder="Enter Webhook URL"
                        value={widget?.settings?.integrates?.slack}
                    />
                    <CustomModal
                        title="Slack tooltip"
                        visible={openTooltip}
                        toggleVisible={toggleOpenTooltip}
                    >
                        <TooltipContent>
                            <h1>Don't know where to get a Webhook URL?</h1>
                            <Divider />
                            <p>
                                Incoming webhooks are a simple way to post messages from external
                                sources into Slack. You can include message attachments to display
                                richly-formatted messages. You need to enable Incoming Webhooks for
                                your app at{' '}
                                <a target="_blank" href="https://api.slack.com" rel="noreferrer">
                                    api.slack.com
                                </a>
                                , select the appropriate form submission channel, and copy the
                                generated Webhook URL.
                                <br />
                                Don't have any apps created? No problem, with{' '}
                                <a
                                    target="_blank"
                                    href="https://api.slack.com/messaging/webhooks"
                                    rel="noreferrer"
                                >
                                    these
                                </a>{' '}
                                instructions you can do it in a couple of minutes.
                            </p>
                        </TooltipContent>
                    </CustomModal>
                </SlackContainer>
            )}
        </>
    )
}

export default Integrations

const SlackContainer = styled.div`
    display: flex;
    flex-direction: column;

    & > p {
        font-size: 14px;
        font-weight: bold;
        color: #3c4b61;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        position: relative;
        gap: 7px;

        img {
            margin-left: 10px;
            cursor: pointer;
        }
    }
`

const TooltipContent = styled.div`
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 500px;

    h1 {
        font-size: 18px;
        font-weight: bold;
    }

    p {
        font-size: 16px;
        line-height: 1.5;

        a {
            text-decoration: underline;
            font-weight: bold;
        }
    }
`

export const ToggleContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 18px;

    & > span {
        font-size: 14px;
        font-weight: bold;
        color: #3c4b61;
        margin-bottom: 4px;
        margin-left: 10px;
    }
`
