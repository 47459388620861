import React from "react";
import { StaticWidget } from "./static-widget";
import { IWidget } from "widgets-base";
import { WidgetContextProvider } from "../../../builder/context/widget";

export interface IStaticWidgetsProps {
    //
    // The group of widgets to render.
    //
    widgets?: IWidget[];
}

//
// Renders a list of widgets for static deployment.
//
export function StaticWidgets({ widgets }: IStaticWidgetsProps ) {

    return (
        <>
            {widgets?.map(widget => {
                return (
                    <WidgetContextProvider
                        key={widget.id} 
                        widget={widget}
                        widgetPath={undefined}
                        >
                        <StaticWidget />
                    </WidgetContextProvider>
                );
            })}
        </>
    );
}
