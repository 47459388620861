import { Divider } from '@mui/material'
import { Link } from 'react-router-dom'
import { RouteNames } from '../../../router'
import { PageContainer } from './styles'

const DocAssets = () => {
    return (
        <PageContainer>
            <h1>Gallery of images</h1>
            <span>
                The{' '}
                <Link to={RouteNames.BRAND}>
                    <strong>brand settings page</strong>
                </Link>{' '}
                is for customizing your brand designs. Create your own gallery.
                <br />
                All settings are available only for your account and do not apply to other users of
                the platform.
            </span>
            <Divider />
            <div className="content">
                <h2>Add image</h2>
                <p>
                    Create your own image gallery. All the images you upload here will be available
                    to you throughout the platform, from using this gallery in page setups to being
                    used in widgets and game giveaways. Add everything you need to achieve your
                    goals from a campaign logo to promotional images, promotions and more. To upload
                    an image, click the Upload new image button and select the appropriate image
                    from your computer.
                </p>
                <img className="image" src="/images/docs/assets.png" alt="add asset" />
                <h2>Delete image</h2>
                <p>
                    To remove an image from your gallery, hover over the corresponding image you
                    want to remove and click on it. The image will then be removed from the
                    database. Save your settings changes.
                </p>
                <img className="image" src="/images/docs/delete-asset.png" alt="delete asset" />
            </div>
        </PageContainer>
    )
}

export default DocAssets
