import { createContext, useContext } from "react";
import { ISignupForm, IUser } from "../model/IUser";

export interface IAuthHook {
    currentUser: IUser | undefined;
    setCurrentUser: React.Dispatch<React.SetStateAction<IUser>>;
    loading: boolean;
    signup(signupValues: ISignupForm): Promise<void>;
    login(email: string, password: string): Promise<void>;
    loginWithGoogle(): Promise<void>;
    signout(): Promise<void>;

    //
    // Sends a password reset email to the requested user.
    //
    sendPasswordResetEmail(email: string): Promise<void>;

    //
    // Verifies a user's password reset.
    //
    confirmPasswordReset(actionCode: string, newPassword: string): Promise<void>;
    //
    // Make an authenticated URL.
    // Attaches the token as a query parameter.
    //
    makeAuthenticatedUrl(path: string): string;

    //
    // Make a URL to retrieve an asset.
    //
    makeAssetUrl(id: string): string;

    //
    // Makes a URL to retreive a form upload.
    //
    makeFormUploadUrl(id: string): string;
};

export const AuthContext = createContext(null);

export function useAuth(): IAuthHook {
    const authContext = useContext(AuthContext);
    if (!authContext) {
        throw new Error(`useAuth must be used within a AuthContextProvider`);
    }
    return authContext;
}
