import { FC } from 'react'
import { useWidget } from 'widgets-base';

interface Props {
    editMode?: boolean
    preview?: string
}

const Hero2: FC<Props> = ({ editMode = false, preview }) => {

    const { widget } = useWidget();
    const backgroundColor = widget?.colourSettings?.['background color'][0]
    const fontFamily = widget?.appearanceSettings?.font?.family
    const fontUrl = widget?.appearanceSettings?.font?.url
    const modFontFamily = fontUrl?.replace(/\s/g, '+')

    const onRedirect = () => {
        const url = widget.settings.link
        !editMode && window.open(url, '_blank', 'noreferrer')
    }

    return (
        <div
            style={{
                backgroundColor,
                width: "100%",
            }}
            >
            <div
                className="mx-auto"
                style={{
                    maxWidth: "800px",
                    padding: ".8em",
                    fontSize: `${widget.appearanceSettings.font.size}px`,
                }}
                >
                <div
                    className="flex flex-col items-center"
                    >
                    <h1 
                        style={{
                            "WebkitTextSizeAdjust": "100%",
                            "WebkitFontSmoothing": "antialiased",
                            "boxSizing": "border-box",
                            "color": widget.colourSettings['pretitle color'][0], // TODO: Why aren't the other colors used?
                            "textAlign": widget.appearanceSettings.align as any,
                            // Can't change the font for different sections, so font is hardcoded for headings.
                            "fontFamily": "Matter, sans-serif",
                            "fontSize": `${widget.appearanceSettings.font.size * 3.33}px`,
                            "fontWeight": "600",
                            "lineHeight": "1",                        
                        }}
                        dangerouslySetInnerHTML={{ __html: widget.settings.text.pretitle }}
                        >
                    </h1>
                    
                    <h1 
                        style={{
                            "WebkitTextSizeAdjust": "100%",
                            "WebkitFontSmoothing": "antialiased",
                            "textAlign": widget.appearanceSettings.align as any,
                            "fontFamily": "Matter, sans-serif",
                            "fontSize": `${widget.appearanceSettings.font.size * 3.33}px`,
                            "fontWeight": "600",
                            "lineHeight": "1",
                            "boxSizing": "border-box",
                            "color": widget.colourSettings['title color'][0],
                        }}
                        dangerouslySetInnerHTML={{ __html: widget.settings.text.title }}
                        >
                    </h1>

                    <p
                        style={{
                            "WebkitTextSizeAdjust": "100%",
                            "fontWeight": "400",
                            "WebkitFontSmoothing": "antialiased",
                            "boxSizing": "border-box",
                            "marginBottom": ".8em",
                            "maxWidth": "600px",
                            "color": widget.colourSettings['content color'][0],
                            "textAlign": widget.appearanceSettings.align as any,
                            "marginTop": "1.8em",
                            "fontFamily": "Matter, sans-serif",
                            "fontSize": `${widget.appearanceSettings.font.size}px`,
                            "lineHeight": widget.appearanceSettings.font.lineHeight,
                            "marginLeft": "0"
                        }}
                        dangerouslySetInnerHTML={{ __html: widget.settings.text.content }}
                        >
                    </p>

                    <button
                        style={{
                            "WebkitTextSizeAdjust": "100%",
                            "fontWeight": "400",
                            "WebkitFontSmoothing": "antialiased",
                            "fontSize": `${widget.appearanceSettings.font.size}px`,
                            "boxSizing": "border-box",
                            "fontFamily": "DM Sans, sans-serif",
                            "transition": "color .3s",
                            "color": "#fff",
                            "lineHeight": "inherit",
                            "cursor": "pointer",
                            "border": "0",
                            "textDecoration": "none",
                            "backgroundColor": widget.colourSettings['button color'][0],
                            "borderRadius": "1.5em",
                            "marginTop": "1.5em",
                            "padding": "1.5em 3em"
                        }}
                        onClick={onRedirect}
                        dangerouslySetInnerHTML={{ __html: widget.settings.text.button }}
                        >
                    </button>

                    <img
                        className="p-4"
                        src={widget.settings.src}
                        alt={widget.name} 
                        style={{
                            width: `${widget?.appearanceSettings?.width}%`,
                        }}
                        >
                    </img>
                </div>
            </div>            
        </div>
    )
}

export default Hero2
