import { Divider } from '@mui/material'
import { Link } from 'react-router-dom'
import { RouteNames } from '../../../router'
import { ListItem, PageContainer } from './styles'

const About = () => {
    return (
        <PageContainer>
            <h1>About Deployable</h1>
            <span>
                We take inspiration from a number of platforms that have been here before us -
                helping take the complexity out of traditionally difficult tasks. The pathway that
                brands like Canva, Shopify, Squarespace, Wordpress and Webflow have achieved can
                give us confidences we are on the right path to help the ‘non coders’ of the world.
                Our journey is a big one, but as a team we will achieve it. Think big, work hard,
                break down walls and we will get to see our dream come true. Let’s get this journey
                started!
            </span>
            <Divider />
            <img className="image" src="/images/docs/deploy1.png" alt="about" />
            <div className="content">
                <h2>Our Vision</h2>
                <p>
                    A platform powering the ‘experience’ revolution; no code requirements and no
                    limitations.
                </p>
                <h2>Our Mission</h2>
                <p>
                    Simplifying complex technology into drag and drop widgets. Providing the world
                    the ability to create incredible experiences; made deployable in minutes.
                </p>
                <h2>Our Purpose</h2>
                <p>
                    We take the power out of the hands of developers and strive to put it into the
                    hands of everyone. Let the world be full of experiences.
                </p>
                <Divider />
                <h2>Our Values</h2>
                <ListItem>
                    <li>
                        We strive to simplify a complex world and make it easily obtained from
                        within our communities.
                    </li>
                    <li>Our genuine care and compassion for human beings is always apparent.</li>
                    <li>
                        When you achieve success, we achieve success. Without you, there's no us.
                    </li>
                    <li>We're here for everyone and anyone who wants to achieve more.</li>
                    <li>
                        We chase big goals with the ambition to deliver the best possible products.
                    </li>
                </ListItem>
                <Divider />
                <img className="image" src="/images/docs/deploy2.png" alt="about" />
                <h2>Areas of improvement</h2>
                <ListItem>
                    <li>Create a landing page which allows us to look like a leading company</li>
                    <li>Strengthen our code and existing foundational technology</li>
                    <li>UX Improvements of current platform/ UX planning and build for future</li>
                    <li>More visual improvements of platform with UI</li>
                    <li>
                        Cleaner on boarding process and education - Questionnaire and user profile
                    </li>
                    <li>Gamify the process of educating users how to learn the platform</li>
                    <li>Build templates to make the platform extremely easy to use</li>
                    <li>Easier flow for how to upload and select assets</li>
                    <li>A cleaner process for how a user edits a widget. Step by step guide?</li>
                    <li>More tutorial content around widgets and how they work</li>
                    <li>Create a suite of marketing collateral</li>
                </ListItem>
                <Divider />
                <h1>Main platform sections</h1>
                <h2>DASHBOARD</h2>
                <p>
                    The dashboard page is the main page where the created campaigns are placed. In
                    the upper part there are information blocks of some of the first steps on the
                    platform for quick navigation. Please note that some pages are not available in
                    the free subscription. You can learn more about subscriptions in the "Billing"
                    section.
                    <br />
                    All campaigns are divided into "drafts" and "published" tabs, which are already
                    deployed and have live links. The search bar will help you find the desired
                    campaign by name. All campaigns are sorted by creation date by default, i.e. the
                    most recently created campaign will be displayed first. A handy platform
                    navigation bar is located on the left side and can be expanded to display page
                    titles. The navigation bar itself consists of several sections: - logo;
                    -platform pages; - notification block; -logout button.
                </p>
                <h2>ACCOUNT</h2>
                <p>
                    Account settings include several sections. These are the account settings
                    themselves, the subscription settings and the feedback form. More information
                    about setting up an account can be found in account section.
                </p>
                <h2>BRAND SETTING</h2>
                <div>
                    The brand settings page provides:{' '}
                    <ListItem>
                        <li>Brand font customization. Loading custom fonts.</li>
                        <li>
                            Preload custom images. Create your own image gallery for further use in
                            campaigns.
                        </li>
                        <li>Setting primary colors.</li>
                        <li>Recording the main social media links.</li>
                    </ListItem>
                    You can learn more about the brand settings page in the brand setting section.
                </div>
                <h2>GAMIFICATION</h2>
                <p>
                    Available for paid subscriptions. It includes creating draws, setting up games,
                    as well as embedding draws on your own websites and landing pages. You can learn
                    more about this in the gamification section.
                </p>
                <h2>TEMPLATES</h2>
                <p>
                    The templates page will introduce you to ready-made templates for your pages. It
                    is described in more detail in the{' '}
                    <Link to={RouteNames.DOC_TEMPLATES}>
                        <strong>templates</strong>
                    </Link>{' '}
                    section. Learn also how to create your own template for reuse.
                </p>
                <h2>FORMS</h2>
                <p>
                    The main form collection page, if the campaign is built using form widgets. All
                    submissions will be accumulated here. You can learn more about the forms page in
                    the{' '}
                    <Link to={RouteNames.DOC_FORMS}>
                        <strong>forms</strong>
                    </Link>{' '}
                    section.
                </p>
            </div>
        </PageContainer>
    )
}

export default About
