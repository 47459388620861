import { Dispatch, FC, ReactNode, SetStateAction, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined'

interface Props {
    id: string
    label: string
    icon: ReactNode
    toggleNode: () => void
    hasNodes: boolean
    searchTerm: string
    level: number
    isOpen: boolean
    path: string | null
    isActive: boolean
    setActiveItem: Dispatch<SetStateAction<string>>
}

const TreeItem: FC<Props> = ({
    label,
    icon,
    toggleNode,
    hasNodes,
    searchTerm,
    level,
    isOpen,
    path,
    isActive,
    setActiveItem,
    id,
}) => {
    const navigate = useNavigate()

    const onSelectCategory = (key: string) => () => {
        if (hasNodes && toggleNode) {
            toggleNode()
        }
        if (path) {
            navigate(path)
        }
        setActiveItem(key)
    }

    const renderIcon = () => {
        if (hasNodes && !isOpen) {
            return <ArrowDropDownOutlinedIcon />
        } else if (hasNodes && isOpen) {
            return <ArrowDropUpOutlinedIcon />
        } else {
            return null
        }
    };

    return (
        <li
            className={isActive ? 'active' : ''}
            style={{
                marginLeft: searchTerm ? 0 : level * 25,
                fontWeight: isOpen ? 'bolder' : 400,
                textDecoration: isOpen ? 'underline' : 'none',
            }}
            onClick={onSelectCategory(id)}
        >
            {icon}
            {label}
            {renderIcon()}
        </li>
    )
}

export default TreeItem
