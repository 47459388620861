import React, { useState } from 'react'
import { Toast } from '../../v1/helpers/Toast';
import { useNavigate } from 'react-router-dom';
import { RouteNames } from '../../router';
import InputField from './DefaultInput';
import ButtonSpinner from '../../v1/components/common/ButtonSpiner';
import { useAuth } from 'widgets-base';

const ResetPassword = ({ actionCode }) => {
    const { confirmPasswordReset } = useAuth();
    const navigate = useNavigate();

    const [buttonLoading, setButtonLoading] = useState<boolean>(false)
    const [password, setPassword] = useState<{
        password: string
        confirmPassword: string
    }>({
        password: '',
        confirmPassword: '',
    })

    async function handleResetPassword(e: React.FormEvent): Promise<void> {
        e.preventDefault()

        // check password same
        if (password.password == '' || password.confirmPassword == '') {
            Toast('Please check your password fields are not empty.', 'error')
            return
        }

        if (password.password !== password.confirmPassword) {
            Toast('Please type your password twice.', 'error')
            return
        }

        if (password.password.length < 6) {
            Toast('Your password needs at least 6 characters.', 'error')
            return
        }

        try {
            setButtonLoading(true)

            await confirmPasswordReset(actionCode, password.password);

            Toast('Your password has been changed, please sign in.', 'success');

            navigate(RouteNames.LOGIN);
        }
        finally {
            setButtonLoading(false);
        }
    }

    function handlePasswordChange(e: React.ChangeEvent<HTMLInputElement>): void {
        setPassword((prevState) => {
            return {
                ...prevState,
                [e.target.name]: e.target.value,
            }
        })
    }

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="max-w-md w-full px-6 text-center">
                <h1 className="font-bold text-2xl">Reset Your Password</h1>
                <form className="mt-6" onSubmit={handleResetPassword}>
                    <fieldset>
                        <InputField
                            label="New Password"
                            name="password"
                            value={password.password}
                            onChange={handlePasswordChange}
                            type="password"
                        />

                        <InputField
                            label="Confirm Password"
                            name="confirmPassword"
                            value={password.confirmPassword}
                            onChange={handlePasswordChange}
                            type="password"
                        />

                        <button
                            type="submit"
                            className="w-full mt-4 bg-black p-2 text-white rounded-full px-10 col-span-3 md:w-fit-content align-end focus:outline-none hover:bg-primary"
                        >
                            {buttonLoading ? (
                                <ButtonSpinner height="30px" width="30px" />
                            ) : (
                                'Change Password'
                            )}
                        </button>
                    </fieldset>
                </form>
            </div>
        </div>
    );
}

export default ResetPassword
