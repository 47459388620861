import { Divider } from '@mui/material'
import { FC } from 'react'
import { WidgetSettingContainer } from '../../../Design/WidgetSettingContainer'
import { BorderRadius, Margins, Orientation, Paddings, Width } from '../../../EditMode/Design'
import AssetsSetting from '../../../EditMode/Settings/AssetsSetting'
import RedirectSetting from '../../../EditMode/Settings/RedirectSetting'
import { useWidget } from 'widgets-base'

interface Props {
}

const ImageSetting: FC<Props> = () => {

    const { widget } = useWidget();

    return (
        <WidgetSettingContainer padding={18}>
            <AssetsSetting />
            {/* <ImageFrameSetting /> */}
            <RedirectSetting action={widget?.settings?.action} />
            <Divider />
            <Width width={widget?.appearanceSettings?.width} />
            <Orientation align={widget?.appearanceSettings?.align} />
            <Divider />
            <BorderRadius
                topRightValue={widget?.appearanceSettings?.borderTopRightRadius}
                bottomRightValue={widget?.appearanceSettings?.borderBottomRightRadius}
                topLeftValue={widget?.appearanceSettings?.borderTopLeftRadius}
                bottomLeftValue={widget?.appearanceSettings?.borderBottomLeftRadius}
            />
            <Margins
                topValue={widget?.appearanceSettings?.marginTop}
                bottomValue={widget?.appearanceSettings?.marginBottom}
                leftValue={widget?.appearanceSettings?.marginLeft}
                rightValue={widget?.appearanceSettings?.marginRight}
            />
            <Paddings
                topValue={widget?.appearanceSettings?.paddingTop}
                bottomValue={widget?.appearanceSettings?.paddingBottom}
                leftValue={widget?.appearanceSettings?.paddingLeft}
                rightValue={widget?.appearanceSettings?.paddingRight}
            />
        </WidgetSettingContainer>
    )
}

export default ImageSetting
