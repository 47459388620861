import { ChangeEvent, useState } from 'react'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import PasswordForm from '../../../components/password-form'
import { Button } from '../../components/common/Button'
import { InputBox } from '../../components/common/InputBox'
import { ToggleButton } from '../../components/common/ToggleButton'
import { SEO } from '../../components/SEO'
import { IErrors } from '../../modules/IErrors'
import { AccountWrapper, MainContainer } from './styles'
import { Toast } from '../../helpers/Toast'
import EmailModal from '../../../components/email-modal'
import UserService from '../../../services/UserService'
import moment from 'moment'
import { capitalizeFirstLetter } from '../../helpers/capitalizeFirstLetter'
import { DocsHint } from '../../components/Docs/DocsHint'
import { RouteNames } from '../../../router'
import styled from 'styled-components';
import { IPassword, IUser, useAuth } from 'widgets-base'

const AccountPage = () => {
    const [error, setError] = useState<IErrors>({})
    const { currentUser, setCurrentUser } = useAuth()
    const [loading, setLoading] = useState<boolean>(false)
    const [checkTogglePasswordChange, setCheckTogglePasswordChange] = useState<boolean>(false)
    const [password, setPassword] = useState<IPassword>()
    const [openEmailModal, setOpenEmailModal] = useState<boolean>(false)

    const toggleModal = () => {
        setOpenEmailModal((prev) => !prev)
    }

    const toggleCheckPassword = (event: ChangeEvent<HTMLInputElement>) => {
        setCheckTogglePasswordChange(event.target.checked)
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        //TODO: This should simply set local state until the user clicks save.
        setCurrentUser((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPassword((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const onSaveSetting = async () => {
        setLoading(true)
        try {
            const newPassword = password?.password || null
            const updatedUser = { ...currentUser, lastUpdated: moment().format('LLL') }

            await UserService.updateUser(updatedUser as IUser, newPassword)

            Toast('Profile updated!', 'success')
        } catch (error) {
            console.log(error)
            Toast(error.response.data.message, 'error')
        }
        finally {
            setLoading(false)
        }
    }

    return (
        <AccountWrapper>
            <SEO title="Account" />
            <h1>
                Account settings <DocsHint path={RouteNames.DOC_ACCOUNT} />
            </h1>
            <span>
                Here you can update your contact information, as well as change your email address
                for authorization on the platform
            </span>
            <MainContainer>
                <div className="user__header">
                    <h1>
                        {capitalizeFirstLetter(currentUser?.firstname) +
                            ' ' +
                            capitalizeFirstLetter(currentUser?.lastname)}
                    </h1>
                    {currentUser?.lastUpdated && <p>Last updated: {currentUser?.lastUpdated}</p>}
                    <span>Current subscription: {currentUser?.role?.toUpperCase()}</span>
                </div>
                <InputBox
                    type="text"
                    label="User name (also your link)"
                    value={'deploy.link/' + currentUser?.username}
                    name="username"
                    onChange={handleChange}
                    error={error['username']}
                    helperText={error['username'] ? 'username taken' : ''}
                    placeholder="deploy.link/"
                    disabled={!!currentUser?.username}
                />
                <div className="user__info">
                    <InputBox
                        width="100%"
                        label="First name"
                        name="firstname"
                        value={currentUser?.firstname}
                        onChange={handleChange}
                        type="text"
                    />
                    <InputBox
                        width="100%"
                        label="Last name"
                        name="lastname"
                        value={currentUser?.lastname}
                        onChange={handleChange}
                        type="text"
                    />
                </div>
                <div className="user__email">
                    <InputBox
                        label="Email"
                        name="email"
                        value={currentUser?.email}
                        onChange={handleChange}
                        type="email"
                        readOnly
                    />
                    <Button variant="orange" onClick={toggleModal}>
                        Change email
                    </Button>
                    <EmailModal
                        open={openEmailModal}
                        toggleOpen={toggleModal}
                        email={currentUser?.email}
                    />
                </div>
                <p>Password</p>
                <ToggleContainer>
                    <ToggleButton
                        isToggled={checkTogglePasswordChange}
                        onChange={toggleCheckPassword}
                        name="password"
                    />
                    <span>Change password</span>
                </ToggleContainer>
                {checkTogglePasswordChange && (
                    <PasswordForm
                        password={password}
                        error={error}
                        setError={setError}
                        handlePasswordChange={handlePasswordChange}
                    />
                )}
                <Button
                    type="button"
                    variant="primary"
                    width="90%"
                    loading={loading}
                    loadingText="Saving..."
                    style={{ margin: '0 auto' }}
                    onClick={onSaveSetting}
                >
                    <SaveOutlinedIcon /> Save settings
                </Button>
            </MainContainer>
        </AccountWrapper>
    )
}

export default AccountPage

export const ToggleContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 18px;

    & > span {
        font-size: 14px;
        font-weight: bold;
        color: #3c4b61;
        margin-bottom: 4px;
        margin-left: 10px;
    }
`
