import { Divider } from '@mui/material'
import { ChangeEvent, FC, Fragment } from 'react'
import styled from 'styled-components'
import { InputBox } from '../../common/InputBox'
import { ToggleButton } from '../../common/ToggleButton'
import SwiperSlideSetting from './SwiperSlideSetting'
import { useWidget } from 'widgets-base'

interface Props {
}

const SwiperGeneralSetting: FC<Props> = () => {

    const { widget, setWidget } = useWidget();

    const swiperOptions = [
        { name: 'autoplay', label: 'Autoplay', meaning: widget?.settings?.autoplay },
        { name: 'fadeEffect', label: 'Fade effect*', meaning: widget?.settings?.fadeEffect },
        {
            name: 'slideDescription',
            label: 'Slide description',
            meaning: widget?.settings?.slideDescription,
        },
        {
            name: 'navigation',
            label: 'Show navigation arrows',
            meaning: widget?.settings?.navigation,
        },
        {
            name: 'pagination',
            label: 'Show pagination dots',
            meaning: widget?.settings?.pagination,
        },
    ]

    const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
        const newSetting = {
            ...widget,
            settings: {
                ...widget.settings,
                [e.target.name]: +e.target.value,
            },
        }

        setWidget(newSetting);
    }

    const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === 'fadeEffect') {
            const newSettings = {
                ...widget,
                settings: {
                    ...widget.settings,
                    [e.target.name]: e.target.checked,
                    slidesToShow: 1,
                },
            }

            setWidget(newSettings);
        } else {
            const newSettings = {
                ...widget,
                settings: { ...widget.settings, [e.target.name]: e.target.checked },
            }

            setWidget(newSettings);
    }
    }

    return (
        <div>
            <FlexBox>
                <InputBox
                    label="Slides to show"
                    width="150px"
                    name="slidesToShow"
                    onChange={onChangeValue}
                    type="number"
                    disabled={widget?.settings?.fadeEffect}
                    value={widget?.settings?.slidesToShow}
                    maxLength={widget?.settings?.slides?.length}
                />
                <InputBox
                    label="Slides speed (ms)"
                    width="150px"
                    name="speed"
                    onChange={onChangeValue}
                    type="number"
                    value={widget?.settings?.speed}
                />
            </FlexBox>
            <ToglleWrapper>
                {swiperOptions.map((opt) => (
                    <Fragment key={opt.name}>
                        <ToggleContainer>
                            <ToggleButton
                                isToggled={opt.meaning}
                                onChange={handleCheckbox}
                                name={opt.name}
                            />
                            <span>{opt.label}</span>
                        </ToggleContainer>
                        {opt.name === 'fadeEffect' && (
                            <p>
                                *when choosing a fade effect, the number of slides to show should
                                not be more than one
                            </p>
                        )}
                    </Fragment>
                ))}
            </ToglleWrapper>
            <Divider />
            <SwiperSlideSetting />
        </div>
    )
}

export default SwiperGeneralSetting

const FlexBox = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    gap: 16px;
`

const ToglleWrapper = styled.div`
    & > div:not(:last-child) {
        margin-bottom: 18px;
    }
    & > p {
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        color: #717f95;
        padding-bottom: 18px;
        margin-top: -10px;
    }
`


export const ToggleContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 18px;

    & > span {
        font-size: 14px;
        font-weight: bold;
        color: #3c4b61;
        margin-bottom: 4px;
        margin-left: 10px;
    }
`
