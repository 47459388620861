import { nanoid } from "nanoid";
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import TableRowsIcon from '@mui/icons-material/TableRows';
import { StaticWidgets } from "../../v1/components/new/static-widgets";
import { MoveableWidgets } from "../../v1/components/new/moveable-widgets";
import { useForm } from "../../builder/context/form-state-new";
import ButtonSpinner from "../../v1/components/common/ButtonSpiner";
import { makeStyledElement, useWidget, useRenderContext, usePageTheme, useDragContext, determineDirection, IWidgetType, makeBorderWidth, makeBorderRadius, makePadding, stdGroupProperties, makeColorProperty, stdBorderWidth, stdBorderStyle, stdBorderRadius, stdFontFamily, stdFontSize, stdLineHeight, stdTextAlignment, stdElementAlignment, stdPadding, stdMargin, stdWidth, stdHeight } from "widgets-base";

const Button = makeStyledElement("button");
const Div = makeStyledElement("div");

function Static() {

    const { widget, properties, expandedProperties } = useWidget();
    const { renderContext } = useRenderContext();
    const form = useForm();
    const { pageTheme } = usePageTheme();
    
    let widgetClassName = `submit flex flex-grow `;

    return (
        <Button 
            widgetId={widget.id}
            className={widgetClassName}
            elementName="button"
            variantName={properties.variant || "submit"}
            pageTheme={pageTheme}
            properties={expandedProperties}
            renderContext={renderContext}
            type="submit"
            data-button-type="submit"
            onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                
                form.submitForm()
                    .catch(error => {
                        console.error(`Error submitting form:`);
                        console.error(error);
                    });
            }}
            >
            <StaticWidgets
                widgets={widget.children}
                />

            {form?.submitting
                && (
                    <div
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                        }}
                        >
                        <div
                            style={{
                                transform: "translate(-50%, -50%)",
                            }}
                            >
                            <ButtonSpinner color={expandedProperties.color} height="1.5em" width="1.5em" />
                        </div>
                    </div>
                )
            }
        </Button>
    );
}

function Editor() {

    const { widget, widgetPath, properties, expandedProperties } = useWidget();
    const { renderContext } = useRenderContext();
    const { pageTheme } = usePageTheme();
    const { dragProps, setRef, onSelect } = useDragContext();
    
	let direction = determineDirection(properties, renderContext);
    let widgetClassName = `submit flex flex-grow `;

    const groupStyle: any = {};

    if (widget.children?.length === 0) {
        if (direction === "column") {
            if (!groupStyle.minWidth) {
                groupStyle.minWidth = "175px";
            }
            if (!groupStyle.minHeight) {
                groupStyle.minHeight = "250px";
            }
        }
        else {
            if (!groupStyle.minWidth) {
                groupStyle.minWidth = "250px";
            }
            if (!groupStyle.minHeight) {
                groupStyle.minHeight = "170px";
            }
        }
    }

    return (
        <Div
            {...dragProps}
            ref={setRef}
            onClick={event => {
                event.stopPropagation();
                event.preventDefault();
                onSelect();
            }}
            widgetId={widget.id}
            className={widgetClassName}
            elementName="button"
            variantName={properties.variant || "submit"}
            pageTheme={pageTheme}
            properties={expandedProperties}
            renderContext={renderContext}
            style={groupStyle}
            >
            <MoveableWidgets
                key={`${widget.id}-${widget.lastUpdate}`}
                groupId={widget.id}
                widgets={widget.children}
                direction={direction}
                parentWidgetPath={widgetPath}
                />
        </Div>
    );
}

function Template() {

    const { widget, properties, expandedProperties } = useWidget();
    const { renderContext } = useRenderContext();
    const { pageTheme } = usePageTheme();

    let widgetClassName = `submit flex `;

    return (
        <Div
            widgetId={widget.id}
            className={widgetClassName}
            elementName="button"
            variantName={properties.variant || "submit"}
            pageTheme={pageTheme}
            properties={expandedProperties}
            renderContext={renderContext}
            >
            <StaticWidgets
                widgets={widget.children}
                />
        </Div>
    );
}

const widgetType: IWidgetType = {
    name: 'Submit button',
    Static: Static,
    Preview: Static,
    Editor: Editor,
    Template: Template,
    affinity: [ "form" ], // Can only be placed in a form.
    antiAffinity: [ "field", "submit-button" ], // Cannot be placed in a field or submit button.
    selectionTarget: true, // Steals selection from children.
    template: {
        id: `widget-${nanoid()}`,
        xtype: 'submit-button',
        grouped: true,
        properties: {
            flexDirection: "row",
            ...makeBorderWidth(0.1),
            ...makeBorderRadius(4),
            ...makePadding(8),
            gap: 3,

            color: "white",
            backgroundColor: "#6478f9",
            borderColor: "#6478f9",

            justifyContent: "center",
            alignSelf: "stretch",

            hover: {
                color: "#6478f9",
                backgroundColor: "white",
                borderColor: "#6478f9",
            },
        },
        children: [
            {
                id: `widget-${nanoid()}`,
                xtype: 'text',
                properties: {
                    content: "Submit",
                },
            },
        ],
    },
    properties: [        
        {
            id: "flexDirection",
            name: "Direction",
            type: "option",
            values: [{
                    name: "Row", 
                    value: "row", 
                    icon: <ViewColumnIcon />,
                },
                {
                    name: "Column", 
                    value: "column",
                    icon: <TableRowsIcon />,
                },
            ],
            defaultValue: "row",
            required: true,
            orientation: "vertical",
        },
    ],
    propertyCategories: [
        {
            name: "Items",
            properties: stdGroupProperties,
        },
        {
            name: "Colors",
            properties: [
                makeColorProperty("normal", "color", "Color"),
                makeColorProperty("hover", "color", "Color / Hover"),
                makeColorProperty("normal", "borderColor", "Border"),
                makeColorProperty("hover", "borderColor", "Border / Hover"),
                makeColorProperty("normal", "backgroundColor", "Background"),
                makeColorProperty("hover", "backgroundColor", "Background / Hover"),
            ],
        },
        {
            name: "Border",
            properties: [
                stdBorderWidth,
                stdBorderStyle,
                stdBorderRadius,
            ],
        },
        {
            name: "Design",
            properties: [
                stdFontFamily,
                stdFontSize,
                stdLineHeight,
                stdTextAlignment,
            ],
        },
        {
            name: "Placement",
            properties: [
                stdElementAlignment,
                stdPadding,
                stdMargin,
                stdWidth,
                stdHeight,
            ],
        },
    ],
};

export default widgetType;
