import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import QrCodeScannerOutlinedIcon from '@mui/icons-material/QrCodeScannerOutlined'
import DynamicFormOutlinedIcon from '@mui/icons-material/DynamicFormOutlined'
import { IForm } from '../../modules/IForm'
import { RouteNames } from '../../../router'
import { CampaignContainer } from '../../pages/Forms/styles'
import { ISubmission } from '../../modules/ISubmission'
import { ICampaignForm } from '../../../services/FormService'
import { capitalizeFirstLetter } from '../../helpers/capitalizeFirstLetter'

interface Props {
    campaign: ICampaignForm;
    form: IForm;
    submissions: ISubmission[];
    isTeam?: boolean;
    uid?: string;
}

const getThumbnailCampaign = (url: string) => {
    if (url) {
        return url
    } else {
        return `/images/form-placeholder.png`
    }
}

const CampaignItem: FC<Props> = ({ campaign, form, isTeam = false, uid, submissions }) => {
    const navigate = useNavigate()

    const onViewFormDetails = (formId: string) => () => {
        if (isTeam) {
            navigate(`${RouteNames.FORMS}/${campaign.campaignId}/${formId}/${uid}`)
        } else {
            navigate(`${RouteNames.FORMS}/${campaign.campaignId}/${formId}`)
        }
    }

    const getSubmissionCount = (formId: string) => {
        if (submissions.length) {
            const submissionOfCurrentForm = submissions.filter((sub) => sub.formId === formId)
            return submissionOfCurrentForm.length
        } else {
            return form.submissionCount
        }
    }

    return (
        <CampaignContainer>
            <img src={getThumbnailCampaign(campaign.metaImageUrl)} alt="thumbnail" />
            <div className="campaign__description">
                <h1>{campaign.campaignName}</h1>
                <span>Created: {new Date(campaign.createdAt).toDateString()}</span>
                <div className="campaign__analitics">
                    <p className="campaign__scans">
                        <QrCodeScannerOutlinedIcon fontSize="large" />
                        <span>{campaign.scans} scans</span>
                    </p>
                    <p className="campaign__form_count">
                        <DynamicFormOutlinedIcon fontSize="large" />
                        <span>{form?.forms?.length} active form(s)</span>
                    </p>
                </div>
            </div>
            <div className="campaign__forms">
                {form?.forms?.map((item) => (
                    <div key={item.id} className="form__item" onClick={onViewFormDetails(item.id)}>
                        <p>{capitalizeFirstLetter(item.formName)}</p>
                        <span>{getSubmissionCount(item.id) || 0} submissions</span>
                    </div>
                ))}
            </div>
        </CampaignContainer>
    )
}

export default CampaignItem
