import { Button, Card, CardContent } from "@mui/material";
import { Logo } from "../../../v1/components/Navbar/Logo";
import { ICampaign } from "widgets-base";
import { SpeechBubble } from "./components/speech-bubble";

function ExperienceCard({ title, desc, iconSvg, selected, onClick }) {
    return (
        <button 
            onClick={onClick}
            className="text-neutral-500 text-sm leading-7 whitespace-nowrap items-stretch border grow justify-center px-5 py-2 rounded-lg border-solid"
            style={{
                cursor: 'pointer',
                border: selected
                    ? `2px solid blue`
                    : '2px solid orange',
            }}
            >
            {/* <span dangerouslySetInnerHTML={{ __html: iconSvg }} /> */}
            <span>{title}</span>
        </button>
    );
}

export interface ChooseYourExperienceProps {

    //
    // Templates to choose from.
    //
    templates: ICampaign[];

    //
    // Handles the "next" button.
    //
    onNext: () => void;

    //
    // Index of the selected template.
    //
    selectedTemplateIndex: number;

    //
    // Sets the selected template.
    //
    setSelectedTemplateIndex: (index: number) => void;
}

export function ChooseYourExperience({ templates, selectedTemplateIndex, setSelectedTemplateIndex }: ChooseYourExperienceProps) {

    return (
        <div className="flex flex-col w-full items-center">
            <SpeechBubble
                title="Gabby - Product Manager"
                text="Bring your creativity and we will bring it to life with our tech!"
                avatar="/ai-generator/characters/gabby.png"
                />

            <SpeechBubble
                title="Gabby - Product Manager"
                text="What would you like to build?"
                avatar="/ai-generator/characters/gabby.png"
                />
                
            <div className="text-black text-center text-sm max-w-[430px] self-center mt-8 max-md:max-w-full">
                <span className="font-semibold text-xl leading-6">Choose Your Experience</span>
            </div>

            <div 
                className="flex flex-wrap gap-2.5 mr-5 mt-8 items-center justify-center justify-items-center" 
                style={{ maxWidth: "600px" }}
                >
                {templates.map((template, index) => {
                    return (
                        <ExperienceCard
                            key={index}
                            selected={index === selectedTemplateIndex}
                            onClick={() => setSelectedTemplateIndex(index)}
                            title={template.campaignName}
                            desc={template.campaignDescription}
                            iconSvg={template.iconSvg}
                            />
                    );
                })}
                <button className="text-neutral-500 text-sm leading-7 whitespace-nowrap items-stretch border grow justify-center px-5 py-2 rounded-lg border-solid border-zinc-500">
                    Quiz (Coming Soon)
                </button>
                <button className="text-neutral-500 text-center text-sm leading-7 whitespace-nowrap items-stretch border grow justify-center px-4 py-2 rounded-lg border-solid border-zinc-500">
                    Digital Business Card (Coming Soon)
                </button>
                <button className="text-neutral-500 text-center text-sm leading-7 whitespace-nowrap items-stretch border grow justify-center px-4 py-2 rounded-lg border-solid border-zinc-500">
                    Voice AI Experiences (Coming Soon)
                </button>
            </div>
        </div>
    );
}
