
export interface INode<ChildT> {
    children?: ChildT[];
}

//
// Finds the location of an item in the tree.
//
export function findTreeItem<NodeT extends INode<NodeT>>(nodes: NodeT[], fn: (node: NodeT) => boolean, parent?: number[]): { path: number[], node: NodeT } | undefined {
    if (!parent) {
        parent = [];
    }

    for (let index = 0; index < nodes.length; ++index) {
        const node = nodes[index];
        if (fn(node)) {
            return {
                path: parent.concat([index]),
                node,
            };
        }

        if (node.children) {
            const result = findTreeItem<NodeT>(node.children, fn, parent.concat([index]));
            if (result) {
                return result;
            }
        }
    }

    return undefined;
}

//
// Gets an item from a tree given its path.
//
export function getTreeItem<NodeT extends INode<NodeT>>(nodes: NodeT[], path: number[]): NodeT | undefined {
    if (path.length === 0) {
        return undefined;
    }

    let working = nodes[path[0]];
    for (let i = 1; i < path.length; i++) {
        working = working.children[path[i]];
    }

    return working;
}

// 
// Makes a copy of an array.
//
export function cloneArray(array) {
    return array.slice();
}

//
// Clones an array and update the specified item.
//
export function updateArray(array, index, item) {
    const newArray = cloneArray(array);
    newArray[index] = item;
    return newArray;
}

//
// Splices a new item into a tree and returns the updated tree.
// Original input is immutable and not modified.
// 
export function spliceTree<NodeT extends INode<NodeT>>(nodes: NodeT[], itemPath: number[], newItem: NodeT): NodeT[] {

    // console.log(`Splicing tree:`, parentPath, itemIndex);
    // console.log(newItem);
    // console.log(JSON.stringify(nodes, null, 4));

    let splicedTree = cloneArray(nodes);
    let arrayToSplice = splicedTree;

    for (const index of itemPath.slice(0, itemPath.length-1)) {
        if (index < 0 || index >= arrayToSplice.length) {
            throw new Error(`Bad index ${index} to array of length ${arrayToSplice.length}!`);
        }
        const node = arrayToSplice[index] = Object.assign({}, arrayToSplice[index]);
        if (!node.children) {
            throw new Error(
                `Expected node ${JSON.stringify(node)} to have children!`
            );
        }
        node.children = cloneArray(node.children);
        arrayToSplice = node.children;
    }

    const itemIndex = itemPath[itemPath.length-1];
    if (itemIndex < 0 || itemIndex > arrayToSplice.length) {
        throw new Error(`Bad index ${itemIndex} to array of length ${arrayToSplice.length}!`);
    }

    arrayToSplice.splice(itemIndex, 0, newItem);

    // console.log(`Spliced tree:`, parentPath, itemIndex, newItem);
    // console.log(JSON.stringify(splicedTree, null, 4));

    return splicedTree;
}

//
// Removes an item from the tree and returns the updated tree.
// Original input is immutable and not modified.
//
export function removeTreeItem<NodeT extends INode<NodeT>>(nodes: NodeT[], predicate: (node: NodeT) => boolean) {

    const result = findTreeItem(nodes, predicate); 
    if (!result) {
        return nodes; // No change.
    }

    const { path } = result;

    let splicedTree = cloneArray(nodes);
    let arrayToSplice = splicedTree;

    for (const index of path.slice(0, path.length-1)) {
        if (index < 0 || index >= arrayToSplice.length) {
            throw new Error(`Bad index ${index} to array of length ${arrayToSplice.length}!`);
        }
        const node = arrayToSplice[index] = Object.assign({}, arrayToSplice[index]);
        if (!node.children) {
            throw new Error(
                `Expected node ${JSON.stringify(node)} to have children!`
            );
        }
        node.children = cloneArray(node.children);
        arrayToSplice = node.children;
    }

    const index = path[path.length-1];
    if (index < 0 || index >= arrayToSplice.length) {
        throw new Error(`Bad index ${index} to array of length ${arrayToSplice.length}!`);
    }

    return [
        splicedTree,
        arrayToSplice.splice(index, 1)[0]
    ];
}

//
// Clones the entire tree.
//
export function cloneTree(nodes) {
    const cloned = cloneArray(nodes);
    for (const item of cloned) {
        if (item.children) {
            item.children = cloneTree(item.children);
        }
    }

    return cloned;
}

//
// Moves an item from one location in a tree to another.
//
export function moveTree<NodeT extends INode<NodeT>>(nodes: NodeT[], oldPath: number[], newPath: number[]): { newNodes: NodeT[], newPath: number[] } { //todo: this will sometimes do redundant cloning!

    // console.log(`!! moveTree: before !!`);
    // console.log(oldParentPath, oldIndex, newParentPath, newIndex);
    // console.log(JSON.stringify(nodes, null, 4));

    nodes = cloneTree(nodes); //TODO: This is a bit heavy handed.

    let oldParent = nodes;
    for (const index of oldPath.slice(0, oldPath.length-1)) {
        oldParent = oldParent[index].children as any;
    }

    let newParent = nodes;
    for (const index of newPath.slice(0, newPath.length-1)) {   
        newParent = newParent[index].children as any;
    }

    const oldIndex = oldPath[oldPath.length-1];
    let newIndex = newPath[newPath.length-1];

    if (oldParent === newParent) {
        if (oldIndex < newIndex) {
            newIndex -= 1; // Item will be removed from the same array, so adjust the new index by one.
        }
    }

    const [itemToMove] = oldParent.splice(oldIndex, 1);
    newParent.splice(newIndex, 0, itemToMove);

    // console.log(`!! moveTree: after !!`);
    // console.log(JSON.stringify(nodes, null, 4));

    return {
        newNodes: nodes,
        newPath: newPath.slice(0, newPath.length-1).concat([newIndex]),       
    };
}

//
// Sets a new item in a tree.
//
export function setTreeItem<NodeT extends INode<NodeT>>(nodes: NodeT[], path: number[], newItem: NodeT) {

    // console.log(`Splicing tree:`, parentPath, itemIndex);
    // console.log(newItem);
    // console.log(JSON.stringify(nodes, null, 4));

    let splicedTree = cloneArray(nodes);
    let arrayToSplice = splicedTree;

    for (const index of path.slice(0, path.length-1)) {
        if (index < 0 || index >= arrayToSplice.length) {
            throw new Error(`Bad index ${index} to array of length ${arrayToSplice.length}!`);
        }
        const node = arrayToSplice[index] = Object.assign({}, arrayToSplice[index]);
        if (!node.children) {
            throw new Error(
                `Expected node ${JSON.stringify(node)} to have children!`
            );
        }
        node.lastUpdate = Date.now(); // This is a bit of a hack to force the tree to update.
        node.children = cloneArray(node.children);
        arrayToSplice = node.children;
    }

    const itemIndex = path[path.length-1];
    if (itemIndex < 0 || itemIndex > arrayToSplice.length) {
        throw new Error(`Bad index ${itemIndex} to array of length ${arrayToSplice.length}!`);
    }

    arrayToSplice[itemIndex] = newItem;

    // console.log(`Spliced tree:`, parentPath, itemIndex, newItem);
    // console.log(JSON.stringify(splicedTree, null, 4));

    return splicedTree;
}

//
// Converts the tree form one type to another.
//
export function mapTree<NodeT extends INode<NodeT>>(nodes: NodeT[], fn: (node: NodeT) => NodeT) {
    const mapped = cloneArray(nodes);
    return mapped.map(node => {
        const newNode = fn(Object.assign({}, node));
        if (node.children) {
            newNode.children = mapTree<NodeT>(node.children, fn);
        }
        return newNode;
    });
}

//
// Filters nodes out of the tree.
//
export function filterTree<NodeT extends INode<NodeT>>(nodes: NodeT[], fn: (node: NodeT) => boolean) {
    const filtered = nodes.filter(fn);

    for (const node of filtered) {
        if (node.children) {
            node.children = filterTree<NodeT>(node.children, fn);
        }
    }

    return filtered;
}

//
// Flattens the entire tree to a single array.
//
export function flattenTree<NodeT extends INode<NodeT>>(nodes: NodeT[]): NodeT[] {
    let flattened: NodeT[] = [];

    for (const node of nodes) {
        flattened.push(node);
        if (node.children) {
            flattened = flattened.concat(flattenTree<NodeT>(node.children));
        }
    }

    return flattened;
}

//
// Returns true if the first node is an ancesor of the second node (searching down the tree from the first node).
// TODO: This could be much quicker if we had a parent reference on each node.
//
export function isDescendent<NodeT extends INode<NodeT>>(firstNode: NodeT, secondNode: NodeT): boolean {
    if (firstNode === secondNode) {
        return true;
    }

    if (!firstNode.children) {
        return false;
    }

    for (const child of firstNode.children) {
        if (isDescendent(child, secondNode)) {
            return true;
        }
    }

    return false;
}

//
// Get a node in the tree by its path.
//
export function getNode<NodeT extends INode<NodeT>>(nodes: NodeT[], path: number[]): NodeT | undefined {
    if (path.length === 0) {
        return undefined;
    }
    
    let working = nodes[path[0]];
    for (let i = 1; i < path.length; i++) {
        working = working.children[path[i]];
    }
    return working;
}

//
// Gets a set of nodes based on a path.
//
export function getNodeSet<NodeT extends INode<NodeT>>(nodes: NodeT[], path: number[]): NodeT[] {
    if (path.length === 0) {
        return [];
    }

    let set: NodeT[] = [];
    let working = nodes[path[0]];
    set.push(working);
    for (let i = 1; i < path.length; i++) {
        working = working.children[path[i]];
        set.push(working);
    }

    return set;
}

//
// Gets a random integer between min and max.
//
export function getRandomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min)) + min;
}


//
// Removes a random element from an array and returns it.
//
export function extractRandomElement<T>(array: T[]): T {
    const index = getRandomInt(0, array.length);
    return extractElement(array, index);
}

//
// Removes an element from an array and returns it.
//
export function extractElement<T>(array: T[], index: number): T {
    return array.splice(index, 1)[0];
}

//
// Removes the element from the array.
//
export function removeElement<T>(array: T[], el: T): void {
    const index = array.indexOf(el);
    if (index !== -1) {
        array.splice(index, 1);
    }
}
