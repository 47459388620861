import { ChangeEvent, FC, useState } from 'react'
import styled from 'styled-components'
import { checkUrl } from '../../../helpers/validation'
import { InputBox } from '../../common/InputBox'
import { useBrandingContext } from '../../../../builder/context/branding-context'
import SocialIcons from '../../Widgets/General/SocialWidget/SocialIcons'
import { useWidget } from 'widgets-base'
import { ISocialLinks } from '../../../modules/widgets/general/ISocial'

interface Props {
}

const SocialGeneralSetting: FC<Props> = () => {

    const { widget, setWidget } = useWidget();
    const { branding } = useBrandingContext();
    
    const [error, setError] = useState<{ [key: string]: string | null }>({})

    const handleChangeSocial = (event: ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name
        const value = event.target.value
        const newSettings = {
            ...widget,
            settings: {
                ...widget.settings,
                links: {
                    ...widget.settings.links,
                    [name]: value,
                },
            },
        }

        setWidget(newSettings);
    }

    const defaultLinks = {
        facebook: "",
        twitter: "",
        instagram: "",
        linkedin: "",
        whatsapp: "",
        tiktok: "",
        youtube: "",
        pinterest: "",
        spotify: "",
    };   

    const overrideLinks: ISocialLinks = widget?.settings?.links;
    const socialLinks = Object.assign({}, defaultLinks, branding?.social || {}, overrideLinks);

    const checkSocialLink = (name: string, type: string, value: string) => {
        const url = checkUrl(name, type, value)
        if (url === 'Invalid URL') {
            setError((prevState) => {
                return { ...prevState, [name]: url }
            })
        } else {
            const newSettings = {
                ...widget,
                settings: {
                    ...widget.settings,
                    links: {
                        ...widget.settings.links,
                        [name]: url,
                    },
                },
            }

            setWidget(newSettings);
            setError((prevState) => {
                return { ...prevState, [name]: null }
            })
        }
    }

    const onCheckSocialLink = (name: string) => () => {
        checkSocialLink(name, 'url', widget.settings.links[name])
    }

    return (
        <LinksList>
            {Object.entries(socialLinks).map(([ key, value ]) => (
                <div key={key} className="link_item">
                    <SocialIcons
                        key={key}
                        platform={key}
                        height={"30px"}
                        width={"30px"}
                        color={"white"}
                        link={value}
                        editMode={true}
                        useIcon={true}
                        />
                    <InputBox
                        type="text"
                        value={value}
                        name={key}
                        onChange={handleChangeSocial}
                        onBlur={onCheckSocialLink(key)}
                        error={error[key]}
                        width="100%"
                        placeholder="https://"
                    />
                </div>
            ))}
        </LinksList>
    )
}

export default SocialGeneralSetting

const LinksList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    .link_item {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
    .input_box {
        margin-bottom: 0;
    }
`
