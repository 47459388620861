import { Route, Routes } from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { RouteNames } from 'live/build/router'
import { ProtectedRoute } from 'live/build/components/protected-route'
import Dashboard from 'live/build/v1/pages/Dashboard'
import Layout from 'live/build/v1/components/GeneralLayout'
import { docsRoutes } from 'live/build/router/docsRoutes'
import { privatRoutes } from 'live/build/router/privatRoutes'
import { publicRoutes } from 'live/build/router/publicRoutes'
import Templates from 'live/build/v1/pages/Templates'
import DocsLayout from 'live/build/v1/components/Docs/DocsLayout'
import BuilderPage from 'live/build/builder'
import { AIGeneratorPage } from "live/build/pages/ai-generator/ai-generator";
import { AIGeneratorPage2 } from "live/build/pages/ai-generator-2/ai-generator";
import Preview from 'live/build/v1/pages/Preview'
import { SectionsPage } from 'live/build/v1/pages/sections';

// set public stripe API key
const stripePromise = loadStripe('pk_test_m44SwkXCuDHX3cnaDMZGH0NA00lnexDnL2')

const App = () => {
    return (
        <Routes>
            {/* public pages */}
            {publicRoutes.map((route) => (
                <Route key={route.path} path={route.path} element={route.element} />
            ))}
            {/* private pages */}
            <Route path="/main" element={<Layout />}>
                {privatRoutes.map((route) => {
                    if (route.path === RouteNames.BILLING) {
                        return (
                            <Route
                                key={route.path}
                                path={route.path}
                                element={
                                    <ProtectedRoute>
                                        <Elements stripe={stripePromise}>{route.element}</Elements>
                                    </ProtectedRoute>
                                }
                            />
                        )
                    } else {
                        //todo: why is this not a ProtectedRoute?
                        return <Route key={route.path} path={route.path} element={route.element} />
                    }
                })}
                <Route
                    path={RouteNames.DASHBOARD}
                    element={
                        <ProtectedRoute>
                            <Dashboard />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path={RouteNames.TEMPLATES}
                    element={
                        <ProtectedRoute>
                            <Templates />
                        </ProtectedRoute>
                    }
                />
                {/* documentation page */}
                <Route path={RouteNames.DOC} element={<DocsLayout />}>
                    {docsRoutes.map((route) => (
                        <Route key={route.path} path={route.path} element={route.element} />
                    ))}
                </Route>
            </Route>
            <Route 
                path={`${RouteNames.AI_GENERATOR}`} 
                element={
                    <ProtectedRoute>
                        <AIGeneratorPage />
                    </ProtectedRoute>
                }
                />
            <Route 
                path={`${RouteNames.AI_GENERATOR2}`} 
                element={
                    <ProtectedRoute>
                        <AIGeneratorPage2 />
                    </ProtectedRoute>
                }
                />
            <Route 
                path={`${RouteNames.BUILDER}/:campaignId`} 
                element={
                    <ProtectedRoute>
                        <BuilderPage />
                    </ProtectedRoute>
                }
                />
            <Route 
                path={`${RouteNames.PREVIEW}/:campaignId`} 
                element={
                    <ProtectedRoute>
                        <Preview />
                    </ProtectedRoute>
                }
                />
            <Route 
                path={`/sections`} 
                element={
                    <ProtectedRoute>
                        <SectionsPage />
                    </ProtectedRoute>
                }
                />
        </Routes>
    )
}

export default App
