import styled from 'styled-components'

export const UsernamePageContainer = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    h1 {
        text-align: center;
        margin: 18px 0;
    }
`
