import styled from 'styled-components'

export const AccountWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 5%;
    & > h1 {
        font-weight: 600;
        font-size: 32px;
        width: 100%;
        box-sizing: content-box;
        line-height: 38px;
        color: #3ae29b;
        margin-bottom: 7px;
        text-align: left;
    }

    & > span {
        font-size: 14px;
        color: #cacaca;
        width: 100%;
        text-align: left;
        margin-bottom: 5%;
    }
`

export const FeedbackWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 5%;
    & > h1 {
        font-weight: 600;
        font-size: 32px;
        width: 100%;
        box-sizing: content-box;
        line-height: 38px;
        color: #3ae29b;
        margin-bottom: 7px;
        text-align: left;
    }

    & > span {
        font-size: 14px;
        color: #cacaca;
        width: 100%;
        text-align: left;
        margin-bottom: 5%;
    }

    .title {
        font-size: 38px;
        font-weight: bold;
        color: #6c6bf4;
        margin-bottom: 15px;
        line-height: 1.5;
        text-align: center;
    }

    .feedback-data {
        display: flex;
        flex-direction: column;
        max-width: 500px;
        position: relative;
        background-color: #ffffffd4;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0px 15px 41px #dfe2f2;

        .input_box {
            input,
            textarea {
                background-color: #fafaff96;
            }
        }

        & > img {
            position: absolute;
            left: -64%;
            top: 21%;
            width: 45rem;
            z-index: -1;
            filter: blur(3px);
        }
        h3 {
            font-size: 14px;
            font-weight: bold;
            color: #868686;
            margin-bottom: 15px;
        }

        .feedback-rating {
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;

            & > label {
                font-size: 14px;
                font-weight: bold;
                color: #3c4b61;
                margin-bottom: 7px;
            }
            .MuiRating-root {
                margin: 0 auto;
            }
        }
    }

    @media (max-width: 520px) {
        .feedback-data {
            img {
                width: 30rem;
            }
        }
    }
`

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 500px;
    width: 100%;

    .user__header {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-bottom: 25px;
        h1 {
            font-size: 25px;
            font-weight: bold;
            color: #6c6bf4;
        }
        p {
            color: #cacaca;
            margin-bottom: 5px;
        }
        span {
            font-size: 14px;
            color: #9ca201;
            text-decoration: underline;
        }
    }

    .user__info {
        display: flex;
        justify-content: space-between;
        gap: 10px;
    }

    .user__email {
        display: flex;
        gap: 10px;
        align-items: center;

        button {
            height: 45px;
        }

        & > div {
            flex: 1;
        }
    }

    & > p {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: rgb(60, 75, 97);
        margin-bottom: 10px;
    }
`

export const EmailModalContent = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    max-width: 470px;
    width: 100%;

    .container {
        display: flex;
        flex-direction: column;
    }

    span {
        font-size: 13px;
        line-height: 18px;
        color: #868686;
    }

    h1 {
        font-size: 25px;
        width: 100%;
        font-weight: 600;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
            cursor: pointer;
        }
    }

    h2 {
        font-size: 18px;
        font-weight: 600;
        margin-top: 20px;
        margin-bottom: 20px;
        width: 100%;
    }

    h3 {
        font-size: 15px;
        width: 100%;
        margin-bottom: 5px;
    }
`
