import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import { useStripe } from '@stripe/react-stripe-js'
import { ChangeEvent, useState } from 'react'

import BillingDetails from '../../components/Billing/BillingDetails'
import FreeCard from '../../components/Billing/FreeCard'
import LiteCard from '../../components/Billing/LiteCard'
import PremiumCard from '../../components/Billing/PremiumCard'
import { SEO } from '../../components/SEO'
import { Toast } from '../../helpers/Toast'
import { IProduct } from './productData'
import { IStripeProduct } from '../../modules/IStripeProduct'
import UserService from '../../../services/UserService'
import { BillingWrapper, OfferList } from './styles'
import Reviews from '../../components/Billing/Reviews'
import { DocsHint } from '../../components/Docs/DocsHint'
import { RouteNames } from '../../../router'
import { useAuth, UserRoles } from 'widgets-base'

const BillingPage = () => {
    const [period, setPeriod] = useState<string>('month')
    const [loading, setLoading] = useState({ premium: false, lite: false })
    const { currentUser } = useAuth()
    const stripe = useStripe()

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPeriod(event.target.value)
    }

    const onSubscribe = (product: IProduct, role: string) => async () => {
        const line_items: IStripeProduct = {
            price: product.id,
            quantity: 1,
        }

        if (currentUser.role === role && currentUser?.stripe?.subscriptionId) {
            Toast('You are already subscribed to this tariff plan', 'error')
            return
        }

        if (currentUser.role === UserRoles.ADMIN) {
            Toast(
                `Your status is ADMIN. You don't need subscriptions, you are so powerful on this platform! :)`,
                'error'
            )
            return
        }

        setLoading((prev) => ({ ...prev, [role]: true }))
        try {
            const { data } = await UserService.createCheckoutSession(line_items, role)

            const { error } = await stripe.redirectToCheckout({
                sessionId: data.sessionId,
            })

            if (error) {
                Toast('Error redirect to checkout payment', 'error')
                setLoading((prev) => ({ ...prev, [role]: false }))
                console.log(error)
            }
        } catch (error) {
            console.log(error)
            Toast(error.response.data.message, 'error')
        }
        setLoading((prev) => ({ ...prev, [role]: false }))
    }

    return (
        <BillingWrapper>
            <SEO title="Plans & Billing" />
            <h1>
                Billing and plans <DocsHint path={RouteNames.DOC_BILLING} />
            </h1>
            <span>
                Choose the subscription of your choice. We offer to use the "Premium" plan for
                complete freedom of action to achieve your marketing goals
            </span>
            <BillingDetails />
            <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">Choose period</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={period}
                    onChange={handleChange}
                >
                    <FormControlLabel value="month" control={<Radio />} label="Month" />
                    <FormControlLabel value="year" control={<Radio />} label="Year" />
                </RadioGroup>
            </FormControl>
            <OfferList>
                <FreeCard />
                <PremiumCard period={period} onPayment={onSubscribe} loading={loading.premium} />
                <LiteCard period={period} onPayment={onSubscribe} loading={loading.lite} />
            </OfferList>
            <Reviews />
        </BillingWrapper>
    )
}

export default BillingPage
