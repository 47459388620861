import styled from "styled-components";

export const AssetsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 5%;
    & > h1 {
        font-weight: 600;
        font-size: 32px;
        width: 100%;
        box-sizing: content-box;
        line-height: 38px;
        color: #3ae29b;
        margin-bottom: 7px;
        text-align: left;
    }

    & > span {
        font-size: 14px;
        color: #cacaca;
        width: 100%;
        text-align: left;
        margin-bottom: 2%;
    }

    .MuiButtonBase-root {
        letter-spacing: 0;
    }

    .brand__content {
        margin-bottom: 100px;
        width: 100%;
    }
`