import { TextField } from '@mui/material'
import { useState } from 'react'
import styled from 'styled-components'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useForm } from '../../../../../builder/context/form-state-old'
import { useWidget } from 'widgets-base'

interface Props {
    borderFieldsColor?: string
    backgroundFieldsColor?: string
}

export const DatePickerField = ({ borderFieldsColor, backgroundFieldsColor }: Props) => {

    const { widget } = useWidget();
    const { getFieldValue, setFieldValue } = useForm();
    
    const label = widget.settings.label
    const name = widget.settings.name
    const required = widget.settings.required

    const [selectedDate, setSelectedDate] = useState(new Date())

    const handleDateChange = (date) => {
        setSelectedDate(date)
        setFieldValue(name, date);
    }

    const width = widget.appearanceSettings.width + '%'
    const paddingTop = widget.appearanceSettings.paddingTop / 10 + 'em'
    const paddingBottom = widget.appearanceSettings.paddingBottom / 10 + 'em'
    const paddingLeft = widget.appearanceSettings.paddingLeft / 10 + 'em'
    const paddingRight = widget.appearanceSettings.paddingRight / 10 + 'em'
    const marginTop = widget.appearanceSettings.marginTop / 10 + 'em'
    const marginBottom = widget.appearanceSettings.marginBottom / 10 + 'em'
    const marginLeft =
        widget.appearanceSettings.marginLeft / 10 === 0
            ? 'auto'
            : widget.appearanceSettings.marginLeft / 10 + 'em'
    const marginRight =
        widget.appearanceSettings.marginRight / 10 === 0
            ? 'auto'
            : widget.appearanceSettings.marginRight / 10 + 'em'
    const borderTopWidth = widget.appearanceSettings.borderTopWidth / 10 + 'em'
    const borderBottomWidth = widget.appearanceSettings.borderBottomWidth / 10 + 'em'
    const borderLeftWidth = widget.appearanceSettings.borderLeftWidth / 10 + 'em'
    const borderRightWidth = widget.appearanceSettings.borderRightWidth / 10 + 'em'
    const borderWidth = borderTopWidth && borderRightWidth && borderBottomWidth && borderLeftWidth
    const borderTopLeftRadius = widget.appearanceSettings.borderTopLeftRadius / 10 + 'em'
    const borderTopRightRadius = widget.appearanceSettings.borderTopRightRadius / 10 + 'em'
    const borderBottomLeftRadius = widget.appearanceSettings.borderBottomLeftRadius / 10 + 'em'
    const borderBottomRightRadius = widget.appearanceSettings.borderBottomRightRadius / 10 + 'em'

    const alignment = widget?.appearanceSettings?.alignment
    const weight = Number(widget?.appearanceSettings?.font?.weight) || 400
    const border = `${borderWidth} solid ${borderFieldsColor}`

    return (
        <Wrapper
            align={alignment}
            marginTop={marginTop}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            marginRight={marginRight}
        >
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Container
                    width={width}
                    weight={weight}
                    paddingTop={paddingTop}
                    paddingBottom={paddingBottom}
                    paddingLeft={paddingLeft}
                    paddingRight={paddingRight}
                    marginTop={marginTop}
                    marginBottom={marginBottom}
                    marginLeft={marginLeft}
                    marginRight={marginRight}
                    borderTopLeftRadius={borderTopLeftRadius}
                    borderBottomLeftRadius={borderBottomLeftRadius}
                    borderTopRightRadius={borderTopRightRadius}
                    borderBottomRightRadius={borderBottomRightRadius}
                    border={border}
                    backgroundColor={backgroundFieldsColor}
                >
                    <MobileDatePicker
                        label={label}
                        inputFormat="MM/DD/YYYY"
                        value={selectedDate}
                        onChange={handleDateChange}
                        renderInput={(params) => <TextField {...params} required={required} />}
                    />
                </Container>
            </LocalizationProvider>
        </Wrapper>
    )
}

const Wrapper = styled.div.attrs((props) => ({}))<{
    align: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
}>`
    display: flex;
    align-items: ${(props) =>
        props.align === 'left' ? 'flex-start' : props.align === 'center' ? 'center' : 'flex-end'};
    justify-content: center;
    width: 100%;
    cursor: pointer;
    flex-direction: column;
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
`
const Container = styled.div<{
    width: string
    paddingTop: string
    paddingBottom: string
    weight: number
    paddingLeft: string
    paddingRight: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
    borderTopLeftRadius: string
    borderBottomLeftRadius: string
    borderTopRightRadius: string
    borderBottomRightRadius: string
    border: string
    backgroundColor: string
}>`
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 20em;
    font-weight: ${(props) => +props.weight};
    width: ${(props) => props.width};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};

    .react-date-picker__wrapper {
        padding-top: ${(props) => props.paddingTop};
        padding-bottom: ${(props) => props.paddingBottom};
        padding-left: ${(props) => props.paddingLeft};
        padding-right: ${(props) => props.paddingRight};
        border: ${(props) => props.border};
        border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
        border-top-left-radius: ${(props) => props.borderTopLeftRadius};
        border-top-right-radius: ${(props) => props.borderTopRightRadius};
        border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
        background-color: ${(props) => props.backgroundColor};
    }

    .react-calendar {
        width: 100%;
        color: #000 !important;
        background: #cbd5e0;
    }

    .MuiInputBase-root {
        border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
        border-top-left-radius: ${(props) => props.borderTopLeftRadius};
        border-top-right-radius: ${(props) => props.borderTopRightRadius};
        border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
    }
`
