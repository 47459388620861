import styled from 'styled-components'
import { useForm } from "../../builder/context/form-state-new";
import { useWidget, useRenderContext, usePageTheme, useDragContext, makeStyledElement, IWidgetType, makeColorProperty, stdBorderWidth, stdBorderStyle, stdBorderRadius, stdFontFamily, stdFontSize, stdLineHeight, stdTextAlignment, stdElementAlignment, stdPadding, stdMargin, stdWidth, stdHeight } from 'widgets-base';

function Static() {

    const { widget, properties, expandedProperties, parentWidget } = useWidget();
    const form = useForm();
    const { renderContext } = useRenderContext();
    const { pageTheme } = usePageTheme();

    return (
        <Input
            id={parentWidget?.id}
            widgetId={widget.id}
            style={{ height: "100%" }}
            elementName="input"
            variantName={properties.variant || "default"}
            pageTheme={pageTheme}
            properties={expandedProperties}
            renderContext={renderContext}
            onChange={(event) => {
                if (form) {
                    form.setFieldValue(parentWidget?.properties?.fieldName, event.target.value);
                }
            }}
            name={parentWidget?.properties?.fieldName}
            type={expandedProperties.type}
            placeholder={expandedProperties.placeholder}
            required={expandedProperties.required}
            min={expandedProperties.min}
            max={expandedProperties.max}
            step={expandedProperties.step}
            value={form?.getFieldValue(parentWidget?.properties?.fieldName) || ""}
            {...widget.attributes || {}}
            />
    );
}

function Editor() {

    const { widget, properties, expandedProperties } = useWidget();
    const { renderContext } = useRenderContext();
    const { pageTheme } = usePageTheme();
    const { dragProps, setRef, onSelect } = useDragContext();
    
    return (
        <Input
            {...dragProps}
            ref={setRef}
            onClick={event => {
                event.stopPropagation();
                event.preventDefault();
                onSelect();
            }}
            widgetId={widget.id}
            style={{ height: "100%" }}
            elementName="input"
            variantName={properties.variant || "default"}
            pageTheme={pageTheme}
            properties={expandedProperties}
            renderContext={renderContext}
            name={widget.name}
            type={expandedProperties.type}
            placeholder={expandedProperties.placeholder}
            readOnly
            />
    );
}

function Template() {

    const { widget, properties, expandedProperties } = useWidget();
    const { renderContext } = useRenderContext();
    const { pageTheme } = usePageTheme();

    return (
        <Input
            widgetId={widget.id}
            style={{ height: "100%" }}
            elementName="input"
            variantName={properties.variant || "default"}
            pageTheme={pageTheme}
            properties={expandedProperties}
            renderContext={renderContext}
            name={widget.name}
            type={expandedProperties.type}
            placeholder={expandedProperties.placeholder}
            required={expandedProperties.required}
            min={expandedProperties.min}
            max={expandedProperties.max}
            step={expandedProperties.step}
            defaultValue="Form input"
            readOnly
            />
    );
}

const Input = styled(makeStyledElement("input"))`
    width: 100%;
`;

const widgetType: IWidgetType = {
    name: 'Input',
    Static: Static,
    Preview: Static,
    Editor: Editor,
    Template: Template,
    isFormWidget: true,
    affinity: [ "field" ], // An input can only be placed in a field.
    properties: [
        {
            id: "placeholder",
            name: "Placeholder",
            type: "text",
            required: true,
            defaultValue: "",
        },
        {
            id: "inputType",
            name: "Type",
            type: "option",
            required: true,
            defaultValue: "text",
            values: [
                {
                    name: "Text",
                    value: "text",
                },
                {
                    name: "Number",
                    value: "number",
                },
                {
                    name: "Email",
                    value: "email",
                },
                {
                    name: "Phone Number",
                    value: "phone-number",
                },
            ],
        },
        {
            id: "required",
            name: "Required",
            type: "boolean",
            required: true, 
            defaultValue: false,
        },
        {
            id: "min",
            name: "Min",
            type: "number",
            defaultValue: 0,
        },
        {
            id: "max",
            name: "Max",
            type: "number",
            defaultValue: 100,
        },
        {
            id: "step",
            name: "Step",
            type: "number",
            defaultValue: 1,
        },
    ],
    propertyCategories: [
        {
            name: "Colors",
            properties: [
                makeColorProperty("normal", "color", "Color"),
                makeColorProperty("hover", "color", "Color / Hover"),
                makeColorProperty("normal", "borderColor", "Border"),
                makeColorProperty("hover", "borderColor", "Border / Hover"),
                makeColorProperty("normal", "backgroundColor", "Background"),
                makeColorProperty("hover", "backgroundColor", "Background / Hover"),
            ],
        },
        {
            name: "Border",
            properties: [
                stdBorderWidth,
                stdBorderStyle,
                stdBorderRadius,
            ],
        },
        {
            name: "Design",
            properties: [
                stdFontFamily,
                stdFontSize,
                stdLineHeight,
                stdTextAlignment,
            ],
        },
        {
            name: "Placement",
            properties: [
                stdElementAlignment,
                stdPadding,
                stdMargin,
                stdWidth,
                stdHeight,
            ],
        },
    ],
};

export default widgetType;