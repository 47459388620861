import React, {FormEvent, useState} from 'react'
import validator from 'validator'
import styled from 'styled-components'
import {checkInputValid, checkValidPasswords} from '../../helpers/validation'
import {IErrors} from '../../modules/IErrors'
import {Button} from '../common/Button'
import ButtonSpinner from '../common/ButtonSpiner'
import Checkbox from '../common/Checkbox'
import {InputBox} from '../common/InputBox'
import {AuthContainer} from './Signin'
import {Toast} from '../../helpers/Toast'
import { ISignupForm, useAuth } from 'widgets-base'

const Signup = ({updateFormType}: { updateFormType: (value: string) => void }) => {
    const [formValues, setFormValues] = useState<ISignupForm>({
        firstname: '',
        lastname: '',
        email: '',
        company: '',
        username: '',
        password: '',
        confirmPassword: '',
        termsAndConditions: false,
    })
    const [errors, setErrors] = useState<IErrors>({})
    const [loading, setLoading] = useState<boolean>(false)
    const { signup } = useAuth()

    const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setFormValues((prevState) => {
            return {
                ...prevState,
                [e.target.name]: e.target.value,
            }
        })
    }

    const checkValid = (value: string, name: string, type: string, errorMsg: string): void => {
        const valid = checkInputValid(value, type)

        if (!valid) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    [name]: errorMsg,
                }
            })
        } else {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    [name]: '',
                }
            })
        }
    }

    const checkPasswords = (
        password: string,
        confirmPassword: string,
        name: string,
        errorMsg: string
    ): void => {
        const valid = checkValidPasswords(password, confirmPassword)

        if (!valid) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    [name]: errorMsg,
                }
            })
        } else {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    [name]: '',
                }
            })
        }
    }

    const handleSignUp = async (e: FormEvent): Promise<void> => {
        e.preventDefault()

        setErrors({})

        let formErrors = {}

        if (validator.isEmpty(formValues.firstname) || validator.isEmpty(formValues.lastname)) {
            formErrors['firstname'] = 'Please input a valid'
        }

        if (validator.isEmpty(formValues.email) || !validator.isEmail(formValues.email)) {
            formErrors['email'] = 'Please enter a valid email'
        }

        if (validator.isEmpty(formValues.username)) {
            formErrors['username'] = 'Please enter a valid username'
        }

        if (validator.isEmpty(formValues.password)) {
            formErrors['password'] = 'Please enter a valid password'
        }

        if (validator.isEmpty(formValues.confirmPassword)) {
            formErrors['confirmPassword'] = 'Please enter a valid confirm password'
        }

        if (!validator.equals(formValues.confirmPassword, formValues.password)) {
            formErrors['confirmPassword'] = 'Passwords are not the same'
        }

        if (formValues.termsAndConditions === false) {
            formErrors['termsAndConditions'] = 'Please accept terms and conditions'
        }

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
            return
        }

        try {
            setLoading(true) //todo: don't really need a separate setLoading here.
            await signup(formValues);
            setLoading(false)
        } catch (error) {
            if (error.response.data.error) {
                Toast(error.response.data.error, 'error')
            } else {
                Toast(error, 'error')
            }
            setLoading(false)
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormValues((prevState) => {
            return {
                ...prevState,
                [event.target.name]: event.target.checked,
            }
        })
    }

    return (
        <AuthContainer>
            <div>
                <h1>Sign up</h1>
                <div>
                    <p className="sub-text">already with us? </p>
                    <button
                        type="button"
                        className="sign-up-button"
                        onClick={() => updateFormType('signIn')}
                    >
                        Login
                    </button>
                </div>
            </div>

            <div className="input-container">
                <div className="grid-col-2">
                    <InputBox
                        placeholder="First name"
                        name="firstname"
                        type="text"
                        width="100%"
                        onChange={handleTextChange}
                        value={formValues?.firstname}
                        onBlur={() =>
                            checkValid(
                                formValues.firstname,
                                'firstname',
                                'string',
                                'Please enter valid name.'
                            )
                        }
                        error={errors.firstname}
                    />
                    <InputBox
                        placeholder="Last name"
                        name="lastname"
                        type="text"
                        width="100%"
                        onChange={handleTextChange}
                        value={formValues?.lastname}
                        onBlur={() =>
                            checkValid(
                                formValues?.lastname,
                                'lastname',
                                'string',
                                'Please enter valid name.'
                            )
                        }
                        error={errors?.lastname}
                    />
                </div>
                <InputBox
                    placeholder="Email address"
                    name="email"
                    type="email"
                    onChange={handleTextChange}
                    value={formValues?.email}
                    onBlur={() =>
                        checkValid(
                            formValues?.email,
                            'email',
                            'string',
                            'Please enter valid email.'
                        )
                    }
                    error={errors?.email}
                />

                <InputBox
                    placeholder="Unique user name"
                    name="username"
                    type="text"
                    onChange={handleTextChange}
                    value={formValues?.username}
                    onBlur={() =>
                        checkValid(
                            formValues?.username,
                            'username',
                            'string',
                            'Please enter valid username'
                        )
                    }
                    error={errors.username}
                />
                <p className="username-preview">deploy.link/ {formValues?.username}</p>

                <InputBox
                    placeholder="Password"
                    name="password"
                    type="password"
                    onChange={handleTextChange}
                    value={formValues?.password}
                    onBlur={() =>
                        checkValid(
                            formValues?.password,
                            'password',
                            'password',
                            'Password must contain uppercase, lowercase, number and symbol.'
                        )
                    }
                    size={'small'}
                />
                {errors.password && <ErrorMessage>{errors.password}</ErrorMessage>}
                <InputBox
                    placeholder="Confirm password"
                    name="confirmPassword"
                    type="password"
                    onChange={handleTextChange}
                    value={formValues?.confirmPassword}
                    onBlur={() =>
                        checkPasswords(
                            formValues?.password,
                            formValues?.confirmPassword,
                            'confirmPassword',
                            "Password's do not match."
                        )
                    }
                    error={errors.confirmPassword}
                />
            </div>

            <div className="tnc-layout">
                <Checkbox
                    name="termsAndConditions"
                    text="I agree to <a href='https://www.deployable.co/legal'>terms and conditions</a>"
                    checked={formValues?.['termsAndConditions']}
                    handleChange={handleChange}
                />
            </div>

            <Button
                type="button"
                onClick={handleSignUp}
                variant="secondary"
                width="100%"
                size="medium"
            >
                {loading ? <ButtonSpinner height="25px" width="25px"/> : 'Create account'}
            </Button>
        </AuthContainer>
    )
}

export default Signup

const ErrorMessage = styled.span`
    position: relative;
    top: -15px;
    color: red;
`;
