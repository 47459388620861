import { Divider, Tooltip } from '@mui/material'
import { ChangeEvent, FC, useState } from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { ButtonAction, IButtonSettings } from '../../../modules/widgets/IButtonSettings'
import { InputBox } from '../../common/InputBox'
import { checkInputValid } from '../../../helpers/validation'
import AddElementButton from './AddElementButton'
import ButtonSettings from '../../../models/widgets/ButtonSettings'
import { useWidget, IView } from 'widgets-base'
import { useCampaign } from '../../../../builder/context/campaign'

interface Props {
}

const buttonOptions = [
    { label: ButtonAction.REDIRECT, value: ButtonAction.REDIRECT },
    { label: ButtonAction.GO_TO_VIEW, value: ButtonAction.GO_TO_VIEW },
    { label: ButtonAction.TOGGLE_POPUP, value: ButtonAction.TOGGLE_POPUP },
]

const ButtonGeneralSetting: FC<Props> = () => {

    const { campaign } = useCampaign();
    const { widget, setWidget } = useWidget();
    const [error, setError] = useState<string>('')

    const pagesOptions = campaign.views.map((page: IView, index: number) => ({
        label: page.name,
        value: `${index}`,
    }))

    const setWidgetOnBlur = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === '') {
            setError('Please enter valid web link')
            return
        }
        if (e.target.value.slice(0, 3) === 'www') {
            const url = 'https://' + e.target.value
            const isValidUrl = checkInputValid(url, 'url')
            if (!isValidUrl) {
                setError('Please enter valid web link')
                return
            }
        }
        if (e.target.value.substring(0, 8) !== 'https://') {
            setError('Please add https:// in front of your link')
        } else {
            setError(null)
        }
    }

    const onChangeButtonValue = (event: ChangeEvent<HTMLInputElement>, index: number) => {
        const value = event.target.value
        const name = event.target.name

        const buttonSetting = {
            ...widget.settings.links[index],
            [name]: value,
        }

        const links = [
            ...widget.settings.links.slice(0, index),
            buttonSetting,
            ...widget.settings.links.slice(index + 1),
        ]

        const newWidget = {
            ...widget,
            settings: {
                ...widget.settings,
                links,
            },
        }

        setWidget(newWidget);
    }

    const onHandleChangeActionType = (value: { label: string; value: string }, index: number) => {
        const buttonSetting = {
            ...widget.settings.links[index],
            type: value.value,
        }

        const links = [
            ...widget.settings.links.slice(0, index),
            buttonSetting,
            ...widget.settings.links.slice(index + 1),
        ]

        const newWidget = {
            ...widget,
            settings: {
                ...widget.settings,
                links,
            },
        }

        setWidget(newWidget);
    }

    const onHandleChangeRedirectPage = (value: { label: string; value: string }, index: number) => {
        const buttonSetting = {
            ...widget.settings.links[index],
            viewIndex: +value.value,
        }

        const links = [
            ...widget.settings.links.slice(0, index),
            buttonSetting,
            ...widget.settings.links.slice(index + 1),
        ]

        const newWidget = {
            ...widget,
            settings: {
                ...widget.settings,
                links,
            },
        }

        setWidget(newWidget);
    }

    const onRemoveButton = (id: string) => () => {
        const newLinks = widget.settings.links.filter((button: IButtonSettings) => button.id !== id)
        const newWidget = {
            ...widget,
            settings: {
                ...widget.settings,
                links: newLinks,
            },
        }

        setWidget(newWidget);
    }

    const onAddNewButton = () => {
        const newWidget = {
            ...widget,
            settings: {
                ...widget.settings,
                links: [
                    ...widget.settings.links,
                    new ButtonSettings(
                        'View more',
                        'https://deploy.link',
                        ButtonAction.REDIRECT,
                        0,
                        0
                    ),
                ],
            },
        }

        setWidget(newWidget);
    }

    return (
        <ButtonList>
            {widget?.settings?.links?.map((button: IButtonSettings, index: number) => {
                const defaultType = { label: `${button.type}`, value: `${button.type}` }
                const defaultPage = {
                    label: `Page ${+button.viewIndex + 1}`,
                    value: `${button.viewIndex}`,
                }
                const defaultPopup = {
                    label: `Popup ${button.layerIndex + 1}`,
                    value: `${button.layerIndex}`,
                }
                return (
                    <ButtonBlock key={button.id}>
                        <InputBox
                            label="Button name"
                            value={button.linkTitle}
                            name="linkTitle"
                            type="text"
                            onChange={(e) => onChangeButtonValue(e, index)}
                        />
                        <RedirectBlock>
                            <label>Redirect on click action</label>
                            <Select
                                options={buttonOptions}
                                defaultValue={defaultType}
                                onChange={(value) => onHandleChangeActionType(value, index)}
                            />
                            {button.type === ButtonAction.REDIRECT && (
                                <InputBox
                                    value={button.linkUrl}
                                    name="linkUrl"
                                    type="text"
                                    onChange={(e) => onChangeButtonValue(e, index)}
                                    onBlur={setWidgetOnBlur}
                                    error={error}
                                />
                            )}
                            {button.type === ButtonAction.GO_TO_VIEW && (
                                <Select
                                    options={pagesOptions}
                                    defaultValue={defaultPage}
                                    onChange={(value) => onHandleChangeRedirectPage(value, index)}
                                />
                            )}
                        </RedirectBlock>
                        <Divider />
                        {widget?.settings?.links?.length > 1 && (
                            <Tooltip title="Remove button" placement="bottom" arrow>
                                <span className="remove_button" onClick={onRemoveButton(button.id)}>
                                    <DeleteOutlinedIcon />
                                </span>
                            </Tooltip>
                        )}
                    </ButtonBlock>
                )
            })}
            <AddElementButton name="Add new one button" onClick={onAddNewButton} />
        </ButtonList>
    )
}

export default ButtonGeneralSetting

const ButtonList = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
`

const RedirectBlock = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #717f95;
    gap: 10px;

    & > label {
        font-size: 12px;
        color: #3c4b61;
        font-weight: bold;
    }

    & > div {
        width: 100%;
        & > div {
            border-radius: 10px;
        }
    }
`

const ButtonBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 7px;
    position: relative;

    .remove_button {
        position: absolute;
        top: -12px;
        right: 0;
        opacity: 0.6;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
            opacity: 1;
        }
    }
`
