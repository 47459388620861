// TODO: добавить актуальные айдишники цен для прода
// actual prices for products from Stripe.com
const premiumProductMonthID = 'price_1MMqXHFK2gbpmlViG4lGRlf9'
const premiumProductYearID = 'price_1MMqWIFK2gbpmlViwFgOoWsD'
const liteProductMonthID = 'price_1MMqYgFK2gbpmlViEiOLO4Gn'
const liteProductYearID = 'price_1MMqYSFK2gbpmlViO3UpRa1D'

export interface IProduct {
    id: string
    title: string
    price: number
}

const productsData = [
    {
        id: premiumProductMonthID,
        title: 'Premium plan/month',
        price: 85,
    },
    {
        id: premiumProductYearID,
        title: 'Premium plan/year',
        price: 1020,
    },
    {
        id: liteProductMonthID,
        title: 'Lite plan/month',
        price: 22,
    },
    {
        id: liteProductYearID,
        title: 'Lite plan/year',
        price: 210,
    },
]

const getProductData = (id: string) => {
    const productData = productsData.find((product) => product.id === id)

    if (!productData) {
        console.log('Product data does not exist for ID: ' + id)
        return null
    }

    return productData
}

export {
    productsData,
    getProductData,
    premiumProductMonthID,
    premiumProductYearID,
    liteProductMonthID,
    liteProductYearID,
}
