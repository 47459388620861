import { useState } from "react";
import { useProperty } from "../../context/property";
import { IPropertyType } from "../property-type";
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { NumberInput } from "../../components/number-editor";
import { IPropertyBag, useWidget } from "widgets-base";

//
// Determine the "all" value for a group of properties.
// Returns undefined for indeterminate value.
//
function determineAllValue(properties: IPropertyBag, styleIds: string[]): number | undefined {
    const values = styleIds.map(id => properties?.[id]);
    if (values.length === 0) {
        return undefined;
    }

    const firstValue = values[0];
    if (firstValue === undefined) {
        return undefined;
    }

    if (values.slice(1).every(value => value === firstValue)) {
        return firstValue;
    }

    return undefined;;
}

//
// An editor for border radius properties.
//
function BorderRadiusEditor() {

    const { property } = useProperty();
    const { properties, updateProperties } = useWidget();

    const [expanded, setExpanded] = useState<boolean>(false)

    function onChangeAll(name: string, value: number) {
        const propertiesToUpdate = {};
        let needUpdate = false;
        for (const styleId of property.styleId!) {
            if (properties[styleId] !== value) {                
                propertiesToUpdate[styleId] = value;
                needUpdate = true;
            }
        }

        if (needUpdate) {
            updateProperties(propertiesToUpdate);
        }
    }

    function onChangeSingleValue(name: string, value: number) {
        if (properties[name] !== value) {
            updateProperties({
                [name]: value,
            });
        }
    }

    return (
        <Container>
            <ActiveContainer isCustomBorder={expanded}>
                <div className="field">
                    <NumberInput
                        disabled={expanded}
                        name="all"
                        value={determineAllValue(properties, property.styleId!)}
                        onChange={onChangeAll}
                    />
                    <span>pt</span>
                </div>
                <div className="icon" onClick={() => setExpanded(!expanded)}>
                    <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1 4.33333V1H4.33333"
                            stroke={expanded ? '#6478F9' : '#717F95'}
                            strokeLinecap="round"
                        />
                        <path
                            d="M1 9.66667V13H4.33333"
                            stroke={expanded ? '#6478F9' : '#717F95'}
                            strokeLinecap="round"
                        />
                        <path
                            d="M13 4.33333V1H9.66667"
                            stroke={expanded ? '#6478F9' : '#717F95'}
                            strokeLinecap="round"
                        />
                        <path
                            d="M13 9.66667V13H9.66667"
                            stroke={expanded ? '#6478F9' : '#717F95'}
                            strokeLinecap="round"
                        />
                    </svg>
                </div>
            </ActiveContainer>

            {expanded && (
                <MultiSelectContainer
                    initial={{ opacity: 0, y: 50, scale: 0.3 }}
                    animate={{ opacity: 1, y: 0, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
                >
                    <div className="custom">
                        <div className="top-bottom">
                            <div className="flex_container">
                                <div className="field">
                                    <NumberInput 
                                        name={property.styleId![0]}
                                        value={properties[property.styleId![0]]}
                                        onChange={onChangeSingleValue}
                                        />
                                    <span>pt</span>
                                </div>
                                <span>Top left</span>
                            </div>
                            <div className="flex_container">
                                <div className="field">
                                    <NumberInput 
                                        name={property.styleId![1]}
                                        value={properties[property.styleId![1]]}
                                        onChange={onChangeSingleValue}
                                        />
                                    <span>pt</span>
                                </div>
                                <span>Top right</span>
                            </div>
                        </div>
                        <div className="right-left">
                            <div className="flex_container">
                                <div className="field">
                                    <NumberInput 
                                        name={property.styleId![2]}
                                        value={properties[property.styleId![2]]}
                                        onChange={onChangeSingleValue}
                                        />
                                    <span>pt</span>
                                </div>
                                <span>Bottom left</span>
                            </div>
                            <div className="flex_container">
                                <div className="field">
                                    <NumberInput 
                                        name={property.styleId![3]}
                                        value={properties[property.styleId![3]]}
                                        onChange={onChangeSingleValue}
                                        />
                                    <span>pt</span>
                                </div>
                                <span>Bottom right</span>
                            </div>
                        </div>
                    </div>
                </MultiSelectContainer>
            )}
        </Container>
    );
}

const borderRadius: IPropertyType = {
    editor: () => <BorderRadiusEditor />,
};

export default borderRadius;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #717f95;
    width: 100%;

    & > label {
        font-size: 12px;
        color: #3c4b61;
        margin-bottom: 7px;
        font-weight: bold;
    }
`

const ActiveContainer = styled.div<{
    isCustomBorder: boolean
}>`
    display: flex;
    height: 32px;

    .field {
        display: flex;
        align-items: center;
        padding: 5px;
        width: 65px;
        background: #ffffff;
        border: 1px solid #dfe2f2;
        border-radius: 8px;
        margin-right: 6px;
        opacity: ${(props) => (props.isCustomBorder ? 0.5 : 1)};
        input {
            border: none;
            width: 25px;
            font-size: 12px;
            color: #3c4b61;
            font-weight: bold;
        }

        svg {
            margin: 0 8px;
        }
    }

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        border: ${(props) => (props.isCustomBorder ? `2px solid #6478F9` : `1px solid #dfe2f2`)};
        border-radius: 8px;
        width: 32px;
        height: 32px;
        cursor: pointer;
    }
`

const MultiSelectContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    width: 100%;

    .custom {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        .right-left,
        .top-bottom,
        .flex_container {
            display: flex;
            flex-direction: column;
            align-items: center;

            & > span {
                margin-top: 2px;
                font-size: 12px;
                line-height: 14px;
                color: #717f95;
            }

            .field {
                display: flex;
                align-items: center;
                justify-content: center;
                background: #ffffff;
                border: 1px solid #dfe2f2;
                border-radius: 8px;
                padding: 5px;
                width: 78px;
                height: 32px;
                background: #ffffff;
                border: 1px solid #dfe2f2;
                border-radius: 8px;

                input {
                    border: none;
                    width: 40px;
                    font-size: 12px;
                    color: #3c4b61;
                    font-weight: bold;
                }
                span {
                    font-size: 12px;
                    color: #3c4b61;
                    border-left: 1px solid #dfe2f2;
                    padding: 0 5px;
                    font-weight: bold;
                }
            }
        }

        .right-left,
        .top-bottom {
            width: 100%;
            flex-direction: row;
            justify-content: space-around;
        }
    }
`
