import { FC } from 'react'
import styled from 'styled-components'

interface Props {
    tags: string[]
    label?: string
    onAddTags: (tags: string[]) => void
}

export const TagsInput: FC<Props> = ({ tags, label = '', onAddTags }) => {
    const addTags = (event) => {
        if (event.key === 'Enter' && event.target.value !== '') {
            onAddTags([...tags, event.target.value])
            event.target.value = ''
        }
    }

    const removeTags = (index: number) => {
        onAddTags([...tags.filter((tag) => tags.indexOf(tag) !== index)])
    }

    return (
        <div>
            {label && <InputLabel>{label}</InputLabel>}
            <TagsInputContainer>
                <Tags>
                    {tags?.map((tag, index) => (
                        <Tag key={index}>
                            <span className="tag-title">{tag}</span>
                            <i className="tag-close-icon" onClick={() => removeTags(index)}>
                                x
                            </i>
                        </Tag>
                    ))}
                </Tags>
                <input
                    type="text"
                    onKeyUp={(event) => (event.key === 'Enter' ? addTags(event) : null)}
                    placeholder="Press enter to add tags"
                />
            </TagsInputContainer>
        </div>
    )
}

const TagsInputContainer = styled.div`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 48px;
    width: 100%;
    padding: 0 8px;
    border: 1px solid rgb(214, 216, 218);
    border-radius: 6px;
    margin-top: 5px;

    &:focus-within {
        border: 1px solid #0052cc;
    }

    input {
        flex: 1;
        border: none;
        height: 46px;
        font-size: 14px;
        padding: 4px 0 0 0;
        &:focus {
            outline: transparent;
        }
    }
`
const Tags = styled.ul`
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 8px 0 0 0;
`

const Tag = styled.li`
    width: auto;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 0 8px;
    font-size: 14px;
    list-style: none;
    border-radius: 6px;
    margin: 0 8px 8px 0;
    background: #0052cc;
    .tag-title {
        margin-top: 3px;
    }
    .tag-close-icon {
        display: block;
        width: 16px;
        height: 16px;
        line-height: 16px;
        text-align: center;
        font-size: 14px;
        margin-left: 8px;
        color: #0052cc;
        border-radius: 50%;
        background: #fff;
        cursor: pointer;
    }
`

const InputLabel = styled.label`
    font-size: 14px;
    font-weight: bold;
    color: ${(props) => props.theme.text.secondary};
`
