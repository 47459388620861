import { Button } from '@mui/material'
import { FC } from 'react'
import styled from 'styled-components'

interface Props {
    name: string
    onClick: any
}

const AddElementButton: FC<Props> = ({ name, onClick }) => {
    return (
        <Container>
            <Button onClick={onClick}>
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7.625 10H12.5056"
                        stroke="#3C4B61"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M10 7.625V12.5056"
                        stroke="#3C4B61"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <rect
                        x="1.25"
                        y="1.25"
                        width="17.5"
                        height="17.5"
                        rx="4.25"
                        stroke="#3C4B61"
                        strokeWidth="1.5"
                    />
                </svg>
                {name}
            </Button>
        </Container>
    )
}

export default AddElementButton

const Container = styled.div`
    margin: 20px auto;
    display: flex;
    cursor: pointer;
    align-items: center;
    width: 185px;
    height: 38px;
    background: #ffffff;
    border-radius: 8px;
    button {
        width: 100%;
        height: 100%;
        padding: 0;
        outline: none;
        font-style: normal;
        text-transform: none;
        font-weight: 500;
        border: 1px solid #dfe2f2;
        border-radius: 8px;
        font-size: 14px;
        line-height: 14px;
        color: #3c4b61;
        letter-spacing: 0;
    }
    svg {
        margin-right: 9px;
    }
`
