import { FC, memo } from 'react'
import styled from 'styled-components';
import { useWidget } from 'widgets-base';

interface Props {
}

const TextWidget: FC<Props> = () => {

    const { widget } = useWidget();
    const appearanceSettings = widget?.appearanceSettings
    const colourSettings = widget?.colourSettings
    const width = appearanceSettings?.width + '%'
    const fontSize = appearanceSettings ? appearanceSettings.font.size + 'px' : '14px'
    const align = appearanceSettings?.align
    const marginTop = appearanceSettings?.marginTop / 10 + 'em'
    const marginBottom = appearanceSettings?.marginBottom / 10 + 'em'
    const marginLeft = appearanceSettings?.marginLeft / 10 + 'em'
    const marginRight = appearanceSettings?.marginRight / 10 + 'em'
    const paddingTop = appearanceSettings?.paddingTop / 10 + 'em'
    const paddingBottom = appearanceSettings?.paddingBottom / 10 + 'em'
    const paddingLeft = appearanceSettings?.paddingLeft / 10 + 'em'
    const paddingRight = appearanceSettings?.marginRight / 10 + 'em'
    const content = widget?.settings?.content
    const weight = appearanceSettings?.font?.weight || 300
    const fontFamily = appearanceSettings?.font?.family || 'Poppins'

    const color = Array.isArray(colourSettings?.textColour)
        ? colourSettings?.textColour[0]
        : colourSettings?.textColour
    const modFontFamily = fontFamily?.replace(/\s/g, '+')
    const fontUrl = appearanceSettings?.font?.url
    const letterSpacing = appearanceSettings?.font?.spacing + 'px' || '8px'
    const lineHeight = appearanceSettings?.font?.lineHeight || 17

    const stylesChild = {
        weight,
        letterSpacing,
        lineHeight,
    }

    const stylesParent = {
        align,
        width,
        color,
        paddingTop,
        paddingBottom,
        paddingLeft,
        paddingRight,
        marginTop,
        marginBottom,
        marginLeft,
        marginRight,
        fontSize,
        fontFamily,
    }

    return (
        <TextSection className="old-text-widget" id={widget.id}>
            <style>
                {!fontUrl
                    ? `@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`
                    : `@font-face {
                        font-family: '${fontFamily}';
                        src: url('${fontUrl}');
                        font-style: normal;
                      }
                      
                      .header-text {
                        font-family: '${fontFamily}';
                      }`}
            </style>
            <Container
                globalStyles={stylesParent}
                contentStyles={stylesChild}
                dangerouslySetInnerHTML={{ __html: content }}
            />
        </TextSection>
    )
}

export default memo(TextWidget)

const TextSection = styled.section`
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
`
const Container = styled.article<{
    globalStyles: {
        align: string
        width: string
        color: string
        paddingTop: string
        paddingBottom: string
        paddingLeft: string
        paddingRight: string
        marginTop: string
        marginBottom: string
        marginLeft: string
        marginRight: string
        fontSize: string
        fontFamily: string
    }
    contentStyles: {
        weight: string | number
        letterSpacing: string
        lineHeight: number
    }
}>`
    display: flex;
    flex-direction: column;
    width: 100%;
    color: ${(props) => props.globalStyles.color};
    font-size: ${(props) => props.globalStyles.fontSize};
    width: ${(props) => props.globalStyles.width};
    margin-top: ${(props) => props.globalStyles.marginTop};
    margin-bottom: ${(props) => props.globalStyles.marginBottom};
    margin-left: ${(props) => props.globalStyles.marginLeft};
    margin-right: ${(props) => props.globalStyles.marginRight};
    padding-top: ${(props) => props.globalStyles.paddingTop};
    padding-left: ${(props) => props.globalStyles.paddingLeft};
    padding-bottom: ${(props) => props.globalStyles.paddingBottom};
    padding-right: ${(props) => props.globalStyles.paddingRight};
    text-align: ${(props) => props.globalStyles.align};
    font-family: ${(props) => props.globalStyles.fontFamily};
    p,
    pre,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        letter-spacing: ${(props) => props.contentStyles.letterSpacing};
        font-weight: ${(props) => props.contentStyles.weight};
        line-height: ${(props) => props.contentStyles.lineHeight + 'px'};
    }

    h1 {
        font-size: 2.8em;
    }

    h2 {
        font-size: 2.625em;
    }

    h3 {
        font-size: 1.875em;
    }

    h4 {
        font-size: 1.25em;
    }

    h5 {
        font-size: 0.85em;
    }

    h6 {
        font-size: 0.7em;
    }    

    ol {
        list-style-type: none;
        counter-reset: num;
    }

    ol li::before {
        content: counter(num);
        counter-increment: num;
        display: inline-block;
        width: 50px;
        color: ${(props) => props.globalStyles.color};
        text-align: center;
        font-weight: bold;
    }

    ul {
        list-style-type: disc;
        list-style-position: inside;
    }
    a {
        text-decoration: underline;
    }
`
