import React, { FC, memo } from 'react'
import styled from 'styled-components'
import { IButtonSettings } from '../../../../modules/widgets/IButtonSettings'
import SubmitButton from '../../Forms/oldForm/SubmitButton'
import DefaultButton from './DefaultButton'
import { useWidget } from 'widgets-base'

interface Props {
    editMode?: boolean
}

const ButtonWidget: FC<Props> = ({ editMode = false }) => {
    
    const { widget } = useWidget();
    const appearanceSettings = widget?.appearanceSettings
    const type = widget.type
    const columns = appearanceSettings?.columns
    const align = appearanceSettings?.align
    const marginTop = appearanceSettings?.marginTop / 10 + 'em'
    const marginBottom = appearanceSettings?.marginBottom / 10 + 'em'
    const marginLeft = appearanceSettings?.marginLeft / 10 + 'em'
    const marginRight = appearanceSettings?.marginRight / 10 + 'em'
    const buttonSpacing = appearanceSettings?.buttonSpacing / 10 + 'em'
    const links = widget.settings.links || [];

    const stylesParent = {
        columns,
        align,
        marginBottom,
        marginLeft,
        marginRight,
        marginTop,
        buttonSpacing,
    }

    return (
        <ButtonSection id={widget.id} globalStyles={stylesParent}>
            <ButtonContainer globalStyles={stylesParent}>
                {links.map((linkSettings: IButtonSettings, index: number) => {
                    if (editMode) {
                        return (
                            <DefaultButton
                                key={`button-${widget.id}-${index}`}
                                editMode={editMode}
                                action={linkSettings}
                            />
                        )
                    }
                    if (type === 'submit') {
                        return (
                            <SubmitButton
                                key={`button-${widget.id}-${index}`}
                                action={linkSettings}
                            />
                        )
                    } else if (type === 'gamebutton') {
                        return <div>GAME BUTTON</div>
                    } else {
                        return (
                            <DefaultButton
                                key={`button-${widget.id}-${index}`}
                                editMode={editMode}
                                action={linkSettings}
                            />
                        )
                    }
                })}
            </ButtonContainer>
        </ButtonSection>
    )
}

export default memo(ButtonWidget)

const ButtonSection = styled.section<{
    globalStyles: {
        columns: number
        align: string
        marginBottom: string
        marginLeft: string
        marginRight: string
        marginTop: string
        buttonSpacing: string
    }
}>`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: ${(props) => props.globalStyles.marginTop};
    // margin-bottom: ${(props) => props.globalStyles.marginBottom};
    margin-left: ${(props) => props.globalStyles.marginLeft};
    margin-right: ${(props) => props.globalStyles.marginRight};
`

const ButtonContainer = styled.div<{
    globalStyles: {
        columns: number
        align: string
        marginBottom: string
        marginLeft: string
        marginRight: string
        marginTop: string
        buttonSpacing: string
    }
}>`
    width: 100%;
    display: grid;
    grid-template-columns: ${(props) => `repeat(${props.globalStyles.columns}, minmax(0, 1fr))`};
    align-items: ${(props) =>
        props.globalStyles.align === 'left'
            ? 'flex-start'
            : props.globalStyles.align === 'center'
            ? 'center'
            : 'flex-end'};
    gap: ${(props) => props.globalStyles.buttonSpacing};
    justify-items: center;
`
