import { useCampaign } from '../../builder/context/campaign';
import { IAction } from '../modules/widgets/IAction'

export const useRedirectAction = (action: IAction) => {

    const { setCurrentPageIndex } = useCampaign();
    // const analytics = useAnalytics('live')

    let returnedAction = null

    if (action && Object.keys(action).length > 0) {
        switch (action.type) {
            case 'redirect':
                returnedAction = () => {
                    // analytics.track('redirect', { url: action.linkUrl })
                    window.open(action.linkUrl)
                }
                break
            case 'go to page':
                returnedAction = () => {
                                    // analytics.track('go to page', { view: campaign.views[action.viewIndex].name })
                    setCurrentPageIndex(+action.viewIndex)
                }
                break
            case 'redirect to view': //Todo: is this needed?
                returnedAction = () => {
                    // analytics.track('go to page', { view: campaign.views[action.viewIndex].name })
                    setCurrentPageIndex(+action.viewIndex)
                }
                break
            default:
                returnedAction = () => {}
                break
        }
    }

    return {
        action: returnedAction,
    }
}
