import styled from 'styled-components'

export const DocsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    padding: 0 5%;
    margin-bottom: 50px;

    .content {
        display: flex;
        width: 100%;

        @media (max-width: 870px) {
            flex-direction: column;
        }
    }

    & > h1 {
        font-weight: 600;
        font-size: 32px;
        width: 100%;
        box-sizing: content-box;
        line-height: 38px;
        color: #3ae29b;
        margin-bottom: 7px;
        text-align: left;
    }

    & > span {
        font-size: 14px;
        color: #cacaca;
        width: 100%;
        text-align: left;
        margin-bottom: 2%;
    }
`

export const MenuList = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 260px;
    width: 100%;

    & > div {
        outline: none;
    }
    @media (max-width: 870px) {
        max-width: 100%;
    }
`

export const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 0 10px;
    width: 100%;
    border-radius: 8px;
    font-size: 14px;
`

export const ItemList = styled.ul`
    width: 100%;
    margin: 20px 0;
    border-right: 1px solid #cacaca;
    padding: 0 10px;
    transition: all 0.2s ease-in-out;

    .active {
        background-color: #3ae29b;
        border-radius: 8px;
        color: #fff;
    }

    & > li {
        color: #a0a0a0;
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 15px;
        padding: 7px;
        cursor: pointer;
    }
`

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 25px;
    line-height: 1.5;
    .image {
        max-width: 850px;
        width: 100%;
        box-shadow: 0 0 10px 3px #cacaca;
        transition: all 0.2s ease-in-out;
    }
    & > h1 {
        font-size: 28px;
        font-weight: bold;
        color: #6478f9;
    }
    & > span {
        font-size: 14px;
        color: #747474;
    }

    .content {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 25px;
        font-size: 16px;
        h1 {
            font-size: 20px;
            font-weight: bold;
            color: #6478f9;
            text-decoration: underline;
        }
        a {
            color: #f26b42;
        }
        p,
        div {
            margin: 10px 0;
            color: #002b47;
            border-left: 4px solid #cacaca;
            padding-left: 15px;
            & > span {
                font-size: 16px;
                color: #002b47;
                font-weight: bold;
            }
        }

        span {
            color: #b8b8b8;
            font-size: 13px;
        }
        h2 {
            color: #6478f9;
            font-size: 18px;
            font-weight: bold;
        }
    }
`

export const ListItem = styled.ul`
    display: flex;
    flex-direction: column;
    list-style-type: circle;
    padding: 0 40px;
    li {
        padding: 5px;
        color: #002b47;
        padding-left: 15px;
    }
`
