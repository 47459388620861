import { HTMLAttributes } from 'react'
import styled from 'styled-components'

export interface Props extends HTMLAttributes<HTMLButtonElement> {
    width: string
    type?: 'button' | 'submit' | 'reset'
}

const ButtonStyle = styled.button<{ width: string }>`
    background-color: #fafaff;
    color: ${(props) => props.theme.text.light};
    width: ${(props) => props.width || 'auto'};
    border-radius: 0.5rem;
    font-weight: bold;
    text-align: center;
    outline: none;
    font-size: 14px;
    padding: 12px;
    border: 1px solid ${(props) => props.theme.lineColour};
    display: flex;
    justify-content: center;
    font-family: 'Matter SQ';
    cursor: pointer;
    span {
        margin-left: 0.3rem;
        padding-top: 2px;
    }
`

export const GoogleButton = ({ width, ...props }: Props) => {
    return (
        <ButtonStyle width={width} {...props}>
            <img src="/icons/google.svg" height={15} width={15} alt="google button" />
            <span>Sign in with Google</span>
        </ButtonStyle>
    )
}
