import { IWidget } from "widgets-base"


export enum PollsType {
    DOUBLE = 'double',
    MULTIPLE = 'multiple',
}

export type IPollsAnswer = {
    option: string
    votes: number
    id: string
}

export interface PollsTheme {
    textColor: string
    mainColor: string
    backgroundColor: string
    alignment: string
    mode: string[]
}

export interface IPolls extends IWidget {
    id: string
    type: string
    name: string
    settings?: {
        data: IPollsAnswer[]
        type: string
        question: string
        label: string
        title: string
        subtitle: string
        buttonLink: string
        buttonText: string
    }
    appearanceSettings?: {
        borderTopLeftRadius: number
        borderTopRightRadius: number
        borderBottomLeftRadius: number
        borderBottomRightRadius: number
        borderTopWidth: number
        borderBottomWidth: number
        borderLeftWidth: number
        borderRightWidth: number
        paddingTop: number
        paddingBottom: number
        paddingLeft: number
        paddingRight: number
        marginTop: number
        marginBottom: number
        marginLeft: number
        marginRight: number
        mode: string
        align: string
        width: number
        font: {
            family: string
            size: number
            weight?: number
            spacing?: number
            url?: string
        }
        lineHeight: number
    }
    colourSettings?: {
        'border colour': string[]
        'background colour': string[]
        'question colour': string[]
        'section colour': string[]
    }
}
