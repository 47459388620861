import { nanoid } from "nanoid";
import { IWidgetType, emToPt, makeStyledElement, pxToPt, stdElementAlignment, stdHeight, stdMargin, stdPadding, stdWidth, useDragContext, usePageTheme, useRenderContext, useRenderWidget, useWidget } from "widgets-base";

const Div = makeStyledElement("div");

function Static() {

    const { widget, properties, expandedProperties } = useWidget();
    const { renderContext } = useRenderContext();
    const { pageTheme } = usePageTheme();
    const { renderStatic } = useRenderWidget();

    const children = {};

    for (const child of widget.children || []) {
        children[child.name] = child;
    }

    if (!children["ai-message"] || !children["user-message"] || !children["chat-input"]) {
        return (
            <div>
                Missing required children for widget {widget.id} / {widget.xtype}
            </div>
        );
    }

    const aiMessage = children["ai-message"];
    const userMessage = children["user-message"];
    const chatInput = children["chat-input"];

    return (
        <Div
            data-type="chatbot@1"
            widgetId={widget.id}
            elementName="div"
            variantName={properties?.variant || "default"}
            pageTheme={pageTheme}
            properties={expandedProperties}
            renderContext={renderContext}
            style={{
                width: expandedProperties.width,
                height: expandedProperties.height,
            }}
            >
             <div 
                x-data="chat"
                className="flex flex-col overflow-auto w-full h-full"
                x-ref="chatContainer"
                data-assistant-id={widget.properties.assistantId}
                >
                <div className="pr-4 flex-grow" style={{"minWidth":"100%","display":"table"}}>
                    <template x-for="message in messages">
                        <div>
                            <template x-if="message.role === aiRole">
                                {renderStatic(aiMessage)}
                            </template>

                            <template x-if="message.role === userRole">
                                {renderStatic(userMessage)}
                            </template>
                        </div>
                    </template>
                </div>

                <div className="flex items-center pt-0">
                    {renderStatic(chatInput)}
                </div>

                <div className="flex flex-col items-center mt-6">
                    <p className="text-xs text-gray-400 leading-3">
                        <a href="https://www.deployable.co/" className="underline">
                            Powered by Deployable
                        </a>
                    </p>
                </div>
            </div>
        </Div>
    );
}

function Preview() {

    const { widget, properties, expandedProperties } = useWidget();
    const { renderContext } = useRenderContext();
    const { pageTheme } = usePageTheme();
    const { renderPreview } = useRenderWidget();

    const children = {};

    for (const child of widget.children || []) {
        children[child.name] = child;
    }

    if (!children["ai-message"] || !children["user-message"] || !children["chat-input"]) {
        return (
            <div>
                Missing required children for widget {widget.id} / {widget.xtype}
            </div>
        );
    }

    const aiMessage = children["ai-message"];
    const userMessage = children["user-message"];
    const chatInput = children["chat-input"];

    return (
        <Div 
            widgetId={widget.id}
            elementName="div"
            variantName={properties?.variant || "default"}
            pageTheme={pageTheme}
            properties={expandedProperties}
            renderContext={renderContext}
            style={{
                width: expandedProperties.width,
                maxWidth: "100%",
                height: expandedProperties.height,
            }}
            >
             <div className="flex flex-col overflow-auto w-full h-full">
                <div className="pr-4 flex-grow" style={{"minWidth":"100%","display":"table"}}>
                    {renderPreview(aiMessage)}

                    {renderPreview(userMessage)}
                </div>

                <div className="flex flex-col items-center">
                    {renderPreview(chatInput)}
                </div>

                <div className="flex flex-col items-center mt-6">
                    <p className="text-xs text-gray-400 leading-3">
                        <a href="https://www.deployable.co/" className="underline">
                            Powered by Deployable
                        </a>
                    </p>
                </div>
            </div>
        </Div>
    );
}

function Editor() {

    const { widget, properties, expandedProperties, widgetPath } = useWidget();
    const { renderContext } = useRenderContext();
    const { pageTheme } = usePageTheme();
    const { dragProps, setRef, onSelect } = useDragContext();
    const { renderEditor } = useRenderWidget();

    const children = {}; //todo: can this child map stuff some how go into the "use render widget?".

    let childIndex = 0;
    for (const child of widget.children || []) {
        children[child.name] = {
            child,
            childIndex,
        };
        childIndex++;
    }

    if (!children["ai-message"] || !children["user-message"] || !children["chat-input"]) {
        return (
            <div>
                Missing required children for widget {widget.id} / {widget.xtype}
            </div>
        );
    }

    const aiMessage = children["ai-message"];
    const userMessage = children["user-message"];
    const chatInput = children["chat-input"];
    
    return (
        <Div 
            {...dragProps}
            ref={setRef}
            onClick={onSelect}
            widgetId={widget.id}
            elementName="div"
            variantName={properties?.variant || "default"}
            pageTheme={pageTheme}
            properties={expandedProperties}
            renderContext={renderContext}
            style={{
                width: expandedProperties.width,
                height: expandedProperties.height,
            }}
            >
             <div className="flex flex-col overflow-auto w-full h-full">
                <div className="pr-4 flex-grow" style={{"minWidth":"100%","display":"table"}}>
                    {renderEditor(aiMessage.child, widgetPath, aiMessage.childIndex)}
                    {renderEditor(userMessage.child, widgetPath, userMessage.childIndex)}
                </div>

                <div className="flex flex-col items-center">
                    {renderEditor(chatInput.child, widgetPath, chatInput.childIndex)}
                </div>

                <div className="flex flex-col items-center mt-6">
                    <p className="text-xs text-gray-400 leading-3">
                        <a href="https://www.deployable.co/" className="underline">
                            Powered by Deployable
                        </a>
                    </p>
                </div>
            </div>
        </Div>
    );
}

const widgetType: IWidgetType = {
    name: 'Chatbot',
    Static: Static,
    Preview: Preview,
    Editor: Editor,
    Template: Static,
    selectionTarget: true, // Steals selection from children.

    async onPropertyUpdate(widget, campaign, propertyId, propertyValue, updateProperties, api): Promise<void> {
        if (propertyId === "asset") {
            const assistantId = widget.properties.assistantId;
            if (!assistantId) {
                throw new Error(`No assistantId for widget ${widget.id} / ${widget.xtype}`);
            }
            await api.post("/assistant/asset/add", {
                assistantId, 
                assetId: propertyValue.assetId,
            });            
        }
        else if (propertyId === "instructions") {
            const assistantId = widget.properties.assistantId;
            if (!assistantId) {
                throw new Error(`No assistantId for widget ${widget.id} / ${widget.xtype}`);
            }
            await api.post("/assistant/update", {
                assistantId, 
                instructions: propertyValue,
            });            
        }
    },    

    //
    // Callback when a widget of this type is added to the page.
    //
    async onAdded(widget, campaign, updateProperties, api): Promise<void> {
        const { assistantId } = await api.post<{ assistantId: string }>("/assistant/create", {
            widgetId: widget.id,
        });

        updateProperties({
            assistantId,
        });
    },

    //
    // Callback when a widget of this type is removed from the page.
    //
    async onRemoved(widget, campaign, api): Promise<void> {
        const assistantId = widget.properties.assistantId;
        if (!assistantId) {
            return;
        }

        await api.post("/assistant/delete", {
            assistantId,            
        });
    },

    template:  {
        id: nanoid(),
        xtype: 'chatbot@1',
        properties: {
            alignSelf: "center",
            width: 500,
            height: 400,
        },
        children: [
            {
                id: nanoid(),
                name: "ai-message",
                xtype: "group",
                properties: {
                    flexDirection: "row",
                    alignSelf: "stretch",
                    paddingRight: emToPt(1),
                    gap: emToPt(0.75),
                    marginTop: emToPt(1),
                    marginBottom: emToPt(1),
                },
                children: [
                    {
                        id: nanoid(),
                        name: "icon",
                        xtype: "image",
                        properties: {
                            width: pxToPt(20),
                            height: pxToPt(20),
                            flexShrink: "0",
                            image: {
                                url: "/icons/chatbot/ai.svg",
                            },
                        },
                    },
                    {
                        id: nanoid(),
                        name: "message",
                        xtype: "group",
                        properties: {
                            flexDirection: "column",
                            alignSelf: "stretch",
                        },
                        children: [
                            {
                                id: nanoid(),
                                name: "role",
                                xtype: "text",
                                properties: {
                                    content: "AI",
                                    fontWeight: "bold",
                                },
                            },
                            {
                                id: nanoid(),
                                name: "text",
                                xtype: "text",
                                attributes: {
                                    "x-text": "message.text",
                                },
                                properties: {
                                    content: "How can I help you today?",
                                },
                            },
                        ],
                    },
                ],
            },
            {
                id: nanoid(),
                name: "user-message",
                xtype: "group",
                properties: {
                    flexDirection: "row",
                    alignSelf: "stretch",
                    paddingRight: emToPt(1),
                    gap: emToPt(0.75),
                    marginTop: emToPt(1),
                    marginBottom: emToPt(1),
                },
                children: [
                    {
                        id: nanoid(),
                        name: "icon",
                        xtype: "image",
                        properties: {
                            width: pxToPt(20),
                            height: pxToPt(20),
                            flexShrink: "0",
                            image: {
                                url: "/icons/chatbot/user.svg",
                            },
                        },
                    },
                    {
                        id: nanoid(),
                        name: "message",
                        xtype: "group",
                        properties: {
                            flexDirection: "column",
                            alignSelf: "stretch",
                        },
                        children: [
                            {
                                id: nanoid(),
                                name: "role",
                                xtype: "text",
                                properties: {
                                    content: "Customer",
                                    fontWeight: "bold",
                                },
                            },
                            {
                                id: nanoid(),
                                name: "text",
                                xtype: "text",
                                attributes: {
                                    "x-text": "message.text",
                                },
                                properties: {
                                    content: "What services do you offer?",
                                },
                            },
                        ],                                    
                    },
                ],
            },
            {
                id: nanoid(),
                name: "chat-input",
                xtype: "group",
                properties: {
                    flexDirection: "row",
                    alignSelf: "stretch",
                    alignItems: "center",
                },
                children: [
                    {
                        id: nanoid(),
                        name: "input",
                        xtype: "input",
                        attributes: {
                            "x-model": "inputText",
                            "x-on:keydown": "onKeyDown($event)",
                        },
                        properties: {
                            alignSelf: "center",
                            placeholder: "Type your message",
                            paddingTop: emToPt(0.5),
                            paddingBottom: emToPt(0.5),
                            paddingLeft: emToPt(0.75),
                            paddingRight: emToPt(0.75),
                        },
                    },
                    {
                        id: nanoid(),
                        name: "send-button",
                        xtype: "button",
                        attributes: {
                            "x-on:click": "onSendMessage()",                            
                        },
                        properties: {
                            alignSelf: "center",
                        },
                        children: [
                            {
                                id: nanoid(),
                                name: "text",
                                xtype: "text",
                                properties: {
                                    content: "Send",
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    },    
    properties: [
        {
            id: "asset",
            name: "Asset",
            type: "asset",
            fileTypes: [ "pdf" , "csv" ],
            required: true,
            defaultValue: "",
        },
        {
            id: "instructions",
            name: "Instructions",
            type: "textarea",
            required: true,
            defaultValue: "",
        },
    ],
    propertyCategories: [
        {
            name: "Placement",
            properties: [
                stdElementAlignment,
                stdPadding,
                stdMargin,
                stdWidth,
                stdHeight,
            ],
        },
    ],
};

export default widgetType;
