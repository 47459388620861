import { AnimatePresence } from 'framer-motion'
import React, { ChangeEvent, FC, memo } from 'react'
import { AnalyticContainer, AnalyticsPane } from '../../pages/Dashboard/styles'
import SearchInput from '../common/SearchInput'
import AnalyticChip from './AnalyticChip'
import { ICampaign } from 'widgets-base'

interface Props {
    campaigns: ICampaign[];
    searchValue: string;
    handleChangeSearchValue: (e: ChangeEvent<HTMLInputElement>) => void
}

const Analytics: FC<Props> = ({ campaigns, searchValue, handleChangeSearchValue }) => {

    const publishedCampaigns = campaigns.filter(
        (campaign: ICampaign) => campaign.status === 'published'
    )

    const initialValue = 0
    const totalViewsSum = publishedCampaigns.reduce(
        (accumulator, currentValue) => accumulator + currentValue.scans,
        initialValue
    )

    const transition = { duration: 0.5, ease: [0.43, 0.13, 0.23, 0.96] }

    const containerVariants = {
        enter: { transition: { staggerChildren: 0.3 } },
        exit: { transition: { staggerChildren: 0.3 } },
    }

    const analyticPreviewVariants = {
        initial: { y: '100%', opacity: 0 },
        enter: { y: '0', opacity: 1, transition },
        exit: { y: '-100%', opacity: 0, transition },
    }

    return (
        <AnalyticsPane initial="initial" animate="enter" exit="exit" variants={containerVariants}>
            <AnimatePresence initial={true}>
                <AnalyticContainer variants={analyticPreviewVariants}>
                    <AnalyticChip
                        icon="qr"
                        iconColor="#FF7D34"
                        count={totalViewsSum || 0}
                        description="Total QR code scans"
                    />
                    <AnalyticChip
                        icon="layers"
                        iconColor="#3AE29B"
                        count={campaigns.length || 0}
                        description="Total number of campaigns"
                    />
                </AnalyticContainer>
            </AnimatePresence>
            <SearchInput
                value={searchValue}
                onChange={handleChangeSearchValue}
                placeholder="Search project"
                width="400px"
            />
        </AnalyticsPane>
    )
}

export default memo(Analytics)
