import { Divider } from '@mui/material'
import { Link } from 'react-router-dom'
import { RouteNames } from '../../../router'
import { PageContainer } from './styles'

const DocFonts = () => {
    return (
        <PageContainer>
            <h1>Brand fonts</h1>
            <span>
                The{' '}
                <Link to={RouteNames.BRAND}>
                    <strong>brand settings page</strong>
                </Link>{' '}
                is for customizing your brand designs. Add your own fonts.
                <br />
                All settings are available only for your account and do not apply to other users of
                the platform.
            </span>
            <Divider />
            <div className="content">
                <h2>Choose a font</h2>
                <p>
                    We provide a wide selection of fonts. Over 1000+ fonts to choose from to style
                    the text part. They are also available when configuring widgets. You can choose
                    a different font for each settings widget individually in widget editing mode.
                    All fonts are loaded for each widget separately. The font selection component
                    has a search bar for quickly finding the desired font by name.
                </p>
                <img className="image" src="/images/docs/fonts.png" alt="font" />
                <h2>Upload your branded font</h2>
                <p>
                    Want to use your branded font? On the brand settings page, you can download and
                    save branded fonts. To do this, click on the Upload new custom font button and
                    select a branded font file. You can add multiple branded font files. After that,
                    you will see a list of added files.
                </p>
                <img className="image" src="/images/docs/custom-font.png" alt="custom font" />
                <p>
                    At this stage, you can delete the font if you do not need it by clicking on the
                    cross icon. In order for brand fonts to be available in widgets in the future,
                    you need to save changes to the brand settings. In the future, branded fonts
                    will be added to the general list of fonts and will have the prefix [CUSTOM]. To
                    quickly search for a branded font, use the search bar, it is also available in
                    the widget settings.
                </p>
                <img className="image" src="/images/docs/custom-fonts.png" alt="castom fonts" />
            </div>
        </PageContainer>
    )
}

export default DocFonts
