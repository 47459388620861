import _ from "lodash";

export function BottomSection({ onNext, onBack, onGenerate, title, stepIndex, numSteps }) {

    const numPipsBefore = stepIndex;
    const numPipsAfter = numSteps - stepIndex - 1;

    return (
        <div 
            className="flex flex-row items-center border border-gray-200 bg-slate-50 justify-between gap-5 mt-4 pl-12 pr-16 py-2.5 rounded-lg border-solid"
            style={{
                width: "1200px"
            }}
            >
            <div className="text-stone-300 text-center text-sm font-medium leading-[56.28px] tracking-wide uppercase my-auto">GENERATE AGAIN</div>
        
            <button
                onClick={onGenerate}
                >
                <img
                    style={{ width: "40px", height: "40px" }}
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/59ac433a5b8cfcfe4ceeac1792412ee86a77cb25094dc67bfc12e2e5d147fbe4?apiKey=610a4bec134943f393dd6ffcb27214fb&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/59ac433a5b8cfcfe4ceeac1792412ee86a77cb25094dc67bfc12e2e5d147fbe4?apiKey=610a4bec134943f393dd6ffcb27214fb&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/59ac433a5b8cfcfe4ceeac1792412ee86a77cb25094dc67bfc12e2e5d147fbe4?apiKey=610a4bec134943f393dd6ffcb27214fb&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/59ac433a5b8cfcfe4ceeac1792412ee86a77cb25094dc67bfc12e2e5d147fbe4?apiKey=610a4bec134943f393dd6ffcb27214fb&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/59ac433a5b8cfcfe4ceeac1792412ee86a77cb25094dc67bfc12e2e5d147fbe4?apiKey=610a4bec134943f393dd6ffcb27214fb&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/59ac433a5b8cfcfe4ceeac1792412ee86a77cb25094dc67bfc12e2e5d147fbe4?apiKey=610a4bec134943f393dd6ffcb27214fb&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/59ac433a5b8cfcfe4ceeac1792412ee86a77cb25094dc67bfc12e2e5d147fbe4?apiKey=610a4bec134943f393dd6ffcb27214fb&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/59ac433a5b8cfcfe4ceeac1792412ee86a77cb25094dc67bfc12e2e5d147fbe4?apiKey=610a4bec134943f393dd6ffcb27214fb&" className="aspect-square object-contain object-center w-[38px] overflow-hidden self-stretch shrink-0 max-w-full"
                    alt="Icon"
                />
            </button>
                
            <div className="flex-grow"></div>

            <div className="text-center text-sm font-bold tracking-wider uppercase self-center">
                <span>Build Your experience: </span>
                <span className="font-bold tracking-wider text-orange-400"> {title}</span>
                {/* {numSteps} / {stepIndex} / {numPipsBefore} / {numPipsAfter} */}
            </div>

            <div className="justify-center items-stretch self-center flex gap-1.5 pl-3 pr-12 py-0.5">

                {_.range(numPipsBefore).map(index => {
                    return (
                        <div 
                            key={index} 
                            className="bg-neutral-300 flex flex-col rounded-full" 
                            style={{
                                width: "10px",
                                height: "8px",
                            }}
                            />
                    );
                })}

                <div    
                    className="bg-orange-400 flex flex-col rounded-full" 
                    style={{
                        width: "30px",
                        height: "8px"
                    }}
                    />                

                {_.range(numPipsAfter).map(index => {
                    return (
                        <div
                            key={index} 
                            className="bg-neutral-300 flex flex-col rounded-full" 
                            style={{
                                width: "10px",
                                height: "8px",
                            }}
                            />
                    );
                })}

            </div>
            
            <div className="flex-grow"></div>

            <div className="flex flex-row items-center justify-between gap-5">
                <button 
                    className="text-stone-950 text-center text-sm font-medium tracking-wide uppercase whitespace-nowrap justify-center items-stretch bg-orange-400 grow rounded-2xl p-4"
                    onClick={onNext}
                    >
                    Next
                </button>
                <button 
                    className="text-slate-50 text-center text-sm font-medium tracking-wide uppercase justify-center bg-stone-300 rounded-2xl p-4"
                    onClick={onBack}
                    >
                    Back
                </button>
            </div>
        </div>       
    );
}