import React, { createContext, ReactNode, useContext } from "react";

export interface IDragContextHook {
    //
    // Props to apply to widgets to make the draggable.
    //
    dragProps: any;

    //
    // Sets the ref for the element.
    //
    setRef(el: HTMLElement): void;

    //
    // Selects the widget.
    //
    onSelect(): void;
}

const DragContext = createContext<IDragContextHook>(null);

export interface IProps {
    //
    // Props to apply to widgets to make the draggable.
    //
    dragProps: any;

    //
    // Sets the ref for the element.
    //
    setRef(el: HTMLElement): void;

    //
    // Selects the widget.
    //
    onSelect(): void;

    children: ReactNode | ReactNode[];
}

export function DragContextProvider({ dragProps, setRef, onSelect, children }: IProps) {
   
    const value: IDragContextHook = {
        dragProps,
        setRef,
        onSelect,
    };

    return (
        <DragContext.Provider 
            value={value}
            >
            {children}
        </DragContext.Provider>
    );
}

export function useDragContext(): IDragContextHook {
    const context = useContext(DragContext);
    if (!context) {
        throw new Error(`Drag context is not set! Add DragContextProvider to the component tree.`);
    }
    return context;

}
