import React, { FC, memo, MouseEvent } from 'react'
import styled from 'styled-components'
import { useRedirectAction } from '../../../../hooks/useRedirectAction'
import { IAction } from '../../../../modules/widgets/IAction'
import { useWidget } from 'widgets-base'

interface Props {
    editMode?: boolean
}

const ImageWidget: FC<Props> = ({ editMode = false }) => {

    const { widget } = useWidget();
    const action: IAction = widget.settings.action
    const handleAction = useRedirectAction(action)

    const handleClickOn = (e: MouseEvent<HTMLElement>) => {
        if (!editMode) {
            e.stopPropagation()
            handleAction.action()
        }
    }

    const appearanceSettings = widget?.appearanceSettings
    const width = appearanceSettings?.width + '%'
    const align = appearanceSettings?.align
    const paddingTop = appearanceSettings?.paddingTop / 10 + 'em'
    const paddingBottom = appearanceSettings?.paddingBottom / 10 + 'em'
    const paddingLeft = appearanceSettings?.paddingLeft / 10 + 'em'
    const paddingRight = appearanceSettings?.paddingRight / 10 + 'em'

    const marginTop = appearanceSettings?.marginTop / 10 + 'em'
    const marginBottom = appearanceSettings?.marginBottom / 10 + 'em'
    const marginLeft = appearanceSettings?.marginLeft / 10 + 'em'
    const marginRight = appearanceSettings?.marginRight / 10 + 'em'
    const src = widget.settings.src || '/images/default_image.png'

    const borderTopLeftRadius = appearanceSettings?.borderTopLeftRadius / 10 + 'em'
    const borderBottomLeftRadius = appearanceSettings?.borderBottomLeftRadius / 10 + 'em'
    const borderTopRightRadius = appearanceSettings?.borderTopRightRadius / 10 + 'em'
    const borderBottomRightRadius = appearanceSettings?.borderBottomRightRadius / 10 + 'em'

    const shiftRight = appearanceSettings?.boxShadow?.shiftRight
    const shiftDown = appearanceSettings?.boxShadow?.shiftDown
    const spread = appearanceSettings?.boxShadow?.spread
    const blur = appearanceSettings?.boxShadow?.blur
    const shadowColor = appearanceSettings?.boxShadow?.colour[0]
    const boxShadow = `${shiftRight}px ${shiftDown}px ${spread}px ${blur}px ${shadowColor}`

    const generateShape = (frame: string) => {
        switch (frame) {
            case 'None':
                return 'none'
            case 'Circle':
                return 'circle()'
            case 'Triangle':
                return 'polygon(50% 0, 100% 100%, 0 100%)'
            case 'Pentagon':
                return 'polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%)'
            case 'Ellipse':
                return 'ellipse()'
            case 'Octagon':
                return 'polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%)'
            case 'Star':
                return 'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 86% 100%, 50% 70%, 17% 100%, 32% 57%, 2% 35%, 39% 35%)'
            case 'Diamond':
                return 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)'
            default:
                return ''
        }
    }

    const frame = generateShape(widget?.settings?.frame) || 'none'

    const styleschild = {
        paddingTop,
        paddingBottom,
        paddingLeft,
        paddingRight,
        marginTop,
        marginBottom,
        marginLeft,
        marginRight,
        borderTopLeftRadius,
        borderBottomLeftRadius,
        borderTopRightRadius,
        borderBottomRightRadius,
    }

    const stylesParent = {
        frame,
        align,
        width,
    }

    return (
        <ImageSection id={widget.id} globalStyle={stylesParent}>
            <img
                src={src}
                alt={widget.name}
                loading="eager"
                style={styleschild}
                onClick={handleClickOn}
            />
        </ImageSection>
    )
}

export default memo(ImageWidget)

const ImageSection = styled.section<{
    globalStyle: { frame: string; align: string; width: string }
}>`
    width: inherit;
    display: flex;
    flex-direction: column;
    align-items: ${(props) => props.globalStyle.align};
    justify-content: center;

    img {
        max-width: 100%;
        -webkit-clip-path: ${(props) => props.globalStyle.frame};
        clip-path: ${(props) => props.globalStyle.frame};
        width: ${(props) => props.globalStyle.width};
        object-fit: cover;
    }
`
