import {Divider} from '@mui/material'
import {FC} from 'react'
import styled from 'styled-components'
import { useWidget } from 'widgets-base';

interface Props {
}

const ColumnTextWidget: FC<Props> = () => {

    const { widget } = useWidget();
    const fontFamily = widget.appearanceSettings?.font?.family || 'Poppins'
    const modFontFamily = fontFamily?.replace(/\s/g, '+')
    const fontUrl = widget.appearanceSettings?.font?.url

    const appearanceSettings = widget?.appearanceSettings
    const colourSettings = widget?.colourSettings
    const fontSize = appearanceSettings?.font?.size
    const align = appearanceSettings?.align
    const marginTop = appearanceSettings?.marginTop / 10 + 'em'
    const marginBottom = appearanceSettings?.marginBottom / 10 + 'em'
    const marginLeft = appearanceSettings?.marginLeft / 10 + 'em'
    const marginRight = appearanceSettings?.marginRight / 10 + 'em'
    const paddingTop = appearanceSettings?.paddingTop / 10 + 'em'
    const paddingBottom = appearanceSettings?.paddingBottom / 10 + 'em'
    const paddingLeft = appearanceSettings?.paddingLeft / 10 + 'em'
    const paddingRight = appearanceSettings?.marginRight / 10 + 'em'
    const weight = appearanceSettings?.font?.weight || 300
    const letterSpacing = appearanceSettings?.font?.spacing + 'px' || '8px'
    const lineHeight = appearanceSettings?.font?.lineHeight || 1
    const color = colourSettings?.textColour[0]

    const stylesChild = {
        weight,
        letterSpacing,
        lineHeight,
    }

    const stylesParent = {
        align,
        color,
        paddingTop,
        paddingBottom,
        paddingLeft,
        paddingRight,
        marginTop,
        marginBottom,
        marginLeft,
        marginRight,
        fontSize,
        fontFamily,
    }

    return (
        <TextSection id={widget.id}>
            <style>
                {!fontUrl
                    ? `@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`
                    : `@font-face {
            font-family: '${fontFamily}';
            src: url('${fontUrl}');
            font-style: normal;
          }
          
          .header-text {
            font-family: '${fontFamily}';
          }`}
            </style>
            <Container globalStyles={stylesParent} contentStyles={stylesChild}>
                <h1>{widget.settings.title}</h1>
                <Divider/>
                <div className="content">
                    <article>
                        <p>{widget.settings.first_сolumn}</p>
                    </article>
                    <article>
                        <p>{widget.settings.second_column}</p>
                    </article>
                </div>
            </Container>
        </TextSection>
    )
}

export default ColumnTextWidget

const TextSection = styled.section`
    width: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
`

const Container = styled.div<{
    globalStyles: {
        align: string
        color: string
        paddingTop: string
        paddingBottom: string
        paddingLeft: string
        paddingRight: string
        marginTop: string
        marginBottom: string
        marginLeft: string
        marginRight: string
        fontSize: number
        fontFamily: string
    }
    contentStyles: {
        weight: string | number
        letterSpacing: string
        lineHeight: number
    }
}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 700px;
    width: 100%;
    gap: 15px;
    font-family: ${(props) => props.globalStyles.fontFamily};
    color: ${(props) => props.globalStyles.color};
    margin-top: ${(props) => props.globalStyles.marginTop};
    margin-bottom: ${(props) => props.globalStyles.marginBottom};
    margin-left: ${(props) => props.globalStyles.marginLeft};
    margin-right: ${(props) => props.globalStyles.marginRight};
    padding-top: ${(props) => props.globalStyles.paddingTop};
    padding-left: ${(props) => props.globalStyles.paddingLeft};
    padding-bottom: ${(props) => props.globalStyles.paddingBottom};
    padding-right: ${(props) => props.globalStyles.paddingRight};
    font-weight: ${(props) => props.contentStyles.weight};
    font-size: ${(props) => props.globalStyles.fontSize + 'px'};

    hr {
        width: 100%;
    }

    & > h1 {
        font-weight: bold;
        color: ${(props) => props.globalStyles.color};
    }

    .content {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;

        article,
        p {
            text-align: justify;
            color: ${(props) => props.globalStyles.color};
            letter-spacing: ${(props) => props.contentStyles.letterSpacing};
            line-height: ${(props) => props.contentStyles.lineHeight};
        }
    }
`
