import { nanoid } from "nanoid";
import { useRef, useEffect } from "react";
import { makeStyledElement, useWidget, useRenderContext, usePageTheme, useDragContext, IWidgetType, emToPt, pxToEm, stdElementAlignment, stdPadding, stdMargin, stdWidth, stdHeight } from "widgets-base";

declare var spinWheel: any;

const Div = makeStyledElement("div");

//
// Create props for the spin to win wheel.
//
function buildProps(properties: any): any {
    const items = properties.items.map((item) => ({ label: item.text }));
    const itemLabelColors = properties.items.map((item) => item.color);
    const itemBackgroundColors = properties.items.map((item) => item.backgroundColor);
    const props = {
        radius: properties.radius,
        itemLabelRadius: properties.itemLabelRadius,
        itemLabelRadiusMax: properties.itemLabelRadiusMax,
        itemLabelRotation: properties.itemLabelRotation,
        itemLabelAlign: properties.itemLabelAlign,
        itemLabelColors,
        itemLabelBaselineOffset: -0.07,
        itemLabelFont: 'Amatic SC',
        itemLabelFontSizeMax: properties.itemLabelFontSizeMax,
        itemBackgroundColors,
        lineWidth: properties.lineWidth,
        lineColor: properties.lineColor,
        items: items,
    };
    return props;
}

function Static() {

    const { widget, properties, expandedProperties } = useWidget();
    const { renderContext } = useRenderContext();
    const { pageTheme } = usePageTheme();
    
    return (
        <Div
            data-type="spin-to-win@1"
            widgetId={widget.id}
            elementName="image"
            variantName={properties?.variant || "default"}
            pageTheme={pageTheme}
            properties={expandedProperties}
            renderContext={renderContext}
            data-props={JSON.stringify(buildProps(properties))}
            data-style={properties.style}
            style={{
                maxWidth: "100%",
            }}
            />
    );
}

function Preview() {

    const { widget, properties, expandedProperties } = useWidget();
    const { renderContext } = useRenderContext();
    const { pageTheme } = usePageTheme();
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const styleIndex = parseInt(properties.style)-1;
        const props = buildProps(properties);
        props.image = `/widgets/spin-to-win-1/img/example-${styleIndex}-image.svg`;
        props.overlayImage = `/widgets/spin-to-win-1/img/example-${styleIndex}-overlay.svg`;
        const wheel = new spinWheel.Wheel(containerRef.current, props);

        return () => {
            wheel.remove();
        }
    }, [properties]);

    return (
        <Div 
            widgetId={widget.id}
            elementName="div"
            variantName={properties?.variant || "default"}
            pageTheme={pageTheme}
            properties={expandedProperties}
            renderContext={renderContext}
            style={{
                position: "relative",
                width: expandedProperties.width,
                height: expandedProperties.height,
                overflow: "hidden",
                userSelect: "none",
                maxWidth: "100%",
            }}
            >
            <div 
                ref={containerRef}
                style={{
                    width: "100%",
                    height: "100%",
                }}                
                >
            </div>
        </Div>
    );
}

function Editor() {

    const { widget, properties, expandedProperties } = useWidget();
    const { renderContext } = useRenderContext();
    const { pageTheme } = usePageTheme();
    const { dragProps, setRef, onSelect } = useDragContext();
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const styleIndex = parseInt(properties.style)-1;
        const props = buildProps(properties);
        props.image = `/widgets/spin-to-win-1/img/example-${styleIndex}-image.svg`;
        props.overlayImage = `/widgets/spin-to-win-1/img/example-${styleIndex}-overlay.svg`;
        const wheel = new spinWheel.Wheel(containerRef.current, props);

        return () => {
            wheel.remove();
        }
    }, [properties]);

    return (
        <Div 
            {...dragProps}
            ref={setRef}
            onClick={event => {
                event.stopPropagation();
                event.preventDefault();

                onSelect();
            }}
            widgetId={widget.id}
            elementName="div"
            variantName={properties?.variant || "default"}
            pageTheme={pageTheme}
            properties={expandedProperties}
            renderContext={renderContext}
            style={{
                position: "relative",
                width: expandedProperties.width,
                height: expandedProperties.height,
                overflow: "hidden",
                userSelect: "none",
                maxWidth: "100%",
            }}
            >
            <div 
                ref={containerRef}
                style={{
                    width: "100%",
                    height: "100%",
                }}                
                >
            </div>
        </Div>
    );
}

const widgetType: IWidgetType = {
    name: 'Spin to Win',
    Static: Static,
    Preview: Preview,
    Editor: Editor,
    Template: Static,
    collectAssets: (widget, assetIds, assetUrls) => {
        //TODO:
    },
    template:  {
        id: nanoid(),
        xtype: 'spin-to-win@1',
        properties: {
            alignSelf: "center",
            width: emToPt(pxToEm(600)),
            height: emToPt(pxToEm(600)),
            style: "1",
            items: [
                {
                    text: "Prize 1",
                    color: "#fff",
                    backgroundColor: "#ffc93c",
                },
                {
                    text: "Prize 2",
                    color: "#fff",
                    backgroundColor: "#66bfbf",
                },
                {
                    text: "Prize 3",
                    color: "#fff",
                    backgroundColor: "#a2d5f2",
                },
                {
                    text: "Prize 4",
                    color: "#fff",
                    backgroundColor: "#515070",
                },
            ],

            radius: 0.84,
            itemLabelRadius: 0.93,
            itemLabelRadiusMax: 0.35,
            itemLabelRotation: 180,
            itemLabelAlign: "left",
            itemLabelBaselineOffset: 0,
            itemLabelFontSizeMax: 55,
            lineWidth: 1,
            lineColor: '#fff',        
        },
    },    
    properties: [
        {
            id: "style",
            name: "Style",
            type: "option",
            defaultValue: "1",
            values: [
                {
                    name: "1",
                    value: "1",
                },
                {
                    name: "2",
                    value: "2",
                },
                {
                    name: "3",
                    value: "3",
                },
                {
                    name: "4",
                    value: "4",
                },
            ],
            required: true,    
            orientation: "vertical",
        },
        {
            id: "items",
            name: "Items",
            type: "array",
            required: true,
            properties: [
                {
                    id: "text",
                    name: "Text",
                    type: "text",
                    required: true,
                    defaultValue: "",
                },
                {
                    id: "color",
                    name: "Color",
                    type: "color",
                    defaultValue: "#fff",
                    orientation: "horizontal",
                },
                {
                    id: "backgroundColor",
                    name: "Background color",
                    type: "color",
                    defaultValue: "#ffc93c",
                    orientation: "horizontal",
                },
            ],
        }
    ],
    propertyCategories: [
        {
            name: "Wheel style",
            properties: [
                {
                    id: "radius",
                    name: "Radius",
                    type: "number",
                    unit: "pt",
                    defaultValue: 0.84,
                    orientation: "vertical",
                },
                {
                    id: "itemLabelRadius",
                    name: "Item radius",
                    type: "number",
                    unit: "pt",
                    defaultValue: 0.93,
                    orientation: "vertical",
                },
                {
                    id: "itemLabelRadiusMax",
                    name: "Item radius max",
                    type: "number",
                    unit: "pt",
                    defaultValue: 0.35,
                    orientation: "vertical",
                },
                {
                    id: "itemLabelRotation",
                    name: "Item text rotation",
                    type: "number",
                    unit: "pt",
                    defaultValue: 180,
                    orientation: "vertical",
                },
                {
                    id: "itemLabelAlign",
                    name: "Text alignment",
                    type: "option",
                    defaultValue: "left",
                    values: [
                        {
                            name: "Left",
                            value: "left",
                        },
                        {
                            name: "Center",
                            value: "center",
                        },
                        {
                            name: "Right",
                            value: "right",
                        },
                    ],
                    required: true,    
                    orientation: "vertical",
                },
                {
                    id: "itemLabelBaselineOffset",
                    name: "Item label baseline offset",
                    type: "number",
                    unit: "pt",
                    defaultValue: -0.07,
                    orientation: "vertical",
                },               
                {
                    id: "itemLabelFontSizeMax",
                    name: "Item label font size max",
                    type: "number",
                    unit: "pt",
                    defaultValue: 55,
                    orientation: "vertical",
                },               
                {
                    id: "lineWidth",
                    name: "Line width",
                    type: "number",
                    unit: "pt",
                    defaultValue: 55,
                    orientation: "vertical",                    
                },  
                {
                    id: "lineColor",
                    name: "Line color",
                    type: "color",
                    defaultValue: "#fff",
                    orientation: "horizontal",
                }             
            ],
        },
        {
            name: "Placement",
            properties: [
                stdElementAlignment,
                stdPadding,
                stdMargin,
                stdWidth,
                stdHeight,
            ],
        },
    ],
};

export default widgetType;
