import http from '../../../lib/http';
import { IAsset } from '../../modules/branding/IAssets';

export async function clientUpload(file: File): Promise<IAsset> {

    //todo: Automatically resize the asset on upload.
    
    const { data } = await http.post('/asset', file, { 
        headers: {
            'Content-Type': file.type,
            'File-Name': file.name, 
        },
    });
    
    return data;
}
