import { motion } from 'framer-motion'
import { FC, useState, ChangeEvent } from 'react'
import styled from 'styled-components'
import { useWidget } from 'widgets-base'

interface Props {
    topRightValue: number
    bottomRightValue: number
    topLeftValue: number
    bottomLeftValue: number
    label?: string
    isImage?: boolean
}

const BorderRadius: FC<Props> = ({
    topRightValue = 0,
    bottomRightValue = 0,
    topLeftValue = 0,
    bottomLeftValue = 0,
    label = 'Border radius',
    isImage = false,
}) => {

    const { widget, setWidget } = useWidget();
    const [value, setValue] = useState<number | string>('')
    const [isCustomBorder, setCustomState] = useState<boolean>(false)

    const checkCustomState = () => {
        setCustomState((prev) => !prev)
    }

    const handleChangeAllSpacing = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = +event.target.value
        setValue(newValue)

        let newWidget;
        if (!isImage) {
            newWidget = {
                ...widget,
                appearanceSettings: {
                    ...widget.appearanceSettings,
                    borderTopLeftRadius: newValue,
                    borderTopRightRadius: newValue,
                    borderBottomLeftRadius: newValue,
                    borderBottomRightRadius: newValue,
                },
            }
        } else {
            newWidget = {
                ...widget,
                appearanceSettings: {
                    ...widget.appearanceSettings,
                    image: {
                        ...widget.appearanceSettings.image,
                        borderTopLeftRadius: newValue,
                        borderTopRightRadius: newValue,
                        borderBottomLeftRadius: newValue,
                        borderBottomRightRadius: newValue,
                    }
                },
            }
        }

        setWidget(newWidget);
    }

    const handleChangeCustomSpacing = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = +event.target.value
        const name = event.target.name

        let newWidget;
        if (!isImage) {
            newWidget = {
                ...widget,
                appearanceSettings: {
                    ...widget.appearanceSettings,
                    [name]: newValue,
                },
            }
        } else {
            newWidget = {
                ...widget,
                appearanceSettings: {
                    ...widget.appearanceSettings,
                    image: {
                        ...widget.appearanceSettings.image,
                        [name]: newValue,
                    }
                },
            }
        }

        setWidget(newWidget);
    }

    return (
        <Container>
            <label>{label}</label>
            <ActiveContainer isCustomBorder={isCustomBorder}>
                <div className="field">
                    <svg
                        width="10"
                        height="9"
                        viewBox="0 0 10 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M1 9V4.5C1 2.29086 2.79086 0.5 5 0.5H9.5" stroke="#B6B6B6" />
                    </svg>
                    <input
                        disabled={isCustomBorder}
                        placeholder="0"
                        type="number"
                        name="all"
                        value={value}
                        onChange={handleChangeAllSpacing}
                    />
                </div>
                <div className="icon" onClick={checkCustomState}>
                    <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1 4.33333V1H4.33333"
                            stroke={isCustomBorder ? '#6478F9' : '#717F95'}
                            strokeLinecap="round"
                        />
                        <path
                            d="M1 9.66667V13H4.33333"
                            stroke={isCustomBorder ? '#6478F9' : '#717F95'}
                            strokeLinecap="round"
                        />
                        <path
                            d="M13 4.33333V1H9.66667"
                            stroke={isCustomBorder ? '#6478F9' : '#717F95'}
                            strokeLinecap="round"
                        />
                        <path
                            d="M13 9.66667V13H9.66667"
                            stroke={isCustomBorder ? '#6478F9' : '#717F95'}
                            strokeLinecap="round"
                        />
                    </svg>
                </div>
            </ActiveContainer>

            {isCustomBorder && (
                <MultiSelectContainer
                    initial={{ opacity: 0, y: 50, scale: 0.3 }}
                    animate={{ opacity: 1, y: 0, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
                >
                    <div className="custom">
                        <div className="top-bottom">
                            <div className="flex_container">
                                <div className="field">
                                    <input
                                        name="borderTopLeftRadius"
                                        type="number"
                                        value={topLeftValue}
                                        onChange={handleChangeCustomSpacing}
                                    />
                                    <span>px</span>
                                </div>
                                <span>Top left</span>
                            </div>
                            <div className="flex_container">
                                <div className="field">
                                    <input
                                        name="borderTopRightRadius"
                                        type="number"
                                        value={topRightValue}
                                        onChange={handleChangeCustomSpacing}
                                    />
                                    <span>px</span>
                                </div>
                                <span>Top right</span>
                            </div>
                        </div>
                        <div className="right-left">
                            <div className="flex_container">
                                <div className="field">
                                    <input
                                        name="borderBottomLeftRadius"
                                        type="number"
                                        value={bottomLeftValue}
                                        onChange={handleChangeCustomSpacing}
                                    />
                                    <span>px</span>
                                </div>
                                <span>Bottom left</span>
                            </div>
                            <div className="flex_container">
                                <div className="field">
                                    <input
                                        name="borderBottomRightRadius"
                                        type="number"
                                        value={bottomRightValue}
                                        onChange={handleChangeCustomSpacing}
                                    />
                                    <span>px</span>
                                </div>
                                <span>Bottom right</span>
                            </div>
                        </div>
                    </div>
                </MultiSelectContainer>
            )}
        </Container>
    )
}

export default BorderRadius

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #717f95;

    & > label {
        font-size: 12px;
        color: #3c4b61;
        margin-bottom: 7px;
        font-weight: bold;
    }
`

const ActiveContainer = styled.div<{
    isCustomBorder: boolean
}>`
    display: flex;
    height: 32px;

    .field {
        display: flex;
        align-items: center;
        padding: 5px;
        width: 65px;
        background: #ffffff;
        border: 1px solid #dfe2f2;
        border-radius: 8px;
        margin-right: 6px;
        opacity: ${(props) => (props.isCustomBorder ? 0.5 : 1)};
        input {
            border: none;
            width: 25px;
            font-size: 12px;
            color: #3c4b61;
            font-weight: bold;
        }

        svg {
            margin: 0 8px;
        }
    }

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        border: ${(props) => (props.isCustomBorder ? `2px solid #6478F9` : `1px solid #dfe2f2`)};
        border-radius: 8px;
        width: 32px;
        height: 32px;
        cursor: pointer;
    }
`

const MultiSelectContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    width: 100%;

    .custom {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        .right-left,
        .top-bottom,
        .flex_container {
            display: flex;
            flex-direction: column;
            align-items: center;

            & > span {
                margin-top: 2px;
                font-size: 12px;
                line-height: 14px;
                color: #717f95;
            }

            .field {
                display: flex;
                align-items: center;
                justify-content: center;
                background: #ffffff;
                border: 1px solid #dfe2f2;
                border-radius: 8px;
                padding: 5px;
                width: 78px;
                height: 32px;
                background: #ffffff;
                border: 1px solid #dfe2f2;
                border-radius: 8px;

                input {
                    border: none;
                    width: 40px;
                    font-size: 12px;
                    color: #3c4b61;
                    font-weight: bold;
                }
                span {
                    font-size: 12px;
                    color: #3c4b61;
                    border-left: 1px solid #dfe2f2;
                    padding: 0 5px;
                    font-weight: bold;
                }
            }
        }

        .right-left,
        .top-bottom {
            width: 100%;
            flex-direction: row;
            justify-content: space-around;
        }
    }
`
