import { Button } from "@mui/material";
import { SeedQuestions } from "../seed-questions";

export function InitialCopy({ onNext, onBack, seeds, answers, setAnswer, imageSearch, setImageSearch }) {

    return (
        <div className="flex flex-col overflow-hidden">
            <div 
                className="flex flex-col h-screen"
                style={{ width: "400px" }}
                >
                <SeedQuestions
                    seeds={seeds}
                    answers={answers}
                    setAnswer={setAnswer}
                    imageSearch={imageSearch}
                    setImageSearch={setImageSearch}
                    />

                <div className="flex flex-row mt-6">
                    <Button
                        variant="contained"
                        onClick={onNext}
                        >
                        Continue
                    </Button>

                    <Button
                        sx={{ ml: 1 }}
                        variant="outlined"
                        onClick={onBack}
                        >
                        Back
                    </Button>

                </div>
            </div>
        </div>
    );
}

