import { Rating } from '@mui/material'
import { memo } from 'react'
import styled from 'styled-components'
import { useForm } from '../../../../../../builder/context/form-state-old'
import { IWidget } from 'widgets-base'


interface Props {
    widget?: IWidget
}

const RatingComponent = ({ widget }: Props) => {
    
    const { getFieldValue, setFieldValue } = useForm();

    const name = widget?.settings?.name
    
    const handleRatingChange = (_event, rating): void => {
        setFieldValue(name, rating);
    }

    const max = parseInt(widget?.settings?.numberOfRatings)
    const size = widget?.appearanceSettings?.defaultSize
    const label = widget?.settings?.label
    const labelSize = widget?.appearanceSettings?.labelSize

    const width = widget?.appearanceSettings?.width + '%'
    const font = { family: 'Poppins' }
    const modFontFamily = font?.family?.replace(/\s/g, '+')

    const paddingTop = widget?.appearanceSettings?.paddingTop / 10 + 'em'
    const paddingBottom = widget?.appearanceSettings?.paddingBottom / 10 + 'em'
    const paddingLeft = widget?.appearanceSettings?.paddingLeft / 10 + 'em'
    const paddingRight = widget?.appearanceSettings?.paddingRight / 10 + 'em'
    const marginTop = widget?.appearanceSettings?.marginTop / 10 + 'em'
    const marginBottom = widget?.appearanceSettings?.marginBottom / 10 + 'em'
    const marginLeft = widget?.appearanceSettings?.marginLeft / 10 + 'em'
    const marginRight = widget?.appearanceSettings?.marginRight / 10 + 'em'
    const alignment = widget?.appearanceSettings?.alignment
    const color = Array.isArray(widget?.colourSettings['icon colour'])
        ? widget?.colourSettings['icon colour']?.[0]
        : widget?.colourSettings['icon colour']

    return (
        <Wrapper
            align={alignment}
            marginTop={marginTop}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            marginRight={marginRight}
            fontFamily={font?.family}
        >
            <style>
                {`@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`}
            </style>
            <Container
                width={width}
                paddingTop={paddingTop}
                paddingBottom={paddingBottom}
                paddingLeft={paddingLeft}
                paddingRight={paddingRight}
                marginTop={marginTop}
                marginBottom={marginBottom}
                marginLeft={marginLeft}
                marginRight={marginRight}
            >
                <label
                    style={{
                        width: '100%',
                        fontSize: labelSize,
                        fontFamily: font?.family,
                        marginBottom: '0.5em',
                        color,
                    }}
                >
                    {label}
                </label>

                <div style={{ width: '100%' }}>
                    <Rating
                        name={name}
                        max={max}
                        size={size}
                        color={color}
                        value={Number(getFieldValue(name)) || Number(widget?.settings?.defaultValue)}
                        onChange={handleRatingChange}
                    />
                </div>
            </Container>
        </Wrapper>
    )
}

export default memo(RatingComponent)

const Wrapper = styled.div<{
    align: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
    fontFamily: string
}>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;
    flex-direction: column;
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    font-family: ${(props) => props.fontFamily};
`
const Container = styled.div<{
    width: string
    paddingTop: string
    paddingBottom: string
    paddingLeft: string
    paddingRight: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
}>`
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    width: ${(props) => props.width};
    padding-top: ${(props) => props.paddingTop};
    padding-bottom: ${(props) => props.paddingBottom};
    padding-left: ${(props) => props.paddingLeft};
    padding-right: ${(props) => props.paddingRight};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    input {
        width: 100%;
        border-radius: inherit;
    }
`
