import { Divider } from '@mui/material'
import { Link } from 'react-router-dom'
import { RouteNames } from '../../../router'
import { PageContainer } from './styles'

const DocEmbedCampaign = () => {
    return (
        <PageContainer>
            <h1>Embedding campaign</h1>
            <span>Place the created deployed campaigns on your pages or blog.</span>
            <Divider />
            <div className="content">
                <p>
                    The embed feature is only available for deployed campaigns. You can't embed a
                    draft because drafts don't have links generated for live visits yet. On the
                    dashboard page, on the Published tab, select the campaign you want to embed.
                    Click on the three dots on the campaign card to open the pop-up menu. Select
                    Embed from the list of options. Please note that this feature is available for
                    paid subscriptions.
                </p>
                <img className="image" src="/images/docs/embed-campaign.png" alt="embed campaign" />
                <p>
                    We move on. In the pop-up window, you will see the code to embed on your pages.
                    Of course, we do not offer to embed the entire campaign as a whole. It will take
                    up quite a lot of space and may not look very good. Therefore, we offer two
                    accommodation options to choose from.
                </p>
                <img
                    className="image"
                    src="/images/docs/embed-campaign1.png"
                    alt="embed campaign"
                />
                <p>
                    The first option corresponds to the pre-campaign card and looks like the one
                    shown in the picture below. It will show the name of the campaign, the campaign
                    thumbnail, as well as the description of the campaign that was specified in the
                    campaign settings when it was created in the constructor. Read more about this
                    in the{' '}
                    <Link to={RouteNames.DOC_CREATE_CAMPAIGN}>
                        <strong>campaign creation</strong>
                    </Link>{' '}
                    section. The View Campaign button will redirect you to the campaign you created
                    with a list of built widgets. The redirect to the campaign details will be
                    performed in a new browser tab.
                </p>
                <img
                    className="image"
                    src="/images/docs/embed-campaign2.png"
                    alt="embed campaign"
                />
                <p>
                    The second version of the embed code generates a button component. This is more
                    suitable for those who do not need extra information about the company, but
                    simply a link to some product or form for customers.
                </p>
                <img
                    className="image"
                    src="/images/docs/embed-campaign3.png"
                    alt="embed campaign"
                />
                <p>
                    We are constantly trying to improve our functionality. Therefore, if you have
                    any questions or suggestions for improving the embedding, please contact us via
                    the{' '}
                    <Link to={RouteNames.FEEDBACK}>
                        <strong>feedback</strong>
                    </Link>{' '}
                    form.
                </p>
            </div>
        </PageContainer>
    )
}

export default DocEmbedCampaign
