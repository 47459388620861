import React from 'react'
import styled, { keyframes } from 'styled-components'

export const LoaderRocket = () => {
    return (
        <div>
            <Wrapper>
                <svg
                    width="92"
                    height="92"
                    viewBox="0 0 92 92"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M91.7 3.90012C91.5 1.90012 90 0.400115 88 0.200115C62.7 -1.79989 44.6 10.5001 33.3 25.9001C27.1 23.9001 20.4 24.6001 14.3 28.0001C8.4 31.3001 3.5 36.9001 0.3 43.7001C-0.4 45.3001 -0.1 47.1001 1.2 48.3001C2.4 49.5001 4.3 49.7001 5.8 48.9001C12.2 45.5001 19.4 46.2001 23.7 50.5001L41.3 68.1001L41.4 68.2001C45.7 72.5001 46.4 79.7001 43 86.1001C42.2 87.6001 42.5 89.5001 43.6 90.7001C44.4 91.5001 45.4 91.9001 46.5 91.9001C47.1 91.9001 47.7 91.8001 48.2 91.5001C55 88.3001 60.6 83.3001 63.9 77.5001C67.4 71.4001 68.1 64.7001 66 58.4001C70.5 55.1001 74.6 51.5001 78 47.5001C88.6 35.4001 93.2 20.7001 91.7 3.90012ZM13.4 38.8001C17.7 34.6001 23.1 32.0001 28.8 33.0001C27.2 35.8001 25.9 38.6001 24.7 41.4001C21.3 39.5001 17.4 38.6001 13.4 38.8001ZM53.3 78.6001C53.4 74.5001 52.5 70.6001 50.7 67.2001C53.6 66.0001 56.4 64.6001 59.1 63.1001C60.1 68.8001 57.6 74.3001 53.3 78.6001ZM45.3 60.7001L31.2 46.6001C38.3 27.7001 55.9 7.50012 84 8.00012C84.9 41.5001 57.3 56.0001 45.3 60.7001ZM71.2 20.8001C72.4 22.0001 73.1 23.7001 73.1 25.4001C73.1 27.1001 72.4 28.8001 71.2 30.0001C70 31.2001 68.3 31.9001 66.6 31.9001C64.9 31.9001 63.2 31.3001 62 30.0001C60.8 28.7001 60.1 27.1001 60.1 25.4001C60.1 23.7001 60.8 22.0001 62 20.8001C63.2 19.6001 64.9 18.9001 66.6 18.9001C68.3 18.9001 70 19.6001 71.2 20.8001ZM14.9 62.4001C10.3 67.0001 10.1 76.2001 10.1 78.0001C10.1 80.2001 11.9 82.0001 14.1 82.0001H14.2C16.3 82.0001 25.1 81.7001 29.6 77.2001C32.4 74.4001 33 71.5001 33 69.6001C33 66.9001 31.9 64.3001 29.8 62.2001C24.8 57.1001 18.5 58.7001 14.9 62.4001ZM24 71.5001C23 72.5001 20.8 73.2001 18.5 73.5001C18.9 71.2001 19.6 69.0001 20.5 68.1001C21.2 67.4001 21.8 67.0001 22.5 67.0001C23 67.0001 23.6 67.3001 24.2 67.9001C24.8 68.5001 25.1 69.1001 25.1 69.6001C25 70.2001 24.5 71.0001 24 71.5001Z"
                        fill="#6478F9"
                    />
                </svg>
                <span>Loading...</span>
            </Wrapper>
        </div>
    )
}

const loadDots = keyframes`
  to {
    clip-path: inset(0 -1ch 0 0)
  }
`

const rocketAnimation = keyframes`
  from {
    transform: scale(.9);
    transform: translate3d(0, -4px, 0);
  }
  50% {
    transform: scale(1);
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: scale(.9);
    transform: translate3d(0, -4px, 0);
  }
`

const Wrapper = styled.div`
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: 'Matter';

    & > span {
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
        color: #717f95;
        clip-path: inset(0 1ch 0 0);
        animation: ${loadDots} 1s steps(3) infinite;
    }

    & > svg {
        margin-bottom: 35px;
        animation: ${rocketAnimation} 1s infinite;
    }
`
