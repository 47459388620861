import { useProperty } from "../../context/property";
import { IPropertyType } from "../property-type";

//
// An editor for boolean properties.
//
function PropertyBooleanEditor() {

    const { propertyValue, setPropertyValue } = useProperty();

    return (
        <input
            type="checkbox"
            style={{
                fontFamily: "Matter, \"Helvetica Neue\", Helvetica, Arial, sans-serif",
                borderRadius: "4px",
                border: "1px solid rgb(223, 226, 242)",
                color: "rgb(113, 127, 149)",
                padding: "10px 12px",
                margin: "5px",
                fontSize: "14px",
            }}
            checked={propertyValue || false}
            onChange={event => {
                setPropertyValue(event.target.checked);
            }}
        />
    );
}

const boolean: IPropertyType = {
    editor: () => <PropertyBooleanEditor />,
};

export default boolean;