import { ChangeEvent, useState, KeyboardEvent, FC } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Toast } from '../../helpers/Toast'
import { checkInputValid } from '../../helpers/validation'
import { IErrors } from '../../modules/IErrors'
import { RouteNames } from '../../../router'
import { Button } from '../common/Button'
import ButtonSpinner from '../common/ButtonSpiner'
import { GoogleButton } from '../common/GoogleButton'
import { InputBox } from '../common/InputBox'

import { SyncLoader } from 'react-spinners'
import { useAuth } from 'widgets-base'

interface Props {
    updateFormType: (value: string) => void
}

interface UserForm {
    email: string
    password: string
}

const Signin: FC<Props> = ({ updateFormType }) => {
    const [errors, setErrors] = useState<IErrors>({})
    const [loading, setLoading] = useState<boolean>(false)
    const [googleLoading, setGoogleLoading] = useState<boolean>(false)
    const [formValues, setFormValues] = useState<UserForm>({ email: '', password: '' })
    const { login, loginWithGoogle } = useAuth()

    const handleChangeFormType = (): void => {
        updateFormType('signUp')
    }

    const handleTextChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setFormValues((prevState) => {
            return {
                ...prevState,
                [e.target.name]: e.target.value,
            }
        })
    }

    const onLoginWithGoogle = async () => {
        try {
            setGoogleLoading(true) //todo: don't really need a separate setLoading here.
            await loginWithGoogle();
            setGoogleLoading(false)
        } catch (error) {
            Toast('Login failed', 'error')
            setGoogleLoading(false)
            console.log(error)
        }
    }

    const handleSignIn = async (): Promise<void> => {
        const email = formValues.email
        const password = formValues.password

        if (!checkInputValid(email, 'string')) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    email: 'Enter valid email.',
                }
            })
        }

        if (!checkInputValid(password, 'string')) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    password: 'Enter password.',
                }
            })
            return
        }

        try {
            setLoading(true) //todo: don't really need a separate setLoading here.
            await login(email, password)
            setLoading(false)
        } catch (error) {
            const message = error.response?.data?.message || error.message;
            Toast(message, 'error')
            setLoading(false)
        }
    }

    const handlePressKeyLogin = (e: KeyboardEvent<Element>): void => {
        if (e.key === 'Enter') {
            handleSignIn()
        }
    }

    return (
        <AuthContainer>
            <h1>Login</h1>
            <>
                <p className="sub-text">Not already with us? </p>
                <button type="button" className="sign-up-button" onClick={handleChangeFormType}>
                    Sign up
                </button>
            </>

            <div className="input-container">
                <InputBox
                    size="medium"
                    width="100%"
                    value={formValues?.email}
                    placeholder="Email"
                    name="email"
                    type="email"
                    onChange={handleTextChange}
                    error={errors['email']}
                />
                <InputBox
                    size="medium"
                    width="100%"
                    value={formValues?.password}
                    placeholder="Password"
                    name="password"
                    type="password"
                    onChange={handleTextChange}
                    error={errors['password']}
                    onKeyUp={handlePressKeyLogin}
                />
            </div>

            <span className="span-or">or</span>

            {googleLoading ? (
                <SyncLoader
                    color="#707070"
                    speedMultiplier={0.4}
                    size={5}
                    cssOverride={{ textAlign: 'center', margin: '20px 0' }}
                />
            ) : (
                <GoogleButton type="button" width="100%" onClick={onLoginWithGoogle} />
            )}

            <Button
                type="button"
                onClick={handleSignIn}
                variant="secondary"
                width="100%"
                size="medium"
                borderRadius={'8px'}
            >
                {loading ? <ButtonSpinner height="25px" width="25px" /> : 'Signin'}
            </Button>

            <ForgotPassword>
                <Link to={RouteNames.FORGOT_PASSWORD} replace>
                    Forgot Password ?
                </Link>
            </ForgotPassword>
        </AuthContainer>
    )
}

export default Signin

export const AuthContainer = styled.section`
    width: 50%;

    h1 {
        color: ${(props) => props.theme.colors.primary || 'red'};
        font-size: 38px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .sub-text {
        color: ${(props) => props.theme.text.light};
        font-size: 16px;
        display: inline-block;
    }

    .sign-up-button {
        color: ${(props) => props.theme.colors.primary};
        font-size: 16px;
        display: inline-block;
        font-weight: bold;
        margin-left: 10px;
        background: none;
        border: none;
        cursor: pointer;
    }

    .input-container {
        margin-top: 15px;
    }

    .username-preview {
        margin-bottom: 0.5rem;
        font-size: 11px;
        color: ${(props) => props.theme.text.light};
    }

    .grid-col-2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 5px;
    }

    .tnc-layout {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: normal;
        margin: 25px 0;
        color: #717f95;
        font-size: 13px;
        font-weight: bold;

        input {
            margin-right: 10px;
        }

        a {
            color: ${(props) => props.theme.colors.primary};
        }
    }

    .span-or {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 0.5rem 0;
        font-size: 16px;
        color: ${(props) => props.theme.text.light};
    }

    @media (max-width: 600px) {
        width: 100%;

        h1 {
            margin-top: 20px;
        }
    }
`

const ForgotPassword = styled.div`
    display: flex;
    justify-content: center;
    font-size: 11px;
    width: 100%;
    padding-top: 1rem;

    a {
        color: ${(props) => props.theme.text.light};
    }
`
