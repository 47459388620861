import { createContext, useContext } from "react";
import { IPageTheme } from "../lib/style";

export interface IPageThemeContextHook {
    //
    // The current page theme.
    //
    pageTheme: IPageTheme;
}

export const PageThemeContext = createContext<IPageThemeContextHook>(null);

export function usePageTheme(): IPageThemeContextHook {
    const context = useContext(PageThemeContext);
    if (!context) {
        throw new Error(`Page theme context is not set! Add PageThemeContextProvider to the component tree.`);
    }
    return context;
}
