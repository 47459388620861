import React, { FC, ReactElement, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { RouteNames } from '../router'
import { LoaderRocket } from '../v1/components/common/LoaderRocket'
import { useAuth } from 'widgets-base'

interface Props {
    children: ReactElement<any, any>
}

export const ProtectedRoute: FC<Props> = ({ children }) => {
    const { currentUser, loading } = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if (!currentUser && !loading) {
            navigate(RouteNames.LOGIN);
        }
    }, [currentUser, loading])

    if (loading) {
        return <LoaderRocket />
    }

    return children
}
