import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

export const ScrollToTopButton = () => {
    const [showButton, setShowButton] = useState(false)

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.pageYOffset > 1200) {
                setShowButton(true)
            } else {
                setShowButton(false)
            }
        })
    }, [])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    return (
        <>
            {showButton && (
                <ScrollButton onClick={scrollToTop}>
                    <KeyboardArrowUpIcon fontSize="large" color="inherit" />
                </ScrollButton>
            )}
        </>
    )
}

const fadeIn = keyframes`
    from {
      opacity: 0;
      transform: translateX(300px);
    }
    to {
      opacity: 1;
    }
`

const ScrollButton = styled.button`
    position: fixed;
    width: 58px;
    height: 58px;
    right: 5%;
    bottom: 5%;
    border: 3px solid #57be92;
    border-radius: 50%;
    background-color: #57be92;
    color: #fff;
    cursor: pointer;
    z-index: 1500;
    animation: ${fadeIn} 0.5s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 15px 2px #57be9192;

    svg {
        width: 50px;
        height: 50px;
        border: 3px solid #fff;
        border-radius: 50%;
    }
`
