import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import CloseIcon from '@mui/icons-material/Close'
import {Toast} from '../../../helpers/Toast'
import {CustomModal} from '../../common/CustomModal'
import CustomQRCode from './CustomQRCode'
import {Tooltip} from '@mui/material'
import styled, {css, keyframes} from 'styled-components'
import { useCampaign } from '../../../../builder/context/campaign'

const DeploySussesModal = ({visible, toggleVisible}) => {

    const { campaign } = useCampaign();

    const fallbackCopyTextToClipboard = (text: string) => {
        let textArea = document.createElement('textarea')
        textArea.value = text

        // Avoid scrolling to bottom
        textArea.style.top = '0'
        textArea.style.left = '0'
        textArea.style.position = 'fixed'

        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()

        let successful = document.execCommand('copy')
        if (successful) {
            Toast(`URL Copied: ${campaign?.deployUrl}`, 'success', campaign?.deployUrl)
        } else {
            Toast('Oops, unable to copy ', 'error')
        }
        document.body.removeChild(textArea)
    }

    const copyToClipboard = (): void => {
        if (navigator.clipboard !== undefined) {
            navigator.clipboard
                .writeText(campaign?.deployUrl)
                .then(() => {
                    Toast(
                        `URL copied to clipboard! ${campaign?.deployUrl} `,
                        'success',
                        campaign?.deployUrl
                    )
                })
                .catch((err) => {
                    console.log(err)
                    Toast('Error copying link', 'error')
                })
        } else {
            fallbackCopyTextToClipboard(campaign?.deployUrl)
        }
    }

    const onCopyLink = () => copyToClipboard()

    return (
        <CustomModal visible={visible} toggleVisible={toggleVisible} title="Upload Asset">
            <Body>
                <TopBar onClick={toggleVisible}>
                    <CloseIcon/>
                </TopBar>
                <Wrapper>
                    <img width="80px" height="67px" src="/images/confetti.svg" alt="confetti"/>
                    <h1>Deploy Successful !</h1>
                    <CustomQRCode/>

                    <LinkBox>
                        <p>{campaign?.deployUrl}</p>
                        <Tooltip title="Copy link" placement="bottom" arrow>
                            <RoundIconButton onClick={onCopyLink} className="button">
                                <ContentCopyIcon fontSize="small" color="inherit"/>
                            </RoundIconButton>
                        </Tooltip>
                        <Tooltip title="Go to page" placement="bottom" arrow>
                            <RoundIconButton className="button">
                                <a
                                    style={{color: '#fff'}}
                                    href={campaign.deployUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <OpenInNewIcon fontSize="small" color="inherit"/>
                                </a>
                            </RoundIconButton>
                        </Tooltip>
                    </LinkBox>
                </Wrapper>
            </Body>
        </CustomModal>
    )
}

export default DeploySussesModal

const Body = styled.section`
    width: 700px;
    height: 650px;
    background-color: white;
    border: 1px solid #e3e7ff;
    box-sizing: border-box;
    border-radius: 16px;

    padding: 28px;

    font-family: Matter;
    font-style: normal;
    font-weight: normal;

    h1 {
        text-align: center;
        font-size: 29px;
        font-weight: 600;
        line-height: 35px;
        color: #3ae29b;
        margin-bottom: 30px;
    }

    img {
        margin-bottom: 21px;
    }

    p {
        font-size: 14px;
        color: #3c4b61;
    }
`

const LinkBox = styled.div`
    margin-top: 30px;
    width: 500px;
    height: 56px;
    background: #fafaff;
    border: 1px solid #dfe2f2;
    box-sizing: border-box;
    border-radius: 200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    p {
        position: relative;
        top: 15px;
        left: 20px;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #717f95;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 350px;
        margin-right: 50px;
    }

    .button {
        position: relative;
        top: 9px;
        right: 0%;
        background: #6478f9;
        width: 36px;
        height: 36px;
    }
`

const TopBar = styled.div`
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`
const Wrapper = styled.div`
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 7px;
        p {
            font-size: 13px;
            font-weight: bold;
        }
        .savings {
            display: flex;
            gap: 10px;
            .time {
                color: #ff840a;
                font-size: 16px;
                font-weight: bold;
            }
            .cost {
                color: #5df599;
                font-size: 16px;
                font-weight: bold;
            }
        }
    }
`


const spin = keyframes`
      0% {
        transform: rotateY(0);
      }
      100% {
        transform: rotateY(180deg);
      }
`

export const RoundIconButton = styled.button<{
    loadingProcces?: boolean
}>`
    border-radius: 50%;
    height: 36px;
    width: 36px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0.25rem;
    outline: none;
    border: none;
    color: #fff;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        background-color: #fff;
        box-shadow: inset 0 0 5px 1px rgba(100, 120, 249, 0.4);
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
    }

    img {
        animation: ${(props) => (props.loadingProcces ? css`0.5s ${spin} infinite` : 'none')};
    }
`

