import { nanoid } from 'nanoid'
import { ICheckbox, IOption } from '../../../modules/widgets/forms/ICheckbox'

export class CheckboxModel implements ICheckbox {
    constructor(label: string, options: IOption[]) {
        this.settings.label = label
        this.settings.options = options
    }
    id = nanoid()
    name = 'checkbox'
    type = 'checkbox'
    icon = '/images/widgets/forms/product-form.png'
    category = 'form'
    settings = {
        options: [
            {
                label: 'checkbox 1',
                value: 'checkbox 1',
                state: false,
            },
        ],
        defaultValue: { label: 'checkbox 1', value: 'checkbox 1' },
        placeholder: 'placeholder text',
        required: false,
        name: 'checkbox',
        label: 'Checkbox Group',
    }
    colourSettings = {
        'label colour': ['rgba(0,0,0,1)', '#FFA7E1', '#0426FF'],
        'text colour': ['rgba(0,0,0,1)', '#FFA7E1', '#0426FF'],
        'border colour': ['transparent', '#FFA7E1', '#0426FF'],
        'background colour': ['transparent', '#FFA7E1', '#0426FF'],
        'icon colour': ['rgba(0,0,0,1)', '#FFA7E1', '#0426FF'],
    }
    appearanceSettings = {
        font: {
            family: 'Poppins',
            size: 12,
            weight: 400,
        },
        width: 85,
        height: 0,
        alignment: 'center',
        spacing: 5,
        columns: 1,
        border: true,
        borderRadius: true,
        margin: true,
        padding: true,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
        borderBottomLeftRadius: 2,
        borderBottomRightRadius: 2,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        iconSize: 10,
    }
}
