import { Button } from '@mui/material';
import { SeedQuestions } from '../seed-questions';
import { Preview } from '../preview';
import { ProgressButton } from './components/progress-button';
import { SpeechBubble } from './components/speech-bubble';

export function ConfirmContent({ campaign, generating, pageTheme }) {

    return (
        <div className="flex flex-col w-full items-center overflow-hidden">
            <div 
                className="flex flex-row gap-8 justify-center justify-items-center w-screen"
                style={{
                    maxWidth: "1200px",
                }}
                >
                <div 
                    className="flex flex-col w-full items-center"
                    >
                    <div 
                        className="flex flex-col items-start gap-4"
                        style={{
                            maxWidth: "600px",
                        }}
                        >
                        <SpeechBubble
                            title="Galina - Head of Delivery"
                            text="Look at what we saved"
                            avatar="/ai-generator/characters/galina.png"
                            />

                        <div 
                            className="flex flex-col p-2 border border-orange-500 border-solid rounded-xl"
                            >
                            <header className="header text-orange-400 text-lg font-bold leading-10 tracking-wide self-stretch">
                                Est. Timeline Saved
                            </header>
                            <div className="self-stretch flex items-stretch justify-between gap-1">
                                <div className="timeline-info text-neutral-500 text-base leading-7 bg-orange-400 bg-opacity-10 grow pl-4 pr-16 py-1 rounded-xl items-start">
                                    Calculating...
                                </div>
                                <img
                                    loading="lazy"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/6a28cefb9cd5c82401ab8be380a17dab7104052c164e94f65db53939d942ce06?apiKey=610a4bec134943f393dd6ffcb27214fb&"
                                    className="aspect-[1.17] object-contain object-center w-[27px] overflow-hidden self-center shrink-0 max-w-full my-auto"
                                    alt="timeline-info"
                                />
                            </div>
                            <header className="header text-orange-400 text-lg font-bold leading-10 tracking-wide self-stretch mt-4">
                                Est. Average Budget Saved
                            </header>
                            <div className="self-stretch flex items-stretch justify-between gap-1.5 mt-2">
                                <div className="budget-info text-neutral- text-base leading-7 bg-orange-400 bg-opacity-10 grow pl-4 pr-16 py-1 rounded-xl items-start">
                                    Calculating..
                                </div>
                                <img
                                    loading="lazy"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/eab63c6bd53b209e3d5ecb13901c7e4865b58323844510228fdeffa5adda3d9f?apiKey=610a4bec134943f393dd6ffcb27214fb&"
                                    className="aspect-[1.23] object-contain object-center w-[27px] overflow-hidden self-center shrink-0 max-w-full my-auto"
                                    alt="budget-info"
                                />
                            </div>
                            <header className="header text-emerald-400 text-lg font-bold leading-10 tracking-wide self-stretch mt-1">
                                Time to Build with Deployable
                            </header>
                            <div className="self-stretch flex items-stretch justify-between gap-1.5 mt-2">
                                <div className="build-info text-neutral-500 text-base font-bold leading-7 bg-emerald-400 bg-opacity-20 grow pl-5 pr-16 py-2 rounded-xl items-start">
                                    Calculating...
                                </div>
                                <img
                                    loading="lazy"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/4c7360a45ee8e12d945ece0c5c3e436f45392003895299377dd1a7d0361ce30b?apiKey=610a4bec134943f393dd6ffcb27214fb&"
                                    className="aspect-[1.23] object-contain object-center w-[27px] overflow-hidden self-center shrink-0 max-w-full my-auto"
                                    alt="build-info"
                                />
                            </div>
                            <div className="info-text justify-center text-neutral-500 text-base leading-7 ml-5 mt-4 self-start">
                                * Estimate worked off average developer rate of $200 per hour
                            </div>
                        </div>

                        <div 
                            className="flex flex-col justify-center items-stretch pl-1.5"
                            >
                            <div className="bg-stone-50 bg-opacity-10 pr-5 pt-1 pb-1 rounded-lg">
                                <div className="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                                    <div 
                                        className="flex flex-col items-stretch"
                                        style={{
                                            width: "32%",
                                        }}
                                        >
                                        <div 
                                            className="flex-col overflow-hidden relative flex aspect-[1.1295336787564767] grow pl-5 pr-16 pt-2 pb-4 items-start"
                                            style={{
                                                width: "218px",
                                            }}
                                            >
                                            <img 
                                                loading="lazy" 
                                                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d93cf81b6c4da72bd96611ebb599a5727068f77841798215e14d0b0d6c34c7af?apiKey=610a4bec134943f393dd6ffcb27214fb&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d93cf81b6c4da72bd96611ebb599a5727068f77841798215e14d0b0d6c34c7af?apiKey=610a4bec134943f393dd6ffcb27214fb&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d93cf81b6c4da72bd96611ebb599a5727068f77841798215e14d0b0d6c34c7af?apiKey=610a4bec134943f393dd6ffcb27214fb&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d93cf81b6c4da72bd96611ebb599a5727068f77841798215e14d0b0d6c34c7af?apiKey=610a4bec134943f393dd6ffcb27214fb&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d93cf81b6c4da72bd96611ebb599a5727068f77841798215e14d0b0d6c34c7af?apiKey=610a4bec134943f393dd6ffcb27214fb&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d93cf81b6c4da72bd96611ebb599a5727068f77841798215e14d0b0d6c34c7af?apiKey=610a4bec134943f393dd6ffcb27214fb&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d93cf81b6c4da72bd96611ebb599a5727068f77841798215e14d0b0d6c34c7af?apiKey=610a4bec134943f393dd6ffcb27214fb&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d93cf81b6c4da72bd96611ebb599a5727068f77841798215e14d0b0d6c34c7af?apiKey=610a4bec134943f393dd6ffcb27214fb&" 
                                                className="absolute h-full w-full object-cover object-center inset-0" 
                                                />
                                            <img 
                                                loading="lazy" 
                                                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/538ab5d97ae118f24f5077e5baf7108b1cb262ef97e473d4c1e629a9c563eb98?apiKey=610a4bec134943f393dd6ffcb27214fb&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/538ab5d97ae118f24f5077e5baf7108b1cb262ef97e473d4c1e629a9c563eb98?apiKey=610a4bec134943f393dd6ffcb27214fb&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/538ab5d97ae118f24f5077e5baf7108b1cb262ef97e473d4c1e629a9c563eb98?apiKey=610a4bec134943f393dd6ffcb27214fb&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/538ab5d97ae118f24f5077e5baf7108b1cb262ef97e473d4c1e629a9c563eb98?apiKey=610a4bec134943f393dd6ffcb27214fb&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/538ab5d97ae118f24f5077e5baf7108b1cb262ef97e473d4c1e629a9c563eb98?apiKey=610a4bec134943f393dd6ffcb27214fb&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/538ab5d97ae118f24f5077e5baf7108b1cb262ef97e473d4c1e629a9c563eb98?apiKey=610a4bec134943f393dd6ffcb27214fb&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/538ab5d97ae118f24f5077e5baf7108b1cb262ef97e473d4c1e629a9c563eb98?apiKey=610a4bec134943f393dd6ffcb27214fb&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/538ab5d97ae118f24f5077e5baf7108b1cb262ef97e473d4c1e629a9c563eb98?apiKey=610a4bec134943f393dd6ffcb27214fb&" 
                                                className="aspect-square object-contain object-center overflow-hidden max-w-full mb-4" 
                                                style={{
                                                    width: "65px",
                                                }}
                                                />
                                        </div>
                                    </div>
                                    <div 
                                        className="flex flex-col items-stretch"
                                        style={{
                                            width: "68%",
                                        }}
                                        >
                                        <div className="flex flex-col mt-2 items-start">
                                            <header 
                                                className="justify-center text-zinc-800 text-lg font-semibold leading-5 tracking-wider ml-4"
                                                style={{
                                                    maxWidth: "600px",
                                                }}
                                                >
                                                <div className=" text-lg leading-5 tracking-wider text-zinc-800">
                                                    <span className=" text-lg leading-5 tracking-wider text-zinc-800">Matthew Fay</span>
                                                </div>
                                                <div className=" text-sm leading-5 tracking-wider text-zinc-800">
                                                    <span className=" text-sm leading-5 tracking-wider text-zinc-800">Marketing Guru + Beer Lover + D Grade Cricketer</span>
                                                </div>
                                            </header>
                                            <div className="flex flex-col text-center items-center bg-indigo-500 self-stretch flex flex-col mt-2 pl-12 pr-6 py-3 rounded-xl">
                                                <div className="text-slate-50 text-base leading-5 tracking-wide">
                                                    Saving $150,000 in customer lead costs for his brand.
                                                </div>
                                                <div className="text-slate-50 text-base leading-5 tracking-wide self-center mt-2">
                                                    Adding 5000 new contact - 400% increase.
                                                </div>
                                                <div className="text-slate-50 text-base leading-5 tracking-wide self-center mt-2">
                                                    Conversion Rate of 92% on campaigns.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div 
                    className="flex flex-col items-center gap-4"
                    >
                    <SpeechBubble
                        title="Ashley - Chief Technology Officer"
                        text="Okay, so we've built the structure. Hit 'Next' to continue."
                        avatar="https://cdn.builder.io/api/v1/image/assets/TEMP/0736b9d620a67fc04e2a5db09863ea622456e2b459d9e739661e6f65e1ddb70b?apiKey=610a4bec134943f393dd6ffcb27214fb&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/0736b9d620a67fc04e2a5db09863ea622456e2b459d9e739661e6f65e1ddb70b?apiKey=610a4bec134943f393dd6ffcb27214fb&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0736b9d620a67fc04e2a5db09863ea622456e2b459d9e739661e6f65e1ddb70b?apiKey=610a4bec134943f393dd6ffcb27214fb&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/0736b9d620a67fc04e2a5db09863ea622456e2b459d9e739661e6f65e1ddb70b?apiKey=610a4bec134943f393dd6ffcb27214fb&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/0736b9d620a67fc04e2a5db09863ea622456e2b459d9e739661e6f65e1ddb70b?apiKey=610a4bec134943f393dd6ffcb27214fb&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0736b9d620a67fc04e2a5db09863ea622456e2b459d9e739661e6f65e1ddb70b?apiKey=610a4bec134943f393dd6ffcb27214fb&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/0736b9d620a67fc04e2a5db09863ea622456e2b459d9e739661e6f65e1ddb70b?apiKey=610a4bec134943f393dd6ffcb27214fb&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/0736b9d620a67fc04e2a5db09863ea622456e2b459d9e739661e6f65e1ddb70b?apiKey=610a4bec134943f393dd6ffcb27214fb&"
                        reverse={true}
                        />

                    <Preview 
                        generating={generating}
                        campaign={campaign}
                        pageTheme={pageTheme}
                        fontSize="4px"
                        height="600px"
                        width="500px"
                        />
                </div>
            </div>
        </div>
    );
}

