import React, {FC} from 'react'
import styled from 'styled-components'
import { ISocialLinks } from '../../../../modules/widgets/general/ISocial'
import SocialIcons from './SocialIcons'
import { useWidget } from 'widgets-base'
import { useBrandingContext } from '../../../../../builder/context/branding-context'

interface Props {
    editMode?: boolean
}

const SocialWidget: FC<Props> = ({ editMode = false }) => {

    const { widget } = useWidget();
    const { branding } = useBrandingContext();
    const appearanceSettings = widget?.appearanceSettings
    const colourSettings = widget?.colourSettings
    const width: string = appearanceSettings?.width + '%'
    const iconWidth: string = appearanceSettings?.iconWidth + 'px'
    const columns: number = appearanceSettings?.columns
    const iconColour: string = Array.isArray(colourSettings?.iconColour)
        ? colourSettings?.iconColour[0]
        : colourSettings?.iconColour
    const marginTop: string = appearanceSettings?.marginTop / 10 + 'em'
    const marginBottom: string = appearanceSettings?.marginBottom / 10 + 'em'
    const marginLeft: string = appearanceSettings?.marginLeft / 10 + 'em'
    const marginRight: string = appearanceSettings?.marginRight / 10 + 'em'
    const overrideLinks: ISocialLinks = widget?.settings?.links
    const iconSpacing = appearanceSettings?.iconSpacing / 10 + 'em'
    const isRow: boolean = appearanceSettings?.flex
    const useIcon: boolean = appearanceSettings?.useIcon

    const stylesParent = {
        width,
        columns,
        marginTop,
        marginBottom,
        marginLeft,
        marginRight,
        isRow,
    }
    
    const styles = {
        margin: iconSpacing,
    }

    const socialLinks = Object.assign({}, branding?.social || {}, overrideLinks);

    return (
        <SocialSection id={widget.id} globalStyles={stylesParent}>
            <div className="social">
                {Object.entries(socialLinks).map(([key, value]) => {
                    if (value) {
                        return (
                            <SocialIcons
                                key={key}
                                platform={key}
                                height={iconWidth}
                                width={iconWidth}
                                color={iconColour}
                                link={value}
                                styles={styles}
                                editMode={editMode}
                                useIcon={useIcon}
                            />
                        );
                    } 
                    else {
                        return undefined;
                    }
                })}
            </div>
        </SocialSection>
    )
}

export default SocialWidget;

const SocialSection = styled.section<{
    globalStyles: {
        width: string
        columns: number
        marginTop: string
        marginBottom: string
        marginLeft: string
        marginRight: string
        isRow: boolean
    }
}>`
    width: 100%;
    max-width: inherit;
    display: flex;
    justify-content: center;
    margin-top: 1em;

    & > .social {
        display: ${(props) => (props.globalStyles.isRow ? 'flex' : 'grid')};
        justify-content: space-evenly;
        justify-items: center;
        align-items: center;
        grid-template-columns: ${(props) => `repeat(${props.globalStyles.columns}, 1fr)`};
        cursor: pointer;
        width: ${(props) => props.globalStyles.width};
        margin-top: ${(props) => props.globalStyles.marginTop};
        margin-bottom: ${(props) => props.globalStyles.marginBottom};
        margin-left: ${(props) => props.globalStyles.marginLeft};
        margin-right: ${(props) => props.globalStyles.marginRight};
    }
`
