import { AxiosResponse } from 'axios'
import instance from '../lib/http'
import { IStripeProduct } from '../v1/modules/IStripeProduct'
import { IUser } from 'widgets-base'

interface IUserData {
    user: IUser
    invites: string[]
}

export default class UserService { //todo: Why have a class when all the functions are static?
    
    static getUser(): Promise<AxiosResponse<IUserData>> {
        return instance.get<IUserData>('/getUser')
    }

    static changeEmail(email: string, password: string): Promise<AxiosResponse> {
        return instance.post('/changeEmail', { email, password })
    }

    static updateUser(data: IUser, password: string): Promise<AxiosResponse> {
        return instance.post('/updateUser', { data, password })
    }

    static createCheckoutSession(line_items: IStripeProduct, role: string): Promise<AxiosResponse> {
        return instance.post('/create-checkout-session', {
            line_items,
            role,
        })
    }

    static getStripeSession(session_id: string, role: string): Promise<AxiosResponse<IUser>> {
        return instance.post('/getStripeSession', { session_id, role })
    }

    static deleteSubscription(subscriptionId: string): Promise<AxiosResponse<IUser>> {
        return instance.post('/deleteSubscription', { subscriptionId })
    }
}
