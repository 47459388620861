import React from 'react'
import styled from 'styled-components'

interface Props {
    isToggled?: boolean
    onChange: (arg?: any) => void
    name?: string
    label?: string
}

const InputWrapper = styled.label`
    position: relative;
    display: flex;
    align-items: center;
`

const Input = styled.input`
    position: absolute;
    left: -9999px;
    top: -9999px;

    &:checked + span {
        background: #6478f9;

        &:before {
            left: calc(100% - 5px);
            transform: translateX(-100%);
        }
    }
`
const Slider = styled.span`
    display: flex;
    cursor: pointer;
    width: 48px;
    height: 24px;
    border-radius: 1000px;
    background-color: #dfe2f2;
    position: relative;
    transition: background-color 0.15s;

    &:before {
        content: '';
        position: absolute;
        left: 5px;
        top: 4px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        transition: 0.15s;
        background: white;
    }
`

const Label = styled.span`
    font-size: 14px;
    font-weight: 500;
    margin-left: 5px;
`

export const ToggleButton = ({ isToggled, onChange, name, label }: Props) => {
    return (
        <InputWrapper className="toggle_button">
            <Input name={name} checked={isToggled} type="checkbox" onChange={onChange} />
            <Slider />
            {label && <Label>{label}</Label>}
        </InputWrapper>
    )
}
