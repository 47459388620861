import React, { FC } from 'react'
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    ViberShareButton,
    WhatsappShareButton,
    LinkedinIcon,
    FacebookIcon,
    TwitterIcon,
    ViberIcon,
    WhatsappIcon,
} from 'react-share'
import styled from 'styled-components'
import { IEvent } from '../../../../modules/widgets/general/IEvents'
import { useCampaign } from '../../../../../builder/context/campaign'

interface Props {
    event: IEvent
}

const ShareSocial: FC<Props> = ({ event }) => {
    
    const { campaign } = useCampaign();

    return (
        <SocialContainer>
            <LinkedinShareButton
                title={`Uncommin events`}
                summary={event.description}
                windowWidth={750}
                windowHeight={600}
                url={campaign?.deployUrl}
            >
                <LinkedinIcon size={'2rem'} round />
            </LinkedinShareButton>

            <FacebookShareButton
                title={`Uncommin events`}
                quote={event.description}
                hashtag={event.label}
                windowWidth={750}
                windowHeight={600}
                url={campaign?.deployUrl}
            >
                <FacebookIcon size={'2rem'} round />
            </FacebookShareButton>

            <TwitterShareButton
                title={`Uncommin events`}
                hashtags={[`${event.label}`, '#deployable']}
                windowWidth={750}
                windowHeight={600}
                url={campaign?.deployUrl}
            >
                <TwitterIcon size={'2rem'} round />
            </TwitterShareButton>

            <ViberShareButton
                title={`Uncommin events`}
                windowWidth={750}
                windowHeight={600}
                url={campaign?.deployUrl}
            >
                <ViberIcon size={'2rem'} round />
            </ViberShareButton>

            <WhatsappShareButton
                title={`Uncommin events`}
                windowWidth={750}
                windowHeight={600}
                url={campaign?.deployUrl}
            >
                <WhatsappIcon size={'2rem'} round />
            </WhatsappShareButton>
        </SocialContainer>
    )
}

export default ShareSocial

const SocialContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    & > button:not(:last-child) {
        margin-right: 10px;
    }
`
