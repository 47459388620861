import { Slider } from "@mui/material";
import { useProperty } from "../../context/property";
import { IPropertyType } from "../property-type";
import { NumberInput } from "../../components/number-editor";
import { useWidget } from "widgets-base";

//
// An editor for size properties.
//
function PropertySizeEditor() {

    const { properties, updateProperties } = useWidget();
    const { property } = useProperty();

    function onChangeProperty(value: number): void {
        updateProperties({
            width: value,
            height: value * properties.aspectRatio,
        });
    }

    return (
        <div
            className="flex flex-row items-center w-full"
            >
            <div
                className="flex flex-row items-center"
                style={{
                    padding: "5px",
                    width: "80px",
                    height: "32px",
                    border: "1px solid #dfe2f2",
                    borderRadius: "8px",
                    marginRight: "6px",
                }}
                >
                <NumberInput
                    name={property.id}
                    value={properties.width}
                    onChange={(name, value) => onChangeProperty(value)}
                />
                {property.unit &&
                    <div
                        style={{
                            fontSize: "12px",
                            color: "#3c4b61",
                            borderLeft: "1px solid #dfe2f2",
                            padding: "0 5px",
                            fontWeight: "bold",
                        }}
                        >
                        {property.unit}
                    </div>
                }
            </div>

            {(typeof properties.width === "number" && property.range) &&
                <div className="flex flex-grow px-2" >
                    <Slider
                        size="small"
                        min={property.range[0]}
                        max={property.range[1]}
                        step={property.range[2]}
                        value={properties.width}
                        valueLabelDisplay="auto"
                        onChange={(name, value: number) => onChangeProperty(value)}
                        />
                </div>
            }
        </div>
    );
}

const size: IPropertyType = {
    editor: () => <PropertySizeEditor />,
};

export default size;