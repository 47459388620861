import { ChangeEvent, FC } from 'react'
import styled from 'styled-components'
import { useWidget } from 'widgets-base'

interface Props {
    label: string
    value: number
    name: string
}

const SizeField: FC<Props> = ({ label, value, name }) => {

    const { widget, setWidget } = useWidget();

    const handleChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
        const newValue = +event.target.value
        const name = event.target.name
        const newWidget = {
            ...widget,
            appearanceSettings: {
                ...widget.appearanceSettings,
                font: {
                    ...widget.appearanceSettings.font,
                    size: {
                        ...widget.appearanceSettings.font.size,
                        [name]: newValue,
                    },
                },
            },
        }

        setWidget(newWidget);
    }

    return (
        <Container>
            <label>{label}</label>
            <div className="field">
                <input name={name} type="number" value={value} onChange={handleChangeValue} />
                <span>px</span>
            </div>
        </Container>
    )
}

export default SizeField

const Container = styled.div`
    display: flex;
    flex-direction: column;

    & > label {
        font-size: 12px;
        color: #3c4b61;
        margin-bottom: 7px;
        font-weight: bold;
    }

    .field {
        display: flex;
        align-items: center;
        padding: 5px;
        height: 32px;
        width: 78px;
        background: #ffffff;
        border: 1px solid #dfe2f2;
        border-radius: 8px;
        margin-right: 6px;
        input {
            border: none;
            width: 40px;
            font-size: 12px;
            color: #3c4b61;
            font-weight: bold;
        }
        span {
            font-size: 12px;
            color: #3c4b61;
            border-left: 1px solid #dfe2f2;
            padding: 0 5px;
            font-weight: bold;
        }
    }
`
