import _ from 'lodash/fp'

//
// https://blog.klipse.tech/javascript/2020/11/26/lodash-fp.html
// https://github.com/lodash/lodash/wiki/FP-Guide
//

var fp = _.noConflict();

const _fp = fp.convert({
    // Specify capping iteratee arguments.
    cap: false,
    // Specify currying.
    curry: false,
    // Specify fixed arity.
    fixed: false,
    // Specify immutable operations.
    immutable: true,
    // Specify rearranging arguments.
    rearg: false,
});

export default _fp;
