import { nanoid } from 'nanoid'
import { QRCode } from './QRCode'
import { ICampaign, IWidget } from 'widgets-base'

//
// Creates a random name for a campaign.
//
export function generateRandomName() {
    const list1 = [
        'Jovial',
        'Eccentric',
        'Hilarious',
        'Peculiar',
        'Whimsical',
        'Radiant',
        'Doodle',
        'Gleeful',
        'Sprightly',
        'Zesty',
        'Happy',
        'Silly',
        'Crazy',
        'Witty',
        'Jolly',
        'Playful',
        'Funky',
        'Cheeky',
        'Daring',
        'Goofy',
        'Wild',
        'Wordy',
        'Wise',
        'Funny',
        'Bubbly',
        'Zany',
        'Quirky',
        'Lively',
        'Hilarious',
        'Whimsical',
        'Mischievous',
        'Charming',
        'Energetic',
        'Dashing',
        'Wacky',
        'Hysterical',
        'Outlandish',
        'Whoopee',
        'Punny',
        'Frolicsome',
        'Chuckles',
        'Boisterous',
        'Giggly',
        'Snazzy',
        'Fuzzy',
        'Furry',
        'Flappy',
        'Snappy',
        'Fizzy',
        'Cheesecake',
    ]

    const list2 = [
        'Dynamo',
        'Whizkid',
        'Captain',
        'Luminary',
        'Maverick',
        'Zenith',
        'Daredevil',
        'Sorcerer',
        'Enigma',
        'Trailblazer',
        'Einstein',
        'Picasso',
        'Shakespeare',
        'Ninja',
        'Jedi',
        'Unicorn',
        'Captain',
        'Panda',
        'Wizard',
        'Rockstar',
        'Cheesecake',
        'Noodle',
        'Sage',
        'Badger',
        'Superhero',
        'Magician',
        'Pirate',
        'Guru',
        'Astronaut',
        'Pegasus',
        'Champ',
        'Rascal',
        'Maestro',
        'Legend',
        'Dynamo',
        'Whiz',
        'Samurai',
        'Magpie',
        'Virtuoso',
        'Maverick',
        'Luminary',
        'Firecracker',
        'Maestro',
        'Enigma',
        'Bannana',
        'Kitty',
        'Puppy',
    ]

    const randomName1 = list1[Math.floor(Math.random() * list1.length)]
    const randomName2 = list2[Math.floor(Math.random() * list2.length)]

    return randomName1 + " " + randomName2;
}

export class Campaign implements ICampaign {
    constructor(
        public uid: string,
        public fontFamilies: any[] = [],
        public views: any[] = [],
        public thumbnail: string = "",
        public from_template: boolean = false
    ) {
        this._id = nanoid();
        this.uid = uid
        this.fontFamilies = fontFamilies
        this.views = views
        this.thumbnail = thumbnail //Todo: this is whacked. Why is this needed when we have templateThumbnail?
        this.from_template = from_template
        this.deployUrl = `https://live.deploy.link/live/${this.campaignId}`
    }
    _id: string
    ipAddresses?: any
    logoImageUrl?: any
    logoFile?: File
    textColour?: string; //todo: move to page theme.
    iconColour?: string
    buttonColour?: string
    buttonTextColour?: string
    shortUrl?: string
    widgets?: IWidget[]
    migrationCampaignId?: string; //todo: remove
    migratorCampaignId?: string; //todo: remove
    campaignId?: string; //todo: remove.
    campaignName = generateRandomName()
    campaignDescription = ''
    metaImageUrl = ''
    metaImageFile = null
    screenshot = ''
    redirect = false
    redirectUrl = ''
    templateName = ''
    templateCategory = ''
    templateThumbnail = this.thumbnail
    isPublicTemplate = false
    status = 'draft'
    editMode = false
    createdAt = new Date().toISOString()
    lastModified = new Date().toISOString()
    scans = 0
    deployQRUrl = ''
    deployUrl: string
    analytics = {
        googleAnalytics: {
            enabled: false,
            trackingId: '',
        },
        tagManagerSettings: {
            enabled: false,
            appName: 'deploy-link',
            containerId: 'GTM-',
            dataLayerName: 'customDataLayer',
        },
    }
    qrcode = new QRCode(
        '',
        'M',
        true,
        200,
        10,
        'rgba(0,0,0,0)',
        '#000000',
        '',
        50,
        50,
        0.5,
        'squares',
        undefined
    )
    version = 4
}
