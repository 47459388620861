import { Divider } from '@mui/material'
import { Link } from 'react-router-dom'
import { RouteNames } from '../../../router'
import { PageContainer } from './styles'

const DocSocial = () => {
    return (
        <PageContainer>
            <h1>Brand social links</h1>
            <span>
                The{' '}
                <Link to={RouteNames.BRAND}>
                    <strong>brand settings page</strong>
                </Link>{' '}
                is for customizing your brand designs. Add your own links.
                <br />
                All settings are available only for your account and do not apply to other users of
                the platform.
            </span>
            <Divider />
            <div className="content">
                <h2>What are social links for?</h2>
                <p>
                    The platform uses a social media widget, the addition of which will help you
                    increase the number of visits to your brand's social page. Enter your link here
                    and our program will automatically add "http://" for correct user redirects.
                    Save your settings changes.
                </p>
                <img
                    style={{ maxWidth: '500px' }}
                    className="image"
                    src="/images/docs/social.png"
                    alt="font"
                />
            </div>
        </PageContainer>
    )
}

export default DocSocial
