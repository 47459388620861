import { Tab, Tabs } from '@mui/material'
import { FC, SyntheticEvent } from 'react'
import { IAllProjects } from '../../pages/Dashboard'

interface Props {
    tab: number
    projects: IAllProjects
    handleChangeTab: (_event: SyntheticEvent, newValue: number) => void
}

const DashboardTabs: FC<Props> = ({ tab, projects, handleChangeTab }) => {
    return (
        <Tabs
            value={tab}
            onChange={handleChangeTab}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
        >
            <Tab
                label="All"
                style={{
                    textTransform: 'none',
                    borderRadius: '10px',
                }}
            />

            <Tab
                label="Published"
                style={{
                    textTransform: 'none',
                    borderRadius: '10px',
                }}
            />
            <Tab
                label="Drafts"
                style={{
                    textTransform: 'none',
                    borderRadius: '10px',
                }}
            />
            {projects?.legacy?.length && (
                <Tab
                    label="Legacy campaigns"
                    style={{
                        textTransform: 'none',
                        borderRadius: '10px',
                        color: 'red',
                    }}
                />
            )}
        </Tabs>
    )
}

export default DashboardTabs
