import { nanoid } from "nanoid";
import { IWidget, makePadding, makeBorderRadius } from "widgets-base";

export const subscribeForm: IWidget = {
    id: `widget-${nanoid()}`,
    name: 'Subscribe',
    xtype: 'group',
    source: "subscribe-form-section",
    grouped: true,
    properties: {
        flexDirection: "column",
        ...makePadding(8),
        alignSelf: "center",
        color: "white",
        backgroundColor: "#3690F7",
    },
    children: [
        {
            id: `widget-${nanoid()}`,
            xtype: 'text',
            properties: {
                content: "<b>BECOME A THERMACELL INSIDER</b>",
                fontSize: 18,
                textAlign: "center",
                alignSelf: "stretch",
            },
        },
        {
            id: `widget-${nanoid()}`,
            xtype: 'text',
            properties: {
                content: "Subscribe for updates",
                fontSize: 8,
                textAlign: "center",
                alignSelf: "stretch",
            },
        },
        {
            id: `widget-${nanoid()}`,
            xtype: 'form',
            name: "Subscribe form",
            grouped: true,
            properties: {
                flexDirection: "row",
                // ...makeMargin(12),
                // ...makePadding(12),
                gap: 6,
                // minWidth: 175,
                // maxWidth: 250,
                alignSelf: "stretch",
                justifyContent: "center",
            },
            children: [
                {
                    id: `widget-${nanoid()}`,
                    xtype: 'field',
                    grouped: true,
                    properties: {
                        fieldName: "Email",
                        flexDirection: "column",
                        alignSelf: "center",
                        flexGrow: "1",
                    },
                    children: [
                        {
                            id: `widget-${nanoid()}`,
                            xtype: 'input',
                            properties: {
                                placeholder: "Email",
                                required: true,
                                fontSize: 8,
                                backgroundColor: "#F3F3F3",
                                ...makeBorderRadius(40),
                                ...makePadding(12),
                                inputType: "email",
                                alignSelf: "stretch",
                            },
                        },
                    ],
                },
                {
                    id: `widget-${nanoid()}`,
                    xtype: 'submit-button',
                    grouped: true,
                    properties: {
                        alignSelf: "center",
                        justifyContent: "center",
                        ...makeBorderRadius(40),
                        ...makePadding(12),
                        // marginLeft: 6,
                        // marginRight: 6,
                        // marginTop: 16,
                        // marginBottom: 6,
                        color: "white",
                        backgroundColor: "#FCBA40",
                        fontSize: 8,
                    },
                    children: [
                        {
                            id: `widget-${nanoid()}`,
                            xtype: 'text',
                            properties: {
                                content: "<b>SUBSCRIBE</b>",
                            },
                        },
                    ],
                },
            ],
        },
    ],
};