import { useState, useRef, memo, ChangeEvent } from 'react'
import styled from 'styled-components'
import { useForm } from '../../../../../../builder/context/form-state-old'
import { Toast } from '../../../../../helpers/Toast'
import { checkFileSize } from '../../../../../helpers/upload/checkFileSize'
import { checkFileTypes } from '../../../../../helpers/upload/checkFileTypes'
import { returnAcceptedFileString } from '../../../../../helpers/upload/returnAcceptedFileString'
import { useWidget } from 'widgets-base'

interface Props {
}

const Upload = ({}: Props) => {

    const { widget } = useWidget();
    const { setFieldValue } = useForm();

    const name = widget.settings.name
    const fileInput = useRef(null)
    const [selectedFiles, setSelectedFiles] = useState(null)
    const fileTypeString = returnAcceptedFileString(widget.settings.fileType)

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>, fileType: string): void => {
        const files = e.target.files
        const limitExceed = checkFileSize(files, 50)
        if (!limitExceed) {
            Toast('Total file size exceeds 50mb. Please upload smaller file.', 'error')
            setSelectedFiles(null)
            return
        }

        const isValidArray = checkFileTypes(files, fileType)
        if (isValidArray.length > 0) {
            Toast('One or more files is not an accepted file type.', 'error')
            setSelectedFiles(null)
            return
        }

        setSelectedFiles(files)

        setFieldValue(name, { files: Array.from(files), fileType });
    }

    const width = widget.appearanceSettings.width + '%'
    const font = widget.appearanceSettings.font || { family: 'Open Sans' }
    const modFontFamily = font.family?.replace(/\s/g, '+')
    const fontUrl = widget.appearanceSettings?.font?.url

    const paddingTop = widget.appearanceSettings.paddingTop / 10 + 'em'
    const paddingBottom = widget.appearanceSettings.paddingBottom / 10 + 'em'
    const paddingLeft = widget.appearanceSettings.paddingLeft / 10 + 'em'
    const paddingRight = widget.appearanceSettings.paddingRight / 10 + 'em'
    const marginTop = widget.appearanceSettings.marginTop / 10 + 'em'
    const marginBottom = widget.appearanceSettings.marginBottom / 10 + 'em'
    const marginLeft = widget.appearanceSettings.marginLeft / 10 + 'em'
    const marginRight = widget.appearanceSettings.marginRight / 10 + 'em'

    const borderTopLeftRadius = widget.appearanceSettings.borderTopLeftRadius / 10 + 'em'
    const borderTopRightRadius = widget.appearanceSettings.borderTopRightRadius / 10 + 'em'
    const borderBottomLeftRadius = widget.appearanceSettings.borderBottomLeftRadius / 10 + 'em'
    const borderBottomRightRadius = widget.appearanceSettings.borderBottomRightRadius / 10 + 'em'
    const borderTopWidth = widget.appearanceSettings.borderTopWidth / 10 + 'em'
    const borderBottomWidth = widget.appearanceSettings.borderBottomWidth / 10 + 'em'
    const borderLeftWidth = widget.appearanceSettings.borderLeftWidth / 10 + 'em'
    const borderRightWidth = widget.appearanceSettings.borderRightWidth / 10 + 'em'
    const borderWidth = `${borderTopWidth} ${borderRightWidth} ${borderBottomWidth} ${borderLeftWidth}`
    const alignment = widget.appearanceSettings.alignment
    const fontSize = widget.appearanceSettings ? widget.appearanceSettings.font.size + 'px' : '14px'

    const color = Array.isArray(widget.colourSettings['text colour'])
        ? widget.colourSettings['text colour'][0]
        : widget.colourSettings['text colour']
    const borderColor = Array.isArray(widget.colourSettings['border colour'])
        ? widget.colourSettings['border colour'][0]
        : widget.colourSettings['border colour']
    const backgroundColor = Array.isArray(widget.colourSettings['background colour'])
        ? widget.colourSettings['background colour'][0]
        : widget.colourSettings['background colour']
    const letterSpacing = widget?.appearanceSettings?.font?.spacing + 'px' || '8px'
    const lineHeight = widget?.appearanceSettings?.font?.lineHeight || 1

    const required = widget.settings.required

    const label = widget.settings.label

    const multipleFiles = widget.settings.multipleFiles

    return (
        <Wrapper
            align={alignment}
            marginTop={marginTop}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            marginRight={marginRight}
        >
            <style>
                {`@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`}
            </style>
            <Container
                width={width}
                marginTop={marginTop}
                marginBottom={marginBottom}
                marginLeft={marginLeft}
                marginRight={marginRight}
                borderTopLeftRadius={borderTopLeftRadius}
                borderBottomLeftRadius={borderBottomLeftRadius}
                borderTopRightRadius={borderTopRightRadius}
                borderBottomRightRadius={borderBottomRightRadius}
            >
                <input
                    ref={fileInput}
                    type="file"
                    name={name}
                    accept={`${fileTypeString}`}
                    onChange={(e) => handleFileChange(e, widget.settings.fileType)}
                    required={required}
                    multiple={multipleFiles}
                />
                <button
                    type="button"
                    onClick={(e) => fileInput.current && fileInput.current.click()}
                    style={{
                        fontSize,
                        fontFamily: font.family,
                        color,
                        backgroundColor,
                        borderColor,
                        paddingTop,
                        paddingBottom,
                        paddingLeft,
                        paddingRight,
                        borderWidth,
                        letterSpacing,
                        lineHeight,
                    }}
                >
                    {label}
                </button>
                <div>
                    {selectedFiles &&
                        [...selectedFiles].map((file, index) => {
                            return (
                                <p key={`${file.name}-${index}`} className="text-xs ">
                                    {file.name}
                                </p>
                            )
                        })}
                </div>
            </Container>
        </Wrapper>
    )
}

export default memo(Upload)

const Wrapper = styled.div.attrs((props) => ({}))<{
    align: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
}>`
    display: flex;
    align-items: ${(props) =>
        props.align === 'left' ? 'flex-start' : props.align === 'center' ? 'center' : 'flex-end'};
    justify-content: center;
    width: 100%;
    cursor: pointer;
    flex-direction: column;
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
`
const Container = styled.div.attrs((props) => ({}))<{
    width: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
    borderTopLeftRadius: string
    borderBottomLeftRadius: string
    borderTopRightRadius: string
    borderBottomRightRadius: string
}>`
    position: relative;
    display: flex;
    flex-direction: column;
    width: ${(props) => props.width};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
    border-top-left-radius: ${(props) => props.borderTopLeftRadius};
    border-top-right-radius: ${(props) => props.borderTopRightRadius};
    border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
    button {
        width: 100%;
        border-radius: inherit;
    }

    & > input {
        display: none;
    }
`
