import React, { useState } from 'react'
import styled, { keyframes } from 'styled-components'

export const BurgerMenu = () => {
    const [openMenu, setOpenMenu] = useState(false)

    const onHandleOpenMenu = () => {
        setOpenMenu((prev) => !prev)
    }

    return (
        <>
            <NavIcon onClick={onHandleOpenMenu}>
                <Line open={openMenu} />
                <Line open={openMenu} />
                <Line open={openMenu} />
            </NavIcon>
            <Overlay open={openMenu}>
                <OverlayContent open={openMenu}>
                    <img width={'50%'} height={50} src="/images/logo.svg" alt="logo" />
                    <Link target="_blank" href="https://www.deployable.co">
                        Learn more about DEPLOYABLE
                    </Link>
                    <Link target="_blank" href="https://www.deploy.link">
                        Get your own digital card.
                    </Link>
                </OverlayContent>
            </Overlay>
        </>
    )
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const NavIcon = styled.span`
    width: 35px;
    height: 35px;
    background: #000;
    cursor: pointer;
    border: none;
    outline: none;
    position: absolute;
    top: 20px;
    left: 20px;
    border-radius: 50%;
    border: 2px solid #fff;
    z-index: 12;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const Line = styled.span<{
    open: boolean
}>`
    display: block;
    border-radius: 50px;
    width: 20px;
    height: 2px;
    margin: 2px;
    background-color: #fff;
    transition: width 0.2s ease-in-out;

    :nth-child(odd) {
        width: ${(props) => (props.open ? '40%' : '20px')};
    }
`
const Overlay = styled.div<{
    open: boolean
}>`
    position: absolute;
    height: ${(props) => (props.open ? '220px' : 0)};
    width: 101%;
    top: -2px;
    background: #fff;
    transition: height 0.4s ease-in-out;
    z-index: 9;
    color: #000;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
`

const OverlayContent = styled.div<{
    open: boolean
}>`
    padding: 25px;
    display: ${(props) => (props.open ? 'flex' : 'none')};
    flex-direction: column;
    animation: ${fadeIn} 0.8s ease-in-out;
    animation-duration: 0.6s;

    & > img {
        margin-top: 50px !important;
    }

    a:not(:last-child) {
        border-bottom: 1px solid #cacaca;
    }
`

const Link = styled.a`
    color: #000;
    text-decoration: none;
    font-size: 16px;
    padding: 18px 0;
    transition: transform 0.2s ease-in-out;

    &:hover {
        transform: translateX(5px);
    }
`
