import { Divider } from '@mui/material'
import { Link } from 'react-router-dom'
import { RouteNames } from '../../../router'
import { ListItem, PageContainer } from './styles'

const DocAccount = () => {
    return (
        <PageContainer>
            <h1>Profile settings</h1>
            <span>
                Features of managing your{' '}
                <Link to={RouteNames.ACCOUNT}>
                    <strong>profile.</strong>
                </Link>
            </span>
            <Divider />
            <div className="content">
                <div>
                    The profile page shows your full name, current subscription, information about
                    recent profile updates. As well as the username and your email. You cannot
                    change your username as it is unique to your profile and used to create the
                    campaign. The main features available on this page are:
                    <ListItem>
                        <li>Change the user's first and last name.</li>
                        <li>Account handoff.</li>
                        <li>Change password.</li>
                    </ListItem>
                </div>
                <img className="image" src="/images/docs/account.png" alt="account handoff" />
                <p>
                    For Premium and Lite subscriptions, technical support for your profile is
                    available from our specialists. If you have any questions regarding changes to
                    your account data, just contact us via the{' '}
                    <Link to={RouteNames.FEEDBACK}>
                        <strong>feedback</strong>
                    </Link>{' '}
                    form and we will help you solve any problem.
                </p>
            </div>
        </PageContainer>
    )
}

export default DocAccount
