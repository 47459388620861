import { useState } from 'react'

import { capitalizeFirstLetter } from '../../helpers/capitalizeFirstLetter'
import { Toast } from '../../helpers/Toast'
import { BillingDetailsContainer } from '../../pages/Billing/styles'
import UserService from '../../../services/UserService'
import { Button } from '../common/Button'
import { useConfirm } from '../../../hooks/confirm'
import { useAuth } from 'widgets-base'

const BillingDetails = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const { currentUser, setCurrentUser } = useAuth()
    const { isConfirmed } = useConfirm();

    const onUnsubscribe = async () => {
        setLoading(true)
        try {
            const confirmed = await isConfirmed(`Are you sure you want to unsubscribe?`)

            if (confirmed) {
                const { data } = await UserService.deleteSubscription(
                    currentUser.stripe.subscriptionId
                )
                setCurrentUser(data) //todo: Does this really need to update the current user?
                Toast('Subscription canceled!', 'success')
            }
        } catch (error) {
            console.log(error)
            Toast(error.response.data.message, 'error')
        }
        setLoading(false)
    }

    return (
        <BillingDetailsContainer>
            <span className="title">Current plan</span>
            <h3>{capitalizeFirstLetter(currentUser.role)}</h3>
            {currentUser?.stripe?.subscriptionId && (
                <div className="stripe">
                    <h3>Billing period</h3>
                    <span>Start date: {new Date(currentUser.stripe.createdAt).toDateString()}</span>
                    <span>End date: {new Date(currentUser.stripe.finishedAt).toDateString()}</span>
                    <Button loading={loading} variant="primary" onClick={onUnsubscribe}>
                        Unsubscribe
                    </Button>
                </div>
            )}
        </BillingDetailsContainer>
    )
}

export default BillingDetails
