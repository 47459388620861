import { TextField } from "@mui/material";

export function SeedQuestions({ seeds, answers, setAnswer, imageSearch, setImageSearch }) {

    return (
        <>
            {seeds.map((seedQuestion, index) => {
                return (
                    <div 
                        key={index}
                        className="mb-10"
                        >
                        <div className="mt-2 text-base">
                            {index+1}. {seedQuestion.question}
                        </div>
                        <div className="flex flex-col w-full mt-2">
                            <TextField
                                className="w-full"
                                multiline
                                rows={5}
                                placeholder="Write your answer here"
                                value={answers[index]}
                                onChange={(e) => setAnswer(index, e.target.value)}
                                />
                        </div>
                    </div>
                );
            })}

            <div className="mb-6">
                <div className="mt-2 text-base">
                    {seeds.length > 0 &&
                        `${seeds.length+1}. ` 
                    }

                    What images would you like to use?
                </div>
                <div className="flex flex-col w-full mt-2">
                    <TextField
                        className="w-full"
                        multiline
                        rows={5}
                        placeholder="Enter a search term for images"
                        value={imageSearch}
                        onChange={(e) => setImageSearch(e.target.value)}
                        />
                </div>
            </div>

        </>
    );
}