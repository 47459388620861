import { WidgetPalette } from './widget-palette'
import { useRecoilState } from 'recoil';
import SettingsTab, { Marginier } from '../../v1/components/Builder/SidebarTabs/SettingsTab';
import QRDesignTab from '../../v1/components/Builder/SidebarTabs/QRDesignTab';
import { selectedNavbar } from './builder-nav';
import { sideBarOpenState } from '../../v1/components/BuilderLayout';
import { WidgetContextProvider } from '../context/widget';
import { PropertiesEditor } from '../property/property-editor';
import RenderSettings from '../../v1/components/EditMode/RenderSettings';
import { useCampaign } from '../context/campaign';
import { elementCategories, sectionCategories } from '../widget/widget-templates';
import { debugJsonState, debugModeState } from '../../v1/store/builder/builder';

export function Sidebar({ paletteKey }) {

    const { selectedWidget } = useCampaign();
    const [activeTab, setActiveTab] = useRecoilState(selectedNavbar);
    const [isSidebarOpen, setSidebarOpen] = useRecoilState(sideBarOpenState);
    const [debugMode, setDebugMode] = useRecoilState(debugModeState);
    const [debugJson, setDebugJson] = useRecoilState(debugJsonState);

    return (
        <div className="h-full overflow-y-auto">

            <div className="p-4 pb-1 flex flex-row items-center">
                <h2 className="flex-grow text-lg font-medium cursor-pointer">{activeTab}</h2>
                <img
                    src="/icons/close-panel.svg"
                    alt="close panel"
                    height={20}
                    width={20}
                    onClick={() => setSidebarOpen(false)}
                />
            </div>

            {activeTab === "Settings" && (
                <SettingsTab />
            )}

            {activeTab === "Sections" && (
                <WidgetPalette 
                    key={`${paletteKey}-sections`}
                    widgetCategories={sectionCategories} 
                    />
            )}

            {activeTab === "Elements" && (
                <WidgetPalette 
                    key={`${paletteKey}-elements`}
                    widgetCategories={elementCategories} 
                    />
            )}

            {activeTab === "QR Design" && (
                <QRDesignTab />
            )}

            {(selectedWidget  && activeTab === "Selected") && (
                <WidgetContextProvider
                    key={selectedWidget.id}
                    widget={selectedWidget}
                    widgetPath={undefined}
                    >

                    <div className="relative pb-40">
                        {selectedWidget.xtype !== undefined
                            && <PropertiesEditor />
                        }

                        {selectedWidget.type !== undefined
                            && <>
                                <RenderSettings />
                                <Marginier height='150px' />
                            </>
                        }
                    </div>
                </WidgetContextProvider>
            )}

            {debugMode && activeTab === "Debug" && (
                <div>
                    <pre>
                        {debugJson}
                    </pre>
                </div>
            )}

        </div>
    )
}
