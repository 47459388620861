import { FC } from 'react'
import { WidgetSettingContainer } from '../../Design/WidgetSettingContainer'
import SidebarTabs from '../../EditMode/SidebarTabs'
import PollsDesignSetting from './PollsDesignSetting'
import PollsGeneralSetting from './PollsGeneralSetting'

interface Props {
}

const PollsSetting: FC<Props> = () => {
    return (
        <WidgetSettingContainer padding={0}>
            <SidebarTabs
                setting={<PollsGeneralSetting />}
                design={<PollsDesignSetting />}
            />
        </WidgetSettingContainer>
    )
}

export default PollsSetting
