import { RouteNames } from '.'
import { ProtectedRoute } from '../components/protected-route'
import Feedback from '../v1/pages/Account/Feedback'
import SuccesPayment from '../v1/pages/Billing/SuccesPayment'

// lazy imports
import AccountPage from '../v1/pages/Account'
import BillingPage from '../v1/pages/Billing'
import BrandSettings from '../v1/pages/Branding'
import { FormsPage } from '../v1/pages/Forms';
import FormDetails from '../v1/pages/Forms/FormDetails'
import AssetsPage from '../v1/pages/Assets'

export const privatRoutes = [
    {
        path: RouteNames.ACCOUNT,
        element: (
            <ProtectedRoute>
                <AccountPage />
            </ProtectedRoute>
        ),
    },
		{
			path: RouteNames.ASSETS,
			element: (
					<ProtectedRoute>
							<AssetsPage />
					</ProtectedRoute>
			),
		},
    {
        path: RouteNames.BILLING,
        element: <BillingPage />, //TODO: Why not make this a private route here?
    },
    {
        path: `${RouteNames.SUCCESS_PAY}/:role/:session_id`,
        element: (
            <ProtectedRoute>
                <SuccesPayment />
            </ProtectedRoute>
        ),
    },
    {
        path: RouteNames.BRAND,
        element: (
            <ProtectedRoute>
                <BrandSettings />
            </ProtectedRoute>
        ),
    },
    {
        path: RouteNames.FORMS,
        element: (
            <ProtectedRoute>
                <FormsPage />
            </ProtectedRoute>
        ),
    },
    {
        path: `${RouteNames.FORMS}/:campaignId/:formId`,
        element: (
            <ProtectedRoute>
                <FormDetails />
            </ProtectedRoute>
        ),
    },
    {
        path: RouteNames.FEEDBACK,
        element: (
            <ProtectedRoute>
                <Feedback />
            </ProtectedRoute>
        ),
    },
]
