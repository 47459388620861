import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import FormService from '../../../services/FormService'
import { ISubmission } from '../../modules/ISubmission'
import { FormsPageWrapper } from './styles'
import { SEO } from '../../components/SEO'
import { capitalizeFirstLetter } from '../../helpers/capitalizeFirstLetter'
import FormLoader from '../../components/Forms/FormLoader'
import { Toast } from '../../helpers/Toast'
import FormDetailsActions from '../../components/Forms/FormDetailsActions'
import FormTable from '../../components/Forms/FormTable'
import { IForms } from '../../modules/IForm'
import { useAuth } from 'widgets-base'


const FormDetails = ({}) => {
    const { campaignId, formId } = useParams();
    const [loading, setLoading] = useState<boolean>(true);
    const [currentForm, setCurrentForm] = useState<IForms>();
    const [submissions, setSubmissions] = useState<ISubmission[]>([]);
    const { currentUser } = useAuth();

    useEffect(() => {
        if (!currentUser) {
            return;
        }

        setLoading(true);
        FormService.getSubmissions(campaignId, formId)
            .then(({ data }) => {
                setSubmissions(data.submissions);
                setCurrentForm(data.form);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, [currentUser, campaignId, formId]);

    const onRefreshData = async () => {
        setLoading(true)
        try {
            const { data } = await FormService.getSubmissions(campaignId, formId)
            setSubmissions(data.submissions)
        } catch (error) {
            console.log(error)
            Toast(error.response.data.message, 'error')
        }
        setLoading(false)
    }

    return (
        <FormsPageWrapper>
            <SEO title="Form details" />
            <h1>Form details</h1>
            <span>Analyze the received data to achieve your marketing goals</span>
            <p>
                Form name: <span>{capitalizeFirstLetter(currentForm?.formName) || '...'}</span>
            </p>
            <FormDetailsActions
                refreshData={onRefreshData}
                form={currentForm}
                campaignId={campaignId}
                submissions={submissions}
            />
            {loading ? (
                <FormLoader />
            ) : (
                <FormTable
                    fields={currentForm?.fields}
                    campaignId={campaignId}
                    setLoading={setLoading}
                    submissions={submissions}
                    setSubmissions={setSubmissions}
                />
            )}
        </FormsPageWrapper>
    )
}

export default FormDetails
