//
// https://medium.com/@jaredloson/a-replacement-for-window-confirm-using-promises-and-react-hooks-cfc011e76a7a
//

import React, { createContext, useContext, useState } from 'react'

export const ConfirmContext = createContext([])

export function ConfirmContextProvider({ children }) {
    const [confirm, setConfirm] = useState({
        prompt: '',
        isOpen: false,
        proceed: null,
        cancel: null,
    })

    return (
        <ConfirmContext.Provider value={[confirm, setConfirm]}>{children}</ConfirmContext.Provider>
    )
}

export const useConfirm = () => {
    const [confirm, setConfirm] = useContext(ConfirmContext)

    const isConfirmed = (prompt: string) => {
        const promise = new Promise((resolve, reject) => {
            setConfirm({
                prompt,
                isOpen: true,
                proceed: resolve,
                cancel: reject,
            })
        })
        return promise.then(
            () => {
                setConfirm({ ...confirm, isOpen: false })
                return true
            },
            () => {
                setConfirm({ ...confirm, isOpen: false })
                return false
            }
        )
    }

    return {
        ...confirm,
        isConfirmed,
    }
}
