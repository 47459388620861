import React from 'react'
import styled from 'styled-components'
import { makeFullAssetUrl } from 'widgets-base'

interface Props {
    getPass: () => Promise<void>
}

export const WalletButton = ({ getPass }: Props) => {
    return (
        <CustomWalletButton onClick={getPass}>
            <img width={160} src={makeFullAssetUrl("/images/add-to-apple-wallet-logo.png")} alt="wallet" />
        </CustomWalletButton>
    )
}

const CustomWalletButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 50px;
`
