import { FC, memo } from 'react'
import styled from 'styled-components'
;
import { useWidget } from 'widgets-base';

interface Props {
    editMode?: boolean
}

const IframeWidget: FC<Props> = ({ editMode }) => {

    const { widget } = useWidget();
    const appearanceSettings = widget?.appearanceSettings
    const colourSettings = widget?.colourSettings
    const name = widget?.name
    const src = widget?.settings.src
    const width = appearanceSettings?.width + '%'
    const height = appearanceSettings?.height + 'em'

    const paddingTop = appearanceSettings?.paddingTop / 10 + 'em'
    const paddingBottom = appearanceSettings?.paddingBottom / 10 + 'em'
    const paddingLeft = appearanceSettings?.paddingLeft / 10 + 'em'
    const paddingRight = appearanceSettings?.paddingRight / 10 + 'em'
    const marginTop = appearanceSettings?.marginTop / 10 + 'em'
    const marginBottom = appearanceSettings?.marginBottom / 10 + 'em'
    const marginLeft = appearanceSettings?.marginLeft / 10 + 'em'
    const marginRight = appearanceSettings?.marginRight / 10 + 'em'
    const borderWidth = appearanceSettings?.borderWidth / 10 + 'em'
    const borderTopLeftRadius = appearanceSettings?.borderTopLeftRadius / 10 + 'em'
    const borderBottomLeftRadius = appearanceSettings?.borderBottomLeftRadius / 10 + 'em'
    const borderTopRightRadius = appearanceSettings?.borderTopRightRadius / 10 + 'em'
    const borderBottomRightRadius = appearanceSettings?.borderBottomRightRadius / 10 + 'em'
    const borderColor = Array.isArray(colourSettings?.['border colour'])
        ? colourSettings?.['border colour'][0]
        : colourSettings?.['border colour']
    const backgroundColor = Array.isArray(colourSettings?.['background colour'])
        ? colourSettings?.['background colour'][0]
        : colourSettings?.['background colour']

    return (
        <IframeSection id={widget.id}>
            <Container
                isPreview={editMode}
                width={width}
                height={height}
                paddingTop={paddingTop}
                paddingBottom={paddingBottom}
                paddingLeft={paddingLeft}
                paddingRight={paddingRight}
                marginTop={marginTop}
                marginBottom={marginBottom}
                marginLeft={marginLeft}
                marginRight={marginRight}
                borderTopLeftRadius={borderTopLeftRadius}
                borderBottomLeftRadius={borderBottomLeftRadius}
                borderTopRightRadius={borderTopRightRadius}
                borderBottomRightRadius={borderBottomRightRadius}
            >
                <iframe
                    src={src}
                    loading="lazy"
                    name={name}
                    title={name}
                    width={width}
                    height={height}
                    style={{
                        backgroundColor,
                        borderWidth,
                        borderColor,
                    }}
                    allow="camera; microphone"
                    frameBorder="0"
                    allowFullScreen
                    seamless
                    sandbox="allow-forms allow-modals allow-popups allow-presentation allow-popups-to-escape-sandbox allow-same-origin allow-scripts allow-top-navigation  allow-top-navigation-by-user-activation"
                    referrerPolicy="strict-origin-when-cross-origin"
                >
                    <p className="text-sm">Your browser does not support iframes.</p>
                </iframe>
            </Container>
        </IframeSection>
    )
}

export default memo(IframeWidget)

const IframeSection = styled.section`
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
`
const Container = styled.div<{
    isPreview: boolean
    width: string
    height: string
    paddingTop: string
    paddingBottom: string
    paddingLeft: string
    paddingRight: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
    borderTopLeftRadius: string
    borderBottomLeftRadius: string
    borderTopRightRadius: string
    borderBottomRightRadius: string
}>`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    display: flex;
    justify-content: center;
    cursor: pointer;
    padding-top: ${(props) => props.paddingTop};
    padding-bottom: ${(props) => props.paddingBottom};
    padding-left: ${(props) => props.paddingLeft};
    padding-right: ${(props) => props.paddingRight};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
    border-top-left-radius: ${(props) => props.borderTopLeftRadius};
    border-top-right-radius: ${(props) => props.borderTopRightRadius};
    border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
    pointer-events: ${(props) => (props.isPreview ? 'none' : 'auto')};

    iframe {
        width: 100%;
        height: 100%;
        border-radius: inherit;
    }
`
