import { nanoid } from 'nanoid'
import { ISocial } from '../../../modules/widgets/general/ISocial'

class Social implements ISocial {
    constructor(public iconMode?: boolean) {
        this.appearanceSettings.useIcon = iconMode
    }
    name = 'social'
    type = 'social'
    id = nanoid()    
    category = 'general'
    settings = {
        links: {
        },
        icons: {
            facebook: '/icons/social/facebook-icon.png',
            twitter: '/icons/social/twitter.png',
            instagram: '/icons/social/instagram.png',
            linkedin: '/icons/social/linkedin.png',
            whatsapp: '/icons/social/whatsapp.png',
            tiktok: '/icons/social/tik-tok.png',
            youtube: '/icons/social/youtube.png',
            pinterest: '/icons/social/painters.png',
            spotify: '/icons/social/spotify.png',
        },
    }
    appearanceSettings = {
        width: 90,
        flex: true,
        useIcon: false,
        height: 'auto',
        iconWidth: 30,
        iconHeight: 30,
        iconSpacing: 15,
        columns: 3,
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 15,
        marginBottom: 15,
        marginLeft: 0,
        marginRight: 0,
    }
    colourSettings = {
        iconColour: ['#6478f9', '#FFA7E1', '#0426FF'],
    }
}

export default Social
