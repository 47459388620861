import { Rating } from '@mui/material'
import { ChangeEvent, useState } from 'react'
import { Button } from '../../components/common/Button'
import { InputBox } from '../../components/common/InputBox'
import { SEO } from '../../components/SEO'
import { Toast } from '../../helpers/Toast'
import { sendFeedbackMessage } from '../../lib/slack/sendFeedbackMessage'

import { FeedbackWrapper } from './styles'
import { useAuth } from 'widgets-base'

const Feedback = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const { currentUser } = useAuth()
    
    const [feedbackData, setFeedbackData] = useState({
        email: '',
        company: '',
        feedback: '',
        rating: 0,
    })

    const handleChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
        setFeedbackData((prev) => ({ ...prev, [event.target.name]: event.target.value }))
    }

    const onChangeRating = (event: any, newValue: number) => {
        setFeedbackData((prev) => ({ ...prev, rating: newValue }))
    }

    const sendFeedback = async () => {
        setLoading(true)
        try {
            const data = {
                ...feedbackData,
                customer: `${currentUser.firstname + ' ' + currentUser.lastname}`,
            }

            await sendFeedbackMessage(data)
            Toast('Message sended', 'success')
        } catch (error) {
            console.log(error)
            Toast(error.response.data.message, 'error')
        }
        setLoading(false)
    }

    return (
        <FeedbackWrapper>
            <SEO title="Feedback" />
            <h1>Feedback</h1>
            <span>
                Every feedback regarding the work of our platform is important to us. Leave your
                feedback about the experience of using our tools.
            </span>
            <h1 className="title">Share your experience about our platform</h1>
            <div className="feedback-data">
                <img src="/images/feedback2.png" alt="feedback" />
                <h3>
                    Here you can leave your suggestions and wishes for improving the work and/or
                    adding new features to achieve your marketing goals.
                </h3>
                <InputBox
                    value={feedbackData.email}
                    name="email"
                    label="Enter your email if you need feedback"
                    onChange={handleChangeValue}
                />
                <InputBox
                    value={feedbackData.company}
                    name="company"
                    label="Enter your company name"
                    onChange={handleChangeValue}
                />
                <div className="feedback-rating">
                    <label htmlFor="rating">Rate our platform from 1 to 10</label>
                    <Rating
                        id="rating"
                        name="simple-controlled"
                        size="large"
                        max={10}
                        value={feedbackData.rating}
                        onChange={onChangeRating}
                    />
                </div>
                <InputBox
                    type="textarea"
                    height="200px"
                    value={feedbackData.feedback}
                    name="feedback"
                    label="Enter your feedback or tell us your experience story"
                    onChange={handleChangeValue}
                />
                <Button width="200px" variant="primary" loading={loading} onClick={sendFeedback}>
                    SEND FEEDBACK
                </Button>
            </div>
        </FeedbackWrapper>
    )
}

export default Feedback
