import React from 'react'
import styled from 'styled-components'

export const File = ({
    name,
    imageSrc,
    videoSrc,
    editable,
    onClick,
    height,
    width,
}: {
    name: string
    imageSrc?: string
    videoSrc?: string
    editable?: boolean
    onClick?: any
    height?: string
    width?: string
}) => {
    return (
        <Body height={height} width={width} onClick={onClick} editable={editable}>
            {imageSrc && imageSrc.trim() !== '' ? (
                <ThumbnailImage imageSrc={imageSrc} />
            ) : (
                <ThumbnailVideo src={videoSrc} />
            )}
            <p className="text-center pt-2 overflow-hidden">{name}</p>
        </Body>
    )
}

const Body = styled.div.attrs((props) => ({}))<{
    editable: boolean
    height: string
    width: string
}>`
    display: flex;
    flex-direction: column;
    cursor: ${(props) => (props.editable ? 'pointer' : 'not-allowed')};
    width: ${(props) => (props.width ? props.width : '132px')};
    height: ${(props) => (props.height ? props.height : '132px')};
    background: #fafaff;
    border: 1px solid #dfe2f2;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 8px 8px 8px 8px;
    object-fit: cover;
    align-items: center;
    justify-content: center;
    overflow-wrap: anywhere;

    margin-top: 5px;
    margin-bottom: 5px;

    p {
        font-size: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100px;
    }
`

const ThumbnailImage = styled.div.attrs((props) => ({}))<{
    imageSrc: string
}>`
    align-self: center;
    background: url('${(props) => props.imageSrc}');
    width: 120px;
    height: 100px;
    background-size: cover;
    overflow: hidden;
    display: block;
    margin: auto;
    background-position: center;
`

const ThumbnailVideo = styled.video`
    align-self: center;
    width: 120px;
    height: 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    object-fit: fill;
    pointer-events: none;
    display: block;
`
