import React, { createContext, ReactNode, useContext, useRef } from "react";
import { getElementRect, Rect } from "../lib/dom";

export interface IWidgetRefMap {
    [index: string]: React.MutableRefObject<HTMLElement>;
}

export interface IEditorContextHook {

    //
    // Cached rects for each widget.
    //
    widgetRectMapRef: React.MutableRefObject<IWidgetRefMap>;

    //
    // Sets the ref for a widget.
    //
    setWidgetRef(widgetId: string, ref: React.MutableRefObject<HTMLElement>): void;

    //
    // Get the rect for a widget.
    //
    getWidgetRect(widgetId: string): Rect;
}

const EditorContext = createContext<IEditorContextHook>(null);

export interface IProps {
    children: ReactNode | ReactNode[];
}

export function EditorContextProvider({ children }: IProps) {
   
    //
    // TODO: Probably want to move a bunch of stuff for editing campaigns from the campaing context to here.
    //

    //
    // Cache for widget refs.
    // 
    const widgetMapRef = useRef<IWidgetRefMap>({}); 

    //
    // Sets the ref for a widget.
    //
    function setWidgetRef(widgetId: string, ref: React.MutableRefObject<HTMLElement>): void {
        widgetMapRef.current = {
            ...widgetMapRef.current,
            [widgetId]: ref,
        };
    }

    //
    // Get the rect for a widget.
    //
    function getWidgetRect(widgetId: string): Rect | undefined {
        const widgetRef = widgetMapRef.current[widgetId];
        return getElementRect(widgetRef);
    }

    const value: IEditorContextHook = {
        widgetRectMapRef: widgetMapRef,
        setWidgetRef,
        getWidgetRect,
    };

    return (
        <EditorContext.Provider 
            value={value}
            >
            {children}
        </EditorContext.Provider>
    );    
}

export function useEditorContext(): IEditorContextHook {
    const context = useContext(EditorContext);
    if (!context) {
        throw new Error(`Editor context is not set! Add EditorContextProvider to the component tree.`);
    }
    return context;

}
