import { useEffect } from "react";

export function Redirect({ to }) {
    useEffect(() => {
        const timeout = setTimeout(() => {
            window.location.replace(to);
        }, 10);

        return () => clearTimeout(timeout);
    }, []);

    return <></>;
}