import { FC } from 'react'
import styled from 'styled-components'
import { EventItem } from '../../../models/widgets/general/Events'
import { IEvent } from '../../../modules/widgets/general/IEvents'
import AddElementButton from './AddElementButton'
import EventItemSetting from './EventItemSetting'
import { useWidget } from 'widgets-base'

interface Props {
}

const EventListSetting: FC<Props> = () => {

    const { widget, setWidget } = useWidget();

    const onAddNewEvent = () => {
        const newEvent = new EventItem()

        const newSettings = {
            ...widget,
            settings: {
                ...widget.settings,
                eventsList: [...widget.settings.eventsList, newEvent],
            },
        }

        setWidget(newSettings);
    }

    const onRemoveEvent = (id: string) => () => {
        if (widget.settings.eventsList.length === 1) {
            return
        }
        const filteredEvent = widget.settings.eventsList.filter((event: IEvent) => event.id !== id)
        const newSettings = {
            ...widget,
            settings: {
                ...widget.settings,
                eventsList: filteredEvent,
            },
        }

        setWidget(newSettings);
    }

    const updateEvent = (eventIndex: number, settings: any) => {
        const currentEvent = widget.settings.eventsList[eventIndex]
        const updatedEvent = {
            ...currentEvent,
            ...settings,
        }
        const newList = [
            ...widget.settings.eventsList.slice(0, eventIndex),
            updatedEvent,
            ...widget.settings.eventsList.slice(eventIndex + 1),
        ]
        const newSettings = {
            ...widget,
            settings: {
                ...widget.settings,
                eventsList: newList,
            },
        }

        setWidget(newSettings);
    }

    return (
        <EventsContainer>
            {widget?.settings?.eventsList?.map((event: IEvent, index: number) => (
                <EventItemSetting
                    key={event?.id}
                    event={event}
                    eventIndex={index}
                    deleteEvent={onRemoveEvent}
                    updateEvent={updateEvent}
                />
            ))}
            <AddElementButton name="Add event" onClick={onAddNewEvent} />
        </EventsContainer>
    )
}

export default EventListSetting

const EventsContainer = styled.div`
    & > div:last-child {
        display: flex;
        justify-content: flex-end;
    }
    & > div:first-child {
        & > div:last-child {
            margin-bottom: 0;
        }
    }
`
