import { Button } from '@mui/material';
import { SeedQuestions } from '../seed-questions';
import { Preview } from '../preview';
import { clientUpload } from '../../../v1/lib/upload/clientUpload';
import { IWidget, useAuth } from 'widgets-base';
import { ProgressButton } from './components/progress-button';
import { useRef } from 'react';

//
// Get all the images from the tree of widgets.
//
function* pullImagesFromWidgetHierarchy(widgets: IWidget[]): Generator<IWidget> {
    for (const widget of widgets) {
        if (widget.xtype === "image") {
            yield widget;
        }

        if (widget.children) {
            yield* pullImagesFromWidgetHierarchy(widget.children);
        }
    }
}

//
// Renders an image that can be replaced with drag and drop.
//
function ImageDropReplace({ src, srcId, onAssetUploaded }: { src?: string, srcId?: string, onAssetUploaded?: (assetId: string) => void }) {

    const { makeAssetUrl } = useAuth();
    const inputRef = useRef<HTMLInputElement>(undefined);

    function handleDragOver(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
    }

    async function handleDrop(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();

        const file = e.dataTransfer.files[0];
        const asset = await clientUpload(file);
        onAssetUploaded(asset._id);
    }

    function onClickImage() {
        inputRef.current.click();
    }

    async function onChangeImage(event: React.ChangeEvent<HTMLInputElement>) {
        const file = event.target.files[0];
        const asset = await clientUpload(file);
        onAssetUploaded(asset._id);
    }

    let srcUrl;
    if (srcId) {
        srcUrl = makeAssetUrl(srcId);
    }
    else if (src) {
        srcUrl = src;
    }
    else {
        srcUrl = "/images/placeholder-image.svg";
    }

    return (
        <div 
            className="border border-gray-400 bg-slate-50 bg-opacity-0 flex flex-col items-center pt-2 pb-3 px-4 rounded-2xl border-dashed"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            >
            <input 
                ref={inputRef}
                className="hidden"
                type="file" 
                onChange={onChangeImage} 
                />                
            <div
                style={{
                    height: "80px",
                }}
                >
                <img 
                    src={srcUrl} 
                    style={{
                        height: "100%",
                        objectFit: "contain",
                    }}
                    onClick={onClickImage} 
                    />
            </div>
            <div className="text-center text-xs self-stretch mt-3">
                <span className="font-semibold">Drop your file here or </span>
                <span 
                    className="font-semibold underline text-indigo-500"
                    onClick={onClickImage} 
                    >
                    upload
                </span>
            </div>
            <div className="text-slate-500 text-center text-xs self-stretch mt-2.5">
                .jpg or .png file, max upload 2mb
            </div>
        </div>
    );
}

export function ChooseAssets({ campaign, setCampaign, generating, pageTheme }) {

    return (
        <div className="flex flex-col w-full items-center overflow-hidden">
            <div className="flex flex-row items-center">
                <div
                    className="flex flex-col pr-10"
                    style={{ width: "500px" }}
                >
                    {/* <SeedQuestions
                        seeds={[]}
                        answers={() => {}}
                        setAnswer={() => {}}
                        imageSearch={imageSearch}
                        setImageSearch={setImageSearch}
                        />

                    <div className="flex flex-row mt-6">
                        <Button
                            variant="contained"
                            onClick={onNext}
                            >
                            Continue
                        </Button>

                        <Button
                            sx={{ ml: 1 }}
                            variant="outlined"
                            onClick={onBack}
                            >
                            Back
                        </Button>

                        <ProgressButton
                            onClick={onGenerateAssets}
                            generating={generating}
                            >
                            Generate again
                        </ProgressButton>
                    </div> */}

                    <div className="flex flex-col mt-20">
                        <div className="text-lg">
                            Drag and drop to replace generated assets with your own assets:
                        </div>

                        <div className="grid grid-cols-3 mt-5 p-1 gap-x-3 gap-y-6">
                            <div 
                                className="flex flex-col items-center"
                                >
                                <ImageDropReplace
                                    src={campaign.views[0].backgroundImageUrl}
                                    srcId={campaign.views[0].backgroundImageId}
                                    onAssetUploaded={uploadedAssetId => {
                                        //
                                        // Patch the new background image into the campaign.
                                        //
                                        setCampaign({
                                            ...campaign,
                                            views: [{
                                                ...campaign.views[0],
                                                backgroundImageUrl: undefined,
                                                backgroundImageId: uploadedAssetId,
                                            }],
                                            lastUpdate: Date.now(),
                                        });
                                    }}
                                />
                                <div className="mt-2 text-xs text-gray-700">
                                    Background image
                                </div>
                            </div>

                            {Array.from(pullImagesFromWidgetHierarchy(campaign.views[0].widgets)).map(imageWidget => {
                                return (
                                    <div
                                        key={imageWidget.id}
                                        className="flex flex-col items-center"                                    >
                                        <ImageDropReplace
                                            src={imageWidget.properties?.image?.url || imageWidget.properties?.src}
                                            srcId={imageWidget.properties?.image?.assetId}
                                            onAssetUploaded={uploadedAssetId => {
                                                //
                                                // Patch the new image into the campaign.
                                                // It's a bit ugly to mutate the campaign like this, but it's the easiest way to get it working.
                                                //
                                                delete imageWidget.properties.src;
                                                delete imageWidget.properties.image;
                                                imageWidget.properties.image = {
                                                    assetId: uploadedAssetId,
                                                };

                                                setCampaign({
                                                    ...campaign,
                                                    lastUpdate: Date.now(),
                                                });
                                            }}
                                        />
                                        <div className="mt-2 text-xs text-gray-700">
                                            {imageWidget.name}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                <div className="mt-24">
                    <Preview
                        generating={generating}
                        campaign={campaign}
                        pageTheme={pageTheme}
                        fontSize="4px"
                        height="600px"
                        width="500px"
                    />
                </div>
            </div>
        </div>
    );
}

