import React from "react";
import { DroppableGutter } from "./DroppableGutter";
import { MoveableWidget } from "./moveable-widget";
import { Direction, IWidget } from "widgets-base";
import { WidgetContextProvider } from "../../../builder/context/widget";

export interface IWidgetsProps {
    //
    // The ID for the group of widgets (required for drag and drop).
    //
    groupId: string

    //
    // The group of widgets to render.
    //
    widgets: IWidget[];

    //
    // The flow direction for this group of widgets.
    //
    direction: Direction;

    //
    // The path to parent widget.
    //
    parentWidgetPath: number[];
}

//
// Renders a list of widgets that can be sorted via drag and drop.
//
export function MoveableWidgets({ groupId, widgets, direction, parentWidgetPath }: IWidgetsProps ) {

    return (
        <>
            {widgets?.map((widget, widgetIndex) => {
                return (
                    <WidgetContextProvider
                        key={`${widget.id}-${widgetIndex}-${widget.lastUpdate}`}
                        widget={widget}
                        widgetPath={parentWidgetPath.concat([widgetIndex])}
                        >
                        <MoveableWidget
                            key={`${widget.id}-${widgetIndex}-${widget.lastUpdate}`}
                            parentDirection={direction}
                        />
                    </WidgetContextProvider>
                );
            })}

            {groupId !== "main" && widgets?.length === 0 &&
                <DroppableGutter
                    dropId={`gutter-bottom-${groupId}`}
                    parentDirection={direction}
                    />
            }
        </>
    );
}
