import { Divider } from '@mui/material'
import { Link } from 'react-router-dom'
import { RouteNames } from '../../../router'
import { ListItem, PageContainer } from './styles'

const CreateCampaign = () => {
    return (
        <PageContainer>
            <h1>Create campaign</h1>
            <span>
                A campaign is an object that includes settings for styles, fonts, images, pages, and
                a collection of widgets for displaying on pages.
            </span>
            <Divider />
            <div className="content">
                <p>
                    The new default campaign created includes the styles that are configured in the
                    brand settings. Therefore, we recommend that you go through the following steps
                    to create your first campaign.
                </p>
                <Divider />
                <h1>Campaign creating flow</h1>
                <h2>Step 1</h2>
                <p>
                    <span>Brand setting</span>
                    <br />
                    To start creating a campaign, you need to click on the New project button. You
                    will see a pop-up window offering to customize brand styles. We recommend that
                    you start by setting up your brand. This can be done on the{' '}
                    <Link to={RouteNames.BRAND}>
                        <strong>brand page</strong>
                    </Link>
                    . Read more about what brand customization is and how it works{' '}
                    <Link to={RouteNames.DOC_BRAND_ASSETS}>
                        <strong>here</strong>
                    </Link>
                    . You can skip this step if you have already set up your brand.
                </p>
                <img className="image" src="/images/docs/brand-doc.png" alt="brand setting" />
                <h2>Step 2</h2>
                <p>
                    <span>Template selection</span>
                    <br />
                    At this stage, we suggest that you familiarize yourself with our templates. If
                    you don't want to use a template at this stage, choose a blank form. You can
                    also get acquainted with templates directly in the constructor, as well as
                    create your own template. More about this in the{' '}
                    <Link to={RouteNames.DOC_TEMPLATES}>
                        <strong>Templates</strong>
                    </Link>{' '}
                    section.
                </p>
                <h2>Step 3</h2>
                <p>
                    <span>Campapign setting</span>
                    <br />
                    At this stage, you already have a campaign draft created. For further work, you
                    need to specify the settings for your campaign. Please note that the draft does
                    not provide you with the necessary link for your customers to visit this page.
                    The corresponding reference is assigned after deployment. See the{' '}
                    <Link to={RouteNames.DOC_BILLING}>
                        <strong>billing </strong>
                    </Link>
                    section for the number of campaigns that can be published.
                </p>
                <img className="image" src="/images/docs/builder-doc.png" alt="campaign setting" />
                <div>
                    Campaign setup consists of the following functions:
                    <ListItem>
                        <li>
                            Project name. The name of your campaign, which is also a meta tag (for
                            paid subscriptions). Once deployed, your project name becomes your link
                            and can not be changed after deployment at this point.
                        </li>
                        <li>
                            Description of the project, which is also a meta tag (for paid
                            subscriptions). Also, this description of the project will be displayed
                            when embedding the campaign. Read more about embedding{' '}
                            <Link to={RouteNames.DOC_EMBED_CAMPAIGN}>
                                <strong>here</strong>
                            </Link>
                            .
                        </li>
                        <li>
                            Sharing thumbnail preview image. The image that is displayed whenever
                            someone shares a post or page of your website on a social media platform
                            like Facebook or Twitter. This image is also a preview for the campaign
                            on the dashboard page, and is also used when embedding the campaign.
                        </li>
                        <li>
                            Redirect page (optional). When a web browser attempts to open a URL that
                            has been redirected, a page with a different URL is opened.
                        </li>
                        <li>
                            Analytics (optional). Input Google Tag Manager to be able to link back
                            analytics to your Google Analytics Account.
                        </li>
                    </ListItem>
                </div>
                <p>
                    After setting up the basic company data, you can proceed to explore the main
                    features of our{' '}
                    <Link to={RouteNames.DOC_BUILDER}>
                        <strong>constructor</strong>
                    </Link>
                    , as well as learn more about our{' '}
                    <Link to={RouteNames.DOC_WIDGETS}>
                        <strong>widgets</strong>
                    </Link>
                    . We recommend that you periodically save your progress. You can return to
                    editing your campaign at any time on the dashboard page.
                </p>
                <img className="image" src="/images/docs/campaign-doc.png" alt="campaign edit" />
            </div>
        </PageContainer>
    )
}

export default CreateCampaign
