import React from 'react'

const Settings = () => {
    return (
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.0104 15.1706C13.4719 15.1706 14.6567 13.9858 14.6567 12.5243C14.6567 11.0627 13.4719 9.87793 12.0104 9.87793C10.5488 9.87793 9.36401 11.0627 9.36401 12.5243C9.36401 13.9858 10.5488 15.1706 12.0104 15.1706Z"
                stroke="#3C4B61"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.7834 8.68396L19.7228 6.85109C19.28 6.08911 18.3121 5.83168 17.5501 6.27445L17.025 6.57307C15.9644 7.18059 14.6463 6.41861 14.6463 5.19327V4.58574C14.6463 3.71049 13.9359 3 13.0606 3H10.9394C10.0642 3 9.35367 3.71049 9.35367 4.58574V5.19327C9.35367 6.41861 8.03562 7.18059 6.97503 6.57307L6.4499 6.27445C5.68792 5.83168 4.71999 6.09941 4.27722 6.85109L3.21663 8.68396C2.77385 9.44594 3.04158 10.4139 3.79326 10.8566L4.31842 11.1552C5.37901 11.7628 5.37901 13.297 4.31842 13.9046L3.79326 14.2032C3.03128 14.6459 2.77385 15.6139 3.21663 16.3758L4.27722 18.2087C4.71999 18.9707 5.68792 19.2281 6.4499 18.7853L6.97503 18.4867C8.03562 17.8792 9.35367 18.6412 9.35367 19.8665V20.4741C9.35367 21.3493 10.0642 22.0598 10.9394 22.0598H13.0606C13.9359 22.0598 14.6463 21.3493 14.6463 20.4741V19.8665C14.6463 18.6412 15.9644 17.8792 17.025 18.4867L17.5501 18.7853C18.3121 19.2281 19.28 18.9604 19.7228 18.2087L20.7834 16.3758C21.2261 15.6139 20.9584 14.6459 20.2067 14.2032L19.6816 13.9046C18.621 13.297 18.621 11.7628 19.6816 11.1552L20.2067 10.8566C20.9687 10.4139 21.2261 9.43564 20.7834 8.68396Z"
                stroke="#3C4B61"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default Settings
