import { FC, ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { motion } from 'framer-motion'
import { useRecoilValueLoadable } from 'recoil'
import SearchIcon from '@mui/icons-material/Search'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { DotLoader } from 'react-spinners'
import { InputBox } from '../../common/InputBox'
import { fontsState } from '../../../store/branding/fonts'
import { IFont } from '../../../modules/branding/IFont'
import { IBranding } from '../../../modules/IBranding'
import BrandingService from '../../../../services/BrandingService'
import { Toast } from '../../../helpers/Toast'
import { useAuth, useWidget } from 'widgets-base'

interface Props {
    label: string;
    defaultFont: string;
}

const Fonts: FC<Props> = ({ label, defaultFont }) => {

    const { currentUser } = useAuth();
    const { widget, updateWidget } = useWidget();
    const [visibility, setVisibility] = useState(false);
    const [selectedFont, setSelectedFont] = useState(defaultFont || 'Poppins');
    const [searchFont, setSearchFont] = useState('');
    const menuRef = useRef<HTMLDivElement>();
    const fontsList = useRecoilValueLoadable(fontsState);
    const [loading, setLoading] = useState<boolean>(true);
    const [branding, setBranding] = useState<IBranding | undefined>(undefined);

    useEffect(() => {
        if (currentUser) {
            setLoading(true);
            BrandingService.getBrandSetting()
                .then(branding => {
                    setBranding(branding);
                    setLoading(false);
                })
                .catch((error) => {
                    Toast(error.response.data.message, 'error')
                    setLoading(false);
                });
        }
    }, [setBranding, currentUser])

    function onHandleChangeFont(font: IFont, url?: string) {
        if (!url) {
            updateWidget({
                appearanceSettings: {
                    ...widget.appearanceSettings,
                    font: {
                        ...widget.appearanceSettings.font,
                        family: font.family,
                    },
                },
            })
        } else {
            updateWidget({
                appearanceSettings: {
                    ...widget.appearanceSettings,
                    font: {
                        ...widget.appearanceSettings.font,
                        family: font.family,
                        url,
                    },
                },
            });
        }
    }

    useEffect(() => {
        const handler = (event: Event) => {
            if (!menuRef.current.contains(event.target as Node)) {
                setVisibility(false)
            }
        }
        document.addEventListener('mousedown', handler)
        return () => {
            document.removeEventListener('mousedown', handler)
        }
    })

    const onHandleOpenFontList = () => {
        setVisibility(true)
    }

    const onHandleSelected = useCallback(
        (font: any) => () => {
            setVisibility(false)
            setSelectedFont(font.family)
            if (font.url) {
                onHandleChangeFont(font, font.url)
            } else {
                onHandleChangeFont(font)
            }
        },
        [onHandleChangeFont]
    )

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchFont(event.target.value)
    }

    const renderFonts = useMemo(() => {
        switch (fontsList.state) {
            case 'hasValue':
                let dataFonts = fontsList.contents

                // add custom fonts
                if (
                    branding &&
                    Array.isArray(branding.font) &&
                    branding.font.length
                ) {
                    const customFonts = branding.font
                    dataFonts = [...dataFonts, ...customFonts]
                }

                let filteredFonts = []
                dataFonts.forEach((fontData: any) => {
                    if (fontData.family) {
                        // if there is a font family property
                        if (fontData.family.toLowerCase().includes(searchFont.toLowerCase())) {
                            filteredFonts.push(fontData)
                        }
                    } else {
                        // if there isn't a font family property
                        if (
                            typeof fontData === 'string' &&
                            fontData.toLowerCase().includes(searchFont.toLowerCase())
                        ) {
                            filteredFonts.push(fontData)
                        }
                    }
                })

                if (filteredFonts.length !== 0) {
                    return filteredFonts.map((font, index: number) => {
                        return (
                            <li
                                key={font.family + index}
                                className={selectedFont === font.family ? 'active-font' : ''}
                                onClick={onHandleSelected(font)}
                                style={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    fontFamily: font.family,
                                }}
                            >
                                {font.family}
                            </li>
                        )
                    })
                } else {
                    return (
                        <AutoSizer>
                            {({ height, width }) => (
                                <FixedSizeList
                                    className="Lixt"
                                    innerElementType="ul"
                                    height={height}
                                    itemData={dataFonts}
                                    itemCount={dataFonts.length}
                                    itemSize={20}
                                    width={width}
                                >
                                    {({ data, index, style }) => {
                                        const font = data[index]
                                        return (
                                            <li
                                                className={
                                                    selectedFont === font.family
                                                        ? 'active-font'
                                                        : ''
                                                }
                                                onClick={onHandleSelected(font)}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'flex-end',
                                                    fontFamily: font.family,
                                                }}
                                            >
                                                {font ? font.family : 'Loading...'}
                                            </li>
                                        )
                                    }}
                                </FixedSizeList>
                            )}
                        </AutoSizer>
                    )
                }

            case 'loading':
                return <DotLoader color="#639cf1" />
            case 'hasError':
                throw fontsList.contents
        }
    }, [fontsList, onHandleSelected, searchFont, selectedFont, branding])

    return (
        <Container ref={menuRef} className="font__list">
            <FormLabel>{label}</FormLabel>
            <Select onClick={onHandleOpenFontList}>
                <SelectedFont fontFamily={selectedFont}>
                    <span title={selectedFont === '' ? 'Select font' : selectedFont}>
                        {selectedFont === ''
                            ? 'Select font'
                            : selectedFont.length <= 20
                            ? selectedFont
                            : `${selectedFont?.slice(0, 20)}...`}
                    </span>
                    <ArrowDropDownIcon className={visibility ? 'rotate' : ''} />
                </SelectedFont>
            </Select>

            {visibility && (
                <FontsList
                    initial={{ opacity: 0, y: 50, scale: 0.3 }}
                    animate={{ opacity: 1, y: 0, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
                >
                    <SearchBlock>
                        <InputBox
                            name="searchValue"
                            type="text"
                            width="100%"
                            placeholder="Search font"
                            onChange={handleSearch}
                            value={searchFont}
                        />
                        <SearchIcon />
                    </SearchBlock>
                    <ul>{renderFonts}</ul>
                </FontsList>
            )}
        </Container>
    )
}


export default Fonts

const Container = styled.div`
    position: relative;
    width: 200px;
`

const Select = styled.div`
    width: 100%;
    height: 32px;
    display: inline-block;
    padding: 0 16px;
    border-radius: 10px;
    background: #fff;
    border: 1px solid #dfe2f2;
    position: relative;
    cursor: pointer;
`
const SelectedFont = styled.div<{
    fontFamily: string
}>`
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
        font-weight: 400;
        font-size: 14px;
        color: #3c4b61;
        font-family: ${(props) => props.fontFamily};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    svg {
        transition: transform 0.2s ease-in-out;
        &.rotate {
            transform: rotate(180deg);
        }
    }
`
const fadeIn = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`
const FontsList = styled(motion.div)`
    animation: 0.3s ${fadeIn} ease-out;
    position: absolute;
    z-index: 999;
    background: #fff;
    left: 0px;
    top: 55px;
    width: 100%;
    max-height: 249px;
    overflow-y: auto;
    padding: 8px;
    border-radius: 10px;
    border: 1px solid #dfe2f2;

    &::-webkit-scrollbar {
        width: 5px;
        display: none;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 50%;
    }

    &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
    }

    &:hover {
        &::-webkit-scrollbar {
            display: block;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        li {
            height: 32px;
            font-size: 14px;
            list-style: none;
            line-height: 29px;
            padding: 0 10px;
            cursor: pointer;
            border-radius: 6px;
            color: #3c4b61;
            transition: 0.3s linear;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:hover {
                color: #000;
                background: rgba(100, 120, 249, 0.15);
            }
            &.active-option {
                color: #000;
                background: rgba(10, 39, 206, 0.082);
            }
        }
    }
`
const FormLabel = styled.p`
    margin-bottom: 6px;
    font-size: 12px;
    color: #3c4b61;
    font-weight: bold;
`
const SearchBlock = styled.div`
    display: flex;
    width: inherit;
    position: sticky;

    & > div {
        margin-bottom: 0;
    }

    .MuiSvgIcon-root {
        position: absolute;
        right: 10px;
        top: 7px;
        opacity: 0.5;
    }
`
