import styled, { keyframes } from 'styled-components'

const Banner = () => {
    return (
        <BannerLayout>
            <span className="title">
                Build <span>custom</span> digital experiences in minutes
            </span>
            <div className="image-container">
                <img src="/images/login_splash_bg.svg" alt="splash_1" width={800} height={800} />
            </div>
            <div className="image-container">
                <img src="/images/login_splash_1.png" alt="splash_1" />
            </div>
        </BannerLayout>
    )
}

export default Banner

const changeBg = keyframes`
    0% {
      background: #6478F9;
    }
    25% {
      background: #60d394;
    }
    50% {
      background: #aaf683;
    }
    75% {
      background: #ffd97d;
    }
    100% {
      background: #ff9b85;
    }
`

const BannerLayout = styled.section`
    width: 100%;
    height: 100%;
    padding: 2rem;
    color: #ffffff;
    position: relative;
    overflow: hidden;
    animation: ${changeBg} 5s infinite alternate linear;

    img {
        width: 100%;
    }

    .title {
        font-size: 20px;
        font-weight: 700;
        span {
            color: ${(props) => props.theme.colors.orange};
        }
    }

    .banner-header-text {
        font-size: 28px;
    }

    .banner-sub-text {
        font-size: 18px;
    }

    .image-container {
        position: absolute;
        top: 16%;
        left: 10%;
        width: 100%;
        height: 90%;
    }

    @media (max-width: 600px) {
        height: 510px;
        .image-container {
            position: absolute;
            top: 130px;
            left: -20px;
            width: 110%;
            height: 90%;
        }
    }
`
