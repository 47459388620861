export type ScreenType = "small" | "medium" | "large";
export type OrientationType = "portrait" | "landscape";

export interface IRenderContext {
    isStaticExport?: boolean;
    screen?: ScreenType;
    orientation?: OrientationType;
};

const small = window.matchMedia("(min-width: 640px)");
const medium = window.matchMedia("(min-width: 768px)");
const landscape = window.matchMedia("(orientation:landscape)");

let screen: ScreenType = "small";
if (small.matches) {
    screen = "medium";

    if (medium.matches) {
        screen = "large";
    }
}

let orientation: OrientationType = "portrait";
if (landscape.matches) { 
    orientation = "landscape";
}

export function getRenderContext(): IRenderContext {
    return {
        screen,
        orientation,
    };
}
