import { createContext, useContext } from "react";
import { IWidget } from "../model/widget";

export interface IRenderWidgetHook {
    
    //
    // Renders a widget statically for deployment.
    //
    renderStatic(widget: IWidget): JSX.Element;

    //
    // Renders a widget for preview.
    //
    renderPreview(widget: IWidget): JSX.Element;

    //
    // Renders a widget in the editor.
    //
    renderEditor(widget: IWidget, parentWidgetPath: number[], widgetIndex: number): JSX.Element;

    //
    // Renders a widget as a template..
    //
    renderTemplate(widget: IWidget): JSX.Element;
}

export const RenderWidgetContext = createContext<IRenderWidgetHook>(null);

export function useRenderWidget(): IRenderWidgetHook {
    return useContext(RenderWidgetContext);
}
