import { useDraggable, useDroppable } from "@dnd-kit/core";
import { RenderEditorWidget } from "./render-editor-widget";
import { findSelectionTarget } from "../../../builder/widget/widget-types";
import { useEditorContext } from "../../../builder/context/editor-context";
import { useEffect, useRef } from "react";
import { Direction, DragContextProvider, useWidget } from "widgets-base";
import { useCampaign, IDraggingWidget, IDroppableDetails } from "../../../builder/context/campaign";

export interface IProps {

    //
    // The flow direction of the parent group.
    //
    parentDirection: Direction;
}

//
// Renders a widget that can be moved via drag and drop.
//
export function MoveableWidget({ parentDirection }: IProps) {

    const { widget, disableDrag, disableDrop, widgetPath, getAncestors, parentWidget } = useWidget();
    const { setWidgetRef } = useEditorContext();
    const { campaign, currentPageIndex, selectedWidget, setSelectedWidgetPath } = useCampaign();
    const widgetRef = useRef<HTMLElement>(undefined);

    const ancestors = getAncestors();

    const draggingDetails: IDraggingWidget = {
        type: "widget",
        widget,
        widgetPath,
    };
    
    const { attributes, listeners, setNodeRef: setDraggableRef } = useDraggable({
        id: widget.id,
        data: draggingDetails,
        disabled: disableDrag,
    });

    const droppableDetails: IDroppableDetails = {
        type: "widget",
        widget,
        parentWidget,
        ancestors,
        widgetPath,
        parentDirection,
    };

    const { setNodeRef: setDroppableRef } = useDroppable({
        id: widget.id,
        data: droppableDetails,
        disabled: disableDrop,
    });

    useEffect(() => {
        if (widgetRef.current) {
            //
            // Cache the widget ref so that the editor can extract the widget's rect for 
            // placement of the selected widget chrome.
            //
            setWidgetRef(widget.id, widgetRef);
        }
    }, []);

    function selectWidget() {
        console.log(`Clicked widget:`, widget, widgetPath);
        const selectionTargetIndex = findSelectionTarget([widget].concat(ancestors));
        if (selectionTargetIndex === undefined || selectionTargetIndex === 0) {
            //
            // Just the widget itself.
            //
            console.log(`Selecting widget:`, widget);
            setSelectedWidgetPath(widgetPath, campaign.views[currentPageIndex].widgets);
        }
        else {
            //
            // An ancestor of the clicked widget.
            //
            const ancestorIndex = selectionTargetIndex - 1;
            const ancestorWidget = ancestors[ancestorIndex];
            if (selectedWidget && selectedWidget?.id === ancestorWidget.id) {
                // 
                // The ancestor is already selected. Select the originally clicked widget instead.
                //
                console.log(`Selecting widget:`, widget);
                setSelectedWidgetPath(widgetPath, campaign.views[currentPageIndex].widgets);
            }
            else {
                const ancestorWidgetPath = widgetPath.slice(0, widgetPath.length - 1 - ancestorIndex);
                console.log(`Selected ancestor:`, ancestors[ancestorIndex], ancestorWidgetPath);
                setSelectedWidgetPath(ancestorWidgetPath, campaign.views[currentPageIndex].widgets);
            }
        }
    };

    return (
        <DragContextProvider
            dragProps={{
                ...attributes, 
                ...listeners
            }}
            setRef={el => {
                setDraggableRef(el);
                setDroppableRef(el);
                widgetRef.current = el;
            }}
            onSelect={() => {
                selectWidget();
            }}
            >
            <RenderEditorWidget />
        </DragContextProvider>
    );
}
