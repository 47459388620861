
//
// Icon to render in the insertion marker.
//

import { Direction } from "widgets-base";
import { Rect } from "../../../builder/lib/dom";

interface IIconProps {
    color: string;
}

function CirclePlusIcon({ color }: IIconProps) {
    return (
        <svg height="24" width="24">
            <circle
                cx="12"
                cy="12"
                r="10"
                style={{ fill: color }}
            />
            <line
                x1="12"
                y1="7"
                x2="12"
                y2="17"
                style={{ stroke: "white", strokeWidth: 2 }}
            />
            <line
                x1="7"
                y1="12"
                x2="17"
                y2="12"
                style={{ stroke: "white", strokeWidth: 2 }}
            />
        </svg>
    );
}

function CrossIcon({ color }: IIconProps) {
  return (
    <svg height="24" width="24">
      <circle 
        cx="12" 
        cy="12" 
        r="10" 
        style={{fill: color}} 
      />
      <line 
        x1="7" 
        y1="7" 
        x2="17" 
        y2="17" 
        style={{stroke: "white", strokeWidth: 2}} 
      />
      <line 
        x1="17" 
        y1="7" 
        x2="7" 
        y2="17" 
        style={{stroke: "white", strokeWidth: 2}} 
      />
    </svg>
  );
}

export type Position = "start" | "end";

export interface IInsertionMarkerProps {
    parentDirection: Direction;
    position: Position;
    allowed: boolean;
    msg?: string;
    widgetRect: Rect;
}

//
// Renders an insertion marker at the point where an element would be dropped.
//
export function InsertionMarker({ parentDirection, position, allowed, msg, widgetRect }: IInsertionMarkerProps) {

    let insertionMarkerStyle: any = {
        backgroundColor: allowed ? "blue" : "red",
        position: "absolute",
        zIndex: 1000,
    };

    if (parentDirection === "row") {
        insertionMarkerStyle.width = "2px";
        insertionMarkerStyle.top = `${widgetRect.top - 20}px`;
        insertionMarkerStyle.height = `${widgetRect.height + 40}px`;

        if (position === "start") {
            insertionMarkerStyle.left = `${widgetRect.left}px`;
        }
        else {
            insertionMarkerStyle.left = `${widgetRect.left + widgetRect.width}px`;
        }
    }
    else {
        insertionMarkerStyle.height = "2px";
        insertionMarkerStyle.left = `${widgetRect.left - 20}px`;
        insertionMarkerStyle.width = `${widgetRect.width + 40}px`;

        if (position === "start") {
            insertionMarkerStyle.top = `${widgetRect.top}px`;
        }
        else {
            insertionMarkerStyle.top = `${widgetRect.top + widgetRect.height}px`;
        }
    }
    
    return (
        <div
            className="insertion-marker relative"
            style={insertionMarkerStyle}
            >
            <div
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    borderRadius: "50%",
                    zIndex: 5000,
                }}
                >
                {allowed 
                    && <CirclePlusIcon color="blue" />
                    || <CrossIcon color="red" />
                }
            </div>
            {msg &&
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(20px, -50%)",
                        zIndex: 5000,
                    }}
                    >
                    <div 
                        className="text-white text-xs p-1"
                        style={{
                            backgroundColor: allowed ? "blue" : "red",
                        }}
                        >
                        {msg}
                    </div>
                </div>
            }
        </div>
    );
}