import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { generateRandomName } from '../../v1/models/Campaign';
import _ from "lodash";
import { useNavigate } from 'react-router-dom';
import { RouteNames } from '../../router';
import CampaignsService from '../../services/CampaignsService';
import http from '../../lib/http';
import { InitialCopy } from './steps/initial-copy';
import { generateThemeFromColor, generateFontTheme, mergeThemes, allColorThemes, defaultFontSelection } from './themes';
import { ConfirmContent } from './steps/confirm-content';
import { ChooseColors } from './steps/choose-colors';
import { ChooseFonts } from './steps/choose-fonts';
import { ChooseYourExperience } from './steps/choose-your-experience';
import { ChooseAssets } from './steps/choose-assets';
import { FinalPreview } from './steps/final-preview';
import { serializeProject } from '../../lib/deploy';
import { Toast } from '../../v1/helpers/Toast';
import { defaultPageTheme } from '../../builder/context/page-theme';
import { extractRandomElement, removeElement } from '../../builder/lib/utils';
import { IImageDetails, loadImageDetails } from '../../lib/image';
import { ICampaign, IPageTheme, IWidget, UserRoles, useAuth } from 'widgets-base';
import { BottomSection } from './steps/components/bottom-section';
import { BackdropLoader } from '../../v1/components/common/BackdropLoader';
import { ImageSearch } from './steps/image-search';

//
// A request to prompt the AI for copy.
//
export interface IPromptRequest {
    //
    // The id for the prompt.
    // Maps the prompt to a widget.
    //
    id: string;

    //
    // The text for the prompt.
    //
    text: string;
};

//
// A request to generate copy for a web page.
//
export interface IMakeCopyRequest {

    //
    // The seed text to generate copy from.
    //
    seeds?: string[];

    //
    // The prompts to generate copy from.
    //
    prompts: IPromptRequest[];
}

//
// Defines an image.
//
interface IImageSpec {
    url: string;
    details: IImageDetails;
    type: string;
}

//
// Walk the hierarchy of elements and create list of content to be generated.
//
function* makeCopyPrompts(widgets: IWidget[]): Generator<IPromptRequest> {
    for (const widget of widgets) {
        if (widget.properties?.prompt) {
            yield {
                id: widget.id,
                text: widget.properties.prompt,
            };
        }

        if (widget.children) {
            yield* makeCopyPrompts(widget.children);
        }
    }
}

//
// Removes quotes from a string.
//
function removeQuotes(str: string | undefined): string | undefined {
    if (str === undefined) {
        return undefined;
    }

    return str.trim().replace(/^(["'`])|(["'`])$/g, '');
}

//
// Apply copy to all widgets.
//
function applyCopy(widgets: IWidget[], copy: { [index: string]: string; }) {
    for (const widget of widgets) {
        const text = removeQuotes(copy[widget.id]);
        const tag = widget.properties.tag || "p";
        if (text) {
            if (!widget.properties) {
                widget.properties = {};
            }
            widget.properties.content = `<${tag}>${text}</${tag}>`;
        }

        if (widget.children) {
            applyCopy(widget.children, copy);
        }
    }
}

interface IStep {
    label: string;
    description: string;
    subLabel?: string;
    element: string;
}

export function AIGeneratorPage2() {

    const [stepIndex, setStepIndex] = useState(undefined);
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const [answers, setAnswers] = useState({});
    const [imageSearch, setImageSearch] = useState("");
    const [campaign, setCampaign] = useState<ICampaign>(undefined);
    const [generating, setGenerating] = useState(false);
    const [colorThemes, setColorThemes] = useState<IPageTheme[]>([]);
    const [mergedColorThemes, setMergedColorThemes] = useState<IPageTheme[]>([]);
    const [selectedColorThemeIndex, setSelectedColorThemeIndex] = useState(0);
    const [fontThemes, setFontThemes] = useState<IPageTheme[]>([]);
    const [mergedFontThemes, setMergedFontThemes] = useState<IPageTheme[]>([]);
    const [selectedFontThemeIndex, setSelectedFontThemeIndex] = useState(0);
    const [mergedPageTheme, setMergedPageTheme] = useState<IPageTheme>(defaultPageTheme);
    const [aiTemplates, setAiTemplates] = useState<ICampaign[]>(undefined);
    const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(0);
    const [steps, setSteps] = useState(undefined);

    useEffect(() => {
        http.get('/templates')
            .then(({ data: templates }) => {
                setAiTemplates(templates);
            })
            .catch(error => {
                console.error(`Failed to load AI templates:`);
                console.error(error);
            });
    }, []);

    useEffect(() => {
        if (aiTemplates === undefined) {
            return; // Templates not loaded yet.
        }
       
        onGenerateColors();
        onGenerateFonts();
        onGenerateCampaign(true)
            .catch(error => {
                console.error(`AI generator setup failed:`);
                console.error(error);
            });

        const steps: IStep[] = [];
        const aiTemplate = aiTemplates[selectedTemplateIndex];
        for (let answerIndex = 0; answerIndex < aiTemplate.seeds.length; ++answerIndex) {        
            steps.push(makeQuestionStep(answerIndex));
        }
        steps.push({
            label: 'Image search',
            description: `Set the search text for images.`,
            element: "image-search",
        });
        steps.push({
            label: 'Confirm content',
            description: `Check the content and basic layout of your campaign (you can fine tune it later).`,
            element: "confirm-content",
        });
        steps.push({
            label: 'Colors',
            description: `Choose the color scheme for your campaign (you can edit this later).`,
            element: "choose-colors",
        });
        steps.push({
            label: 'Fonts',
            description: `Choose fonts for your campaign (you can edit this later).`,
            element: "choose-fonts",
        });
        steps.push({
            label: 'Assets',
            description: `Drag and drop assets into your campaign (you can edit this later).`,
            element: "choose-assets",
        });
        steps.push({
            label: 'Fine tune and deploy',
            subLabel: 'Get your campaign out there!',
            description: `You can now choose to fine tune or deploy your campaign (you can also deploy it, the fine tune and redeploy again later).`,
            element: "final-preview",
        });

        setSteps(steps);

    }, [aiTemplates, selectedTemplateIndex]);

    useEffect(() => {
        const selectedFontTheme = fontThemes[selectedFontThemeIndex];
        setMergedColorThemes(colorThemes.map(colorTheme => mergeThemes(colorTheme, selectedFontTheme)));

        const selectedColorTheme = colorThemes[selectedColorThemeIndex];
        setMergedFontThemes(fontThemes.map(fontTheme => mergeThemes(selectedColorTheme, fontTheme)));

        const mergedPageTheme = mergeThemes(selectedColorTheme, selectedFontTheme);
        setMergedPageTheme(mergedPageTheme);

    }, [colorThemes, selectedColorThemeIndex, fontThemes, selectedFontThemeIndex]);

    function makeQuestionStep(answerIndex: number): IStep {
        return {
            label: 'Question',
            description: `Answer questions and use AI to generate your personalized campaign.`,
            element: `question-${answerIndex}`,
        };
    }

    //
    // Wait for ms milliseconds.
    //
    function sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    //
    // Sets an answer to a question asked by the AI templates.
    //
    function setAnswer(answerIndex: number, answer: string): void {
        setAnswers({
            ...answers,
            [answerIndex]: answer,
        });
    }

    //
    // Filter images to those matching.
    //
    function filterImages(images: IImageSpec[], allowed: string) {
        return images.filter(image => image.type === allowed);
    }

    //
    // Retrieve an randomize allowed image.
    //
    function extractRandomizedAllowedImage(images: IImageSpec[], allowed?: string): IImageSpec {
        if (!allowed || allowed === "any") {
            // Just return any random image.
            return extractRandomElement(images);
        }
        else {
            console.log("Expecting image type:", allowed);
            const image = extractRandomElement(filterImages(images, allowed));
            console.log("Got image:", image);
            removeElement(images, image);            
            return image;
        }
    }

    //
    // Add images to the widget.
    //
    function addImages(widget: IWidget, images: IImageSpec[]) {
        if (widget.xtype === "image") {
            const nextImage = extractRandomizedAllowedImage(images, widget.properties.allowed);
            widget.properties.image = {
                url: nextImage.url,
            };
        }

        if (widget.children) {
            for (const child of widget.children) {
                addImages(child, images);
            }
        }
    }

    //
    // Add sections bit by bit.
    //
    async function addSections(widgets: IWidget[], images?: IImageSpec[]) {

        for (const widget of widgets) {

            if (images) {
                addImages(widget, images);
            }

            await sleep(500);

            console.log(`Adding widget ${widget.id}`);

            setCampaign(campaign => ({
                ...campaign,
                views: [
                    {
                        ...campaign.views[0],
                        widgets: [
                            ...campaign.views[0].widgets,
                            widget,
                        ],
                    },
                ],
                lastUpdate: Date.now(),
            }));
        }

        console.log(`Finished animation`);
    }

    //
    // Search for relevant assets.
    //
    async function searchImages(query, page = 1, perPage = 10): Promise<IImageSpec[]> {
        
        console.log(`Searching for images for query "${query}"`);

        const { data } = await http.post('/image-search', { query, page, perPage });
        const urls = data.images.map(result => result.urls.regular);
        const details = await Promise.all(data.images.map(image => loadImageDetails(image.urls.thumb)));

        return _.zip(urls, details).map(([url, details]) => {
            let type;
            if (details.aspectRatio > 1) { 
                type = "portrait";
            }
            else if (details.aspectRatio < 1) {
                type = "landscape";
            }
            else {
                type = "square";
            }

            return {
                url,
                details,
                type,
            };
        });
    };

    //
    // Creates a new campaign.
    //
    async function onGenerateCampaign(immediate: boolean) {

        setGenerating(true);

        try {
            const widgets = aiTemplates[selectedTemplateIndex].views[0].widgets;

            const campaignId = nanoid();
            const campaign: ICampaign = {
                _id: campaignId,
                uid: currentUser!.uid!,
                fontFamilies: [],
                campaignName: generateRandomName(),
                campaignDescription: "",
                createdAt: new Date().toISOString(),
                lastModified: new Date().toISOString(),
                scans: 0,
                version: 4,
                views: [
                    {
                        id: nanoid(),
                        name: "Page 1",
                        widgets: [],
                    },
                ],
            };

            if (immediate) {
                //
                // Add all sections immediately with default copy.
                //
                setCampaign({
                    ...campaign,
                    views: [
                        {
                            ...campaign.views[0],
                            widgets: widgets,
                        },
                    ],
                    lastUpdate: Date.now(),
                });
            }
            else {
                //
                // Start rendering while copy is being generated.
                //
                setCampaign(campaign);

                const makeCopyRequest: IMakeCopyRequest = {
                    seeds: aiTemplates[selectedTemplateIndex].seeds.map((seed, index) => `${seed.prompt} ${answers[index]}`),
                    prompts: Array.from(makeCopyPrompts(widgets)),
                };

                
                //
                // Get a set of images.
                //
                const imageQuery = imageSearch || answers[0];
                const images = imageQuery && await searchImages(imageQuery, 1, 30) || undefined;

                //
                // Set background image.
                //
                // const backgroundImageUrl = extractRandomElement(imageUrls);
                // campaign.views[0].backgroundImageUrl = backgroundImageUrl;

                await Promise.all([
                    addSections(widgets, images),
                    (async () => {
                        console.log(`Copy request:`);
                        console.log(makeCopyRequest);

                        const { data: copy } = await http.post('/make-copy', makeCopyRequest);
                        console.log(`Got copy:`);
                        console.log(copy);

                        applyCopy(widgets, copy);
                        console.log(`Finished copy`);
                    })(),
                ]);

                //
                // Ensure all sections have been added.
                //
                setCampaign(campaign => ({
                    ...campaign,
                    views: [
                        {
                            ...campaign.views[0],
                            widgets: widgets,
                        },
                    ],
                    lastUpdate: Date.now(),
                }));
            }
        }
        finally {
            setGenerating(false);
        }
    }


    //
    // Generates color schemes.
    //
    function onGenerateColors() {
        const availableColorThemes = [...allColorThemes];
        const theme1 = extractRandomElement(availableColorThemes);
        const theme2 = extractRandomElement(availableColorThemes);
        const theme3 = extractRandomElement(availableColorThemes);
        const themes = [theme1, theme2, theme3];
        setColorThemes(themes);
        setSelectedColorThemeIndex(1);
    }

    //
    // Generates a color scheme from a particular color.
    //
    function onGenerateFromColor(color: string) {
        const theme = generateThemeFromColor(color);
        setColorThemes([theme]);
        setSelectedColorThemeIndex(0);
    }

    //
    // Generates a selection of fonts.
    //
    function onGenerateFonts() {
        const availableFontSelection = _.cloneDeep(defaultFontSelection);
        const theme1 = generateFontTheme(availableFontSelection);
        const theme2 = generateFontTheme(availableFontSelection);
        const theme3 = generateFontTheme(availableFontSelection);
        const themes = [theme1, theme2, theme3];
        setFontThemes(themes);
        setSelectedFontThemeIndex(1);
    }

    //
    // Adds new images to a widget, if necessary.
    //
    async function addNewImages(widget: IWidget, updateWidget: (updatedWidget: Partial<IWidget>) => void, images: IImageSpec[]): Promise<void> {
        if (widget.xtype === "image") {
            const nextImage = extractRandomizedAllowedImage(images, widget.properties.allowed);
            updateWidget({
                properties: {
                    image: {
                        url: nextImage.url,
                    },
                },
            });

            //
            // Wait a bit. Don't want it seem like it's instant!
            //
            await sleep(200);
        }

        if (widget.children) {

            const widgets = widget.children.slice();

            for (let childIndex = 0; childIndex < widgets.length; childIndex += 1) {

                const child = widget.children[childIndex];

                function updateChildWidget(updatedChildWidget: Partial<IWidget>) {
                    widgets[childIndex] = _.merge({}, child, updatedChildWidget);

                    updateWidget({
                        children: widgets,
                    });
                }

                await addNewImages(child, updateChildWidget, images);
            }
        }
    }

    // 
    // Regenerate assets for the campaign.
    //
    async function onGenerateAssets() {

        setGenerating(true);

        try {
            //
            // Get new assets.
            //
            const imageQuery = imageSearch || answers[0];
            const images = await searchImages(imageQuery, 1, 30);
            let campaignCopy = _.cloneDeep(campaign);

            //
            // Set background image.
            //
            // const backgroundImageUrl = extractRandomElement(imageUrls);
            // campaign.views[0].backgroundImageUrl = backgroundImageUrl;

            const numWidgets = campaignCopy.views[0].widgets.length;

            for (let widgetIndex = 0; widgetIndex < numWidgets; widgetIndex += 1) {

                const widget = campaignCopy.views[0].widgets[widgetIndex];

                function updateWidget(updatedWidget: Partial<IWidget>) {
                    campaignCopy = {
                        ...campaignCopy,
                        views: [
                            {
                                ...campaignCopy.views[0],
                                widgets: [
                                    ...campaignCopy.views[0].widgets.slice(0, widgetIndex),
                                    _.merge({}, widget, updatedWidget),
                                    ...campaignCopy.views[0].widgets.slice(widgetIndex + 1),
                                ],
                            },
                        ],
                        lastUpdate: Date.now(),
                    };

                    setCampaign(campaignCopy);
                }

                await addNewImages(widget, updateWidget, images);
            }
        }
        finally {
            setGenerating(false);
        }
    }
    
    //
    // Open the campaign in the builder.
    //
    async function onEditCampaign() {
        await CampaignsService.saveCampaign({
            ...campaign,
            pageTheme: mergedPageTheme,
        });

        navigate(`${RouteNames.BUILDER}/${campaign._id}`);
    }

    const liveBaseUrl = `https://live.deploy.link`;

    //
    // Make the canonical URL for the page.
    // This is suitable for use in a QR code.
    //
    function makeCanonicalDeployUrl(): string {
        return `${liveBaseUrl}/live/${campaign._id}`
    }

    //
    // Encodes the campaign name for use in a URL.
    //
    function encodeCampaignNameForURL(campaignName: string): string {
        return campaignName.replace(/\s+/g, '-');
    }

    //
    // Make the pretty "display URL" for the page.
    //
    function makeDisplayDeployUrl(): string {
        if (currentUser.role.toLowerCase() === UserRoles.FREE) {
            return makeCanonicalDeployUrl();
        } 
        else {
            //
            // Premium users get a pretty URL.
            //
            return `${liveBaseUrl}/${currentUser.username}/${encodeCampaignNameForURL(campaign.campaignName)}`;
        }
    }

    //
    // Deploy the campaign.
    //
    async function onDeployCampaign() { //todo: This code and the preceeding functions is shared and should be factored out.

        setGenerating(true);

        try {
            const serializedProject = serializeProject(campaign);

            if (currentUser.role.toLowerCase() !== UserRoles.FREE) {
                //
                // Premium users get a pretty URL.
                //
                serializedProject.prettyURL = {
                    accountName: currentUser.username,
                    campaignName: encodeCampaignNameForURL(campaign.campaignName),
                };
            }
        
            setCampaign({ 
                ...campaign,
                deployUrl: makeDisplayDeployUrl(),
                canonicalUrl: makeCanonicalDeployUrl(),
                status: 'published',
                deployDate: new Date().toISOString(),
            });

            await CampaignsService.saveCampaign({
                ...campaign,
                pageTheme: mergedPageTheme,
            });
            await CampaignsService.deployStatic(campaign._id, serializedProject, campaign);

            Toast(`Deployed campaign at ${makeDisplayDeployUrl()}`, `success`);
        }
        finally {
            setGenerating(false);
        }
    }

    function onNext() {
        if (steps === undefined) {
            return;
        }

        if (stepIndex === undefined) {
            setStepIndex(0);
            return;
        }

        if (stepIndex + 1 > steps.length - 1) {
            onEditCampaign()
                .catch(error => {
                    console.error(`Failed to edit campaign:`);
                    console.error(error && error.stack || error.message || error);
                });
        }
        else {
            switch (steps[stepIndex].element) {
                case "image-search": {
                    onGenerateCampaign(false)
                        .catch(error => {
                            console.error(`Failed to generate campaign:`);
                            console.error(error && error.stack || error.message || error);
                        });
                    break;
                }
            }

            setStepIndex(Math.min(steps.length - 1, stepIndex + 1));
        }
    }

    function onBack() {
        if (stepIndex > 0) {
            setStepIndex(stepIndex - 1);
        }
        else {
            setSteps(undefined); // Start again.
            setStepIndex(undefined);
        }
    }

    function onGenerate() {
        if (steps === undefined) {
            return;
        }

        if (stepIndex === undefined) {
            return;
        }

        switch (steps[stepIndex].element) {
            case "confirm-content": {
                onGenerateCampaign(false)
                    .catch(error => {
                        console.error(`Failed to generate campaign:`);
                        console.error(error && error.stack || error.message || error);
                    });
                break;
            }

            case "choose-colors": {
                onGenerateColors();
                break;
            }

            case "choose-fonts": {
                onGenerateFonts();
                break;
            }

            case "choose-assets": {
                onGenerateAssets();
                break;
            }
        }
    }

    function renderStep(step: IStep) {
        switch (step.element) {
            case "question-0": {
                return (
                    <InitialCopy
                        showGabby={true}
                        seed={aiTemplates[selectedTemplateIndex].seeds[0]}
                        answers={answers}
                        setAnswer={setAnswer}
                        answerIndex={0}
                        />
                );
            }

            case "question-1": {
                return (
                    <InitialCopy
                        showGabby={false}
                        seed={aiTemplates[selectedTemplateIndex].seeds[1]}
                        answers={answers}
                        setAnswer={setAnswer}
                        answerIndex={1}
                        />
                );
            }

            case "question-2": {
                return (
                    <InitialCopy
                        showGabby={false}
                        seed={aiTemplates[selectedTemplateIndex].seeds[2]}
                        answers={answers}
                        setAnswer={setAnswer}
                        answerIndex={2}
                        />
                );
            }

            case "question-3": {
                return (
                    <InitialCopy
                        showGabby={false}
                        seed={aiTemplates[selectedTemplateIndex].seeds[3]}
                        answers={answers}
                        setAnswer={setAnswer}
                        answerIndex={3}
                        />
                );
            }

            case "image-search": {
                return (
                    <ImageSearch
                        imageSearch={imageSearch}
                        setImageSearch={setImageSearch}
                        />
                );
            }

            case "confirm-content": {
                return (
                    <ConfirmContent
                        campaign={campaign}
                        generating={generating}
                        pageTheme={mergedPageTheme}
                        />
                );
            }

            case "choose-colors": {
                return (
                    <ChooseColors
                        campaign={campaign}
                        onGenerateColors={onGenerateColors}
                        onGenerateFromColor={onGenerateFromColor}
                        themes={mergedColorThemes}
                        selectedThemeIndex={selectedColorThemeIndex}
                        setSelectedThemeIndex={setSelectedColorThemeIndex}
                        generating={generating}
                        />
                );
            }

            case "choose-fonts": {
                return (
                    <ChooseFonts
                        campaign={campaign}
                        themes={mergedFontThemes}
                        selectedThemeIndex={selectedFontThemeIndex}
                        setSelectedThemeIndex={setSelectedFontThemeIndex}
                        generating={generating}
                        />
                );
            }

            case "choose-assets": {
                return (
                    <ChooseAssets
                        campaign={campaign}
                        setCampaign={setCampaign}
                        generating={generating}
                        pageTheme={mergedPageTheme}
                        />
                );
            }

            case "final-preview": {
                return (
                    <FinalPreview
                        campaign={campaign}
                        generating={generating}
                        pageTheme={mergedPageTheme}
                        />
                );
            }
        }

        throw new Error(`Unknown step type: ${step.element}`);
    }

    return (
        <div
            className="flex flex-col items-center w-screen h-screen pb-4"
            style={{
                backgroundColor: "#FFFCFC",
            }}
            >
            <div className="flex flex-col flex-grow w-full">
                <div className="flex flex-col w-full items-end">
                    <div 
                        className="flex flex-col w-full items-center mt-1 mr-1"
                        style={{ width: "400px" }}
                        >
                        <header className="border border-gray-200 bg-slate-50 flex flex-col justify-center items-center pl-16 pr-12 py-2.5 rounded-full border-solid">
                            <img
                                loading="lazy"
                                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/2c9d1dded083e767f7f3141b72f5b795a5638760e19bb8adab99c172d75a5f0f?apiKey=610a4bec134943f393dd6ffcb27214fb&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/2c9d1dded083e767f7f3141b72f5b795a5638760e19bb8adab99c172d75a5f0f?apiKey=610a4bec134943f393dd6ffcb27214fb&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/2c9d1dded083e767f7f3141b72f5b795a5638760e19bb8adab99c172d75a5f0f?apiKey=610a4bec134943f393dd6ffcb27214fb&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/2c9d1dded083e767f7f3141b72f5b795a5638760e19bb8adab99c172d75a5f0f?apiKey=610a4bec134943f393dd6ffcb27214fb&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/2c9d1dded083e767f7f3141b72f5b795a5638760e19bb8adab99c172d75a5f0f?apiKey=610a4bec134943f393dd6ffcb27214fb&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/2c9d1dded083e767f7f3141b72f5b795a5638760e19bb8adab99c172d75a5f0f?apiKey=610a4bec134943f393dd6ffcb27214fb&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/2c9d1dded083e767f7f3141b72f5b795a5638760e19bb8adab99c172d75a5f0f?apiKey=610a4bec134943f393dd6ffcb27214fb&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/2c9d1dded083e767f7f3141b72f5b795a5638760e19bb8adab99c172d75a5f0f?apiKey=610a4bec134943f393dd6ffcb27214fb&" className="aspect-[5.8] object-contain object-center w-[290px] overflow-hidden"
                                alt="Header Image"
                            />
                        </header>
                        <div className="justify-center text-orange-400 text-center text-base font-bold leading-7">
                            Your Deployable Team
                        </div>                
                    </div>
                </div>

                {aiTemplates === undefined
                    ? <BackdropLoader />
                    : (steps === undefined || stepIndex === undefined)
                        ? <ChooseYourExperience 
                            templates={aiTemplates}
                            onNext={onNext}
                            selectedTemplateIndex={selectedTemplateIndex}
                            setSelectedTemplateIndex={setSelectedTemplateIndex}
                            />
                        : <React.Fragment key={stepIndex}>
                            {renderStep(steps[stepIndex])}
                        </React.Fragment>
                }

                {/* {(campaign && mergedPageTheme) && 
                    <ChooseAssets
                        campaign={campaign}
                        setCampaign={setCampaign}
                        generating={generating}
                        pageTheme={mergedPageTheme}
                        />
                } */}

                {/* <ChooseColors
                    campaign={campaign}
                    onGenerateColors={onGenerateColors}
                    onGenerateFromColor={onGenerateFromColor}
                    themes={mergedColorThemes}
                    selectedThemeIndex={selectedColorThemeIndex}
                    setSelectedThemeIndex={setSelectedColorThemeIndex}
                    generating={generating}
                    /> */}

                {/* <ChooseYourExperience 
                        templates={aiTemplates}
                        onNext={onNext}
                        selectedTemplateIndex={selectedTemplateIndex}
                        setSelectedTemplateIndex={setSelectedTemplateIndex}
                    /> */}

                {/* {aiTemplates[selectedTemplateIndex]?.seeds &&
                    <InitialCopy
                            onNext={onNext}
                            onBack={onBack}
                            seeds={aiTemplates[selectedTemplateIndex]?.seeds}
                            answers={answers}
                            setAnswer={setAnswer}
                            imageSearch={imageSearch}
                            setImageSearch={setImageSearch}
                            />
                } */}

                {/* {mergedPageTheme &&
                    <ConfirmContent
                        onNext={onNext}
                        onBack={onBack}
                        seeds={aiTemplates[selectedTemplateIndex]?.seeds}
                        campaign={campaign}
                        onGenerateCampaign={() => onGenerateCampaign(false)}
                        generating={generating}
                        answers={answers}
                        setAnswer={setAnswer}
                        imageSearch={imageSearch}
                        setImageSearch={setImageSearch}
                        pageTheme={mergedPageTheme}
                        />                
                } */}
            </div>

            <BottomSection
                onNext={onNext}
                onBack={onBack}
                onGenerate={onGenerate}
                title={steps?.[stepIndex]?.label || "Initialising AI"}
                stepIndex={stepIndex === undefined ? 0 : stepIndex+1}
                numSteps={(steps?.length + 1) || 9}
                />
        </div>
    );
}
