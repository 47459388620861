import React, { ChangeEvent, useEffect, useState } from 'react'
import { checkInputValid } from '../../helpers/validation'
import { Toast } from '../../helpers/Toast'
import { AuthContainer } from '../../components/Auth/Signin'
import { InputBox } from '../../components/common/InputBox'
import { Button } from '../../components/common/Button'
import ButtonSpinner from '../../components/common/ButtonSpiner'
import { UsernamePageContainer } from './styles'
import AuthService from '../../../services/auth'

import { useNavigate } from 'react-router-dom'
import { RouteNames } from '../../../router'
import { useAuth } from 'widgets-base'

const Username = () => {
    const [username, setUsername] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const { currentUser } = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if (currentUser) {
            if (currentUser.username) {
                // Already have a username!
                navigate(RouteNames.USERNAME)
            }
        }
    }, [currentUser])

    const handleChangeUsername = (e: ChangeEvent<HTMLInputElement>): void => {
        setUsername(e.target.value)
    }

    const checkValidUsername = () => {
        checkInputValid(username, 'username')
    }

    const updateUsername = async () => {
        try {
            setLoading(true)

            await AuthService.createUsername(username, currentUser.uid)

            setLoading(false)
            navigate(RouteNames.DASHBOARD)
        } catch (error) {
            Toast(error.response.data.message, 'error')
            setLoading(false)
        }
    }

    return (
        <AuthContainer style={{ width: '100%' }}>
            <UsernamePageContainer>
                <img
                    src="/images/deployable-logo.png"
                    alt="deployable"
                    height={23}
                    width={150}
                    className="logo"
                />
                <h1>
                    Welcome, please setup <br /> your username
                </h1>
                <InputBox
                    placeholder="Unique user name"
                    name="username"
                    type="text"
                    onChange={handleChangeUsername}
                    value={username}
                    onBlur={checkValidUsername}
                    width="358px"
                />

                <p className="username-preview text-left">deploy.link/{username}</p>

                <Button
                    style={{ width: '358px' }}
                    type="button"
                    onClick={updateUsername}
                    variant="secondary"
                    width="100%"
                >
                    {loading ? <ButtonSpinner height="25px" width="25px" /> : 'Done'}
                </Button>
            </UsernamePageContainer>
        </AuthContainer>
    )
}

export default Username
