import { RouteNames } from '.'
import {
    About,
    MainPage,
    CreateCampaign,
    DocBuilder,
    DocDrag,
    DocEditMode,
    DocAssets,
    DocFonts,
    DocSocial,
    DocTemplates,
    DocSlack,
    DocSubscribe,
    DocAccount,
    DocEmbedCampaign,
} from '../v1/pages/Docs'

export const docsRoutes = [
    { path: RouteNames.DOC, element: <MainPage /> },
    { path: RouteNames.DOC_ABOUT, element: <About /> },
    { path: RouteNames.DOC_CREATE_CAMPAIGN, element: <CreateCampaign /> },
    { path: RouteNames.DOC_BUILDER, element: <DocBuilder /> },
    { path: RouteNames.DOC_WIDGETS, element: <DocDrag /> },
    { path: RouteNames.DOC_EDIT_MODE, element: <DocEditMode /> },
    { path: RouteNames.DOC_BRAND_ASSETS, element: <DocAssets /> },
    { path: RouteNames.DOC_BRAND_FONTS, element: <DocFonts /> },
    { path: RouteNames.DOC_BRAND_SOCIAL, element: <DocSocial /> },
    { path: RouteNames.DOC_TEMPLATES, element: <DocTemplates /> },
    { path: RouteNames.DOC_BILLING, element: <DocSubscribe /> },
    { path: RouteNames.DOC_SLACK, element: <DocSlack /> },
    { path: RouteNames.DOC_ACCOUNT, element: <DocAccount /> },
    { path: RouteNames.DOC_EMBED_CAMPAIGN, element: <DocEmbedCampaign /> },
]
