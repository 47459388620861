import {Divider} from '@mui/material'
import {FC} from 'react'
import {WidgetSettingContainer} from '../../Design/WidgetSettingContainer'
import {
    AppearanseSet,
    BorderRadius,
    BorderWidth,
    Colors,
    Fonts, Height,
    Margins,
    Orientation,
    Paddings,
    Sizer,
    Width,
} from '../../EditMode/Design'
import { useWidget } from 'widgets-base'

interface Props {
}

const FormDesignSetting: FC<Props> = () => {

    const { widget } = useWidget();

    return (
        <WidgetSettingContainer padding={0}>
            <div style={{display: 'flex', gap: '10px'}}>
                <Fonts
                    label="Select font"
                    defaultFont={widget?.appearanceSettings?.font?.family}
                />
                <Sizer
                    label="Font size"
                    value={widget?.appearanceSettings?.font?.size}
                    name="size"
                />
            </div>
            <Divider/>
            <Width width={widget?.appearanceSettings?.width}/>
            <AppearanseSet
                currentWidget={widget}
                label="Form spacing"
                value={widget?.appearanceSettings?.spacing}
                name="spacing"
            />
            <Orientation align={widget?.appearanceSettings?.align}/>
            <Divider/>
            <BorderWidth
                topValue={widget?.appearanceSettings?.borderTopWidth}
                rightValue={widget?.appearanceSettings?.borderRightWidth}
                leftValue={widget?.appearanceSettings?.borderLeftWidth}
                bottomValue={widget?.appearanceSettings?.borderBottomWidth}
            />
            <BorderRadius
                topRightValue={widget?.appearanceSettings?.borderTopRightRadius}
                bottomRightValue={widget?.appearanceSettings?.borderBottomRightRadius}
                topLeftValue={widget?.appearanceSettings?.borderTopLeftRadius}
                bottomLeftValue={widget?.appearanceSettings?.borderBottomLeftRadius}
            />
            <Divider/>
            <Width
                width={widget?.appearanceSettings?.image?.width}
                label={'Image width'}
                isImage={true}
            />
            <Height
                height={widget?.appearanceSettings?.image?.height}
                label={'Image height'}
                isImage={true}
            />
            <BorderRadius
                topRightValue={widget?.appearanceSettings?.image?.borderTopRightRadius}
                bottomRightValue={widget?.appearanceSettings?.image?.borderBottomRightRadius}
                topLeftValue={widget?.appearanceSettings?.image?.borderTopLeftRadius}
                bottomLeftValue={widget?.appearanceSettings?.image?.borderBottomLeftRadius}
                label={'Image border radius'}
                isImage={true}
            />
            <Divider/>
            <Margins
                topValue={widget?.appearanceSettings?.marginTop}
                bottomValue={widget?.appearanceSettings?.marginBottom}
                leftValue={widget?.appearanceSettings?.marginLeft}
                rightValue={widget?.appearanceSettings?.marginRight}
            />
            <Paddings
                topValue={widget?.appearanceSettings?.paddingTop}
                bottomValue={widget?.appearanceSettings?.paddingBottom}
                leftValue={widget?.appearanceSettings?.paddingLeft}
                rightValue={widget?.appearanceSettings?.paddingRight}
            />
            <Divider/>
            <Colors colors={widget?.colourSettings} />
        </WidgetSettingContainer>
    )
}

export default FormDesignSetting
