import { FC } from 'react'
import { capitalizeFirstLetter } from '../../helpers/capitalizeFirstLetter'
import { IFields } from '../../modules/widgets/forms/IForm'

interface Props {
    fields: IFields[]
}

const TableHeadFields: FC<Props> = ({ fields }) => {
    return (
        <tr>
            <th className="table__head-item">Date</th>
            {fields.map((field: IFields, index: number) => {
                return (
                    <th className="table__head-item" key={`${field.id}-field${index}`}>
                        {capitalizeFirstLetter(field.fieldName)}
                    </th>
                )
            })}
            <th className="table__head-item" style={{ width: '40px' }}></th>
        </tr>
    )
}

export default TableHeadFields
