import styled from 'styled-components'

export const EmbedWrapper = styled.div`
    max-width: 450px;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    color: #000;
    height: 500px;
    box-shadow: 0 0 10px 3px #33333340;

    & > img {
        margin-bottom: 25px;
    }

    & > p {
        font-size: 26px;
        color: #5d5d5d;
        margin: 20px 0;
    }

    .description {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
        & > p {
            font-size: 22px;
            font-weight: bold;
            text-align: center;
        }

        & > span {
            font-size: 14px;
            color: #a9a9a9;
            text-align: center;
        }

        & > img {
            border-radius: 8px;
        }

        & > a {
            text-decoration: none;
            font-size: 16px;
            font-weight: bold;
            padding: 10px 18px;
            background-color: #6478f9;
            color: #fff;
            border-radius: 5px;
            display: flex;
            align-items: center;
        }
    }
`

export const DrawEmbedContainer = styled.div`
    max-width: 420px;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
    box-shadow: 0 0 10px 3px #33333340;

    .loader {
        width: 100%;
        height: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 15px;
    }
`
