import React, { useState, memo } from 'react'
import styled from 'styled-components'
import { useForm } from '../../../../../../builder/context/form-state-old'
import { useWidget } from 'widgets-base'

interface Props {
}

interface Options {
    label: string
    value: string
    state: boolean
}

const Checkbox = ({}: Props) => {

    const { widget } = useWidget();
    const { setFieldValue } = useForm();

    const required = widget.settings.required
    const name = widget.settings.name
    const label = widget.settings.label

    const [checkedState, setCheckedState] = useState<Options[]>(widget.settings.options)

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, index: number): void => {
        const checkboxIndex = checkedState.findIndex((check, ind) => ind === index)
        if (checkboxIndex === -1) return
        const updatedCheckbox = {
            ...checkedState[checkboxIndex],
            state: !checkedState[checkboxIndex].state,
        }

        const newCheckboxes = [
            ...checkedState.slice(0, checkboxIndex),
            updatedCheckbox,
            ...checkedState.slice(checkboxIndex + 1),
        ]

        setCheckedState(newCheckboxes)

        // extract options and create flatten string suitable for csv
        let data: string[] = []
        newCheckboxes.forEach((checkbox) => {
            if (checkbox.state) {
                data.push(checkbox.value)
            }
        })

        setFieldValue(name, data);
    }

    const width = widget.appearanceSettings.width + '%'
    const font = widget.appearanceSettings.font || { family: 'Open Sans' }
    const modFontFamily = font.family?.replace(/\s/g, '+')
    const paddingTop = widget.appearanceSettings.paddingTop / 10 + 'em'
    const paddingBottom = widget.appearanceSettings.paddingBottom / 10 + 'em'
    const paddingLeft = widget.appearanceSettings.paddingLeft / 10 + 'em'
    const paddingRight = widget.appearanceSettings.paddingRight / 10 + 'em'
    const marginTop = widget.appearanceSettings.marginTop / 10 + 'em'
    const marginBottom = widget.appearanceSettings.marginBottom / 10 + 'em'
    const marginLeft = widget.appearanceSettings.marginLeft / 10 + 'em'
    const marginRight = widget.appearanceSettings.marginRight / 10 + 'em'

    const borderTopLeftRadius = widget.appearanceSettings.borderTopLeftRadius / 10 + 'em'
    const borderTopRightRadius = widget.appearanceSettings.borderTopRightRadius / 10 + 'em'
    const borderBottomLeftRadius = widget.appearanceSettings.borderBottomLeftRadius / 10 + 'em'
    const borderBottomRightRadius = widget.appearanceSettings.borderBottomRightRadius / 10 + 'em'
    const borderTopWidth = widget.appearanceSettings.borderTopWidth / 10 + 'em'
    const borderBottomWidth = widget.appearanceSettings.borderBottomWidth / 10 + 'em'
    const borderLeftWidth = widget.appearanceSettings.borderLeftWidth / 10 + 'em'
    const borderRightWidth = widget.appearanceSettings.borderRightWidth / 10 + 'em'
    const borderWidth = borderTopWidth && borderRightWidth && borderBottomWidth && borderLeftWidth
    const alignment = widget.appearanceSettings.alignment
    const fontSize = widget.appearanceSettings.font.size
        ? widget.appearanceSettings.font.size + 'px'
        : '14px'
    const fontUrl = widget.appearanceSettings?.font?.url
    const spacing = widget.appearanceSettings.spacing / 10 + 'em'
    const columns = widget.appearanceSettings.columns
    const weight = Number(widget?.appearanceSettings?.font?.weight) || 400

    const color = Array.isArray(widget.colourSettings['text colour'])
        ? widget.colourSettings['text colour'][0]
        : widget.colourSettings['text colour']
    const borderColor = Array.isArray(widget.colourSettings['border colour'])
        ? widget.colourSettings['border colour'][0]
        : widget.colourSettings['border colour']
    const backgroundColor = Array.isArray(widget.colourSettings['background colour'])
        ? widget.colourSettings['background colour'][0]
        : widget.colourSettings['background colour']
    const labelColour = Array.isArray(widget.colourSettings['label colour'])
        ? widget.colourSettings['label colour'][0]
        : widget.colourSettings['label colour']
    const iconColour = Array.isArray(widget.colourSettings['icon colour'])
        ? widget.colourSettings['icon colour'][0]
        : widget.colourSettings['icon colour']
    const letterSpacing = widget?.appearanceSettings?.font?.spacing + 'px' || '8px'
    const lineHeight = widget?.appearanceSettings?.font?.lineHeight || 1

    const iconSize = widget.appearanceSettings.iconSize / 10 + 'em'

    return (
        <Wrapper
            align={alignment}
            marginTop={marginTop}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            marginRight={marginRight}
            fontFamily={font.family}
        >
            <style>
                {!fontUrl
                    ? `@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`
                    : `@font-face {
                        font-family: '${font.family}';
                        src: url('${fontUrl}');
                        font-style: normal;
                      }
                      
                      .header-text {
                        font-family: '${font.family}';
                      }`}
            </style>
            <Container
                width={width}
                paddingTop={paddingTop}
                paddingBottom={paddingBottom}
                paddingLeft={paddingLeft}
                paddingRight={paddingRight}
            >
                <div
                    style={{
                        display: "grid",
                        gridGap: spacing,
                        width: '100%',
                        border: `${borderWidth} solid ${borderColor}`,
                        backgroundColor,
                        color,
                        paddingTop,
                        paddingBottom,
                        paddingLeft,
                        paddingRight,
                        borderTopLeftRadius,
                        borderTopRightRadius,
                        borderBottomLeftRadius,
                        borderBottomRightRadius,
                    }}
                >
                    <label
                        className={`${
                            alignment === 'center'
                                ? 'self-center'
                                : alignment === 'left'
                                ? 'self-start'
                                : 'self-end'
                        }`}
                        style={{
                            width: '100%',
                            fontSize,
                            fontWeight: +weight,
                            marginBottom: '0.5em',
                            color: labelColour,
                            letterSpacing,
                            lineHeight,
                        }}
                    >
                        {label}
                    </label>
                    <style>
                        {`
                            .checkbox {
                                display: grid;
                                grid-template-columns: min-content auto;
                                align-items: center;
                                grid-gap: 0.5em;
                                font-size: ${fontSize};
                                font-weight: ${weight};
                                color: ${iconColour};
                            }

                            .checkbox__input input[type='checkbox'] {
                                opacity: 0;
                                width: ${iconSize};
                                height: ${iconSize};
                            }

                            .checkbox__control {
                                display: inline-grid;
                                width: ${iconSize};
                                height: ${iconSize};
                                border-radius: 0.25em;
                                border: 0.1em solid currentColor;
                                color: ${iconColour};
                            }

                            .checkbox__input {
                                display: grid;
                                grid-template-areas: 'checkbox';
                            }

                            .checkbox__input > * {
                                grid-area: checkbox;
                            }

                            .checkbox__control svg {
                                transition: transform 0.1s ease-in 25ms;
                                transform: scale(0);
                                transform-origin: bottom left;
                            }

                            .checkbox__input input:checked + .checkbox__control svg {
                                transform: scale(1);
                            }

                            .checkbox__input input:focus + .checkbox__control {
                                box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em ${iconColour};
                            }

                            .checkbox__input input:checkbox:disabled + .checkbox__control {
                                color: grey;
                            }
                        `}
                    </style>

                    {widget.settings.options.map((option, index) => {
                        return (
                            <label key={`${name}-checkbox-${index}`} className="checkbox">
                                <span className="checkbox__input">
                                    <input
                                        type="checkbox"
                                        name={name}
                                        onChange={(e) => handleCheckboxChange(e, index)}
                                        value={option.value}
                                        required={required}
                                    />
                                    <span className="checkbox__control">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            aria-hidden="true"
                                            focusable="false"
                                        >
                                            <path
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="3"
                                                d="M1.73 12.91l6.37 6.37L22.79 4.59"
                                            />
                                        </svg>
                                    </span>
                                </span>
                                <span
                                    className="radio__label"
                                    style={{
                                        fontSize,
                                        fontWeight: weight,
                                        letterSpacing,
                                        lineHeight,
                                    }}
                                >
                                    {option.label}
                                </span>
                            </label>
                        )
                    })}
                </div>
            </Container>
        </Wrapper>
    )
}

export default memo(Checkbox)

const Wrapper = styled.div<{
    align: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
    fontFamily: string
}>`
    display: flex;
    align-items: ${(props) =>
        props.align === 'left' ? 'flex-start' : props.align === 'center' ? 'center' : 'flex-end'};
    width: 100%;
    cursor: pointer;
    flex-direction: column;
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    font-family: ${(props) => props.fontFamily};
`
const Container = styled.div<{
    width: string
    paddingTop: string
    paddingBottom: string
    paddingLeft: string
    paddingRight: string
}>`
    position: relative;
    width: ${(props) => props.width};
`
