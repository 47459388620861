import { motion } from 'framer-motion'
import React from 'react'
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    ViberShareButton,
    WhatsappShareButton,
    LinkedinIcon,
    FacebookIcon,
    TwitterIcon,
    ViberIcon,
    WhatsappIcon,
    PinterestShareButton,
    PinterestIcon,
} from 'react-share'
import styled from 'styled-components'
import { ICampaign } from 'widgets-base'


interface Props {
    campaign: ICampaign
    firstName: string
    lastName: string
    description: string
    role: string
    source: string
}

export const ShareSocial = ({
    firstName,
    lastName,
    description,
    role,
    source,
    campaign,
}: Props) => {
    return (
        <SocialContainer>
            <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                    duration: 0.8,
                    delay: 0.1,
                    ease: [0, 0.71, 0.2, 1.01],
                }}
            >
                <LinkedinShareButton
                    title={`Digital card ${firstName} ${lastName}, ${role}`}
                    summary={description}
                    source={source}
                    windowWidth={750}
                    windowHeight={600}
                    url={campaign?.deployUrl}
                >
                    <LinkedinIcon size={'2rem'} round />
                </LinkedinShareButton>
            </motion.div>
            <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                    duration: 0.8,
                    delay: 0.2,
                    ease: [0, 0.71, 0.2, 1.01],
                }}
            >
                <FacebookShareButton
                    title={`Digital card ${firstName} ${lastName}, ${role}`}
                    quote={description}
                    hashtag={role}
                    windowWidth={750}
                    windowHeight={600}
                    url={campaign?.deployUrl}
                >
                    <FacebookIcon size={'2rem'} round />
                </FacebookShareButton>
            </motion.div>
            <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                    duration: 0.8,
                    delay: 0.3,
                    ease: [0, 0.71, 0.2, 1.01],
                }}
            >
                <TwitterShareButton
                    title={`Digital card ${firstName} ${lastName}, ${role}`}
                    hashtags={[`${role}`, '#digitalcard', '#deployable']}
                    windowWidth={750}
                    windowHeight={600}
                    url={campaign?.deployUrl}
                >
                    <TwitterIcon size={'2rem'} round />
                </TwitterShareButton>
            </motion.div>
            <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                    duration: 0.8,
                    delay: 0.4,
                    ease: [0, 0.71, 0.2, 1.01],
                }}
            >
                <ViberShareButton
                    title={`Digital card ${firstName} ${lastName}, ${role}`}
                    windowWidth={750}
                    windowHeight={600}
                    url={campaign?.deployUrl}
                >
                    <ViberIcon size={'2rem'} round />
                </ViberShareButton>
            </motion.div>
            <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                    duration: 0.8,
                    delay: 0.5,
                    ease: [0, 0.71, 0.2, 1.01],
                }}
            >
                <WhatsappShareButton
                    title={`Digital card ${firstName} ${lastName}, ${role}`}
                    windowWidth={750}
                    windowHeight={600}
                    url={campaign?.deployUrl}
                >
                    <WhatsappIcon size={'2rem'} round />
                </WhatsappShareButton>
            </motion.div>
            <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{
                    duration: 0.8,
                    delay: 0.6,
                    ease: [0, 0.71, 0.2, 1.01],
                }}
            >
                <PinterestShareButton
                    title={`Digital card ${firstName} ${lastName}, ${role}`}
                    media={campaign.deployQRUrl}
                    description={description}
                    windowWidth={750}
                    windowHeight={600}
                    url={campaign?.deployUrl}
                >
                    <PinterestIcon size={'2rem'} round />
                </PinterestShareButton>
            </motion.div>
        </SocialContainer>
    )
}

const SocialContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 22px;
    top: 60px;
    z-index: 10;

    & > div:not(:last-child) {
        margin-bottom: 7px;
    }
`
