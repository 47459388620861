import { Tooltip } from '@mui/material'
import React, { FC } from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Toast } from '../../../helpers/Toast'
import BackgrondSettings from './BackgrondSettings'
import { capitalizeFirstLetter } from '../../../helpers/capitalizeFirstLetter'
import styled from 'styled-components'
import { useConfirm } from '../../../../hooks/confirm'
import { useCampaign } from '../../../../builder/context/campaign'

interface Props {
    currentPageIndex: number
}

const PageSettingsBlock: FC<Props> = ({ currentPageIndex }) => {

    const { campaign, copyPage, removePage } = useCampaign();

    const { isConfirmed } = useConfirm();

    const onRemovePage = (id: string) => async () => {
        if (campaign.views.length === 1) return
        const confirmed = await isConfirmed(`Are you sure you want to delete this page?`)

        if (confirmed) {
            removePage(id)
            Toast('Page deleted!', 'success')
        }
    }

    const copyCurrentPage = () => {
        copyPage()
        Toast('Copied!', 'success')
    }

    return (
        <LayerSetting>
            <h3>{capitalizeFirstLetter(campaign?.views?.[currentPageIndex]?.name)}</h3>
            <div className="headerDivider"></div>
            <BackgrondSettings />
            <Tooltip title="Copy page" arrow>
                <div onClick={copyCurrentPage}>
                    <ContentCopyIcon color="inherit" />
                </div>
            </Tooltip>
            <Tooltip title="Remove page" arrow>
                <div onClick={onRemovePage(campaign?.views?.[currentPageIndex]?.id)}>
                    <DeleteOutlineIcon color="inherit" />
                </div>
            </Tooltip>
        </LayerSetting>
    )
}

export default PageSettingsBlock


export const LayerSetting = styled.div`
    width: max-content;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: #4b4b4b;
    position: absolute;
    top: 0;
    color: #fff;
    padding: 10px;
    display: flex;
    align-items: center;

    h3 {
        font-weight: bold;
        font-size: 14px;
        margin-right: 10px;
    }

    .headerDivider {
        border-left: 1px solid #d3d3d3;
        height: 15px;
        margin-right: 10px;
    }

    svg {
        cursor: pointer;
        margin-right: 10px;
        transition: transform 0.15s ease-in-out;
        &:hover {
            transform: scale(1.1);
        }
    }
`