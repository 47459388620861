import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useProperty } from "../../context/property";
import { IPropertyType } from "../property-type";

//
// An editor for text properties.
//
function PropertyTextEditor() {

    const { propertyValue, setPropertyValue } = useProperty();

    const [ text, setText ] = useState<string>(propertyValue || "");

    useEffect(() => {
        const timer = setTimeout(() => {
            const curValue = propertyValue || "";
            if (curValue !== text) {
                setPropertyValue(text);
            }       
        }, 1000);

        return () => {
            clearTimeout(timer);
        };

    }, [text]);

    useEffect(() => {
        const newText = propertyValue || "";
        if (newText !== text) {
            setText(newText);
        }

    }, [propertyValue]);

    return (
        <ReactQuill 
            theme="snow" 
            value={text} 
            onChange={value => {
                setText(value);
            }}    
            />
    );
}

const text: IPropertyType = {
    editor: () => <PropertyTextEditor />,
};

export default text;