import React, { useCallback } from 'react';
import Settings from '../icons/Settings';
import Elements from '../icons/Elements';
import Assets from '../icons/Assets';
import Design from '../icons/Design';
import Templates from '../icons/Templates';
import QRDesign from '../icons/QRDesign';
import styled from 'styled-components';
import { atom, useRecoilState } from 'recoil';
import { RouteNames } from '../../router';
import { useNavigate } from 'react-router-dom';
import { sideBarOpenState } from '../../v1/components/BuilderLayout';
import { useCampaign } from '../context/campaign';
import EditIcon from '@mui/icons-material/Edit';
import AirplayIcon from '@mui/icons-material/Airplay';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import BuildIcon from '@mui/icons-material/Build';
import { debugModeState } from '../../v1/store/builder/builder';

export const selectedNavbar = atom({
    key: 'selectedNavbar',
    default: null,
});

export function BuilderNav() {
    const { selectedWidget } = useCampaign();    
    const [activeTab, setActiveTab] = useRecoilState(selectedNavbar);
    const [isSidebarOpen, setSidebarOpen] = useRecoilState(sideBarOpenState);
    const [debugMode, setDebugMode] = useRecoilState(debugModeState);


    const navTabItem = [
        { label: 'Settings', id: 'settings', icon: <Settings /> },
        { label: 'Sections', id: 'elements', icon: <AirplayIcon /> },
        { label: 'Elements', id: 'sections', icon: <AddToQueueIcon /> },
        //todo:
        // { label: 'Assets', id: 'assets', icon: <Assets /> },
        // { label: 'Design', id: 'design', icon: <Design /> },
        // { label: 'Templates', id: 'templates', icon: <Templates /> },
        { label: 'QR Design', id: 'qrdesign', icon: <QRDesign /> },
    ];

    if (selectedWidget) {
        navTabItem.push({ label: 'Selected', id: 'selected-widget', icon: <EditIcon /> });
    }

    if (debugMode) {
        navTabItem.push({ label: 'Debug', id: 'debug', icon: <BuildIcon /> });
    }

    return (
        <Nav>
            {navTabItem?.map((item) => (
                <NavTab
                    className={(isSidebarOpen && activeTab === item.label) ? 'active' : ''}
                    key={item.label}
                    label={item.label}
                    onClick={() => {
                        setActiveTab(item.label);
                        setSidebarOpen(true);
                    }}
                    >
                    {item.icon}
                </NavTab>
            ))}
        </Nav>
    )
}

export const Nav = ({ children, ...props }) => {

    const { checkOkToExit } = useCampaign();    
    const navigate = useNavigate();

    async function onLogoClick() {
        const ok = await checkOkToExit();
        if (ok) {           
            navigate(RouteNames.DASHBOARD)
        }
    }

    return (
        <Container {...props}>
            <LogoContainer onClick={onLogoClick}>
                <img src="/icons/color_icon.svg" height={40} width={40} alt="main page" />
            </LogoContainer>
            <div className="nav-container">{children}</div>
        </Container>
    )
}

const Container = styled.div`
    font-size: 10px;
    height: 100vh;
    border-right: 1px solid #dfe2f2;
    display: flex;
    flex-direction: column;
    max-width: 85px;
    align-items: center;
    color: rgb(51, 51, 51);
    background-color: white;
    padding-top: 1rem;
    width: 100%;

    .nav-container {
        margin-top: 1.5rem;
        width: 100%;

        & > .active {
            border-right: 3px solid #6478f9;
            background-color: #f6f7ff;
            color: #6478f9;

            svg {
                path,
                rect {
                    stroke: #6478f9;
                }
            }

            p {
                color: #6478f9;
            }
        }
    }

    img {
        cursor: pointer;
    }
`

interface NavTabPropsTypes {
    children: React.ReactNode
    label: string
    onClick: (value: any) => void
    onMouseEnter?: (value: any) => void
    onMouseLeave?: (value: any) => void
    className?: string
}

export const NavTab = ({ children, label, onClick, ...props }: NavTabPropsTypes) => {
    return (
        <NavTabContainer onClick={onClick} label={label.toLowerCase()} {...props}>
            {children}
            <p>{label}</p>
        </NavTabContainer>
    )
}

const NavTabContainer = styled.div.attrs(() => ({}))<{ label: string }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    width: 100%;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
    p {
        margin-top: 5px;
    }
    svg {
        transition: transform 0.2s ease-in-out;
    }

    &:hover {
        svg {
            transform: scale(1.2);
            path,
            rect {
                stroke: #6478f9;
            }
        }
        p {
            color: #6478f9;
        }
    }
`

const LogoContainer = styled.div`
    width: 40px;
    height: 40px;
`
