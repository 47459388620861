import {motion} from 'framer-motion'
import {FC, useState} from 'react'
import {Toast} from '../../helpers/Toast'
import {clientUpload} from '../../lib/upload/clientUpload'
import {UploadDropZone} from '../common/UploadDropZone'
import {ContentBlock} from './ContentBlock'
import { IBranding } from '../../modules/IBranding'
import { useAuth } from 'widgets-base'


interface Props {
    logoImageUrl?: string;
    logoImageId?: string;
    branding: IBranding;
    setBranding: (branding: IBranding) => void;
}

const LogoSetting: FC<Props> = ({ logoImageUrl, logoImageId, branding, setBranding }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const { makeAssetUrl } = useAuth();

    async function handleFileChange(file: File): Promise<void> {
        setLoading(true);
        try {
            const asset = await clientUpload(file);
            setBranding({
                ...branding,
                logoImageUrl: undefined,
                logoImageId: asset._id,
            });
        }
        catch (error) {
            const msg = 'Error uploading logo';
            console.error(msg);
            console.error(error);
            Toast(msg, 'error');
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <ContentBlock title="Logo">
            <UploadDropZone
                width="100%"
                height="170px"
                imagesOnly
                setFile={handleFileChange}
                isLoading={loading}
                />

            {(logoImageUrl || logoImageId) && (
                <motion.div
                    initial={{scale: 0}}
                    animate={{scale: 0.8}}
                    transition={{
                        delay: 0.5,
                        duration: 1,
                    }}
                    style={{marginTop: '15px', minHeight: '155px'}}
                >
                    <img src={logoImageUrl || (logoImageId && makeAssetUrl(logoImageId))} alt="Brand logo" style={{maxWidth: '100%'}}/>
                </motion.div>
            )}
        </ContentBlock>
    )
}

export default LogoSetting
