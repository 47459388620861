import {useState, useRef, useEffect, memo, FC} from 'react'
import styled, {keyframes} from 'styled-components'
import {ArrowRight, Settings} from '@mui/icons-material'
import {useNavigate} from 'react-router-dom'
import {deepPurple} from '@mui/material/colors'
import {Avatar, ButtonProps, IconButton, ListItem, ListItemIcon, ListItemText, Tooltip} from '@mui/material'
import {RouteNames} from '../../../router'
import {capitalizeFirstLetter} from '../../helpers/capitalizeFirstLetter'
import ListItemButton from '@mui/material/ListItemButton'
import { useAuth } from 'widgets-base'

const accOptions = [
    {id: 1, name: 'Profile settings', icon: '/icons/account.svg', path: RouteNames.ACCOUNT},
    {id: 2, name: 'Billing & Plans', icon: '/icons/billing.svg', path: RouteNames.BILLING},
    {
        id: 3,
        name: 'Feedback for deployable',
        icon: '/icons/feedback.png',
        path: RouteNames.FEEDBACK,
    },
    {
        id: 4,
        name: 'Log out',
        icon: '/icons/logout.svg',
    }
]

interface Props {
    openNavbar: boolean
}

const AccountNav: FC<Props> = ({openNavbar}) => {
    const [visibility, setVisibility] = useState<boolean>(false)
    const navigate = useNavigate()
    const { currentUser, signout } = useAuth()

    const menuRef = useRef<HTMLLIElement>()

    useEffect(() => {
        const handler = (event) => {
            if (!menuRef.current?.contains(event.target)) {
                setVisibility(false)
            }
        }
        document.addEventListener('mousedown', handler)
        return () => {
            document.removeEventListener('mousedown', handler)
        }
    })

    const onHandleOpen = () => {
        setVisibility((prev) => !prev)
    }

    const handleClickLink = (path: string) => () => {
        setVisibility(false)
        navigate(path)
    }
    const handleLogOut = async () => {
        try {
            await signout();
            navigate(RouteNames.LOGIN)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <ListItem disablePadding sx={{display: 'flex', position: 'relative'}} ref={menuRef}>
            <Tooltip
                arrow
                disableHoverListener={openNavbar}
                placement="right"
                title="Account settings"
            >
                <ColorButton sx={{justifyContent: openNavbar ? 'initial' : 'center'}} onClick={onHandleOpen}>
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: openNavbar ? 2 : 'auto',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar
                            src={currentUser?.avatar}
                            alt="avatar"
                            sx={{
                                width: 35,
                                height: 35,
                                fontSize: '12px',
                                bgcolor: deepPurple[500],
                            }}
                        >
                            {!currentUser?.avatar &&
                                currentUser?.firstname &&
                                currentUser?.lastname &&
                                currentUser?.firstname[0] + currentUser?.lastname[0]}
                        </Avatar>
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{
                            color: 'primary',
                            fontWeight: 'medium',
                            variant: 'body1',
                        }}
                        primary={
                            capitalizeFirstLetter(currentUser?.firstname) +
                            ' ' +
                            capitalizeFirstLetter(currentUser?.lastname)
                        }
                        sx={{
                            opacity: openNavbar ? 1 : 0,
                            whiteSpace: 'pre-wrap',
                            '& span': {
                                maxWidth: '40px',
                                hyphens: 'manual',
                            },
                        }}
                    />
                </ColorButton>
            </Tooltip>
            {openNavbar && (
                <IconButton
                    size="large"
                    onClick={onHandleOpen}
                    sx={{
                        '& svg': {
                            color: '#818181cc',
                            transition: '0.2s',
                            transform: 'translateX(0) rotate(0)',
                        },
                        '&:hover, &:focus': {
                            bgcolor: 'unset',
                            '& svg:first-of-type': {
                                transform: 'translateX(-4px) rotate(-20deg)',
                            },
                            '& svg:last-of-type': {
                                right: 0,
                                opacity: 1,
                            },
                        },
                        '&:after': {
                            content: '""',
                            position: 'absolute',
                            height: '80%',
                            display: 'block',
                            left: 0,
                            width: '1px',
                            bgcolor: 'divider',
                        },
                    }}
                >
                    <Settings/>
                    <ArrowRight sx={{position: 'absolute', right: 4, opacity: 0}}/>
                </IconButton>
            )}
            {visibility && (
                <Options openNavbar={openNavbar}>
                    <ul>
                        {accOptions?.map((option) => {
                            if (option.name === 'Log out') {
                                return (
                                    <li key={option.id} onClick={handleLogOut}>
                                        <img width={20} src={option.icon} alt={option.name}/> {option.name}
                                    </li>
                                )
                            } else {
                                return (
                                    <li key={option.id} onClick={handleClickLink(option.path)}>
                                        <img width={20} src={option.icon} alt={option.name}/> {option.name}
                                    </li>
                                )
                            }
                        })}
                    </ul>
                </Options>
            )}
        </ListItem>
    )
}

export default memo(AccountNav)

const fadeIn = keyframes`
    0% {
        opacity: 0;
        transform: translateX(-10px);
      }
    100% {
        opacity: 1;
      }
`

const Options = styled.div.attrs((props) => ({}))<{
    openNavbar?: boolean
}>`
    animation: 0.3s ${fadeIn} ease-out;
    position: fixed;
    // top: 65px; where should list of options exist?
    bottom: 38px;
    left: ${(props) => (props.openNavbar ? '244px' : '60px')};
    width: max-content;
    overflow-y: auto;
    padding: 8px;
    border: 1px solid #dfe2f2;
    border-radius: 10px;
    z-index: 99;
    background-color: #fff;
    ul {
        margin: 0;
        padding: 0;
        & > li:not(:last-child) {
            margin-bottom: 10px;
        }
        & > li {
            display: flex;
            align-items: flex-start;
            align-items: center;
            height: 32px;
            font-size: 14px;
            list-style: none;
            line-height: 29px;
            padding: 4.5px 15px;
            cursor: pointer;
            border-radius: 6px;
            color: #3c4b61;
            transition: 0.3s linear;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:hover {
                color: #000;
                background: rgba(100, 120, 249, 0.15);
            }
            & > img {
                margin-right: 9px;
            }
        }
    }
`

const ColorButton = styled(ListItemButton)<ButtonProps>(({theme}) => ({
    minHeight: 48,
    px: 2.5,
    margin: '5px',
    borderRadius: '8px',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
        backgroundColor: 'rgba(100, 120, 249, 0.15)',
        boxShadow: '0px 0px 5px 3px rgba(0, 0, 0, 0.3)',
        transform: 'translateY(-2px)',
    },
}))
