import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined'
import { Toast } from '../../../../helpers/Toast'
import { IFields } from '../../../../modules/widgets/forms/IForm'
import { IPollsAnswer, PollsTheme } from '../../../../modules/widgets/polls/IPolls'
import FormService from '../../../../../services/FormService'
import PollsWidget from '../PollsWidget'
import { pollsThemes } from '../theme'
import { useWidget } from 'widgets-base'
import { useCampaign } from '../../../../../builder/context/campaign'

interface Props {
    editMode?: boolean
    preview?: string
}

const MultiplePolls: FC<Props> = ({ editMode = false, preview }) => {

    const { campaign } = useCampaign();
    const { widget } = useWidget();
    const [pollAnswers, setPollAnswers] = useState([...widget?.settings?.data])
    const [isVoted, setIsVoted] = useState(false)

    const submitPollsForm = async (pollsData: { [key: string]: IFields[] }) => {
        try {
            const { data } = await FormService.submitPolls(
                pollsData,
                campaign._id,
                widget.id,
                widget.name,
                campaign.uid
            )

            return data
        } catch (error) {
            Toast(error, 'error')
            throw new Error(error.message)
        }
    }

    const obtainColors = (customTheme: string) => {
        const colors = pollsThemes[customTheme]
        if (!colors) {
            return pollsThemes['black']
        }
        return colors
    }

    const dataFromLocalStorage = JSON.parse(localStorage.getItem(widget.name))

    useEffect(() => {
        if (editMode) {
            setPollAnswers(widget.settings.data)
        }
    }, [editMode, widget.settings.data])

    useEffect(() => {
        const updateFormFields = (pollsAnswers: IFields[]) => {
            const updatedFields = pollsAnswers?.map((field: IFields) => {
                return {
                    option: field.fieldName,
                    votes: field.value,
                    id: field.id,
                }
            })

            return updatedFields
        }

        async function fetchPollsForms(campaignId: string) {
            const { data } = await FormService.getPollsData(campaignId)

            if (data) {
                const newFields = updateFormFields(data.forms[0].fields)
                setPollAnswers(newFields)
            }
        }

        const dataFromLocalStorage = JSON.parse(localStorage.getItem(widget.name))

        if (!editMode) {
            if (dataFromLocalStorage && dataFromLocalStorage.id === widget.id) {
                setIsVoted(true)
            }
            fetchPollsForms(campaign._id)
        }
    }, [])

    const appearanceSettings = widget?.appearanceSettings
    const colorSettings = widget?.colourSettings
    const fontUrl = appearanceSettings?.font?.url
    const fontFamily = appearanceSettings?.font?.family || 'Poppins'
    const fontSize = appearanceSettings?.font?.size
    const fontWeight = Number(appearanceSettings?.font?.weight) || 400
    const modFontFamily = fontFamily?.replace(/\s/g, '+')
    const mode = obtainColors(appearanceSettings?.mode)
    const width = appearanceSettings?.width + '%'
    const borderTopLeftRadius = appearanceSettings?.borderTopLeftRadius + 'px'
    const borderTopRightRadius = appearanceSettings?.borderTopRightRadius + 'px'
    const borderBottomLeftRadius = appearanceSettings?.borderBottomLeftRadius + 'px'
    const borderBottomRightRadius = appearanceSettings?.borderBottomRightRadius + 'px'
    const borderTopWidth = appearanceSettings.borderTopWidth / 10 + 'em'
    const borderBottomWidth = appearanceSettings.borderBottomWidth / 10 + 'em'
    const borderLeftWidth = appearanceSettings.borderLeftWidth / 10 + 'em'
    const borderRightWidth = appearanceSettings.borderRightWidth / 10 + 'em'
    const borderWidth = borderTopWidth && borderRightWidth && borderBottomWidth && borderLeftWidth
    const borderColour = colorSettings['border colour'][0]
    const marginTop = appearanceSettings?.marginTop / 10 + 'em'
    const marginBottom = appearanceSettings?.marginBottom / 10 + 'em'
    const marginRight = appearanceSettings?.marginRight / 10 + 'em'
    const marginLeft = appearanceSettings?.marginLeft / 10 + 'em'
    const paddingTop = appearanceSettings?.paddingTop + 'px'
    const paddingBottom = appearanceSettings?.paddingBottom + 'px'
    const paddingLeft = appearanceSettings?.paddingLeft + 'px'
    const paddingRight = appearanceSettings?.paddingRight + 'px'

    const customStyles: PollsTheme = {
        textColor: colorSettings['question colour'][0],
        mainColor: '#62f5c4',
        backgroundColor: 'rgb(255,255,255)',
        alignment: appearanceSettings?.align,
        mode: mode,
    }

    const handleVote = useCallback(
        async (voteAnswer: string) => {
            if (editMode) return

            try {
                const voteAnswerIndex = pollAnswers.findIndex(
                    (answer) => answer.option === voteAnswer
                )

                const newPollAnswer = {
                    ...pollAnswers[voteAnswerIndex],
                    votes: pollAnswers[voteAnswerIndex].votes + 1,
                }

                const newPollAnswers = [
                    ...pollAnswers.slice(0, voteAnswerIndex),
                    newPollAnswer,
                    ...pollAnswers.slice(voteAnswerIndex + 1),
                ]

                setPollAnswers(newPollAnswers)

                const updateFormFields = (pollsAnswers: IPollsAnswer[]) => {
                    const updatedFields = pollsAnswers.map((field: IPollsAnswer) => {
                        return {
                            id: field.id,
                            fieldName: field.option,
                            value: field.votes,
                            fieldType: 'polls',
                            fieldLabel: 'answer',
                        }
                    })

                    return updatedFields
                }

                const newFields = updateFormFields(newPollAnswers)

                await submitPollsForm({ fields: newFields })

                localStorage.setItem(
                    widget.name,
                    JSON.stringify({
                        id: widget.id,
                        answer: voteAnswer,
                        voteId: pollAnswers[voteAnswerIndex].id,
                    })
                )
            } catch (error) {
                alert(error)
            }
        },
        [campaign._id, editMode, pollAnswers, widget.id, widget.name]
    )

    const handleRedirect = (url: string) => () => {
        if (!url) return
        window.location.replace(url)
    }

    return (
        <PollsSection id={widget.id}>
            <FlexContainer
                previewWidth={preview}
                paddingTop={paddingTop}
                paddingBottom={paddingBottom}
                paddingLeft={paddingLeft}
                paddingRight={paddingRight}
                marginTop={marginTop}
                marginBottom={marginBottom}
                marginRight={marginRight}
                marginLeft={marginLeft}
            >
                <div className="polls_title" style={{ color: customStyles.textColor, fontFamily }}>
                    <h2 style={{ marginBottom: '20px', padding: '10px' }}>
                        {widget.settings.label}
                    </h2>
                    <span style={{ maxWidth: '250px', marginBottom: '20px' }}>
                        {widget.settings.title}
                    </span>
                    <span style={{ marginBottom: '10px', fontSize: '16px' }}>
                        {widget.settings.subtitle}
                    </span>
                    <button
                        style={{ color: customStyles.textColor, fontFamily }}
                        type="button"
                        onClick={handleRedirect(widget.settings.buttonLink)}
                    >
                        {widget.settings.buttonText} <ArrowForwardOutlinedIcon />
                    </button>
                </div>
                <Wrapper
                    width={width}
                    align={widget?.appearanceSettings?.align}
                    background={colorSettings['background colour'][0]}
                    border={`${borderWidth} solid ${borderColour}`}
                    borderTopLeftRadius={borderTopLeftRadius}
                    borderTopRightRadius={borderTopRightRadius}
                    borderBottomLeftRadius={borderBottomLeftRadius}
                    borderBottomRightRadius={borderBottomRightRadius}
                    fontFamily={fontFamily}
                    fontSize={fontSize + 'px'}
                    fontWeight={fontWeight}
                    className="poll_card"
                >
                    <style>
                        {!fontUrl
                            ? `@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`
                            : `@font-face {
                      font-family: '${fontFamily}';
                      src: url('${fontUrl}');
                      font-style: normal;
                    }
                    .header-text {
                      font-family: '${fontFamily}';
                    }`}
                    </style>

                    <PollsWidget
                        question={widget?.settings?.question}
                        pollAnswers={pollAnswers}
                        handleVote={handleVote}
                        isVoted={isVoted}
                        customStyles={customStyles}
                        pollsType={widget?.settings?.type}
                        dataFromLocalStorage={dataFromLocalStorage}
                    />
                </Wrapper>
            </FlexContainer>
        </PollsSection>
    )
}

export default memo(MultiplePolls)

const PollsSection = styled.section`
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #66bdf7;
`

const FlexContainer = styled.div<{
    previewWidth: string
    marginTop?: string
    marginBottom?: string
    marginRight?: string
    marginLeft?: string
    paddingTop?: string
    paddingBottom?: string
    paddingLeft?: string
    paddingRight?: string
}>`
    display: flex;
    flex-direction: ${(props) => (props.previewWidth === '414px' ? 'column' : 'row')};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    padding-top: ${(props) => props.paddingTop};
    padding-left: ${(props) => props.paddingLeft};
    padding-bottom: ${(props) => props.paddingBottom};
    padding-right: ${(props) => props.paddingRight};
    max-width: 740px;
    gap: 15px;

    .poll_card {
        width: ${(props) => (props.previewWidth === '414px' ? '90%' : '50%')};
    }

    .polls_title {
        width: ${(props) => (props.previewWidth === '414px' ? '100%' : '50%')};
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: ${(props) => (props.previewWidth === '414px' ? '18px' : '0')};

        & > h2 {
            font-size: 40px;
            font-weight: bold;
            text-align: center;
        }

        & > span {
            font-size: 14px;
            text-align: center;
            margin-bottom: 5px;
        }

        & > button {
            border: 0;
            outline: none;
            width: 60%;
            background-color: #2c6efa;
            padding: 10px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            font-weight: bold;
            cursor: pointer;

            & > svg {
                margin-left: 5px;
            }
        }
    }

    @media (max-width: 450px) {
        flex-direction: column;
        .polls_title {
            width: 100%;
            margin-bottom: 18px;
        }
        .poll_card {
            width: 100%;
        }
    }
`

const Wrapper = styled.div<{
    align: string
    width: string
    background: string
    border: string
    borderTopLeftRadius: string
    borderTopRightRadius: string
    borderBottomLeftRadius: string
    borderBottomRightRadius: string
    fontFamily: string
    fontSize: string
    fontWeight: number
}>`
    display: flex;
    margin: 0 auto;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);
    background-color: ${(props) => props.background};
    border: ${(props) => props.border};
    border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
    border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
    border-top-left-radius: ${(props) => props.borderTopLeftRadius};
    border-top-right-radius: ${(props) => props.borderTopRightRadius};
    font-family: ${(props) => props.fontFamily};
    font-size: ${(props) => props.fontSize};

    article {
        width: 100%;

        h1 {
            width: 100%;
            border-width: 0;
            border-bottom: 1px solid #944b5d3a;
            text-align: ${(props) => props.align};
            font-weight: bold;
        }
    }
`
