import { motion, SVGMotionProps } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'

const Button = styled.div`
    z-index: 99;
    cursor: pointer;
`

const Path = (
    props: JSX.IntrinsicAttributes &
        SVGMotionProps<SVGPathElement> &
        React.RefAttributes<SVGPathElement>
) => <motion.path fill="transparent" strokeLinecap="round" strokeWidth="3" {...props} />

const transition = { duration: 0.33 }

export const MenuToggle = ({ toggle, isOpen }) => {
    return (
        <Button onClick={toggle}>
            <svg
                width="35"
                height="30"
                style={{ padding: '7px' }}
                viewBox="0 0 47 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <Path
                    animate={isOpen ? 'open' : 'closed'}
                    initial={false}
                    variants={{
                        closed: {
                            d: 'M38.0817 28.7891C38.0696 27.8895 38.3209 27.0067 38.8035 26.2528C39.2861 25.4988 39.9782 24.9079 40.7919 24.5552C41.6056 24.2024 42.5041 24.1037 43.3731 24.2716C44.2421 24.4396 45.0423 24.8665 45.6721 25.4983C46.3018 26.13 46.7327 26.9379 46.9097 27.8193C47.0868 28.7007 47.0021 29.6157 46.6665 30.4481C46.3308 31.2804 45.7593 31.9924 45.0247 32.4935C44.2901 32.9946 43.4256 33.2623 42.5411 33.2623C41.9564 33.2781 41.3745 33.1737 40.8305 32.9553C40.2864 32.7368 39.7914 32.409 39.375 31.9912C38.9586 31.5735 38.6294 31.0746 38.4071 30.5243C38.1849 29.9741 38.0742 29.3839 38.0817 28.7891Z',
                            fill: '#3AE29B',
                        },
                        open: {
                            d: 'M38.0817 28.7891C38.0696 27.8895 38.3209 27.0067 38.8035 26.2528C39.2861 25.4988 39.9782 24.9079 40.7919 24.5552C41.6056 24.2024 42.5041 24.1037 43.3731 24.2716C44.2421 24.4396 45.0423 24.8665 45.6721 25.4983C46.3018 26.13 46.7327 26.9379 46.9097 27.8193C47.0868 28.7007 47.0021 29.6157 46.6665 30.4481C46.3308 31.2804 45.7593 31.9924 45.0247 32.4935C44.2901 32.9946 43.4256 33.2623 42.5411 33.2623C41.9564 33.2781 41.3745 33.1737 40.8305 32.9553C40.2864 32.7368 39.7914 32.409 39.375 31.9912C38.9586 31.5735 38.6294 31.0746 38.4071 30.5243C38.1849 29.9741 38.0742 29.3839 38.0817 28.7891Z',
                            fill: '#3AE29B',
                        },
                    }}
                    transition={transition}
                />
                <Path
                    d="M23.0895 35.9999H19.5485L22.2235 29.9113C21.63 29.7297 21.0809 29.4193 20.6129 29.0008C20.145 28.5824 19.7689 28.0655 19.51 27.4848C19.251 26.9041 19.1151 26.273 19.1114 25.6337C19.1077 24.9944 19.2362 24.3617 19.4883 23.7778C19.9022 22.7697 20.6564 21.9512 21.6109 21.4745C22.5653 20.9978 23.6549 20.8954 24.6769 21.1863C25.699 21.4772 26.5839 22.1417 27.1671 23.0561C27.7504 23.9705 27.9921 25.0726 27.8476 26.1573C27.7224 27.1672 27.3993 28.1402 26.898 29.0168L23.0895 35.9999Z"
                    fill="#6478F9"
                    animate={isOpen ? 'open' : 'closed'}
                    initial={false}
                    variants={{
                        closed: { opacity: 1 },
                        open: { opacity: 0 },
                    }}
                    transition={transition}
                />
                <Path
                    animate={isOpen ? 'open' : 'closed'}
                    initial={false}
                    variants={{
                        closed: {
                            d: 'M19.1117 4.59415C19.1004 3.69475 19.3481 2.81219 19.8234 2.05866C20.2987 1.30513 20.9801 0.714682 21.781 0.36237C22.5818 0.0100586 23.4659 -0.0882019 24.321 0.0800835C25.176 0.248369 25.9633 0.675599 26.5827 1.30746C27.2022 1.93931 27.6258 2.74727 27.7998 3.62859C27.9738 4.50991 27.8903 5.4248 27.5599 6.25695C27.2295 7.0891 26.6671 7.8009 25.9442 8.30189C25.2213 8.80289 24.3706 9.07044 23.5002 9.07051C22.9247 9.08595 22.352 8.98116 21.8166 8.76242C21.2811 8.54367 20.7939 8.21548 20.3841 7.7975C19.9744 7.37953 19.6505 6.88037 19.4318 6.32994C19.2132 5.7795 19.1043 5.18911 19.1117 4.59415Z',
                            fill: '#6478F9',
                        },
                        open: {
                            d: 'M19.1117 4.59415C19.1004 3.69475 19.3481 2.81219 19.8234 2.05866C20.2987 1.30513 20.9801 0.714682 21.781 0.36237C22.5818 0.0100586 23.4659 -0.0882019 24.321 0.0800835C25.176 0.248369 25.9633 0.675599 26.5827 1.30746C27.2022 1.93931 27.6258 2.74727 27.7998 3.62859C27.9738 4.50991 27.8903 5.4248 27.5599 6.25695C27.2295 7.0891 26.6671 7.8009 25.9442 8.30189C25.2213 8.80289 24.3706 9.07044 23.5002 9.07051C22.9247 9.08595 22.352 8.98116 21.8166 8.76242C21.2811 8.54367 20.7939 8.21548 20.3841 7.7975C19.9744 7.37953 19.6505 6.88037 19.4318 6.32994C19.2132 5.7795 19.1043 5.18911 19.1117 4.59415Z',
                            fill: '#6478F9',
                        },
                    }}
                    transition={transition}
                />
                <Path
                    d="M0.000405939 4.59415C-0.0110817 3.6946 0.240742 2.8119 0.723854 2.0583C1.20697 1.3047 1.89954 0.714243 2.7135 0.362032C3.52745 0.0098206 4.42601 -0.0882397 5.29491 0.0803265C6.16381 0.248893 6.96379 0.676466 7.59311 1.30867C8.22243 1.94088 8.65265 2.74916 8.82908 3.63071C9.0055 4.51227 8.92016 5.42726 8.58389 6.25935C8.24762 7.09143 7.67561 7.80301 6.94063 8.30358C6.20564 8.80416 5.34089 9.07112 4.45633 9.07051C3.87163 9.08596 3.28989 8.98115 2.746 8.76237C2.2021 8.54359 1.70726 8.21535 1.29114 7.79732C0.875022 7.3793 0.546202 6.8801 0.324407 6.32968C0.102612 5.77926 -0.00758996 5.18895 0.000405939 4.59415Z"
                    fill="#FF7D34"
                    animate={isOpen ? 'open' : 'closed'}
                    initial={false}
                    variants={{
                        closed: { opacity: 1 },
                        open: { opacity: 0 },
                    }}
                    transition={transition}
                />
                <Path
                    animate={isOpen ? 'open' : 'closed'}
                    initial={false}
                    variants={{
                        closed: {
                            d: 'M0.000405939 28.7131C-0.0110817 27.8279 0.240742 26.9592 0.723854 26.2175C1.20697 25.4759 1.89954 24.8948 2.7135 24.5482C3.52745 24.2016 4.42601 24.1051 5.29491 24.2709C6.16381 24.4368 6.96379 24.8576 7.59311 25.4798C8.22243 26.102 8.65265 26.8974 8.82908 27.765C9.0055 28.6325 8.92016 29.533 8.58389 30.3519C8.24762 31.1708 7.67561 31.871 6.94063 32.3637C6.20564 32.8563 5.34089 33.119 4.45633 33.1184C3.87163 33.1336 3.28989 33.0305 2.746 32.8152C2.2021 32.5999 1.70726 32.2768 1.29114 31.8655C0.875022 31.4541 0.546202 30.9628 0.324407 30.4211C0.102612 29.8794 -0.00758996 29.2985 0.000405939 28.7131Z',
                            fill: '#FF7D34',
                        },
                        open: {
                            d: 'M0.000405939 28.7131C-0.0110817 27.8279 0.240742 26.9592 0.723854 26.2175C1.20697 25.4759 1.89954 24.8948 2.7135 24.5482C3.52745 24.2016 4.42601 24.1051 5.29491 24.2709C6.16381 24.4368 6.96379 24.8576 7.59311 25.4798C8.22243 26.102 8.65265 26.8974 8.82908 27.765C9.0055 28.6325 8.92016 29.533 8.58389 30.3519C8.24762 31.1708 7.67561 31.871 6.94063 32.3637C6.20564 32.8563 5.34089 33.119 4.45633 33.1184C3.87163 33.1336 3.28989 33.0305 2.746 32.8152C2.2021 32.5999 1.70726 32.2768 1.29114 31.8655C0.875022 31.4541 0.546202 30.9628 0.324407 30.4211C0.102612 29.8794 -0.00758996 29.2985 0.000405939 28.7131Z',
                            fill: '#FF7D34',
                        },
                    }}
                    transition={transition}
                />
            </svg>
        </Button>
    )
}
