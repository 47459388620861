import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import { RouteNames } from '../../../router'
import { MenuToggle } from './MenuToggle'

interface LogoPropType {
    isMobile?: boolean
    toggle?: () => void
    isOpen?: boolean
}
export const Logo = ({ isMobile, toggle, isOpen }: LogoPropType) => {
    const navigate = useNavigate()

    const goBackHome = () => {
        navigate(RouteNames.DASHBOARD)
    }

    return (
        <LogoWrapper onClick={goBackHome}>
            <LogoImg>
                {isMobile ? (
                    <MenuToggle toggle={toggle} isOpen={isOpen} />
                ) : (
                    <svg
                        width="180"
                        height="28"
                        style={{ padding: '5px' }}
                        viewBox="0 0 180 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M18.7362 14.1814C18.7362 11.351 17.0665 9.48496 14.5341 9.48496C12.0276 9.48496 10.3653 11.351 10.3653 14.1814C10.3653 17.0118 12.035 18.8761 14.5341 18.8761C17.0739 18.8761 18.7362 17.01 18.7362 14.1814ZM6.44702 14.1814C6.44702 9.39663 9.2299 6.09511 13.907 6.09511C15.8828 6.09511 17.5767 6.90301 18.4857 8.08633V0H22.4356V22.0175H18.4857V20.214C17.701 21.458 15.9459 22.2659 13.9701 22.2659C9.23546 22.2659 6.44702 18.9699 6.44702 14.1814Z"
                            fill="#192536"
                        />
                        <path
                            d="M37.5411 12.3735C37.3557 10.5129 36.1318 9.11653 33.8435 9.11653C31.9001 9.11653 30.5223 10.2652 30.0828 12.3735H37.5411ZM41.7134 15.2269H29.9938C30.3387 17.5829 31.8741 18.9775 34.1309 18.9775C35.9853 18.9775 37.2963 18.1096 37.7024 16.5921L41.4019 17.5848C40.5229 20.5298 37.7655 22.2657 34.0679 22.2657C28.8348 22.2657 26.0458 18.2655 26.0458 14.2048C26.0458 10.1441 28.616 6.14209 33.849 6.14209C39.1767 6.14209 41.7765 10.0505 41.7765 13.6763C41.7765 14.2268 41.745 14.9168 41.7134 15.2287"
                            fill="#192536"
                        />
                        <path
                            d="M57.1997 14.1966C57.1997 11.3773 55.5301 9.51858 53.0314 9.51858C50.4974 9.51858 48.8279 11.3773 48.8279 14.1966C48.8279 17.0158 50.4974 18.8727 53.0296 18.8727C55.5376 18.8727 57.1978 17.014 57.1978 14.1966H57.1997ZM45.129 6.38955H49.0783V8.18595C49.9576 6.97796 51.6754 6.14209 53.7808 6.14209C58.3275 6.14209 61.1175 9.42509 61.1175 14.1966C61.1175 18.968 58.1383 22.2492 53.4989 22.2492C51.6809 22.2492 49.9892 21.5673 49.0783 20.4546V27.6402H45.1308L45.129 6.38955Z"
                            fill="#192536"
                        />
                        <path d="M68.3381 0H64.4699V22.01H68.3381V0Z" fill="#192536" />
                        <path
                            d="M84.2112 14.2048C84.2112 11.4139 82.4849 9.52204 79.9418 9.52204C77.369 9.52204 75.6723 11.4139 75.6723 14.2048C75.6723 16.9957 77.3671 18.8857 79.9418 18.8857C82.4812 18.8857 84.2112 16.9957 84.2112 14.2048ZM71.6943 14.2048C71.6943 9.55323 75.1451 6.14209 79.9455 6.14209C84.7459 6.14209 88.1949 9.55323 88.1949 14.2048C88.1949 18.8564 84.7403 22.2657 79.9418 22.2657C75.1433 22.2657 71.6906 18.8545 71.6906 14.2048"
                            fill="#192536"
                        />
                        <path
                            d="M105.473 6.14209L97.1681 27.6402H93.1709L95.6047 21.3093L89.4843 6.14209H93.7641L97.5414 16.295L101.444 6.14209H105.473Z"
                            fill="#192536"
                        />
                        <path
                            d="M124.126 15.2599V14.9168L121.152 15.2599C119.075 15.5076 118.021 16.3737 118.021 17.4912C118.021 18.5775 118.888 19.383 120.315 19.383C122.733 19.383 124.126 17.5481 124.126 15.2599ZM127.908 12.4983V22.018H124.126V19.7555C123.259 21.2748 121.375 22.2657 119.446 22.2657C116.379 22.2657 114.241 20.2821 114.241 17.6141C114.241 14.5461 116.565 12.902 121.21 12.4047L124.123 12.1277V11.9717C124.123 10.2046 122.914 9.11836 121.023 9.11836C119.319 9.11836 118.111 10.1734 117.924 11.8487L114.453 11.2285C114.949 8.12749 117.707 6.14209 121.023 6.14209C125.115 6.14209 127.905 8.34401 127.905 12.4983"
                            fill="#192536"
                        />
                        <path
                            d="M143.908 14.1814C143.908 11.351 142.265 9.48496 139.807 9.48496C137.308 9.48496 135.674 11.351 135.674 14.1814C135.674 17.0118 137.308 18.8761 139.807 18.8761C142.275 18.8761 143.908 17.01 143.908 14.1814ZM135.921 20.214V22.0175H132.034V0H135.921V8.08633C136.815 6.90301 138.476 6.09511 140.424 6.09511C145.02 6.09511 147.765 9.39111 147.765 14.1814C147.765 18.9718 145.027 22.2659 140.362 22.2659C138.45 22.2659 136.691 21.458 135.921 20.214Z"
                            fill="#192536"
                        />
                        <path d="M154.47 0H150.602V22.01H154.47V0Z" fill="#192536" />
                        <path
                            d="M169.056 12.3735C168.871 10.5129 167.647 9.11653 165.358 9.11653C163.415 9.11653 162.037 10.2652 161.598 12.3735H169.056ZM173.223 15.2269H161.503C161.848 17.5829 163.383 18.9775 165.64 18.9775C167.495 18.9775 168.806 18.1096 169.212 16.5921L172.921 17.5848C172.042 20.5298 169.284 22.2657 165.588 22.2657C160.353 22.2657 157.564 18.2655 157.564 14.2048C157.564 10.1441 160.135 6.14209 165.368 6.14209C170.695 6.14209 173.295 10.0505 173.295 13.6763C173.295 14.2268 173.264 14.9168 173.232 15.2287"
                            fill="#192536"
                        />
                        <path
                            d="M175.101 19.8674C175.094 19.3853 175.232 18.9121 175.497 18.508C175.762 18.1039 176.142 17.7871 176.589 17.598C177.036 17.4089 177.53 17.356 178.007 17.4461C178.485 17.5361 178.925 17.7649 179.27 18.1035C179.616 18.4422 179.853 18.8752 179.95 19.3476C180.048 19.8201 180.001 20.3105 179.817 20.7567C179.632 21.2028 179.318 21.5844 178.915 21.8531C178.511 22.1217 178.036 22.2651 177.55 22.2652C177.229 22.2736 176.91 22.2176 176.611 22.1006C176.312 21.9835 176.04 21.8077 175.811 21.5838C175.582 21.3599 175.401 21.0925 175.279 20.7976C175.157 20.5026 175.096 20.1863 175.101 19.8674Z"
                            fill="#3AE29B"
                        />
                        <path
                            d="M109.241 25.3372H107.264L108.757 22.0076C108.426 21.9083 108.12 21.7386 107.858 21.5097C107.597 21.2809 107.387 20.9982 107.243 20.6807C107.098 20.3631 107.022 20.018 107.02 19.6684C107.018 19.3188 107.09 18.9727 107.23 18.6534C107.461 18.1022 107.883 17.6545 108.415 17.3938C108.948 17.1332 109.556 17.0772 110.127 17.2363C110.697 17.3954 111.192 17.7587 111.517 18.2588C111.843 18.7588 111.978 19.3615 111.897 19.9547C111.827 20.507 111.647 21.039 111.367 21.5185L109.241 25.3372Z"
                            fill="#6478F9"
                        />
                        <path
                            d="M107.02 8.34874C107.014 7.86665 107.152 7.39359 107.418 6.98969C107.683 6.58579 108.063 6.26931 108.51 6.08046C108.957 5.89162 109.451 5.83895 109.928 5.92916C110.406 6.01936 110.845 6.24836 111.191 6.58704C111.537 6.92572 111.773 7.35879 111.87 7.83119C111.967 8.30358 111.921 8.79397 111.736 9.24001C111.552 9.68605 111.238 10.0676 110.834 10.3361C110.431 10.6047 109.956 10.7481 109.47 10.7481C109.149 10.7564 108.829 10.7002 108.53 10.583C108.231 10.4657 107.959 10.2898 107.731 10.0658C107.502 9.84172 107.321 9.57417 107.199 9.27913C107.077 8.98409 107.016 8.66764 107.02 8.34874Z"
                            fill="#6478F9"
                        />
                        <path
                            d="M0.000223013 8.34874C-0.00608804 7.86657 0.132258 7.39343 0.397669 6.9895C0.663079 6.58556 1.04357 6.26907 1.49073 6.08028C1.9379 5.89149 2.43155 5.83893 2.9089 5.92929C3.38626 6.01964 3.82575 6.24882 4.17148 6.58769C4.51721 6.92656 4.75357 7.3598 4.85049 7.83232C4.94741 8.30484 4.90053 8.79529 4.71579 9.24129C4.53105 9.6873 4.2168 10.0687 3.81302 10.337C3.40924 10.6053 2.93416 10.7484 2.4482 10.7481C2.12699 10.7564 1.80739 10.7002 1.50859 10.5829C1.20978 10.4657 0.937927 10.2897 0.709322 10.0657C0.480717 9.8416 0.300071 9.57402 0.178222 9.27899C0.0563725 8.98396 -0.00416975 8.66755 0.000223013 8.34874Z"
                            fill="#FF8038"
                        />
                        <path
                            d="M0.000223013 19.7362C-0.00608804 19.2794 0.132258 18.8312 0.397669 18.4485C0.663079 18.0658 1.04357 17.766 1.49073 17.5872C1.9379 17.4083 2.43155 17.3585 2.9089 17.4441C3.38626 17.5297 3.82575 17.7468 4.17148 18.0679C4.51721 18.3889 4.75357 18.7993 4.85049 19.247C4.94741 19.6946 4.90053 20.1593 4.71579 20.5818C4.53105 21.0043 4.2168 21.3657 3.81302 21.6199C3.40924 21.8741 2.93416 22.0096 2.4482 22.0093C2.12699 22.0172 1.80739 21.9639 1.50859 21.8528C1.20978 21.7417 0.937927 21.5751 0.709322 21.3628C0.480717 21.1505 0.300071 20.897 0.178222 20.6175C0.0563725 20.338 -0.00416975 20.0383 0.000223013 19.7362Z"
                            fill="#FF8038"
                        />
                    </svg>
                )}
            </LogoImg>
        </LogoWrapper>
    )
}

const fadeIn = keyframes`
    from {
      transform: translateX(-100px);
    }
    50% {
      transform: translateX(5px);
    }
    to {
      transform: translateX(0);
    }
`

const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
`

const LogoImg = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    place-content: center;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.2);
    svg {
        width: inherit;
        height: inherit;
        cursor: pointer;
        animation: ${fadeIn} 1s;
    }
`
