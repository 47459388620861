import { useRef } from "react";
import { PageThemeProvider } from "../../builder/context/page-theme";
import { StaticWidgets } from "../../v1/components/new/static-widgets";
import _ from "lodash";
import { IPageTheme, RenderContextProvider } from "widgets-base";
import { BackdropLoader } from "../../v1/components/common/BackdropLoader";
import { CampaignContextProvider, useCampaign } from "../../builder/context/campaign";

//
// Previews a campaign.
//
export function PreviewCampaign({ generating, pageTheme }: { generating: boolean, pageTheme: IPageTheme }) {

    const { campaign, currentPageIndex } = useCampaign();
    const curPage = campaign.views[currentPageIndex];

    return (
        <div
            className="relative"
            style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
            }}
            >
            <PageThemeProvider
                pageTheme={pageTheme}
                >
                <div className="main flex flex-col w-full">
                    <StaticWidgets
                        widgets={curPage.widgets}
                        />
                </div>
            </PageThemeProvider>

            {generating && <BackdropLoader />}
        </div>
    );
}

export function Preview({ generating, campaign, pageTheme, fontSize, height, maxWidth }) {

    return (
         <div
            className="flex flex-col"
            style={{
                fontSize,
                maxWidth,
                height,
            }}
            >
            <div
                className="mx-auto border border-dashed border-gray-400 bg-white overflow-y-scroll"
                style={{
                    filter: generating ? "blur(2px)" : "blur(0px)",
                    transition: "filter 0.5s ease",
                }}
                >
                <div
                    className=""
                    style={{}}
                    >   
                    {campaign &&
                        <CampaignContextProvider
                            key={campaign.lastUpdate}
                            campaign={campaign}
                            campaignId={campaign._id}
                            enableSaving={false}
                            >
                            <RenderContextProvider
                                defaultRenderContext={{
                                    screen: "large",
                                    orientation: "portrait",
                                }}
                                >
                                <PreviewCampaign 
                                    generating={generating}
                                    pageTheme={pageTheme}
                                    />
                            </RenderContextProvider>
                        </CampaignContextProvider>}
                </div>
            </div>
        </div>
    );
}

export function PreviewMultiple({ themes, selectedThemeIndex, setSelectedThemeIndex, generating, campaign }) {
    
    const scrollRefs = _.range(3).map(_ => useRef(undefined));

    function onScroll(scrollIndex: number) {
        //
        // Get scroll position.
        //
        const scrollPosition = scrollRefs[scrollIndex].current.scrollTop;

        //
        // Set linked scroll positions.
        //
        for (let i = 0; i < scrollRefs.length; i += 1) {
            if (i !== scrollIndex) {
                scrollRefs[i].current.scrollTop = scrollPosition;
            }
        }
    }

    return (
        <div 
            className="flex flex-row gap-x-4"
            style={{
                height: "calc(100vh - 200px)",
            }}
            >
            {themes.map((theme, themeIndex) => (
                <div
                    key={themeIndex}
                    className="flex flex-col h-full"
                    >
                    <div
                        className="border-4 border-solid rounded-sm p-1 h-full"
                        style={{
                            borderColor: themeIndex === selectedThemeIndex ? "blue" : "transparent",
                        }}
                        >
                        <div
                            className="border border-dashed border-gray-400 overflow-y-scroll bg-white h-full"
                            ref={scrollRefs[themeIndex]} 
                            onScroll={() => onScroll(themeIndex)}
                            onClick={() => setSelectedThemeIndex(themeIndex)}
                            style={{
                                filter: generating ? "blur(2px)" : "blur(0px)",
                                transition: "filter 0.5s ease",
                                fontSize: "3.5px",
                                maxWidth: "400px",
                            }}
                            >
                            <div className="select-none pointer-events-none">
                                <CampaignContextProvider
                                    key={campaign.lastUpdate}
                                    campaign={campaign}
                                    campaignId={campaign._id}
                                    enableSaving={false}
                                    >
                                    <RenderContextProvider
                                        defaultRenderContext={{
                                            screen: "large",
                                            orientation: "portrait",
                                        }}
                                        >
                                        <PreviewCampaign
                                            generating={generating}
                                            pageTheme={theme}
                                            />
                                    </RenderContextProvider>
                                </CampaignContextProvider>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}