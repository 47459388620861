import styled from 'styled-components'

export const BrandingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 5%;
    & > h1 {
        font-weight: 600;
        font-size: 32px;
        width: 100%;
        box-sizing: content-box;
        line-height: 38px;
        color: #3ae29b;
        margin-bottom: 7px;
        text-align: left;
    }

    & > span {
        font-size: 14px;
        color: #cacaca;
        width: 100%;
        text-align: left;
        margin-bottom: 2%;
    }

    .MuiButtonBase-root {
        letter-spacing: 0;
    }

    .brand__content {
        margin-bottom: 100px;
        width: 100%;
    }
`

export const BrandElements = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(278px, 1fr));
    justify-items: stretch;
    padding: 2px 5px 5px 2px;
    grid-gap: 28px;
    justify-content: space-between;
    margin-top: 28px;

    .sections {
        min-width: 278px;
        width: 100%;
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        justify-content: flex-start;
        border-radius: 12px;
        border: 1px solid #e3e7ff;
        background: '#FAFAFF';
        cursor: pointer;
        position: relative;
        transition: all 0.2s ease-in-out;
    }

    .brand__elements {
        display: flex;
        flex-direction: column;
    }
`

export const BrandingFooter = styled.div`
    padding: 12px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
`
