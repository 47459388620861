import { Button } from '@mui/material';
import { Preview } from '../preview';

export function FinalPreview({ onEdit, onDeploy, onBack, campaign, generating, pageTheme }) {

    return (
        <div className="flex flex-col overflow-hidden">
            <Preview 
                generating={generating}
                campaign={campaign}
                pageTheme={pageTheme}
                fontSize="6px"
                height="calc(100vh - 200px)"
                maxWidth="600px"
                />

            <div className="flex flex-row mt-8 ml-2">
                <Button
                    variant="contained"
                    onClick={onEdit}
                    >
                    Fine tune and deploy
                </Button>
                {/* <Button
                    variant="contained"
                    onClick={onDeploy}
                    sx={{ ml: 1 }}
                    >
                    Deploy
                </Button> */}

                <Button
                    sx={{ ml: 5 }}
                    variant="outlined"
                    onClick={onBack}
                    >
                    Back
                </Button> 
            </div>
        </div>
    );
}

