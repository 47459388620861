import React, { FC, ReactNode } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'

const variants = {
    open: { opacity: 1, y: 0, display: 'flex' },
    closed: { opacity: 0, y: '-5%', display: 'none' },
}

interface Props {
    children: ReactNode
    enabled: boolean
}

const PluginSettingsLayout: FC<Props> = ({ children, enabled }) => {
    return (
        <motion.div
            animate={enabled ? 'open' : 'closed'}
            variants={variants}
            transition={{ duration: 0.5 }}
        >
            <ContentWrapper>{children}</ContentWrapper>
        </motion.div>
    )
}

export default PluginSettingsLayout

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`
