import { FC, memo } from 'react'
import styled from 'styled-components'

import RenderContainerWidgets from './RenderContainerWidgets'
import { useWidget } from 'widgets-base'

interface Props {
    editMode?: boolean
}

const ContainerWidget: FC<Props> = ({ editMode }) => {

    const { widget } = useWidget();
    const appearanceSettings = widget?.appearanceSettings
    const colourSettings = widget?.colourSettings
    const backgroundImageUrl = appearanceSettings.backgroundImageUrl
    const backgroundColor = Array.isArray(colourSettings['background colour'])
        ? colourSettings['background colour'][0]
        : colourSettings['background colour']
    const borderColor = Array.isArray(colourSettings['border colour'])
        ? colourSettings['border colour'][0]
        : colourSettings['border colour']
    const paddingTop = appearanceSettings.paddingTop / 10 + 'em'
    const paddingBottom = appearanceSettings.paddingBottom / 10 + 'em'
    const paddingLeft = appearanceSettings.paddingLeft / 10 + 'em'
    const paddingRight = appearanceSettings.paddingRight / 10 + 'em'
    const width = appearanceSettings.width + '%'
    const borderTopWidth = appearanceSettings.borderTopWidth / 10 + 'em'
    const borderBottomWidth = appearanceSettings.borderBottomWidth / 10 + 'em'
    const borderLeftWidth = appearanceSettings.borderLeftWidth / 10 + 'em'
    const borderRightWidth = appearanceSettings.borderRightWidth / 10 + 'em'
    const borderWidth = borderTopWidth && borderRightWidth && borderBottomWidth && borderLeftWidth
    const marginTop = appearanceSettings.marginTop / 10 + 'em'
    const marginBottom = appearanceSettings.marginBottom / 10 + 'em'
    const marginLeft = appearanceSettings?.marginLeft / 10 + 'em'
    const marginRight = appearanceSettings?.marginRight / 10 + 'em'
    const borderTopLeftRadius = appearanceSettings.borderTopLeftRadius / 10 + 'em'
    const borderTopRightRadius = appearanceSettings.borderTopRightRadius / 10 + 'em'
    const borderBottomLeftRadius = appearanceSettings.borderBottomLeftRadius / 10 + 'em'
    const borderBottomRightRadius = appearanceSettings.borderBottomRightRadius / 10 + 'em'

    const isChildrenGame = widget.children?.some(widget => widget.category === 'game')

    const checkJustify = (align: string) => {
        if (align === 'left') {
            return 'flex-start'
        } else if (align === 'end') {
            return 'flex-end'
        } else {
            return 'center'
        }
    }

    return (
        <ContainerSection id={widget.id} alignment={checkJustify(appearanceSettings?.alignItems)}>
            <ContainerWrapper
                isPreview={editMode}
                isChildrenGame={isChildrenGame}
                width={width}
                borderTopWidth={borderTopWidth}
                borderLeftWidth={borderLeftWidth}
                borderRightWidth={borderRightWidth}
                borderBottomWidth={borderBottomWidth}
                borderWidth={borderWidth}
                borderColor={borderColor}
                marginTop={marginTop}
                marginBottom={marginBottom}
                marginLeft={marginLeft}
                marginRight={marginRight}
                style={{
                    backgroundImage: `url(${backgroundImageUrl || ''})`,
                    backgroundColor,
                    borderTopLeftRadius,
                    borderTopRightRadius,
                    borderBottomLeftRadius,
                    borderBottomRightRadius,
                    borderColor,
                    paddingTop,
                    paddingBottom,
                    paddingLeft,
                    paddingRight,
                    alignItems: `${editMode ? 'center' : appearanceSettings.alignItems}`,
                    gridTemplateColumns: `${
                        editMode
                            ? 'repeat(1,1fr)'
                            : 'repeat(' + appearanceSettings.columns + ',1fr)'
                    }`,
                }}
            >
                <RenderContainerWidgets
                    id={widget.id}
                    widgets={widget.children}
                    parentId={widget.id}
                />
            </ContainerWrapper>
        </ContainerSection>
    )
}

export default memo(ContainerWidget)

const ContainerSection = styled.section<{
    alignment: string
}>`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.alignment};
`

const ContainerWrapper = styled.div<{
    width: string
    isPreview: boolean
    isChildrenGame: boolean
    borderTopWidth: string
    borderLeftWidth: string
    borderRightWidth: string
    borderBottomWidth: string
    borderWidth: string
    borderColor: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
}>`
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: grid;
    height: auto;
    max-width: 420px;
    outline: none;
    cursor: pointer;
    width: ${(props) => props.width};
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    overflow-y: ${(props) => !props.isPreview && 'hidden'};
    overflow-x: ${(props) => !props.isPreview && 'hidden'};
    border: ${(props) => `${props.borderWidth} solid ${props.borderColor}`};
    border-top-width: ${(props) => props.borderTopWidth};
    border-bottom-width: ${(props) => props.borderBottomWidth};
    border-right-width: ${(props) => props.borderRightWidth};
    border-left-width: ${(props) => props.borderLeftWidth};
`
