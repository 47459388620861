import { motion } from 'framer-motion'
import { ChangeEvent, Dispatch, FC, SetStateAction } from 'react'
import { checkInputValid, checkValidPasswords } from '../v1/helpers/validation'
import { IErrors } from '../v1/modules/IErrors'
import { InputBox } from '../v1/components/common/InputBox'
import { IPassword } from 'widgets-base'

interface Props {
    password: IPassword
    error: IErrors
    setError: Dispatch<SetStateAction<IErrors>>
    handlePasswordChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const PasswordForm: FC<Props> = ({ password, error, setError, handlePasswordChange }) => {
    const checkValid = (value: string, name: string, type: string, errorMsg: string): void => {
        const valid = checkInputValid(value, type)

        if (!valid) {
            setError((prevState) => {
                return {
                    ...prevState,
                    [name]: errorMsg,
                }
            })
        } else {
            setError((prevState) => {
                return {
                    ...prevState,
                    [name]: '',
                }
            })
        }
    }

    const checkPasswords = (
        password: string,
        confirmPassword: string,
        name: string,
        errorMsg: string
    ): void => {
        const valid = checkValidPasswords(password, confirmPassword)

        if (!valid) {
            setError((prevState) => {
                return {
                    ...prevState,
                    [name]: errorMsg,
                }
            })
        } else {
            setError((prevState) => {
                return {
                    ...prevState,
                    [name]: '',
                }
            })
        }
    }

    return (
        <motion.div
            className="user__info"
            initial={{ opacity: 0, y: 50, scale: 0.3 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
        >
            <InputBox
                type="text"
                onChange={handlePasswordChange}
                width="230px"
                label="Password"
                name="password"
                value={password?.password || ''}
                error={error.password}
                onBlur={() =>
                    checkValid(
                        password?.password,
                        'password',
                        'password',
                        'Password must contain uppercase, lowercase, number and symbol.'
                    )
                }
            />
            <InputBox
                type="text"
                onChange={handlePasswordChange}
                width="230px"
                label="Confirm password"
                name="confirmPassword"
                value={password?.confirmPassword || ''}
                error={error.confirmPassword}
                onBlur={() =>
                    checkPasswords(
                        password?.password,
                        password?.confirmPassword,
                        'confirmPassword',
                        "Password's do not match."
                    )
                }
            />
        </motion.div>
    )
}

export default PasswordForm
