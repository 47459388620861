import { ChangeEvent, FC, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import Analytics from '../../components/Dashboard/Analytics'
import ProjectCard from '../../components/Dashboard/ProjectCard'
import { SEO } from '../../components/SEO'
import { Toast } from '../../helpers/Toast'

import CampaignsService from '../../../services/CampaignsService'

import { ScrollToTopButton } from '../../components/common/ScrollToTopButton'
import DashboardHeader from '../../components/Dashboard/DashboardHeader'
import DashboardTabs from '../../components/Dashboard/DashboardTabs'
import {
    DashboardWrapper,
    EmptyCampaign,
    ProjectGridContainer,
    ProjectsHeader,
    SearchResult,
} from './styles'
import { useNavigate } from 'react-router-dom'
import { RouteNames } from '../../../router'
import { useConfirm } from '../../../hooks/confirm'
import { ICampaign, useAuth } from 'widgets-base'

interface Props {
}

export interface IAllProjects {
    public: ICampaign[]
    draft: ICampaign[]
    legacy: ICampaign[]
}

const Dashboard: FC<Props> = ({}) => {
    const [campaigns, setCampaigns] = useState<ICampaign[]>([]);
    const [campaignsData, setCampaignsData] = useState<ICampaign[]>([]); //TODO: These are filtered campaings.
    const { currentUser } = useAuth()
    const [searchValue, setSearchValue] = useState<string>('')
    const [projects, setProjects] = useState<IAllProjects>()
    const [tab, setTab] = useState<number>(0)
    const { isConfirmed } = useConfirm();

    const navigate = useNavigate()

    useEffect(() => {
        if (currentUser) {
            CampaignsService.getCampaigns()
                .then(({ data }) => {
                    setCampaigns(data)
                    setCampaignsData(data)
                    if (!data.length) {
                        navigate(RouteNames.TEMPLATES)
                    }
                })
                .catch((error) => {
                    Toast(error.response.data.message, 'error')
                })
        }
    }, [setCampaigns, currentUser])

    const handleChangeTab = (_event: SyntheticEvent, newValue: number) => {
        setTab(newValue)
    }

    const handleChangeSearchValue = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setSearchValue(e.target.value)

            if (e.target.value) {
                const filteredCampaigns = campaignsData.filter((campaign) =>
                    campaign.campaignName.toLowerCase().includes(e.target.value.toLowerCase())
                )
                setCampaignsData(filteredCampaigns)
            } else {
                setCampaignsData(campaigns)
            }
        },
        [campaigns, campaignsData]
    )

    const renderCampaigns = useMemo(() => {
        if (campaignsData.length !== 0 && !searchValue) {
            const publishedCampaigns = campaignsData.filter(
                (campaign: ICampaign) => campaign.status === 'published'
            )
            const draftCampaigns = campaignsData.filter(
                (campaign: ICampaign) => campaign.status === 'draft'
            )
            const oldCampaign = campaignsData.filter((campaign: ICampaign) => !campaign.status)
            setProjects({ public: publishedCampaigns, draft: draftCampaigns, legacy: oldCampaign })

            if (tab === 0) {
                return campaigns
            }
            if (tab === 1) {
                return publishedCampaigns
            }
            if (tab === 2) {
                return draftCampaigns
            }
            if (tab === 3) {
                return oldCampaign
            }
        }
        if (searchValue) {
            return campaignsData
        }
    }, [campaignsData, searchValue, tab])

    const deleteCampaign = useCallback(
        async (data: ICampaign) => {
            const confirmed = await isConfirmed(
                `Are you sure you want to delete the project ${data.campaignName}?`
            )

            if (confirmed) {
                try {
                    await CampaignsService.deleteCampaign(
                        data._id,
                        data.campaignName, //tdoo: why pass up campaignName and username?
                        currentUser?.username
                    )
                    setCampaignsData((prev) =>
                        prev.filter((campaign) => campaign._id !== data._id)
                    )
                    Toast('Campaign deleted', 'success')
                } catch (error) {
                    console.log(error)
                    Toast(error.response.data.message, 'error')
                }
            }
        },
        [isConfirmed, currentUser]
    )

    const onMakePrimaryLink = useCallback(
        async (campaignId: string, campaignName: string) => {
            if (!currentUser?.username) {
                Toast('Please set your username first in the accounts section.', 'error')
                return
            }
            try {
                await CampaignsService.makePrimaryLink(currentUser?.username, campaignId)
                Toast(`Successfully set ${campaignName} to primary brand page.`, 'success')
            } catch (error) {
                console.log(error)
                Toast(error.response.data.message, 'error')
            }
        },
        [currentUser]
    )

    return (
        <DashboardWrapper>
            <SEO title="Dashboard" />
            <h1>My projects</h1>
            <DashboardHeader />
            <Analytics
                searchValue={searchValue}
                handleChangeSearchValue={handleChangeSearchValue}
                campaigns={campaigns}
            />
            <ProjectsHeader>
                <span>Projects</span>
            </ProjectsHeader>
            {!searchValue ? (
                <DashboardTabs tab={tab} projects={projects} handleChangeTab={handleChangeTab} />
            ) : (
                <SearchResult>Search result</SearchResult>
            )}
            {!renderCampaigns?.length && (
                <EmptyCampaign>You don't have any campaigns in this section yet</EmptyCampaign>
            )}
            <ProjectGridContainer>
                {renderCampaigns?.map((campaign) => {
                    // backward compatible with previous scan based on unique ip address
                    let scans = 0
                    if (campaign.ipAddresses) {
                        scans = campaign.ipAddresses.length
                    }

                    if (campaign.scans) {
                        scans = scans + campaign.scans
                    }

                    return (
                        <ProjectCard
                            key={campaign._id}
                            data={campaign}
                            scans={scans}
                            deleteCampaign={deleteCampaign}
                            makePrimaryLink={onMakePrimaryLink}
                        />
                    )
                })}
            </ProjectGridContainer>
            <ScrollToTopButton />
        </DashboardWrapper>
    )
}

export default Dashboard
