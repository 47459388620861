import React from 'react';
import { InputBox } from '../../common/InputBox';

interface Props {
    name: string;
    value?: string;
    placeholder: string;
    plugin: string;
    handleSettingsChange: (name: string, value: string, plugin: string) => void
}

const SettingInput = ({ name, placeholder, value, plugin, handleSettingsChange }: Props) => {
    return (
        <InputBox
            size="small"
            placeholder={placeholder}
            value={value}
            onChange={(e) => handleSettingsChange(name, e.target.value, plugin)}
            width="285px"
        />
    );
}

export default SettingInput
