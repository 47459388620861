import { ChangeEvent, useEffect, useState } from 'react'
import validator from 'validator'
import { useNavigate } from 'react-router-dom'

import { Toast } from '../../helpers/Toast'
import { InputBox } from '../../components/common/InputBox'
import ButtonSpinner from '../../components/common/ButtonSpiner'
import { Button } from '../../components/common/Button'
import { RouteNames } from '../../../router'
import AuthService from '../../../services/auth'
import { VerifyEmailWrapper, VerifyEmailContainer } from './styles'
import { useAuth } from 'widgets-base'


const VerifyEmail = () => {
    const [emailLoading, setEmailLoading] = useState<boolean>(false)
    const [email, setEmail] = useState<string>('')
    const [errors, setErrors] = useState<string[]>([])
    const navigate = useNavigate()
    const { currentUser } = useAuth()

    useEffect(() => {
        if (!currentUser) {
            navigate(RouteNames.LOGIN)
        }
    }, [currentUser, navigate])

    const handleTextChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setEmail(e.target.value)
    }

    const handleRedirectToLogin = () => {
        navigate(RouteNames.LOGIN)
    }

    const sendVerificationEmail = async () => {
        setErrors([])

        if (email === '' || email.length === 0 || !validator.isEmail(email)) {
            setErrors(['Please set valid email'])
            setEmailLoading(false)
            return
        }

        setEmailLoading(true)

        try {
            await AuthService.sendVerifyMail(email)
            Toast('Verification email sent!', 'success')
            setEmailLoading(false)
        } catch (err) {
            Toast('Something went wrong!', 'error')
        }

        setEmailLoading(false)
    }

    return (
        <VerifyEmailWrapper>
            <VerifyEmailContainer>
                <h1>Verify Email</h1>
                <p>You have been sent an verification email</p>
                <p>Verify email to login.</p>
                <Button type="button" variant="secondary" onClick={handleRedirectToLogin}>
                    Go to Login
                </Button>

                <InputBox
                    placeholder="email address"
                    name="email"
                    width="100%"
                    type="email"
                    onChange={handleTextChange}
                    value={email}
                />

                {errors.map((error, index) => {
                    return <p key={index}>{error}</p>
                })}

                <Button type="button" variant="primary" onClick={sendVerificationEmail}>
                    {emailLoading ? (
                        <ButtonSpinner height="25px" width="25px" />
                    ) : (
                        'Resend email verification'
                    )}
                </Button>
                <span>
                    <strong>Note: </strong>Your verification email may have landed in spam/junk.
                </span>
            </VerifyEmailContainer>
        </VerifyEmailWrapper>
    )
}

export default VerifyEmail
