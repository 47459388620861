import { Divider } from '@mui/material'
import { Link } from 'react-router-dom'
import { RouteNames } from '../../../router'
import { PageContainer } from './styles'

const DocTemplates = () => {
    return (
        <PageContainer>
            <h1>Custom templates</h1>
            <span>
                Ready-made templates for quick use on the{' '}
                <Link to={RouteNames.TEMPLATES}>
                    <strong>templates page.</strong>
                </Link>
            </span>
            <Divider />
            <div className="content">
                <p>
                    We try to save you time and money by creating more and more ready-made templates
                    when creating a campaign.
                </p>
                <h2>What are custom templates for?</h2>
                <p>
                    In addition to using our ready-made templates, we provide the ability to create
                    your own templates for reuse. Please note that custom templates are only
                    available on your profile and are not public for security purposes. We do not
                    recommend using draw widgets in templates, as draws have deadlines. Pay
                    attention to this, so as not to create a campaign with a drawing template that
                    has already ended.
                </p>
                <h2>Create first custom template</h2>
                <p>
                    Pre-build your campaign on the page builder using widgets and customizing them
                    to your liking. To create your first custom template, on the builder page,
                    select the Templates tab in the builder sidebar. A panel will open with two
                    tabs, Public and Private. On the Privat tab at the bottom of the panel, click
                    the Create template from current project button. Enter a name for the template
                    in the dialog box and click save.
                </p>
                <img className="image" src="/images/docs/template.png" alt="font" />
                <p>
                    Congratulations! You have created your first template. Now it will always be
                    available in this tab of the sidebar of the designer. Use templates by dragging
                    them into the canvas area of ​​the builder page. Please note that using a
                    template overwrites all current page settings and uses the settings that were
                    saved directly in the template.
                </p>
            </div>
        </PageContainer>
    )
}

export default DocTemplates
