import { FC, useState, ChangeEvent } from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import validator from 'validator'
import { checkInputValid } from '../../../../../helpers/validation'
import { InputBox } from '../../../../common/InputBox'
import { ToggleButton } from '../../../../common/ToggleButton'
import { WidgetSettingContainer } from '../../../../Design/WidgetSettingContainer'
import { useWidget } from 'widgets-base'

interface Props {
}

const RatingSetting: FC<Props> = () => {
    
    const { widget, setWidget } = useWidget();    
    const [error, setError] = useState<string>('')

    const starsSizes = widget?.appearanceSettings?.size?.map(
        (item: 'small' | 'medium' | 'large') => ({
            label: item,
            value: item,
        })
    )

    const defaultSize = {
        label: widget?.appearanceSettings?.defaultSize,
        value: widget?.appearanceSettings?.defaultSize,
    }

    const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newField = {
            ...widget,
            settings: { ...widget.settings, [e.target.name]: e.target.value },
        }

        setWidget(newField);
    }

    const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
        const newField = {
            ...widget,
            settings: { ...widget.settings, [e.target.name]: e.target.checked },
        }

        setWidget(newField);
    }

    const handleTextBlur = (value: string, attr: string) => () => {
        setError('')
        if (attr === 'name') {
            if (validator.isEmpty(value) || value === '') {
                setError('Input name can not be empty')
                return
            }
            if (!checkInputValid(value, 'alphanumericWithDashSpace')) {
                setError('Please set valid input name without special characters')
            }
        }
    }

    const handleChangeStarSize = (value: {
        label: 'small' | 'medium' | 'large'
        value: 'small' | 'medium' | 'large'
    }) => {
        const newField = {
            ...widget,
            appearanceSettings: { ...widget.appearanceSettings, defaultSize: value.value },
        }

        setWidget(newField);
    }

    return (
        <WidgetSettingContainer padding={0}>
            <InputBox
                label="Rating label"
                name="label"
                placeholder="Label name"
                onChange={handleTextChange}
                type="text"
                value={widget?.settings?.label}
                onBlur={handleTextBlur(widget?.settings?.label, 'label')}
            />
            <InputBox
                label="Field name"
                name="name"
                placeholder="Name"
                onChange={handleTextChange}
                type="text"
                value={widget?.settings?.name}
                onBlur={handleTextBlur(widget?.settings?.name, 'name')}
                error={error}
            />
            <SelectContainer>
                <label>Set stars size</label>
                <Select
                    options={starsSizes}
                    defaultValue={defaultSize}
                    onChange={handleChangeStarSize}
                />
            </SelectContainer>
            <FlexBox>
                <InputBox
                    label="Default rating"
                    width="90%"
                    name="defaultValue"
                    onChange={handleTextChange}
                    type="number"
                    step={widget?.settings?.defaultOption === 'stars' ? 0.5 : 1}
                    maxLength={widget?.settings?.numberOfRatings}
                    min={0}
                    value={widget?.settings?.defaultValue}
                />
                <InputBox
                    label="Max rating"
                    width="90%"
                    name="numberOfRatings"
                    onChange={handleTextChange}
                    type="number"
                    maxLength={10}
                    value={widget?.settings?.numberOfRatings}
                />
            </FlexBox>
            <ToggleContainer>
                <ToggleButton
                    name="required"
                    isToggled={widget?.settings?.required}
                    onChange={handleCheckbox}
                />
                <span>Field is required</span>
            </ToggleContainer>
        </WidgetSettingContainer>
    )
}

export default RatingSetting

const FlexBox = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    & > div:first-child {
        margin-right: 16px;
    }
`

const SelectContainer = styled.div`
    & > label {
        font-size: 14px;
        font-weight: bold;
        color: #3c4b61;
    }
    & > div {
        margin-top: 5px;
        & > div {
            background-color: #fafaff;
            border-radius: 10px;
            border-color: #dfe2f2;
        }
    }
`

export const ToggleContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 18px;

    & > span {
        font-size: 14px;
        font-weight: bold;
        color: #3c4b61;
        margin-bottom: 4px;
        margin-left: 10px;
    }
`
