//
// An editor for number values.
//

import { useEffect, useRef, useState } from "react";

export interface INumberInputProps {
    //
    // The name of the value.
    //
    name: string;

    //
    // The current value.
    // Set to undefined then value is indeterminate.
    //
    value?: number | string;

    //
    // Event raised to indicate the value changed.
    //
    onChange(name: string, value: number): void;

    //
    // Disables the input.
    //
    disabled?: boolean;
}

function isValidNumber(text: string): boolean {
    if (text.length === 0) {
        return false;
    }
    return /^\d*(\.\d+)?$/.test(text);
}

export function NumberInput({ name, value, onChange, disabled }: INumberInputProps) {

    const initialText = (value === undefined || disabled) ? "-" : value.toString();

    const [ text, setText ] = useState<string>(initialText);
    const [ error, setError ] = useState<boolean>(false);
    const isInitialMount = useRef(true);

    useEffect(() => {
        const updatedText = (value === undefined || disabled) ? "-" : value.toString();
        setText(updatedText);

    }, [value]);
    
    useEffect(() => {

        if (isInitialMount.current) {
            // Don't trigger the timeout on initial render.
            isInitialMount.current = false;
            return;
        }

        if (disabled || text === initialText || text === "-") {
            // No need to update when disabled.
            // Or when text hasn't changed.
            // Or when test indicates indeterminate value.
            return;
        }

        const timeout = setTimeout(() => {
            const trimmed = text.trim();
            if (isValidNumber(trimmed)) {
                const newValue = parseFloat(trimmed);
                if (value !== newValue) {
                    onChange(name, newValue);
                }

                setError(false);
            }
            else {
                setError(true);
            }
        }, 300);

        return () => {
            clearTimeout(timeout);
        };
    }, [text, onChange]);

    return (
        <input
            className="w-full"
            disabled={disabled}
            style={{
                border: error 
                    ? "1px solid red"
                    : "1px solid transparent",
            }}
            type="text"
            name={name}
            value={text}
            onChange={event => {
                setText(event.target.value);
            }}
            />
    );
}
