export interface IPlayer {
    email: string
    isActivated: boolean
    id: string
}

export enum UserRoles {
    FREE = 'free',
    LITE = 'lite',
    PREMIUM = 'premium',
    ADMIN = 'admin',
}

export interface IUserStripe {
    createdAt: number
    finishedAt: number
    customerId: string
    subscriptionId: string
    planDetails?: {
        id: string
        object: string
        active: boolean
        aggregate_usage: null | string
        amount: number
        amount_decimal: string
        billing_scheme: string
        created: number
        currency: string
        interval: string
        interval_count: number
        livemode: boolean
        metadata: {}
        nickname: null | string
        product: string
        tiers: null | number
        tiers_mode: null | string
        transform_usage: null | string
        trial_period_days: null | number
        usage_type: string
    }
}

export interface ISocial {
    facebook?: string
    instagram?: string
    linkedin?: string
    twitter?: string
    pinterest?: string
    spotify?: string
    tiktok?: string
    whatsapp?: string
    youtube?: string
}

export interface ISignupForm {
    firstname: string
    lastname: string
    email: string
    username: string
    company: string
    password: string
    confirmPassword: string
    termsAndConditions: boolean
}

export interface IUser {
    _id: string;
    uid?: string
    firstname: string
    lastname: string
    email: string
    onboardingStatus: string
    role: string
    avatar: string
    autosave?: boolean
    business?: string
    projectId?: string
    isAdmin?: boolean
    social?: ISocial
    username: string
    lastUpdated?: string
    stripe?: IUserStripe
}

export interface IGoogleUser {
    uid: string
    firstname: string
    lastname: string
    email: string
    avatar: string
    token?: string
}

export interface IPassword {
    password: string
    confirmPassword: string
}
