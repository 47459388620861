import { FC } from 'react'
import styled from 'styled-components'
import { useWidget } from 'widgets-base';

interface Props {
    align: string
}

const Orientation: FC<Props> = ({ align }) => {

    const { widget, setWidget } = useWidget();

    const onChangeAlign = (alignment: string) => () => {
        const newWidget = {
            ...widget,
            appearanceSettings: {
                ...widget.appearanceSettings,
                align: alignment,
            },
        }

        setWidget(newWidget);
    }

    return (
        <Container>
            <label>Orientation</label>
            <div className="blocks">
                <div
                    className={align === 'flex-start' ? 'active_option' : 'align_option'}
                    onClick={onChangeAlign('flex-start')}
                >
                    <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect
                            width="1.07692"
                            height="14"
                            fill={align === 'flex-start' ? '#6478F9' : '#717F95'}
                        />
                        <rect
                            x="3.23077"
                            y="3.23047"
                            width="10.7692"
                            height="2.15385"
                            fill={align === 'flex-start' ? '#6478F9' : '#717F95'}
                        />
                        <rect
                            x="3.23077"
                            y="8.61523"
                            width="6.46154"
                            height="2.15385"
                            fill={align === 'flex-start' ? '#6478F9' : '#717F95'}
                        />
                    </svg>
                </div>

                <div
                    className={align === 'center' ? 'active_option' : 'align_option'}
                    onClick={onChangeAlign('center')}
                >
                    <svg
                        width="12"
                        height="15"
                        viewBox="0 0 12 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect
                            x="5.45453"
                            width="1.09091"
                            height="14.1818"
                            fill={align === 'center' ? '#6478F9' : '#717F95'}
                        />
                        <rect
                            y="2.72754"
                            width="12"
                            height="2.18182"
                            fill={align === 'center' ? '#6478F9' : '#717F95'}
                        />
                        <rect
                            x="2.18182"
                            y="8.18164"
                            width="7.63636"
                            height="2.18182"
                            fill={align === 'center' ? '#6478F9' : '#717F95'}
                        />
                    </svg>
                </div>

                <div
                    className={align === 'flex-end' ? 'active_option' : 'align_option'}
                    onClick={onChangeAlign('flex-end')}
                >
                    <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect
                            width="1.07692"
                            height="14"
                            transform="matrix(-1 0 0 1 14 0)"
                            fill={align === 'flex-end' ? '#6478F9' : '#717F95'}
                        />
                        <rect
                            width="10.7692"
                            height="2.15385"
                            transform="matrix(-1 0 0 1 10.7692 3.23047)"
                            fill={align === 'flex-end' ? '#6478F9' : '#717F95'}
                        />
                        <rect
                            width="6.46154"
                            height="2.15385"
                            transform="matrix(-1 0 0 1 10.7692 8.61523)"
                            fill={align === 'flex-end' ? '#6478F9' : '#717F95'}
                        />
                    </svg>
                </div>
            </div>
        </Container>
    )
}

export default Orientation

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #717f95;

    & > label {
        font-size: 12px;
        color: #3c4b61;
        margin-bottom: 7px;
        font-weight: bold;
    }

    .blocks {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .align_option {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #717f95;
        border-radius: 8px;
        cursor: pointer;
        svg {
            width: 23px;
        }
    }

    .active_option {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #6478f9;
        border-radius: 8px;
        cursor: pointer;
    }
`
