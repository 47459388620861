import React from "react";
import { PreviewWidget } from "./preview-widget";
import { IWidget } from "widgets-base";
import { WidgetContextProvider } from "../../../builder/context/widget";

export interface IPreviewWidgetsProps {
    //
    // The group of widgets to render.
    //
    widgets?: IWidget[];
}

//
// Renders a list of widgets for preview.
//
export function PreviewWidgets({ widgets }: IPreviewWidgetsProps ) {

    return (
        <>
            {widgets?.map(widget => {
                return (
                    <WidgetContextProvider
                        key={widget.id} 
                        widget={widget}
                        widgetPath={undefined}
                        >
                        <PreviewWidget />
                    </WidgetContextProvider>
                );
            })}
        </>
    );
}
