import { ReactNode } from "react";
import { IRenderWidgetHook, IWidget, RenderWidgetContext } from "widgets-base";
import { WidgetContextProvider } from "./widget";
import { StaticWidget } from "../../v1/components/new/static-widget";
import { MoveableWidget } from "../../v1/components/new/moveable-widget";
import { RenderTemplateWidget } from "../../v1/components/new/render-template-widget";

export interface IRenderWidgetContextProviderProps {
    children: ReactNode | ReactNode[];
}

export function RenderWidgetContextProvider({ children }: IRenderWidgetContextProviderProps) {
    
    //
    // Renders a widget statically for deployment.
    //
    function renderStatic(widget: IWidget): JSX.Element {
        return (
            <WidgetContextProvider
                key={`${widget.id}-${widget.lastUpdate}`}
                widget={widget}
                >
                <StaticWidget
                    key={`${widget.id}-${widget.lastUpdate}`}
                />
            </WidgetContextProvider>
        );
    }

    //
    // Renders a widget for preview.
    //
    function renderPreview(widget: IWidget): JSX.Element {
        return (
            <WidgetContextProvider
                key={`${widget.id}-${widget.lastUpdate}`}
                widget={widget}
                >
                {/* todo: There should be a seperate "preview widget" path for this. */}
                <StaticWidget
                    key={`${widget.id}-${widget.lastUpdate}`}
                />
            </WidgetContextProvider>
        );        
    }

    //
    // Renders a widget in the editor.
    //
    function renderEditor(widget: IWidget, parentWidgetPath: number[], widgetIndex: number): JSX.Element {
        return (
            <WidgetContextProvider
                key={`${widget.id}-${widget.lastUpdate}`}
                widget={widget}
                widgetPath={parentWidgetPath.concat([widgetIndex])}
                disableDrag={true}
                disableDrop={true}
                >
                {/* todo: rename MoveableWidget to EditorWidget */}
                <MoveableWidget
                    key={`${widget.id}-${widget.lastUpdate}`}
                    parentDirection="column"
                />
            </WidgetContextProvider>
        );    
    }

    //
    // Renders a widget as a template..
    //
    function renderTemplate(widget: IWidget): JSX.Element {
        //todo: the way template widgets are rendered should be consistant and logical.
        return (
            <WidgetContextProvider 
                widget={widget}
                widgetPath={undefined}
                >
                <RenderTemplateWidget />
            </WidgetContextProvider>        
        );
    }

    const value: IRenderWidgetHook = {
        renderStatic,
        renderPreview,
        renderEditor,
        renderTemplate,
    };
    
    return (
        <RenderWidgetContext.Provider 
            value={value}
        >
            {children}
        </RenderWidgetContext.Provider>
    );
}
