import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import CampaignsService from '../../../services/CampaignsService'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { EmbedWrapper } from './styles'
import { ICampaign } from 'widgets-base'

const EmbedDraw = () => {
    const { campaignId } = useParams()
    const [loading, setLoading] = useState<boolean>(false)
    const [campaign, setCampaign] = useState<ICampaign>()

    useEffect(() => {
        setLoading(true)
        CampaignsService.getFreePublicCampaign(campaignId)
            .then(({ data }) => {
                setCampaign(data)
                setLoading(false)
            })
            .catch((error) => {
                console.log(error)
                setLoading(false)
            })
    }, [campaignId])

    return (
        <EmbedWrapper>
            <img width={100} src="/icons/color_icon.svg" alt="logo" />
            {loading ? (
                <p>Loading...</p>
            ) : (
                <div className="description">
                    <p>{campaign?.campaignName}</p>
                    <img width={250} src={campaign?.metaImageUrl} alt="preview" />
                    {campaign?.campaignDescription && <span>{campaign?.campaignDescription}</span>}
                    <a href={campaign?.deployUrl} target="_blank" rel="noreferrer">
                        View campaign <KeyboardArrowRightIcon />
                    </a>
                </div>
            )}
        </EmbedWrapper>
    )
}

export default EmbedDraw
