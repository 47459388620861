// import firebase from 'firebase/app'
import { Toast } from '../../helpers/Toast'
import { IPassData } from '../../modules/widgets/general/IPassData'

export const generatePass = async ({
    email,
    firstName,
    lastName,
    mobile,
    profileImage,
    companyLink,
}: IPassData) => {
    //
    // This broke after upgrade to Firebase.
    // In anycase we don't run the Firebase functions any more so I have to figure out how to do this?
    //
    // let getpass = firebase.functions().httpsCallable('getWalletPass')
    // return getpass({
    //     expirationDate: '2032-12-31T23:00:15+08:00',
    //     foregroundColor: 'rgb(0, 0, 0)',
    //     backgroundColor: 'rgb(255, 255, 255)',
    //     labelColor: 'rgb(100, 120, 249)',
    //     sharingProhibited: false,
    //     barcode: {
    //         message: companyLink ? companyLink : 'https://www.deployable.co',
    //     },
    //     fields: [
    //         {
    //             key: 'field4',
    //             value: firstName + ' ' + lastName,
    //         },
    //         {
    //             key: 'field7',
    //             value: email,
    //         },
    //         {
    //             key: 'field6',
    //             value: mobile ? mobile : '0912345678',
    //         },
    //     ],
    //     images: [
    //         {
    //             type: 'logo',
    //             hex: 'b0b51d84403941c844d104d25bda5122bd9627d9', // deployable logo
    //         },
    //     ],
    // })
    //     .then((result) => {
    //         return { data: result.data }
    //     })
    //     .catch((error) => {
    //         Toast(error.message, 'error')
    //     })
}
