import { Box, Tab, Tabs } from '@mui/material'
import { useState, SyntheticEvent, ReactNode, FC } from 'react'
import { debugModeState } from '../../store/builder/builder'
import { useRecoilState } from 'recoil'

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
        </div>
    )
}

const a11yProps = (index: number) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

interface SidebarTabsProps {
    setting: ReactNode
    design: ReactNode
}

const SidebarTabs: FC<SidebarTabsProps> = ({ setting, design }) => {
    const [tab, setTab] = useState(0);

    const handleChange = (_event: SyntheticEvent, newValue: number) => {
        setTab(newValue)
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={handleChange} aria-label="basic tabs">
                    <Tab label="Settings" {...a11yProps(0)} />
                    <Tab label="Design" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={tab} index={0}>
                {setting}
            </TabPanel>
            <TabPanel value={tab} index={1}>
                {design}
            </TabPanel>
        </Box>
    )
}

export default SidebarTabs
