import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined'
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined'
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined'
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined'
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined'
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined'
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined'
import AppSettingsAltOutlinedIcon from '@mui/icons-material/AppSettingsAltOutlined'
import AutoAwesomeMosaicOutlinedIcon from '@mui/icons-material/AutoAwesomeMosaicOutlined'
import FormatColorFillOutlinedIcon from '@mui/icons-material/FormatColorFillOutlined'
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined'
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined'
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined'
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined'
import FontDownloadOutlinedIcon from '@mui/icons-material/FontDownloadOutlined'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined'
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined'
import { RouteNames } from '../../../router'

export const treeData = [
    {
        key: 'documentation',
        label: 'Documentation',
        icon: <FindInPageOutlinedIcon />,
        path: RouteNames.DOC,
        nodes: [],
    },
    {
        key: 'get-started',
        label: 'Get started',
        icon: <FormatListNumberedOutlinedIcon />,
        path: null,
        nodes: [
            {
                key: 'about-platform',
                label: 'About platform',
                icon: <InfoOutlinedIcon />,
                path: RouteNames.DOC_ABOUT,
                nodes: [],
            },
            {
                key: 'create-first-campaign',
                label: 'Create first campaign',
                icon: <AddBoxOutlinedIcon />,
                path: null,
                nodes: [
                    {
                        key: 'add-campaign',
                        label: 'Add new campaign',
                        icon: <NoteAddOutlinedIcon />,
                        path: RouteNames.DOC_CREATE_CAMPAIGN,
                        nodes: [],
                    },
                    {
                        key: 'builder-functions',
                        label: 'Builder functions',
                        icon: <ConstructionOutlinedIcon />,
                        path: RouteNames.DOC_BUILDER,
                        nodes: [],
                    },
                    {
                        key: 'drag-and-drop-widgets',
                        label: 'Drag and drop widgets',
                        icon: <WidgetsOutlinedIcon />,
                        path: RouteNames.DOC_WIDGETS,
                        nodes: [],
                    },
                    {
                        key: 'widget-edit-mode',
                        label: 'Widget edit mode',
                        icon: <FormatColorFillOutlinedIcon />,
                        path: RouteNames.DOC_EDIT_MODE,
                        nodes: [],
                    },
                ],
            },
            {
                key: 'brand-setting',
                label: 'Brand setting',
                icon: <AppSettingsAltOutlinedIcon />,
                path: null,
                nodes: [
                    {
                        key: 'assets',
                        label: 'Assets',
                        icon: <CollectionsOutlinedIcon />,
                        path: RouteNames.DOC_BRAND_ASSETS,
                        nodes: [],
                    },
                    {
                        key: 'fonts',
                        label: 'Fonts',
                        icon: <FontDownloadOutlinedIcon />,
                        path: RouteNames.DOC_BRAND_FONTS,
                        nodes: [],
                    },
                    {
                        key: 'social',
                        label: 'Social',
                        icon: <FacebookOutlinedIcon />,
                        path: RouteNames.DOC_BRAND_SOCIAL,
                        nodes: [],
                    },
                ],
            },
            {
                key: 'templates',
                label: 'Custom templates',
                icon: <AutoAwesomeMosaicOutlinedIcon />,
                path: RouteNames.DOC_TEMPLATES,
                nodes: [],
            },
            {
                key: 'billing',
                label: 'Billing',
                icon: <CreditCardOutlinedIcon />,
                path: RouteNames.DOC_BILLING,
                nodes: [],
            },
        ],
    },
    {
        key: 'integrations',
        label: 'Integrations',
        icon: <IntegrationInstructionsOutlinedIcon />,
        path: null,
        nodes: [
            {
                key: 'slack-integration',
                label: 'Slack integration',
                icon: (
                    <svg
                        width={20}
                        enableBackground="new 0 0 2447.6 2452.5"
                        viewBox="0 0 2447.6 2452.5"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clipRule="evenodd" fillRule="evenodd">
                            <path
                                d="m897.4 0c-135.3.1-244.8 109.9-244.7 245.2-.1 135.3 109.5 245.1 244.8 245.2h244.8v-245.1c.1-135.3-109.5-245.1-244.9-245.3.1 0 .1 0 0 0m0 654h-652.6c-135.3.1-244.9 109.9-244.8 245.2-.2 135.3 109.4 245.1 244.7 245.3h652.7c135.3-.1 244.9-109.9 244.8-245.2.1-135.4-109.5-245.2-244.8-245.3z"
                                fill="#36c5f0"
                            />
                            <path
                                d="m2447.6 899.2c.1-135.3-109.5-245.1-244.8-245.2-135.3.1-244.9 109.9-244.8 245.2v245.3h244.8c135.3-.1 244.9-109.9 244.8-245.3zm-652.7 0v-654c.1-135.2-109.4-245-244.7-245.2-135.3.1-244.9 109.9-244.8 245.2v654c-.2 135.3 109.4 245.1 244.7 245.3 135.3-.1 244.9-109.9 244.8-245.3z"
                                fill="#2eb67d"
                            />
                            <path
                                d="m1550.1 2452.5c135.3-.1 244.9-109.9 244.8-245.2.1-135.3-109.5-245.1-244.8-245.2h-244.8v245.2c-.1 135.2 109.5 245 244.8 245.2zm0-654.1h652.7c135.3-.1 244.9-109.9 244.8-245.2.2-135.3-109.4-245.1-244.7-245.3h-652.7c-135.3.1-244.9 109.9-244.8 245.2-.1 135.4 109.4 245.2 244.7 245.3z"
                                fill="#ecb22e"
                            />
                            <path
                                d="m0 1553.2c-.1 135.3 109.5 245.1 244.8 245.2 135.3-.1 244.9-109.9 244.8-245.2v-245.2h-244.8c-135.3.1-244.9 109.9-244.8 245.2zm652.7 0v654c-.2 135.3 109.4 245.1 244.7 245.3 135.3-.1 244.9-109.9 244.8-245.2v-653.9c.2-135.3-109.4-245.1-244.7-245.3-135.4 0-244.9 109.8-244.8 245.1 0 0 0 .1 0 0"
                                fill="#e01e5a"
                            />
                        </g>
                    </svg>
                ),
                path: RouteNames.DOC_SLACK,
                nodes: [],
            },
        ],
    },
    {
        key: 'account',
        label: 'Account',
        icon: <ManageAccountsOutlinedIcon />,
        path: RouteNames.DOC_ACCOUNT,
        nodes: [],
    },
    {
        key: 'embedding',
        label: 'Embedding',
        icon: <IosShareOutlinedIcon />,
        path: null,
        nodes: [
            {
                key: 'embed-campaign',
                label: 'Embed campaign',
                icon: <AppSettingsAltOutlinedIcon />,
                path: RouteNames.DOC_EMBED_CAMPAIGN,
                nodes: [],
            },            
        ],
    },
]