import { Divider } from '@mui/material'
import { FC } from 'react'
import { WidgetSettingContainer } from '../../components/Design/WidgetSettingContainer'
import {
    Alignment,
    BorderRadius,
    Colors,
    Fonts,
    Margins,
    Paddings,
    Sizer,
    Width,
} from '../../components/EditMode/Design'
import FontSizer from '../../components/EditMode/Design/FontSizer'
import Spacing from '../../components/EditMode/Design/Spacing'
import { useWidget } from 'widgets-base'

interface Props {
}

const Hero1DesignSettings: FC<Props> = () => {

    const { widget } = useWidget();

    return (
        <WidgetSettingContainer padding={18}>
            <div style={{ display: 'flex', gap: '10px' }}>
                <Fonts
                    label="Select font"
                    defaultFont={widget?.appearanceSettings?.font?.family}
                />
                <Sizer
                    label="Font size"
                    value={widget?.appearanceSettings?.font?.size}
                    name="size"
                />
            </div>
            <Divider />
            <Alignment align={widget?.appearanceSettings?.align} />
            <div style={{ display: 'flex', gap: '10px' }}>
                <FontSizer
                    label="Set line height for texts"
                    value={widget.appearanceSettings.font?.lineHeight}
                    name="lineHeight"
                />
                <Spacing
                    
                    label="Set Spacing"
                    value={widget.appearanceSettings.font.spacing}
                />
            </div>
            <Divider />
            <Width label="Image width"  width={widget?.appearanceSettings?.width} />
            <Margins
                topValue={widget?.appearanceSettings?.marginTop}
                bottomValue={widget?.appearanceSettings?.marginBottom}
                leftValue={widget?.appearanceSettings?.marginLeft}
                rightValue={widget?.appearanceSettings?.marginRight}
            />
            <Paddings
                topValue={widget?.appearanceSettings?.paddingTop}
                bottomValue={widget?.appearanceSettings?.paddingBottom}
                leftValue={widget?.appearanceSettings?.paddingLeft}
                rightValue={widget?.appearanceSettings?.paddingRight}
            />
            <Divider />
            <BorderRadius
                topRightValue={widget?.appearanceSettings?.borderTopRightRadius}
                bottomRightValue={widget?.appearanceSettings?.borderBottomRightRadius}
                topLeftValue={widget?.appearanceSettings?.borderTopLeftRadius}
                bottomLeftValue={widget?.appearanceSettings?.borderBottomLeftRadius}
            />
            <Divider />
            <Colors colors={widget?.colourSettings}  />
        </WidgetSettingContainer>
    )
}

export default Hero1DesignSettings
