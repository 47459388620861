import { IPageTheme } from "widgets-base";
import { defaultPageTheme } from "../../builder/context/page-theme";
import { extractRandomElement } from "../../builder/lib/utils";
import * as _ from "lodash";

const colorSets = [
    {
      background: '#fffffe',
      headline: '#181818',
      paragraph: '#2e2e2e',
      button: '#4fc4cf',
      'button text': '#181818'
    },
    {
      background: '#fffffe',
      headline: '#00214d',
      paragraph: '#1b2d45',
      button: '#00ebc7',
      'button text': '#00214d'
    },
    {
      background: '#fffffe',
      headline: '#094067',
      paragraph: '#5f6c7b',
      button: '#3da9fc',
      'button text': '#fffffe'
    },
    {
      background: '#16161a',
      headline: '#fffffe',
      paragraph: '#94a1b2',
      button: '#7f5af0',
      'button text': '#fffffe'
    },
    {
      background: '#f2f7f5',
      headline: '#00473e',
      paragraph: '#475d5b',
      button: '#faae2b',
      'button text': '#00473e'
    },
    {
      background: '#fffffe',
      headline: '#2b2c34',
      paragraph: '#2b2c34',
      button: '#6246ea',
      'button text': '#fffffe'
    },
    {
      background: '#fec7d7',
      headline: '#0e172c',
      paragraph: '#0e172c',
      button: '#0e172c',
      'button text': '#fffffe'
    },
    {
      background: '#f8f5f2',
      headline: '#232323',
      paragraph: '#222525',
      button: '#078080',
      'button text': '#232323'
    },
    {
      background: '#eff0f3',
      headline: '#0d0d0d',
      paragraph: '#2a2a2a',
      button: '#ff8e3c',
      'button text': '#0d0d0d'
    },
    {
      background: '#004643',
      headline: '#fffffe',
      paragraph: '#abd1c6',
      button: '#f9bc60',
      'button text': '#001e1d'
    },
    {
      background: '#f9f4ef',
      headline: '#020826',
      paragraph: '#716040',
      button: '#8c7851',
      'button text': '#fffffe'
    },
    {
      background: '#232946',
      headline: '#fffffe',
      paragraph: '#b8c1ec',
      button: '#eebbc3',
      'button text': '#232946'
    },
    {
      background: '#0f0e17',
      headline: '#fffffe',
      paragraph: '#a7a9be',
      button: '#ff8906',
      'button text': '#fffffe'
    },
    {
      background: '#fffffe',
      headline: '#272343',
      paragraph: '#2d334a',
      button: '#ffd803',
      'button text': '#272343'
    },
    {
      background: '#faeee7',
      headline: '#33272a',
      paragraph: '#594a4e',
      button: '#ff8ba7',
      'button text': '#33272a'
    },
    {
      background: '#55423d',
      headline: '#fffffe',
      paragraph: '#fff3ec',
      button: '#ffc0ad',
      'button text': '#271c19'
    },
    {
      background: '#fef6e4',
      headline: '#001858',
      paragraph: '#172c66',
      button: '#f582ae',
      'button text': '#001858'
    }
]

export const allColorThemes = colorSets.map((colorSet) => {
    const theme: IPageTheme = _.merge({}, defaultPageTheme, {
        colors: {
            body: {
                default: {
                    color: colorSet.paragraph,
                    backgroundColor: colorSet.background,
                },
            },
            elements: {
                group: {
                    inverted: {
                        color: colorSet.background,
                        backgroundColor: colorSet.paragraph,
                    },
                },
                h1: {
                    default: {
                        color: colorSet.headline,
                    },
                },
                button: {
                    default: {
                        color: colorSet['button text'],
                        backgroundColor: colorSet.button,
                    },
                    primary: {
                        color: colorSet['button text'],
                        backgroundColor: colorSet.button,
                    },
                    secondary: {
                        color: colorSet['button text'],
                        backgroundColor: colorSet.button,
                    },
                    link: {
                        color: colorSet['button text'],
                        backgroundColor: colorSet.button,
                    },
                    upload: {
                        color: colorSet['button text'],
                        backgroundColor: colorSet.button,
                    },
                    submit: {
                        color: colorSet['button text'],
                        backgroundColor: colorSet.button,
                    },
                },
                input: {
                    default: {
                        borderColor: "gray",
                    },
                },
                textarea: {
                    default: {
                        borderColor: "gray",
                    },
                },
            },
        },
    });

    return theme;
});

//
// Generate a theme from one color.
//
export function generateThemeFromColor(color: string) {

    const theme: IPageTheme = {
        colors: {
            body: {
                default: {
                    color: "black",
                    backgroundColor: "white",
                },
            },
            elements: {
                group: {
                    inverted: {
                        color: "white",
                        backgroundColor: color,
                    },
                },
                h1: {
                    default: {
                        color: color,
                    },
                },
                button: {
                    default: {
                        color: "black",
                        backgroundColor: "white",
                    },
                    primary: {
                        color: "white",
                        backgroundColor: color,
                    },
                    secondary: {
                        color: "black",
                        backgroundColor: "white",
                    },
                    link: {
                        color: color,
                    },
                    upload: {
                        color: "black",
                        backgroundColor: "white",
                    },
                    submit: {
                        color: "white",
                        backgroundColor: color,
                    },
                },
                input: {
                    default: {
                        borderColor: "gray",
                    },
                },
                textarea: {
                    default: {
                        borderColor: "gray",
                    },
                },
            },
        },
        fonts: {
            body: {
            },

            elements: {
                h1: {
                    default: {
                        fontSize: "3.5em",
                        fontWeight: "700",
                        lineHeight: "120%",
                    },
                },
                h2: {
                    default: {
                        fontSize: "2.75em",
                    },
                },
            },
        },
        structure: {
            elements: {
                button: {
                    default: {
                        paddingTop: "1em",
                        paddingBottom: "1em",
                        paddingLeft: "2em",
                        paddingRight: "2em",
                    },
                },
                input: {
                    default: {
                        paddingTop: ".2em",
                        paddingBottom: ".2em",
                        paddingLeft: ".2em",
                        paddingRight: ".2em",
                        borderStyle: "solid",
                        borderLeftWidth: ".1em",
                        borderRightWidth: ".1em",
                        borderTopWidth: ".1em",
                        borderBottomWidth: ".1em",
                    },
                },
                textarea: {
                    default: {
                        paddingTop: ".2em",
                        paddingBottom: ".2em",
                        paddingLeft: ".2em",
                        paddingRight: ".2em",
                        borderStyle: "solid",
                        borderLeftWidth: ".1em",
                        borderRightWidth: ".1em",
                        borderTopWidth: ".1em",
                        borderBottomWidth: ".1em",
                    },
                },
            },
        },
    };

    return theme;
}

const headerFonts = [
    "'Playfair Display', serif",
    "'Roboto Slab', serif",
    "'Oswald', sans-serif",
    "'Montserrat', sans-serif",
    "'Raleway', sans-serif",
];

const subHeaderFonts = [
    "'Lato', sans-serif",
    "'Open Sans', sans-serif",
    "'Josefin Sans', sans-serif",
    "'Nunito', sans-seri",
    "'Montserrat', sans-serif",
];

const bodyFonts = [
    "'Roboto', sans-serif",
    "'Merriweather', serif",
    "'Noto Sans', sans-serif",
    "'Tinos', serif",
    "'Gabarito', sans-serif",
];

export interface IFontSelection {
    headers: string[];
    subHeaders: string[];
    body: string[];
}

//
// Default selection of fonts the ai-generator can choose from.
//
export const defaultFontSelection: IFontSelection = {
    headers: headerFonts,
    subHeaders: subHeaderFonts,
    body: bodyFonts,
}

//
// Generate a theme from one color.
//
export function generateFontTheme(fontSelection: IFontSelection) {

    const theme: IPageTheme = {
        fonts: {
            body: {
                fontFamily: extractRandomElement(fontSelection.body),
            },

            elements: {
                h1: {
                    default: {
                        fontFamily: extractRandomElement(fontSelection.headers),
                    },
                },
                h2: {
                    default: {
                        fontFamily: extractRandomElement(fontSelection.subHeaders),
                    },
                },
            },
        },
    };

    return theme;
}

//
// Merge two themes together.
//
export function mergeThemes(theme1?: IPageTheme, theme2?: IPageTheme): IPageTheme {

    if (!theme1) {
        return theme2;
    }

    if (!theme2) {
        return theme1;
    }

    const merged = _.merge({}, theme1, theme2);
    return merged;
}