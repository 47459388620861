import { FC, Fragment } from 'react'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { ISubmission } from '../../modules/ISubmission'
import { IFields } from '../../modules/widgets/forms/IForm'
import { useAuth } from 'widgets-base'


interface Props {
    fields: IFields[]
    submissions: ISubmission[]
    removeField: (id: string) => () => Promise<void>
}

const TableBodyFields: FC<Props> = ({ fields, submissions, removeField }) => {

    const { makeFormUploadUrl } = useAuth();

    return (
        <Fragment>
            {submissions.map((submission) => {
                return (
                    <tr key={submission._id}>
                        <td className="table__body-item">
                            {new Date(submission.createdAt).toDateString()}
                        </td>
                        {fields.map((field, index) => {
                            if (!Array.isArray(submission.fields)) {
                                return (
                                    <td
                                        key={`${submission._id}-${index}`}
                                        className="table__body-item"
                                    ></td>
                                )
                            }
                            const fieldHeader = field.fieldName
                            const fieldIndex = submission.fields.findIndex(
                                (f) => f.fieldName === fieldHeader
                            )
                            if (fieldIndex === -1) {
                                return (
                                    <td
                                        key={`${submission._id}-${index}`}
                                        className="table__body-item"
                                    ></td>
                                )
                            }
                            const fieldValue = submission.fields[fieldIndex].value;
                            const fieldType = submission.fields[fieldIndex].fieldType;
                            if (Array.isArray(fieldValue)) {
                                if (fieldType === 'checkbox') {
                                    let checkboxString = ''
                                    fieldValue.forEach((val) => {
                                        checkboxString = checkboxString + `<p>${val}</p>`
                                    })
                                    return (
                                        <td
                                            className="table__body-item"
                                            key={`${submission._id}-${index}`}
                                            dangerouslySetInnerHTML={{
                                                __html: checkboxString,
                                            }}
                                        ></td>
                                    )
                                } else if (fieldType === 'upload' || fieldType === 'file-upload') {
                                    return (
                                        <td
                                            className="table__body-item"
                                            key={`${submission._id}-${index}`}
                                        >
                                            {fieldValue?.map((val) => {
                                                return (
                                                    <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        key={val?.fileName}
                                                        className="underline text-blue-500"
                                                        href={val?.url || makeFormUploadUrl(val?.assetId)}
                                                    >
                                                        {val?.fileName || val?.originalName}
                                                    </a>
                                                )
                                            })}
                                        </td>
                                    )
                                }
                            } else if (fieldType === 'reward') {
                                const isNotWins = submission.fields.some(
                                    (field) => field.value === 'no win'
                                )
                                return (
                                    <td
                                        className="table__body-item"
                                        key={`${submission._id}-${index}`}
                                        style={{
                                            color: isNotWins ? 'red' : 'green',
                                        }}
                                    >
                                        {fieldValue?.toString()}
                                    </td>
                                )
                            } else {
                                return (
                                    <td
                                        className="table__body-item"
                                        key={`${submission._id}-${index}`}
                                    >
                                        {fieldValue?.toString() || 'no data'}
                                    </td>
                                )
                            }
                            return (
                                <td className="table__body-item" key={`${submission._id}-${index}`}>
                                    non value
                                </td>
                            )
                        })}
                        <td className="table__body-item" onClick={removeField(submission._id)}>
                            <DeleteOutlineOutlinedIcon />
                        </td>
                    </tr>
                )
            })}
        </Fragment>
    )
}

export default TableBodyFields
