import {Divider} from '@mui/material'
import {ChangeEvent, FC} from 'react'
import {InputBox} from '../../common/InputBox'
import {WidgetSettingContainer} from '../../Design/WidgetSettingContainer'
import AssetsSetting from './AssetsSetting'
import {ToggleButton} from "../../common/ToggleButton";
import { useWidget } from 'widgets-base'
import { useCampaign } from '../../../../builder/context/campaign'

interface Props {
}

const NavbarGeneralSetting: FC<Props> = () => {

    const { campaign, updateCampaign } = useCampaign();
    const { widget, setWidget } = useWidget();

    const onChangeBurger = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.checked
        const name = event.target.name

        const newWidget = {
            ...widget,
            appearanceSettings: {
                ...widget.appearanceSettings,
                [name]: value,
            },
        
        }
        setWidget(newWidget);
    }

    const onChangePageName = (event: ChangeEvent<HTMLInputElement>, index: number) => {
        const newName = event.target.value
        const updatedPage = {...campaign.views[index], name: newName}
        const newPages = [
            ...campaign.views.slice(0, index),
            updatedPage,
            ...campaign.views.slice(index + 1),
        ]

        updateCampaign({
            views: newPages,
        });
    }

    return (
        <WidgetSettingContainer padding={0}>
            <AssetsSetting label="Upload your logo"/>
            <Divider/>
            <ToggleButton onChange={onChangeBurger} label='Create hamburger menu' name='isBurger'/>
            {campaign.views.map((page, index) => (
                <div key={page.id}>
                    <InputBox
                        label={`Set ${index + 1} page name`}
                        value={page.name}
                        name="name"
                        type="text"
                        onChange={(event) => onChangePageName(event, index)}
                    />
                </div>
            ))}
        </WidgetSettingContainer>
    )
}

export default NavbarGeneralSetting
