import { IconButton } from "@mui/material";
import { PropertyContextProvider, useProperty } from "../../context/property";
import { IPropertyType } from "../property-type";
import DeleteIcon from '@mui/icons-material/Delete';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { PropertyEditor } from "../property-editor";
import hash from "object-hash";

//
// An editor for array properties.
//
function ProperyArrayEditor() {

    const { property, propertyValue, setPropertyValue } = useProperty();

    return (
        <div className="flex flex-col w-full text-base">
            <div className="flex flex-col w-full">
                {propertyValue.map((elementValue, valueIndex) => {
                    return (
                        <div 
                            className="flex flex-row w-full border-b border-gray-200 border-solid p-1 pt-4 pb-4"
                            key={hash(elementValue)}
                            >
                            <div 
                                className="flex flex-col flex-grow mr-4" 
                                >
                                {property.properties.map((property, propertyIndex) => {
                                    return (
                                        <div 
                                            className="flex flex-row w-full mb-2 pl-2"
                                            key={propertyIndex}
                                            >
                                            <div className="flex-grow">
                                                <PropertyContextProvider                                                
                                                    parentIndex={valueIndex}
                                                    property={property}
                                                    >
                                                    <PropertyEditor />
                                                </PropertyContextProvider>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div>
                                <IconButton
                                    size="small"
                                    color="error"
                                    >
                                    <DeleteIcon 
                                        onClick={() => {
                                            setPropertyValue([
                                                ...propertyValue.slice(0, valueIndex),
                                                ...propertyValue.slice(valueIndex + 1),
                                            ]);
                                        }}
                                        />
                                </IconButton>
                            </div>

                        </div>
                    );
                })}
            </div>

            <div className="flex flex-row">
                <IconButton>
                    <PlaylistAddIcon 
                        onClick={() => {
                            setPropertyValue([
                                ...propertyValue,
                                {},
                            ]);
                        }}
                        />
                </IconButton>
            </div>
        </div>
    );
}

const boolean: IPropertyType = {
    editor: () => <ProperyArrayEditor />,
};

export default boolean;