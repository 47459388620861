import { nanoid } from "nanoid";
import styled, { css as _css } from 'styled-components'
import Vimeo from '@u-wave/react-vimeo'
import { useWidget, useRenderContext, usePageTheme, useDragContext, makeStyledElement, IWidgetType, stdElementAlignment, stdPadding, stdMargin, stdWidth, stdHeight } from "widgets-base";

//
// Gets the video ID for a youtube video.
//
function getYoutubeVideoId(url: string): string {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[2].length === 11) {
        return match[2]
    }
    else {
        return 'error';
    }
}

//
// Determines the details of the video.
//
function getVideoDetails(url?: string): { url: string, platform: string | undefined } {

    let platform: string | undefined = undefined;

    if (url?.includes('youtube')) {
        url = 'https://www.youtube.com/embed/' + getYoutubeVideoId(url) + '?autoplay=1&mute=1';
        platform = 'youtube';
    }
    else if (url?.includes('youtu.be/')) {
        const video_id = url.substring(url.indexOf('.be/') + 4);
        url = `https://www.youtube.com/embed/${video_id}?autoplay=1&mute=1`;
        platform = 'youtube';
    }
    else if (url?.includes('vimeo')) {
        platform = 'vimeo';
    }

    return { url, platform };
}

function Static() {

    const { widget, properties, expandedProperties } = useWidget();
    const { renderContext } = useRenderContext();
    const { pageTheme } = usePageTheme();

    const { url, platform } = getVideoDetails(properties?.videoUrl as string);

    return (
        <Div
            widgetId={widget.id}
            elementName="video"
            variantName={properties?.variant || "default"}
            pageTheme={pageTheme}
            properties={expandedProperties}
            renderContext={renderContext}
            >
            {platform === 'youtube' && (
                <iframe
                    src={url}
                    style={{ width: '100%' }}
                    height="100%"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    title={expandedProperties?.alt}
                    />
            )}

            {platform === 'vimeo' && (
                <Vimeo
                    video={url}
                    style={{ width: '100%' }}
                    height="100%"
                    responsive
                    autoplay
                    loop
                    muted
                    controls
                    />
            )}

            {!platform && (
                <video
                    src={url}
                    controls
                    autoPlay
                    muted
                    loop
                    width="100%"
                    height=""
                    playsInline
                    style={{
                        width: '100%',
                    }}
                />
            )}
        </Div>
    );
}

function Editor() {

    const { widget, properties, expandedProperties } = useWidget();
    const { renderContext } = useRenderContext();
    const { pageTheme } = usePageTheme();
    const { dragProps, setRef, onSelect } = useDragContext();

    return (
        <Div
            {...dragProps}
            ref={setRef}
            onClick={event => {
                event.stopPropagation();
                event.preventDefault();
                onSelect();
            }}
            widgetId={widget.id}
            elementName="video"
            variantName={properties?.variant || "default"}
            pageTheme={pageTheme}
            properties={expandedProperties}
            renderContext={renderContext}
            >
            <img
                style={{ width: '100%', height: '100%' }}
                src="/images/widgets/video.png"
                />
        </Div>
    );
}

const Div = styled(makeStyledElement("div"))`
    width: 100%;
    height: 100%;
`;

const widgetType: IWidgetType = {
    name: 'Video',
    Static: Static,
    Preview: Static,
    Editor: Editor,
    Template: Static,
    template: {
        id: `widget-${nanoid()}`,
        xtype: 'video',
        properties: {
            videoUrl: "https://www.youtube.com/embed/NgTBFrFA_n4",
            width: 420,
            height: 255,
            alignSelf: 'center',
            maxWidth: "100%",
        },
    },
    properties: [
        {
            id: "src",
            name: "Video",
            type: "text",
            required: true,
            defaultValue: "",
        },
        {
            id: "alt",
            name: "Alternate text",
            type: "text",
            required: true,
            defaultValue: "",
        },
    ],
    propertyCategories: [
        {
            name: "Placement",
            properties: [
                stdElementAlignment,
                stdPadding,
                stdMargin,
                stdWidth,
                stdHeight,
            ],
        },
    ],
};

export default widgetType;
