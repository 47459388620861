import React, { useState } from "react";
import { SketchPicker } from "react-color";
import { useProperty } from "../../context/property";
import { IPropertyType } from "../property-type";

function ModalColorPicker({ propertyValue, setPropertyValue, onCloseModal }) {

    return (
        <div 
            className="absolute select-none"
            style={{
                top: "34px",
                zIndex: "100",
            }}
            >
            <div
                className="fixed"
                style={{
                    left: "0",
                    right: "0",
                    top: "0",
                    bottom: "0",
                }}
                onClick={event => {
                    onCloseModal();
                    event.preventDefault();
                }}
            />
            <SketchPicker
                color={propertyValue}
                onChangeComplete={color => {
                    setPropertyValue(color.hex);
                }}
            />
        </div>
    );
}

//
// An editor for color properties.
//
export function PropertyColorEditor() {

    const { propertyValue, setPropertyValue } = useProperty();

    const [openColorPicker, setOpenColorPicker] = useState<boolean>(false);
    
    return (
        <>
            <div
                className="cursor-pointer"
                style={{
                    width: "30px",
                    height: "30px",
                    backgroundColor: propertyValue,
                    border: "1px solid #dfe2f2",
                    borderRadius: "8px",
                }}
                onClick={() => setOpenColorPicker(!openColorPicker)}
            />

            {openColorPicker
                && <ModalColorPicker
                    propertyValue={propertyValue}
                    setPropertyValue={value => {
                        setPropertyValue(value);
                    }}
                    onCloseModal={() => {
                        setOpenColorPicker(false);
                    }}
                    />
            }            
        </>
    );
}

const text: IPropertyType = {
    editor: () => <PropertyColorEditor />,
};

export default text;