import { useState } from 'react'
import styled from 'styled-components'
import Signin from './Signin'
import Signup from './Signup'

const LoginForm = () => {
    const [formType, setFormType] = useState<string>('signIn')

    return (
        <LoginLayout>
            {formType === 'signUp' && <Signup updateFormType={setFormType} />}
            {formType === 'signIn' && <Signin updateFormType={setFormType} />}
        </LoginLayout>
    )
}

export default LoginForm

const LoginLayout = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 95%;
`
