import React from 'react'

const QRDesign = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.76578 4H3V6.76578H5.76578V4Z"
                stroke="#3C4B61"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.76578 16.4463H3V19.2121H5.76578V16.4463Z"
                stroke="#3C4B61"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.9775 4H18.2117V6.76578H20.9775V4Z"
                stroke="#3C4B61"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3 9.53125H7.14867V10.9141"
                stroke="#3C4B61"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M3 13.6807H8.53156"
                stroke="#3C4B61"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.234 16.4465H20.9777V19.2123H15.4683V12.2979H20.9777V13.6807"
                stroke="#3C4B61"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.3196 13.6807V12.2979"
                stroke="#3C4B61"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.4683 5.38289V4"
                stroke="#3C4B61"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.6803 16.4463H11.3196"
                stroke="#3C4B61"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.53174 16.4893V19.2122H12.6804"
                stroke="#3C4B61"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.53174 4.02246V6.76612H9.91463"
                stroke="#3C4B61"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.6801 4.02246V9.5319H9.91431"
                stroke="#3C4B61"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.4683 9.53125H20.9998"
                stroke="#3C4B61"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default QRDesign
