import { motion } from 'framer-motion'
import moment from 'moment'
import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { RouteNames } from '../../../router'
import CardPopover from './CardPopover'
import { DeploySignal } from './DeploySignal'
import { ICampaign } from 'widgets-base'

interface Props {
    data: ICampaign
    scans: number
    deleteCampaign: (data: ICampaign) => Promise<void>
    makePrimaryLink: (campaignId: string, campaignName: string, username?: string) => Promise<void>
}

const ProjectCard: FC<Props> = ({
    data,
    scans,
    deleteCampaign,
    makePrimaryLink,
}) => {

    const dateCreated = moment(data.createdAt).format('LLL')
    const navigate = useNavigate()

    const onViewCampaignDetails = () => {
        navigate(`${RouteNames.BUILDER}/${data._id}`)
    }

    return (
        <ProjectCardComponent
            whileHover={{
                scale: 1.04,
            }}
        >
            <div onClick={onViewCampaignDetails}>
								{data.metaImageUrl ? (
										<PreviewCampaign imageUrl={data.metaImageUrl}  />
								) : (
										<img src="/icons/sceleton.png" alt="sceleton" />
								)}
						</div>
            <Footer>
                <FlexContainer>
                    <CampaignInfo>
                        <img width={20} height={20} src="/icons/calendar.png" alt="calendar" />
                        <CreatedComponent>
                            <span style={{ marginBottom: '5px' }}>Created:</span>
                            <span>{dateCreated}</span>
                        </CreatedComponent>
                    </CampaignInfo>
                    <CampaignInfo>
                        <img width={25} height={25} src="/icons/qrcode.png" alt="views" />
                        <span>
                            <strong>{scans}</strong> scans
                        </span>
                    </CampaignInfo>
                </FlexContainer>
                <FlexContainer>
                    <FooterDetail>
                        <DeploySignal isDeployed={data.status === 'published'} />
                        <div style={{ marginLeft: 10 }}>
                            <ProjectName>
                                <p>{data.campaignName}</p>
                            </ProjectName>
                            <ProjectLink href={data.deployUrl} target="_blank">
                                {'View Link / QR'}
                            </ProjectLink>
                        </div>
                    </FooterDetail>
                    <FooterMoreDetail>
                        <CardPopover
                            data={data}
                            deleteCampaign={deleteCampaign}
                            makePrimaryLink={makePrimaryLink}
                        />
                    </FooterMoreDetail>
                </FlexContainer>
            </Footer>
        </ProjectCardComponent>
    )
}

export default ProjectCard

const FlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

const CreatedComponent = styled.div`
    display: flex;
    flex-direction: column;
`

const CampaignInfo = styled.div`
    display: flex;
    align-items: center;

    span {
        font-size: 12px;
        line-height: 10px;
        color: #717f95;
    }

    & > img {
        margin-right: 10px;
    }
`

const ProjectCardComponent = styled(motion.div)`
    max-height: 392px;
    min-width: 278px;
    height: 392px;
    width: 100%;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    border: 1px solid #e3e7ff;
    background: '#FAFAFF';
    cursor: pointer;
    position: relative;
    transition: all 0.1s ease-in-out;

    &:hover {
        box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
    }
`

const PreviewCampaign = styled.div<{
    imageUrl: string
}>`
    width: 98%;
    height: 98%;
    background-image: ${(props) => `url(${props.imageUrl})`};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: all 0.5s ease-in-out;
    border-radius: 15px;
`

const Footer = styled.div`
    height: 105px;
    width: 100%;
    bottom: 0px;
    position: absolute;
    background: white;
    padding: 13px 16px;
    border-top: 1px solid #dfe2f5;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    font-family: 'Matter';
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 55;
`
const FooterDetail = styled.div`
    height: 35px;
    width: 195px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
`

const FooterMoreDetail = styled.div`
    height: 35px;
    width: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
`
const ProjectName = styled.div`
    font-size: 14px !important;
    line-height: 14px;
    color: #3c4b61;
    text-transform: capitalize;
    margin-bottom: 3.5px;
    max-width: 200px;

    p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 170px;
        padding: 3px 0;
    }
`
const ProjectLink = styled.a`
    font-family: 'Matter';
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 10px;
    text-decoration-line: underline;
    color: #717f95;
    overflow: none;
`
