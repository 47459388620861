import {
    AppBar,
    Box,
    Button,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
} from '@mui/material'
import { FC, useState, MouseEvent } from 'react'
import styled from 'styled-components'
import MenuIcon from '@mui/icons-material/Menu'
import { capitalizeFirstLetter } from '../../../../helpers/capitalizeFirstLetter'
import { useWidget, useRenderContext } from 'widgets-base'
import { useCampaign } from '../../../../../builder/context/campaign'

interface Props {
    preview?: string
}

const NavbarWidget: FC<Props> = ({ preview }) => {

    const { widget } = useWidget();
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)
    const { campaign, setCurrentPageIndex } = useCampaign();
    const { renderContext } = useRenderContext();

    const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget)
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null)
    }

    const redirectToPage = (index: number) => () => {
        handleCloseNavMenu()
        setCurrentPageIndex(index)
    }

    const getDisplayValue = (device: string) => {
        const isBurger = widget.appearanceSettings.isBurger;
        if (device === 'mobile') {
            if (preview && preview === '414px' || isBurger) {
                return 'flex'
            } else {
                return { xs: 'flex', md: 'none' }
            }
        } else {
            if (preview && preview === '414px' || isBurger) {
                return 'none'
            } else {
                return { xs: 'none', md: 'flex' }
            }
        }
    }

    const fontFamily = widget?.appearanceSettings?.font?.family
    const modFontFamily = fontFamily?.replace(/\s/g, '+')
    const fontUrl = widget?.appearanceSettings?.font?.url

    const margins = {
        marginTop: widget.appearanceSettings.marginTop,
        marginRight: widget.appearanceSettings.marginRight,
        marginBottom: widget.appearanceSettings.marginBottom,
        marginLeft: widget.appearanceSettings.marginLeft,
    }

    const paddings = {
        paddingTop: widget.appearanceSettings.paddingTop,
        paddingRight: widget.appearanceSettings.paddingRight,
        paddingBottom: widget.appearanceSettings.paddingBottom,
        paddingLeft: widget.appearanceSettings.paddingLeft,
    }

    if (renderContext.isStaticExport) {
        return (
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: widget.appearanceSettings.marginTop,
                    marginRight: widget.appearanceSettings.marginRight,
                    marginBottom: widget.appearanceSettings.marginBottom,
                    marginLeft: widget.appearanceSettings.marginLeft,
                    paddingTop: "12px",
                    paddingBottom: "12px",
                    paddingLeft: "24px",
                    paddingRight: "24px",
                    backgroundColor: widget.colourSettings['background colour'][0]
                }}
            >
                <style>
                    {!fontUrl
                        ? `@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`
                        : `@font-face {
                            font-family: '${fontFamily}';
                            src: url('${fontUrl}');
                            font-style: normal;
                        }`}
                </style>
                <div 
                    className="nav-menu-button"
                    style={{ position: "relative" }}
                    >
                    <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
                        <rect width="28" fill="white" height="4" x="2" y="6" rx="2" />
                        <rect width="28" fill="white" height="4" x="2" y="14" rx="2" />
                        <rect width="28" fill="white" height="4" x="2" y="22" rx="2" />
                    </svg>

                    <div
                        className="nav-menu"
                        style={{
                            display: "none",
                            position: "absolute",
                            top: "38px",
                            minWidth: "200px",
                            backgroundColor: "white",
                            border: "1px solid gray",
                            borderRadius: "5px",
                            padding: "8px 0px",
                        }}
                        >
                        {campaign?.views?.map((page, index) => (
                            <div
                                key={page.id} 
                                data-button-type="page"
                                data-button-page={(index+1).toString()}
                                style={{
                                    fontSize: widget.appearanceSettings.font.size,
                                    fontFamily: `${fontFamily} !important`,
                                    padding: "6px 16px",
                                    cursor: "pointer",
                                }}
                                >
                                {capitalizeFirstLetter(page.name)}
                            </div>
                        ))}
                    </div>
                </div>
                <div style={{ flexGrow: 1 }} />
                <img width={150} src={widget.settings.src} alt="logo" />
            </div>
        );
    }

    return (
        <NavbarSection id={widget.id} style={margins}>
            <style>
                {!fontUrl
                    ? `@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`
                    : `@font-face {
                        font-family: '${fontFamily}';
                        src: url('${fontUrl}');
                        font-style: normal;
                      }`}
            </style>
            <AppBar
                position="static"
                sx={{ backgroundColor: widget.colourSettings['background colour'][0] }}
            >
                <Container maxWidth="xl" sx={paddings}>
                    <Toolbar disableGutters>
                        <Box
                            sx={{
                                flexGrow: 1,
                                color: widget.colourSettings['text colour'][0],
                                display: getDisplayValue('mobile'),
                            }}
                        >
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>

                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: getDisplayValue('mobile'),
                                    zIndex: 9999,
                                }}
                            >
                                {campaign?.views?.map((page, index) => (
                                    <MenuItem key={page.id} onClick={redirectToPage(index)}>
                                        <Typography
                                            sx={{
                                                fontSize: widget.appearanceSettings.font.size,
                                                fontFamily: `${fontFamily} !important`,
                                            }}
                                            textAlign="center"
                                        >
                                            Donkey
                                            {capitalizeFirstLetter(page.name)}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: getDisplayValue('laptop'),
                                flexWrap: 'wrap',
                            }}
                        >
                            {campaign?.views?.map((page, index) => (
                                <Button
                                    key={page.id}
                                    onClick={redirectToPage(index)}
                                    sx={{
                                        my: 2,
                                        color: widget.colourSettings['text colour'][0],
                                        display: 'block',
                                        letterSpacing: 0,
                                        fontSize: widget.appearanceSettings.font.size,
                                        fontFamily: `${fontFamily} !important`,
                                    }}
                                >
                                    {page.name}
                                </Button>
                            ))}
                        </Box>
                        <img width={150} src={widget.settings.src} alt="logo" />
                    </Toolbar>
                </Container>
            </AppBar>
        </NavbarSection>
    )
}

export default NavbarWidget

const NavbarSection = styled.section`
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
`
