import React from 'react'
import { Loader } from '../../pages/Forms/styles'

const FormLoader = () => {
    return (
        <Loader>
            <svg viewBox="0 0 200 120">
                <path
                    d="M 10 50 A 10 10 90 0 0 90 50 A 10 10 90 0 0 10 50"
                    stroke="#ccc"
                    strokeWidth="2"
                    fill="none"
                />
                <path
                    d="M 10 50 A 10 10 90 0 0 90 50 A 10 10 90 0 0 10 50"
                    stroke="#ccc"
                    strokeWidth="5"
                    fill="none"
                    strokeDasharray="63"
                >
                    <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="rotate"
                        from="0 50 50"
                        to="360 50 50"
                        dur="5s"
                        repeatCount="indefinite"
                    />
                </path>
                <path
                    d="M 82 74 L 97 90 H 145 M 150 115 V 50 H 185 L 197 61 V 115 Z"
                    stroke="#ccc"
                    strokeWidth="3"
                    fill="none"
                />
                <path d="M 155 58 H 189" stroke="#ccc" strokeWidth="1" fill="none">
                    <animate
                        attributeName="d"
                        dur="2"
                        repeatCount="indefinite"
                        begin="0"
                        values="M 155 50 H 170; M 155 115 H 189"
                    />
                </path>
                <path d="M 155 58 H 189" stroke="#ccc" strokeWidth="1" fill="none">
                    <animate
                        attributeName="d"
                        dur="2"
                        repeatCount="indefinite"
                        begin="-0.4"
                        values="M 155 50 H 179; M 155 115 H 189"
                    />
                </path>
                <path d="M 155 58 H 189" stroke="#ccc" strokeWidth="1" fill="none">
                    <animate
                        attributeName="d"
                        dur="2"
                        repeatCount="indefinite"
                        begin="-0.8"
                        values="M 155 50 H 180; M 155 115 H 189"
                    />
                </path>
                <path d="M 155 58 H 189" stroke="#ccc" strokeWidth="1" fill="none">
                    <animate
                        attributeName="d"
                        dur="2"
                        repeatCount="indefinite"
                        begin="-1.2"
                        values="M 155 50 H 179; M 155 115 H 189"
                    />
                </path>
                <path d="M 155 58 H 189" stroke="#ccc" strokeWidth="1" fill="none">
                    <animate
                        attributeName="d"
                        dur="2"
                        repeatCount="indefinite"
                        begin="-1.6"
                        values="M 155 50 H 182; M 155 115 H 189"
                    />
                </path>
            </svg>
            <span>Loading submissions...</span>
        </Loader>
    )
}

export default FormLoader
