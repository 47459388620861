export enum RouteNames {
    // public routes
    LOGIN = '/',
    FORGOT_PASSWORD = '/auth/forgot_password',
    RESET_PASSWORD = '/auth/reset_password',    
    USERNAME = '/username',
    VERIFY_EMAIL = '/verify_email',
    AUTH_ACTION = '/auth/action',
    BLOG = '/main/blog',
    EMBED = '/embed',
    EMBED_DRAW = '/embed/draw',
    // private routes
    DASHBOARD = '/main/dashboard',
    BUILDER = '/main/campaign/edit',
    AI_GENERATOR = '/main/ai-generator',
    AI_GENERATOR2 = "/main/ai-generator-2",
    BRAND = '/main/brand_settings',
    TEMPLATES = '/main/templates',
    ACCOUNT = '/main/account',
    PREVIEW = '/main/preview',
    ANALITICS = '/main/analitics',
    FORMS = '/main/forms',
    BILLING = '/main/billing',
    SUCCESS_PAY = '/main/payment/success',
    FEEDBACK = '/main/feedback',
    ASSETS = '/main/assets',
    // docs routes
    DOC = '/main/documentation',
    DOC_ABOUT = '/main/documentation/about',
    DOC_BLOG = '/main/documentation/blog',
    DOC_TEMPLATES = '/main/documentation/templates',
    DOC_FORMS = '/main/documentation/forms',
    DOC_CREATE_CAMPAIGN = '/main/documentation/create-campaign',
    DOC_EMBED_CAMPAIGN = '/main/documentation/embed-campaign',
    DOC_BUILDER = '/main/documentation/builder',
    DOC_WIDGETS = '/main/documentation/widgets',
    DOC_EDIT_MODE = '/main/documentation/edit-mode',
    DOC_BRAND_ASSETS = '/main/documentation/assets',
    DOC_BRAND_FONTS = '/main/documentation/fonts',
    DOC_BRAND_SOCIAL = '/main/documentation/social',
    DOC_BILLING = '/main/documentation/billing',
    DOC_SLACK = '/main/documentation/integrations/slack',
    DOC_ACCOUNT = '/main/documentation/account',
}
