import { SpeechBubble } from "./components/speech-bubble";

export function ImageSearch({ imageSearch, setImageSearch }) {

    return (
        <div className="flex flex-col w-full items-center overflow-hidden">

            <SpeechBubble
                title="Ken - Head of Audience Incentives"
                text="What images would you like to use?"
                avatar="/ai-generator/characters/ken.png"
                reverse={true}
                />

            <div className="flex flex-col mt-4">

                <div className="prominent-text text-neutral-400 text-xl tracking-wider ml-4 mt-6">
                    Write Your Prompt Here
                </div>
                <div>
                    <textarea 
                        className="p-4 text-xl text-white placeholder-white leading-9 tracking-wide bg-blue-500 rounded-lg"
                        placeholder="Write Image Description - e.g. Friends enjoying Summer"
                        style={{
                            width: "500px",
                            minHeight: "150px",
                        }}
                        value={imageSearch}
                        onChange={(e) => {
                            setImageSearch(e.target.value);
                        }}
                        >
                    </textarea>
                </div>
            </div>

            <SpeechBubble
                title="Buzz - Head of Good Tips"
                text="We partner with Unsplash and their amazing talent for stock imagery or upload your own assets in a moment."
                avatar="/ai-generator/characters/buzz.png"
                />            
        </div>
    );
}

