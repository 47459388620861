import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { capitalizeFirstLetter } from '../../helpers/capitalizeFirstLetter'
import { RouteNames } from '../../../router'
import { Button } from '../common/Button'
import { UserRoles } from 'widgets-base'

const FreeCard: FC = () => {
    const navigate = useNavigate()

    const handleClickFree = () => {
        navigate(RouteNames.DASHBOARD)
    }

    return (
        <div className="offer">
            <h1>{capitalizeFirstLetter(UserRoles.FREE)}</h1>
            <span>For individuals and teams getting started</span>
            <p className="offer__price">
                $0 <span>free forever</span>
            </p>
            <ul className="offer__details">
                <li>
                    <img src="/icons/checkbox.svg" alt="list icon" />
                    <span>Access to one page</span>
                </li>
                <li>
                    <img src="/icons/checkbox.svg" alt="list icon" />
                    <span>Access to Drag & Drop Builder</span>
                </li>
                <li>
                    <img src="/icons/checkbox.svg" alt="list icon" />
                    <span>QR code launcher</span>
                </li>
                <li>
                    <img src="/icons/checkbox.svg" alt="list icon" />
                    <span>Access to use forms</span>
                </li>
            </ul>
            <Button variant="primary" onClick={handleClickFree}>
                Start free
            </Button>
        </div>
    )
}

export default FreeCard
