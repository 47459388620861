export enum ButtonAction {
    REDIRECT = 'redirect',
    GO_TO_VIEW = 'go to page',
    TOGGLE_POPUP = 'toggle popup',
    START_GAME = 'start game',
}

export interface IButtonSettings {
    id: string
    linkTitle: string
    linkUrl: string
    type: ButtonAction // redirect, go to page, open popup, close popup
    layerIndex: number
    viewIndex: number | string;
    widgetSettings: { [key: string]: any }
}
