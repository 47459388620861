import { Divider } from '@mui/material'
import { FC } from 'react'
import { WidgetSettingContainer } from '../../../Design/WidgetSettingContainer'
import { BorderRadius, BorderWidth, Colors, Fonts } from '../../../EditMode/Design'
import { useWidget } from 'widgets-base'

interface Props {
}

const EventDesignSetting: FC<Props> = () => {

    const { widget } = useWidget();

    return (
        <WidgetSettingContainer padding={0}>
            <Fonts
                label="Select font"
                defaultFont={widget?.appearanceSettings?.font?.family}
            />
            <Divider />
            <BorderWidth
                topValue={widget?.appearanceSettings?.borderTopWidth}
                rightValue={widget?.appearanceSettings?.borderRightWidth}
                leftValue={widget?.appearanceSettings?.borderLeftWidth}
                bottomValue={widget?.appearanceSettings?.borderBottomWidth}
            />
            <BorderRadius
                topRightValue={widget?.appearanceSettings?.borderTopRightRadius}
                bottomRightValue={widget?.appearanceSettings?.borderBottomRightRadius}
                topLeftValue={widget?.appearanceSettings?.borderTopLeftRadius}
                bottomLeftValue={widget?.appearanceSettings?.borderBottomLeftRadius}
            />
            <Divider />
            <Colors colors={widget?.colourSettings} />
        </WidgetSettingContainer>
    )
}

export default EventDesignSetting
