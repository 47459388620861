import { FC } from 'react'
import { SyncLoader } from 'react-spinners'
import { capitalizeFirstLetter } from '../../helpers/capitalizeFirstLetter'
import {
    getProductData,
    IProduct,
    liteProductMonthID,
    liteProductYearID,
} from '../../pages/Billing/productData'
import { Button } from '../common/Button'
import { UserRoles } from 'widgets-base'

interface Props {
    period: string
    loading: boolean
    onPayment: (product: IProduct, role: string) => () => Promise<void>
    role?: string
}

const LiteCard: FC<Props> = ({ period, loading, onPayment, role = 'lite' }) => {
    const product =
        period === 'month' ? getProductData(liteProductMonthID) : getProductData(liteProductYearID)

    return (
        <div className="offer">
            <h1>{capitalizeFirstLetter(UserRoles.LITE)}</h1>
            <span>For professionals and product managers</span>
            <p className="offer__price">
                ${product.price} <span>(AUD) Per member, per month Billed annually</span>
            </p>
            <ul className="offer__details">
                <li>
                    <img src="/icons/checkbox.svg" alt="list icon" />
                    <span>Access to 5 pages</span>
                </li>
                <li>
                    <img src="/icons/checkbox.svg" alt="list icon" />
                    <span>Access to Drag & Drop Builder</span>
                </li>
                <li>
                    <img src="/icons/checkbox.svg" alt="list icon" />
                    <span>QR code launcher</span>
                </li>
                <li>
                    <img src="/icons/checkbox.svg" alt="list icon" />
                    <span>No watermark</span>
                </li>
                <li>
                    <img src="/icons/checkbox.svg" alt="list icon" />
                    <span>Access to use forms</span>
                </li>
                <li>
                    <img src="/icons/checkbox.svg" alt="list icon" />
                    <span>Basic Gamification Pack</span>
                </li>
                <li>
                    <img src="/icons/checkbox.svg" alt="list icon" />
                    <span>Communication pathway with deployable</span>
                </li>
                <li>
                    <img src="/icons/checkbox.svg" alt="list icon" />
                    <span>Unique link username</span>
                </li>
                <li>
                    <img src="/icons/checkbox.svg" alt="list icon" />
                    <span>Meta data for deployed page</span>
                </li>
            </ul>
            <Button variant="primary" onClick={onPayment(product, role)}>
                {loading ? (
                    <SyncLoader
                        color="#fff"
                        speedMultiplier={0.4}
                        size={5}
                        cssOverride={{ textAlign: 'center', margin: '20px 0' }}
                    />
                ) : (
                    'Subscribe'
                )}
            </Button>
        </div>
    )
}

export default LiteCard
