import React, { FC, useEffect, useRef } from 'react'
import { SketchPicker } from 'react-color'
import styled, { keyframes } from 'styled-components'

export type ColorPickerCallBack = {
    hex: string
    hsl: { h: number; s: number; l: number; a: number }
    hsv: { h: number; s: number; v: number; a: number }
    oldHue: number
    rgb: { r: number; g: number; b: number; a: number }
    source: string
}

interface Props {
    color: string
    handleColorChange: (color: any) => void
    toggleOpen: () => void
}

export const ColorPicker: FC<Props> = ({ color, handleColorChange, toggleOpen }) => {
    const pickerRef = useRef<HTMLDivElement>()

    useEffect(() => {
        const handler = (event) => {
            if (!pickerRef.current.contains(event.target)) {
                toggleOpen()
            }
        }
        document.addEventListener('mousedown', handler)
        return () => {
            document.removeEventListener('mousedown', handler)
        }
    })

    return (
        <Container ref={pickerRef} className="color_picker">
            <SketchPicker onChange={handleColorChange} color={color} />
        </Container>
    )
}

const showIt = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`

const Container = styled.div`
    position: absolute;
    z-index: 9999;
    animation: ${showIt} 0.2s ease-in-out;
    color: black;
`
