export const productShowCaseTemplate: any = {
    "templateName": "Product showcase",
    "templateThumbnail" : "/images/templates/product-show-case.png",
    "_id" : "IR8hqpAsthWuKosnXhDVT",
    "userId" : "Hf7F58y0nlVatZxg4L7N6ESu5AL2",
    "uid" : "Hf7F58y0nlVatZxg4L7N6ESu5AL2",
    "views" : [
        {
            "name" : "Page 1",
            "id" : "HyoalO2cZtuxAIjhZe8y7",
            "backgroundColour" : "rgb(255,255,255,1)",
            "backgroundImageUrl" : "",
            "widgets" : [
                {
                    "name" : "navbar",
                    "type" : "navbar",
                    "id" : "LnL1hlk3NoJaKmRvqEGta",
                    "category" : "general",
                    "properties" : {
                        "alignSelf" : "stretch"
                    },
                    "settings" : {
                        "pages" : [

                        ],
                        "src" : "/images/deployable-logo.png"
                    },
                    "appearanceSettings" : {
                        "width" : 90,
                        "marginTop" : 0,
                        "isBurger" : false,
                        "marginBottom" : 0,
                        "marginLeft" : 0,
                        "marginRight" : 0,
                        "paddingTop" : 0,
                        "paddingBottom" : 0,
                        "paddingLeft" : 0,
                        "paddingRight" : 0,
                        "font" : {
                            "family" : "Poppins",
                            "size" : 14,
                            "weight" : 400
                        }
                    },
                    "colourSettings" : {
                        "background colour" : [
                            "transparent",
                            "transparent",
                            "transparent"
                        ],
                        "text colour" : [
                            "black",
                            "black",
                            "black"
                        ]
                    },
                    "children" : [

                    ],
                    "widgets" : [

                    ]
                },
                {
                    "id" : "98055f2666ee6b98c27587f65d1706671c96655cb69f7f32927928862d31a47c",
                    "name" : "hero-stack",
                    "xtype" : "group",
                    "properties" : {
                        "columnGap" : 60,
                        "rowGap" : 60,
                        "flexDirection" : "column",
                        "justifyContent" : "flex-start",
                        "alignItems" : "center",
                        "paddingTop" : 48,
                        "paddingRight" : 18,
                        "paddingBottom" : 48,
                        "paddingLeft" : 18,
                        "display" : "flex",
                        "small" : {
                            "backgroundAttachment" : "scroll"
                        }
                    },
                    "children" : [
                        {
                            "id" : "5ccb26d5fa9aa3264ad453770e4c10a58758fbd564d6e82f9fda18be018fd61d",
                            "name" : "small-container",
                            "xtype" : "group",
                            "properties" : {
                                "maxWidth" : 720,
                                "columnGap" : 30,
                                "rowGap" : 30,
                                "flexDirection" : "column",
                                "justifyContent" : "flex-start",
                                "alignItems" : "center",
                                "display" : "flex",
                                "small" : {
                                    "backgroundAttachment" : "scroll"
                                }
                            },
                            "children" : [
                                {
                                    "id" : "40e5c40372383b4f448d8f7faa1a4cec74d794fe0294a643959b82277a79f9d7",
                                    "name" : "title-section",
                                    "xtype" : "group",
                                    "properties" : {
                                        "columnGap" : 18,
                                        "rowGap" : 18,
                                        "flexDirection" : "column",
                                        "justifyContent" : "flex-start",
                                        "alignItems" : "center",
                                        "display" : "flex",
                                        "small" : {
                                            "backgroundAttachment" : "scroll"
                                        }
                                    },
                                    "children" : [
                                        {
                                            "id" : "0ef157dc775326493e6fbee710f6e670dbf5c4c88263ef42ddf3118f75138a66",
                                            "name" : "title-section",
                                            "xtype" : "group",
                                            "properties" : {
                                                "columnGap" : 18,
                                                "rowGap" : 18,
                                                "flexDirection" : "column",
                                                "justifyContent" : "flex-start",
                                                "alignItems" : "center",
                                                "display" : "flex",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "5976481244612d3f1ecac8f249f394e95ed9bd91efa1bccaefd9942736d4ba38",
                                                    "name" : "text-10",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "fontSize" : 42,
                                                        "textAlign" : "center",
                                                        "fontWeight" : "700",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "3b1a22f196f9eaaea39d5658a6077bf032cae591a3af5ccf22f3cdafecce80c5",
                                                            "xtype" : "text",
                                                            "properties" : {
                                                                "content" : "<p>Product Showcase</p>",
                                                                "maxWidth" : "100%"
                                                            },
                                                            "lastUpdate" : 1694609507214.0,
                                                            "children" : [

                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "c8677442625d4f84f3998aee05fd10eddbc2f8fa4a96b972e73bdb129ccd2b02",
                                                    "name" : "lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit-suspendisse-varius-enim-in-eros-elementum-tristique",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "textAlign" : "center",
                                                        "fontWeight" : "400",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "3ae9881fd8d37360d01765c5b2cf0977028f18bdf8a25f68285c0c75038c055c",
                                                            "xtype" : "text",
                                                            "properties" : {
                                                                "content" : "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</p>",
                                                                "maxWidth" : "100%"
                                                            },
                                                            "children" : [

                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "widgets" : [

                                            ]
                                        },
                                        {
                                            "id" : "98680b2e99aadda5e8e196943fd749ee3ed0b0c8e8d40e4fcc8b2ebd2872f07d",
                                            "name" : "actions",
                                            "xtype" : "group",
                                            "properties" : {
                                                "columnGap" : 12,
                                                "rowGap" : 12,
                                                "flexGrow" : "0",
                                                "flexShrink" : "1",
                                                "flexBasis" : "auto",
                                                "justifyContent" : "flex-start",
                                                "alignItems" : "flex-start",
                                                "paddingTop" : 12,
                                                "display" : "flex",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "b4910288fb90e40213dc05f5f7416eeaa265313dcab9e89de8b7af350cd41ea2",
                                                    "name" : "button",
                                                    "xtype" : "button",
                                                    "properties" : {
                                                        "link" : {
                                                            "target" : "url",
                                                            "url" : "#",
                                                            "page" : 0
                                                        },
                                                        "columnGap" : 6,
                                                        "rowGap" : 6,
                                                        "flexGrow" : "0",
                                                        "flexShrink" : "1",
                                                        "flexBasis" : "auto",
                                                        "justifyContent" : "center",
                                                        "alignItems" : "center",
                                                        "paddingTop" : 9,
                                                        "paddingRight" : 18,
                                                        "paddingBottom" : 9,
                                                        "paddingLeft" : 18,
                                                        "textDecorationLine" : "none",
                                                        "textDecorationThickness" : "initial",
                                                        "textDecorationStyle" : "initial",
                                                        "textDecorationColor" : "initial",
                                                        "display" : "flex",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "ae55c93fcedee8795ea335baf222883de252c85148fd4fd7d7a5246fc4806d94",
                                                            "name" : "text-11",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "fontSize" : 9,
                                                                "fontWeight" : "500",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "c047b3ef4470bd16e25ee149ab711366eaf6fd95020c7221c20bee6946be8966",
                                                                    "xtype" : "text",
                                                                    "properties" : {
                                                                        "content" : "<p>BUY ONLINE</p>",
                                                                        "maxWidth" : "100%"
                                                                    },
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ],
                                                                    "lastUpdate" : 1694611552819.0
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "widget-Pt3UABhQnamt18qxSmEAX",
                                                    "name" : "button",
                                                    "xtype" : "button",
                                                    "properties" : {
                                                        "link" : {
                                                            "target" : "url",
                                                            "url" : "#",
                                                            "page" : 0
                                                        },
                                                        "columnGap" : 6,
                                                        "rowGap" : 6,
                                                        "flexGrow" : "0",
                                                        "flexShrink" : "1",
                                                        "flexBasis" : "auto",
                                                        "justifyContent" : "center",
                                                        "alignItems" : "center",
                                                        "paddingTop" : 9,
                                                        "paddingRight" : 18,
                                                        "paddingBottom" : 9,
                                                        "paddingLeft" : 18,
                                                        "textDecorationLine" : "none",
                                                        "textDecorationThickness" : "initial",
                                                        "textDecorationStyle" : "initial",
                                                        "textDecorationColor" : "initial",
                                                        "display" : "flex",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "widget-2KARaY7bhWllTuf71gxUJ",
                                                            "name" : "text-11",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "fontSize" : 9,
                                                                "fontWeight" : "500",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "widget-wMEoq8dPuh4inM4Xf845h",
                                                                    "xtype" : "text",
                                                                    "properties" : {
                                                                        "content" : "<p>LEARN MORE</p>",
                                                                        "maxWidth" : "100%"
                                                                    },
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ],
                                                                    "lastUpdate" : 1694611563626.0
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "widget-RDrYPoDxxriWYJ_q0-jhA",
                                                    "name" : "button",
                                                    "xtype" : "button",
                                                    "properties" : {
                                                        "link" : {
                                                            "target" : "url",
                                                            "url" : "#",
                                                            "page" : 0
                                                        },
                                                        "columnGap" : 6,
                                                        "rowGap" : 6,
                                                        "flexGrow" : "0",
                                                        "flexShrink" : "1",
                                                        "flexBasis" : "auto",
                                                        "justifyContent" : "center",
                                                        "alignItems" : "center",
                                                        "paddingTop" : 9,
                                                        "paddingRight" : 18,
                                                        "paddingBottom" : 9,
                                                        "paddingLeft" : 18,
                                                        "textDecorationLine" : "none",
                                                        "textDecorationThickness" : "initial",
                                                        "textDecorationStyle" : "initial",
                                                        "textDecorationColor" : "initial",
                                                        "display" : "flex",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "widget-bZy01kPYlqbrZ0MhZtuLB",
                                                            "name" : "text-11",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "fontSize" : 9,
                                                                "fontWeight" : "500",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "widget-7j76TubBTtSUUngDb_qZ8",
                                                                    "xtype" : "text",
                                                                    "properties" : {
                                                                        "content" : "<p>OUR TEAM</p>",
                                                                        "maxWidth" : "100%"
                                                                    },
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ],
                                                                    "lastUpdate" : 1694611590852.0
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "widget-6zv2-w6jbSpgR-ePug0Eg",
                                                    "name" : "button",
                                                    "xtype" : "button",
                                                    "properties" : {
                                                        "link" : {
                                                            "target" : "url",
                                                            "url" : "#",
                                                            "page" : 0
                                                        },
                                                        "columnGap" : 6,
                                                        "rowGap" : 6,
                                                        "flexGrow" : "0",
                                                        "flexShrink" : "1",
                                                        "flexBasis" : "auto",
                                                        "justifyContent" : "center",
                                                        "alignItems" : "center",
                                                        "paddingTop" : 9,
                                                        "paddingRight" : 18,
                                                        "paddingBottom" : 9,
                                                        "paddingLeft" : 18,
                                                        "textDecorationLine" : "none",
                                                        "textDecorationThickness" : "initial",
                                                        "textDecorationStyle" : "initial",
                                                        "textDecorationColor" : "initial",
                                                        "display" : "flex",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "widget-_lMWPQ5QdLPNAGJx9PxIC",
                                                            "name" : "text-11",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "fontSize" : 9,
                                                                "fontWeight" : "500",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "widget-Ww-D044K7G_jb1dv5znJz",
                                                                    "xtype" : "text",
                                                                    "properties" : {
                                                                        "content" : "<p>CONTACT US</p>",
                                                                        "maxWidth" : "100%"
                                                                    },
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ],
                                                                    "lastUpdate" : 1694611580756.0
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "widgets" : [

                                    ]
                                },
                                {
                                    "id" : "335d847f6b10f2caff28240ad3adc3ae2b7d46d3a3f041fda06cc4023c9d934a",
                                    "name" : "image-wrapper-2",
                                    "xtype" : "group",
                                    "properties" : {
                                        "flexDirection" : "column",
                                        "justifyContent" : "flex-start",
                                        "alignItems" : "flex-start",
                                        "display" : "flex",
                                        "small" : {
                                            "backgroundAttachment" : "scroll"
                                        }
                                    },
                                    "children" : [
                                        {
                                            "id" : "333d349729959dcd01a9fbe760d6025d526f5348ba84eeeec222882203d33d43",
                                            "name" : "image-2",
                                            "xtype" : "image",
                                            "properties" : {
                                                "borderTopWidth" : 0,
                                                "borderRightWidth" : 0,
                                                "borderBottomWidth" : 0,
                                                "borderLeftWidth" : 0,
                                                "borderTopStyle" : "initial",
                                                "borderRightStyle" : "initial",
                                                "borderBottomStyle" : "initial",
                                                "borderLeftStyle" : "initial",
                                                "borderTopColor" : "initial",
                                                "borderRightColor" : "initial",
                                                "borderBottomColor" : "initial",
                                                "borderLeftColor" : "initial",
                                                "borderImageSource" : "initial",
                                                "borderImageSlice" : "initial",
                                                "borderImageWidth" : "initial",
                                                "borderImageOutset" : "initial",
                                                "borderImageRepeat" : "initial",
                                                "maxWidth" : "100%",
                                                "verticalAlign" : "middle",
                                                "display" : "flex",
                                                "objectFit" : "cover",
                                                "flexDirection" : "column",
                                                "justifyContent" : "center",
                                                "alignItems" : "center",
                                                "small" : {
                                                    "display" : "inline-block",
                                                    "backgroundAttachment" : "scroll"
                                                },
                                                "src" : "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f910a082a4d59b14adb3aa_Image.png",
                                                "alt" : "",
                                                "maxHeight" : "100%"
                                            },
                                            "children" : [

                                            ],
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "widgets" : [

                                    ]
                                }
                            ],
                            "widgets" : [

                            ]
                        }
                    ],
                    "widgets" : [

                    ]
                },
                {
                    "id" : "65d6cc2adbb61846199f807ddb7d2d26ec99900bc7010821117b5a895171fb87",
                    "name" : "logo-title",
                    "xtype" : "group",
                    "properties" : {
                        "columnGap" : 24,
                        "rowGap" : 24,
                        "flexDirection" : "column",
                        "justifyContent" : "flex-start",
                        "alignItems" : "center",
                        "paddingTop" : 48,
                        "paddingRight" : 18,
                        "paddingBottom" : 48,
                        "paddingLeft" : 18,
                        "display" : "flex",
                        "small" : {
                            "backgroundAttachment" : "scroll"
                        },
                        "backgroundColor" : "#f5f7fa"
                    },
                    "children" : [
                        {
                            "id" : "e641e4365819c4858a48065af9773b6fb23fe6ba1dbb081bc3d084321109e606",
                            "name" : "title",
                            "xtype" : "group",
                            "properties" : {
                                "textAlign" : "center",
                                "fontWeight" : "700",
                                "small" : {
                                    "backgroundAttachment" : "scroll"
                                }
                            },
                            "children" : [
                                {
                                    "id" : "18147657d01ddbbfd9b60b4c6af57ec64087b775f960d869711d98d33f9956a8",
                                    "xtype" : "label",
                                    "properties" : {
                                        "content" : "<p>Awards Showcase</p>",
                                        "maxWidth" : "100%",
                                        "fontSize" : 18
                                    },
                                    "lastUpdate" : 1694611351557.0,
                                    "children" : [

                                    ],
                                    "widgets" : [

                                    ]
                                }
                            ],
                            "widgets" : [

                            ]
                        },
                        {
                            "id" : "9351165994094323288955d35c381a9bdb2a3c3b5146956b3997db00cd4bdc3d",
                            "name" : "content-5",
                            "xtype" : "group",
                            "properties" : {
                                "columnGap" : 18,
                                "rowGap" : 18,
                                "flexDirection" : "column",
                                "justifyContent" : "center",
                                "alignItems" : "center",
                                "display" : "flex",
                                "small" : {
                                    "backgroundAttachment" : "scroll"
                                }
                            },
                            "children" : [
                                {
                                    "id" : "3eec78c528061cc21850b400544bf6c7f24e9cd2f1f50d994375b92d01d4d356",
                                    "name" : "logos",
                                    "xtype" : "group",
                                    "properties" : {
                                        "columnGap" : 36,
                                        "rowGap" : 36,
                                        "justifyContent" : "center",
                                        "alignItems" : "center",
                                        "display" : "flex",
                                        "small" : {
                                            "backgroundAttachment" : "scroll",
                                            "flexDirection" : "column"
                                        }
                                    },
                                    "children" : [
                                        {
                                            "id" : "0ae7c1057b5e1e4e74e9287e5233a74384e0cc16678b27e120d14cbc24e88405",
                                            "name" : "logo-wrapper",
                                            "xtype" : "group",
                                            "properties" : {
                                                "flexDirection" : "column",
                                                "justifyContent" : "center",
                                                "alignItems" : "center",
                                                "display" : "flex",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "5418807fd3699a323de95cc6ac538275ad8cbe0e573b87f9654acbb88b2cd924",
                                                    "name" : "logo-2",
                                                    "xtype" : "image",
                                                    "properties" : {
                                                        "borderTopWidth" : 0,
                                                        "borderRightWidth" : 0,
                                                        "borderBottomWidth" : 0,
                                                        "borderLeftWidth" : 0,
                                                        "borderTopStyle" : "initial",
                                                        "borderRightStyle" : "initial",
                                                        "borderBottomStyle" : "initial",
                                                        "borderLeftStyle" : "initial",
                                                        "borderTopColor" : "initial",
                                                        "borderRightColor" : "initial",
                                                        "borderBottomColor" : "initial",
                                                        "borderLeftColor" : "initial",
                                                        "borderImageSource" : "initial",
                                                        "borderImageSlice" : "initial",
                                                        "borderImageWidth" : "initial",
                                                        "borderImageOutset" : "initial",
                                                        "borderImageRepeat" : "initial",
                                                        "maxWidth" : "100%",
                                                        "verticalAlign" : "middle",
                                                        "display" : "flex",
                                                        "objectFit" : "cover",
                                                        "flexGrow" : "0",
                                                        "flexShrink" : "1",
                                                        "flexBasis" : "auto",
                                                        "justifyContent" : "center",
                                                        "alignItems" : "center",
                                                        "small" : {
                                                            "display" : "inline-block",
                                                            "backgroundAttachment" : "scroll"
                                                        },
                                                        "src" : "https://storage.googleapis.com/quicklinks---staging.appspot.com/assets/content/Hf7F58y0nlVatZxg4L7N6ESu5AL2/Untitled-design-(6).png",
                                                        "alt" : "",
                                                        "maxHeight" : "100%",
                                                        "width" : 52,
                                                        "height" : 41.93548387096774,
                                                        "aspectRatio" : 0.8064516129032258
                                                    },
                                                    "children" : [

                                                    ],
                                                    "lastUpdate" : 1694611226103.0,
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "widgets" : [

                                            ]
                                        },
                                        {
                                            "id" : "f2c5390f890e74b4c61046ec1035901ad063d4c5f52fc024ad3506eaa74580cd",
                                            "name" : "logo-wrapper",
                                            "xtype" : "group",
                                            "properties" : {
                                                "flexDirection" : "column",
                                                "justifyContent" : "center",
                                                "alignItems" : "center",
                                                "display" : "flex",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "45dc8a843d139971c8912973c89a81ae9322fbdf3c742ecdc0ed034cd9d3011a",
                                                    "name" : "logo-2",
                                                    "xtype" : "image",
                                                    "properties" : {
                                                        "borderTopWidth" : 0,
                                                        "borderRightWidth" : 0,
                                                        "borderBottomWidth" : 0,
                                                        "borderLeftWidth" : 0,
                                                        "borderTopStyle" : "initial",
                                                        "borderRightStyle" : "initial",
                                                        "borderBottomStyle" : "initial",
                                                        "borderLeftStyle" : "initial",
                                                        "borderTopColor" : "initial",
                                                        "borderRightColor" : "initial",
                                                        "borderBottomColor" : "initial",
                                                        "borderLeftColor" : "initial",
                                                        "borderImageSource" : "initial",
                                                        "borderImageSlice" : "initial",
                                                        "borderImageWidth" : "initial",
                                                        "borderImageOutset" : "initial",
                                                        "borderImageRepeat" : "initial",
                                                        "maxWidth" : "100%",
                                                        "verticalAlign" : "middle",
                                                        "display" : "flex",
                                                        "objectFit" : "cover",
                                                        "flexGrow" : "0",
                                                        "flexShrink" : "1",
                                                        "flexBasis" : "auto",
                                                        "justifyContent" : "center",
                                                        "alignItems" : "center",
                                                        "small" : {
                                                            "display" : "inline-block",
                                                            "backgroundAttachment" : "scroll"
                                                        },
                                                        "src" : "https://storage.googleapis.com/quicklinks---staging.appspot.com/assets/content/Hf7F58y0nlVatZxg4L7N6ESu5AL2/Untitled-design-(6).png",
                                                        "alt" : "",
                                                        "maxHeight" : "100%",
                                                        "width" : 52,
                                                        "height" : 41.93548387096774,
                                                        "aspectRatio" : 0.8064516129032258
                                                    },
                                                    "children" : [

                                                    ],
                                                    "lastUpdate" : 1694611236794.0,
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "widgets" : [

                                            ]
                                        },
                                        {
                                            "id" : "6164c69132cc95db85526d61e2a27c9aa04a8832ab2e08ee88d4c676988a79b2",
                                            "name" : "logo-wrapper",
                                            "xtype" : "group",
                                            "properties" : {
                                                "flexDirection" : "column",
                                                "justifyContent" : "center",
                                                "alignItems" : "center",
                                                "display" : "flex",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "abf65a51edc657024acc3ced09e0af3f2c955515989d5d2318cb38af4e8fb36e",
                                                    "name" : "logo-2",
                                                    "xtype" : "image",
                                                    "properties" : {
                                                        "borderTopWidth" : 0,
                                                        "borderRightWidth" : 0,
                                                        "borderBottomWidth" : 0,
                                                        "borderLeftWidth" : 0,
                                                        "borderTopStyle" : "initial",
                                                        "borderRightStyle" : "initial",
                                                        "borderBottomStyle" : "initial",
                                                        "borderLeftStyle" : "initial",
                                                        "borderTopColor" : "initial",
                                                        "borderRightColor" : "initial",
                                                        "borderBottomColor" : "initial",
                                                        "borderLeftColor" : "initial",
                                                        "borderImageSource" : "initial",
                                                        "borderImageSlice" : "initial",
                                                        "borderImageWidth" : "initial",
                                                        "borderImageOutset" : "initial",
                                                        "borderImageRepeat" : "initial",
                                                        "maxWidth" : "100%",
                                                        "verticalAlign" : "middle",
                                                        "display" : "flex",
                                                        "objectFit" : "cover",
                                                        "flexGrow" : "0",
                                                        "flexShrink" : "1",
                                                        "flexBasis" : "auto",
                                                        "justifyContent" : "center",
                                                        "alignItems" : "center",
                                                        "small" : {
                                                            "display" : "inline-block",
                                                            "backgroundAttachment" : "scroll"
                                                        },
                                                        "src" : "https://storage.googleapis.com/quicklinks---staging.appspot.com/assets/content/Hf7F58y0nlVatZxg4L7N6ESu5AL2/Untitled-design-(6).png",
                                                        "alt" : "",
                                                        "maxHeight" : "100%",
                                                        "width" : 52,
                                                        "height" : 41.93548387096774,
                                                        "aspectRatio" : 0.8064516129032258
                                                    },
                                                    "children" : [

                                                    ],
                                                    "lastUpdate" : 1694611257626.0,
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "widgets" : [

                                            ]
                                        },
                                        {
                                            "id" : "ee85a62bbeb21651226f8125da45876abdbbc81aa90996223c5163856946c5b3",
                                            "name" : "logo-wrapper",
                                            "xtype" : "group",
                                            "properties" : {
                                                "flexDirection" : "column",
                                                "justifyContent" : "center",
                                                "alignItems" : "center",
                                                "display" : "flex",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "a84625235254d943b9c8293c385c52dd7513791aa280301323dc214592b1e771",
                                                    "name" : "logo-2",
                                                    "xtype" : "image",
                                                    "properties" : {
                                                        "borderTopWidth" : 0,
                                                        "borderRightWidth" : 0,
                                                        "borderBottomWidth" : 0,
                                                        "borderLeftWidth" : 0,
                                                        "borderTopStyle" : "initial",
                                                        "borderRightStyle" : "initial",
                                                        "borderBottomStyle" : "initial",
                                                        "borderLeftStyle" : "initial",
                                                        "borderTopColor" : "initial",
                                                        "borderRightColor" : "initial",
                                                        "borderBottomColor" : "initial",
                                                        "borderLeftColor" : "initial",
                                                        "borderImageSource" : "initial",
                                                        "borderImageSlice" : "initial",
                                                        "borderImageWidth" : "initial",
                                                        "borderImageOutset" : "initial",
                                                        "borderImageRepeat" : "initial",
                                                        "maxWidth" : "100%",
                                                        "verticalAlign" : "middle",
                                                        "display" : "flex",
                                                        "objectFit" : "cover",
                                                        "flexGrow" : "0",
                                                        "flexShrink" : "1",
                                                        "flexBasis" : "auto",
                                                        "justifyContent" : "center",
                                                        "alignItems" : "center",
                                                        "small" : {
                                                            "display" : "inline-block",
                                                            "backgroundAttachment" : "scroll"
                                                        },
                                                        "src" : "https://storage.googleapis.com/quicklinks---staging.appspot.com/assets/content/Hf7F58y0nlVatZxg4L7N6ESu5AL2/Untitled-design-(6).png",
                                                        "alt" : "",
                                                        "maxHeight" : "100%",
                                                        "width" : 52,
                                                        "height" : 41.93548387096774,
                                                        "aspectRatio" : 0.8064516129032258
                                                    },
                                                    "children" : [

                                                    ],
                                                    "lastUpdate" : 1694611267142.0,
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "widgets" : [

                                            ]
                                        },
                                        {
                                            "id" : "04d1e1bd2bee3c2c1852d3eceeb1f727abd017f4a89deca665cbe39f236ed5e8",
                                            "name" : "logo-wrapper",
                                            "xtype" : "group",
                                            "properties" : {
                                                "flexDirection" : "column",
                                                "justifyContent" : "center",
                                                "alignItems" : "center",
                                                "display" : "flex",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "1b48028424096da66584a0008e2cbaa0ee2e58ca0cd6310425c9447b2caf4ad2",
                                                    "name" : "logo-2",
                                                    "xtype" : "image",
                                                    "properties" : {
                                                        "borderTopWidth" : 0,
                                                        "borderRightWidth" : 0,
                                                        "borderBottomWidth" : 0,
                                                        "borderLeftWidth" : 0,
                                                        "borderTopStyle" : "initial",
                                                        "borderRightStyle" : "initial",
                                                        "borderBottomStyle" : "initial",
                                                        "borderLeftStyle" : "initial",
                                                        "borderTopColor" : "initial",
                                                        "borderRightColor" : "initial",
                                                        "borderBottomColor" : "initial",
                                                        "borderLeftColor" : "initial",
                                                        "borderImageSource" : "initial",
                                                        "borderImageSlice" : "initial",
                                                        "borderImageWidth" : "initial",
                                                        "borderImageOutset" : "initial",
                                                        "borderImageRepeat" : "initial",
                                                        "maxWidth" : "100%",
                                                        "verticalAlign" : "middle",
                                                        "display" : "flex",
                                                        "objectFit" : "cover",
                                                        "flexGrow" : "0",
                                                        "flexShrink" : "1",
                                                        "flexBasis" : "auto",
                                                        "justifyContent" : "center",
                                                        "alignItems" : "center",
                                                        "small" : {
                                                            "display" : "inline-block",
                                                            "backgroundAttachment" : "scroll"
                                                        },
                                                        "src" : "https://storage.googleapis.com/quicklinks---staging.appspot.com/assets/content/Hf7F58y0nlVatZxg4L7N6ESu5AL2/Untitled-design-(6).png",
                                                        "alt" : "",
                                                        "maxHeight" : "100%",
                                                        "width" : 52,
                                                        "height" : 41.93548387096774,
                                                        "aspectRatio" : 0.8064516129032258
                                                    },
                                                    "children" : [

                                                    ],
                                                    "lastUpdate" : 1694611285061.0,
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "widgets" : [

                                            ]
                                        },
                                        {
                                            "id" : "32647943f31e67865dd102545a954fc933de2d58629970114802a18341890050",
                                            "name" : "logo-wrapper",
                                            "xtype" : "group",
                                            "properties" : {
                                                "flexDirection" : "column",
                                                "justifyContent" : "center",
                                                "alignItems" : "center",
                                                "display" : "flex",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "ec9f9c18720a5f2bd990f24f32778fda0b0254accb986b8820f469295a41d895",
                                                    "name" : "logo-2",
                                                    "xtype" : "image",
                                                    "properties" : {
                                                        "borderTopWidth" : 0,
                                                        "borderRightWidth" : 0,
                                                        "borderBottomWidth" : 0,
                                                        "borderLeftWidth" : 0,
                                                        "borderTopStyle" : "initial",
                                                        "borderRightStyle" : "initial",
                                                        "borderBottomStyle" : "initial",
                                                        "borderLeftStyle" : "initial",
                                                        "borderTopColor" : "initial",
                                                        "borderRightColor" : "initial",
                                                        "borderBottomColor" : "initial",
                                                        "borderLeftColor" : "initial",
                                                        "borderImageSource" : "initial",
                                                        "borderImageSlice" : "initial",
                                                        "borderImageWidth" : "initial",
                                                        "borderImageOutset" : "initial",
                                                        "borderImageRepeat" : "initial",
                                                        "maxWidth" : "100%",
                                                        "verticalAlign" : "middle",
                                                        "display" : "flex",
                                                        "objectFit" : "cover",
                                                        "flexGrow" : "0",
                                                        "flexShrink" : "1",
                                                        "flexBasis" : "auto",
                                                        "justifyContent" : "center",
                                                        "alignItems" : "center",
                                                        "small" : {
                                                            "display" : "inline-block",
                                                            "backgroundAttachment" : "scroll"
                                                        },
                                                        "src" : "https://storage.googleapis.com/quicklinks---staging.appspot.com/assets/content/Hf7F58y0nlVatZxg4L7N6ESu5AL2/Untitled-design-(6).png",
                                                        "alt" : "",
                                                        "maxHeight" : "100%",
                                                        "width" : 52,
                                                        "height" : 41.93548387096774,
                                                        "aspectRatio" : 0.8064516129032258
                                                    },
                                                    "children" : [

                                                    ],
                                                    "lastUpdate" : 1694611305672.0,
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "widgets" : [

                                    ]
                                }
                            ],
                            "widgets" : [

                            ]
                        }
                    ],
                    "lastUpdate" : 1694611641840.0,
                    "widgets" : [

                    ]
                },
                {
                    "id" : "e8ceb1edca9248e81b5a5ddd1dad1d6631898427422ccaa78781becd90429b9a",
                    "name" : "pricing-items",
                    "xtype" : "group",
                    "properties" : {
                        "columnGap" : 60,
                        "rowGap" : 60,
                        "justifyContent" : "center",
                        "alignItems" : "flex-start",
                        "paddingTop" : 48,
                        "paddingRight" : 18,
                        "paddingBottom" : 48,
                        "paddingLeft" : 18,
                        "display" : "flex",
                        "small" : {
                            "backgroundAttachment" : "scroll"
                        }
                    },
                    "children" : [
                        {
                            "id" : "f37345c2eab8f2744bedaff1b989ee60b218c0cada95890911c24568d8b19d3d",
                            "name" : "small-container-4",
                            "xtype" : "group",
                            "properties" : {
                                "maxWidth" : 720,
                                "columnGap" : 30,
                                "rowGap" : 30,
                                "flexDirection" : "column",
                                "justifyContent" : "center",
                                "alignItems" : "flex-start",
                                "display" : "flex",
                                "small" : {
                                    "backgroundAttachment" : "scroll"
                                }
                            },
                            "children" : [
                                {
                                    "id" : "9e966aad2e1918c61f3bdaa994b9867c4c8b1d1c56144dd3f736ae97fed4735d",
                                    "name" : "pricing-item",
                                    "xtype" : "group",
                                    "properties" : {
                                        "columnGap" : 30,
                                        "rowGap" : 30,
                                        "backgroundColor" : "rgb(245, 247, 250)",
                                        "justifyContent" : "flex-start",
                                        "alignItems" : "center",
                                        "paddingTop" : 30,
                                        "paddingRight" : 24,
                                        "paddingBottom" : 30,
                                        "paddingLeft" : 24,
                                        "display" : "flex",
                                        "small" : {
                                            "backgroundAttachment" : "scroll",
                                            "flexDirection" : "column"
                                        },
                                        "width" : 528
                                    },
                                    "children" : [
                                        {
                                            "id" : "ef09b2201378e2f5ef15e9963be241c3230c715ea78620d7706aa0c92017a8cd",
                                            "name" : "image-wrapper-9",
                                            "xtype" : "group",
                                            "properties" : {
                                                "maxWidth" : 195,
                                                "flexDirection" : "column",
                                                "justifyContent" : "center",
                                                "alignItems" : "center",
                                                "display" : "flex",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "a6e7a7af7999b6ded9fe918253e9fd1ac0b6c64ad81882d15371093b11e9fb1f",
                                                    "name" : "image-15",
                                                    "xtype" : "image",
                                                    "properties" : {
                                                        "borderTopWidth" : 0,
                                                        "borderRightWidth" : 0,
                                                        "borderBottomWidth" : 0,
                                                        "borderLeftWidth" : 0,
                                                        "borderTopStyle" : "initial",
                                                        "borderRightStyle" : "initial",
                                                        "borderBottomStyle" : "initial",
                                                        "borderLeftStyle" : "initial",
                                                        "borderTopColor" : "initial",
                                                        "borderRightColor" : "initial",
                                                        "borderBottomColor" : "initial",
                                                        "borderLeftColor" : "initial",
                                                        "borderImageSource" : "initial",
                                                        "borderImageSlice" : "initial",
                                                        "borderImageWidth" : "initial",
                                                        "borderImageOutset" : "initial",
                                                        "borderImageRepeat" : "initial",
                                                        "maxWidth" : "100%",
                                                        "verticalAlign" : "middle",
                                                        "display" : "flex",
                                                        "objectFit" : "cover",
                                                        "justifyContent" : "center",
                                                        "alignItems" : "center",
                                                        "small" : {
                                                            "display" : "inline-block",
                                                            "backgroundAttachment" : "scroll"
                                                        },
                                                        "src" : "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f915244f19a7a6e6b124cf_image.png",
                                                        "alt" : "",
                                                        "maxHeight" : "100%"
                                                    },
                                                    "children" : [

                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "widgets" : [

                                            ]
                                        },
                                        {
                                            "id" : "de5f61d2516a49d9eda9ec3c67f448740c69fdbf052631bf896ea05ab5f3592b",
                                            "name" : "info-3",
                                            "xtype" : "group",
                                            "properties" : {
                                                "columnGap" : 12,
                                                "rowGap" : 12,
                                                "flexDirection" : "column",
                                                "justifyContent" : "flex-start",
                                                "alignItems" : "flex-start",
                                                "paddingTop" : 12,
                                                "paddingRight" : 12,
                                                "paddingBottom" : 12,
                                                "paddingLeft" : 12,
                                                "display" : "flex",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "92ff9fc2cf38dd7dbce32c3f002b4e2ae100cd39399870890a14129d23e7a6f2",
                                                    "name" : "text-39",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "fontSize" : 10.5,
                                                        "fontWeight" : "400",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "57e9f660f85f791a46d26501e607fd929d8b293850b1881201cf9ec4a51e9ef8",
                                                            "xtype" : "label",
                                                            "properties" : {
                                                                "content" : "<p>Tasting Notes</p>",
                                                                "maxWidth" : "100%"
                                                            },
                                                            "lastUpdate" : 1694608949071.0,
                                                            "children" : [

                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "831ca1116a9657873e1727d53d96fbf9b1c718fd872ad08d18c868662f55e4ac",
                                                    "name" : "pricing-info",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "justifyContent" : "space-between",
                                                        "alignItems" : "center",
                                                        "paddingTop" : 12,
                                                        "paddingBottom" : 12,
                                                        "display" : "flex",
                                                        "boxShadow" : null,
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll",
                                                            "flexDirection" : "column"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "47f7ee21ecef439c6f7296817ad17189f7754b0339d6d5f4fd80cfc2523e9b11",
                                                            "name" : "text-40",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "fontSize" : 18,
                                                                "fontWeight" : "700",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "8f1c2207d5fd48565f6305985b28085d94659b325a88e7113f2aca1066215587",
                                                                    "xtype" : "label",
                                                                    "properties" : {
                                                                        "content" : "<p>Product #1</p>",
                                                                        "maxWidth" : "100%"
                                                                    },
                                                                    "lastUpdate" : 1694608650675.0,
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "e2e7363270481ec130a7bc2809b9d47a9e536e7073b96b889030968c047100ec",
                                                    "name" : "pricing-details",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "justifyContent" : "space-between",
                                                        "alignItems" : "center",
                                                        "display" : "flex",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll",
                                                            "flexDirection" : "column"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "0ae20d0c6a47490a8b1791b4ae62c9101199e7efc7030f5d1ab6840662a44713",
                                                            "name" : "detail",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "columnGap" : 3,
                                                                "rowGap" : 3,
                                                                "flexDirection" : "column",
                                                                "justifyContent" : "flex-start",
                                                                "alignItems" : "flex-start",
                                                                "display" : "flex",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "d5bbe1efe4702ef7dfd2cbe0ef5becaa7f7c8cb9e1e3c2c7e4b375f44291e530",
                                                                    "name" : "text-39",
                                                                    "xtype" : "group",
                                                                    "properties" : {
                                                                        "fontSize" : 10.5,
                                                                        "fontWeight" : "400",
                                                                        "small" : {
                                                                            "backgroundAttachment" : "scroll"
                                                                        }
                                                                    },
                                                                    "children" : [
                                                                        {
                                                                            "id" : "38be7bd8c9c430eef0500db782e41ba5995301aed95f22b97621f40ee3c7d781",
                                                                            "xtype" : "label",
                                                                            "properties" : {
                                                                                "content" : "<p>ABV 5.0%</p>",
                                                                                "maxWidth" : "100%"
                                                                            },
                                                                            "lastUpdate" : 1694608731523.0,
                                                                            "children" : [

                                                                            ],
                                                                            "widgets" : [

                                                                            ]
                                                                        }
                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                },
                                                                {
                                                                    "id" : "dfcc308ae667baafa9ff3f9c06e6c5c7bc739e984c82f79c33ef531676b0dc40",
                                                                    "name" : "description-5",
                                                                    "xtype" : "group",
                                                                    "properties" : {
                                                                        "fontWeight" : "400",
                                                                        "small" : {
                                                                            "backgroundAttachment" : "scroll"
                                                                        }
                                                                    },
                                                                    "children" : [
                                                                        {
                                                                            "id" : "2cc7e47724b8070c0bd014f4a971f45e47200fc0a3b116fe20b731d7b7a077e5",
                                                                            "xtype" : "label",
                                                                            "properties" : {
                                                                                "content" : "<p>Product Description</p>",
                                                                                "maxWidth" : "100%"
                                                                            },
                                                                            "children" : [

                                                                            ],
                                                                            "widgets" : [

                                                                            ],
                                                                            "lastUpdate" : 1694611713081.0
                                                                        }
                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "grouped" : false,
                                            "lastUpdate" : 1694608821898.0,
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "lastUpdate" : 1694608907938.0,
                                    "widgets" : [

                                    ]
                                },
                                {
                                    "id" : "widget-FluRSPs7A5-44gfOLts-5",
                                    "name" : "pricing-item",
                                    "xtype" : "group",
                                    "properties" : {
                                        "columnGap" : 30,
                                        "rowGap" : 30,
                                        "backgroundColor" : "rgb(245, 247, 250)",
                                        "justifyContent" : "flex-start",
                                        "alignItems" : "center",
                                        "paddingTop" : 30,
                                        "paddingRight" : 24,
                                        "paddingBottom" : 30,
                                        "paddingLeft" : 24,
                                        "display" : "flex",
                                        "small" : {
                                            "backgroundAttachment" : "scroll",
                                            "flexDirection" : "column"
                                        },
                                        "width" : 528
                                    },
                                    "children" : [
                                        {
                                            "id" : "widget-OxxYfr-KRchllU9iA8Rft",
                                            "name" : "image-wrapper-9",
                                            "xtype" : "group",
                                            "properties" : {
                                                "maxWidth" : 195,
                                                "flexDirection" : "column",
                                                "justifyContent" : "center",
                                                "alignItems" : "center",
                                                "display" : "flex",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "widget-dOia5ui1vyMaI8roUnnq-",
                                                    "name" : "image-15",
                                                    "xtype" : "image",
                                                    "properties" : {
                                                        "borderTopWidth" : 0,
                                                        "borderRightWidth" : 0,
                                                        "borderBottomWidth" : 0,
                                                        "borderLeftWidth" : 0,
                                                        "borderTopStyle" : "initial",
                                                        "borderRightStyle" : "initial",
                                                        "borderBottomStyle" : "initial",
                                                        "borderLeftStyle" : "initial",
                                                        "borderTopColor" : "initial",
                                                        "borderRightColor" : "initial",
                                                        "borderBottomColor" : "initial",
                                                        "borderLeftColor" : "initial",
                                                        "borderImageSource" : "initial",
                                                        "borderImageSlice" : "initial",
                                                        "borderImageWidth" : "initial",
                                                        "borderImageOutset" : "initial",
                                                        "borderImageRepeat" : "initial",
                                                        "maxWidth" : "100%",
                                                        "verticalAlign" : "middle",
                                                        "display" : "flex",
                                                        "objectFit" : "cover",
                                                        "justifyContent" : "center",
                                                        "alignItems" : "center",
                                                        "small" : {
                                                            "display" : "inline-block",
                                                            "backgroundAttachment" : "scroll"
                                                        },
                                                        "src" : "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f915244f19a7a6e6b124cf_image.png",
                                                        "alt" : "",
                                                        "maxHeight" : "100%"
                                                    },
                                                    "children" : [

                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "widgets" : [

                                            ]
                                        },
                                        {
                                            "id" : "widget-pyJdtCapEqm38g86waGrI",
                                            "name" : "info-3",
                                            "xtype" : "group",
                                            "properties" : {
                                                "columnGap" : 12,
                                                "rowGap" : 12,
                                                "flexDirection" : "column",
                                                "justifyContent" : "flex-start",
                                                "alignItems" : "flex-start",
                                                "paddingTop" : 12,
                                                "paddingRight" : 12,
                                                "paddingBottom" : 12,
                                                "paddingLeft" : 12,
                                                "display" : "flex",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "widget-xiT-CCtlFl3zolnKpC4Rq",
                                                    "name" : "text-39",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "fontSize" : 10.5,
                                                        "fontWeight" : "400",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "widget-iV808DoKMM1PM_Tx91etL",
                                                            "xtype" : "label",
                                                            "properties" : {
                                                                "content" : "<p>Tasting Notes</p>",
                                                                "maxWidth" : "100%"
                                                            },
                                                            "lastUpdate" : 1694608949071.0,
                                                            "children" : [

                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "widget-GPn2ndGIvj_1ZsDGUx0gH",
                                                    "name" : "pricing-info",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "justifyContent" : "space-between",
                                                        "alignItems" : "center",
                                                        "paddingTop" : 12,
                                                        "paddingBottom" : 12,
                                                        "display" : "flex",
                                                        "boxShadow" : null,
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll",
                                                            "flexDirection" : "column"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "widget-rHUdDBR0Az9VbykAKw0KI",
                                                            "name" : "text-40",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "fontSize" : 18,
                                                                "fontWeight" : "700",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "widget-N_PB6d0z1kAfeZK_rVRiz",
                                                                    "xtype" : "label",
                                                                    "properties" : {
                                                                        "content" : "<p>Product #2</p>",
                                                                        "maxWidth" : "100%"
                                                                    },
                                                                    "lastUpdate" : 1694609064133.0,
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "widget-JY-zlj-F3RCX6md0kk7qS",
                                                    "name" : "pricing-details",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "justifyContent" : "space-between",
                                                        "alignItems" : "center",
                                                        "display" : "flex",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll",
                                                            "flexDirection" : "column"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "widget-mnjv3zcDijTMWjpgb6gvu",
                                                            "name" : "detail",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "columnGap" : 3,
                                                                "rowGap" : 3,
                                                                "flexDirection" : "column",
                                                                "justifyContent" : "flex-start",
                                                                "alignItems" : "flex-start",
                                                                "display" : "flex",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "widget-bTh_J4PvflIng9Snv3JMv",
                                                                    "name" : "text-39",
                                                                    "xtype" : "group",
                                                                    "properties" : {
                                                                        "fontSize" : 10.5,
                                                                        "fontWeight" : "400",
                                                                        "small" : {
                                                                            "backgroundAttachment" : "scroll"
                                                                        }
                                                                    },
                                                                    "children" : [
                                                                        {
                                                                            "id" : "widget-QxgJQGRKflVvjS4r1uuUa",
                                                                            "xtype" : "label",
                                                                            "properties" : {
                                                                                "content" : "<p>ABV 5.0%</p>",
                                                                                "maxWidth" : "100%"
                                                                            },
                                                                            "lastUpdate" : 1694608731523.0,
                                                                            "children" : [

                                                                            ],
                                                                            "widgets" : [

                                                                            ]
                                                                        }
                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                },
                                                                {
                                                                    "id" : "widget-RygtBVDUkdv_NphEGUOtO",
                                                                    "name" : "description-5",
                                                                    "xtype" : "group",
                                                                    "properties" : {
                                                                        "fontWeight" : "400",
                                                                        "small" : {
                                                                            "backgroundAttachment" : "scroll"
                                                                        }
                                                                    },
                                                                    "children" : [
                                                                        {
                                                                            "id" : "widget-l8ulnSectNEFAKAOG1PMn",
                                                                            "xtype" : "label",
                                                                            "properties" : {
                                                                                "content" : "<p>Product Description</p>",
                                                                                "maxWidth" : "100%"
                                                                            },
                                                                            "children" : [

                                                                            ],
                                                                            "widgets" : [

                                                                            ],
                                                                            "lastUpdate" : 1694611717444.0
                                                                        }
                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "grouped" : false,
                                            "lastUpdate" : 1694608821898.0,
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "lastUpdate" : 1694608907938.0,
                                    "widgets" : [

                                    ]
                                },
                                {
                                    "id" : "widget-w3CHJ-YbDNVHUEamhaX8v",
                                    "name" : "pricing-item",
                                    "xtype" : "group",
                                    "properties" : {
                                        "columnGap" : 30,
                                        "rowGap" : 30,
                                        "backgroundColor" : "rgb(245, 247, 250)",
                                        "justifyContent" : "flex-start",
                                        "alignItems" : "center",
                                        "paddingTop" : 30,
                                        "paddingRight" : 24,
                                        "paddingBottom" : 30,
                                        "paddingLeft" : 24,
                                        "display" : "flex",
                                        "small" : {
                                            "backgroundAttachment" : "scroll",
                                            "flexDirection" : "column"
                                        },
                                        "width" : 528
                                    },
                                    "children" : [
                                        {
                                            "id" : "widget-1YWDM8ET5o710eAJG8D_s",
                                            "name" : "image-wrapper-9",
                                            "xtype" : "group",
                                            "properties" : {
                                                "maxWidth" : 195,
                                                "flexDirection" : "column",
                                                "justifyContent" : "center",
                                                "alignItems" : "center",
                                                "display" : "flex",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "widget-gk-D8aLnUP41rIiRPA1nx",
                                                    "name" : "image-15",
                                                    "xtype" : "image",
                                                    "properties" : {
                                                        "borderTopWidth" : 0,
                                                        "borderRightWidth" : 0,
                                                        "borderBottomWidth" : 0,
                                                        "borderLeftWidth" : 0,
                                                        "borderTopStyle" : "initial",
                                                        "borderRightStyle" : "initial",
                                                        "borderBottomStyle" : "initial",
                                                        "borderLeftStyle" : "initial",
                                                        "borderTopColor" : "initial",
                                                        "borderRightColor" : "initial",
                                                        "borderBottomColor" : "initial",
                                                        "borderLeftColor" : "initial",
                                                        "borderImageSource" : "initial",
                                                        "borderImageSlice" : "initial",
                                                        "borderImageWidth" : "initial",
                                                        "borderImageOutset" : "initial",
                                                        "borderImageRepeat" : "initial",
                                                        "maxWidth" : "100%",
                                                        "verticalAlign" : "middle",
                                                        "display" : "flex",
                                                        "objectFit" : "cover",
                                                        "justifyContent" : "center",
                                                        "alignItems" : "center",
                                                        "small" : {
                                                            "display" : "inline-block",
                                                            "backgroundAttachment" : "scroll"
                                                        },
                                                        "src" : "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f915244f19a7a6e6b124cf_image.png",
                                                        "alt" : "",
                                                        "maxHeight" : "100%"
                                                    },
                                                    "children" : [

                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "widgets" : [

                                            ]
                                        },
                                        {
                                            "id" : "widget-4Cz-MY6WIqXj8V96lLPw6",
                                            "name" : "info-3",
                                            "xtype" : "group",
                                            "properties" : {
                                                "columnGap" : 12,
                                                "rowGap" : 12,
                                                "flexDirection" : "column",
                                                "justifyContent" : "flex-start",
                                                "alignItems" : "flex-start",
                                                "paddingTop" : 12,
                                                "paddingRight" : 12,
                                                "paddingBottom" : 12,
                                                "paddingLeft" : 12,
                                                "display" : "flex",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "widget-jYwBqZ1iT03HYd1ce8xnA",
                                                    "name" : "text-39",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "fontSize" : 10.5,
                                                        "fontWeight" : "400",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "widget-RmD65zjtCmnsh18Mq0r0v",
                                                            "xtype" : "label",
                                                            "properties" : {
                                                                "content" : "<p>Tasting Notes</p>",
                                                                "maxWidth" : "100%"
                                                            },
                                                            "lastUpdate" : 1694608949071.0,
                                                            "children" : [

                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "widget-w16YIHUQGhmiiW4bx6oDM",
                                                    "name" : "pricing-info",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "justifyContent" : "space-between",
                                                        "alignItems" : "center",
                                                        "paddingTop" : 12,
                                                        "paddingBottom" : 12,
                                                        "display" : "flex",
                                                        "boxShadow" : null,
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll",
                                                            "flexDirection" : "column"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "widget-AMKO-TSLnfQa6o1zOjWN1",
                                                            "name" : "text-40",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "fontSize" : 18,
                                                                "fontWeight" : "700",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "widget-vqktEaSmCkSCjzTFCWYBr",
                                                                    "xtype" : "label",
                                                                    "properties" : {
                                                                        "content" : "<p>Product #3</p>",
                                                                        "maxWidth" : "100%"
                                                                    },
                                                                    "lastUpdate" : 1694609067592.0,
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "widget-XWyxL5AZ4CIvrYwt3c28x",
                                                    "name" : "pricing-details",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "justifyContent" : "space-between",
                                                        "alignItems" : "center",
                                                        "display" : "flex",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll",
                                                            "flexDirection" : "column"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "widget-rLd1eFZiWiGWCEdBukCz_",
                                                            "name" : "detail",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "columnGap" : 3,
                                                                "rowGap" : 3,
                                                                "flexDirection" : "column",
                                                                "justifyContent" : "flex-start",
                                                                "alignItems" : "flex-start",
                                                                "display" : "flex",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "widget-rcSU_PKM7HZJnlLDbGmYN",
                                                                    "name" : "text-39",
                                                                    "xtype" : "group",
                                                                    "properties" : {
                                                                        "fontSize" : 10.5,
                                                                        "fontWeight" : "400",
                                                                        "small" : {
                                                                            "backgroundAttachment" : "scroll"
                                                                        }
                                                                    },
                                                                    "children" : [
                                                                        {
                                                                            "id" : "widget-n23zlsLWoRWN0Bt58hUOY",
                                                                            "xtype" : "label",
                                                                            "properties" : {
                                                                                "content" : "<p>ABV 5.0%</p>",
                                                                                "maxWidth" : "100%"
                                                                            },
                                                                            "lastUpdate" : 1694608731523.0,
                                                                            "children" : [

                                                                            ],
                                                                            "widgets" : [

                                                                            ]
                                                                        }
                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                },
                                                                {
                                                                    "id" : "widget-xWSucaBa13uZ3_rTZ79HC",
                                                                    "name" : "description-5",
                                                                    "xtype" : "group",
                                                                    "properties" : {
                                                                        "fontWeight" : "400",
                                                                        "small" : {
                                                                            "backgroundAttachment" : "scroll"
                                                                        }
                                                                    },
                                                                    "children" : [
                                                                        {
                                                                            "id" : "widget-1vIZ33cMasf-TVEb7ix1w",
                                                                            "xtype" : "label",
                                                                            "properties" : {
                                                                                "content" : "<p>Product Description</p>",
                                                                                "maxWidth" : "100%"
                                                                            },
                                                                            "children" : [

                                                                            ],
                                                                            "widgets" : [

                                                                            ],
                                                                            "lastUpdate" : 1694611720369.0
                                                                        }
                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "grouped" : false,
                                            "lastUpdate" : 1694608821898.0,
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "lastUpdate" : 1694608907938.0,
                                    "widgets" : [

                                    ]
                                },
                                {
                                    "id" : "widget-QxNqvN6gQsSD6-_PfJKQY",
                                    "name" : "pricing-item",
                                    "xtype" : "group",
                                    "properties" : {
                                        "columnGap" : 30,
                                        "rowGap" : 30,
                                        "backgroundColor" : "rgb(245, 247, 250)",
                                        "justifyContent" : "flex-start",
                                        "alignItems" : "center",
                                        "paddingTop" : 30,
                                        "paddingRight" : 24,
                                        "paddingBottom" : 30,
                                        "paddingLeft" : 24,
                                        "display" : "flex",
                                        "small" : {
                                            "backgroundAttachment" : "scroll",
                                            "flexDirection" : "column"
                                        },
                                        "width" : 528
                                    },
                                    "children" : [
                                        {
                                            "id" : "widget-k-vUpxTHbgDiE2bVCDLA3",
                                            "name" : "image-wrapper-9",
                                            "xtype" : "group",
                                            "properties" : {
                                                "maxWidth" : 195,
                                                "flexDirection" : "column",
                                                "justifyContent" : "center",
                                                "alignItems" : "center",
                                                "display" : "flex",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "widget-UJKRVJ6BlZ1-w8vEN85B_",
                                                    "name" : "image-15",
                                                    "xtype" : "image",
                                                    "properties" : {
                                                        "borderTopWidth" : 0,
                                                        "borderRightWidth" : 0,
                                                        "borderBottomWidth" : 0,
                                                        "borderLeftWidth" : 0,
                                                        "borderTopStyle" : "initial",
                                                        "borderRightStyle" : "initial",
                                                        "borderBottomStyle" : "initial",
                                                        "borderLeftStyle" : "initial",
                                                        "borderTopColor" : "initial",
                                                        "borderRightColor" : "initial",
                                                        "borderBottomColor" : "initial",
                                                        "borderLeftColor" : "initial",
                                                        "borderImageSource" : "initial",
                                                        "borderImageSlice" : "initial",
                                                        "borderImageWidth" : "initial",
                                                        "borderImageOutset" : "initial",
                                                        "borderImageRepeat" : "initial",
                                                        "maxWidth" : "100%",
                                                        "verticalAlign" : "middle",
                                                        "display" : "flex",
                                                        "objectFit" : "cover",
                                                        "justifyContent" : "center",
                                                        "alignItems" : "center",
                                                        "small" : {
                                                            "display" : "inline-block",
                                                            "backgroundAttachment" : "scroll"
                                                        },
                                                        "src" : "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f915244f19a7a6e6b124cf_image.png",
                                                        "alt" : "",
                                                        "maxHeight" : "100%"
                                                    },
                                                    "children" : [

                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "widgets" : [

                                            ]
                                        },
                                        {
                                            "id" : "widget-7A6D3nZdF4OAfbbbAhJaF",
                                            "name" : "info-3",
                                            "xtype" : "group",
                                            "properties" : {
                                                "columnGap" : 12,
                                                "rowGap" : 12,
                                                "flexDirection" : "column",
                                                "justifyContent" : "flex-start",
                                                "alignItems" : "flex-start",
                                                "paddingTop" : 12,
                                                "paddingRight" : 12,
                                                "paddingBottom" : 12,
                                                "paddingLeft" : 12,
                                                "display" : "flex",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "widget-tALuyl-PU1bS7pWpNRwEc",
                                                    "name" : "text-39",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "fontSize" : 10.5,
                                                        "fontWeight" : "400",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "widget-blw6h45jPwqnBYvQLFsPE",
                                                            "xtype" : "label",
                                                            "properties" : {
                                                                "content" : "<p>Tasting Notes</p>",
                                                                "maxWidth" : "100%"
                                                            },
                                                            "lastUpdate" : 1694608949071.0,
                                                            "children" : [

                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "widget-qtVhA_eNNmYHB6jv2oEaA",
                                                    "name" : "pricing-info",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "justifyContent" : "space-between",
                                                        "alignItems" : "center",
                                                        "paddingTop" : 12,
                                                        "paddingBottom" : 12,
                                                        "display" : "flex",
                                                        "boxShadow" : null,
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll",
                                                            "flexDirection" : "column"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "widget-lGvataDU86cscEu4Rly2Z",
                                                            "name" : "text-40",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "fontSize" : 18,
                                                                "fontWeight" : "700",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "widget-EAFyuZibqq5PR1aTz2UzX",
                                                                    "xtype" : "label",
                                                                    "properties" : {
                                                                        "content" : "<p>Product #4</p>",
                                                                        "maxWidth" : "100%"
                                                                    },
                                                                    "lastUpdate" : 1694609071849.0,
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "widget-30ul0mbwb73QxPhnZvgbK",
                                                    "name" : "pricing-details",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "justifyContent" : "space-between",
                                                        "alignItems" : "center",
                                                        "display" : "flex",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll",
                                                            "flexDirection" : "column"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "widget-Ytrvza4XjZDqRW_O682it",
                                                            "name" : "detail",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "columnGap" : 3,
                                                                "rowGap" : 3,
                                                                "flexDirection" : "column",
                                                                "justifyContent" : "flex-start",
                                                                "alignItems" : "flex-start",
                                                                "display" : "flex",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "widget-Y3zGLF1FY6q_z6sc5OE60",
                                                                    "name" : "text-39",
                                                                    "xtype" : "group",
                                                                    "properties" : {
                                                                        "fontSize" : 10.5,
                                                                        "fontWeight" : "400",
                                                                        "small" : {
                                                                            "backgroundAttachment" : "scroll"
                                                                        }
                                                                    },
                                                                    "children" : [
                                                                        {
                                                                            "id" : "widget-hIQmhChZc0Y5p3JauQEsS",
                                                                            "xtype" : "label",
                                                                            "properties" : {
                                                                                "content" : "<p>ABV 5.0%</p>",
                                                                                "maxWidth" : "100%"
                                                                            },
                                                                            "lastUpdate" : 1694608731523.0,
                                                                            "children" : [

                                                                            ],
                                                                            "widgets" : [

                                                                            ]
                                                                        }
                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                },
                                                                {
                                                                    "id" : "widget-uR87f86R6dSDk-DKeg_iW",
                                                                    "name" : "description-5",
                                                                    "xtype" : "group",
                                                                    "properties" : {
                                                                        "fontWeight" : "400",
                                                                        "small" : {
                                                                            "backgroundAttachment" : "scroll"
                                                                        }
                                                                    },
                                                                    "children" : [
                                                                        {
                                                                            "id" : "widget-uFKW7eigipVqASdLqfSP1",
                                                                            "xtype" : "label",
                                                                            "properties" : {
                                                                                "content" : "<p>Product Description</p>",
                                                                                "maxWidth" : "100%"
                                                                            },
                                                                            "children" : [

                                                                            ],
                                                                            "widgets" : [

                                                                            ],
                                                                            "lastUpdate" : 1694611724029.0
                                                                        }
                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "grouped" : false,
                                            "lastUpdate" : 1694608821898.0,
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "lastUpdate" : 1694608907938.0,
                                    "widgets" : [

                                    ]
                                },
                                {
                                    "id" : "widget-4b_eCElaN9oBx_Dyd4Y9w",
                                    "name" : "pricing-item",
                                    "xtype" : "group",
                                    "properties" : {
                                        "columnGap" : 30,
                                        "rowGap" : 30,
                                        "backgroundColor" : "rgb(245, 247, 250)",
                                        "justifyContent" : "flex-start",
                                        "alignItems" : "center",
                                        "paddingTop" : 30,
                                        "paddingRight" : 24,
                                        "paddingBottom" : 30,
                                        "paddingLeft" : 24,
                                        "display" : "flex",
                                        "small" : {
                                            "backgroundAttachment" : "scroll",
                                            "flexDirection" : "column"
                                        },
                                        "width" : 528
                                    },
                                    "children" : [
                                        {
                                            "id" : "widget-oJhYGiB8rTmyGvfBEUoBT",
                                            "name" : "image-wrapper-9",
                                            "xtype" : "group",
                                            "properties" : {
                                                "maxWidth" : 195,
                                                "flexDirection" : "column",
                                                "justifyContent" : "center",
                                                "alignItems" : "center",
                                                "display" : "flex",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "widget-fnyqL7ToOb79v2QK5awmD",
                                                    "name" : "image-15",
                                                    "xtype" : "image",
                                                    "properties" : {
                                                        "borderTopWidth" : 0,
                                                        "borderRightWidth" : 0,
                                                        "borderBottomWidth" : 0,
                                                        "borderLeftWidth" : 0,
                                                        "borderTopStyle" : "initial",
                                                        "borderRightStyle" : "initial",
                                                        "borderBottomStyle" : "initial",
                                                        "borderLeftStyle" : "initial",
                                                        "borderTopColor" : "initial",
                                                        "borderRightColor" : "initial",
                                                        "borderBottomColor" : "initial",
                                                        "borderLeftColor" : "initial",
                                                        "borderImageSource" : "initial",
                                                        "borderImageSlice" : "initial",
                                                        "borderImageWidth" : "initial",
                                                        "borderImageOutset" : "initial",
                                                        "borderImageRepeat" : "initial",
                                                        "maxWidth" : "100%",
                                                        "verticalAlign" : "middle",
                                                        "display" : "flex",
                                                        "objectFit" : "cover",
                                                        "justifyContent" : "center",
                                                        "alignItems" : "center",
                                                        "small" : {
                                                            "display" : "inline-block",
                                                            "backgroundAttachment" : "scroll"
                                                        },
                                                        "src" : "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f915244f19a7a6e6b124cf_image.png",
                                                        "alt" : "",
                                                        "maxHeight" : "100%"
                                                    },
                                                    "children" : [

                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "widgets" : [

                                            ]
                                        },
                                        {
                                            "id" : "widget-x_FsWk_ulc0QQvvPODxe1",
                                            "name" : "info-3",
                                            "xtype" : "group",
                                            "properties" : {
                                                "columnGap" : 12,
                                                "rowGap" : 12,
                                                "flexDirection" : "column",
                                                "justifyContent" : "flex-start",
                                                "alignItems" : "flex-start",
                                                "paddingTop" : 12,
                                                "paddingRight" : 12,
                                                "paddingBottom" : 12,
                                                "paddingLeft" : 12,
                                                "display" : "flex",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "widget-2RdIHgHMnkDBeJl1MTO23",
                                                    "name" : "text-39",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "fontSize" : 10.5,
                                                        "fontWeight" : "400",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "widget-6fmyYtQPJgjNaX899bUub",
                                                            "xtype" : "label",
                                                            "properties" : {
                                                                "content" : "<p>Tasting Notes</p>",
                                                                "maxWidth" : "100%"
                                                            },
                                                            "lastUpdate" : 1694608949071.0,
                                                            "children" : [

                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "widget-dUCdGuT-7FmcswMB_JjMW",
                                                    "name" : "pricing-info",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "justifyContent" : "space-between",
                                                        "alignItems" : "center",
                                                        "paddingTop" : 12,
                                                        "paddingBottom" : 12,
                                                        "display" : "flex",
                                                        "boxShadow" : null,
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll",
                                                            "flexDirection" : "column"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "widget-zHSsGrQlOiXlYsOV2b7Hu",
                                                            "name" : "text-40",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "fontSize" : 18,
                                                                "fontWeight" : "700",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "widget-zvTCXVOLSVsVw5HLTqnYn",
                                                                    "xtype" : "label",
                                                                    "properties" : {
                                                                        "content" : "<p>Product #5</p>",
                                                                        "maxWidth" : "100%"
                                                                    },
                                                                    "lastUpdate" : 1694609086181.0,
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "widget-C9D_QzMc_Ql1aUlCbx-Ur",
                                                    "name" : "pricing-details",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "justifyContent" : "space-between",
                                                        "alignItems" : "center",
                                                        "display" : "flex",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll",
                                                            "flexDirection" : "column"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "widget-zL3laTDWkumPPHSZa90rV",
                                                            "name" : "detail",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "columnGap" : 3,
                                                                "rowGap" : 3,
                                                                "flexDirection" : "column",
                                                                "justifyContent" : "flex-start",
                                                                "alignItems" : "flex-start",
                                                                "display" : "flex",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "widget-jOHtx1ZkC9Qg4_fmjdhox",
                                                                    "name" : "text-39",
                                                                    "xtype" : "group",
                                                                    "properties" : {
                                                                        "fontSize" : 10.5,
                                                                        "fontWeight" : "400",
                                                                        "small" : {
                                                                            "backgroundAttachment" : "scroll"
                                                                        }
                                                                    },
                                                                    "children" : [
                                                                        {
                                                                            "id" : "widget-_pojen3eN4b4kUmt6ySgu",
                                                                            "xtype" : "label",
                                                                            "properties" : {
                                                                                "content" : "<p>ABV 5.0%</p>",
                                                                                "maxWidth" : "100%"
                                                                            },
                                                                            "lastUpdate" : 1694608731523.0,
                                                                            "children" : [

                                                                            ],
                                                                            "widgets" : [

                                                                            ]
                                                                        }
                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                },
                                                                {
                                                                    "id" : "widget-uP6fwQMza6nBedHolBk1l",
                                                                    "name" : "description-5",
                                                                    "xtype" : "group",
                                                                    "properties" : {
                                                                        "fontWeight" : "400",
                                                                        "small" : {
                                                                            "backgroundAttachment" : "scroll"
                                                                        }
                                                                    },
                                                                    "children" : [
                                                                        {
                                                                            "id" : "widget-4J1_0OUKPZ2gh0At_uYjO",
                                                                            "xtype" : "label",
                                                                            "properties" : {
                                                                                "content" : "<p>Product Description</p>",
                                                                                "maxWidth" : "100%"
                                                                            },
                                                                            "children" : [

                                                                            ],
                                                                            "widgets" : [

                                                                            ],
                                                                            "lastUpdate" : 1694611727749.0
                                                                        }
                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "grouped" : false,
                                            "lastUpdate" : 1694608821898.0,
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "lastUpdate" : 1694608907938.0,
                                    "widgets" : [

                                    ]
                                },
                                {
                                    "id" : "widget-UPq7qeF8uH4tltDfBDMQF",
                                    "name" : "pricing-item",
                                    "xtype" : "group",
                                    "properties" : {
                                        "columnGap" : 30,
                                        "rowGap" : 30,
                                        "backgroundColor" : "rgb(245, 247, 250)",
                                        "justifyContent" : "flex-start",
                                        "alignItems" : "center",
                                        "paddingTop" : 30,
                                        "paddingRight" : 24,
                                        "paddingBottom" : 30,
                                        "paddingLeft" : 24,
                                        "display" : "flex",
                                        "small" : {
                                            "backgroundAttachment" : "scroll",
                                            "flexDirection" : "column"
                                        },
                                        "width" : 528
                                    },
                                    "children" : [
                                        {
                                            "id" : "widget-WIfZurncaZSKjHCHAwJYF",
                                            "name" : "image-wrapper-9",
                                            "xtype" : "group",
                                            "properties" : {
                                                "maxWidth" : 195,
                                                "flexDirection" : "column",
                                                "justifyContent" : "center",
                                                "alignItems" : "center",
                                                "display" : "flex",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "widget-fPyjsva23_CcYhf-47zlZ",
                                                    "name" : "image-15",
                                                    "xtype" : "image",
                                                    "properties" : {
                                                        "borderTopWidth" : 0,
                                                        "borderRightWidth" : 0,
                                                        "borderBottomWidth" : 0,
                                                        "borderLeftWidth" : 0,
                                                        "borderTopStyle" : "initial",
                                                        "borderRightStyle" : "initial",
                                                        "borderBottomStyle" : "initial",
                                                        "borderLeftStyle" : "initial",
                                                        "borderTopColor" : "initial",
                                                        "borderRightColor" : "initial",
                                                        "borderBottomColor" : "initial",
                                                        "borderLeftColor" : "initial",
                                                        "borderImageSource" : "initial",
                                                        "borderImageSlice" : "initial",
                                                        "borderImageWidth" : "initial",
                                                        "borderImageOutset" : "initial",
                                                        "borderImageRepeat" : "initial",
                                                        "maxWidth" : "100%",
                                                        "verticalAlign" : "middle",
                                                        "display" : "flex",
                                                        "objectFit" : "cover",
                                                        "justifyContent" : "center",
                                                        "alignItems" : "center",
                                                        "small" : {
                                                            "display" : "inline-block",
                                                            "backgroundAttachment" : "scroll"
                                                        },
                                                        "src" : "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f915244f19a7a6e6b124cf_image.png",
                                                        "alt" : "",
                                                        "maxHeight" : "100%"
                                                    },
                                                    "children" : [

                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "widgets" : [

                                            ]
                                        },
                                        {
                                            "id" : "widget-7oX-U4ngdOkZxUF4cnK5w",
                                            "name" : "info-3",
                                            "xtype" : "group",
                                            "properties" : {
                                                "columnGap" : 12,
                                                "rowGap" : 12,
                                                "flexDirection" : "column",
                                                "justifyContent" : "flex-start",
                                                "alignItems" : "flex-start",
                                                "paddingTop" : 12,
                                                "paddingRight" : 12,
                                                "paddingBottom" : 12,
                                                "paddingLeft" : 12,
                                                "display" : "flex",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "widget-NoE455ziYRC6OYQcQCXr6",
                                                    "name" : "text-39",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "fontSize" : 10.5,
                                                        "fontWeight" : "400",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "widget-twGHhghSnZhahPQ_afkKH",
                                                            "xtype" : "label",
                                                            "properties" : {
                                                                "content" : "<p>Tasting Notes</p>",
                                                                "maxWidth" : "100%"
                                                            },
                                                            "lastUpdate" : 1694608949071.0,
                                                            "children" : [

                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "widget-XVXx-qEPF64EuI-Vz27Eq",
                                                    "name" : "pricing-info",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "justifyContent" : "space-between",
                                                        "alignItems" : "center",
                                                        "paddingTop" : 12,
                                                        "paddingBottom" : 12,
                                                        "display" : "flex",
                                                        "boxShadow" : null,
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll",
                                                            "flexDirection" : "column"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "widget-VNrETjW96YvkaMNaaFMO8",
                                                            "name" : "text-40",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "fontSize" : 18,
                                                                "fontWeight" : "700",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "widget-Ol4L_x2NjrX97MjhAoaEC",
                                                                    "xtype" : "label",
                                                                    "properties" : {
                                                                        "content" : "<p>Product #6</p>",
                                                                        "maxWidth" : "100%"
                                                                    },
                                                                    "lastUpdate" : 1694609093366.0,
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "widget-cpOCxnKr0VhrwXxNojyy9",
                                                    "name" : "pricing-details",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "justifyContent" : "space-between",
                                                        "alignItems" : "center",
                                                        "display" : "flex",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll",
                                                            "flexDirection" : "column"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "widget-76e6jnJBUxvm8TUM5cri3",
                                                            "name" : "detail",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "columnGap" : 3,
                                                                "rowGap" : 3,
                                                                "flexDirection" : "column",
                                                                "justifyContent" : "flex-start",
                                                                "alignItems" : "flex-start",
                                                                "display" : "flex",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "widget-He289B2rj_wq4GsVgIRUp",
                                                                    "name" : "text-39",
                                                                    "xtype" : "group",
                                                                    "properties" : {
                                                                        "fontSize" : 10.5,
                                                                        "fontWeight" : "400",
                                                                        "small" : {
                                                                            "backgroundAttachment" : "scroll"
                                                                        }
                                                                    },
                                                                    "children" : [
                                                                        {
                                                                            "id" : "widget-V_6Gx-rBR6ffLBlSRxGxA",
                                                                            "xtype" : "label",
                                                                            "properties" : {
                                                                                "content" : "<p>ABV 5.0%</p>",
                                                                                "maxWidth" : "100%"
                                                                            },
                                                                            "lastUpdate" : 1694608731523.0,
                                                                            "children" : [

                                                                            ],
                                                                            "widgets" : [

                                                                            ]
                                                                        }
                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                },
                                                                {
                                                                    "id" : "widget-H4Ux0VXgSfhVTJTW9TLbu",
                                                                    "name" : "description-5",
                                                                    "xtype" : "group",
                                                                    "properties" : {
                                                                        "fontWeight" : "400",
                                                                        "small" : {
                                                                            "backgroundAttachment" : "scroll"
                                                                        }
                                                                    },
                                                                    "children" : [
                                                                        {
                                                                            "id" : "widget-0xf3bfQb-sG2udv86RBQf",
                                                                            "xtype" : "label",
                                                                            "properties" : {
                                                                                "content" : "<p>Product Description</p>",
                                                                                "maxWidth" : "100%"
                                                                            },
                                                                            "children" : [

                                                                            ],
                                                                            "widgets" : [

                                                                            ],
                                                                            "lastUpdate" : 1694611731477.0
                                                                        }
                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "grouped" : false,
                                            "lastUpdate" : 1694608821898.0,
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "lastUpdate" : 1694608907938.0,
                                    "widgets" : [

                                    ]
                                },
                                {
                                    "id" : "widget-fw9SlND9bjfN7loKjxgYw",
                                    "name" : "pricing-item",
                                    "xtype" : "group",
                                    "properties" : {
                                        "columnGap" : 30,
                                        "rowGap" : 30,
                                        "backgroundColor" : "rgb(245, 247, 250)",
                                        "justifyContent" : "flex-start",
                                        "alignItems" : "center",
                                        "paddingTop" : 30,
                                        "paddingRight" : 24,
                                        "paddingBottom" : 30,
                                        "paddingLeft" : 24,
                                        "display" : "flex",
                                        "small" : {
                                            "backgroundAttachment" : "scroll",
                                            "flexDirection" : "column"
                                        },
                                        "width" : 528
                                    },
                                    "children" : [
                                        {
                                            "id" : "widget-aYKIsAaLVczo5PFE_Anse",
                                            "name" : "image-wrapper-9",
                                            "xtype" : "group",
                                            "properties" : {
                                                "maxWidth" : 195,
                                                "flexDirection" : "column",
                                                "justifyContent" : "center",
                                                "alignItems" : "center",
                                                "display" : "flex",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "widget-3xlaHYpwWnirNyhCbZ2rL",
                                                    "name" : "image-15",
                                                    "xtype" : "image",
                                                    "properties" : {
                                                        "borderTopWidth" : 0,
                                                        "borderRightWidth" : 0,
                                                        "borderBottomWidth" : 0,
                                                        "borderLeftWidth" : 0,
                                                        "borderTopStyle" : "initial",
                                                        "borderRightStyle" : "initial",
                                                        "borderBottomStyle" : "initial",
                                                        "borderLeftStyle" : "initial",
                                                        "borderTopColor" : "initial",
                                                        "borderRightColor" : "initial",
                                                        "borderBottomColor" : "initial",
                                                        "borderLeftColor" : "initial",
                                                        "borderImageSource" : "initial",
                                                        "borderImageSlice" : "initial",
                                                        "borderImageWidth" : "initial",
                                                        "borderImageOutset" : "initial",
                                                        "borderImageRepeat" : "initial",
                                                        "maxWidth" : "100%",
                                                        "verticalAlign" : "middle",
                                                        "display" : "flex",
                                                        "objectFit" : "cover",
                                                        "justifyContent" : "center",
                                                        "alignItems" : "center",
                                                        "small" : {
                                                            "display" : "inline-block",
                                                            "backgroundAttachment" : "scroll"
                                                        },
                                                        "src" : "https://uploads-ssl.webflow.com/64f90edaca6e2359d32c093f/64f915244f19a7a6e6b124cf_image.png",
                                                        "alt" : "",
                                                        "maxHeight" : "100%"
                                                    },
                                                    "children" : [

                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "widgets" : [

                                            ]
                                        },
                                        {
                                            "id" : "widget-b-1rpQCdPGfVJmWjSU84G",
                                            "name" : "info-3",
                                            "xtype" : "group",
                                            "properties" : {
                                                "columnGap" : 12,
                                                "rowGap" : 12,
                                                "flexDirection" : "column",
                                                "justifyContent" : "flex-start",
                                                "alignItems" : "flex-start",
                                                "paddingTop" : 12,
                                                "paddingRight" : 12,
                                                "paddingBottom" : 12,
                                                "paddingLeft" : 12,
                                                "display" : "flex",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "widget-GlZlJ5Fg_LUDlBT9znJdD",
                                                    "name" : "text-39",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "fontSize" : 10.5,
                                                        "fontWeight" : "400",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "widget-5Qdo3lkxfrLmLVXRtAtHL",
                                                            "xtype" : "label",
                                                            "properties" : {
                                                                "content" : "<p>Tasting Notes</p>",
                                                                "maxWidth" : "100%"
                                                            },
                                                            "lastUpdate" : 1694608949071.0,
                                                            "children" : [

                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "widget-BJlA6u306xDMMzQFADPVd",
                                                    "name" : "pricing-info",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "justifyContent" : "space-between",
                                                        "alignItems" : "center",
                                                        "paddingTop" : 12,
                                                        "paddingBottom" : 12,
                                                        "display" : "flex",
                                                        "boxShadow" : null,
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll",
                                                            "flexDirection" : "column"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "widget-YDZcUekc3Aud3iuP1NxFp",
                                                            "name" : "text-40",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "fontSize" : 18,
                                                                "fontWeight" : "700",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "widget-uLniSE8HoCgOtF7BoJ87r",
                                                                    "xtype" : "label",
                                                                    "properties" : {
                                                                        "content" : "<p>Product #7</p>",
                                                                        "maxWidth" : "100%"
                                                                    },
                                                                    "lastUpdate" : 1694609495068.0,
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "widget-fMijcIDgVl3z2PGG0cyFJ",
                                                    "name" : "pricing-details",
                                                    "xtype" : "group",
                                                    "properties" : {
                                                        "justifyContent" : "space-between",
                                                        "alignItems" : "center",
                                                        "display" : "flex",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll",
                                                            "flexDirection" : "column"
                                                        }
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "widget-xAtcyFZEXoenDw2SjvB2J",
                                                            "name" : "detail",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "columnGap" : 3,
                                                                "rowGap" : 3,
                                                                "flexDirection" : "column",
                                                                "justifyContent" : "flex-start",
                                                                "alignItems" : "flex-start",
                                                                "display" : "flex",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "widget-g7fPx75huTbmD9AC8__Fs",
                                                                    "name" : "text-39",
                                                                    "xtype" : "group",
                                                                    "properties" : {
                                                                        "fontSize" : 10.5,
                                                                        "fontWeight" : "400",
                                                                        "small" : {
                                                                            "backgroundAttachment" : "scroll"
                                                                        }
                                                                    },
                                                                    "children" : [
                                                                        {
                                                                            "id" : "widget-BFkOVfOnA21xPmF5A_dV8",
                                                                            "xtype" : "label",
                                                                            "properties" : {
                                                                                "content" : "<p>ABV 5.0%</p>",
                                                                                "maxWidth" : "100%"
                                                                            },
                                                                            "lastUpdate" : 1694608731523.0,
                                                                            "children" : [

                                                                            ],
                                                                            "widgets" : [

                                                                            ]
                                                                        }
                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                },
                                                                {
                                                                    "id" : "widget-cTsJkpETYuFS_VJZ61SaC",
                                                                    "name" : "description-5",
                                                                    "xtype" : "group",
                                                                    "properties" : {
                                                                        "fontWeight" : "400",
                                                                        "small" : {
                                                                            "backgroundAttachment" : "scroll"
                                                                        }
                                                                    },
                                                                    "children" : [
                                                                        {
                                                                            "id" : "widget-UpMbZdAY9v_JADTxynLHh",
                                                                            "xtype" : "label",
                                                                            "properties" : {
                                                                                "content" : "<p>Product Description</p>",
                                                                                "maxWidth" : "100%"
                                                                            },
                                                                            "children" : [

                                                                            ],
                                                                            "widgets" : [

                                                                            ],
                                                                            "lastUpdate" : 1694611734475.0
                                                                        }
                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "grouped" : false,
                                            "lastUpdate" : 1694608821898.0,
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "lastUpdate" : 1694608907938.0,
                                    "widgets" : [

                                    ]
                                }
                            ],
                            "widgets" : [

                            ]
                        }
                    ],
                    "widgets" : [

                    ]
                },
                {
                    "id" : "eecbc115592387a6ed8f64bdbd83c886af3d8a70ad24c0702deefa13efe5921f",
                    "name" : "contact-form",
                    "xtype" : "group",
                    "properties" : {
                        "columnGap" : 48,
                        "rowGap" : 48,
                        "flexDirection" : "column",
                        "justifyContent" : "flex-start",
                        "alignItems" : "center",
                        "paddingTop" : 48,
                        "paddingRight" : 18,
                        "paddingBottom" : 48,
                        "paddingLeft" : 18,
                        "display" : "flex",
                        "small" : {
                            "backgroundAttachment" : "scroll"
                        }
                    },
                    "children" : [
                        {
                            "id" : "fb2f6afed26c9b8d2e77996c5d3a372a6745a29fddf2bf72db84ba4836904132",
                            "name" : "container-2",
                            "xtype" : "group",
                            "properties" : {
                                "maxWidth" : 900,
                                "columnGap" : 30,
                                "rowGap" : 30,
                                "flexDirection" : "column",
                                "justifyContent" : "flex-start",
                                "alignItems" : "center",
                                "display" : "flex",
                                "small" : {
                                    "backgroundAttachment" : "scroll"
                                },
                                "width" : 350
                            },
                            "children" : [
                                {
                                    "id" : "b34c2b2edeba97a83a402a2e5ed0c8d77e0b96e679746f58ba5d2ea3607d3773",
                                    "name" : "section-title",
                                    "xtype" : "group",
                                    "properties" : {
                                        "maxWidth" : 397.5,
                                        "columnGap" : 12,
                                        "rowGap" : 12,
                                        "flexDirection" : "column",
                                        "justifyContent" : "flex-start",
                                        "alignItems" : "center",
                                        "display" : "flex",
                                        "small" : {
                                            "backgroundAttachment" : "scroll"
                                        }
                                    },
                                    "children" : [
                                        {
                                            "id" : "bc38029dc1113f6e35b517d719e1cd8831db151c47109bd2576dc399a546764b",
                                            "name" : "text-14",
                                            "xtype" : "group",
                                            "properties" : {
                                                "fontWeight" : "700",
                                                "marginBottom" : 7.5,
                                                "marginTop" : 15,
                                                "textAlign" : "center",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll",
                                                    "fontWeight" : "bold"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "c2c5e04eb968c72e46155c90494af89e78ba34dd1aba4de91ea719f9090aee31",
                                                    "xtype" : "text",
                                                    "properties" : {
                                                        "content" : "<h2>Win (Insert Product)</h2><h2>for a Year!</h2>",
                                                        "maxWidth" : "100%"
                                                    },
                                                    "lastUpdate" : 1694610979255.0,
                                                    "children" : [

                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "widgets" : [

                                            ]
                                        },
                                        {
                                            "id" : "8f76f911842de9c0dac94e46278d271cdc986756f39ca2222f699cafbd16fbaa",
                                            "name" : "text-15",
                                            "xtype" : "group",
                                            "properties" : {
                                                "fontSize" : 13.5,
                                                "textAlign" : "center",
                                                "fontWeight" : "400",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "8bef4bed11e712cc005a40a837b26828a5331f7b78b2534935746b2cb934e188",
                                                    "xtype" : "text",
                                                    "properties" : {
                                                        "content" : "<p>Simply leave your feedback for your chance </p><p>to go into the draw.</p>",
                                                        "maxWidth" : "100%"
                                                    },
                                                    "lastUpdate" : 1694610916801.0,
                                                    "children" : [

                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "widgets" : [

                                    ]
                                },
                                {
                                    "id" : "42dcbfb430f90f1a0430cf8fcdb82f81e6c13a54239fcac22b46f723c312654b",
                                    "name" : "form-wrapper w-form",
                                    "xtype" : "group",
                                    "properties" : {
                                        "marginBottom" : 11.25,
                                        "maxWidth" : 375,
                                        "columnGap" : 7.5,
                                        "rowGap" : 7.5,
                                        "flexDirection" : "column",
                                        "justifyContent" : "flex-start",
                                        "alignItems" : "flex-start",
                                        "display" : "flex",
                                        "small" : {
                                            "backgroundAttachment" : "scroll"
                                        }
                                    },
                                    "children" : [
                                        {
                                            "id" : "817125118788b181916bee96ba0c9da427158be60fbda77525a7471da55f23dc",
                                            "name" : "form",
                                            "xtype" : "form",
                                            "properties" : {
                                                "columnGap" : 12,
                                                "rowGap" : 12,
                                                "flexDirection" : "column",
                                                "justifyContent" : "flex-start",
                                                "alignItems" : "flex-start",
                                                "display" : "flex",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                },
                                                "width" : 350
                                            },
                                            "children" : [
                                                {
                                                    "id" : "b9f3eb644dc51b5c8581d822273f4e2b535fd43d2e3e2b4ada23f722a778253f",
                                                    "name" : "input-wrapper",
                                                    "xtype" : "field",
                                                    "properties" : {
                                                        "fieldName": "Name",
                                                        "columnGap" : 6,
                                                        "rowGap" : 6,
                                                        "flexDirection" : "column",
                                                        "justifyContent" : "flex-start",
                                                        "alignItems" : "flex-start",
                                                        "display" : "flex",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        },
                                                        "alignSelf" : "stretch"
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "8a0e3a7c584f92e9fbb80dd7e6ea225fe96d379c594c9d56d639eb2c527559ba",
                                                            "name" : "form-block-label",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "display" : "block",
                                                                "marginBottom" : 3.75,
                                                                "fontWeight" : "400",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll",
                                                                    "fontWeight" : "bold"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "9b1200a5f96546c1781a9c5d5db126d32c241ba3d227cb7276a8995fae260cc0",
                                                                    "xtype" : "label",
                                                                    "properties" : {
                                                                        "content" : "<p>Name</p>",
                                                                        "maxWidth" : "100%"
                                                                    },
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        },
                                                        {
                                                            "id" : "389087f394ffcfb0b8f8f2e6b00a0e973d23f1e301b59c42ecba39f09fb34d35",
                                                            "name" : "form-text-input w-input",
                                                            "xtype" : "input",
                                                            "properties" : {
                                                                "required": true,
                                                                "fontStyle" : "inherit",
                                                                "fontVariantLigatures" : "inherit",
                                                                "fontVariantCaps" : "inherit",
                                                                "fontVariantNumeric" : "inherit",
                                                                "fontVariantEastAsian" : "inherit",
                                                                "fontVariantAlternates" : "inherit",
                                                                "fontWeight" : "500",
                                                                "fontStretch" : "inherit",
                                                                "fontOpticalSizing" : "inherit",
                                                                "fontKerning" : "inherit",
                                                                "fontFeatureSettings" : "inherit",
                                                                "fontVariationSettings" : "inherit",
                                                                "display" : "flex",
                                                                "paddingTop" : 9,
                                                                "paddingRight" : 9,
                                                                "paddingBottom" : 9,
                                                                "paddingLeft" : 9,
                                                                "marginBottom" : 7.5,
                                                                "verticalAlign" : "middle",
                                                                "borderTopWidth" : 0.75,
                                                                "borderRightWidth" : 0.75,
                                                                "borderBottomWidth" : 0.75,
                                                                "borderLeftWidth" : 0.75,
                                                                "borderTopStyle" : "solid",
                                                                "borderRightStyle" : "solid",
                                                                "borderBottomStyle" : "solid",
                                                                "borderLeftStyle" : "solid",
                                                                "borderTopColor" : "rgb(0, 0, 0)",
                                                                "borderRightColor" : "rgb(0, 0, 0)",
                                                                "borderBottomColor" : "rgb(0, 0, 0)",
                                                                "borderLeftColor" : "rgb(0, 0, 0)",
                                                                "borderImageSource" : "initial",
                                                                "borderImageSlice" : "initial",
                                                                "borderImageWidth" : "initial",
                                                                "borderImageOutset" : "initial",
                                                                "borderImageRepeat" : "initial",
                                                                "columnGap" : 6,
                                                                "rowGap" : 6,
                                                                "justifyContent" : "flex-start",
                                                                "alignItems" : "center",
                                                                "small" : {
                                                                    "fontWeight" : "inherit",
                                                                    "marginBottom" : 0,
                                                                    "backgroundAttachment" : "scroll",
                                                                    "display" : "block",
                                                                    "paddingTop" : 6,
                                                                    "paddingBottom" : 6,
                                                                    "borderTopColor" : "rgb(204, 204, 204)",
                                                                    "borderRightColor" : "rgb(204, 204, 204)",
                                                                    "borderBottomColor" : "rgb(204, 204, 204)",
                                                                    "borderLeftColor" : "rgb(204, 204, 204)"
                                                                },
                                                                "textAlign" : "center",
                                                                "alignSelf" : "stretch"
                                                            },
                                                            "children" : [

                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "04ee032714447b3969996545e60ce806fc850bd056134d799fe7b3fda34dc017",
                                                    "name" : "input-wrapper",
                                                    "xtype" : "field",
                                                    "properties" : {
                                                        "fieldName": "Email",
                                                        "columnGap" : 6,
                                                        "rowGap" : 6,
                                                        "flexDirection" : "column",
                                                        "justifyContent" : "flex-start",
                                                        "alignItems" : "flex-start",
                                                        "display" : "flex",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        },
                                                        "alignSelf" : "stretch"
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "dbfa865b4e31d4fcf99546f2da4519884b62d517a9ed2b35d642b69748c19e6f",
                                                            "name" : "form-block-label",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "display" : "block",
                                                                "marginBottom" : 3.75,
                                                                "fontWeight" : "400",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll",
                                                                    "fontWeight" : "bold"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "b1f03afec651961d8869634c53816d0c0c366215155c8461dd85d57f4a8f63f5",
                                                                    "xtype" : "label",
                                                                    "properties" : {
                                                                        "content" : "<p>Email</p>",
                                                                        "maxWidth" : "100%"
                                                                    },
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        },
                                                        {
                                                            "id" : "6c3e1543a35d82fe3836e6c9ee2576e20e722a53ea92813750b6fc8f5fbf9565",
                                                            "name" : "form-text-input-2 w-input",
                                                            "xtype" : "input",
                                                            "properties" : {
                                                                "required": true,
                                                                "inputType": "email",
                                                                "fontStyle" : "inherit",
                                                                "fontVariantLigatures" : "inherit",
                                                                "fontVariantCaps" : "inherit",
                                                                "fontVariantNumeric" : "inherit",
                                                                "fontVariantEastAsian" : "inherit",
                                                                "fontVariantAlternates" : "inherit",
                                                                "fontWeight" : "500",
                                                                "fontStretch" : "inherit",
                                                                "fontOpticalSizing" : "inherit",
                                                                "fontKerning" : "inherit",
                                                                "fontFeatureSettings" : "inherit",
                                                                "fontVariationSettings" : "inherit",
                                                                "display" : "flex",
                                                                "paddingTop" : 9,
                                                                "paddingRight" : 9,
                                                                "paddingBottom" : 9,
                                                                "paddingLeft" : 9,
                                                                "marginBottom" : 7.5,
                                                                "verticalAlign" : "middle",
                                                                "borderTopWidth" : 0.75,
                                                                "borderRightWidth" : 0.75,
                                                                "borderBottomWidth" : 0.75,
                                                                "borderLeftWidth" : 0.75,
                                                                "borderTopStyle" : "solid",
                                                                "borderRightStyle" : "solid",
                                                                "borderBottomStyle" : "solid",
                                                                "borderLeftStyle" : "solid",
                                                                "borderTopColor" : "rgb(0, 0, 0)",
                                                                "borderRightColor" : "rgb(0, 0, 0)",
                                                                "borderBottomColor" : "rgb(0, 0, 0)",
                                                                "borderLeftColor" : "rgb(0, 0, 0)",
                                                                "borderImageSource" : "initial",
                                                                "borderImageSlice" : "initial",
                                                                "borderImageWidth" : "initial",
                                                                "borderImageOutset" : "initial",
                                                                "borderImageRepeat" : "initial",
                                                                "columnGap" : 6,
                                                                "rowGap" : 6,
                                                                "justifyContent" : "flex-start",
                                                                "alignItems" : "flex-start",
                                                                "small" : {
                                                                    "fontWeight" : "inherit",
                                                                    "marginBottom" : 0,
                                                                    "backgroundAttachment" : "scroll",
                                                                    "display" : "block",
                                                                    "paddingTop" : 6,
                                                                    "paddingBottom" : 6,
                                                                    "borderTopColor" : "rgb(204, 204, 204)",
                                                                    "borderRightColor" : "rgb(204, 204, 204)",
                                                                    "borderBottomColor" : "rgb(204, 204, 204)",
                                                                    "borderLeftColor" : "rgb(204, 204, 204)"
                                                                },
                                                                "textAlign" : "center",
                                                                "alignSelf" : "stretch"
                                                            },
                                                            "children" : [

                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "efe97e6f4f7e90156718eaca3c2e60e3ec4dfaf99088edf30786beb25e716a1c",
                                                    "name" : "input-wrapper",
                                                    "xtype" : "field",
                                                    "properties" : {
                                                        "fieldName": "Message",
                                                        "columnGap" : 6,
                                                        "rowGap" : 6,
                                                        "flexDirection" : "column",
                                                        "justifyContent" : "flex-start",
                                                        "alignItems" : "flex-start",
                                                        "display" : "flex",
                                                        "small" : {
                                                            "backgroundAttachment" : "scroll"
                                                        },
                                                        "alignSelf" : "stretch"
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "4359dea3349271a3007f8a15e374d15c18fc31f789a6f9b5998fcc5b4449fdaa",
                                                            "name" : "form-block-label",
                                                            "xtype" : "group",
                                                            "properties" : {
                                                                "display" : "block",
                                                                "marginBottom" : 3.75,
                                                                "fontWeight" : "400",
                                                                "small" : {
                                                                    "backgroundAttachment" : "scroll",
                                                                    "fontWeight" : "bold"
                                                                }
                                                            },
                                                            "children" : [
                                                                {
                                                                    "id" : "cf984b2b0175e0577891b6824392f4ff25cd7c09a2edb9ba5e3765ca126843c9",
                                                                    "xtype" : "label",
                                                                    "properties" : {
                                                                        "content" : "<p>Message</p>",
                                                                        "maxWidth" : "100%"
                                                                    },
                                                                    "children" : [

                                                                    ],
                                                                    "widgets" : [

                                                                    ]
                                                                }
                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        },
                                                        {
                                                            "id" : "e9ecda854bb6747c7e7b6a4143c5f104959e69d7b83adc852efb4f1938a75f6b",
                                                            "name" : "form-textarea w-input",
                                                            "xtype" : "text-area",
                                                            "properties" : {
                                                                "required": true,
                                                                "fontStyle" : "inherit",
                                                                "fontVariantLigatures" : "inherit",
                                                                "fontVariantCaps" : "inherit",
                                                                "fontVariantNumeric" : "inherit",
                                                                "fontVariantEastAsian" : "inherit",
                                                                "fontVariantAlternates" : "inherit",
                                                                "fontWeight" : "500",
                                                                "fontStretch" : "inherit",
                                                                "fontOpticalSizing" : "inherit",
                                                                "fontKerning" : "inherit",
                                                                "fontFeatureSettings" : "inherit",
                                                                "fontVariationSettings" : "inherit",
                                                                "overflowX" : "auto",
                                                                "overflowY" : "auto",
                                                                "display" : "flex",
                                                                "paddingTop" : 9,
                                                                "paddingRight" : 9,
                                                                "paddingBottom" : 9,
                                                                "paddingLeft" : 9,
                                                                "marginBottom" : 7.5,
                                                                "verticalAlign" : "middle",
                                                                "borderTopWidth" : 0.75,
                                                                "borderRightWidth" : 0.75,
                                                                "borderBottomWidth" : 0.75,
                                                                "borderLeftWidth" : 0.75,
                                                                "borderTopStyle" : "solid",
                                                                "borderRightStyle" : "solid",
                                                                "borderBottomStyle" : "solid",
                                                                "borderLeftStyle" : "solid",
                                                                "borderTopColor" : "rgb(0, 0, 0)",
                                                                "borderRightColor" : "rgb(0, 0, 0)",
                                                                "borderBottomColor" : "rgb(0, 0, 0)",
                                                                "borderLeftColor" : "rgb(0, 0, 0)",
                                                                "borderImageSource" : "initial",
                                                                "borderImageSlice" : "initial",
                                                                "borderImageWidth" : "initial",
                                                                "borderImageOutset" : "initial",
                                                                "borderImageRepeat" : "initial",
                                                                "columnGap" : 6,
                                                                "rowGap" : 6,
                                                                "justifyContent" : "flex-start",
                                                                "alignItems" : "flex-start",
                                                                "small" : {
                                                                    "fontWeight" : "inherit",
                                                                    "marginBottom" : 0,
                                                                    "backgroundAttachment" : "scroll",
                                                                    "display" : "block",
                                                                    "paddingTop" : 6,
                                                                    "paddingBottom" : 6,
                                                                    "borderTopColor" : "rgb(204, 204, 204)",
                                                                    "borderRightColor" : "rgb(204, 204, 204)",
                                                                    "borderBottomColor" : "rgb(204, 204, 204)",
                                                                    "borderLeftColor" : "rgb(204, 204, 204)"
                                                                },
                                                                "alignSelf" : "stretch"
                                                            },
                                                            "children" : [

                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                },
                                                {
                                                    "id" : "c21dbb8090dcafd3600192c1a56da78cb767026ccda56523677aa34566274fdd",
                                                    "name" : "form-button w-button",
                                                    "xtype" : "submit-button",
                                                    "properties" : {
                                                        "fontStyle" : "inherit",
                                                        "fontVariantLigatures" : "inherit",
                                                        "fontVariantCaps" : "inherit",
                                                        "fontVariantNumeric" : "inherit",
                                                        "fontVariantEastAsian" : "inherit",
                                                        "fontVariantAlternates" : "inherit",
                                                        "fontWeight" : "500",
                                                        "fontStretch" : "inherit",
                                                        "fontOpticalSizing" : "inherit",
                                                        "fontKerning" : "inherit",
                                                        "fontFeatureSettings" : "inherit",
                                                        "fontVariationSettings" : "inherit",
                                                        "display" : "flex",
                                                        "paddingTop" : 9,
                                                        "paddingRight" : 18,
                                                        "paddingBottom" : 9,
                                                        "paddingLeft" : 18,
                                                        "borderTopWidth" : 0,
                                                        "borderRightWidth" : 0,
                                                        "borderBottomWidth" : 0,
                                                        "borderLeftWidth" : 0,
                                                        "borderTopStyle" : "initial",
                                                        "borderRightStyle" : "initial",
                                                        "borderBottomStyle" : "initial",
                                                        "borderLeftStyle" : "initial",
                                                        "borderTopColor" : "initial",
                                                        "borderRightColor" : "initial",
                                                        "borderBottomColor" : "initial",
                                                        "borderLeftColor" : "initial",
                                                        "borderImageSource" : "initial",
                                                        "borderImageSlice" : "initial",
                                                        "borderImageWidth" : "initial",
                                                        "borderImageOutset" : "initial",
                                                        "borderImageRepeat" : "initial",
                                                        "textDecorationLine" : "none",
                                                        "textDecorationThickness" : "initial",
                                                        "textDecorationStyle" : "initial",
                                                        "textDecorationColor" : "initial",
                                                        "cursor" : "pointer",
                                                        "borderTopLeftRadius" : 0,
                                                        "borderTopRightRadius" : 0,
                                                        "borderBottomRightRadius" : 0,
                                                        "borderBottomLeftRadius" : 0,
                                                        "appearance" : "button",
                                                        "columnGap" : 6,
                                                        "rowGap" : 6,
                                                        "justifyContent" : "center",
                                                        "alignItems" : "center",
                                                        "small" : {
                                                            "fontWeight" : "inherit",
                                                            "backgroundAttachment" : "scroll",
                                                            "display" : "inline-block",
                                                            "paddingTop" : 6.75,
                                                            "paddingRight" : 11.25,
                                                            "paddingBottom" : 6.75,
                                                            "paddingLeft" : 11.25
                                                        },
                                                        "alignSelf" : "center"
                                                    },
                                                    "children" : [
                                                        {
                                                            "id" : "ba6da065c17bdaf49ce11de8de2ade1b9e3f8f1481649f2b7c8055f58d3236cf",
                                                            "xtype" : "text",
                                                            "properties" : {
                                                                "content" : "<p>Send message</p>"
                                                            },
                                                            "children" : [

                                                            ],
                                                            "widgets" : [

                                                            ]
                                                        }
                                                    ],
                                                    "widgets" : [

                                                    ]
                                                }
                                            ],
                                            "fields" : [

                                            ],
                                            "formId" : "7ff183c2-dd5b-4323-a3c7-e8e6d4334ab7",
                                            "widgets" : [

                                            ]
                                        }
                                    ],
                                    "widgets" : [

                                    ]
                                }
                            ],
                            "widgets" : [

                            ]
                        }
                    ],
                    "widgets" : [

                    ]
                },
                {
                    "id" : "widget-NNkdPFbbPdgSq_0olrWUc",
                    "name" : "hero-no-image",
                    "xtype" : "group",
                    "properties" : {
                        "columnGap" : 60,
                        "rowGap" : 60,
                        "flexDirection" : "column",
                        "justifyContent" : "flex-start",
                        "alignItems" : "center",
                        "paddingTop" : 48,
                        "paddingRight" : 18,
                        "paddingBottom" : 48,
                        "paddingLeft" : 18,
                        "display" : "flex",
                        "small" : {
                            "backgroundAttachment" : "scroll"
                        }
                    },
                    "children" : [
                        {
                            "id" : "widget-27XmeNnXTd0jFVXDa5io-",
                            "name" : "small-container-2",
                            "xtype" : "group",
                            "properties" : {
                                "maxWidth" : 720,
                                "columnGap" : 30,
                                "rowGap" : 30,
                                "backgroundColor" : "rgb(245, 247, 250)",
                                "borderTopLeftRadius" : 12,
                                "borderTopRightRadius" : 12,
                                "borderBottomRightRadius" : 12,
                                "borderBottomLeftRadius" : 12,
                                "flexDirection" : "column",
                                "justifyContent" : "flex-start",
                                "alignItems" : "center",
                                "paddingTop" : 24,
                                "paddingRight" : 24,
                                "paddingBottom" : 24,
                                "paddingLeft" : 24,
                                "display" : "flex",
                                "small" : {
                                    "backgroundAttachment" : "scroll"
                                }
                            },
                            "children" : [
                                {
                                    "id" : "widget-d5hkPrwbAIwn_Ui-9rWn-",
                                    "name" : "content",
                                    "xtype" : "group",
                                    "properties" : {
                                        "columnGap" : 18,
                                        "rowGap" : 18,
                                        "flexDirection" : "column",
                                        "justifyContent" : "flex-start",
                                        "alignItems" : "center",
                                        "display" : "flex",
                                        "small" : {
                                            "backgroundAttachment" : "scroll"
                                        }
                                    },
                                    "children" : [
                                        {
                                            "id" : "widget-HKs4gQvPyjOIV7nKugNzG",
                                            "name" : "text-12",
                                            "xtype" : "group",
                                            "properties" : {
                                                "fontSize" : 36,
                                                "textAlign" : "center",
                                                "fontWeight" : "700",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "widget-E9r4GYMwUnseaSgAxxS4b",
                                                    "xtype" : "text",
                                                    "properties" : {
                                                        "content" : "<p>Terms and Conditions</p>",
                                                        "maxWidth" : "100%"
                                                    },
                                                    "lastUpdate" : 1694612776346.0
                                                }
                                            ]
                                        },
                                        {
                                            "id" : "widget-9_uUZ9scY6CsCWHYYg9U_",
                                            "name" : "lorem-ipsum-dolor-sit-amet-consectetur-adipiscing-elit-suspendisse-varius-enim-in-eros-elementum-tristique",
                                            "xtype" : "group",
                                            "properties" : {
                                                "textAlign" : "center",
                                                "fontWeight" : "400",
                                                "small" : {
                                                    "backgroundAttachment" : "scroll"
                                                }
                                            },
                                            "children" : [
                                                {
                                                    "id" : "widget-FngfxFEtRkjPobemrOara",
                                                    "xtype" : "text",
                                                    "properties" : {
                                                        "content" : "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</p>",
                                                        "maxWidth" : "100%"
                                                    }
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ],
    "thumbnail" : null,
    "from_template" : true,
    "campaignName" : "Product Showcase Template",
    "campaignDescription" : "",
    "metaImageUrl" : "",
    "metaImageFile" : null,
    "screenshot" : "",
    "redirect" : false,
    "redirectUrl" : "",
    "templateCategory" : "",
    "isPublicTemplate" : false,
    "status" : "published",
    "editMode" : true,
    "createdAt" : "2023-09-13T13:47:10.619Z",
    "lastModified" : "2023-09-13T13:46:58.742Z",
    "scans" : 3,
    "deployQRUrl" : "https://storage.googleapis.com/quicklinks---staging.appspot.com/campaign/Hf7F58y0nlVatZxg4L7N6ESu5AL2/IR8hqpAsthWuKosnXhDVT/qrcode/Product%20Showcase%20Template_1694612817931.png?GoogleAccessId=firebase-adminsdk-w2673%40quicklinks---staging.iam.gserviceaccount.com&Expires=16447017600&Signature=kZU1lKIZeUdHYNShEqgtdtMp8tzA6Lo0fm2U3fj6H33BOgdMJykX1bw2KOFLA6jGvyAPUhPrppiqRGbWvMtdoQ5yXtlc7kVnmAvsKvDOy%2ByYvp9WIzoCZ2xgF4KKfStpC3E%2FAukRqsjHB3haGHgZ4fyo9s8jp9gGOsKKnfe%2FwiIsnIkWHPotbOdMPyC%2BeEDIRugHP3i5BbLUphTPOErHgMRQvzOw0zN2OCz%2BQqhIvu9iO2M8P3ujM7WPP12fCJ4k4QG5Px4YHRcciW%2F5HHvg%2FsHkurNY%2Bwkf5cJ%2Bh9EfqwCnKS54v2vmTsbezN%2Btj%2Bhy2WOxtZ501k8d%2FlSxxtJ4CA%3D%3D",
    "analytics" : {
        "googleAnalytics" : {
            "enabled" : false,
            "trackingId" : ""
        },
        "tagManagerSettings" : {
            "enabled" : false,
            "appName" : "deploy-link",
            "containerId" : "GTM-",
            "dataLayerName" : "customDataLayer"
        }
    },
    "qrcode" : {
        "value" : "",
        "ecLevel" : "M",
        "enableCORS" : true,
        "size" : 200,
        "quietZone" : 10,
        "bgColor" : "rgba(0,0,0,0)",
        "fgColor" : "#000000",
        "logoImage" : "",
        "logoWidth" : 50,
        "logoHeight" : 50,
        "logoOpacity" : 0.5,
        "qrStyle" : "squares"
    },
    "version" : 4,
    "campaignId" : "IR8hqpAsthWuKosnXhDVT",
    "deployUrl" : "https://staging.deploy.link/v2/samhussey/Product-Showcase-Template",
    "oldVersion" : 4,
    "lastUpdate" : 1694612819520.0,
    "fonts" : [
        "Poppins"
    ]
}