import { FC } from 'react'
import { WidgetSettingContainer } from '../../../Design/WidgetSettingContainer'
import NavbarGeneralSetting from '../../../EditMode/Settings/NavbarGeneralSetting'
import SidebarTabs from '../../../EditMode/SidebarTabs'
import NavbarDesignSetting from './NavbarDesignSetting'

interface Props {
}

const NavbarSetting: FC<Props> = () => {
    return (
        <WidgetSettingContainer padding={0}>
            <SidebarTabs
                setting={<NavbarGeneralSetting />}
                design={<NavbarDesignSetting />}
            />
        </WidgetSettingContainer>
    )
}

export default NavbarSetting
