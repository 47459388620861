import { motion } from 'framer-motion'
import styled from 'styled-components'

export const DashboardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 5%;
    position: relative;
    & > h1 {
        font-weight: 600;
        font-size: 32px;
        width: 100%;
        box-sizing: content-box;
        line-height: 38px;
        color: #3ae29b;
        margin-bottom: 14px;
        text-align: left;
    }

    .MuiButtonBase-root {
        letter-spacing: 0;
    }
`

export const EmptyCampaign = styled.p`
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #cacaca;
    margin-top: 20px;
`

export const AnalyticsPane = styled(motion.div)`
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 30px;

    @media (max-width: 720px) {
        flex-direction: column;
        margin-top: 80px;

        input {
            margin-top: 18px;
            width: 100%;
        }
    }

    p {
        font-family: 'Matter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #717f95;
    }
`

export const AnalyticContainer = styled(motion.div)`
    display: flex;
    & > div:first-child {
        margin-right: 20px;
    }

    @media (max-width: 720px) {
        margin-bottom: 30px;
    }
`

export const AnaliticsLink = styled.div`
    display: flex;
    cursor: pointer;
    transition: transform 0.5s ease;
    & > p {
        margin-right: 5px;
    }
    &:hover {
        transform: translateX(8px);
    }
`

export const ProjectsHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;

    & > span {
        font-size: 24px;
        font-weight: 700;
    }

    @media (max-width: 720px) {
        flex-direction: column;

        input {
            margin-top: 18px;
            width: 100%;
        }
    }
`

export const SearchResult = styled.span`
    font-size: 24px;
    line-height: 17px;
    text-align: center;
    color: #717f95;
`

export const ProjectGridContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(278px, 1fr));
    justify-items: stretch;
    padding: 2px 5px 5px 2px;
    grid-gap: 28px;
    height: 860px;
    justify-content: space-between;
    margin-bottom: 50px;
    margin-top: 28px;

    @media (max-width: 550px) {
        justify-items: center;
    }
`

export const ProjectCardFooter = styled.div`
    height: 55px;
    width: 100%;
    bottom: 0px;
    position: absolute;
    background: white;
    padding: 13px 16px;
    border-top: 1px solid #dfe2f5;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    font-family: 'Matter';
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 55;
`

export const ProjectCardFooterDetail = styled.div`
    height: 35px;
    width: 195px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
`
