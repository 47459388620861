import React from 'react'
import { Keyboard, FreeMode, Scrollbar, Autoplay, EffectFade, Parallax } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { SliderContainer } from '../../pages/Billing/styles'

const Reviews = () => {
    return (
        <SliderContainer>
            <h1>Some reviews of our awesome customers</h1>
            <Swiper
                slidesPerView={'auto'}
                centeredSlides={true}
                spaceBetween={50}
                loop={true}
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                }}
                modules={[FreeMode, Scrollbar, Keyboard, Autoplay, EffectFade, Parallax]}
                className="mySwiper"
                breakpoints={{
                    640: {
                        width: 640,
                        slidesPerView: 2,
                    },
                    768: {
                        width: 768,
                        slidesPerView: 3,
                    },
                }}
            >
                <SwiperSlide style={{ width: '300px' }}>
                    {({ isActive }) => (
                        <div className="slide">
                            <span>
                                "We have nearly tripled our mailing list since using the QR codes on
                                our can designs alone. We like the speed that you guys move at and
                                the features that are being rolled out are magnificent. Easy to use,
                                Price, Quality, Time saving!"
                            </span>
                            <div className="slide__footer">
                                <img width={70} src="/images/customers/Revel.png" alt="logo" />
                                <div className="user-info">
                                    <h3>Matt Fay</h3>
                                    <span>Revel Brewing Co</span>
                                </div>
                            </div>
                        </div>
                    )}
                </SwiperSlide>
                <SwiperSlide style={{ width: '300px' }}>
                    {({ isActive }) => (
                        <div className="slide">
                            <span>
                                "We frequently have marketing and retail campaign ideas than have
                                novel digital components but sadly these rarely make it out of the
                                gate. We don't have code/developer skills in-house, and so we find
                                and brief development studios, but few of these potential partners
                                can work to our tight time-frames or budget parameters and so we're
                                forced to take alternative routes and move on. That was before we
                                started to use deployable."
                            </span>
                            <div className="slide__footer">
                                <img width={60} src="/images/customers/Hasbro.png" alt="logo" />
                                <div className="user-info">
                                    <h3>Ben Peace</h3>
                                    <span>Senior Director - Licensing, Hasbro</span>
                                </div>
                            </div>
                        </div>
                    )}
                </SwiperSlide>
                <SwiperSlide style={{ width: '300px' }}>
                    {({ isActive }) => (
                        <div className="slide">
                            <span>
                                "We really liked the use of gamification with which we played our
                                codes. It was an interesting experience."
                            </span>
                            <div className="slide__footer">
                                <img
                                    width={100}
                                    src="/images/customers/activision.png"
                                    alt="logo"
                                />
                                <div className="user-info">
                                    <h3>Tegan Knight</h3>
                                    <span>Activision</span>
                                </div>
                            </div>
                        </div>
                    )}
                </SwiperSlide>
                <SwiperSlide style={{ width: '300px' }}>
                    {({ isActive }) => (
                        <div className="slide">
                            <span>
                                "We have found better/faster access to feedback from both customers
                                and consumers with deployable. The platform is very easy to use."
                            </span>
                            <div className="slide__footer">
                                <img
                                    width={100}
                                    src="/images/customers/Feral-Brewing.png"
                                    alt="logo"
                                />
                                <div className="user-info">
                                    <h3>Jack Davidson</h3>
                                    <span>Feral Brewing Co</span>
                                </div>
                            </div>
                        </div>
                    )}
                </SwiperSlide>
                <SwiperSlide style={{ width: '300px' }}>
                    {({ isActive }) => (
                        <div className="slide">
                            <span>
                                "I am very happy with the platform, can't think of any improvements.
                                Very easy to use, time saving, better customer traffic. "
                            </span>
                            <div className="slide__footer">
                                <img
                                    width={70}
                                    style={{ borderRadius: '8px' }}
                                    src="/images/customers/Urban-Alley-Brewery.png"
                                    alt="logo"
                                />
                                <div className="user-info">
                                    <h3>Brunno Lima</h3>
                                    <span>Urban Alley Brewery</span>
                                </div>
                            </div>
                        </div>
                    )}
                </SwiperSlide>
            </Swiper>
        </SliderContainer>
    )
}

export default Reviews
