import { Divider } from '@mui/material'
import { ChangeEvent, FC, useState } from 'react'
import { validURL } from '../../../../helpers/validation'
import { InputBox } from '../../../common/InputBox'
import { WidgetSettingContainer } from '../../../Design/WidgetSettingContainer'
import { BorderRadius, Paddings, Width } from '../../../EditMode/Design'
import { useWidget } from 'widgets-base'

interface Props {
}

const VideoSetting: FC<Props> = () => {

    const { widget, updateWidget } = useWidget();
    const [error, setError] = useState<string | null>(null)

    const getYoutubeVideoId = (url: string) => {
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
        var match = url.match(regExp)
        if (match && match[2].length === 11) {
            return match[2]
        } else {
            return 'error'
        }
    }

    const handleVideoChange = (e: ChangeEvent<HTMLInputElement>) => {
        let url = e.target.value
        let platform = ''
        if (url.includes('youtube')) {
            url =
                'https://www.youtube.com/embed/' +
                getYoutubeVideoId(e.target.value) +
                '?autoplay=1&mute=1'
            platform = 'youtube'
        } else if (url.includes('youtu.be/')) {
            const video_id = url.substring(url.indexOf('.be/') + 4)
            url = `https://www.youtube.com/embed/${video_id}?autoplay=1&mute=1`
            platform = 'youtube'
        } else if (url.includes('vimeo')) {
            platform = 'vimeo'
        }

        updateWidget({
            settings: {
                ...widget?.settings,
                platform: platform,
                src: url,
            },
        });
    }

    const handleVideoOnBlur = () => {
        let url = widget?.settings?.src
        if (url === '') {
            return setError(null)
        }
        const isValid = validURL(url)
        if (!isValid) {
            setError('Invalid URL please include https:// in front')
        } else {
            setError(undefined)
        }
    }

    return (
        <WidgetSettingContainer padding={18}>
            <InputBox
                label="Video URL"
                value={widget?.settings?.src}
                name="src"
                type="text"
                error={error}
                onChange={handleVideoChange}
                onBlur={handleVideoOnBlur}
            />
            <Divider />
            <Width width={widget?.appearanceSettings?.width} />
            <BorderRadius
                topRightValue={widget?.appearanceSettings?.borderTopRightRadius}
                bottomRightValue={widget?.appearanceSettings?.borderBottomRightRadius}
                topLeftValue={widget?.appearanceSettings?.borderTopLeftRadius}
                bottomLeftValue={widget?.appearanceSettings?.borderBottomLeftRadius}
                
            />
            <Paddings
                topValue={widget?.appearanceSettings?.paddingTop}
                bottomValue={widget?.appearanceSettings?.paddingBottom}
                leftValue={widget?.appearanceSettings?.paddingLeft}
                rightValue={widget?.appearanceSettings?.paddingRight}
                
            />
        </WidgetSettingContainer>
    )
}

export default VideoSetting
