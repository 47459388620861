import { FC, useEffect, useRef, useState, MouseEvent } from 'react'
import MoveToInboxOutlinedIcon from '@mui/icons-material/MoveToInboxOutlined'
import { Badge, ButtonProps, ListItem, ListItemIcon, ListItemText, Popover, Tooltip } from '@mui/material'
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined'
import NotInterestedOutlinedIcon from '@mui/icons-material/NotInterestedOutlined'
import moment, { MomentInput } from 'moment'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { ISubmission } from '../../modules/ISubmission'
import { RouteNames } from '../../../router'
import FormService from '../../../services/FormService'
import { capitalizeFirstLetter } from '../../helpers/capitalizeFirstLetter'
import ListItemButton from '@mui/material/ListItemButton'
import { useAuth } from 'widgets-base'


interface Props {
    openNavbar: boolean
    storageKey: string
    notificationKey: string
    notificationMsg: string
    heading?: string
    multiLineSplitter?: string
    showDate?: boolean
}

const FormsNotify: FC<Props> = ({
    openNavbar,
    storageKey,
    notificationKey,
    heading,
    notificationMsg,
    multiLineSplitter = '\n',
    showDate = true,
}) => {

    const [formData, setFormData] = useState<ISubmission[]>([]);
    const [showCount, setShowCount] = useState<boolean>(false)
    const [messageCount, setMessageCount] = useState<number>(0)
    const [show, setShow] = useState<boolean>(false)
    const [target, setTarget] = useState(null)
    const [newMessageIds, setNewMessageIds] = useState({})
    const navigate = useNavigate();
    const { currentUser } = useAuth();

    useEffect(() => {
        if (!currentUser) {
            return;
        }

        FormService.getForms()
            .then(({ data }) => {
                setFormData(data.submissions); // TODO: Do we really need to load the entire data set?
            })
            .catch((error) => console.log(error));
    }, [currentUser]);

    // Useref for the overlay
    const ref = useRef(null)

    useEffect(() => {
        // Object for id's of new messages
        let newFormId = {}

        // We read if any last read item id is in the local storage
        const getItemFromLocalStorage = localStorage.getItem(storageKey)
        const readItemLs = JSON.parse(getItemFromLocalStorage) || {}
        const readMsgId = Object.keys(readItemLs).length > 0 ? readItemLs['id'] : ''

        //Get new forms that do not have a read field
        const formsWithoutRead = formData.filter((form: ISubmission) => {
            if (!form.read && form.createdAt > readMsgId) {
                return form
            } else {
                return null
            }
        })

        // Get new forms that were read before they were updated
        const newFormWithRead = formData.filter((form: ISubmission) => {
            if (form.read && form.read < form.createdAt && form.createdAt > readMsgId) {
                return form
            } else {
                return null
            }
        })

        const newFormsState = formsWithoutRead.concat(newFormWithRead)

        // Reset count if forms not found
        if (!newFormsState.length) {
            setMessageCount(0)
        }

        // Create an IDs object for all new forms
        for (let i = 0; i < newFormsState.length; i++) {
            newFormId[newFormsState[i]._id] = true
        }

        setNewMessageIds(newFormId)

        if (formData.length && Object.keys(newFormId).length > 0) {
            setShowCount(true)
            setMessageCount(Object.keys(newFormId).length)
        } else {
            setShowCount(false)
        }
    }, [formData, storageKey])

    // Handle the click on the notification bell
    const handleClick = (
        event: MouseEvent | MouseEvent<HTMLDivElement, MouseEvent<Element, MouseEvent>>
    ) => {
        setShow(!show)
        setTarget(event.target)
    }

    // Hide the notification on clicking outside
    const hide = () => {
        setShow(false)
    }

    // Call the function when mark as read link is clicked
    const markAsRead = () => {
        setShowCount(false)
        localStorage.setItem(storageKey, JSON.stringify({ id: formData[0][notificationKey] }))
        setMessageCount(0)
        setNewMessageIds({})
    }

    // Calculate the day diff
    const getDayDiff = (timestamp: MomentInput) => {
        let a = moment()
        let b = moment(timestamp)
        let diff = a.diff(b, 'year')

        if (diff === 0) {
            diff = a.diff(b, 'month')
            if (diff === 0) {
                diff = a.diff(b, 'days')
                if (diff === 0) {
                    diff = a.diff(b, 'hour')
                    if (diff === 0) {
                        diff = a.diff(b, 'minute')
                        if (diff === 0) {
                            diff = a.diff(b, 'second')
                            return `${diff} second(s) before`
                        } else {
                            return `${diff} minute(s) before`
                        }
                    } else {
                        return `${diff} hour(s) before`
                    }
                } else {
                    return `${diff} days(s) before`
                }
            } else {
                return `${diff} month(s) before`
            }
        } else {
            return `${diff} year(s) before`
        }
    }

    const getWhen = (timestamp: MomentInput) => {
        let when = `${moment(timestamp).format('L')} ${moment(timestamp).format('LTS')}`
        return when
    }

    // Get the notification message
    const getContent = (message: string) => {
        if (message.indexOf(multiLineSplitter) >= 0) {
            let splitted = message.split(multiLineSplitter)
            let ret = '<ul>'

            for (let i = 0; i <= splitted.length - 1; i++) {
                if (splitted[i] !== '') {
                    ret = ret + '<li>' + splitted[i] + '</li>'
                }
            }

            ret = ret + '</ul>'
            return {
                __html: ret,
            }
        }
        return {
            __html: `<ul><li>${capitalizeFirstLetter(message)}</li></ul>`,
        }
    }

    const onViewForm = (form: ISubmission) => async () => {
        await FormService.markFormAsRead(form._id)
        navigate(`${RouteNames.FORMS}/${form.campaignId}/${form.formId}`)
        setShow(false)
    }

    return (
        <ListItem disablePadding sx={{ display: 'block' }}>
            <Tooltip
                arrow
                disableHoverListener={openNavbar}
                placement="right"
                title="Incoming forms"
            >
                <ColorButton
                    sx={{ justifyContent: openNavbar ? 'initial' : 'center' }}
                    onClick={(event: MouseEvent) => handleClick(event)}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: openNavbar ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <Badge badgeContent={messageCount} color="error">
                            <MoveToInboxOutlinedIcon />
                        </Badge>
                    </ListItemIcon>
                    <ListItemText primary="Incoming forms" sx={{ display: openNavbar ? 'block' : 'none' }} />
                </ColorButton>
            </Tooltip>

            <div ref={ref}>
                <Popover
                    id="popover-contained"
                    open={show}
                    anchorEl={target}
                    onClose={hide}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    sx={{
                        zIndex: 6000,
                    }}
                >
                    {formData && formData.length > 0 && <Title>{heading}</Title>}
                    <div style={{ padding: '3px 3px' }}>
                        {showCount && (
                            <MarkedSpan onClick={markAsRead}>
                                <BookmarksOutlinedIcon />
                                Mark all as read
                            </MarkedSpan>
                        )}
                        <InfoPanel>
                            {formData && formData.length > 0 ? (
                                formData
                                    .filter(message => message[notificationMsg])
                                    .map((message: ISubmission) => {
                                        return (
                                            <li
                                                className={
                                                    newMessageIds[message._id]
                                                        ? 'notification-message unread'
                                                        : 'notification-message'
                                                }
                                                key={message._id}
                                                onClick={onViewForm(message)}
                                            >
                                                <div className="timestamp">
                                                    <span>{getDayDiff(message[notificationKey])}</span>
                                                    {showDate && (
                                                        <span>
                                                            {' ('}
                                                            {getWhen(message[notificationKey])}
                                                            {')'}
                                                        </span>
                                                    )}
                                                </div>
                                                <div
                                                    className="content"
                                                    dangerouslySetInnerHTML={getContent(
                                                        message[notificationMsg]
                                                    )}
                                                />
                                            </li>
                                        )
                                    })
                            ) : (
                                <NotFound>
                                    <NotInterestedOutlinedIcon />
                                    <span>No Notifications found!</span>
                                </NotFound>
                            )}
                        </InfoPanel>
                    </div>
                </Popover>
            </div>
        </ListItem>
    )
}

export default FormsNotify

const Title = styled.span`
    font-size: 18px;
    text-align: center;
    font-weight: 500;
    padding: 5px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const MarkedSpan = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6478f9;
    margin: 10px 0;
    font-size: 14px;
    font-weight: 500;
    background-color: #ececec;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
`

const InfoPanel = styled.ul`
    max-height: 300px;
    overflow-y: auto;
    padding: 0;
    cursor: pointer;

    &::-webkit-scrollbar {
        width: 3px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
    }

    .notification-message {
        list-style: none;
        padding: 4px;
        background-color: #ebebeb;
        margin-bottom: 3px;
        border: 1px solid #ececec;
        border-radius: 8px;
    }

    .notification-message .timestamp {
        margin-bottom: 2px;
        font-size: 13px;
        font-weight: 600;
    }

    .notification-message .content {
        font-size: 14px;
        padding: 3px 0;
    }

    .notification-message.unread {
        background-color: #3ae29b;
        color: #ffffff;
    }
`

const NotFound = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    svg {
        margin-right: 10px;
    }
`

const ColorButton = styled(ListItemButton)<ButtonProps>(({theme}) => ({
    minHeight: 48,
    px: 2.5,
    margin: '5px',
    borderRadius: '8px',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
        backgroundColor: 'rgba(100, 120, 249, 0.15)',
        boxShadow: '0px 0px 5px 3px rgba(0, 0, 0, 0.3)',
        transform: 'translateY(-2px)',
    },
}))
