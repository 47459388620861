export interface ISpeechBubbleProps {
    title: string;
    text: string;
    avatar: string;
    reverse?: boolean;
}

export function SpeechBubble({ title, text, avatar, reverse }: ISpeechBubbleProps) {

    return (
        <div className="flex flex-row items-start mt-4">            
            {!reverse &&
                <img
                    className="m-3 mt-6 mr-4"
                    src={avatar}
                    style={{
                        width: "75px",
                        height: "75px",
                    }}
                />
            }
            <div className="flex flex-col">
                <div className=" text-lg text-gray-400 pl-4">
                    {title}
                </div>
                <div 
                    className="flex flex-row items-center text-lg bg-gray-200 mt-2 pl-4 pr-4 pt-4 pb-4 rounded-2xl"
                    style={{
                        maxWidth: "800px",
                    }}
                    >
                    {text}
                </div>
            </div>

            {reverse &&
                <img
                    className="m-3 mt-6 ml-4"
                    src={avatar}
                    style={{
                        width: "75px",
                        height: "75px",
                    }}
                    />
            }                        
        </div>
    );
}
