import { useState, FC, ChangeEvent } from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import { checkInputValid } from '../../../helpers/validation'
import { IAction } from '../../../modules/widgets/IAction'
import { InputBox } from '../../common/InputBox'
import { LinkAction } from '../../../modules/widgets/general/ILink'
import { useWidget, IView } from 'widgets-base'
import { useCampaign } from '../../../../builder/context/campaign'

const actionTypeOptions = [
    { label: 'None', value: 'none' },
    { label: 'Redirect', value: LinkAction.REDIRECT },
    { label: 'Go to page', value: LinkAction.GO_TO_PAGE },
]

interface Props {
    action: IAction
}

const RedirectSetting: FC<Props> = ({ action }) => {

    const { campaign } = useCampaign();
    const { widget, setWidget } = useWidget();
    const [selectRedirectType, setSelectRedirectType] = useState({ label: 'None', value: 'none' })
    const [selectRedirectPage, setSelectRedirectPage] = useState({ label: 'None', value: 'none' })
    const [selectRedirectPopup, setSelectRedirectPopup] = useState({ label: 'None', value: 'none' })
    const [error, setError] = useState<string>('')

    const pagesOptions = campaign.views.map((page: IView, index: number) => ({
        label: page.name,
        value: `${index}`,
    }))

    const onHandleChangeActionType = (value: { label: string; value: string }) => {
        setSelectRedirectType(value)
        if (value.value === 'none') {
            const newWidget = {
                ...widget,
                settings: {
                    ...widget.settings,
                    action: {
                        linkUrl: 'https://deploy.link',
                        type: 'none',
                        viewIndex: 0,
                        layerIndex: -1,
                    },
                },
            }

            setWidget(newWidget);
        } else {
            const newWidget = {
                ...widget,
                settings: {
                    ...widget.settings,
                    action: {
                        ...widget.settings.action,
                        type: value.value,
                    },
                },
            }

            setWidget(newWidget);
        }
    }

    const onHandleChangeRedirectPage = (value: { label: string; value: string }) => {
        setSelectRedirectPage(value)
        const newWidget = {
            ...widget,
            settings: {
                ...widget.settings,
                action: {
                    ...widget.settings.action,
                    viewIndex: +value.value,
                },
            },
        }

        setWidget(newWidget);
    }

    const checkUrl = (e: ChangeEvent<HTMLInputElement>): void => {
        setError(null)
        if (e.target.value === '') {
            setError('Please enter valid web link')
            return
        }
        if (e.target.value.slice(0, 3) === 'www') {
            const url = 'https://' + e.target.value
            const isValidUrl = checkInputValid(url, 'url')
            if (!isValidUrl) {
                setError('Please enter valid web link')
                return
            }
        }
        if (e.target.value.substring(0, 8) !== 'https://') {
            setError('Please add https:// in front of your link')
        } else {
            setError(null)
        }
    }

    const onChangeRedirectLink = (e: ChangeEvent<HTMLInputElement>) => {
        const newWidget = {
            ...widget,
            settings: {
                ...widget.settings,
                action: {
                    ...widget.settings.action,
                    linkUrl: e.target.value,
                },
            },
        }

        setWidget(newWidget);
    }

    return (
        <Container>
            <label>Redirect on click action</label>
            <Select
                options={actionTypeOptions}
                value={selectRedirectType}
                onChange={onHandleChangeActionType}
            />
            {action?.type === LinkAction.REDIRECT && (
                <InputBox
                    value={action.linkUrl}
                    name="linkUrl"
                    type="text"
                    onChange={onChangeRedirectLink}
                    onBlur={checkUrl}
                    error={error}
                />
            )}
            {action?.type === LinkAction.GO_TO_PAGE && (
                <Select
                    options={pagesOptions}
                    value={selectRedirectPage}
                    onChange={onHandleChangeRedirectPage}
                />
            )}
        </Container>
    )
}

export default RedirectSetting

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #717f95;
    gap: 10px;

    & > label {
        font-size: 12px;
        color: #3c4b61;
        font-weight: bold;
    }

    & > div {
        width: 100%;
        & > div {
            border-radius: 10px;
        }
    }
`
