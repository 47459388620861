import { FC } from 'react'
import { WidgetSettingContainer } from '../../../Design/WidgetSettingContainer'
import SidebarTabs from '../../../EditMode/SidebarTabs'
import FormDesignSetting from '../FormDesignSetting'
import ProductGeneralSetting from './ProductGeneralSetting'

interface Props {
}

const ProductFormSetting: FC<Props> = () => {
    return (
        <WidgetSettingContainer padding={0}>
            <SidebarTabs
                setting={<ProductGeneralSetting />}
                design={<FormDesignSetting />}
            />
        </WidgetSettingContainer>
    )
}

export default ProductFormSetting
