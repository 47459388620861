import { FC } from 'react'
import { WidgetSettingContainer } from '../../../Design/WidgetSettingContainer'
import AudioGeneralSetting from '../../../EditMode/Settings/AudioGeneralSetting'
import SidebarTabs from '../../../EditMode/SidebarTabs'
import AudioDesignSetting from './AudioDesignSetting'

interface Props {
}

const AudioSetting: FC<Props> = () => {
    return (
        <WidgetSettingContainer padding={0}>
            <SidebarTabs
                setting={<AudioGeneralSetting />}
                design={<AudioDesignSetting />}
            />
        </WidgetSettingContainer>
    )
}

export default AudioSetting
