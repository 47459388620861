import { useParams } from 'react-router-dom'
import { SEO } from '../../components/SEO'
import { PreviewWidgets } from '../../components/new/preview-widgets'
import { RenderContextProvider, getRenderContext } from 'widgets-base';
import { PageThemeProvider, defaultPageTheme } from '../../../builder/context/page-theme';
import { useCampaign, CampaignContextProvider } from '../../../builder/context/campaign';
import { RenderWidgetContextProvider } from '../../../builder/context/render-widget';

export function PreviewCampaign() {

    const { campaign, currentPageIndex } = useCampaign();
    const curPage = campaign.views[currentPageIndex];

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
            }}
        >
            <SEO title="Preview" />

            <PageThemeProvider pageTheme={campaign.pageTheme || defaultPageTheme}>
                <div
                    className="main flex flex-col w-full"
                    >
                    <RenderContextProvider defaultRenderContext={getRenderContext()}>
                        <RenderWidgetContextProvider>
                            <PreviewWidgets
                                widgets={curPage.widgets}
                            />
                        </RenderWidgetContextProvider>
                    </RenderContextProvider>
                </div>
            </PageThemeProvider>
        </div>
    );
}

function Preview() {
    const { campaignId } = useParams()

    return (
        <CampaignContextProvider 
            campaignId={campaignId} 
            enableSaving={false}
            >
            <PreviewCampaign />
        </CampaignContextProvider>
    );
}

export default Preview
