import { nanoid } from 'nanoid'
import { IDatePicker } from '../../../modules/widgets/forms/IDatePicker'

export class DatePickerModel implements IDatePicker {
    id = nanoid()
    name = 'date picker'
    type = 'datepicker'
    icon = '/icons/widgets/datepicker.svg'
    category = 'form'
    colourSettings = {
        'text colour': ['rgba(0,0,0,1)', '#FFA7E1', '#0426FF'],
        'label colour': ['rgba(0,0,0,1)', '#FFA7E1', '#0426FF'],
        'border colour': ['rgba(0,0,0,1)', '#FFA7E1', '#0426FF'],
        'background colour': ['#ffffff', '#FFA7E1', '#0426FF'],
    }
    appearanceSettings = {
        border: true,
        borderRadius: true,
        margin: true,
        padding: true,
        width: 90,
        height: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        alignment: 'center',
        font: {
            family: 'Poppins',
            weight: 300,
            size: 14,
        },
    }
    settings = {
        required: false,
        name: 'date picker',
        label: 'date picker',
        defaultValue: new Date(),
    }
}
