import { FC, useCallback } from 'react'
import styled from 'styled-components'
import { SliderSlides } from '../../../models/widgets/general/Swiper'
import { ISliderSlides } from '../../../modules/widgets/general/ISwiper'
import AddElementButton from './AddElementButton'
import SlideItem from './SlideItem'
import { useWidget } from 'widgets-base'

interface Props {
}

const SwiperSlideSetting: FC<Props> = () => {

    const { widget, setWidget } = useWidget();

    const onAddNewSlide = () => {
        const newSlide = new SliderSlides(
            `Slide ${widget.settings.slides.length + 1}`,
            'Subtitle',
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam dictum mattis velit, sit amet faucibus felis iaculis nec. Nulla laoreet justo vitae porttitor porttitor.',
            `https://swiperjs.com/demos/images/nature-${widget.settings.slides.length + 1}.jpg`
        )

        const newSettings = {
            ...widget,
            settings: {
                ...widget.settings,
                slides: [...widget.settings.slides, newSlide],
            },
        }

        setWidget(newSettings);
    }

    const deleteSlide = useCallback(
        (id: string) => () => {
            if (widget.settings.slides.length === 1) {
                return
            }
            const filteredSlides = widget.settings.slides.filter(
                (slide: ISliderSlides) => slide.id !== id
            )
            const newSettings = {
                ...widget,
                settings: {
                    ...widget.settings,
                    slides: filteredSlides,
                },
            }

            setWidget(newSettings);
        },
        [setWidget, widget]
    )

    const updateSlide = useCallback(
        (slideIndex: number, settings: any) => {
            const currentSlide = widget.settings.slides[slideIndex]
            const updatedSlide = {
                ...currentSlide,
                ...settings,
            }
            const slides = [
                ...widget.settings.slides.slice(0, slideIndex),
                updatedSlide,
                ...widget.settings.slides.slice(slideIndex + 1),
            ]
            const newSettings = {
                ...widget,
                settings: {
                    ...widget.settings,
                    slides,
                },
            }

            setWidget(newSettings);
        },
        [setWidget, widget]
    )

    return (
        <SlidesList>
            {widget?.settings?.slides?.map((slide: ISliderSlides, slideIndex: number) => (
                <SlideItem
                    key={slide?.id}
                    slide={slide}
                    slideIndex={slideIndex}
                    deleteSlide={deleteSlide}
                    updateSlide={updateSlide}
                />
            ))}
            <AddElementButton name="Add new slide" onClick={onAddNewSlide} />
        </SlidesList>
    )
}

export default SwiperSlideSetting

const SlidesList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    & > div:last-child {
        margin-right: 18px;
        display: flex;
        justify-content: flex-end;
    }
`
