import { nanoid } from 'nanoid'
import { ICountdown } from '../../../modules/widgets/general/ICountdown'

const date = new Date()

export class Countdown implements ICountdown {
    constructor(public name: string) {
        this.name = name
    }
    id = nanoid()
    type = 'countdown'    
    category = 'general'
    settings = {
        endDate: date.setDate(date.getDate() + 3),
        endDateText: 'Time is up',
    }
    appearanceSettings = {
        width: 90,
        height: 100,
        align: 'center',
        borderTopLeftRadius: 36,
        borderTopRightRadius: 36,
        borderBottomLeftRadius: 36,
        borderBottomRightRadius: 36,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        backgroundImageUrl: '',
        backgroundFile: null,
        backgroundFileName: '',
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        shadow: false,
        font: {
            family: 'Inter',
            size: 20,
            weight: 700,
            spacing: 2,
            lineHeight: 1,
        },
    }
    colourSettings = {
        textColour: ['#6478F9', '#FFA7E1', '#0426FF'],
    }
}

export class BeautyCountdown implements ICountdown {
    constructor(public name: string) {
        this.name = name
    }
    id = nanoid()
    type = 'styled countdown'
    category = 'general'
    settings = {
        endDate: date.setDate(date.getDate() + 3),
        endDateText: 'Time is up',
    }
    appearanceSettings = {
        width: 90,
        height: 100,
        align: 'center',
        borderTopLeftRadius: 36,
        borderTopRightRadius: 36,
        borderBottomLeftRadius: 36,
        borderBottomRightRadius: 36,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        backgroundImageUrl: '',
        backgroundFile: null,
        backgroundFileName: '',
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        shadow: false,
        font: {
            family: 'Inter',
            size: 20,
            weight: 700,
            spacing: 2,
            lineHeight: 1,
        },
    }
    colourSettings = {
        textColour: ['#6478F9', '#FFA7E1', '#0426FF'],
    }
}
