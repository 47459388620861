import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContainer } from '../../components/Auth/Signin';
import { Button } from '../../components/common/Button';
import ButtonSpinner from '../../components/common/ButtonSpiner';
import { InputBox } from '../../components/common/InputBox';
import { Toast } from '../../helpers/Toast';
import { checkValidEmail } from '../../helpers/validation';
import { RouteNames } from '../../../router';
import { ForgotPassContainer, ForgotPassFooter } from './styles';
import { useAuth } from 'widgets-base';
;

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { sendPasswordResetEmail } = useAuth();

    const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setEmail(e.target.value)
    }

    const handleSubmit = async (e: React.FormEvent): Promise<void> => {
        e.preventDefault()
        if (!checkValidEmail(email)) {
            Toast('Enter valid email.', 'error')
            setError('Enter valid email')
        }

        try {
            setLoading(true)
            await sendPasswordResetEmail(email);
            Toast('Reset link successfully sent!', 'success')
            navigate(RouteNames.LOGIN)
        } catch (error) {
            Toast(error, 'error')
        }
        finally {
            setLoading(false)
        }
    }

    return (
        <ForgotPassContainer>
            <AuthContainer style={{ width: '358px' }}>
                <h1>Forgot Password?</h1>
                <InputBox
                    name="email"
                    size="medium"
                    width="100%"
                    type="text"
                    placeholder="Email"
                    onChange={handleTextChange}
                    value={email}
                    error={error}
                />
                <Button onClick={handleSubmit} type="button" variant="secondary" width="100%">
                    {loading ? <ButtonSpinner height="25px" width="25px" /> : 'Send reset link'}
                </Button>

                <ForgotPassFooter>
                    <p>Not already with us? </p>
                    <button type="button" className="sign-up-button">
                        <Link to={RouteNames.LOGIN} replace>
                            Sign Up
                        </Link>
                    </button>
                </ForgotPassFooter>
            </AuthContainer>
        </ForgotPassContainer>
    )
}

export default ForgotPassword
