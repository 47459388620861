import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import TreeMenu, { TreeMenuItem } from 'react-simple-tree-menu'
import { DocsWrapper, ItemList, MainContent, MenuList } from '../../pages/Docs/styles'
import { treeData } from '../../pages/Docs/treeData'
import { ScrollToTopButton } from '../common/ScrollToTopButton'
import SearchInput from '../common/SearchInput'
import { SEO } from '../SEO'
import TreeItem from './TreeItem'

const DocsLayout = () => {
    const [activeItem, setActiveItem] = useState<string>('')

    return (
        <DocsWrapper>
            <SEO title="Docs" />
            <h1>Documentation</h1>
            <span>
                Welcome to the documentation area for Deployable, here you should be able to find
                everything you need to know about our platform.
            </span>
            <div className="content">
                <MenuList>
                    <TreeMenu data={treeData} initialActiveKey="get-started" debounceTime={125}>
                        {({ search, items }) => (
                            <>
                                <SearchInput
                                    value=""
                                    onChange={(e) => search(e.target.value)}
                                    placeholder="Type and search"
                                    width="250px"
                                />
                                <ItemList>
                                    {items.map((props: TreeMenuItem) => {
                                        return (
                                            <TreeItem
                                                key={props.key}
                                                id={props.key}
                                                label={props.label}
                                                icon={props.icon}
                                                toggleNode={props.toggleNode}
                                                hasNodes={props.hasNodes}
                                                searchTerm={props.searchTerm}
                                                level={props.level}
                                                isOpen={props.isOpen}
                                                path={props.path}
                                                isActive={activeItem === props.key}
                                                setActiveItem={setActiveItem}
                                            />
                                        )
                                    })}
                                </ItemList>
                            </>
                        )}
                    </TreeMenu>
                </MenuList>
                <MainContent>
                    <Outlet />
                </MainContent>
            </div>
            <ScrollToTopButton />
        </DocsWrapper>
    )
}

export default DocsLayout
