import { Button } from '@mui/material'
import { ChangeEvent, FC, useState } from 'react'
import styled from 'styled-components'
import validator from 'validator'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import { Toast } from '../../helpers/Toast'
import { IFont } from '../../modules/branding/IFont'
import { ContentBlock } from './ContentBlock'
import Fonts from "./Fonts";
import { IBranding } from '../../modules/IBranding'

interface Props {
    defaultFont: string
    font: any[]
    branding: IBranding,
    setBranding: (branding: IBranding) => void;
}

const FontSetting: FC<Props> = ({ defaultFont, font, branding, setBranding }) => {
    const [fontFile, setFontFile] = useState([])

    function fontUpload(e: ChangeEvent<HTMLInputElement>): void {
        const file = e.target.files[0]
        const extension = file?.name?.split('.').pop()

        if (!validator.isAlphanumeric(extension)) {
            Toast(
                'Upload valid font file with woff, woff2, ttf, or otf file type extension',
                'error'
            );
            return;
        }

        if (
            extension !== 'woff' &&
            extension !== 'woff2' &&
            extension !== 'ttf' &&
            extension !== 'otf'
        ) {
            Toast('Make sure your font is woff, woff2, ttf, or otf file format', 'error');
            return;
        }

        setFontFile((prev) => [...prev, file]);

        const newFontsList = []
        // convert old font structure to new
        if (typeof font === 'object') {
            Object.entries(font).forEach((arrayElement) => {
                newFontsList.push(arrayElement[1]) // grab the font object
            })
            newFontsList.push(file);
            setBranding({ 
                ...branding,
                font: newFontsList,
            });
        } 
        else {
            setBranding({
                ...branding,
                font: [...font, file],
            });
        }
    }

    const onRemoveCustomFont = (name: string) => () => {
        setFontFile((prev) => [...prev.filter((item) => item.name !== name)])
    }

    return (
        <ContentBlock title="Default font">
            <Fonts
                label="Select font"
                defaultFont={defaultFont}
                branding={branding}
                setBranding={setBranding}
                />
            <UploadContainer>
                <input
                    type="file"
                    name="true"
                    id="customFont"
                    accept=".woff, .woff2, .ttf, .otf"
                    onChange={fontUpload}
                />
                <Button sx={{ textTransform: 'none' }} htmlFor="customFont" component="label">
                    Upload font
                </Button>
            </UploadContainer>
            {fontFile?.length !== 0 && <Description>Uploaded fonts</Description>}
            {fontFile?.map((file) => (
                <FontsList key={file.name}>
                    <input defaultValue={file.name} className="fonts_name" />
                    <HighlightOffOutlinedIcon onClick={onRemoveCustomFont(file.name)} />
                </FontsList>
            ))}
        </ContentBlock>
    )
}

export default FontSetting

const UploadContainer = styled.div`
    width: 100%;
    margin-top: 11px;
    background: #6478f9;
    border-radius: 8px;

    input {
        display: none;
    }
    label {
        width: 100%;
        color: #fff;
    }
    .MuiButton-label {
        text-transform: none;
    }
`

const Description = styled.span`
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #3c4b61;
    margin: 6px 0;
`

const FontsList = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 5px 0;
    transition: background 0.15s ease-in-out;
    & > .fonts_name {
        flex: 1;
        cursor: pointer;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        background: none;
        color: #717f95;
        border: none;
        padding: 5px;
    }
    & > .MuiSvgIcon-root {
        color: #717f95;
        cursor: pointer;
    }
    &:hover {
        background: rgba(100, 120, 249, 0.15);
    }
`
