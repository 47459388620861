import { ChangeEvent, useEffect, useState } from 'react'
import { BackdropLoader } from '../../components/common/BackdropLoader'
import SearchInput from '../../components/common/SearchInput'
import CampaignItem from '../../components/Forms/CampaignItem'
import { SEO } from '../../components/SEO'

import { IForm } from '../../modules/IForm'
import { ISubmission } from '../../modules/ISubmission'
import { CampaignsList, FormsPageWrapper, NoDataComponent } from './styles'
import FormService, { ICampaignForm, IFormsData } from '../../../services/FormService'
import { useAuth } from 'widgets-base'


export function FormsPage() {
    const [searchText, setSearchText] = useState<string>('')
    const [filteredCampaigns, setFilteredCampaigns] = useState<ICampaignForm[]>([])
    const [forms, setForms] = useState<IFormsData>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const { currentUser } = useAuth();

    useEffect(() => {
        if (!currentUser) {
            return;
        }

        FormService.getForms() // TODO: Really just want to load a summary of the data.
            .then(({ data }) => {
                setForms(data);
                setFilteredCampaigns(data.campaigns);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [currentUser]);

    function onChangeSearchText(e: ChangeEvent<HTMLInputElement>) {
        setSearchText(e.target.value)

        if (e.target.value) {
            const filteredCampaigns = forms.campaigns.filter((campaign: ICampaignForm) =>
                campaign.campaignName.toLowerCase().includes(e.target.value.toLowerCase())
            );
            setFilteredCampaigns(filteredCampaigns);
        } else {
            setFilteredCampaigns(forms.campaigns);
        }
    }

    if (loading) {
        return <BackdropLoader />;
    }

    return (
        <FormsPageWrapper>
            <SEO title="Forms" />
            <h1>Forms of campaigns</h1>
            <span>
                Create more testimonial forms with our widgets to learn more about your audience's
                experience.
                <br />
                Here you can see the details of the received forms.
            </span>

            <SearchInput
                value={searchText}
                onChange={onChangeSearchText}
                placeholder="Search by campaign name"
                width="400px"
            />
            
            {filteredCampaigns.length > 0 ?
                <CampaignsList>
                    {filteredCampaigns.map(
                        campaign => (
                            <CampaignItem
                                key={campaign.campaignId}
                                campaign={campaign}
                                form={forms.forms.find((form: IForm) => form.campaignId === campaign.campaignId)}
                                submissions={forms.submissions.filter((sub: ISubmission) => sub.campaignId === campaign.campaignId)}
                            />
                        )
                    )}
                </CampaignsList>
                : <NoDataComponent>
                    <img width={400} src="/images/nodata.png" alt="no data" />
                    <span>You don't have incoming forms for your campaigns yet</span>
                </NoDataComponent>
            }
        </FormsPageWrapper>
    );
}
 