import { useState } from "react";
import { IPropertyType } from "../property-type";
import styled from 'styled-components'
import { Toast } from "../../../v1/helpers/Toast";
import { clientUpload } from "../../../v1/lib/upload/clientUpload";
import { Button } from '@mui/material'
import { PulseLoader } from 'react-spinners'
import { CustomModal } from "../../../v1/components/common/CustomModal";
import { AssetsImages } from "../../../v1/components/Design/asset-picker";
import { DEFAULT_IMAGE_WIDTH } from "../../../widgets/image";
import { useAuth } from "widgets-base";
import { loadImageDetails } from "../../../lib/image";
import { useProperty } from "../../context/property";
import { IAsset } from "../../../v1/modules/branding/IAssets";

export interface IImageSpec {
    //
    // Old style Firebase image url.
    //
    url?: string;

    //
    // New style asset Id.
    //
    assetId?: string;

    //
    // Dimensions of the image.
    //
    width?: number;
    height?: number;
    aspectRatio?: number;
}

//
// Extracts the file name from a path.
//
function getFileName(path: string): string {
    return path.replace(/^.*[\\\/]/, '');
}

//
// An editor for image properties.
//
function PropertyImageEditor({ config }) {

    const { makeAssetUrl } = useAuth();
    const { propertyValue, setPropertyValue } = useProperty<IImageSpec>();

    const [openAssetModal, setOpenAssetModal] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    let src: string;
    if (propertyValue?.assetId) {
        src = makeAssetUrl(propertyValue.assetId);
    }
    else {
        src = propertyValue?.url;
    }

    const toggleOpenAssetModal = () => {
        setOpenAssetModal((prev) => !prev)
    }

    function handleChangeImage(image: IAsset): void {
        if (image.fileUrl) {
            //
            // Old style Firebase storage image URL.
            //
            loadImageDetails(image.fileUrl)
                .then((imageDetails) => {
	    		    setPropertyValue({
	                    url: image.fileUrl,
	                    assetId: undefined,
	                    width: DEFAULT_IMAGE_WIDTH,
	                    height: DEFAULT_IMAGE_WIDTH * imageDetails.aspectRatio,
	                    aspectRatio: imageDetails.aspectRatio,
	                });
                });
        }
        else {
            //
            // New style asset ID.
            //
            loadImageDetails(makeAssetUrl(image._id))
                .then((imageDetails) => {
			        setPropertyValue({
	                    url: undefined,
	                    assetId: image._id,
	                    width: DEFAULT_IMAGE_WIDTH,
	                    height: DEFAULT_IMAGE_WIDTH * imageDetails.aspectRatio,
	                    aspectRatio: imageDetails.aspectRatio,
	                });		
                });
        }

        toggleOpenAssetModal();
    }

    async function handleUploadImage(event): Promise<void> {
        const file = event.target.files[0]
        const fileSize = file.size / 1024 / 1024
        if (fileSize > 2) {
            Toast('Please upload image less than 2mb.', 'error')
            return
        }

        try {
            setLoading(true)
            const asset = await clientUpload(file);
            const fileUrl = makeAssetUrl(asset._id);
            const imageDetails = await loadImageDetails(fileUrl);
            setPropertyValue({
                url: undefined,
                assetId: asset._id,
                width: DEFAULT_IMAGE_WIDTH,
                height: DEFAULT_IMAGE_WIDTH * imageDetails.aspectRatio,
                aspectRatio: imageDetails.aspectRatio,
            });
        } 
        catch (error) {
            console.log(error)
            Toast('Error uploading file', 'error')
        }
        setLoading(false)
    }

    return (
        <Container>
            <ButtonGroup>
                <Button variant="outlined" color="primary" onClick={toggleOpenAssetModal}>
                    <svg
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M14.3925 16.8231H4.6075C3.26167 16.8231 2.17708 15.7306 2.17708 14.3927V4.60767C2.17708 3.26184 3.26959 2.17725 4.6075 2.17725H14.3925C15.7383 2.17725 16.8229 3.26975 16.8229 4.60767V14.3927C16.8229 15.7306 15.7304 16.8231 14.3925 16.8231Z"
                            stroke="#717F95"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M2.17708 13.8144L4.97166 11.0198C5.44666 10.5448 6.22249 10.5448 6.69749 11.0198L7.40999 11.7323C7.88499 12.2073 8.66083 12.2073 9.13583 11.7323L12.2946 8.57354C12.7696 8.09854 13.5454 8.09854 14.0204 8.57354L16.815 11.3681"
                            stroke="#717F95"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M6.45207 4.67871C5.78707 4.67871 5.24875 5.21704 5.24875 5.88204C5.24875 6.54704 5.78707 7.08539 6.45207 7.08539C7.11707 7.08539 7.65542 6.54704 7.65542 5.88204C7.65542 5.21704 7.10916 4.67871 6.45207 4.67871Z"
                            fill="#717F95"
                        />
                    </svg>
                    From assets
                </Button>
                <input
                    type="file"
                    id="uploadBackground"
                    accept="images/*;capture=camera"
                    onChange={handleUploadImage}
                />
                <Button
                    htmlFor="uploadBackground"
                    component="label"
                    variant="outlined"
                    color="primary"
                >
                    <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M20.1591 11.8146C20.0394 13.5492 18.6836 15.4434 16.0318 15.4434C14.8156 15.4434 14.1776 15.4434 14.1776 15.4434C13.7389 15.4434 13.3801 15.1044 13.3801 14.6658C13.3801 14.2271 13.7389 13.9081 14.1776 13.9081C14.1776 13.9081 14.8156 13.8682 16.0318 13.8682C17.6867 13.8682 18.5042 12.7517 18.5839 11.695C18.6637 10.5984 17.9659 9.38213 16.2711 9.14287C16.0518 9.10299 15.8524 8.98336 15.7328 8.80392C15.6131 8.62448 15.5733 8.38522 15.6131 8.16589C15.8325 7.2288 15.5932 6.49108 14.9751 6.15213C14.4567 5.85305 13.6193 5.87299 12.9414 6.53096C12.762 6.7104 12.5028 6.79015 12.2635 6.75028C12.0243 6.7104 11.8049 6.55089 11.6853 6.33157C10.5289 4.1583 8.75438 4.05861 7.57802 4.61688C6.44153 5.15521 5.54431 6.49108 6.24215 8.18583C6.32191 8.38522 6.32191 8.62448 6.22221 8.82386C6.12252 9.02324 5.94308 9.18275 5.74369 9.24256C3.31122 9.98028 3.37104 11.5753 3.43085 12.0539C3.57042 12.9311 4.26826 13.8284 5.34493 13.8284L7.83721 13.8483C8.27586 13.8483 8.63475 14.2271 8.63475 14.6658C8.63475 15.1044 8.27586 15.5032 7.83721 15.5032L5.36487 15.4434C3.6103 15.4434 2.1548 14.0876 1.87567 12.2732C1.65635 10.8775 2.2545 9.0033 4.48758 8.00639C3.96919 5.81318 5.20536 3.95892 6.88018 3.18133C8.59487 2.36386 11.0074 2.60311 12.6025 4.81626C13.5994 4.25799 14.7757 4.21812 15.7527 4.77639C16.5901 5.25491 17.3478 6.25182 17.268 7.74719C19.3815 8.38522 20.2588 10.2195 20.1591 11.8146ZM13.7988 12.4726C13.9583 12.6321 14.1576 12.7118 14.357 12.7118C14.5564 12.7118 14.7558 12.6321 14.9153 12.4726C15.2343 12.1536 15.2343 11.6551 14.9153 11.3361L11.5457 7.94657C11.4062 7.80701 11.2068 7.72725 10.9875 7.72725C10.7681 7.72725 10.5688 7.80701 10.4292 7.96651L7.05962 11.356C6.74061 11.675 6.74061 12.1735 7.05962 12.4925C7.37863 12.8115 7.87709 12.8115 8.1961 12.4925L10.1899 10.4389L10.2099 17.8958C10.2099 18.3344 10.5688 18.6933 11.0074 18.6933C11.446 18.6933 11.8049 18.3344 11.8049 17.8958L11.785 10.4588L13.7988 12.4726Z"
                            fill="#717F95"
                        />
                    </svg>
                    {loading ? (
                        <PulseLoader color="#bbbbbb" speedMultiplier={0.7} size={10} />
                    ) : (
                        'Upload new'
                    )}
                </Button>
            </ButtonGroup>

            <CardContainer>
                <ImageContainer>
                    <span className="fab-action-button">
                        {src 
                            && (
                                <img src={src} />
                            )
                            || (
                                <img src="/images/placeholder-image.svg" />
                            )
                        }
                    </span>
                </ImageContainer>
                <CardTitle>
                    <h4>{src && getFileName(src) || 'default'}</h4>
                </CardTitle>
            </CardContainer>

            <CustomModal
                visible={openAssetModal}
                toggleVisible={toggleOpenAssetModal}
                title="Assets modal"
            >
                <AssetsModalContent>
                    <h3>Select image</h3>
                    <AssetsImages onChangeAsset={handleChangeImage} />
                </AssetsModalContent>
            </CustomModal>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #717f95;
    width: 100%;

    & > label {
        font-size: 12px;
        color: #3c4b61;
        margin-bottom: 7px;
        font-weight: bold;
    }
`

const ButtonGroup = styled.div`
    display: flex;
    width: 100%;
    input[id^='uploadBackground'] {
        display: none;
    }
    .MuiButton-outlinedPrimary {
        outline: none;
        width: 100%;
        text-transform: none;
        border: 1px solid #dfe2f2;
        border-radius: 6px;
        font-size: 12px;
        color: #717f95;
        letter-spacing: 0;
        &:first-child {
            margin-right: 2px;
        }
        &:hover {
            border: 1px solid #dfe2f2;
        }
        svg {
            margin-right: 2px;
        }
        @media (max-width: 450px) {
            .MuiButton-label {
                display: -webkit-inline-box;
            }
        }
    }
`

const AssetsModalContent = styled.div`
    background-color: #fff;
    border-radius: 8px;
    max-width: 700px;
    max-height: 60vh;
    min-width: 400px;
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    overflow-y: auto;

    & > h3 {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 18px;
    }

    .assets_list {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        min-height: 300px;

        p {
            color: #aaa;
            font-size: 14px;
            margin-bottom: 10px;
        }

        span {
            color: #0933ee;
            font-weight: bold;
            cursor: pointer;
        }
    }

    .assets_grid_list {
        gap: 10px;
        grid-auto-rows: auto;
        display: grid;
        grid-template-columns: repeat(9, minmax(0, 1fr));
        justify-items: stretch;
        min-height: 300px;

        & > img:nth-child(2n + 1) {
            grid-area: span 7 / span 3 / auto / auto;
        }

        & > img:nth-child(7) {
            grid-area: span 5 / span 3 / auto / auto;
        }

        & > img {
            grid-area: span 12 / span 3 / auto / auto;
            border-radius: 12px;
            max-width: 100%;
            transition: all 0.2s ease-in-out;
        }

        & > img:hover {
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
            transform: translateY(-5px);
            cursor: pointer;
        }
    }
`

const CardContainer = styled.div`
    height: 136px;
    width: 100%;
    cursor: pointer;
    background: #fafaff;
    border: 1px solid #dfe2f2;
    border-radius: 10px;
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all 0.15s ease-in-out;
    margin: 14px 0;
`
const ImageContainer = styled.div`
    position: absolute;
    overflow: hidden;
    height: 100px;
    width: 90%;
    top: 5px;
    border-radius: 5px;
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: contain;
        width: 100%;
    }
    &:hover .fab-buttons {
        opacity: 1;
        visibility: visible;
    }
    &:hover .fab-buttons__link {
        transform: scaleY(1) scaleX(1) translateY(-10px) translateX(-106px);
    }
    img:hover + .fab-buttons .fab-buttons__link:before {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
        transform-origin: right center 0;
        transition-delay: 0.3s;
    }
    .MuiSvgIcon-root {
        color: #fff;
    }
    .fab-buttons {
        position: absolute;
        right: 41px;
        bottom: 21px;
        list-style: none;
        margin: 0;
        padding: 0;
        opacity: 0;
        visibility: hidden;
        transition: 0.2s;
    }
    .fab-action-button__icon {
        display: inline-block;
        width: 56px;
        height: 56px;
        background: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjZmZmZmZmIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz4KICAgIDxwYXRoIGQ9Ik0xOCAxNi4wOGMtLjc2IDAtMS40NC4zLTEuOTYuNzdMOC45MSAxMi43Yy4wNS0uMjMuMDktLjQ2LjA5LS43cy0uMDQtLjQ3LS4wOS0uN2w3LjA1LTQuMTFjLjU0LjUgMS4yNS44MSAyLjA0LjgxIDEuNjYgMCAzLTEuMzQgMy0zcy0xLjM0LTMtMy0zLTMgMS4zNC0zIDNjMCAuMjQuMDQuNDcuMDkuN0w4LjA0IDkuODFDNy41IDkuMzEgNi43OSA5IDYgOWMtMS42NiAwLTMgMS4zNC0zIDNzMS4zNCAzIDMgM2MuNzkgMCAxLjUtLjMxIDIuMDQtLjgxbDcuMTIgNC4xNmMtLjA1LjIxLS4wOC40My0uMDguNjUgMCAxLjYxIDEuMzEgMi45MiAyLjkyIDIuOTIgMS42MSAwIDIuOTItMS4zMSAyLjkyLTIuOTJzLTEuMzEtMi45Mi0yLjkyLTIuOTJ6Ii8+Cjwvc3ZnPg==')
            center no-repeat;
    }
    .fab-buttons__item {
        display: block;
        text-align: center;
    }
    .fab-buttons__link {
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        text-decoration: none;
        background-color: rgba(0, 0, 0, 0.8);
        border-radius: 50%;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
            0 3px 1px -2px rgba(0, 0, 0, 0.2);
        transform: scaleY(0.5) scaleX(0.5) translateY(0px) translateX(0px);
        -moz-transition: 0.3s;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
    }
    [data-tooltip]:before {
        top: 50%;
        margin-top: -11px;
        font-weight: 600;
        border-radius: 2px;
        background: #585858;
        color: #fff;
        content: attr(data-tooltip);
        font-size: 12px;
        text-decoration: none;
        visibility: hidden;
        opacity: 0;
        padding: 4px 7px;
        margin-right: 12px;
        position: absolute;
        transform: scale(0);
        right: 100%;
        white-space: nowrap;
        transform-origin: top right;
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    }
    [data-tooltip]:hover:before {
        visibility: visible;
        opacity: 1;
        right: -25px;
        top: -14px;
        transform: scale(1);
        transform-origin: right center 0;
    }
`
const CardTitle = styled.div`
    position: absolute;
    bottom: 5px;
    padding: 0 10px;
    width: 100%;
    & > h4 {
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        text-align: center;
        font-size: 10px;
        line-height: 12px;
        color: #717f95;
    }
`

const image: IPropertyType = {
    editor: (config: any) => <PropertyImageEditor config={config} />,
};

export default image;