import styled from 'styled-components'

const MainPage = () => {
    return (
        <Container>
            <DocsPreview>
                <img width={'80%'} src="/images/deployable-logo-white.png" alt="deployable logo" />
                <span>documentation</span>
                <p>
                    Welcome to the documentation area for Deployable, here you should be able to
                    find everything you need to know about our platform.
                </p>
            </DocsPreview>
            <h1>Powerful self-service ‍audience engagement engine.</h1>
            <p>
                Create powerful links for next level customer experiences. Create anything from
                gamification experiences to a custom links page and start generating more leads,
                sales and subscriptions. Take advantage of industry leading features to create
                engaging data collection tools, gamification experiences, digital business cards and
                more. We aren't interested in all of the buzzwords and catchphrases and marketing
                jargon surrounding the word brand. But we do care about what people think about us.
                We care about our reputation. We care about building great relationships. The
                following pages are full of guidelines, rules, and handy tips that we hope will help
                you communicate our values, realise our vision, and reinforce our brand. It is
                impossible to predict every situation, brand execution, or implementation, but this
                guide will help refine your approach. Whether you are a new marketing executive, an
                outside designer, or one of our excellent partners, thank you for helping us achieve
                our goals and pursue our mission. Sincerely, welcome to the deployable family.
            </p>
            <img className="image" src="/images/docs/deploy.png" alt="main" />
            <h1>Our main features</h1>
            <h2>* Gamification</h2>
            <p>
                Prize spin wheel, scratch card, quizzes, polls, flip cards and more! Learn more in
                the gamification section.
            </p>
            <h2>* Digital Business Cards</h2>
            <p>
                Multiple templates, auto populates contact details and saves directly into peoples
                phones, launch through phones wallet and smart watches with QR codes. Learn more in
                the Digital card section.
            </p>
            <h2>* Forms & Competitions</h2>
            <p>
                Email subscribe forms, customer feedback forms, contact forms and competition entry
                forms. Learn more in the Forms section.
            </p>
            <span>
                © 2023 deployable.co
                <img src="/icons/color_icon.svg" alt="logo" />
            </span>
        </Container>
    )
}

export default MainPage

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    img {
        max-width: 100%;
    }

    & > h1 {
        font-size: 28px;
        color: #6478f9;
        font-weight: bolder;
        margin: 15px 0;
    }
    & > h2 {
        font-size: 20px;
        color: #896400;
        font-weight: bold;
    }
    & > p {
        border-left: 4px solid #cacaca;
        padding-left: 15px;
        font-size: 16px;
        color: #002b47;
        line-height: 1.5;
        margin: 10px 20px;
    }
    & > span {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #cacaca;
        font-size: 12px;
    }
`

const DocsPreview = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 8px;
    background-color: #6478f992;
    background-image: linear-gradient(90deg, #6478f9c5 0%, #3ae29cd8 100%);
    padding: 70px 20px;
    color: #fff;

    & > span {
        font-size: 16px;
        margin-bottom: 15px;
    }

    & > p {
        font-size: 16px;
        border-left: 2px solid #fff;
        padding-left: 15px;
    }

    & > img {
        max-width: 600px;
    }
`
