import { Divider } from '@mui/material'
import { ChangeEvent, FC } from 'react'
import { InputBox } from '../../../common/InputBox'
import { WidgetSettingContainer } from '../../../Design/WidgetSettingContainer'
import { BorderRadius, BorderWidth, Colors, Fonts, Margins, Sizer } from '../../../EditMode/Design'
import AssetsSetting from '../../../EditMode/Settings/AssetsSetting'
import { useWidget } from 'widgets-base'

interface Props {
}

const ProductSetting: FC<Props> = () => {

    const { widget, setWidget } = useWidget();

    const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
        const newWidget = {
            ...widget,
            settings: {
                ...widget.settings,
                [event.target.name]: event.target.value,
            },
        }

        setWidget(newWidget);
    }

    return (
        <WidgetSettingContainer padding={18}>
            <InputBox
                label="Enter product name"
                width="100%"
                name="pretitle"
                onChange={onChangeValue}
                type="text"
                value={widget?.settings?.pretitle}
            />
            <InputBox
                label="Enter title"
                width="100%"
                name="title"
                onChange={onChangeValue}
                type="text"
                value={widget?.settings?.title}
            />
            <InputBox
                label="Enter text content"
                width="100%"
                name="description"
                onChange={onChangeValue}
                type="text"
                value={widget?.settings?.description}
            />
            <InputBox
                label="Set link for product details"
                width="100%"
                name="linkAction"
                onChange={onChangeValue}
                type="text"
                value={widget?.settings?.linkAction}
            />
            <AssetsSetting />
            <Divider />
            <div style={{ display: 'flex', gap: '10px' }}>
                <Fonts
                    label="Select font"
                    defaultFont={widget?.appearanceSettings?.font?.family}
                />
                <Sizer
                    label="Font size"
                    value={widget?.appearanceSettings?.font?.size}
                    name="size"
                />
            </div>
            <Divider />
            <BorderWidth
                topValue={widget?.appearanceSettings?.borderTopWidth}
                rightValue={widget?.appearanceSettings?.borderRightWidth}
                leftValue={widget?.appearanceSettings?.borderLeftWidth}
                bottomValue={widget?.appearanceSettings?.borderBottomWidth}
            />
            <BorderRadius
                topRightValue={widget?.appearanceSettings?.borderTopRightRadius}
                bottomRightValue={widget?.appearanceSettings?.borderBottomRightRadius}
                topLeftValue={widget?.appearanceSettings?.borderTopLeftRadius}
                bottomLeftValue={widget?.appearanceSettings?.borderBottomLeftRadius}
            />
            <Divider />
            <Margins
                topValue={widget?.appearanceSettings?.marginTop}
                bottomValue={widget?.appearanceSettings?.marginBottom}
                leftValue={widget?.appearanceSettings?.marginLeft}
                rightValue={widget?.appearanceSettings?.marginRight}
            />
            <Divider />
            <Colors colors={widget?.colourSettings} />
        </WidgetSettingContainer>
    )
}

export default ProductSetting
