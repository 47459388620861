import { FC, useEffect, useRef, useState } from 'react'
import { atom, useRecoilState } from 'recoil'
import { nanoid } from 'nanoid'
import {
    DndContext,
    DragEndEvent,
    DragOverEvent,
    DragOverlay,
    DragStartEvent,
    MouseSensor,
    useSensor,
    useSensors,
    pointerWithin,
    DragCancelEvent,
} from '@dnd-kit/core'

import { Sidebar } from '../../builder/components/sidebar'
import { SEO } from './SEO'
import { getNodeSet, moveTree, spliceTree } from '../../builder/lib/utils'
import PageSettingsBlock from './Builder/Preview/PageSettingsBlock'
import WidgetPreviewHeader from './Builder/Preview/WidgetPreviewHeader'
import PreviewFooter from './Builder/Preview/PreviewFooter'
import { MoveableWidgets } from './new/moveable-widgets'
import { TemplateWidget } from './new/template-widget'
import { getEventCoordinates } from '@dnd-kit/utilities';
import { debugJsonState, debugModeState } from '../store/builder/builder'
import { RenderTemplateWidget } from './new/render-template-widget'
import { PageDroppableGutter } from './new/PageDroppableGutter'
import _ from "../../lib/lodash";
import { BuilderNav, selectedNavbar } from '../../builder/components/builder-nav'
import { determineDropRuling, getWidgetName } from '../../builder/widget/widget-types'
import { PageThemeProvider, defaultPageTheme } from '../../builder/context/page-theme'
import { useEditorContext } from '../../builder/context/editor-context'
import { IconButton, Tooltip } from '@mui/material'
import { truncate } from '../../lib/text'
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { useConfirm } from '../../hooks/confirm'
import { InsertionMarker, Position } from './new/insertion-marker'
import { Point, Rect, determineHorizontalHalf, determineVerticalHalf, getElementRect } from '../../builder/lib/dom'
import { useAuth, useRenderContext, IRenderContext, RenderContextProvider, Direction } from 'widgets-base'
import { IDroppableDetails, useCampaign, IDraggingWidget } from '../../builder/context/campaign'
import { WidgetContextProvider } from '../../builder/context/widget'

interface Props {
}

const offsetCenterFromCursor = ({
    activatorEvent,
    draggingNodeRect,
    transform,
}) => {
    if (draggingNodeRect && activatorEvent) {
        const activatorCoordinates = getEventCoordinates(activatorEvent);

        if (!activatorCoordinates) {
            return transform;
        }

        const offsetX = activatorCoordinates.x - draggingNodeRect.left;
        const offsetY = activatorCoordinates.y - draggingNodeRect.top;

        return {
            ...transform,
            x: transform.x + offsetX + 5,
            y: transform.y + offsetY + 10,
        };
    }

    return transform;
};

export const sideBarOpenState = atom<boolean>({
    key: 'sideBarOpenState',
    default: false,
});

//
// Details of the last "drag over" event that occurred.
//
interface IDragOverDetails {
    overId: string;
    droppableDetails: IDroppableDetails;
    widgetRect: Rect;
}

//
// Records the position of the insertion marker during drag and drop.
//
export interface IInsertionMarker {
    //
    // The widget that is currently dragged over.
    //
    widgetId: string;

    //
    // The rect of the widget, if known.
    //
    widgetRect?: Rect;

    //
    // The react of the parent widget, if known.
    //
    parentRect?: Rect;

    //
    // The direction of the group that contains the widget.
    //
    parentDirection: Direction;

    //
    // The position to place the insertion marker.
    //
    position: Position;

    //
    // Optional message to display in the insertion marker.
    //
    msg?: string;

    //
    // Is drag and drop allowed at this point?
    //
    allowed: boolean;

    //
    // Forces the insertion marker to be updated.
    //
    lastUpdated: number;
}

const BuilderLayout: FC<Props> = ({}) => {

    const {
        campaign,
        currentPageIndex,
        setCurrentPageIndex,
        selectedWidget,
        selectedWidgetPath,
        setSelectedWidgetPath,
        clearSelectedWidget,
        setWidgets,
        draggingWidget,
        setDraggingWidget,
        assignNewIds,
        addWidget,
        removeWidget,
        duplicateWidget,
        updateWidget,
    } = useCampaign();

    const { makeAssetUrl } = useAuth();
    const { renderContext, setRenderContext } = useRenderContext();

    const [isSidebarOpen, setSidebarOpen] = useRecoilState(sideBarOpenState);
    const [activeTab, setActiveTab] = useRecoilState(selectedNavbar);

    const [debugMode, setDebugMode] = useRecoilState(debugModeState);
    const [debugJson, setDebugJson] = useRecoilState(debugJsonState);
    const [selectedWidgetRect, setSelectedWidgetRect] = useState<Rect>(undefined);
    const { isConfirmed } = useConfirm();
    const { getWidgetRect } = useEditorContext();
    const canvasRef = useRef<HTMLDivElement>(undefined);

    const lastMousePosRef = useRef<Point>(undefined);
    const dragOverDetailsRef = useRef<IDragOverDetails>(undefined);
    
    //
    // The current position of the insertion marker for drag and drop.
    //
    const [insertionMarker, setInsertionMarker] = useState<IInsertionMarker>(undefined);

    const curPage = campaign.views[currentPageIndex];

    useEffect(() => {
        function onKeyDown(event: KeyboardEvent) {
            if (event.key === 'Escape') {
                clearSelectedWidget();
            }
        }

        document.addEventListener('keydown', onKeyDown);

        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, []);

    //
    // This is used to force the widget palette to re-render after the ids for a template widget have been renewed.
    // Have to do this because otherwise the animations in dndkit don't line up properly.
    // Want to find a better solution in the future (if only dndkit would support cloning things from a palette).
    //
    const [paletteKey, setPaletteKey] = useState<string>(nanoid());

    useEffect(() => {
        if (selectedWidget) {
            setActiveTab("Selected");
            setSidebarOpen(true);
        }
        else if (activeTab === "Selected") {
            setSidebarOpen(false);
        }
    }, [selectedWidget]);

    //
    // Updates the chrome for the selected widget.
    //
    function updateSelectionChrome(): void {
        if (selectedWidget) {
            const selectedWidgetRect = getWidgetRect(selectedWidget.id);
            if (selectedWidgetRect) {
                setSelectedWidgetRect(selectedWidgetRect);
                return;
            }
        }

        setSelectedWidgetRect(undefined);
    }

    const __updateSelectionChrome = _.debounce(updateSelectionChrome, 300);

    function onScroll() {
        if (selectedWidgetRect) {
            // Clear widget chrome while scrolling.
            setSelectedWidgetRect(undefined);
        }

        //
        // Debounced call to update the selection chrome.
        //
        __updateSelectionChrome();
    }
    
    
    useEffect(() => {
        setTimeout(() => { // TODO: This is a bit of a workaround to get the prop rect after the sidebar has opened.
            requestAnimationFrame(() => { 
                updateSelectionChrome();
            });
        }, 250);

        setTimeout(() => {
            requestAnimationFrame(() => { 
                updateSelectionChrome();
            });
        }, 500); // Just to be sure it was updated to the right spot.

        if (debugMode) {
            setDebugJson(JSON.stringify(selectedWidget, null, 2));
        }

    }, [selectedWidget, isSidebarOpen, draggingWidget]);

    useEffect(() => {
        document.addEventListener('mousemove', onMouseMove);

        return () => {
            document.removeEventListener('mousemove', onMouseMove);
        };

    }, [draggingWidget]);

    useEffect(() => {
        //
        // Update selection chrome when changing mode.s
        //
        __updateSelectionChrome();

    }, [renderContext]);

    const mouseSensor = useSensor(MouseSensor, {
        activationConstraint: {
            // delay: 150,
            // tolerance: 5,
            distance: 25,
        },
    });
    const sensors = useSensors(mouseSensor);

    function onDragStart(event: DragStartEvent) {
        // console.log(`onDragStart`);
        // console.log(event);
        // console.log(event.active.data.current);

        const draggingWidget: IDraggingWidget = {
            ...event.active.data.current as any,
        };

        // console.log(`Dragging widget: `);
        // console.log(draggingWidget);

        setDraggingWidget(draggingWidget);
    };

    //
    // Updates the insertion marker depending on where the mouse is located.
    //
    function updateInsertionMarker(dropId: string, mousePos: Point) {

        if (!dragOverDetailsRef.current) {
            return;
        }

        let insertionMarkerPosition: Position;

        const dragOverDetails = dragOverDetailsRef.current;
        const widgetRect = dragOverDetails.widgetRect;
        const droppableDetails = dragOverDetails.droppableDetails;

        if (dropId.startsWith("gutter-")) {
            //
            // Dropping at end of page or group.
            //
            insertionMarkerPosition = "start";
        }
        else {
            if (droppableDetails.parentDirection === "column") {
                //
                // Splits the widget in half vertically.
                //
                insertionMarkerPosition = determineVerticalHalf(widgetRect, mousePos);
            }
            else {
                //
                // Splits the widget in half horizontinally.
                //
                insertionMarkerPosition = determineHorizontalHalf(widgetRect, mousePos);
            }
        }

        let dropAllowed = true;
        let msg: string;
        if (droppableDetails.parentWidget) {
            msg = `Place ${draggingWidget.widget.xtype} in ${droppableDetails.parentWidget.xtype}`;
        }
        else {
            msg = `Place ${draggingWidget.widget.xtype} into the page`;
        }
        
        if (droppableDetails.ancestors) {
            const dropRuling = determineDropRuling(draggingWidget.widget, droppableDetails.ancestors);
            if (!dropRuling.allowed) {
                dropAllowed = false;
                msg = dropRuling.noDropMsg;
            }
        }

        let parentRect: Rect | undefined = undefined;

        if (droppableDetails.parentWidget) {
            parentRect = getWidgetRect(droppableDetails.parentWidget.id);
        }

        if (widgetRect) {
            setInsertionMarker({
                widgetId: droppableDetails.widget?.id,
                widgetRect,
                parentRect,
                parentDirection: droppableDetails.parentDirection,
                position: insertionMarkerPosition,
                allowed: dropAllowed,
                msg,
                lastUpdated: Date.now(),
            });
            return;
        }
    }

    function onDragOver(event: DragOverEvent) {
        console.log(`onDragOver`);
        // console.log(event);
        // console.log(draggingWidget);

        const { active, over } = event;
        // console.log(active, over);
        console.log(over);

        if (debugMode) {
            setDebugJson(JSON.stringify({ event: "onDragOver", over, active }, null, 2));
        }

        if (over && over.id) {
            const widgetRect: Rect = {
                top: over.rect.top,
                left: over.rect.left,
                width: over.rect.width,
                height: over.rect.height,
                lastUpdated: Date.now(),
            };

            const overId = over.id as string;
            dragOverDetailsRef.current = {
                overId,
                droppableDetails: over.data.current as IDroppableDetails,
                widgetRect,
            };

            const mouseEvent = event.activatorEvent as MouseEvent;
            const mousePos: Point = { x: mouseEvent.clientX, y: mouseEvent.clientY };
            const offsetMousePos = {
                x: mousePos.x + event.delta.x,
                y: mousePos.y + event.delta.y,
            };
            updateInsertionMarker(overId, offsetMousePos);
            return;
        }

        //
        // Assume we are not over anything.
        //
        setInsertionMarker(undefined);
        dragOverDetailsRef.current = undefined;
    };

    function onMouseMove(event: MouseEvent) { //todo: might want to throttle this. Try requestAnimationFrame.
        // console.log(`onMouseMove`);
        // console.log(event);

        lastMousePosRef.current = { x: event.clientX, y: event.clientY };
     
        if (dragOverDetailsRef.current) {
            const mousePos: Point = { x: event.clientX, y: event.clientY };
            updateInsertionMarker(dragOverDetailsRef.current.overId, mousePos);
        }
    }

    async function onDragEnd(event: DragEndEvent) {
        console.log(`onDragEnd`);
        // console.log(event);
        // console.log(draggingWidget);

        const { active, over } = event;
        // console.log(active, over);
        console.log(over);

        if (debugMode) {
            setDebugJson(JSON.stringify({ event: "onDragEnd", over, active }, null, 2));
        }

        if (!over) {
            //
            // Not over anything.
            //
        }
        else {

            const droppableDetails = over.data.current as IDroppableDetails;
            let targetWidgetPath = droppableDetails.widgetPath;
            const targetParentPath = targetWidgetPath.slice(0, targetWidgetPath.length-1);

            if ((over.id as string).startsWith("gutter-")) {
                //
                // Adding to the end of the page.
                // No need to do anything.
                //
            }
            else {
                //
                // Adding before or after a widget, depending on where we droppped on the widget.
                //
                const offsetMousePos = {
                    x: lastMousePosRef.current.x + event.delta.x,
                    y: lastMousePosRef.current.y + event.delta.y,
                };
        
                let insertionMarkerPosition: Position;
    
                const widgetRect: Rect = {
                    top: over.rect.top,
                    left: over.rect.left,
                    width: over.rect.width,
                    height: over.rect.height,
                    lastUpdated: Date.now(),
                };

                if (droppableDetails.parentDirection === "column") {
                    //
                    // Splits the widget in half vertically.
                    //
                    insertionMarkerPosition = determineVerticalHalf(widgetRect, offsetMousePos);
                }
                else {
                    //
                    // Splits the widget in half horizontinally.
                    //
                    insertionMarkerPosition = determineHorizontalHalf(widgetRect, offsetMousePos);
                }

                if (insertionMarkerPosition === "end") {
                    // Point the widget path to the next widget.
                    targetWidgetPath = targetParentPath.concat([ targetWidgetPath[targetWidgetPath.length - 1] + 1 ]);
                }
            }

            const targetAncestors = getNodeSet(curPage.widgets, targetParentPath);
            const dropRuling = determineDropRuling(draggingWidget.widget, targetAncestors);
            if (!dropRuling.allowed) {
                console.log(`Drop not allowed: ${dropRuling.noDropMsg}`);
            }
            else if (draggingWidget.type === "template") {
                const newWidget = _.cloneDeep(draggingWidget.widget) // Clone it so that we can reset the ids on the template widget.
                const newWidgets = await addWidget(newWidget, targetWidgetPath, curPage.widgets);
    
                setSelectedWidgetPath(targetWidgetPath, newWidgets);
                setActiveTab("Selected");
                setSidebarOpen(true);
    
                // Need to create a unique ids for template widget so that the dndkit animations line up.
                assignNewIds(draggingWidget.widget);
                setPaletteKey(nanoid()); // Make the palette rerender with new ids for the widget.
            }
            else if (active.id !== over.id) {
                const { newNodes, newPath } = moveTree(
                    curPage.widgets,
                    active.data.current.widgetPath,
                    targetWidgetPath,
                );
                setWidgets(newNodes);
                setSelectedWidgetPath(newPath, newNodes);
                setActiveTab("Selected");
                setSidebarOpen(true);
            }
        }

        setDraggingWidget(undefined);
        setInsertionMarker(undefined);
        dragOverDetailsRef.current = undefined;
    };

    function onDragCancel(event: DragCancelEvent){
        // console.log(`onDragCancel`);
        // console.log(event);

        setDraggingWidget(undefined);
        setInsertionMarker(undefined);
        dragOverDetailsRef.current = undefined;
    };

    function calcScale(renderContext: IRenderContext) {
        switch (renderContext.screen) {
            case "small": return "9px";
            case "medium": return "11px";
            case "large": return "13px";
        }
    }

    function calcPreviewWidth(renderContext: IRenderContext) {
        if (renderContext.orientation === "portrait") {
            switch (renderContext.screen) {
                case "small": return 320;
                case "medium": return 768;
                case "large": return "90%";
            }
        }
        else {
            switch (renderContext.screen) {
                case "small": return 759;
                case "medium": return 700;
                case "large": return "90%";
            }
        }
    }

    function calcPreviewHeight(renderContext: IRenderContext) {
        if (renderContext.orientation === "portrait") {
            switch (renderContext.screen) {
                case "small": return 759;
                case "medium": return "90%";
                case "large": return "90%";
            }
        }
        else {
            switch (renderContext.screen) {
                case "small": return 351;
                case "medium": return "90%";
                case "large": return "90%";
            }
        }
    }

    const leftSidebarSize = 360;

    let backgroundImageUrl: string | undefined;
    if (curPage.backgroundImageId) {
        backgroundImageUrl = makeAssetUrl(curPage.backgroundImageId);
    }
    else {
        backgroundImageUrl = curPage.backgroundImageUrl;
    }

    return (
        <div
            className="flex flex-row"
            style={{
                backgroundColor: '#fff',
                color: '#000',
                display: 'flex',
                position: 'relative',
                width: "100vw",
                height: "100vh",
                overflow: "hidden",
            }}
        >
            <SEO title="Builder" />
            <DndContext
                onDragStart={onDragStart}
                onDragOver={onDragOver}
                onDragEnd={onDragEnd}
                onDragCancel={onDragCancel}
                sensors={sensors}
                collisionDetection={pointerWithin}
                modifiers={[offsetCenterFromCursor]}
                >
                <BuilderNav />

                <div
                    className="bg-white relative"
                    style={{
                        width: isSidebarOpen ? `${leftSidebarSize}px` : 0,
                        transition: "all 0.3s ease-in-out",
                    }}
                >
                    <Sidebar
                        paletteKey={paletteKey}
                    />
                </div>

                <div
                    style={{
                        position: 'relative',
                        flexGrow: 1,
                        backgroundColor: '#cfcfcf',
                    }}
                >

                    <div
                        style={{
                            width: "100%",
                            height: "100vh",
                            overflow: "hidden",
                            display: "flex",
                            flexDirection: "column",
                            position: "relative",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "rgba(43, 59, 74, 0.3)",
                        }}
                    >
                        <PageSettingsBlock currentPageIndex={currentPageIndex} />

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                transition: "width 0.3s ease-in-out",
                                backgroundColor: "#fff",
                                width: calcPreviewWidth(renderContext),
                                height: calcPreviewHeight(renderContext),
                                fontSize: calcScale(renderContext),
                                marginBottom: "30px",
                                borderRadius: "10px",
                                boxShadow: "0px 0px 10px 3px rgba(0, 0, 0, 0.3)"
                            }}
                        >
                            <WidgetPreviewHeader />

                            <div
                                className="flex flex-col w-full"
                                style={{
                                    backgroundColor: curPage.backgroundColour,
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                    backgroundSize: "cover",
                                    backgroundImage: `url("${backgroundImageUrl}")`,
                                    overflowX: "hidden",
                                    overflowY: "auto",
                                }}
                                onScroll={onScroll}
                            >
                                <PageThemeProvider
                                    pageTheme={campaign.pageTheme || defaultPageTheme}
                                    >
                                    <div
                                        className="main flex flex-col w-full"
                                        ref={canvasRef}
                                        >
                                        <MoveableWidgets
                                            key={`${campaign.lastUpdate}`}
                                            groupId="main"
                                            widgets={curPage.widgets}
                                            direction="column"
                                            parentWidgetPath={[]}
                                        />
                                    </div>
                                </PageThemeProvider>

                                <PageDroppableGutter
                                    widgetPath={[curPage.widgets.length]}
                                />
                            </div>
                        </div>

                        <PreviewFooter />
                    </div>
                </div>

                <DragOverlay>
                    {draggingWidget ? (
                        <div
                            className="flex flex-col bg-white border border-gray-300 rounded-sm shadow-md p-1"
                            >
                            <PageThemeProvider
                                pageTheme={campaign.pageTheme || defaultPageTheme}
                                >
                                <RenderContextProvider>
                                    {draggingWidget.type === "template"
                                        ? <TemplateWidget
                                            template={draggingWidget.template}
                                        />
                                        : <WidgetContextProvider 
                                            widget={draggingWidget.widget}
                                            widgetPath={undefined}
                                            >
                                            <RenderTemplateWidget />
                                        </WidgetContextProvider>
                                    }
                                </RenderContextProvider>
                            </PageThemeProvider>
                        </div>
                    ) : null}
                </DragOverlay>

            </DndContext>

            <div
                id="widget-chrome-overlay"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    background: "transparent",
                    zIndex: 10000,
                    pointerEvents: "none",
                }}
                >
                {selectedWidget && selectedWidgetRect 
                    && <>
                        {!draggingWidget
                            && <>
                                <div
                                    className="widget-header select-none pointer-events-auto flex flex-row items-center text-gray-600 text-xs mb-1 absolute ml-2 pl-2 pt-1 pb-2 pr-1 bg-gray-50 border-2 border-solid border-green-500"
                                    style={{
                                        top: selectedWidgetRect.top - 16 - 42,
                                        left: selectedWidgetRect.left - 16,
                                        zIndex: 10000,
                                        overflow: "hidden",
                                    }}
                                >
                                    {selectedWidgetPath.length > 1 && (
                                        <Tooltip title="Selects the parent element" arrow>
                                            <IconButton
                                                size="small"
                                                onClick={event => {
                                                    event.stopPropagation();
                                                    event.preventDefault();
                                                    setSelectedWidgetPath(selectedWidgetPath.slice(0, selectedWidgetPath.length - 1), campaign.views[currentPageIndex].widgets);
                                                }}
                                            >
                                                <KeyboardReturnIcon
                                                    fontSize="inherit"
                                                    sx={{
                                                        transform: "rotate(90deg)",
                                                    }}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    )}

                                    <div className="ml-1 whitespace-nowrap">
                                        {truncate(getWidgetName(selectedWidget), 60)}
                                    </div>

                                    <div className="flex flex-row ml-4">
                                        <Tooltip title={selectedWidget.grouped ? "Unlocks the widget" : "Locks the widget"} arrow>
                                            <IconButton
                                                size="small"
                                                onClick={event => {
                                                    event.stopPropagation();
                                                    event.preventDefault();
                                                    updateWidget(selectedWidgetPath, selectedWidget, { grouped: !selectedWidget.grouped });
                                                }}
                                            >
                                                {selectedWidget.grouped
                                                    ? <LockIcon
                                                        fontSize="inherit"
                                                    />
                                                    : <LockOpenIcon
                                                        fontSize="inherit"
                                                    />
                                                }
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Duplicates the element" arrow>
                                            <IconButton
                                                size="small"
                                                onClick={async event => {
                                                    event.stopPropagation();
                                                    event.preventDefault();
                                                    await duplicateWidget(selectedWidget);
                                                }}
                                                >
                                                <ContentCopyIcon
                                                    fontSize="inherit"
                                                />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Deletes the element" arrow>
                                            <IconButton
                                                size="small"
                                                color="error"
                                                onClick={async event => {
                                                    event.stopPropagation();
                                                    event.preventDefault();

                                                    const confirmed = await isConfirmed(`Are you sure you want to delete this element?`);
                                                    if (confirmed) {
                                                        await removeWidget(selectedWidget);
                                                    }
                                                }}
                                            >
                                                <DeleteIcon
                                                    fontSize="inherit"
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>

                                <div
                                    key={selectedWidgetRect.lastUpdated}
                                    className="widget-border select-none pointer-events-none border-2 border-solid border-green-500"
                                    style={{
                                        position: 'absolute',
                                        top: selectedWidgetRect.top - 16,
                                        left: selectedWidgetRect.left - 16,
                                        width: selectedWidgetRect.width + 32,
                                        height: selectedWidgetRect.height + 32,
                                        background: "transparent",
                                    }}
                                    >
                                </div>
                            </>
                        }

                        {/* For testing the insertion marker. */}
                        {/* <InsertionMarker2
                            key={selectedWidget.id}
                            position="end"
                            parentDirection="row"
                            allowed={true}
                            msg="Hello!"
                            widgetRect={getWidgetRect(selectedWidget.id)}
                        /> */}
                    </>
                }

                {insertionMarker
                    && <>
                        {/* This isn't really needed.
                        {insertionMarker.widgetRect
                            && <div
                                key={insertionMarker.widgetRect.lastUpdated}
                                className="drag-over-border select-none pointer-events-none border-2 border-dashed border-gray-300"
                                style={{
                                    position: 'absolute',
                                    top: insertionMarker.widgetRect?.top - 4,
                                    left: insertionMarker.widgetRect?.left - 4,
                                    width: insertionMarker.widgetRect?.width + 8,
                                    height: insertionMarker.widgetRect?.height + 8,
                                    background: "transparent",
                                }}
                                >
                            </div>
                        } */}

                        {(() => {
                            const containerRect = insertionMarker.parentRect
                                && getWidgetRect(insertionMarker.widgetId)
                                || getElementRect(canvasRef);
                            return (
                                <div
                                    className="drag-over-border select-none pointer-events-none border-2 border-dashed border-blue-800"
                                    style={{
                                        position: 'absolute',
                                        top: containerRect.top - 4,
                                        left: containerRect.left - 4,
                                        width: containerRect.width + 8,
                                        height: containerRect.height + 8,
                                        background: "transparent",
                                    }}
                                    >
                                </div>
                            );
                        })()}

                        <InsertionMarker
                            key={insertionMarker.lastUpdated}
                            position={insertionMarker.position}
                            parentDirection={insertionMarker.parentDirection}
                            allowed={insertionMarker.allowed}
                            msg={insertionMarker.msg}
                            widgetRect={insertionMarker.widgetRect 
                                ? insertionMarker.widgetRect 
                                : (insertionMarker.widgetId
                                    && getWidgetRect(insertionMarker.widgetId)
                                    || getElementRect(canvasRef)
                                )
                            }
                            />
                    </>
                }
            </div>
        </div>
    )
}

export default BuilderLayout
