import { FC } from 'react'
import { SyncLoader } from 'react-spinners'
import { capitalizeFirstLetter } from '../../helpers/capitalizeFirstLetter'
import {
    getProductData,
    IProduct,
    premiumProductMonthID,
    premiumProductYearID,
} from '../../pages/Billing/productData'
import { Button } from '../common/Button'
import { UserRoles } from 'widgets-base'

interface Props {
    period: string
    loading: boolean
    onPayment: (product: IProduct, role: string) => () => Promise<void>
    role?: string
}

const PremiumCard: FC<Props> = ({ period, loading, onPayment, role = 'premium' }) => {
    const product =
        period === 'month'
            ? getProductData(premiumProductMonthID)
            : getProductData(premiumProductYearID)

    return (
        <div className="offer-premium">
            <h1>{capitalizeFirstLetter(UserRoles.PREMIUM)}</h1>
            <span>For those clients who need more for their business</span>
            <p className="offer__price">
                ${product.price} <span>(AUD) Per member, per month Billed annually</span>
            </p>
            <ul className="offer__details">
                <li>
                    <img src="/icons/checkbox.svg" alt="list icon" />
                    <span>Unlimited deployed page</span>
                </li>
                <li>
                    <img src="/icons/checkbox.svg" alt="list icon" />
                    <span>Access to Drag & Drop Builder</span>
                </li>
                <li>
                    <img src="/icons/checkbox.svg" alt="list icon" />
                    <span>No watermark</span>
                </li>
                <li>
                    <img src="/icons/checkbox.svg" alt="list icon" />
                    <span>Full access to gamification widgets</span>
                </li>
                <li>
                    <img src="/icons/checkbox.svg" alt="list icon" />
                    <span>
                        Communication pathway with deployable and feature request opportunities
                    </span>
                </li>
                <li>
                    <img src="/icons/checkbox.svg" alt="list icon" />
                    <span>QR Code Launcher</span>
                </li>
                <li>
                    <img src="/icons/checkbox.svg" alt="list icon" />
                    <span>Access to team projects</span>
                </li>
                <li>
                    <img src="/icons/checkbox.svg" alt="list icon" />
                    <span>Unique link username</span>
                </li>
                <li>
                    <img src="/icons/checkbox.svg" alt="list icon" />
                    <span>Access to use forms</span>
                </li>
                <li>
                    <img src="/icons/checkbox.svg" alt="list icon" />
                    <span>Meta data for deployed page</span>
                </li>
            </ul>
            <Button variant="invert" onClick={onPayment(product, role)}>
                {loading ? (
                    <SyncLoader
                        color="#707070"
                        speedMultiplier={0.4}
                        size={5}
                        cssOverride={{ textAlign: 'center', margin: '20px 0' }}
                    />
                ) : (
                    'Subscribe'
                )}
            </Button>
        </div>
    )
}

export default PremiumCard
