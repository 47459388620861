import { memo, ChangeEvent } from 'react'
import styled from 'styled-components'
import { useForm } from '../../../../../../builder/context/form-state-old'
import { useWidget } from 'widgets-base';

interface Props {
}

const Select = ({}: Props) => {

    const { widget } = useWidget();
    const { getFieldValue, setFieldValue } = useForm();

    const placeholder = widget.settings.placeholder
    const required = widget.settings.required
    const name = widget.settings.name
    const label = widget.settings.label
    const options = widget.settings.options

    const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>): void => {
        setFieldValue(name, e.target.value);
    }

    const width = widget.appearanceSettings.width + '%'
    const font = widget.appearanceSettings.font || { family: 'Open Sans' }
    const modFontFamily = font.family?.replace(/\s/g, '+')

    const paddingTop = widget.appearanceSettings.paddingTop / 10 + 'em'
    const paddingBottom = widget.appearanceSettings.paddingBottom / 10 + 'em'
    const paddingLeft = widget.appearanceSettings.paddingLeft / 10 + 'em'
    const paddingRight = widget.appearanceSettings.paddingRight / 10 + 'em'
    const marginTop = widget.appearanceSettings.marginTop / 10 + 'em'
    const marginBottom = widget.appearanceSettings.marginBottom / 10 + 'em'
    const marginLeft = widget.appearanceSettings.marginLeft / 10 + 'em'
    const marginRight = widget.appearanceSettings.marginRight / 10 + 'em'

    const borderTopLeftRadius = widget.appearanceSettings.borderTopLeftRadius / 10 + 'em'
    const borderTopRightRadius = widget.appearanceSettings.borderTopRightRadius / 10 + 'em'
    const borderBottomLeftRadius = widget.appearanceSettings.borderBottomLeftRadius / 10 + 'em'
    const borderBottomRightRadius = widget.appearanceSettings.borderBottomRightRadius / 10 + 'em'
    const borderTopWidth = widget.appearanceSettings.borderTopWidth / 10 + 'em'
    const borderBottomWidth = widget.appearanceSettings.borderBottomWidth / 10 + 'em'
    const borderLeftWidth = widget.appearanceSettings.borderLeftWidth / 10 + 'em'
    const borderRightWidth = widget.appearanceSettings.borderRightWidth / 10 + 'em'
    const borderWidth = `${borderTopWidth} ${borderRightWidth} ${borderBottomWidth} ${borderLeftWidth}`
    const alignment = widget.appearanceSettings.alignment
    const fontSize = widget.appearanceSettings ? widget.appearanceSettings.font.size + 'px' : '14px'
    const fontUrl = widget?.appearanceSettings?.font?.url
    const weight = Number(widget?.appearanceSettings?.font?.weight) || 400
    const letterSpacing = widget?.appearanceSettings?.font?.spacing + 'px' || '8px'
    const lineHeight = widget?.appearanceSettings?.font?.lineHeight || 1

    const textColour = Array.isArray(widget.colourSettings['text colour'])
        ? widget.colourSettings['text colour'][0]
        : widget.colourSettings['text colour']
    const borderColour = Array.isArray(widget.colourSettings['border colour'])
        ? widget.colourSettings['border colour'][0]
        : widget.colourSettings['border colour']
    const labelColour = Array.isArray(widget.colourSettings['label colour'])
        ? widget.colourSettings['label colour'][0]
        : widget.colourSettings['label colour']
    const backgroundColour = Array.isArray(widget.colourSettings['background colour'])
        ? widget.colourSettings['background colour'][0]
        : widget.colourSettings['background colour']

    return (
        <Wrapper
            align={alignment}
            marginTop={marginTop}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            marginRight={marginRight}
            fontFamily={font.family}
        >
            <style>
                {!fontUrl
                    ? `@import url(https://fonts.googleapis.com/css2?family=${modFontFamily}:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);`
                    : `@font-face {
                        font-family: '${font.family}';
                        src: url('${fontUrl}');
                        font-style: normal;
                      }
                      
                      .header-text {
                        font-family: '${font.family}';
                      }`}
            </style>
            <Container
                width={width}
                fontSize={fontSize}
                weight={weight}
                marginTop={marginTop}
                marginBottom={marginBottom}
                marginLeft={marginLeft}
                marginRight={marginRight}
                borderTopLeftRadius={borderTopLeftRadius}
                borderBottomLeftRadius={borderBottomLeftRadius}
                borderTopRightRadius={borderTopRightRadius}
                borderBottomRightRadius={borderBottomRightRadius}
            >
                <label
                    className={`${
                        alignment === 'center'
                            ? 'self-center'
                            : alignment === 'left'
                            ? 'self-start'
                            : 'self-end'
                    }`}
                    style={{
                        width: '100%',
                        fontSize: fontSize,
                        marginBottom: '0.5em',
                        color: labelColour,
                        fontWeight: +weight,
                        letterSpacing,
                        lineHeight,
                    }}
                    htmlFor={name}
                >
                    {label}
                </label>
                <select
                    onChange={handleSelectChange}
                    name={name}
                    value={getFieldValue(name)}
                    style={{
                        paddingTop: paddingTop,
                        paddingBottom: paddingBottom,
                        paddingLeft: paddingLeft,
                        paddingRight: paddingRight,
                        color: textColour,
                        backgroundColor: backgroundColour,
                        borderWidth: borderWidth,
                        borderColor: borderColour,
                        fontSize: fontSize,
                        fontWeight: +weight,
                        letterSpacing,
                        lineHeight,
                    }}
                    placeholder={placeholder}
                    required={required}
                >
                    {options.map((option, index) => {
                        return (
                            <option key={`option-${widget.id}-${index}`} value={option.value}>
                                {option.label}
                            </option>
                        )
                    })}
                </select>
            </Container>
        </Wrapper>
    )
}

export default memo(Select)

const Wrapper = styled.div<{
    align: string
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
    fontFamily: string
}>`
    display: flex;
    align-items: ${(props) =>
        props.align === 'left' ? 'flex-start' : props.align === 'center' ? 'center' : 'flex-end'};
    justify-content: center;
    width: 100%;
    cursor: pointer;
    flex-direction: column;
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    font-family: ${(props) => props.fontFamily};
`
const Container = styled.div<{
    width: string
    fontSize: string
    weight: number
    marginTop: string
    marginBottom: string
    marginLeft: string
    marginRight: string
    borderTopLeftRadius: string
    borderBottomLeftRadius: string
    borderTopRightRadius: string
    borderBottomRightRadius: string
}>`
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: ${(props) => props.fontSize};
    width: ${(props) => props.width};
    font-weight: ${(props) => +props.weight};
    margin-top: ${(props) => props.marginTop};
    margin-bottom: ${(props) => props.marginBottom};
    margin-left: ${(props) => props.marginLeft};
    margin-right: ${(props) => props.marginRight};
    border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius};
    border-top-left-radius: ${(props) => props.borderTopLeftRadius};
    border-top-right-radius: ${(props) => props.borderTopRightRadius};
    border-bottom-right-radius: ${(props) => props.borderBottomRightRadius};
    select {
        width: 100%;
        border-radius: inherit;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: transparent;
        background-image: url('/icons/chev_down.svg');
        background-repeat: no-repeat;
        background-position-x: 95%;
        background-position-y: 50%;
    }
`
