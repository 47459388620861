import { useDroppable } from "@dnd-kit/core";
import { Direction, useWidget } from "widgets-base";
import { IDroppableDetails } from "../../../builder/context/campaign";

export interface IDroppableGutterProps {
    parentDirection: Direction;
    dropId: string;
}

const GUTTER_SIZE = 12;

//
// Defines a gutter above/before a widget that is a droppable location for new widget.
//
export function DroppableGutter({ parentDirection, dropId }: IDroppableGutterProps) {

    const { widget, widgetPath, getAncestors } = useWidget();

    const ancestors = getAncestors();

    const droppableDetails: IDroppableDetails = {
        type: "widget",
        parentWidget: widget,
        ancestors,
        widgetPath: widgetPath.concat([0]),
        parentDirection,
    };

    const { setNodeRef } = useDroppable({
        id: dropId,
        data: droppableDetails,
    });

    let className = `gutter gutter-${parentDirection} ${dropId} relative flex flex-col items-center justify-center `;
    className += "flex-grow ";

    let style: any = {
        border: "2px dashed transparent",
        backgroundColor: "transparent",
    };

    if (parentDirection === "column") {
        style.minHeight = `${GUTTER_SIZE}px`;
    }
    else {
        style.minWidth = `${GUTTER_SIZE}px`;
    }

    // Debugging:
    // style.border = "1px solid #D3D3D3";

    return (
        <div
            className={className}
            style={style}
            ref={setNodeRef}
            >
            <div className="text-gray-400 text-xs place-self-center self-center text-center">
                Drop an element here
            </div>
        </div>
    );
}

