import { Backdrop, Box, Fade, Modal } from '@mui/material'
import { FC, useMemo } from 'react'
import styled from 'styled-components'
import { capitalizeFirstLetter } from '../../../helpers/capitalizeFirstLetter'
import { Button } from '../../common/Button'
import {
    DropdownSetting,
    InputSetting,
    RadioSetting,
    RatingSetting,
    UploadSetting,
    TextareaSetting,
    DatepickerSetting,
} from './FormFields/FieldSetting'
import { useWidget } from 'widgets-base'

interface Props {
    open: boolean
    toggleOpen: () => void
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'transparent',
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    borderRadius: 8,
}

const FieldSettingModal: FC<Props> = ({ open, toggleOpen }) => {

    const { widget } = useWidget();

    function renderFieldSettings() {
        switch (widget.type) {
            case 'input box':
                return <InputSetting />
            case 'text area':
                return <TextareaSetting />
            case 'select':
                return <DropdownSetting />
            case 'radio':
                return <RadioSetting />
            case 'checkbox':
                return <RadioSetting />
            case 'upload':
                return <UploadSetting />
            case 'rating':
                return <RatingSetting />
            case 'datepicker':
                return <DatepickerSetting />
            default:
                return null
        }
    }

    return (
        <Modal
            aria-labelledby="Field settings"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={toggleOpen}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Container>
                        <ModalContent>
                            <h1>{capitalizeFirstLetter(widget.name)} settings</h1>
                            {renderFieldSettings()}
                            <Button type="button" variant="primary" onClick={toggleOpen}>
                                SAVE
                            </Button>
                        </ModalContent>
                    </Container>
                </Box>
            </Fade>
        </Modal>
    )
}

export default FieldSettingModal

const Container = styled.div`
    background: transparent;
    position: relative;

    .MuiBox-root {
        background-color: transparent;
        box-shadow: none;
    }

    .drag-area {
        background: transparent;
        border-radius: 30px;
        width: 100%;
        height: 80vh;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

const ModalContent = styled.div`
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
    min-width: 400px;
    max-width: 450px;
    overflow-y: auto;
    max-height: 80vh;

    & > h1 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 15px;
    }
`
