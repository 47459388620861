import { nanoid } from "nanoid";
import { makeStyledElement, useWidget, useRenderContext, useAuth, usePageTheme, getImageUrl, useDragContext, emToPt, pxToEm, IWidgetType, makeFullAssetUrl, makeColorProperty, stdBorderWidth, stdBorderStyle, stdBorderRadius, stdElementAlignment, stdPadding, stdMargin, stdSize } from "widgets-base";

const Img = makeStyledElement("img");


function Static() {

    const { widget, properties, expandedProperties } = useWidget();
    const { renderContext } = useRenderContext();
    const { makeAssetUrl } = useAuth();
    const { pageTheme } = usePageTheme();
    
    const imageUrl = getImageUrl(properties?.image, renderContext.isStaticExport, makeAssetUrl);

    //
    // Hack to get images to fit nicely on mobile.
    //
    delete expandedProperties.height;

    return (
        <Img
            widgetId={widget.id}
            elementName="image"
            variantName={properties?.variant || "default"}
            pageTheme={pageTheme}
            style={{ 
                objectFit: 'cover', 
                maxWidth: '100%',
                overflow: 'hidden',
            }}
            properties={expandedProperties}
            renderContext={renderContext}
            src={imageUrl}
            alt={properties?.alt}
            />
    );
}

function Editor() {

    const { widget, properties, expandedProperties } = useWidget();
    const { renderContext } = useRenderContext();
    const { makeAssetUrl } = useAuth();
    const { pageTheme } = usePageTheme();
    const { dragProps, setRef, onSelect } = useDragContext();

    const imageUrl = getImageUrl(properties?.image, false, makeAssetUrl);

    //
    // Hack to get images to fit nicely on mobile.
    //
    delete expandedProperties.height;

    return (
        <Img 
            {...dragProps}
            ref={setRef}
            onClick={event => {
                event.stopPropagation();
                event.preventDefault();
                onSelect();
            }}
            widgetId={widget.id}
            elementName="image"
            variantName={properties?.variant || "default"}
            pageTheme={pageTheme}
            style={{ 
                objectFit: 'cover', 
                maxWidth: '100%',
                overflow: 'hidden',
            }}
            properties={expandedProperties}
            renderContext={renderContext}
            src={imageUrl}
            alt={properties?.alt}
            />
    );
}

export const DEFAULT_IMAGE_WIDTH = emToPt(pxToEm(300));
export const DEFAULT_IMAGE_HEIGHT = emToPt(pxToEm(46));

const widgetType: IWidgetType = {
    name: 'Image',
    Static: Static,
    Preview: Static,
    Editor: Editor,
    Template: Static,
    collectAssets: (widget, assetIds, assetUrls) => {
        if (widget.properties?.image?.assetId) {
            assetIds.add(widget.properties?.image?.assetId);
        }
        else if (widget.properties?.image?.url) {
            assetUrls.add(makeFullAssetUrl(widget.properties?.image?.url));
        }
    },
    onPropertyUpdate: async (widget, campaign, propertyId, propertyValue, updateProperties, api) => {
        if (propertyId === "image") {
            console.log(`Updating widget width and height from image.`); //fio:
            updateProperties({
                width: propertyValue.width, //todo: consider having a max size of the image here.
                height: propertyValue.height,
                aspectRatio: propertyValue.aspectRatio,
            });
        }
    },
    template:  {
        id: `widget-${nanoid()}`,
        xtype: 'image',
        properties: {
            image: {
                url: "/images/deployable-logo.png",
                width: DEFAULT_IMAGE_WIDTH,
                height: DEFAULT_IMAGE_HEIGHT,
                aspectRatio: DEFAULT_IMAGE_HEIGHT / DEFAULT_IMAGE_WIDTH,
            },
            alt: "<Describe your image>",
            width: DEFAULT_IMAGE_WIDTH,
            height: DEFAULT_IMAGE_HEIGHT,
            aspectRatio: DEFAULT_IMAGE_HEIGHT / DEFAULT_IMAGE_WIDTH,
            maxWidth: "100%",
            alignSelf: "center",
            allowed: "any",
        },
    },    
    properties: [
        {
            id: "image",
            name: "Image",
            type: "image",
            required: true,
            defaultValue: "",
        },
        {
            id: "alt",
            name: "Alternate text",
            type: "text",
            required: true,
            defaultValue: "",
        },
        {
            id: "allowed",
            name: "Allowed image types",
            type: "option",
            defaultValue: "any",
            values: [
                {
                    name: "Any",
                    value: "any",
                },
                {
                    name: "Portrait",
                    value: "portrait",
                },
                {
                    name: "Landscape",
                    value: "landscape",
                },
                {
                    name: "Square",
                    value: "square",
                },
            ],
            required: true,    
            orientation: "vertical",
        }        
    ],            
    propertyCategories: [
        {
            name: "Colors",
            properties: [
                makeColorProperty("normal", "backgroundColor", "Background"),
            ],
        },
        {
            name: "Border",
            properties: [
                stdBorderWidth,
                stdBorderStyle,
                stdBorderRadius,
            ],
        },
        {
            name: "Placement",
            properties: [
                stdElementAlignment,
                stdPadding,
                stdMargin,
                stdSize,
            ],
        },
    ],
};

export default widgetType;
