import { useDraggable } from "@dnd-kit/core";
import { RenderTemplateWidget } from "./render-template-widget";
import { IWidgetTemplate } from "../../../builder/widget/widget-templates";
import { getWidgetName } from "../../../builder/widget/widget-types";
import { BrandingContextProvider } from "../../../builder/context/branding-context";
import { PageThemeProvider, defaultPageTheme } from "../../../builder/context/page-theme";
import { useCampaign, IDraggingWidget } from "../../../builder/context/campaign";
import { WidgetContextProvider } from "../../../builder/context/widget";

export interface IProps {
    //
    // The template widget to render.
    //
    template: IWidgetTemplate;
};

function renderWidgetIcon(template: IWidgetTemplate) {
    if (template.iconImage) {
        // Renders an image for the widget's icon.
        return (
            <img
                style={{
                    width: "45px",
                    height: "30px",
                }} 
                src={template.iconImage} 
                />
        );
    }

    if (template.iconElement) {
        // Renders a JSX element for the widget's icon.
        return (
            <div
                className="flex flex-col items-center"
                style={{
                    width: "45px",
                    height: "30px",
                }} 
                >
                {template.iconElement}
            </div>
        );
    }
    
    throw new Error(`Not an icon!`);
}

function RenderWidgetPreview({ template }) {

    const { campaign } = useCampaign();

    if (template.iconImage || template.iconElement) {
        // Renders an icon for the widget.
        return (
            <div 
                className="flex flex-col items-center justify-center text-xs"
                style={{
                    width: "88px",
                    height: "85px",
                }} 
                >
                {renderWidgetIcon(template)}
                <div className="text-center mt-1">{getWidgetName(template.widget)}</div>
            </div>
        );
    }

    if (template.previewImage) {
        // Renders an image that looks like the widget.
        return <img src={template.previewImage} />;
    }

    // Renders the actual widget.
    return (
        <PageThemeProvider pageTheme={campaign.pageTheme || defaultPageTheme}>
            <WidgetContextProvider 
                widget={template.widget} 
                widgetPath={undefined}   
                >
                <RenderTemplateWidget />
            </WidgetContextProvider>
        </PageThemeProvider>
    );
}

//
// Renders a template widget that can be dragged out of the widget palette.
//
export function TemplateWidget({ template }: IProps) {

    const draggingDetails: IDraggingWidget = {
        type: "template",
        widget: template.widget,
        template: template,
    };

    const { attributes, listeners, setNodeRef } = useDraggable({
        id: template.widget.id,
        data: draggingDetails,
    });

    return (
        <div
            className="template-widget-container flex flex-row items-center justify-center bg-white border border-solid border-gray-200 rounded"
            style={{
                overflow: "hidden",
                fontSize: template.scale,
            }}
            ref={setNodeRef}
            {...listeners}
            {...attributes}
            >
            
            <div className="template-widget flex flex-row w-full items-center justify-center pointer-events-none select-none">
                <RenderWidgetPreview template={template} />
            </div>
        </div>
    );
}
