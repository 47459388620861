import { nanoid } from "nanoid";
import { IWidget, makeMargin, makePadding } from "widgets-base";

export const contactUsForm: IWidget = {
    id: `widget-${nanoid()}`,
    xtype: 'group',
    source: "contacts-us-form-section",
    grouped: true,
    properties: {
        flexDirection: "column",
        alignSelf: "stretch",
        width: "100%",
        paddingTop: 50,
        paddingBottom: 20,
    },
    children: [
        {
            id: `widget-${nanoid()}`,
            xtype: 'form',
            grouped: true,
            properties: {
                flexDirection: "column",
                ...makeMargin(12),
                ...makePadding(12),
                gap: 12,
                width: 400,
                maxWidth: "100%",
                alignSelf: "center",
                justifyContent: "center",
            },
            children: [
                {
                    id: `widget-${nanoid()}`,
                    xtype: 'text',
                    properties: {
                        content: "<b>Tagline</b>",
                        textAlign: "center",
                        alignSelf: "stretch",
                    },
                },
                {
                    id: `widget-${nanoid()}`,
                    xtype: 'text',
                    properties: {
                        content: "<h2><b>Contact us</b></h2>",
                        textAlign: "center",
                        alignSelf: "stretch",
                    },
                },
                {
                    id: `widget-${nanoid()}`,
                    xtype: 'text',
                    properties: {
                        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                        textAlign: "center",
                        alignSelf: "stretch",
                    },
                },        
                {
                    id: `widget-${nanoid()}`,
                    xtype: 'field',
                    grouped: true,
                    properties: {
                        fieldName: "Name",
                        flexDirection: "column",
                        alignSelf: "stretch",
                    },
                    children: [
                        {
                            id: `widget-${nanoid()}`,
                            xtype: 'label',
                            properties: {
                                content: "<p>Name</p>",
                                alignSelf: "flex-start",
                                fontSize: 9,
                                marginBottom: 6,
                            },
                        },
                        {
                            id: `widget-${nanoid()}`,
                            xtype: 'input',
                            properties: {
                                placeholder: "Enter your full name...",
                                required: true,
                                fontSize: 10,
                                ...makePadding(12),
                                inputType: "text",
                                alignSelf: "stretch",
                                borderColor: "black",
                                borderTopWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderRightWidth: 1,
                                borderStyle: "solid",
                            },
                        },
                    ],
                },
                {
                    id: `widget-${nanoid()}`,
                    xtype: 'field',
                    grouped: true,
                    properties: {
                        fieldName: "Email",
                        flexDirection: "column",
                        alignSelf: "stretch",
                    },
                    children: [
                        {
                            id: `widget-${nanoid()}`,
                            xtype: 'label',
                            properties: {
                                content: "<p>Email</p>",
                                alignSelf: "flex-start",
                                fontSize: 9,
                                marginBottom: 6,
                            },
                        },
                        {
                            id: `widget-${nanoid()}`,
                            xtype: 'input',
                            properties: {
                                placeholder: "Enter your email...",
                                required: true,
                                fontSize: 10,
                                ...makePadding(12),
                                inputType: "email",
                                alignSelf: "stretch",
                                borderColor: "black",
                                borderTopWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderRightWidth: 1,
                                borderStyle: "solid",
                            },
                        },
                    ],
                },
                {
                    id: `widget-${nanoid()}`,
                    xtype: 'field',
                    grouped: true,
                    properties: {
                        fieldName: "Message",
                        flexDirection: "column",
                        alignSelf: "stretch",
                    },
                    children: [
                        {
                            id: `widget-${nanoid()}`,
                            xtype: 'label',
                            properties: {
                                content: "<p>Message</p>",
                                alignSelf: "flex-start",
                                fontSize: 9,
                                marginBottom: 6,
                            },
                        },
                        {
                            id: `widget-${nanoid()}`,
                            xtype: 'text-area',
                            properties: {
                                placeholder: "Enter your message...",
                                required: true,
                                fontSize: 10,
                                ...makePadding(12),
                                alignSelf: "stretch",
                                borderColor: "black",
                                borderTopWidth: 1,
                                borderBottomWidth: 1,
                                borderLeftWidth: 1,
                                borderRightWidth: 1,
                                borderStyle: "solid",
                            },
                        },
                    ],
                },
                {
                    id: `widget-${nanoid()}`,
                    xtype: 'field',
                    grouped: true,
                    properties: {
                        fieldName: "Accept terms and conditions?",
                        flexDirection: "row",
                        alignSelf: "flex-start",
                        justifyContent: "flex-start",
                    },
                    children: [
                        {
                            id: `widget-${nanoid()}`,
                            xtype: 'checkbox',
                            properties: {
                                alignSelf: "center",
                                required: true,
                                ...makePadding(6),
                            },
                        },
                        {
                            id: `widget-${nanoid()}`,
                            xtype: 'label',
                            properties: {
                                content: "<p>I accept the Terms</p>",
                                fontSize: 9,
                                alignSelf: "center",
                            },
                        },
                    ],
                },
                {
                    id: `widget-${nanoid()}`,
                    xtype: 'submit-button',
                    grouped: true,
                    properties: {
                        alignSelf: "center",
                        justifyContent: "center",
                        ...makePadding(10),
                        marginLeft: 6,
                        marginRight: 6,
                        marginTop: 16,
                        marginBottom: 6,
                    },
                    children: [
                        {
                            id: `widget-${nanoid()}`,
                            xtype: 'text',
                            properties: {
                                content: "<p>Submit</p>",
                            },
                        },
                    ],
                },
            ],
        },
    ],
};