import { useDroppable } from "@dnd-kit/core";
import { IDroppableDetails, useCampaign } from "../../../builder/context/campaign";

export interface IDroppableGutterProps {
    widgetPath: number[];
}

//
// Defines a gutter at the end of the page that is a droppable location for new widget.
//
export function PageDroppableGutter({ widgetPath }: IDroppableGutterProps)  {

    const droppableDetails: IDroppableDetails = {
        type: "gutter",
        widgetPath,
        parentDirection: "column",
    };

    const { setNodeRef, isOver } = useDroppable({
        id: "gutter-page",
        data: droppableDetails,
    });

    const { draggingWidget } = useCampaign();

    const className = `page-gutter w-full flex flex-col items-center justify-center relative `;

    let style: any = {
        border: "2px dashed transparent",
        backgroundColor: "transparent",
        height: "250px",
        marginTop: "10px",
    };

    //
    // It's useful to show the gutter when there's nothing in the canvas/group yet.
    //
    style.border = "2px dashed #97A5A6";
    style.backgroundColor = "rgba(255, 255, 255, 0.5)";

    if (draggingWidget) {
        if (isOver) {
            // Area is dragged over or highlighted.
            style.border = "2px dashed #ECF0F1";
            style.borderColor = "#0CBFCC";
        }
        else {
            // Make it visible while dragging.
            style.border = "2px dashed #97A5A6";
            style.backgroundColor = "rgba(255, 255, 255, 0.5)";
        }
    }

    if (isOver) {
        style.backgroundColor = "#ECF0F1";
        style.border = "2px dashed #0CBFCC";
    }

    // For debugging.
    // if (showGutters) {
    //     style.backgroundColor = "rgba(0, 255, 0, 0.25)";
    // }

    return (
        <div
            className={className}
            style={style}
            ref={setNodeRef}
        >
            <div
                className="text-gray-400 text-lg place-self-center self-center text-center"
            >
                Drop an element here
            </div>
        </div>
    );
}
