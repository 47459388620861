import { Outlet } from 'react-router-dom'
import { MiniDrawer } from './Navbar'

const GeneralLayout = () => {
    return (
        <MiniDrawer>
            <Outlet />
        </MiniDrawer>
    )
}

export default GeneralLayout
