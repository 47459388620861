import { nanoid } from "nanoid";
import { IWidget, makePadding, makeMargin, makeBorderRadius } from "widgets-base";

export const uploadForm: IWidget = {
    id: `widget-${nanoid()}`,
    name: 'Upload and win',
    xtype: 'group',
    source: "upload-form-section",
    grouped: true,
    properties: {
        flexDirection: "row",
        ...makePadding(8),
        alignSelf: "center",
        small: {
            flexDirection: "column",
        },
    },
    children: [
        {
            id: `widget-${nanoid()}`,
            xtype: 'form',
            name: "Upload and win form",
            grouped: true,
            properties: {
                flexDirection: "column",
                ...makeMargin(12),
                ...makePadding(12),
                gap: 12,
                minWidth: 175,
                maxWidth: 250,
                justifyContent: "center",
            },
            children: [
                {
                    id: `widget-${nanoid()}`,
                    xtype: 'field',
                    grouped: true,
                    properties: {
                        fieldName: "First name",
                        flexDirection: "column",
                        alignSelf: "stretch",
                    },
                    children: [
                        {
                            id: `widget-${nanoid()}`,
                            xtype: 'label',
                            properties: {
                                content: "<p>First name</p>",
                                alignSelf: "flex-start",
                                fontSize: 9,
                                marginBottom: 6,
                            },
                        },
                        {
                            id: `widget-${nanoid()}`,
                            xtype: 'input',
                            properties: {
                                placeholder: "Enter your first name",
                                required: true,
                                fontSize: 10,
                                backgroundColor: "#F3F3F3",
                                ...makeBorderRadius(40),
                                ...makePadding(12),
                                inputType: "text",
                                alignSelf: "stretch",
                            },
                        },
                    ],
                },
                {
                    id: `widget-${nanoid()}`,
                    xtype: 'field',
                    grouped: true,
                    properties: {
                        fieldName: "Last name",
                        flexDirection: "column",
                        alignSelf: "stretch",
                    },
                    children: [
                        {
                            id: `widget-${nanoid()}`,
                            xtype: 'label',
                            properties: {
                                content: "<p>Last name</p>",
                                alignSelf: "flex-start",
                                fontSize: 9,
                                marginBottom: 6,
                            },
                        },
                        {
                            id: `widget-${nanoid()}`,
                            xtype: 'input',
                            properties: {
                                placeholder: "Enter your last name",
                                required: true,
                                fontSize: 10,
                                backgroundColor: "#F3F3F3",
                                ...makeBorderRadius(40),
                                ...makePadding(12),
                                inputType: "text",
                                alignSelf: "stretch",
                            },
                        },
                    ],
                },
                {
                    id: `widget-${nanoid()}`,
                    xtype: 'field',
                    grouped: true,
                    properties: {
                        fieldName: "Email",
                        flexDirection: "column",
                        alignSelf: "stretch",
                    },
                    children: [
                        {
                            id: `widget-${nanoid()}`,
                            xtype: 'label',
                            properties: {
                                content: "<p>Email</p>",
                                alignSelf: "flex-start",
                                fontSize: 9,
                                marginBottom: 6,
                            },
                        },
                        {
                            id: `widget-${nanoid()}`,
                            xtype: 'input',
                            properties: {
                                placeholder: "Enter your email",
                                required: true,
                                fontSize: 10,
                                backgroundColor: "#F3F3F3",
                                ...makeBorderRadius(40),
                                ...makePadding(12),
                                inputType: "email",
                                alignSelf: "stretch",
                            },
                        },
                    ],
                },
                {
                    id: `widget-${nanoid()}`,
                    name: "Upload button",
                    xtype: "field",
                    grouped: true,
                    properties: {
                        fieldName: "Uploaded file",
                        flexDirection: "column",
                        gap: 6,
                        alignSelf: "stretch",
                        justifyContent: "center"
                    },
                    children: [
                        {
                            id: `widget-${nanoid()}`,
                            xtype: "file-upload",
                            properties: {
                                required: true,
                                acceptedFiles: "image",
                                alignSelf: "stretch",
                                justifyContent: "center",
                                borderTopLeftRadius: 40,
                                borderTopRightRadius: 40,
                                borderBottomLeftRadius: 40,
                                borderBottomRightRadius: 40,
                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingTop: 10,
                                paddingBottom: 10,
                                marginLeft: 6,
                                marginRight: 6,
                                marginTop: 16,
                                marginBottom: 6,
                                color: "white",
                                backgroundColor: "#292929",
                                hover: {
                                    backgroundColor: "#3E3E3E"
                                }
                            },
                            children: [
                                {
                                    id: `widget-${nanoid()}`,
                                    xtype: "text",
                                    properties: {
                                        content: "<p>Upload file</p>"
                                    }
                                }
                            ]
                        }
                    ]
                },
                {
                    id: `widget-${nanoid()}`,
                    xtype: 'field',
                    grouped: true,
                    properties: {
                        fieldName: "Over 18?",
                        flexDirection: "row",
                        alignSelf: "flex-start",
                        justifyContent: "flex-start",
                    },
                    children: [
                        {
                            id: `widget-${nanoid()}`,
                            xtype: 'checkbox',
                            properties: {
                                alignSelf: "center",
                                required: true,
                                ...makePadding(6),
                            },
                        },
                        {
                            id: `widget-${nanoid()}`,
                            xtype: 'label',
                            properties: {
                                content: "<p>I am over 18 years old</p>",
                                fontSize: 9,
                                alignSelf: "center",
                            },
                        },
                    ],
                },
                {
                    id: `widget-${nanoid()}`,
                    xtype: 'field',
                    grouped: true,
                    properties: {
                        fieldName: "Read terms and conditions?",
                        flexDirection: "row",
                        alignSelf: "flex-start",
                        justifyContent: "flex-start",
                    },
                    children: [
                        {
                            id: `widget-${nanoid()}`,
                            xtype: 'checkbox',
                            properties: {
                                alignSelf: "center",
                                required: true,
                                ...makePadding(6),
                            },
                        },
                        {
                            id: `widget-${nanoid()}`,
                            xtype: 'label',
                            properties: {
                                content: "<p>I have read and understood the terms and conditions</p>",
                                fontSize: 9,
                                alignSelf: "center",
                            },
                        },
                    ],
                },
                {
                    id: `widget-${nanoid()}`,
                    xtype: 'submit-button',
                    grouped: true,
                    properties: {
                        alignSelf: "stretch",
                        justifyContent: "center",
                        ...makeBorderRadius(40),
                        ...makePadding(10),
                        marginLeft: 6,
                        marginRight: 6,
                        marginTop: 16,
                        marginBottom: 6,
                        color: "white",
                        backgroundColor: "#292929",
                        hover: {
                            backgroundColor: "#3E3E3E",
                        },
                    },
                    children: [
                        {
                            id: `widget-${nanoid()}`,
                            xtype: 'text',
                            properties: {
                                content: "SUBMIT",
                            },
                        },
                    ],
                },
            ],
        },
        {
            id: `widget-${nanoid()}`,
            xtype: 'image',
            properties: {
                image: {
                    url: "/images/widgets/forms/product-1.png",
                },
                maxWidth: 250,

                small: {
                    marginTop: 24,
                },
            },
        },
    ],
};